import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import {
  getFolloweingList,
  getFollowerList,
} from '../../actions/followerActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import Loader from '../../components/Loader'
import Story from '../../components/Story'

const FollowingScreen = ({ history }) => {
  const dispatch = useDispatch()
  const getFollowing = useSelector((state) => state.getFollowing)
  const { followings, loading, error, suceess } = getFollowing

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!suceess) {
      dispatch(getFolloweingList())
    }
  }, [suceess])
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          <h1>Stories</h1>
          <Row>
            {followings.length > 0 &&
              followings.map((follower) => (
                <Col key={follower._id} sm={4} md={4} lg={3} xs={12}>
                  <Link to={`/user/${follower.following._id}`}>
                    <Row>
                      <Col>
                        <Image
                          src={follower.following.profileImage}
                          className='follow-profile-image'
                        ></Image>
                      </Col>
                      <Col>{follower.following.name}</Col>
                    </Row>
                  </Link>
                </Col>
              ))}
          </Row>
        </>
      )}
    </Container>
  )
}

export default FollowingScreen
