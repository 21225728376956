export const INVENTORY_CREATE_REQUEST = 'INVENTORY_CREATE_REQUEST'
export const INVENTORY_CREATET_SUCCESS = 'INVENTORY_CREATET_SUCCESS'
export const INVENTORY_CREATE_FAIL = 'INVENTORY_CREATE_FAIL'
export const INVENTORY_CREATE_RESET = 'INVENTORY_CREATE_RESET'

export const INVENTORY_LIST_REQUEST = 'INVENTORY_LIST_REQUEST'
export const INVENTORY_LIST_SUCCESS = 'INVENTORY_LIST_SUCCESS'
export const INVENTORY_LIST_FAIL = 'INVENTORY_LIST_FAIL'

export const INVENTORY_APPROVE_REQUEST = 'INVENTORY_APPROVE_REQUEST'
export const INVENTORY_APPROVE_SUCCESS = 'INVENTORY_APPROVE_SUCCESS'
export const INVENTORY_APPROVE_FAIL = 'INVENTORY_APPROVE_FAIL'

export const INVENTORY_GET_REQUEST = 'INVENTORY_GET_REQUEST'
export const INVENTORY_GET_SUCCESS = 'INVENTORY_GET_SUCCESS'
export const INVENTORY_GET_FAIL = 'INVENTORY_GET_FAIL'

export const INVENTORY_UPDATE_REQUEST = 'INVENTORY_UPDATE_REQUEST'
export const INVENTORY_UPDATE_SUCCESS = 'INVENTORY_UPDATE_SUCCESS'
export const INVENTORY_UPDATE_FAIL = 'INVENTORY_UPDATE_FAIL'

export const INVENTORY_DELETE_REQUEST = 'INVENTORY_DELETE_REQUEST'
export const INVENTORY_DELETE_SUCCESS = 'INVENTORY_DELETE_SUCCESS'
export const INVENTORY_DELETE_FAIL = 'INVENTORY_DELETE_FAIL'

export const INVENTORY_LIST_SELLER_REQUEST = 'INVENTORY_LIST_SELLER_REQUEST'
export const INVENTORY_LIST_SELLER_SUCCESS = 'INVENTORY_LIST_SELLER_SUCCESS'
export const INVENTORY_LIST_SELLER_FAIL = 'INVENTORY_LIST_SELLER_FAIL'
