import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

function ScrollToTop(WrappedComponent) {
  const ScrollToTop = (props) => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []) // Empty array ensures that effect is only run on mount and unmount

    return <WrappedComponent {...props} />
  }

  return withRouter(ScrollToTop)
}

export default ScrollToTop
