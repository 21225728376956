import {
  READING_COUNT_FAIL,
  READING_COUNT_REQUEST,
  READING_COUNT_SUCCEES,
  USER_READINGS_ADD_FAIL,
  USER_READINGS_ADD_REQUEST,
  USER_READINGS_ADD_SUCESS,
  USER_READING_LIST_FAIL,
  USER_READING_LIST_REQUEST,
  USER_READING_LIST_SUCESS,
  USER_READING_STATUS_FAIL,
  USER_READING_STATUS_REQUEST,
  USER_READING_STATUS_SUCESS,
} from '../constants/readingConstants'

export const addUserReadings = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_READINGS_ADD_REQUEST:
      return {
        loading: true,
      }
    case USER_READINGS_ADD_SUCESS:
      return {
        loading: false,
        success: true,
        wishlistProducts: action.payload,
      }
    case USER_READINGS_ADD_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getUserReadingReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_READING_LIST_REQUEST:
      return {
        loading: true,
      }
    case USER_READING_LIST_SUCESS:
      return {
        loading: false,
        success: true,
        readingList: action.payload,
      }
    case USER_READING_LIST_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getReadingCountReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case READING_COUNT_REQUEST:
      return {
        loading: true,
      }
    case READING_COUNT_SUCCEES:
      return {
        loading: false,
        success: true,
        count: action.payload,
      }
    case READING_COUNT_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getReadingStatusReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_READING_STATUS_REQUEST:
      return {
        loading: true,
      }
    case USER_READING_STATUS_SUCESS:
      return {
        loading: false,
        success: true,
        page: action.payload,
      }
    case USER_READING_STATUS_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      }
    default:
      return state
  }
}
