import axios from 'axios'
import { AUTHOR_PRODUCT_LIST_FAIL, AUTHOR_PRODUCT_LIST_REQUST, AUTHOR_PRODUCT_LIST_SUCCESS, CATEGORY_PRODUCT_LIST_FAIL, CATEGORY_PRODUCT_LIST_REQUST, CATEGORY_PRODUCT_LIST_SUCCESS } from '../constants/categoryConstants'

export const listProductsByCategory =(category,pageNumber='')=> async(dispatch)=>{
    try{
        dispatch({type:CATEGORY_PRODUCT_LIST_REQUST})
        const {data} = await axios.get(`/api/category/${category}/page/${pageNumber}/`)
        dispatch({
            type:CATEGORY_PRODUCT_LIST_SUCCESS, 
            payload:data
        })
    }catch(error){
        dispatch({
            type:CATEGORY_PRODUCT_LIST_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}
export const listProductsByAuthor =(author,pageNumber='',limit=false)=> async(dispatch)=>{
    try{
        dispatch({type:AUTHOR_PRODUCT_LIST_REQUST})
        const {data} = await axios.get(`/api/author/${author}/page/${pageNumber}/${limit}`)
        dispatch({
            type:AUTHOR_PRODUCT_LIST_SUCCESS, 
            payload:data
        })
    }catch(error){
        dispatch({
            type:AUTHOR_PRODUCT_LIST_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}