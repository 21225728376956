import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Row, Col, Card, ListGroupItem } from 'react-bootstrap'
const AIBoxAttributes = ({ history, cart }) => {
  const [keyword, setKeyword] = useState('')
  const data = JSON.parse(cart.cartData)
  return (
    <>
      <div>Step 0: {data.step0.toString()}</div>
      <div>Step 1: {data.step1.toString()}</div>
      <div>Step 2: {data.step2.toString()}</div>
      <div>Step 3: {data.step3.toString()}</div>
      <div>Step 4: {data.step4.toString()}</div>
    </>
  )
}

export default AIBoxAttributes
