import React, { useState, useEffect, useRef } from 'react'
import {
  Table,
  Form,
  Button,
  Row,
  Col,
  Container,
  ListGroup,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { getUserDetails, updateUserProfile } from '../../actions/userActions'
import { listMyOrders } from '../../actions/orderActions'
import { myOrderDetailsReducer } from '../../reducers/orderReducers'
import { LinkContainer } from 'react-router-bootstrap'
import { scanProduct, upsertProductDetails } from '../../actions/productActions'
import { Link } from 'react-router-dom'
import AdminNav from '../../components/AdminNav'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const ProductScanScreen = ({ location, history, getState }) => {
  const [isbn, setIsbn] = useState('')
  const [tray, setTray] = useState(localStorage.getItem('tray'))
  const [showTray, setShowTray] = useState(true)
  const [blocation, setBlocation] = useState('')
  const [price, setPrice] = useState('')
  const [mrp, setMrp] = useState(0)
  const [cover, setCover] = useState('paperback')
  const [language, setLanguage] = useState('eng')
  const [type, setType] = useState('Used')
  const [inventory, setInventory] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [flag, setFlag] = useState(false)
  const [flag2, setFlag2] = useState(false)
  const dispatch = useDispatch()
  const inputRefs = useRef([])
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productScan = useSelector((state) => state.productScan)
  const { loading, success, error, product } = productScan

  const productUpsertDetails = useSelector((state) => state.productUpsert)
  const {
    success: upsertSuccees,
    loading: updateLoading,
    product: updateProduct,
  } = productUpsertDetails

  useEffect(() => {
    if (!userInfo && userInfo.isAdmin) {
      history.push('/login')
    }

    if (isbn.length == 13 && !loading && !flag) {
      setFlag(true)
      setFlag2(true)
      dispatch(scanProduct(isbn))
      inputRefs.current[0].focus()
    }
    if (success && product && flag) {
      setFlag(false)
      setIsbn('')
      setMrp(product.invn.price)
      setPrice(product.invn.salePrice)
      setInventory(product.invn.qty)
      var location =
        product.book.location &&
        (product.book.location.length == 0 ||
          product.book.location == undefined)
          ? ''
          : product.book.location == undefined
          ? ''
          : product.book.location + ','
      setBlocation(location)
      inputRefs.current[1].focus()
    }
    if (upsertSuccees) {
      if (updateProduct._id == product.book._id) {
        inputRefs.current[0].focus()
      }
      //setFlag(false)
      // setIsbn('')
      // setMrp(updateProduct.price)
      // setPrice(updateProduct.salePrice)
      // setInventory(updateProduct.countInStock)
      // setBlocation(updateProduct.location + ',')
    }
    if (tray && tray.length > 2) {
      localStorage.setItem('tray', tray)
    }
  }, [
    dispatch,
    history,
    userInfo,
    success,
    product,
    upsertSuccees,
    updateProduct,
    isbn,
    tray,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(scanProduct(isbn))
  }

  const updateHandler = (e) => {
    e.preventDefault()

    dispatch(
      upsertProductDetails(
        product.book.isbn,
        price,
        inventory,
        blocation.endsWith(',') ? blocation.slice(0, -1) : blocation,
        cover,
        language,
        type,
        mrp
      )
    )
  }

  const traySubmitHandler = (e) => {
    e.preventDefault()
    localStorage.setItem('tray', tray)
    alert('submitted')
  }
  const updatePlusOne = (e) => {
    e.preventDefault()

    dispatch(
      upsertProductDetails(
        product.book.isbn,
        price,
        parseInt(inventory) + 1,
        blocation.endsWith(',') ? blocation.slice(0, -1) : blocation,
        cover,
        language,
        type,
        mrp
      )
    )
  }

  return (
    <Container>
      <AdminNav></AdminNav>
      <Container>
        <Form onSubmit={submitHandler}>
          <Row>
            <h5>Scan</h5>

            <Col xs={9}>
              <Form.Group controlId='name'>
                <Form.Control
                  type='text'
                  placeholder='SCAN ISBN'
                  value={isbn}
                  ref={(el) => (inputRefs.current[0] = el)}
                  onChange={(e) => setIsbn(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Button type='submit' varaint='primary'>
                Scan
              </Button>
            </Col>
          </Row>
        </Form>
        {/* {tray && tray.length > 2 ? (
          <p>{tray}</p>
        ) : (
          <Form onSubmit={traySubmitHandler}>
            <Row>
              <h5>Scan</h5>

              <Col xs={9}>
                <Form.Group controlId='name'>
                  <Form.Control
                    type='text'
                    placeholder='Tray Num'
                    value={tray}
                    ref={(el) => (inputRefs.current[0] = el)}
                    onChange={(e) => setTray(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Button type='submit' varaint='primary'>
                  Scan
                </Button>
              </Col>
            </Row>
          </Form>
        )} */}

        {loading && updateLoading && <Loader></Loader>}
        {success && !product.book && (
          <Message variant='danger'>Not Found</Message>
        )}
        {product && upsertSuccees && <Message>Updated</Message>}
        {success && product.book && (
          <>
            <Row>
              <Col xs={4}>
                <img src={product.book.image} width='100px' />
              </Col>
              <Col xs={8}>
                <h4>{product.book.name}</h4>
                <div>
                  OnWebsite: <strong>{product.isNew ? 'YES' : 'NO'}</strong>{' '}
                  {!product.isNew && (
                    <small>
                      <br />
                      Qty: <strong>{inventory}</strong>
                      <br />
                      Location: <strong>{blocation}</strong>
                    </small>
                  )}
                  <br />
                  Price: <strong>{price}</strong>
                  <br />
                  Rating :{' '}
                  <strong>
                    {product.book.grating}/({product.book.gnumReviews})
                  </strong>
                  <br />
                </div>
              </Col>
            </Row>
            <Row>
              {product.book.categories.length > 0 && (
                <ListGroup.Item key='category'>
                  <div>
                    Genre:
                    {product.book.categories.map((category) => (
                      <Link
                        to={`/category/${category.slug}`}
                        key={`${category.slug}`}
                        className='topic'
                      >
                        {' '}
                        {category.name}
                      </Link>
                    ))}
                  </div>
                </ListGroup.Item>
              )}
            </Row>
            <Row>
              <Col>
                <Form onSubmit={updateHandler}>
                  <Row>
                    <Col xs={6}>
                      <Form.Group controlId='price'>
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Price'
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId='price'>
                        <Form.Label>MRP</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter MRP'
                          value={mrp}
                          onChange={(e) => setMrp(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId='location'>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type='String'
                          placeholder='Enter Location'
                          value={blocation}
                          ref={(el) => (inputRefs.current[1] = el)}
                          onChange={(e) => setBlocation(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Form.Group controlId='inventory'>
                        <Form.Label>Inventory</Form.Label>
                        <Form.Control
                          type='Number'
                          placeholder='Enter Inventory'
                          value={inventory}
                          onChange={(e) => setInventory(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId='isEbook'>
                        <Form.Label>Book Cover</Form.Label>
                        <Form.Control
                          as='select'
                          aria-label='Select Book Type'
                          onChange={(e) => setCover(e.target.value)}
                        >
                          <option value='paperback'>PaperBack</option>
                          <option value='hardcover'>Hardcover</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Form.Group controlId='isEbook'>
                        <Form.Label>Language</Form.Label>
                        <Form.Control
                          as='select'
                          aria-label='Select Book Type'
                          onChange={(e) => setLanguage(e.target.value)}
                        >
                          <option value='eng'>English</option>
                          <option value='kannada'>Kannada</option>
                          <option value='hindi'>Hindi</option>
                          <option value='tamil'>Tamil</option>
                          <option value='telugu'>Telugu</option>
                          <option value='malayalam'>Malayalam</option>
                          <option value='marathi'>Marathi</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId='isEbook'>
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                          as='select'
                          aria-label='Select Book Type'
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value='Used'>Used</option>
                          <option value='New'>New</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type='submit' varaint='primary'>
                        Update
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type='submit'
                        varaint='primary'
                        onClick={(e) => updatePlusOne(e)}
                      >
                        Update + 1
                      </Button>
                    </Col>
                  </Row>

                  {/* <Form.Group controlId='isActive'>
                  <Form.Check
                    type='checkbox'
                    value={true}
                    label='Is Active'
                    checked={true}
                    onChange={(e) => setIsActive(e.target.value)}
                  ></Form.Check>
                </Form.Group> */}
                </Form>
                <br />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Container>
  )
}

export default ProductScanScreen
