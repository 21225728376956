import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { listSubscriptions } from '../../actions/subscriptionActions'
import Loader from '../../components/Loader'

const UserSubscriptionList = ({ history, match }) => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const listSubscriptionRes = useSelector((state) => state.listSubscription)
  const { success, subscriptions, loading } = listSubscriptionRes
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    } else if (!userLoggedIn.isSubscribed) {
      history.push(`/app/profile/billing`)
    }
    dispatch(listSubscriptions())
  }, [dispatch, history, userLoggedIn])
  return (
    <>
      {loading && !success ? (
        <Loader></Loader>
      ) : (
        <Container>
          <Row>
            <Col>
              {subscriptions && subscriptions.length == 0 && (
                <div style={{ textAlign: 'center' }}>
                  No Active Subscription
                </div>
              )}
              {subscriptions &&
                subscriptions.length > 0 &&
                subscriptions.map((s) => (
                  <div style={{ textAlign: 'center' }}>
                    {s.name}
                    <br />₹{s.amount}/Month
                    <br />
                    <span className='topic'>Active</span>
                  </div>
                ))}
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}
{
}

export default UserSubscriptionList
