import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { FaTruck } from 'react-icons/fa'

import { useHistory } from 'react-router-dom'
const BoxProgressBar = ({ cart }) => {
  const history = useHistory()
  const onBarClick = () => {
    history.push(`/cart/`)
  }
  const pragressVaraint = 'success'
  var newValue = 0
  cart &&
    cart.cartItems &&
    cart.cartItems.forEach((item, index) => {
      if (item.isBookscartBox) {
        newValue++
      }
    })
  newValue = cart && (newValue > 9 ? 9 : newValue > 0 ? newValue : 0)
  const ProgressValue = newValue * 11.11

  return (
    newValue > 0 && (
      <>
        <small className='mobile-center '>
          {cart &&
            cart.cartItems.length > 0 &&
            newValue > 0 &&
            (newValue == 9
              ? 'Box Completed'
              : `${newValue} selected ${9 - newValue} more to go`)}
        </small>
        <ProgressBar
          variant={pragressVaraint}
          now={ProgressValue}
          onClick={onBarClick}
          className='boxprogressbar'
        />
        <small className='mobile-center'>
          {cart && cart.cartItems.length > 0 && '(9 Books for ₹999/-)'}
          <br />
          <strong>
            Free Shipping <FaTruck />{' '}
          </strong>
        </small>
      </>
    )
  )
}

export default BoxProgressBar
