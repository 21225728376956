import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
} from 'react-bootstrap'

const AboutUsScreen = ({ match, history }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Container>
        <Row>
          <div class='py-3'>
            <div class='container'>
              <h1>About Us</h1>
              <p>
                Knowledge is priceless and books are the best means from which
                you can attain this knowledge. Reading is believed to empower
                people, offer them education, and give them an opportunity to
                live for a moment in someone else’s shoes. Books are also a
                critical asset to improvement in one’s life while it also offers
                an occasional escape to fictional worlds inhabited by
                interesting characters of the books.
              </p>
              <p>We are the team working hard to make reading more accessible</p>
              <strong>READ LEAD SUCCEED</strong>
            </div>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default AboutUsScreen
// dispatch(listProductsDetails('BookscartBox1', 'surprise-box-15-books'))
