import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Image, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { login, sendOTP, verifyOtp } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import SocialLogin from '../components/SocialLogin'
import { register } from '../actions/userActions'

const LoginOTPScreen = ({ location, history }) => {
  const [phone, setPhone] = useState('')
  const [otp, setOtp] = useState('')
  const [otpMessage, setOtpMessage] = useState('')
  const [otpReSentMessage, setOtpReSentMessage] = useState(false)
  const [userVerifyMessage, setUserVerifyMessage] = useState('')
  const [phoneMessage, setPhoneMessage] = useState('')

  const [showMainForm, setShowMainForm] = useState(true)
  const [showOtpForm, setShowOtpForm] = useState(false)

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const userVerify = useSelector((state) => state.userVerify)
  const {
    loading: userVerifyLoading,
    error: userVerifyError,
    success: userVerifySuccess,
    result: userVerifyResult,
  } = userVerify

  const otpVerify = useSelector((state) => state.userOtpVerify)
  const {
    loading: otpLoading,
    error: otpError,
    success: otpSuccess,
    result,
  } = otpVerify

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userInfo) {
      history.push(redirect)
    }
    if (otpSuccess) {
      if (result.result) {
        history.push('/')
      }
    }
    if (!userVerifyLoading && userVerifySuccess && userVerifyResult) {
      if (userVerifyResult.result) {
        setOtpMessage(true)
        setOtpReSentMessage(false)
        setShowMainForm(false)
        setShowOtpForm(true)
      } else {
        setUserVerifyMessage('Enter Registered Phone Number')
      }
    }
  }, [
    history,
    userInfo,
    redirect,
    otpSuccess,
    userVerifySuccess,
    userVerifyResult,
    result,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(phone)) {
      dispatch(sendOTP(phone))
    } else {
      setPhoneMessage('Phone Number is not valid')
    }
  }
  const resendOtp = (e) => {
    dispatch(sendOTP(phone))
    setOtp('')
    setOtpReSentMessage(true)
  }
  const otpSubmitHandler = (e) => {
    e.preventDefault()
    const reg = false
    dispatch(verifyOtp(phone, otp, reg))
    setOtpReSentMessage(false)
  }

  return (
    <Container>
      <Row className='login-register'>
        <Col md={12}>
          <div className='social-logins'>
            <SocialLogin />
          </div>
          {showMainForm && (
            <div style={{ padding: '20px' }} className='login-box'>
              <FormContainer>
                <h1>Log In</h1>
                <div className='registerErrorMessage'>{userVerifyMessage}</div>
                <div className='registerErrorMessage'>{phoneMessage}</div>
                {/* {loading && <Loader></Loader>} */}
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId='phone'>
                    <Form.Label>Enter Registered Mobile Number</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter 10 digits Number'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    ></Form.Control>
                  </Form.Group>
                  <br />
                  <Button type='submit' varaint='primary'>
                    Login
                  </Button>
                  <div className='new-customer'>
                    <Link to={'/register'} style={{ float: 'right' }}>
                      {' '}
                      <small>New Constomer?</small>
                    </Link>
                  </div>
                  <p className='text-muted'>
                    <br />
                    By continuing, you agree to 99bookscart{' '}
                    <Link to='/pages/terms-and-condition'>
                      Terms of Condition
                    </Link>{' '}
                    & <Link to='/pages/privacy-policy'>User Policy</Link>.
                  </p>
                </Form>
                <br />
              </FormContainer>
            </div>
          )}
          {showOtpForm && (
            <>
              <div style={{ padding: '20px' }} className='login-box'>
                <FormContainer>
                  <h1>Enter Otp</h1>
                  {/* {message && <Message variant='danger'>{message}</Message>}
              {error && <Message variant='danger'>{error}</Message>} */}
                  {loading && <Loader></Loader>}
                  {otpMessage && otpSuccess && !result.result && (
                    <small className='registerErrorMessage'>
                      Enter Valid OTP
                      <br />
                    </small>
                  )}
                  {otpReSentMessage && (
                    <small className='registerErrorMessage'>
                      Resent Otp
                      <br />
                    </small>
                  )}
                  <Form onSubmit={otpSubmitHandler}>
                    <Form.Group controlId='name'>
                      <Form.Label>
                        Enter OTP recieved on Registerd Mobile
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter 4 digit OTP'
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>

                    <Button type='submit' varaint='primary'>
                      Submit
                    </Button>
                    <br />
                    <a onClick={resendOtp} style={{ float: 'right' }}>
                      Resend otp
                    </a>
                  </Form>
                </FormContainer>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default LoginOTPScreen
