import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'
import BlogsMeta from '../../../components/meta/BlogsMeta'
import { getBlog, getBlogBySlug } from '../../../actions/blogActions'
import Typography from '@mui/material/Typography'
import { Paper } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import AdBanner from '../../../components/Ads/AdBanner'
import AdHeaderBanner from '../../../components/Ads/AdHeaderBanner'
import AdInArticle from '../../../components/Ads/AdInArticle'
import { ShareSocial } from 'react-share-social'

export function convertToSlug2(Text) {
  return (
    Text &&
    Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  )
}
const ShayariTypeScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const getBlogRes = useSelector((state) => state.getBlog)
  const { loading, error, success, blog } = getBlogRes
  const [date, setDate] = useState('2018-12-28')
  const [paragraph, setParagraph] = useState([])
  const [len, setLen] = useState(0)
  const [url, setUrl] = useState('')
  var contentBeforeAd = ''
  var contentAfterAd = ''
  useEffect(() => {
    window.scrollTo(0, 0)

    if (success) {
      if (blog.slug != match.params.title) {
        dispatch(getBlogBySlug(match.params.title))
      }
      setUrl(
        `https://www.99bookscart.com/blog/view/${blog._id}/${convertToSlug2(
          blog.title
        )}`
      )
      // 'Updated: Dec 15, 2023, 07:04 PM IST'
      var date = new Date(blog.createdAt)
      setDate(
        date.getDate() +
          ' ' +
          date.toLocaleString('default', { month: 'long' }) +
          ', ' +
          date.getFullYear() +
          ',' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ' IST'
      )
      const blogText = blog.description
      //setParagraph(paragraphs)
      if (blog.description) {
        var l = blog.description.length
        var kk = l / 1300
        setLen(blog.description.length % 1000)
        var plist = []
        if (blog.type == 2) {
          var l = blog.description.length
          var kk = l / 1000
          var t2 = 0
          var temp = 4200
          for (var i = 0; i <= kk; i++) {
            plist.push(blog.description.substring(t2, temp))
            setParagraph(plist)
            t2 = temp
            temp = temp + 1000
          }
        } else if (blog.type == 1) {
          var l = blog.description.length
          var kk = l / 1000
          var t2 = 0
          var temp = 2000
          for (var i = 0; i <= kk; i++) {
            plist.push(blog.description.substring(t2, temp))
            setParagraph(plist)
            t2 = temp
            temp = temp + 1000
          }
        } else if (blog.type == 4) {
          var l = blog.description.length
          var kk = l / 1000
          var t2 = 0
          var temp = 4200
          for (var i = 0; i <= kk; i++) {
            plist.push(blog.description.substring(t2, temp))
            setParagraph(plist)
            t2 = temp
            temp = temp + 1000
          }
        } else {
          var l = blog.description.length
          var kk = l / 600
          var t2 = 0
          var temp = 600
          for (var i = 0; i <= kk; i++) {
            plist.push(blog.description.substring(t2, temp))
            setParagraph(plist)
            temp = temp + 600
            t2 = t2 + 600
          }
        }
      }
    } else {
      if (match.params.id !== undefined) {
        dispatch(getBlogBySlug(match.params.title))
      } else {
        dispatch(getBlogBySlug(match.params.title))
      }
    }
  }, [dispatch, success, match, contentBeforeAd])

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <BlogsMeta blog={blog}></BlogsMeta>
          <Container xs={12} md={12}>
            <AdHeaderBanner />
            <div role='presentation'>
              <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
                <Link underline='hover' color='inherit' to={'/blog'}>
                  Blog
                </Link>
                <Typography color='text.primary'>Post</Typography>
              </Breadcrumbs>
            </div>
            {userInfo && userInfo.isAdmin && (
              <Link to={`/blog/edit/${blog._id}`}>
                <Button>Edit</Button>
              </Link>
            )}
            <Col sx={{ flex: 1 }} style={{ padding: '5px' }}>
              <div>
                <h1 className='blog-title'>{blog && blog.title}</h1>
                <Typography variant='subtitle'>
                  by <a href='#'>Pooja Rawath</a>. posted on {date && date}
                  <br />
                </Typography>
              </div>

              <div className='mt-1 mobile-view' style={{ textAlign: 'center' }}>
                {/* <a
                  href={`https://www.ysense.com/?rb=154612805`}
                  target='_blank'
                  rel='nofollow'
                > 
                  <amp-img
                    src='https://s3-books-images.s3.amazonaws.com/e0ba1636-8e7b-4c31-aede-8b3a48f50f91.png'
                    alt='complete survey and earn money'
                    width='300'
                    height='60'
                    className=' text-center'
                    layout='responsive'
                  />
                </a>
                <br /> */}
                <amp-img
                  src={blog && blog.image}
                  // src='https://s3-books-images.s3.amazonaws.com/fa4faa81-b548-46cc-a489-9de23fba45f7.png'
                  alt={blog.title}
                  width='300'
                  height='180'
                  className=' text-center'
                  layout='responsive'
                />
              </div>
              <div
                className='mt-1 desktop-view'
                style={{ textAlign: 'center' }}
              >
                {/* <a
                  href={`https://www.ysense.com/?rb=154612805`}
                  target='_blank'
                  rel='nofollow'
                >
                  <amp-img
                    src='https://s3-books-images.s3.amazonaws.com/e0ba1636-8e7b-4c31-aede-8b3a48f50f91.png'
                    alt='complete survey and earn money'
                    width='1200'
                    height='200'
                    className=' text-center'
                    layout='responsive'
                  />
                </a>
                <br /> */}
                <amp-img
                  src={blog && blog.image}
                  //src='https://s3-books-images.s3.amazonaws.com/fa4faa81-b548-46cc-a489-9de23fba45f7.png'
                  alt={blog.title}
                  width='1200'
                  height='400'
                  className=' text-center'
                  layout='responsive'
                />
              </div>
              <div>
                <AdInArticle />
                {paragraph &&
                  paragraph.map((blog, index) => (
                    <Typography variant='subtitle1' key={index}>
                      <div
                        key={index}
                        className='blog-text'
                        style={{ color: '#000' }}
                        dangerouslySetInnerHTML={{
                          __html:
                            success &&
                            blog.replace(/(<? *script)/gi, 'illegalscript'),
                        }}
                      ></div>
                      <AdInArticle />
                    </Typography>
                  ))}
              </div>
              <ShareSocial
                title={blog.title}
                url={url}
                socialTypes={[
                  'facebook',
                  'twitter',
                  'linkedin',
                  'reddit',
                  'email',
                ]}
              />

              <AdBanner />
              <div>
                Tag:{' '}
                {success &&
                  blog.topic &&
                  blog.topic.map((topic) => (
                    <span className='topic' key={topic}>
                      {topic}
                    </span>
                  ))}
              </div>
            </Col>
            <Paper>
              <div style={{ padding: '10px' }}>
                <Typography variant='subtitle1' paragraph>
                  About Author
                </Typography>
                <Typography variant='subtitle2' paragraph>
                  Pooja Rawath is a passionate blogger and a book lover.
                  Graduated in Literature and public journalism, she is
                  currently pursuing her masters in English Literature. She has
                  a keen interest in writing and reading novels. She loves to
                  write about the latest books and about the book industry.
                </Typography>
                <Typography variant='subtitle2' paragraph>
                  <Link to={'/contact-us'}>Contact Pooja Rawath</Link>
                </Typography>
              </div>
            </Paper>
          </Container>
          {/* <AdInArticle /> */}

          <br />
        </>
      )}
    </>
  )
}

export default ShayariTypeScreen
