import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import { readEpisode, voteEpisode } from '../../../actions/episodeActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import Loader from '../../../components/Loader'
import ReactDOMServer from 'react-dom'
import crypto from 'crypto'
import { addFollower } from '../../../actions/followerActions'
import { FaLightbulb, FaSortAlphaUp, FaSortAlphaDown } from 'react-icons/fa'
import { FaBookReader, FaStar, FaComment } from 'react-icons/fa'
import Comments from '../../../components/Comments'
import SocialShare from '../../../components/SocialShare'
import { convertToSlug, makeTitle } from '../../../helpers/helpers'
import Cookies from 'universal-cookie'
import AppHeader from '../../../components/plots/AppHeader'

const AppReadPlotsScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const cookies = new Cookies()
  const [content, SetContent] = useState('')
  const [follow, setFollow] = useState(false)
  const [voteText, setVoteText] = useState('Vote')
  const [flag, setFlag] = useState(false)
  const [color, setColor] = useState(cookies.get('theme') || '#fff')
  const url = `https://www.99bookscart.com/episode/${match.params.id}`

  const readEpisodeD = useSelector((state) => state.readEpisode)
  const { episode, nexchapterId, loading, error, suceess } = readEpisodeD
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const voteEpisodeHandler = () => {
    dispatch(voteEpisode(match.params.id))
    setVoteText('Voted')
  }
  const followHandler = () => {
    dispatch(addFollower({ following: episode.user._id }))
    setFollow(true)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    } else {
      if (!userLoggedIn.isSubscribed) {
        history.push('/app/profile/billing')
      }
    }
    if ((!suceess || (episode && episode._id != match.params.id)) && !loading) {
      dispatch(readEpisode(match.params.id))
    }

    if (suceess) {
      var algorithm = 'aes256' // or any other algorithm supported by OpenSSL
      var key = 'password'
      var decipher = crypto.createDecipher(algorithm, key)
      var decrypted =
        decipher.update(episode.content, 'hex', 'utf8') + decipher.final('utf8')
      SetContent(decrypted)
      setFlag(true)
      setColor(cookies.get('theme'))
    }
  }, [
    suceess,
    episode,
    content,
    match.params.id,
    loading,
    userLoggedIn,
    history,
  ])
  const fontDecrese = () => {
    if (
      color == '#fff' ||
      color == 'smallfontreading' ||
      color == 'largefontreading'
    ) {
      setColor('smallfontreading')
    } else {
      setColor('bgs-black smallfontreading')
    }
  }
  const fontIncrease = () => {
    if (
      color == '#fff' ||
      color == 'smallfontreading' ||
      color == 'largefontreading'
    ) {
      setColor('largefontreading')
    } else {
      setColor('bgs-black largefontreading')
    }
  }
  const background = () => {
    if (color == '#fff') {
      cookies.set('theme', 'bgs-black', {
        path: '/',
        maxAge: 34550000,
      })
      setColor('bgs-black')
    } else {
      cookies.set('theme', '#fff', {
        path: '/',
        maxAge: 34550000,
      })
      setColor('#fff')
    }
  }
  return (
    <Container
      style={{ paddingTop: '35px', paddingBottom: '35px' }}
      className={color}
    >
      {loading || !suceess ? (
        <Loader></Loader>
      ) : (
        <>
          <Row>
            <Col>
              <Container>
                <div style={{ cursor: 'pointer' }}>
                  <Link
                    style={{ float: 'left', color: 'inherit' }}
                    to={`/app/en/book/${convertToSlug(episode.story.title)}/${
                      episode.story._id
                    }`}
                  >
                    Back
                  </Link>
                </div>
                <div style={{ cursor: 'pointer' }}>
                  <span onClick={voteEpisodeHandler} style={{ float: 'right' }}>
                    <FaStar style={{ color: '#666' }}></FaStar> {voteText}
                  </span>
                </div>
              </Container>
            </Col>
          </Row>
          <div className='episodereadheader'>
            <small>{episode.story.title}</small>
            <h2 className='episodetitle-name' style={{ color: 'inherit' }}>
              {episode.title}
            </h2>
            <div className='storystatslist'>
              <FaBookReader></FaBookReader> {episode.totalReadCount}{' '}
              <FaStar></FaStar> {episode.totalVoteCount} <FaComment></FaComment>{' '}
              {episode.totalComment}
            </div>
          </div>
          <Row>
            <Col xs={12} md={12}>
              <div
                className='episodecontent'
                dangerouslySetInnerHTML={{
                  __html: content.replace(/(<? *script)/gi, 'illegalscript'),
                }}
              ></div>
              <div style={{ textAlign: 'center' }}>
                <br />
                {nexchapterId != null ? (
                  <div>
                    <Link to={`/app/en/plot/${nexchapterId}`}>
                      <Button>Next Idea</Button>
                    </Link>{' '}
                    <br />
                  </div>
                ) : (
                  ''
                )}
              </div>
              <br />
              <div style={{ textAlign: 'center' }}>
                <Link
                  to={`/app/en/book/${convertToSlug(episode.story.title)}/${
                    episode.story._id
                  }`}
                  style={{ color: 'inherit' }}
                >
                  Back to story
                </Link>
              </div>

              <Comments episode={episode}></Comments>
              <Container>
                <div className='footer-menu'>
                  <div className='footer-menu-list'>
                    <div className='footer-menu-list-item' onClick={background}>
                      <FaLightbulb></FaLightbulb>
                    </div>
                    <div
                      className='footer-menu-list-item'
                      onClick={fontDecrese}
                    >
                      <FaSortAlphaDown></FaSortAlphaDown>
                    </div>
                    <div
                      className='footer-menu-list-item'
                      onClick={fontIncrease}
                    >
                      <FaSortAlphaUp></FaSortAlphaUp>
                    </div>
                  </div>
                </div>
              </Container>
            </Col>
            {userLoggedIn && userLoggedIn.isAdmin && (
              <Row>
                <Link
                  to={`/write/episode/${match.params.id}/${episode.story._id}`}
                  className='newbuttons'
                >
                  Edit
                </Link>
              </Row>
            )}
            {/* <Col xs={12} md={3} style={{ textAlign: 'center' }}>
              <div>
                {nexchapterId != null ? (
                  <div>
                    <Link to={`/app/idea/${nexchapterId}`}>
                      <Button>Next Chapter</Button>
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <br />
              <div style={{ fontSize: '12px' }}>
                by{' '}
                <Link to={`/user/${episode.user._id}`} className='writer-name'>
                  {episode.user.name}
                </Link>
              </div>
              <Button onClick={followHandler} disabled={follow ? true : false}>
                Follow
              </Button>
              <SocialShare
                url={url}
                title={episode.title}
                verticle={true}
              ></SocialShare>
            </Col> */}
          </Row>
        </>
      )}
    </Container>
  )
}

export default AppReadPlotsScreen
