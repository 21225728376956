import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import FooterMenu from '../../../components/plots/FooterMenu'
import AppHeader from '../../../components/plots/AppHeader'

import CategoriesApp from '../../../components/CategoriesApp'
import SearchBox from '../../../components/plots/SearchBox'
const AppHomeScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()
  const url = `https://www.99bookscart.com/stories/${match.params.id}`
  const [title, setTitle] = useState('')
  const [content, SetContent] = useState('')
  const [isReadMore, setIsReadMore] = useState(true)
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, success: registerSuccess, userInfo } = userRegister

  useEffect(() => {
    window.scrollTo(0, 0)
    history.push(`/`)
    if (!userLoggedIn) {
      history.push(`/register`)
    } else {
      if (!userLoggedIn.isSubscribed) {
        // history.push('/app/profile/billing')
      }
    }
  }, [dispatch, userInfo, history])
  return (
    <>
      <Container>
        <AppHeader></AppHeader>
        <Row>
          <Container>
            <SearchBox history={history}></SearchBox>
          </Container>
          <CategoriesApp></CategoriesApp>
          {/* <FooterMenu></FooterMenu> */}
        </Row>
        <br />
        <br />
      </Container>
    </>
  )
}
{
}

export default AppHomeScreen
