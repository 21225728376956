import React, { useState, useEffect } from 'react'
import {
  Card,
  Button,
  Modal,
  Image,
  Row,
  Col,
  Container,
} from 'react-bootstrap'
import Rating from './Rating'
import { Link } from 'react-router-dom'
import { addToCart } from '../actions/cartActions.js'
import { useDispatch, useSelector } from 'react-redux'
import BoxProgressBar from './BoxProgressBar'
import { FaBoxOpen } from 'react-icons/fa'
import { getCategory, storyStatus } from '../helpers/helpers'
import { FaBookReader, FaStar, FaList, FaClock } from 'react-icons/fa'
import { convertToSlug } from '../helpers/helpers'
import { listStoryByCat } from '../actions/storyActions'
const Timer = ({}) => {
  const dispatch = useDispatch()
  // const [show, setShow] = useState(false);
  const initialTimer = localStorage.getItem('timer') || 9 * 60 * 60
  const [timer, setTimer] = useState(initialTimer)

  useEffect(() => {
    localStorage.setItem('timer', timer)
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1) // decrement timer by 1 every second
    }, 1000)

    return () => clearInterval(countdown)
  }, [])
  const hours = Math.floor(timer / 3600)
  const minutes = Math.floor((timer % 3600) / 60)
  const seconds = timer % 60
  return (
    <div className='cartCoins'>
      <strong>
        Limited Offer ⏰3days {hours}h {minutes}min {seconds}s
      </strong>
    </div>
  )
}

export default Timer
