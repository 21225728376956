import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { listStory } from '../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import Loader from '../../components/Loader'
import Story from '../../components/Story'
import PaginateStory from '../../components/PaginateStory'
import Message from '../../components/Message'
const StoryListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1

  const listStoryD = useSelector((state) => state.listStory)
  const { stories, loading, error, suceess, page, pages } = listStoryD

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(listStory(pageNumber))
  }, [dispatch, pageNumber])
  return (
    <Container>
      {error && <Message>{error}</Message>}
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          <h1>Stories</h1>
          <Row>
            {stories.map((story) => (
              <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                <Route
                  render={({ history }) => (
                    <Story history={history} story={story} />
                  )}
                />
              </Col>
            ))}
          </Row>
          <PaginateStory pages={pages} page={page}></PaginateStory>
        </>
      )}
    </Container>
  )
}

export default StoryListScreen
