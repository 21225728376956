import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { listUsers, deleteUser } from '../../actions/userActions'
import ReactHtmlParser from 'react-html-parser'
import AdminNav from '../../components/AdminNav'
import { listBlog } from '../../actions/blogActions'
import { myFunction } from '../../helpers/helpers'

import Barcode from './component/Barcode'

const BarcodeListScreen = ({ location, history, match }) => {
  const dispatch = useDispatch()
  const numbers = []
  const series = location.search ? location.search.split('=')[1] : 'F'
  // var vseries = location.search ? location.search.split('=')[2] : '1'
  // if (vseries) {
  // } else {
  //   vseries = '1'
  // }
  for (let i = 1; i <= 99; i++) {
    numbers.push(series.toUpperCase() + i.toString().padStart(2, '0'))
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [dispatch])
  const Array = []
  return (
    <Container>
      <AdminNav></AdminNav>
      <Row>
        <Container>
          <Row>
            {numbers.map((i) => (
              <Col lg={4} md={4} sm={6} key={i}>
                <Barcode code={i}></Barcode>
              </Col>
            ))}
          </Row>
        </Container>
      </Row>
    </Container>
  )
}

export default BarcodeListScreen
