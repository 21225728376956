export const STORE_CREATE_REQUEST = 'STORE_CREATE_REQUEST'
export const STORE_CREATET_SUCCESS = 'STORE_CREATET_SUCCESS'
export const STORE_CREATE_FAIL = 'STORE_CREATE_FAIL'

export const STORE_LIST_REQUEST = 'STORE_LIST_REQUEST'
export const STORE_LIST_SUCCESS = 'STORE_LIST_SUCCESS'
export const STORE_LIST_FAIL = 'STORE_LIST_FAIL'

export const STORE_GET_REQUEST = 'STORE_GET_REQUEST'
export const STORE_GET_SUCCESS = 'STORE_GET_SUCCESS'
export const STORE_GET_FAIL = 'STORE_GET_FAIL'

export const STORE_UPDATE_REQUEST = 'STORE_UPDATE_REQUEST'
export const STORE_UPDATE_SUCCESS = 'STORE_UPDATE_SUCCESS'
export const STORE_UPDATE_FAIL = 'STORE_UPDATE_FAIL'

export const STORE_OTP_REQUEST = 'STORE_OTP_REQUEST'
export const STORE_OTP_SUCCESS = 'STORE_OTP_SUCCESS'
export const STORE_OTP_FAIL = 'STORE_OTP_FAIL'

export const STORE_VERIFY_REQUEST = 'STORE_VERIFY_REQUEST'
export const STORE_VERIFY_SUCCESS = 'STORE_VERIFY_SUCCESS'
export const STORE_VERIFY_FAIL = 'STORE_VERIFY_FAIL'

export const STORE_ADD_BOOK_REQUEST = 'STORE_ADD_BOOK_REQUEST'
export const STORE_ADD_BOOK_SUCCESS = 'STORE_ADD_BOOK_SUCCESS'
export const STORE_ADD_BOOK_FAIL = 'STORE_ADD_BOOK_FAIL'

export const STORE_GET_BYID_REQUEST = 'STORE_GET_BYID_REQUEST'
export const STORE_GET_BYID_SUCCESS = 'STORE_GET_BYID_SUCCESS'
export const STORE_GET_BYID_FAIL = 'STORE_GET_BYID_FAIL'

export const STORE_ADMIN_LIST_REQUEST = 'STORE_ADMIN_LIST_REQUEST'
export const STORE_ADMIN_LIST_SUCCESS = 'STORE_ADMIN_LIST_SUCCESS'
export const STORE_ADMIN_LIST_FAIL = 'STORE_ADMIN_LIST_FAIL'






