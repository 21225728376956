import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
const BuyerOrder = ({ history, order }) => {
  return (
    <Card
      sx={{ minWidth: 275 }}
      variant='outlined'
      key={order._id}
      style={{ marginBottom: '6px' }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          {order.createdAt.substring(0, 10)}
        </Typography>
        <Typography variant='h6' component='div'>
          <Link to={`/order/${order._id}`}>{order.orderName}</Link>
        </Typography>
        {/*<Typography sx={{ mb: 1.5 }} color='text.secondary'>
                              {order.status}
                            </Typography> */}
        <Typography variant='body2'>{order.status}</Typography>
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        <Link to={`/order/${order._id}`}>
          <Button size='small'>View Details</Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default BuyerOrder
