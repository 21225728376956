import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import { register } from '../../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'
import SocialLogin from '../../../components/SocialLogin'
import BookSearch from '../components/BookSearch'
import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/plots/meta/HomeMeta'
import { getSellerInventory } from '../../../actions/inventoryActions'
import SellerProductView from '../components/SellerProductView'
import CPaginate from '../../../components/CPaginate'
import BookPaginate from '../../../components/paginate/BookPaginate'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { getStore } from '../../../actions/storeActions'

const SellerBooksListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const search = window.location.search
  const params = new URLSearchParams(search)
  const [name, setName] = useState('')
  const type = params.get('type') || 2
  const keyword = params.get('keyword') || ''
  const sellerInventory = useSelector((state) => state.sellerInventory)
  const { loading, error, success, products, page, pages } = sellerInventory
  const storeData = useSelector((state) => state.getStore)
  const {
    loading: storeLoading,
    error: storeError,
    success: storeSuccess,
    result,
  } = storeData
  const handleChange = (event, value) => {
    history.push(`/seller/books/page/${value}`)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (userLoggedIn && !loading) {
      dispatch(getStore())
    }
    if (storeSuccess) {
      if (result.store.storeName) {
        dispatch(getSellerInventory(pageNumber, keyword, type))
      } else {
        history.push('/seller/create/new/store')
      }
    }
  }, [dispatch, userLoggedIn, pageNumber, keyword, storeSuccess])
  const searchHandler = (e) => {
    history.push(`/seller/books?keyword=${keyword}`)
  }
  return (
    <>
      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Container>
            <Row>
              <div role='presentation'>
                <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
                  <Link underline='hover' color='inherit' to={'/profile'}>
                    Dashboard
                  </Link>
                  <Typography color='text.primary'>Books List</Typography>
                </Breadcrumbs>
              </div>
            </Row>
            <Row>
              <Col>
                <BookSearch history={history} />
              </Col>
            </Row>

            <Row>
              {products.length === 0 ? (
                <Message variant='info'>No Books Found</Message>
              ) : (
                <>
                  <Row className='text-center'>
                    {products &&
                      products.length > 0 &&
                      products.map(
                        (inven) =>
                          inven &&
                          inven.product && (
                            <SellerProductView
                              product={inven}
                              key={inven.product._id}
                            />
                          )
                      )}
                  </Row>
                  <Stack spacing={1}>
                    <Pagination
                      count={pages}
                      page={page}
                      onChange={handleChange}
                      style={{
                        justifyContent: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    />
                  </Stack>
                </>
              )}
            </Row>
          </Container>
        )}
      </Container>
    </>
  )
}

export default SellerBooksListScreen
