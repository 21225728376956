import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup } from 'react-bootstrap'
import Product from '../components/Product'
import axios from 'axios'
import { listProducts } from '../actions/productActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import CPaginate from '../components/CPaginate'
import { Container } from 'react-bootstrap'
import ProductCarosel from '../components/ProductCarosel'
import Meta from '../components/Meta'
import BoxProgressBar from '../components/BoxProgressBar'
import BoxCarosel from '../components/BoxCarosel'
import { listProductsByCategory } from '../actions/categoryActions'

const RedirectCategoryProductScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1

  const productList = useSelector((state) => state.categoryProduct)
  const { loading, error, products, pages, page } = productList

  const dispatch = useDispatch()
  useEffect(() => {
    history.push('/category/crime-and-thriller')
  }, [dispatch, history, pageNumber])
  return <></>
}

export default RedirectCategoryProductScreen
