import React from 'react'
import { Card, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { addToCart } from '../actions/cartActions.js'
import { useDispatch, useSelector } from 'react-redux'
import {
  PRODUCT_PAYMENTTYPE_FREE,
  PRODUCT_PAYMENTTYPE_SUBSCRIPTION,
} from '../constants/productConstants'


const Ebook = ({ product, history, match, addtocart = true }) => {
  const dispatch = useDispatch()

  // const [show, setShow] = useState(false);
  const [disable, setDisable] = React.useState(false)
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const slug = convertToSlug(product.name)
  let shortlistedBook = false
  cartItems.map((item) => {
    if (item.product === product._id) {
      shortlistedBook = true
    }
  })

 
  const addToCartHandler = () => {
    dispatch(addToCart(product._id, 1))
    setDisable(true)
  }
  const readNow = () => {
    if (userInfo) {
      history.push(`/read/${product.isbn}/${slug}`)
    } else {
      history.push('/login')
    }
  }
  const subscribeNow = () => {
    if (userInfo) {
      history.push(`/profile/subscription`)
    } else {
      history.push('/login')
    }
  }
  return (
    <>
      <Card className=' rounded py-1'>
        <Link to={`/book/${product.isbn}/${slug}`}>
          <Card.Img
            src={product.image}
            variant='top'
            alt={product.name}
            className='book-image'
            layout='responsive'
          ></Card.Img>
        </Link>
        <Card.Body>
          <Link
            to={`/book/${product.isbn}/${slug}`}
            style={{ textDecoration: 'none' }}
          >
            <Card.Title as='div'>{product.name}</Card.Title>
          </Link>
          <Card.Subtitle className='mb-2 text-muted '>
            <Link to={`/author/${product.author}`}>
              by <span className='capitalize-text'>{product.author}</span>
            </Link>
          </Card.Subtitle>

          {/* <Card.Text className='popularity-text'>
                        {product.grating > 0 && (<Rating value={product.grating}/>)} */}

          {/* <small className='float-right instock'>{ (product.countInStock < 3 && product.countInStock!=0) &&product.countInStock+" left"}</small> */}

          {/* </Card.Text> */}

          {/* { product.rating != 0 ?
                    <Card.Text as='div' className='product-page-rating'>
                        <Rating value={product.rating} text={`${product.numReviews}`}></Rating>
                    </Card.Text>
                :''} */}

          <Card.Text className='text-center'>
            <div className='text-center-product'>
              {product.paymentType === PRODUCT_PAYMENTTYPE_FREE ? (
                <div className='product-page-price themecolor'>
                  <Button
                    className='btn-block mt-auto'
                    size='sm'
                    onClick={readNow}
                    type='button'
                  >
                    <span>Read For Free</span>
                  </Button>
                </div>
              ) : product.paymentType === PRODUCT_PAYMENTTYPE_SUBSCRIPTION ? (
                <div className='product-page-price themecolor'>
                  <Button
                    className='btn-block mt-auto'
                    size='sm'
                    onClick={subscribeNow}
                    type='button'
                  >
                    <span>Subscribe</span>
                  </Button>
                </div>
              ) : (
                <>
                  <span className=' themecolor'>
                    &#8377;{product.salePrice}
                  </span>{' '}
                  &nbsp;
                  <strike>&#8377;{product.price}</strike>
                  <br />
                  {addtocart && (
                    <div className='text-center'>
                      <Button
                        className='btn-block mt-auto'
                        size='sm'
                        onClick={addToCartHandler}
                        type='button'
                        disabled={
                          product.countInStock === 0 ||
                          disable ||
                          shortlistedBook
                        }
                      >
                        {!shortlistedBook ? (
                          product.countInStock === 0 ? (
                            'No Stock'
                          ) : (
                            <i className='fa fa-box-open'> Add to cart</i>
                          )
                        ) : (
                          'In cart'
                        )}
                      </Button>
                      <div>
                        {shortlistedBook && (
                          <>
                            <div className='very-small-line-height'>
                              <Link to='/cart'>View Cart</Link>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </Card.Text>

          {/* <small>{cartItems.length+"Books Shortlisted"}</small> */}
        </Card.Body>
      </Card>
      {/* <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Box Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>Box Type: {cartType}<br/>Books: {cartItems.length} 
            <p><BoxProgressBar value={cartItems.length}/></p>
            <Link to='/cart'><Button className='float-right'>Go to Cart</Button></Link>
            </Modal.Body>
      </Modal>
       */}
    </>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default Ebook
