import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  ListGroupItem,
  Form,
  FormGroup,
  Alert,
} from 'react-bootstrap'
import Rating from '../components/Rating'
import axios from 'axios'
import {
  listProductsDetails,
  createProductReview,
} from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_PAYMENTTYPE_FREE,
  PRODUCT_PAYMENTTYPE_SUBSCRIPTION,
} from '../constants/productConstants'
import Meta from '../components/Meta'
import { addToCart, recentlyViewed } from '../actions/cartActions.js'
import { Container } from 'react-bootstrap'
import { Route } from 'react-router-dom'
import Product from '../components/Product'
import { listProductsByAuthor } from '../actions/categoryActions'
import BoxProgressBar from '../components/BoxProgressBar'
import {
  FaFlagCheckered,
  FaTruck,
  FaCompressArrowsAlt,
  FaCheck,
  FaGooglePlay,
  FaHeart,
  FaBarcode,
  FaRegNewspaper,
  FaBuilding,
  FaPenFancy,
  FaCalendarAlt,
  FaBookOpen,
  FaLanguage,
  FaCoins,
} from 'react-icons/fa'

import {
  addToIntrested,
  addToWIshlist,
  getWishlist,
  addToAlreadRead,
  getIntrestsByProduct,
  getAlreadyReadsByProduct,
} from '../actions/wishlistActions'
import ReactHtmlParser from 'react-html-parser'
import ProductMeta from '../components/ProductMeta'
import { BoxDescription } from '../components/BoxDescription'
var dayss = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const BoxScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const [qty, setQty] = useState(1)
  const [addToCartMessage, setAddToCartMessage] = useState(false)
  const [vprice, setVprice] = useState(0)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [slug, setSlug] = useState('')
  const [image, setImage] = useState('')
  const [disable, setDisable] = React.useState(false)
  const [uploading, setUploading] = useState(false)
  const [isReadMore, setIsReadMore] = useState(true)
  const [intrestCheck, setIntrestCheck] = useState(false)
  const [bookcartBoxCount, SetBookcartBoxCount] = useState(0)
  const [alreadyReadCheck, setAlreadyReadCheck] = useState(false)
  const [inBox, SetInBox] = useState(false)
  const [vId, SetVid] = useState(0)

  const cart = useSelector((state) => state.cart)
  const { cartItems, cartType, recentView: recentProducts, cartSuccess } = cart

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product, success, readingCount } = productDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const ulStyle = {
    border: '1px solid #111',
    borderRadius: '6px',
    backgroundColor: '#b6c3dc',
    fontSize: '12px',
  }
  const freeShippingText = {
    color: '#FF5733',
    padding: '3px',
    fontSize: '1em',
    textAlign: 'center',
  }

  const pageStyle = {
    textAlign: 'center',
    // backgroundColor: '#b6c3dc',
    // backgroundImage: `url(
    //   'https://s3-books-images.s3.amazonaws.com/f159c171-6aa0-407b-be30-65f2b26df970.png'
    // )`,
    // backgroundPosition: '-400px -100px',
  }

  var reviewsLenght = 0
  var reviewsList = []
  var date = new Date()

  var deliveryDate = new Date(date.setTime(date.getTime() + 7 * 86400000))

  if (success) {
    product.description =
      product.description && product.description.replace(/<[^>]*>?/gm, '')
    reviewsLenght = product.reviews.length

    if (product.reviews.length > 0) {
      const list = product.reviews.sort(() => Math.random() - 0.5)
      for (var i = 0; i < 12; i++) {
        if (list[i]) {
          reviewsList.push(list[i])
        }
      }
    }
  }
  useEffect(() => {
    if (product && product.isbn != match.params.id) {
      setDisable(false)
      window.scrollTo(0, 0)
      dispatch(listProductsDetails(match.params.id, match.params.slug))
    }

    if (success && product) {
      setSlug(convertToSlug(product.name))
    }
    if (cartItems) {
      SetInBox(false)
      if (cartItems.length > 0) {
        var count = 0
        cartItems.forEach((item, index) => {
          if (item.isBookscartBox) {
            if (
              (item.isbn == product.isbn || item.isbn == match.params.id) &&
              !item.variantSelected
            ) {
              SetInBox(true)
            }
            count++
          }
        })
        SetBookcartBoxCount(count)
      }
    }
  }, [
    dispatch,
    match,
    success,
    product,
    cartItems,
    bookcartBoxCount,
    cartSuccess,
  ])

  const addToCartHandler = () => {
    let variantId = 0
    if (vprice.length > 0) {
      var e = document.getElementById('boxTypeSelect')
      variantId = vId
    } else if (product.isBox) {
      variantId = product.boxType[0]._id
    }
    dispatch(addToCart(product._id, 1, variantId))
    setAddToCartMessage(true)
    if (product.isbn == 'BookscartBox1') {
      if (userInfo) {
        history.push('/checkout/select-address')
      } else {
        history.push('/checkout/basic-information')
      }
    }
  }

  const updateVariantPrice = (ee) => {
    product.salePrice = ee.target.value
    SetVid(ee.target.id)
    setVprice(ee.target.value)
  }

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  const readNow = () => {
    if (userInfo) {
      history.push(`/read/${product.isbn}/${slug}`)
    } else {
      history.push('/login')
    }
  }

  const subscribeNow = () => {
    if (userInfo) {
      history.push(`/profile/subscription`)
    } else {
      history.push('/login')
    }
  }

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Container>
          <ProductMeta product={product}></ProductMeta>
          <Container>
            <ListGroup variants='flush'>
              <Row>
                <Col md={12} style={pageStyle}>
                  <div>
                    <>
                      <img
                        src='https://s3-books-images.s3.amazonaws.com/eaeb8c11-e110-412d-bc7d-99f9ace1b5c5.png'
                        width={'100%'}
                      />
                      <br />
                      <div className=''>
                        <h1>Spice Up Your Personal Library</h1>
                        <strong>{product.name}</strong>
                        {!product.isBox && (
                          // <Link to={`/author/${product.author}`}>
                          <small className='text-muted themeColor'>
                            by {product.author}{' '}
                          </small>
                          // </Link>
                        )}
                        {/* <div>
                          🚀 How to know more than your friends & family? The
                          knowledge you gain from reading gives you lots to
                          share & talk about with others.📚
                        </div> */}
                        <div>
                          Want more Concentration, Ability to Focus & Sleep
                          Better?
                          <br />
                          <span style={freeShippingText}>
                            Read Atleast 6 minutes a day!
                          </span>
                        </div>

                        <div>
                          <span className='popularity-text'>
                            {product.grating > 0 && (
                              <Rating
                                value={product.grating}
                                text={`(${product.gnumReviews.toLocaleString()} reviews)`}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    </>

                    {product.numReviews > 0 && (
                      <ListGroup.Item key='reviews' className='mobile-center'>
                        <Rating
                          value={product.rating}
                          text={`${reviewsLenght.toLocaleString()} reviews`}
                        />
                        {/* ({product.views}) Views */}
                      </ListGroup.Item>
                    )}

                    <div className='text-center-product'>
                      {product.paymentType == PRODUCT_PAYMENTTYPE_FREE ? (
                        <div className='product-page-price themecolor'>
                          FREE
                        </div>
                      ) : product.paymentType ==
                        PRODUCT_PAYMENTTYPE_SUBSCRIPTION ? (
                        <div>Get 1000's of books for just ₹149/month</div>
                      ) : (
                        <>
                          <small className='red-text'>
                            -
                            {Math.round(
                              100 - (product.salePrice / product.price) * 100
                            )}
                            %{' '}
                          </small>
                          <span className='product-page-price themecolor'>
                            &#8377;{product.salePrice}
                          </span>{' '}
                          &nbsp;
                          <strike>&#8377;{product.price}</strike>
                          <br />
                          {/* <small className='text-muted'>
                            Saving &#8377;
                            {Math.round(product.price - product.salePrice)} (
                            {Math.round(
                              100 - (product.salePrice / product.price) * 100
                            )}
                            % OFF)
                          </small> */}
                          {!product.isEbook && (
                            <div className='delivery-date'>
                              <FaTruck></FaTruck> Order Today! Get it by{' '}
                              {dayss[deliveryDate.getDay()]},
                              {deliveryDate.getDate()}{' '}
                              {months[deliveryDate.getMonth()]}
                            </div>
                          )}
                          {!product.isEbook && (
                            <ListGroup.Item key='cartbutton'>
                              <>
                                <div>
                                  {addToCartMessage && (
                                    <>
                                      <div className='very-small-line-height mobile-center line-height'>
                                        <span className='very-small'>
                                          Added to Cart! &nbsp;&nbsp;
                                          <Link to='/cart'>View Cart</Link>
                                        </span>
                                      </div>
                                      <br />
                                    </>
                                  )}
                                </div>
                                {/* {!inBox ? ( */}
                              </>
                            </ListGroup.Item>
                          )}
                        </>
                      )}
                    </div>
                    {product.isBox && (
                      <>
                        {/* <strong>
                          More than 67,940 Surprise Boxes📦 have been purchased!
                        </strong> */}
                        <Row>
                          <Form.Label>20+ Genres Available</Form.Label>

                          <Container>
                            <Row className='margin-left'>
                              {product.boxType.map((x) => (
                                <Col xs={6} md={4}>
                                  <Form.Check
                                    key={x._id}
                                    name='variant'
                                    type='radio'
                                    id={x._id}
                                    label={x.name}
                                    data-id={x._id}
                                    data-name={x.price}
                                    value={x.price}
                                    style={ulStyle}
                                    onChange={(e) => updateVariantPrice(e)}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </Container>
                        </Row>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {!product.isEbook && (
                    <ListGroup.Item key='cartbutton'>
                      <>
                        <div>
                          {addToCartMessage && (
                            <>
                              <div className='very-small-line-height mobile-center line-height'>
                                <span className='very-small'>
                                  Added to Cart! &nbsp;&nbsp;
                                  <Link to='/cart'>View Cart</Link>
                                </span>
                              </div>
                              <br />
                            </>
                          )}
                        </div>
                        <Row>
                          <Col xs={12} md={12} className='text-center'>
                            <br />
                            <Button
                              className='btn-block'
                              onClick={addToCartHandler}
                              type='button'
                              disabled={
                                (product.countInStock == 0 || disable) &&
                                !product.variantSelected
                              }
                            >
                              {product.countInStock == 0 ? (
                                'No Stock'
                              ) : (
                                <i className='fa fa-box-open'> Buy Now</i>
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </>
                    </ListGroup.Item>
                  )}

                  {product.isEbook &&
                    (product.paymentType == PRODUCT_PAYMENTTYPE_FREE ? (
                      <ListGroup.Item key='ebook'>
                        <>
                          <Row>
                            <Col xs={12} md={12}>
                              <Button
                                className='btn-block mt-auto readnow-button'
                                onClick={readNow}
                                type='button'
                                disabled={
                                  (product.countInStock == 0 || disable) &&
                                  !product.variantSelected
                                }
                              >
                                {product.countInStock == 0 ? (
                                  'No Stock'
                                ) : (
                                  <i className='fa fa-box-open'> Read Now </i>
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </>
                      </ListGroup.Item>
                    ) : product.paymentType ==
                      PRODUCT_PAYMENTTYPE_SUBSCRIPTION ? (
                      <ListGroup.Item key='subscription'>
                        <>
                          <Row>
                            <Col xs={12} md={12}>
                              <Button
                                className='btn-block mt-auto readnow-button'
                                onClick={subscribeNow}
                                type='button'
                                disabled={
                                  (product.countInStock == 0 || disable) &&
                                  !product.variantSelected
                                }
                              >
                                {product.countInStock == 0 ? (
                                  'No Stock'
                                ) : (
                                  <i className='fa fa-box-open'>
                                    {' '}
                                    SUBSCRIBE NOW{' '}
                                  </i>
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </>
                      </ListGroup.Item>
                    ) : (
                      <ListGroup.Item key='readnow'>
                        <>
                          <Row>
                            <Col xs={12} md={12}>
                              <Button
                                className='btn-block mt-auto readnow-button'
                                onClick={readNow}
                                type='button'
                                disabled={
                                  (product.countInStock == 0 || disable) &&
                                  !product.variantSelected
                                }
                              >
                                {product.countInStock == 0 ? (
                                  'No Stock'
                                ) : (
                                  <i className='fa fa-box-open'>
                                    {' '}
                                    Purchase and Read{' '}
                                  </i>
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </>
                      </ListGroup.Item>
                    ))}

                  {product.salePrice > 0 && (
                    <ListGroup.Item key='coins'>
                      <p className='text-center'>
                        <br />
                        <FaCoins></FaCoins>{' '}
                        <span style={{ color: '#4267b2' }}>
                          Earn {product.salePrice / 50} coins on this book
                        </span>
                      </p>
                    </ListGroup.Item>
                  )}
                  {product.categories.length > 0 && !product.isBox && (
                    <ListGroup.Item key='category'>
                      <h5>Genre: </h5>
                      {product.categories.map((category) => (
                        // <Link
                        //   to={`/category/${category.slug}`}
                        //   key={`${category.slug}`}
                        //   className='topic'
                        // >

                        <span className='topic'>{category.name}</span>
                        // </Link>
                      ))}
                    </ListGroup.Item>
                  )}

                  <>
                    {product.description &&
                      (product.isBox ? (
                        <ListGroup.Item key='description-bookscartBox'>
                          <BoxDescription
                            description={product.description}
                          ></BoxDescription>
                        </ListGroup.Item>
                      ) : (
                        <>
                          <ListGroup.Item key='description'>
                            <h5>Description:</h5>
                            <div className='product-description'>
                              {isReadMore
                                ? ReactHtmlParser(
                                    product.description.slice(0, 250)
                                  )
                                : ReactHtmlParser(product.description)}
                              <div
                                onClick={toggleReadMore}
                                className='read-or-hide'
                              >
                                {isReadMore ? '...read more' : ' show less'}
                              </div>
                            </div>
                          </ListGroup.Item>
                        </>
                      ))}
                  </>

                  {!product.isBox && (
                    <ListGroup.Item key='details'>
                      <div className='product-details'>
                        <div>
                          <strong>
                            <FaBarcode></FaBarcode> ISBN-13:{' '}
                          </strong>
                          {product.isbn}
                        </div>
                        {product.pages > 1 && (
                          <div>
                            <strong>
                              <FaRegNewspaper></FaRegNewspaper> Pages:{' '}
                            </strong>
                            {product.pages}
                          </div>
                        )}
                        {product.publisher && (
                          <div>
                            <strong>
                              <FaBuilding> </FaBuilding> Publisher:{' '}
                            </strong>
                            {product.publisher}
                          </div>
                        )}
                        {product.author && (
                          <div>
                            <strong>
                              <FaPenFancy></FaPenFancy> Author:{' '}
                            </strong>
                            {product.author}
                          </div>
                        )}
                        {product.edition && (
                          <div>
                            <strong>
                              <FaCalendarAlt></FaCalendarAlt> Edition:{' '}
                            </strong>
                            {product.edition}
                          </div>
                        )}
                        {product.binding && (
                          <div>
                            <strong>
                              {' '}
                              <FaBookOpen></FaBookOpen> Binding:{' '}
                            </strong>
                            {product.binding}
                          </div>
                        )}
                        <div>
                          <strong>
                            <FaLanguage></FaLanguage> Langauge:{' '}
                          </strong>
                          {product.langauge ? product.langauge : 'English'}
                        </div>
                      </div>
                    </ListGroup.Item>
                  )}
                </Col>
              </Row>
            </ListGroup>
          </Container>
          <>
            <Row>
              <Col md={12}>
                {product.reviews.length > 0 && (
                  <>
                    <h5>Reviews</h5>
                    <ListGroup.Item key='reviewsss'>
                      <Container>
                        <Row>
                          {reviewsList.map((review, index) => (
                            <Col key={review._id} xs={12} md={4}>
                              <span className='review-tile'>{review.name}</span>{' '}
                              rated {review.rating} stars
                              <br />
                              <Rating value={review.rating}></Rating>
                              <Row key={review._id}>
                                <Col xs={12} md={4}>
                                  {review.image && (
                                    <img
                                      src={review.image.replace(/\\/g, '/')}
                                      key={review._id}
                                      className='reviewImage'
                                    />
                                  )}
                                </Col>
                                <Col xs={12} md={8} className='right'>
                                  <p key={review._id}>{review.comment}</p>
                                </Col>
                              </Row>
                            </Col>
                          ))}
                        </Row>
                        <Row className='homepage-mobile-bar'>
                          <Link
                            to={`/review/${match.params.id}/${match.params.slug}`}
                          >
                            <h1 className='text-center homepage-mobile-bar-col'>
                              Read All Reviews
                            </h1>
                          </Link>
                        </Row>
                      </Container>
                    </ListGroup.Item>
                  </>
                )}
              </Col>
            </Row>
          </>
        </Container>
      )}
    </>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
function loop(index) {
  if (index < 0) {
    return
  }
}
export default BoxScreen
