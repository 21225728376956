import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
  Form,
  Modal,
} from 'react-bootstrap'
import { listProductsDetails } from '../../actions/productActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Rating from '../../components/Rating'
import SurpriseStats from '../../components/SurpriseStats'
import { userRegisterLP, userAddressLP } from '../../actions/userActions'
import { BoxDescription } from '../../components/BoxDescription'
import { addToCart } from '../../actions/cartActions.js'
import { SHIPPING_STATE } from '../../constants/orderConstants'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const SuprieseBoxScreen = ({ match, history }) => {
  const dispatch = useDispatch()
  const [vprice, setVprice] = useState(999)
  const [vname, setVname] = useState('Mixed Fiction')
  const [vId, SetVid] = useState(0)
  const [show, setShow] = useState(false)
  const [showNext, setShowNext] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [state, setState] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [zipError, setZipError] = useState(null)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product, success, readingCount } = productDetails

  const userRegisterLPRes = useSelector((state) => state.userRegisterLP)
  const { success: lpRegSuccess, userData } = userRegisterLPRes

  const userAddressLPRes = useSelector((state) => state.userAddressLP)
  const { success: lpAddSuccess, userData2 } = userAddressLPRes

  var reviewsLenght = 0
  var reviewsList = []
  if (success) {
    product.description =
      product.description && product.description.replace(/<[^>]*>?/gm, '')
    reviewsLenght = product.reviews.length

    if (product.reviews.length > 0) {
      const list = product.reviews.sort(() => Math.random() - 0.5)
      for (var i = 0; i < 18; i++) {
        if (list[i]) {
          reviewsList.push(list[i])
        }
      }
    }
  }
  const handleClose = () => {
    setShow(false)
  }
  const handleCloseNext = () => {
    setShowNext(false)
  }
  function isEmail(email) {
    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (email !== '' && email.match(emailFormat)) {
      return true
    }
    return false
  }
  const submitHandlerNext = (e) => {
    e.preventDefault()
    dispatch(
      userAddressLP({
        address,
        address2,
        city,
        postalCode,
        state,
        uid: userData._id,
        cid: cookies.get('_cid'),
      })
    )
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    if (isEmail(email)) {
      setEmailError('')
      var phonenoExp = /^\d{10}$/
      if (phonenoExp.test(phone)) {
        setPhoneError('')
        dispatch(
          userRegisterLP({
            name,
            email,
            phone,
          })
        )
      } else {
        setPhoneError('Enter Valid Phone Number')
      }
    } else {
      setEmailError('Enter Valid email')
    }
  }

  useEffect(() => {
    if (!product || !product._id || product.isbn != 'BookscartBox1') {
      window.scrollTo(0, 0)
      dispatch(listProductsDetails('BookscartBox1', 'surprise-box-15-books'))
    }
    if (lpRegSuccess) {
      if (userData.type) {
        if (userData.type == 'phone') {
          setPhoneError(userData.data)
        } else if ((userData.type = 'name')) {
          setEmailError(userData.data)
        }
      } else {
        setShow(false)
        setShowNext(true)
      }
    }
    if (lpAddSuccess) {
      history.push('/checkout/shipping')
    }
  }, [vprice, vname, lpRegSuccess, lpAddSuccess, product])

  const updateVariantPrice = (ee) => {
    setVprice(ee.target.value)
    SetVid(ee.target.id)
    setVname(ee.target.name)
  }
  const BuyNowHandler = () => {
    let variantId = 0
    if (vprice.length > 0) {
      variantId = vId
    } else if (product.isBox) {
      variantId = product.boxType[0]._id
    }
    dispatch(addToCart(product._id, 1, variantId))
    if (userInfo) {
      history.push('/checkout/select-address')
    } else {
      setShow(true)
      // history.push('/checkout/basic-information')
    }
  }
  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <>
          <Row className='ldp-ma'>
            <div className='ldp-fs'>
              <Link to='/'>
                <img
                  src='../../images/logo.png'
                  className='siteLogo'
                  alt='99bookscart logo'
                />
              </Link>
              <br /><br />
              Be More Curious!
              <br /> Be More Knowledgable!
              <br /> Be More Succeesful!
              <br /> Be More Happy!
              <br />
              <br />
              <small>
                Reading makes you Happy
                <br />
              </small>
            </div>
          </Row>
          <Container>
            <Container>
              <Row>
                <br />
                <br />
                <h1 className='ldp-mh'>
                  Build your own Library at Home & Office
                </h1>

                <div className='text-center-rating text-center'>
                  <div>Surprise box contains 15 Books</div>

                  <Rating value={4.8} />
                  <small>1,988 Reviews</small>
                </div>
                <p className='ldp-sh'>
                  🚀 How to know more than your friends & family? The knowledge
                  you gain from reading gives you lots to share & talk about
                  with others.📚
                </p>
                {success && (
                  <>
                    <Row>
                      <Container>
                        <SurpriseStats></SurpriseStats>
                        <Row>
                          <div
                            style={{ textAlign: 'center', marginTop: '8px' }}
                          >
                            <small className='red-text'>
                              -
                              {Math.round(
                                100 - (product.salePrice / product.price) * 100
                              )}
                              %{' '}
                            </small>
                            <span
                              className='product-page-price themecolor'
                              style={{ fontSize: '22px' }}
                            >
                              &#8377;{vprice}
                            </span>{' '}
                            &nbsp;
                            <strike>&#8377;{product.price}</strike>
                            <br />
                            {vname}
                          </div>
                        </Row>
                        <Row className='ldg-vari'>
                          <span>
                            <br />
                            Select Genre
                          </span>

                          {product.boxType.map((x) => (
                            <Col xs={6} md={4}>
                              <Button
                                className='ldp-variant'
                                key={x._id}
                                id={x._id}
                                label={x.name}
                                data-id={x._id}
                                name={x.name}
                                value={x.price}
                                onClick={(e) => updateVariantPrice(e)}
                              >
                                {x.name} ₹{x.price}
                              </Button>
                            </Col>
                          ))}
                        </Row>

                        <Row>
                          <div className='text-center'>
                            <Button className='box-buy' onClick={BuyNowHandler}>
                              Start Reading
                            </Button>
                          </div>
                        </Row>
                        <Row className='ldp-desc'>
                          <BoxDescription
                            description={product.description}
                          ></BoxDescription>
                        </Row>
                      </Container>
                    </Row>
                    <Container>
                      <Row>
                        <Col md={12}>
                          {product.reviews.length > 0 && (
                            <>
                              <h5>Reviews</h5>
                              <ListGroup.Item key='reviewsss'>
                                <Container>
                                  <Row>
                                    {reviewsList.map((review, index) => (
                                      <Col key={review._id} xs={12} md={4}>
                                        <span className='review-tile'>
                                          {review.name}
                                        </span>{' '}
                                        rated {review.rating} stars
                                        <br />
                                        <Rating value={review.rating}></Rating>
                                        <Row key={review._id}>
                                          <Col xs={12} md={4}>
                                            {review.image && (
                                              <img
                                                src={review.image.replace(
                                                  /\\/g,
                                                  '/'
                                                )}
                                                key={review._id}
                                                className='reviewImage'
                                              />
                                            )}
                                          </Col>
                                          <Col xs={12} md={8} className='right'>
                                            <p key={review._id}>
                                              {review.comment}
                                            </p>
                                          </Col>
                                        </Row>
                                      </Col>
                                    ))}
                                  </Row>
                                  <Row className='homepage-mobile-bar'>
                                    <Link
                                      to={`review/BookscartBox1/surprise-box-15-books`}
                                    >
                                      <h1 className='text-center homepage-mobile-bar-col'>
                                        Read All Reviews
                                      </h1>
                                    </Link>
                                  </Row>
                                </Container>
                              </ListGroup.Item>
                            </>
                          )}
                        </Col>
                      </Row>
                      <br />
                      <br />
                    </Container>
                  </>
                )}
              </Row>
            </Container>
          </Container>
        </>
      )}
      <Modal show={show} onHide={handleClose} backdrop='static'>
        <Modal.Body>
          <Form onSubmit={submitHandler2}>
            <div style={{ padding: '20px' }}>
              <Form.Group>
                <div style={{ marginBottom: '20px' }}>
                  <h5>Build Your Own Library</h5>
                  <small>
                    Free Bookmarks on Every Order!
                    <br />
                  </small>
                </div>
                <Form.Text className='red-text'></Form.Text>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  required
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
                <Form.Text className='red-text'>
                  {phoneError && <span>{phoneError}</span>}
                </Form.Text>
                <Form.Control
                  type='tel'
                  placeholder='Phone Number'
                  required
                  value={phone || ''}
                  onChange={(e) => setPhone(e.target.value)}
                ></Form.Control>
                <Form.Text className='red-text'>
                  {emailError && <span>{emailError}</span>}
                </Form.Text>

                <Form.Control
                  type='email'
                  placeholder='Email'
                  required
                  value={email || ''}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Row>
                <Col>
                  <Button onClick={handleClose}>Close</Button>
                </Col>
                <Col>
                  <Button
                    type='submit'
                    variant='primary'
                    className='float-right'
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showNext} onHide={handleCloseNext} backdrop='static'>
        <Modal.Body>
          <Form onSubmit={submitHandlerNext}>
            <div style={{ padding: '20px' }}>
              <h5>Shipping Address</h5>
              <small>
                Free Bookmarks on Every Order!
                <br />
              </small>
              <Form.Group controlId='address' style={{ marginTop: '20px' }}>
                <Form.Control
                  type='text'
                  placeholder='Address'
                  required
                  value={address || ''}
                  onChange={(e) => setAddress(e.target.value)}
                  className='checkoutForm'
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='address2'>
                <Form.Control
                  type='text'
                  placeholder='Apartment, Suite, etc (Optional)'
                  value={address2 || ''}
                  onChange={(e) => setAddress2(e.target.value)}
                  className='checkoutForm'
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='city'>
                <Form.Control
                  type='text'
                  placeholder='City'
                  required
                  value={city || ''}
                  onChange={(e) => setCity(e.target.value)}
                  className='checkoutForm'
                ></Form.Control>
                <Form.Text className='text-muted'>
                  {zipError && <span>{zipError}</span>}
                </Form.Text>
                <Form.Control
                  type='text'
                  placeholder='Zip Code'
                  required
                  value={postalCode || ''}
                  onChange={(e) => setPostalCode(e.target.value)}
                  className='checkoutForm'
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='postalCode'></Form.Group>
              <Form.Group controlId='state'>
                <Form.Control
                  as='select'
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                  className='checkoutForm'
                >
                  <option key='Select your State' value={''}>
                    State
                  </option>
                  {SHIPPING_STATE.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Row>
                <Col>
                  <Button
                    type='submit'
                    variant='primary'
                    className='float-right'
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SuprieseBoxScreen
// dispatch(listProductsDetails('BookscartBox1', 'surprise-box-15-books'))
