import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
//import JsBarcode from 'jsbarcode'
const Barcode = ({ code }) => {
  const dispatch = useDispatch()

  const listBlogRes = useSelector((state) => state.listBlog)
  const { loading, error, success, blogs } = listBlogRes
  const canvasRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (canvasRef.current) {
      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')

      // Define the barcode options
      const options = {
        format: 'CODE128',
        displayValue: true,
        fontOptions: 'bold',
        font: 'Helvetica',
        textAlign: 'center',
        textMargin: 10,
        fontSize: 55,
        width: 2,
        height: 100,
        margin: 10,
      }

      // Generate the barcode and render it to the canvas
      //JsBarcode(canvas, code, options)

      // Draw the canvas to the screen
      ctx.drawImage(canvas, 0, 0)
    }
  }, [dispatch, code])

  return <canvas ref={canvasRef} width={400} height={200} />
}
export default Barcode
