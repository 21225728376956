import { Editor } from '@tinymce/tinymce-react'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteBlog, editBlog, getBlog } from '../../actions/blogActions'
import Loader from '../../components/Loader'
import SmallLoader from '../../components/SmallLoader'
import { myFunction } from '../../helpers/helpers'

const EditBlogScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const storyId = match.params.sid || null
  const blogId = match.params.id || null
  const [metaTitle, setmetaTitle] = useState('')
  const [meateDesc, setmeateDesc] = useState('')
  const [title, setTitle] = useState('')
  const [topic, setTopic] = useState([])
  const [topicLineItems, setTopicLineItems] = useState([])
  const [description, setDescription] = useState('')
  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const getBlogRes = useSelector((state) => state.getBlog)
  const { blog, loading, error, suceess } = getBlogRes
  const deleteBlogRes = useSelector((state) => state.deleteBlog)
  const { suceess: deleteSuccess } = deleteBlogRes
  const editBlogRes = useSelector((state) => state.editBlog)
  const {
    blog: eBlog,
    loading: editLoading,
    error: editError,
    suceess: editSuccess,
  } = editBlogRes
  const editorRef = useRef(null)
  const formLabel = {
    color: '#000',
    fontSize: '16px',
    fontWeight: 'bold',
  }

  const addTags = () => {
    topicLineItems.push(topic)
    setTopic('')
  }
  const removeTags = (item, e) => {
    e.preventDefault()
    const index = topicLineItems.indexOf(item)
    if (index > -1) {
      topicLineItems.splice(index, 1)
    }
    setTopicLineItems(topicLineItems)
  }
  const deleteHandler = () => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteBlog(blog._id))
    }
  }
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      editBlog({
        _id: blog._id,
        title: title,
        topic: topicLineItems,
        description: description,
        metaTitle: metaTitle,
        metaDescription: meateDesc,
      })
    )
  }
  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      if (deleteSuccess) {
        history.push(`/blog`)
      }
      if (editSuccess && eBlog) {
        history.push(`/blog/view/${blog._id}/${myFunction(blog.title)}`)
      } else {
        if (blog && (!blog.title || blog._id !== blogId)) {
          dispatch(getBlog(blogId))
        } else {
          if (blog && blog.title) {
            setTitle(blog.title)
            setDescription(blog.description)
            setTopicLineItems(blog.topic)
            setmetaTitle(blog.metaTitle)
            setmeateDesc(blog.metaDescription)
          }
        }
      }
    } else {
      history.push(`/login`)
    }
  }, [
    history,
    blog,
    editSuccess,
    userInfo,
    dispatch,
    eBlog,
    blogId,
    deleteSuccess,
  ])
  return (
    <Container>
      {false ? (
        <Loader></Loader>
      ) : (
        <>
          <Row>
            <Col>
              <h5>Create Blog</h5>
            </Col>
          </Row>

          <Form onSubmit={submitHandler}>
            <Row>
              <Col sm={12}>
                <Form.Group className='mb-3' controlId='metatitle'>
                  <Form.Label style={formLabel}>Meta Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Untitled Story'
                    required
                    value={metaTitle}
                    onChange={(e) => setmetaTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId='metadesc'>
                  <Form.Label style={formLabel}>Meta Desc</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Untitled Story'
                    required
                    value={meateDesc}
                    onChange={(e) => setmeateDesc(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId='Storytitle'>
                  <Form.Label style={formLabel}>Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Untitled Story'
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId='tags'>
                  <Form.Label style={formLabel}>Tags</Form.Label>
                  <Row>
                    <Col xs={9}>
                      <Form.Control
                        type='text'
                        placeholder='Tags'
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                      />
                    </Col>
                    <Col xs={3}>
                      <Button
                        variant='primary'
                        className='mb-10'
                        style={{ float: 'left' }}
                        onClick={addTags}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>

                  {topicLineItems.length > 0 && (
                    <div>
                      {topicLineItems.map((item) => (
                        <small className='topic' key={item}>
                          {item}{' '}
                          <Link to='' onClick={(e) => removeTags(item, e)}>
                            Remove
                          </Link>
                        </small>
                      ))}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className='mb-3' controlId='description'>
                  <Form.Label style={formLabel}>Description</Form.Label>
                  <Editor
                    apiKey='g547goe8xzsy9huuryqujg4tz7deth3i6cqhgr3173uv4ifc'
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={description}
                    onEditorChange={(newValue, editor) =>
                      setDescription(newValue)
                    }
                    init={{
                      menubar: 'view',
                      height: 1000,

                      plugins:
                        'code autolink directionality visualblocks visualchars  link media   table charmap pagebreak nonbreaking anchor   lists wordcount   help',
                      toolbar:
                        'code |pageembed | formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                  />
                </Form.Group>
                <div style={{ float: 'left' }}>
                  <Button variant='primary' onClick={deleteHandler}>
                    Delete Blog
                  </Button>
                </div>
                <div style={{ float: 'right' }}>
                  <Button variant='primary' type='submit'>
                    Edit Blog
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Container>
  )
}

export default EditBlogScreen
