import React, { useState, useEffect } from 'react'
import { Form, Button, Col, Row, Container, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../../../components/Loader'
import Cookies from 'universal-cookie'
import { createSubscription } from '../../../actions/subscriptionActions'
import AppHeader from '../../../components/plots/AppHeader'

const cookies = new Cookies()

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay))
}
const BillingScreen = ({ history }) => {
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }
  const dispatch = useDispatch()
  const [isBox, setIsBox] = useState(false)
  const [codCost, setCodCost] = useState('')
  const [payButtonClick, setPayButtonClick] = useState(false)
  const [payS, SetPayS] = useState(true)
  const [processingOrder, setProcessingOrder] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [isCodeApplied, setIsCodeApplied] = useState(false)
  const [isCodeA, setIsCodeA] = useState(false)
  const [displayCartError, setDisplayCartError] = useState(false)
  const [couponCodeError, setCouponCodeError] = useState(false)
  const [isCodeB, setIsCodeB] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState('')
  const [codeShow, setCodeShow] = useState(false)
  const [show, setShow] = useState(false)
  const [phone, setPhone] = useState(false)
  const [disable, setDisable] = useState(false)
  const [email, setEmail] = useState(false)
  const [name, setName] = useState(false)
  const [sid, setSid] = useState('0')
  const [emailError, setEmailError] = useState(null)
  const [flag, setFlag] = useState(true)
  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success: orderSuccess, error } = orderCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cSubscription = useSelector((state) => state.createSubscription)
  const {
    loading,
    success: successSubscription,
    error: subcriptionError,
    subscription,
  } = cSubscription

  const uSubscription = useSelector((state) => state.updateSubscription)
  const {
    success: successSubscriptionUpdate,
    subscription: updatedSubs,
    loading: subUpdateLoading,
  } = uSubscription

  const paymentROrder = useSelector((state) => state.orderPayRazor)
  const {
    orderPayDetails,
    success: paySuccess,
    error: payError,
    loading: payLoading,
  } = paymentROrder

  const submitHandler = (e) => {
    e.preventDefault()

    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }

  var options = {
    key: 'rzp_live_04AG18531Cjx9B',
    //key: 'rzp_test_Uc74kK1ulMDQoS',
    currency: 'INR',
    name: '99bookscart',
    description: '99bookscart Plots',
    image: 'https://www.99bookscart.com/images/logo.png',

    handler: function (response) {
      setSid(response.razorpay_subscription_id)
      history.push(
        `/app/subscribed/${subscription.sid}/${response.razorpay_payment_id}/${response.razorpay_subscription_id}`
      )
      timeout(3000)
    },
    prefill: {
      name: userInfo.name,
      email: userInfo.email,
    },
    theme: {
      color: '#005d6e',
    },
    modal: {
      ondismiss: function () {
        setFlag(true)
        setDisable(false)
      },
    },
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    if (!disable) {
      setDisable(true)
      dispatch(createSubscription())
    }
  }
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (userInfo && userInfo.isSubscribed) {
        history.push('/app/home')
      }
    }
    window.scrollTo(0, 0)
    loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (successSubscription && flag && sid == '0') {
      setProcessingOrder(false)
      SetPayS(false)
      setFlag(false)
      options['subscription_id'] = subscription.id
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }
  }, [history, successSubscription])

  return (
    <>
      <Container>
        <AppHeader></AppHeader>
        {subUpdateLoading && !successSubscriptionUpdate && <Loader></Loader>}
        <Container>
          <Row>
            <div style={{ textAlign: 'center' }}>
              <br />
              <br />
              {/* <img
                src='../../images/logo.png'
                className='siteLogo2'
                alt='99bookscart logo'
              /> */}
              <span>
                Read Smart & Read Fast
                <br />
              </span>
              <h1 className='hero-text'>
                Understand Powerful Ideas in 15 minutes from 1000+ non fiction
                books
              </h1>
              <br />
              <h1 className='cart-header'>Level up yourself for ₹99/month.</h1>
              <br />
              {loading && !successSubscription && <Loader></Loader>}
              <Button
                type='submit'
                variant='primary'
                disable={disable ? disable : false}
                onClick={submitHandler2}
                className='subButton'
              >
                Start Subscription
              </Button>
              <br />
              <small>
                <br />
                Cancel your Subscription at any time and you won’t be charged
                next cycle.
              </small>
            </div>

            <div style={{ textAlign: 'center',marginBottom:'80px' }}>
              <br />
              <br />
              <Link to={'/app/home'}>
                <small>Back to Home</small>
              </Link>
              
            </div>
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default BillingScreen
