import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import BlogsMeta from '../../components/meta/BlogsMeta'
import { getBlog } from '../../actions/blogActions'
import Typography from '@mui/material/Typography'
import { Paper } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import AdHeaderBanner from '../../components/Ads/AdHeaderBanner'
import AdInArticle from '../../components/Ads/AdInArticle'
import VerticleSidebar from '../../components/Ads/VerticleSidebar'
import { ShareSocial } from 'react-share-social'
import BlogComment from '../../components/BlogComment'
import { convertToSlug2 } from '../../helpers/helpers' // Import from helpers

const chunkString = (str, length) => {
  const result = []
  for (let i = 0; i < str.length; i += length) {
    result.push(str.substring(i, i + length))
  }
  return result
}

const ViewBlogScreen = ({ match }) => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.userLogin)
  const { loading, error, success, blog } = useSelector(
    (state) => state.getBlog
  )

  const [date, setDate] = useState('')
  const [paragraphs, setParagraphs] = useState([])
  const [url, setUrl] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)

    if (success && blog) {
      setUrl(
        `https://www.99bookscart.com/blog/view/${blog._id}/${convertToSlug2(
          blog.title
        )}`
      )

      const date = new Date(blog.createdAt)
      setDate(
        `${date.getDate()} ${date.toLocaleString('default', {
          month: 'long',
        })}, ${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()} IST`
      )

      setParagraphs(chunkString(blog.description, 600))
    } else if (!success && match.params.id) {
      dispatch(getBlog(match.params.id))
    }
  }, [dispatch, success, blog, match.params.id])

  const blogTitle = useMemo(() => blog?.title, [blog])
  const blogImage = useMemo(() => blog?.image, [blog])

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <BlogsMeta blog={blog} />
          <Container>
            <AdHeaderBanner />
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' to='/blog'>
                Blog
              </Link>
              <Typography color='text.primary'>Post</Typography>
            </Breadcrumbs>

            {userInfo && userInfo.isAdmin && blog && (
              <Link to={`/blog/edit/${blog._id}`}>
                <Button>Edit</Button>
              </Link>
            )}

            <Col style={{ padding: '5px' }}>
              <h1>{blogTitle}</h1>
              <Typography variant='subtitle'>
                by <a href='#'>Pooja Rawath</a>. posted on {date}
              </Typography>
              <AdInArticle />
              <div className='mt-1 mobile-view' style={{ textAlign: 'center' }}>
                <img
                  src={blogImage}
                  alt={blogTitle}
                  width='320px'
                  height='180px'
                  className='blogimgnew'
                  loading='lazy'
                />
              </div>
              <div
                className='mt-1 desktop-view'
                style={{ textAlign: 'center' }}
              >
                <img
                  src={blogImage}
                  alt={blogTitle}
                  width='1200px'
                  height='674px'
                  className='blogimgnew'
                  loading='lazy'
                />
              </div>
              <Row>
                <Col sm={12} md={8}>
                  {paragraphs.map((para, index) => (
                    <Typography key={index}>
                      <div
                        className='blog-text'
                        style={{ color: '#000' }}
                        dangerouslySetInnerHTML={{ __html: para }}
                      />
                      <AdInArticle />
                      <AdInArticle />
                    </Typography>
                  ))}
                </Col>
                <Col sm={12} md={4}>
                  {[...Array(8)].map((_, i) => (
                    <VerticleSidebar key={i} />
                  ))}
                </Col>
              </Row>
              <ShareSocial
                title={blogTitle}
                url={url}
                socialTypes={[
                  'facebook',
                  'twitter',
                  'linkedin',
                  'reddit',
                  'email',
                ]}
              />
              <div>
                Tag:{' '}
                {blog.topic &&
                  blog.topic.map((topic) => (
                    <span className='topic' key={topic}>
                      {topic}
                    </span>
                  ))}
              </div>
              {blog && <BlogComment blog={blog} />}
            </Col>
            <Paper>
              <div style={{ padding: '10px' }}>
                <Typography variant='subtitle1' paragraph>
                  About Author
                </Typography>
                <Typography variant='subtitle2' paragraph>
                  Pooja Rawath is a passionate blogger and a book lover.
                  Graduated in Literature and public journalism, she is
                  currently pursuing her masters in English Literature. She has
                  a keen interest in writing and reading novels. She loves to
                  write about the latest books and about the book industry.
                </Typography>
                <Typography variant='subtitle2' paragraph>
                  <Link to='/contact-us'>Pooja Rawath</Link>
                </Typography>
              </div>
            </Paper>
          </Container>
        </>
      )}
    </>
  )
}

export default ViewBlogScreen
