import axios from 'axios'

import {
  CREATE_BOOK_FAIR_ENTRY_FAIL,
  CREATE_BOOK_FAIR_ENTRY_REQUEST,
  CREATE_BOOK_FAIR_ENTRY_SUCCEES,
  LIST_BOOK_FAIR_ENTRY_FAIL,
  LIST_BOOK_FAIR_ENTRY_REQUEST,
  LIST_BOOK_FAIR_ENTRY_STATS_FAIL,
  LIST_BOOK_FAIR_ENTRY_STATS_REQUEST,
  LIST_BOOK_FAIR_ENTRY_STATS_SUCCEES,
  LIST_BOOK_FAIR_ENTRY_SUCCEES,
} from '../constants/bookFairEntryConstants'

export const createNewBookFairEntry =
  (bookfairEntry) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_BOOK_FAIR_ENTRY_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.post(
        `/api/bookfairentry`,
        bookfairEntry,
        config
      )
      dispatch({
        type: CREATE_BOOK_FAIR_ENTRY_SUCCEES,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CREATE_BOOK_FAIR_ENTRY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listBookFEntries =
  (id, pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LIST_BOOK_FAIR_ENTRY_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(
        `/api/bookfairentry/list/${id}/${pageNumber}`,
        config
      )
      dispatch({
        type: LIST_BOOK_FAIR_ENTRY_SUCCEES,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: LIST_BOOK_FAIR_ENTRY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getBookFEntriesStats = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LIST_BOOK_FAIR_ENTRY_STATS_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(
      `/api/bookfairentry/list/${id}/stats`,
      config
    )
    dispatch({
      type: LIST_BOOK_FAIR_ENTRY_STATS_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LIST_BOOK_FAIR_ENTRY_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
