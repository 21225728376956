import {
  CREATE_SUBSCRIPTION_FAIL,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCEES,
  LIST_SUBSCRIPTION_FAIL,
  LIST_SUBSCRIPTION_REQUEST,
  LIST_SUBSCRIPTION_SUCCEES,
  UPDATE_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCEES,
} from '../constants/subscriptionConstants'

export const createSubscriptionReducer = (
  state = { subscription: {}, success: false },
  action
) => {
  switch (action.type) {
    case CREATE_SUBSCRIPTION_REQUEST:
      return {
        loading: true,
      }
    case CREATE_SUBSCRIPTION_SUCCEES:
      return {
        loading: false,
        success: true,
        subscription: action.payload,
      }
    case CREATE_SUBSCRIPTION_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const updateSubscriptionReducer = (
  state = { subscription: {}, success: false },
  action
) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_REQUEST:
      return {
        loading: true,
      }
    case UPDATE_SUBSCRIPTION_SUCCEES:
      return {
        loading: false,
        success: true,
        subscription: action.payload,
      }
    case UPDATE_SUBSCRIPTION_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const listSubscriptionReducer = (
  state = { subscriptions: [], success: false },
  action
) => {
  switch (action.type) {
    case LIST_SUBSCRIPTION_REQUEST:
      return {
        loading: true,
      }
    case LIST_SUBSCRIPTION_SUCCEES:
      return {
        loading: false,
        success: true,
        subscriptions: action.payload,
      }
    case LIST_SUBSCRIPTION_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
