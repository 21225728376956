import React from 'react'
import { Helmet } from 'react-helmet'
import { convertToSlug2 } from '../../../helpers/helpers'
const BookMeta = ({ story }) => {
  // const slug = convertToSlug(product.name)
  var slug = ''
  if (story.title) {
    slug = convertToSlug(story.title)
  }

  const title = story.title
  const author = story.user && story.user.name
  const image = story.coverImage

  const url = `https://www.99bookscart.com/blog/${story._id}/${convertToSlug2(
    story.title
  )}`
  const baseUrl = 'https://www.99bookscart.com/blog'
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': 'https://www.99bookscart.com/#organization',
        name: 'www.99bookscart.com.com',
        url: 'https://www.99bookscart.com.com',
        logo: {
          '@type': 'ImageObject',
          '@id': 'https://www.99bookscart.com.com/#logo',
          url: 'https://www.99bookscart.com/images/logo.png',
          contentUrl: 'https://www.99bookscart.com/images/logo.png',
          caption: 'www.99bookscart.com',
          inLanguage: 'en-GB',
          width: '250',
          height: '66',
        },
      },
      {
        '@type': 'WebSite',
        '@id': 'https://www.99bookscart.com/#website',
        url: 'https://www.99bookscart.com',
        name: 'www.99bookscart.com',
        alternateName: 'bookscart',
        publisher: { '@id': 'https://www.99bookscart.com/#organization' },
        inLanguage: 'en-GB',
      },
      {
        '@type': 'ImageObject',
        '@id': image,
        url: image,
        width: '1200',
        height: '675',
        caption: title,
        inLanguage: 'en-GB',
      },
      {
        '@type': 'WebPage',
        '@id': url,
        url: url,
        name: title,
        datePublished: story.createdAt,
        dateModified: story.updatedAt,
        isPartOf: { '@id': 'https://www.99bookscart.com/#website' },
        primaryImageOfPage: {
          '@id': image,
        },
        inLanguage: 'en-GB',
      },
      {
        '@type': 'Person',
        '@id': 'https://www.99bookscart.com/contact-us',
        name: author,
        url: 'https://www.99bookscart.com/contact-us',
        image: {
          '@type': 'ImageObject',
          '@id':
            'https://secure.gravatar.com/avatar/7ff6461073df442da4c8d1d80556c9d4?s=96&amp;d=mm&amp;r=g',
          url: 'https://secure.gravatar.com/avatar/7ff6461073df442da4c8d1d80556c9d4?s=96&amp;d=mm&amp;r=g',
          caption: author,
          inLanguage: 'en-GB',
        },
        sameAs: ['https://www.99bookscart.com/'],
        worksFor: { '@id': 'https://www.99bookscart.com/#organization' },
      },
      {
        '@type': 'Article',
        headline: title,
        image: image,
        datePublished: story.createdAt,
        dateModified: story.updatedAt,
        articleBody: story.description,
        articleSection: 'Books',
        author: [
          {
            '@type': 'Person',
            name: author,
            url: 'https://www.99bookscart.com/contact-us',
          },
        ],
        publisher: {
          '@type': 'Organization',
          name: '99bookscart',
          logo: {
            '@type': 'ImageObject',
            url: 'https://www.99bookscart.com/images/logo.png',
          },
        },
      },
    ],
  }
  return (
    <Helmet>
      <title>{`Read Key Ideas from book ${story.title} by ${
        story.user && story.user.name
      }! Read Smart & Read Fast`}</title>
      <meta charset='UTF-8' />

      <meta
        property='og:title'
        content={`Read Key Ideas from book ${story.title} by ${
          story.user && story.user.name
        }! Read Smart & Read Fast`}
      />

      {/* <head prefix='book: https://ogp.me/ns/book#'></head>
      <meta property='og:type' content='book:isbn' />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={product.image} />
      <meta property='og:description' content={product.description} />
      <meta property='og:site_name' content='99bookscart' /> */}
      <meta property='product:brand' content='99bookscart' />
      <meta property='product:availability' content='in stock' />
      <meta property='product:condition' content='new' />
      <meta property='product:price:amount' content={249} />
      <meta property='product:price:currency' content='INR' />
      <meta
        name='title'
        content={`Read Key Ideas from book ${story.title} by ${
          story.user && story.user.name
        }! Read Smart & Read Fast`}
      ></meta>
      <meta
        name='description'
        content={`10 Key Ideas from ${story.title} by ${
          story.user && story.user.name
        }! Read Smart & Read Fast`}
      ></meta>
      <meta name='keyword' content={story.title}></meta>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0'
      ></meta>
      <link rel='canonical' href={url}></link>
      <meta name='robots' content='index, follow' />
      <script className='structured-data-list' type='application/ld+json'>
        {JSON.stringify(articleStructuredData)}
      </script>
    </Helmet>
  )
}

function convertToSlug(string) {
  return string
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default BookMeta
