import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, listStoryByCat } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { makeTitle } from '../../../helpers/helpers'
import ReactHtmlParser from 'react-html-parser'
import Loader from '../../../components/Loader'
import AppStory from '../../../components/AppStory'
import AppHeader from '../../../components/plots/AppHeader'
import AppPlotListPaginate from '../../../components/plots/AppPlotListPaginate'
const AppPlotListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const category = match.params.category || ''
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const storybyCat = useSelector((state) => state.storybyCat)
  const { stories, loading, error, suceess, pages, page } = storybyCat

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    } else {
      if (!userLoggedIn.isSubscribed) {
        //history.push('/app/profile/billing')
      }
    }
    dispatch(listStoryByCat(category, pageNumber))
  }, [dispatch, pageNumber, category, history])
  return (
    <>
      <Container>
        <AppHeader></AppHeader>
        {loading && !suceess ? (
          <Loader></Loader>
        ) : (
          <Container>
            <h1>{makeTitle(category)} Books</h1>
            <Row>
              {stories &&
                stories.map((story) => (
                  <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                    <Route
                      render={({ history }) => (
                        <AppStory history={history} story={story} />
                      )}
                    />
                  </Col>
                ))}
              <AppPlotListPaginate
                pages={pages}
                page={page}
                category={match.params.category}
              ></AppPlotListPaginate>
            </Row>
          </Container>
        )}
      </Container>
    </>
  )
}
{
}

export default AppPlotListScreen
