import React, { useEffect } from 'react'
import { Button, Row, Form, Container, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { commentEpisode, listCommentEpisode } from '../actions/episodeActions'
import Loader from './Loader'

const BlogComment = ({ blog }) => {
  const dispatch = useDispatch()
  const commentEpisodeRed = useSelector((state) => state.commentEpisode)
  const { suceess } = commentEpisodeRed

  const getBlogCommentRes = useSelector((state) => state.getBlogComment)
  const {
    comments,
    loading: commentLoading,
    suceess: commentSuccess,
  } = getBlogCommentRes

  const [comment, setComment] = React.useState('')
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const commentSubmitHandler = (e) => {
    e.preventDefault()
    dispatch()
    // commentEpisode({ comment, episode: episode._id, story: episode.story })
    setComment('')
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!commentSuccess) {
      dispatch(listCommentEpisode(blog._id))
    }
  }, [commentSuccess, blog, dispatch])
  return (
    <Container>
      <Col sm={6}>
        <Row>
          <h2>Leave a Comment</h2>
          <Form onSubmit={commentSubmitHandler}>
            <Form.Group className='mb-3' controlId='name'>
              <Form.Label>
                <h3>Your Name</h3>
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='Full Name'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{color:'#000'}}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='email'>
              <Form.Label>
                <h3>Email</h3>
              </Form.Label>
              <Form.Control
                type='email'
                placeholder='Email'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='comment'>
              <Form.Label>
                <h3>Leave Your Comment</h3>
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='thoughts on this chapter'
                required
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Group>
            <Button className='commentbutton' type='submit'>
              Comment
            </Button>
          </Form>
          {suceess && <div className='red-text'>Comment Added</div>}
        </Row>
      </Col>
      <Col sm={6}>
        <Row style={{ padding: '10px' }}>
          {commentLoading ? (
            <Loader></Loader>
          ) : (
            comments.map((comment) => (
              <div className='storycomments' key={comment._id}>
                <strong>{comment.user.name}</strong> says {comment.comment}
              </div>
            ))
          )}
        </Row>
      </Col>
    </Container>
  )
}

export default BlogComment
