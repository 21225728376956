import axios from "axios"
import { USER_COIN_FAIL, USER_COIN_REQUEST, USER_COIN_SUCESS } from "../constants/coinConstants"


export const getUserCoins =()=>async(dispatch,getState)=>{
    try{
        dispatch({
            type:USER_COIN_REQUEST
        })  
        const {userLogin:{userInfo}} = getState()
        const config ={
            headers:{
                'Content-Type':'application/json',
                Authorization:`Bearer ${userInfo.token}`
            }
        }
       const {data} = await axios.get(`/api/users/coins`,config)
        
        dispatch({
            type:USER_COIN_SUCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:USER_COIN_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}