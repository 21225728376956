import {
  BLOG_CREATET_SUCCESS,
  BLOG_CREATE_FAIL,
  BLOG_CREATE_REQUEST,
  BLOG_GET_FAIL,
  BLOG_GET_REQUEST,
  BLOG_GET_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
} from '../constants/blogConstants'
import {
  INVENTORY_APPROVE_REQUEST,
  INVENTORY_APPROVE_SUCCESS,
  INVENTORY_CREATET_SUCCESS,
  INVENTORY_CREATE_REQUEST,
  INVENTORY_DELETE_FAIL,
  INVENTORY_DELETE_REQUEST,
  INVENTORY_DELETE_SUCCESS,
  INVENTORY_GET_FAIL,
  INVENTORY_GET_REQUEST,
  INVENTORY_GET_SUCCESS,
  INVENTORY_LIST_FAIL,
  INVENTORY_LIST_REQUEST,
  INVENTORY_LIST_SUCCESS,
  INVENTORY_UPDATE_FAIL,
  INVENTORY_UPDATE_REQUEST,
  INVENTORY_UPDATE_SUCCESS,
  INVENTORY_CREATE_RESET,
  INVENTORY_LIST_SELLER_REQUEST,
  INVENTORY_LIST_SELLER_SUCCESS,
  INVENTORY_LIST_SELLER_FAIL,
} from '../constants/inventoryConstants'

export const createInventoryReducer = (state = {}, action) => {
  switch (action.type) {
    case INVENTORY_CREATE_REQUEST:
      return {
        loading: true,
      }
    case INVENTORY_CREATET_SUCCESS:
      return {
        loading: false,
        success: true,
        blog: action.payload,
      }
    case INVENTORY_CREATET_SUCCESS:
      return {
        loading: false,
        error: action.payload,
      }
    case INVENTORY_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const approveInventoryReducer = (state = {}, action) => {
  switch (action.type) {
    case INVENTORY_APPROVE_REQUEST:
      return {
        loading: true,
      }
    case INVENTORY_APPROVE_SUCCESS:
      return {
        loading: false,
        success: true,
        blog: action.payload,
      }
    case INVENTORY_APPROVE_SUCCESS:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const getInventoryReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case INVENTORY_GET_REQUEST:
      return {
        loading: true,
      }
    case INVENTORY_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        inventory: action.payload,
      }
    case INVENTORY_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const updateInventoryReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case INVENTORY_UPDATE_REQUEST:
      return {
        loading: true,
      }
    case INVENTORY_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        inventory: action.payload,
      }
    case INVENTORY_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const deleteInventoryReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case INVENTORY_DELETE_REQUEST:
      return {
        loading: true,
      }
    case INVENTORY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        inventory: action.payload,
      }
    case INVENTORY_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const sellerInventoryReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case INVENTORY_LIST_SELLER_REQUEST:
      return {
        loading: true,
        products: [],
      }
    case INVENTORY_LIST_SELLER_SUCCESS:
      return {
        loading: false,
        success: true,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case INVENTORY_LIST_SELLER_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const listInventoryReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case INVENTORY_LIST_REQUEST:
      return {
        loading: true,
      }
    case INVENTORY_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case INVENTORY_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
