export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST'
export const CREATE_SUBSCRIPTION_SUCCEES = 'CREATE_SUBSCRIPTION_SUCCEES'
export const CREATE_SUBSCRIPTION_FAIL = 'CREATE_SUBSCRIPTION_FAIL'

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST'
export const UPDATE_SUBSCRIPTION_SUCCEES = 'UPDATE_SUBSCRIPTION_SUCCEES'
export const UPDATE_SUBSCRIPTION_FAIL = 'UPDATE_SUBSCRIPTION_FAIL'

export const LIST_SUBSCRIPTION_REQUEST = 'LIST_SUBSCRIPTION_REQUEST'
export const LIST_SUBSCRIPTION_SUCCEES = 'LIST_SUBSCRIPTION_SUCCEES'
export const LIST_SUBSCRIPTION_FAIL = 'LIST_SUBSCRIPTION_FAIL'
