import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Row, Col, Image, Modal } from 'react-bootstrap'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'

import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { login, forgotPassword } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { Helmet } from 'react-helmet'
const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remail, setRemail] = useState('')
  const [showResetPassMessage, setShowResetPassMessage] = useState('')
  const [showMainForm, setShowMainForm] = useState(true)
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const forgotPasswordRed = useSelector((state) => state.forgotPassword)
  const {
    loading: fploading,
    error: fperror,
    result,
    success: fpsuccess,
  } = forgotPasswordRed

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userInfo) {
      history.push(redirect)
    }
    if (fpsuccess) {
      if (result.error && result.errorMessage) {
        setShowResetPassMessage(result.errorMessage)
      } else {
        setShowResetPassMessage('Password Reset Email has been Sent')
        setRemail('')
      }
    }
  }, [history, userInfo, redirect, fpsuccess])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }
  const forgotPasswordHandler = (e) => {
    e.preventDefault()
    setShow(true)
  }
  const handleClose = (e) => {
    setShow(false)
  }
  const forgotpasswordsubmitHandler = (e) => {
    e.preventDefault()
    dispatch(forgotPassword({ email: remail }))
  }
  return (
    <Container>
      <Helmet>
        <title>Login</title>
        <meta charset='UTF-8' />
        <meta
          name='title'
          content={'Build Your Personal Library With Our Online Book Fair'}
        ></meta>
        <meta
          name='description'
          content={
            'Discover the joy of reading with our online book fair! Curate your own book box and choose any 7 books for just ₹999/-. Start building your personal library today'
          }
        ></meta>
        <meta
          name='keyword'
          content={('Online Book Fair', 'Your Own Book Box')}
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        ></meta>
        <link rel='canonical' href={'https://www.99bookscart.com/login'}></link>
      </Helmet>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Row>
          <Col md={12}>
            {showMainForm && (
              <div style={{ padding: '10px' }}>
                <>
                  <div style={{ textAlign: 'center', marginBottom: '8px' }}>
                    <Avatar
                      sx={{ m: 1 }}
                      style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    >
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component='h1' variant='h1'>
                      Sign in
                    </Typography>
                  </div>
                  {error && <div className='red-text'>{error}</div>}
                  {/* {loading && <Loader></Loader>} */}
                  <form onSubmit={submitHandler}>
                    <TextField
                      id='outlined-basic'
                      label='Email Address'
                      variant='outlined'
                      required
                      type='Email'
                      autoComplete='off'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ width: '100%', marginBottom: '10px' }}
                    />

                    <TextField
                      id='outlined-basic'
                      label='Password'
                      variant='outlined'
                      required
                      type='Password'
                      autoComplete='off'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ width: '100%', marginBottom: '10px' }}
                    />

                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      className='theme-button'
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Sign In
                    </Button>
                  </form>
                  <br />
                </>
                <Grid container style={{ fontSize: '14px' }}>
                  <Grid item xs>
                    <Link
                      to={''}
                      onClick={(e) => forgotPasswordHandler(e)}
                      variant='body2'
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      to={'/register'}
                      variant='body2'
                      className='text-center underline'
                    >
                      Don't have a login? Sign Up
                    </Link>
                    <br />
                    <Link
                      to={'/seller/register'}
                      variant='body2'
                      className='text-center'
                      style={{ color: '#fff' }}
                    >
                      <Button
                        className='theme-button'
                        style={{ color: '#fff', marginTop: '10px' }}
                      >
                        Register as Seller{' '}
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            )}
          </Col>
        </Row>
      </Box>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Row>
            <Col>
              <Button
                variant='secondary'
                onClick={handleClose}
                style={{ float: 'right' }}
              >
                X
              </Button>
            </Col>
          </Row>

          <form
            onSubmit={forgotpasswordsubmitHandler}
            className='cart-form'
            style={{ padding: '22px' }}
          >
            <Typography variant='h6'>Forgot Password?</Typography>
            <Typography variant='body2'>
              Enter your email to reset password
            </Typography>
            {showResetPassMessage && (
              <span className='red-text'>{showResetPassMessage}</span>
            )}

            <TextField
              id='outlined-basic'
              label='Email Address'
              variant='outlined'
              required
              type='Email'
              autoComplete='off'
              value={remail || ''}
              onChange={(e) => setRemail(e.target.value)}
              style={{ width: '100%', marginBottom: '10px', marginTop: '15px' }}
              className='theme-button'
            />
            <Button
              type='submit'
              variant='contained'
              fullWidth
              className='theme-button'
            >
              Send Password Reset Link
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default LoginScreen
