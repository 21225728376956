import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Image, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { login } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import { submitSubmission } from '../actions/submissionActions'
import { FORM_ADD_RESET } from '../constants/formConstants'
import { SUBMISSION_ADD_RESET } from '../constants/submissionConstants'
import axios from 'axios'

const SubmissionScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [coverPage, setCoverPage] = useState('')
  const [ebookFile, setEbookFile] = useState('')
  const [author, setAuthor] = useState('')
  const [publisher, setPublisher] = useState('')
  const [publishingYear, setPublishingYear] = useState('')
  const [pages, setPages] = useState('')
  const [genre, setGenre] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [uploadingCoverPage, setUploadingCoverPage] = useState('')
  const [uploadingEbookFile, setUploadingEbookFile] = useState('')
  const [errorEbookFile, setErrorEbookFile] = useState(false)
  const [errorCoverPage, setErrorCoverPage] = useState(false)

  const dispatch = useDispatch()
  const addToSubmission = useSelector((state) => state.addToSubmission)
  const { loading, error, success, submission } = addToSubmission

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!loading) {
      dispatch({ type: SUBMISSION_ADD_RESET })
      setName('')
      setEmail('')
      setContact('')
      setTitle('')
      setDescription('')
      setCoverPage('')
      setEbookFile('')
      setAuthor('')
      setPublisher('')
      setPublishingYear('')
      setPages('')
      setGenre('')
    }
  }, [dispatch, success, loading])

  const contactSubmitHandler = (e) => {
    e.preventDefault()
    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(contact)) {
      dispatch(
        submitSubmission({
          name,
          email,
          contact,
          title,
          description,
          coverPage,
          ebookFile,
          author,
          publisher,
          publishingYear,
          pages,
          genre,
        })
      )
    } else {
      setPhoneError('Enter Valid Phone Number')
    }
  }
  const coverPageUploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploadingCoverPage(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setCoverPage(data)
      setUploadingCoverPage(false)
    } catch (error) {
      setErrorCoverPage(true)
      setUploadingCoverPage(false)
    }
  }
  const ebookUploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploadingEbookFile(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload/epub', formData, config)

      setEbookFile(data)
      setUploadingEbookFile(false)
    } catch (error) {
      setErrorEbookFile(true)
      setUploadingEbookFile(false)
    }
  }

  return (
    <Container>
      <FormContainer>
        <h1>Submit your ebooks to 99bookscart</h1>
        <div className='themecolor'>
          <strong>Note:</strong> Only Authorised entities like Author,
          Publishers or Distributor can submit Ebooks for Sale
        </div>
        {!loading && <Message variant='success'>Submission Successful</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {/* {loading && <Loader></Loader>} */}
        <Form onSubmit={contactSubmitHandler}>
          <Row>
            <Col md={6}>
              <Form.Group controlId='name'>
                {/* <Form.Label>
                        Name
                    </Form.Label> */}
                <Form.Control
                  type='text'
                  placeholder='Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='email'>
                {/* <Form.Label>
                        Email Address
                    </Form.Label> */}
                <Form.Control
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='contact'>
                {/* <Form.Label>
                        Password
                    </Form.Label> */}
                <Form.Text className='text-muted'>
                  {phoneError && <span>{phoneError}</span>}
                </Form.Text>
                <Form.Control
                  type='text'
                  placeholder='Phone Number'
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='book-title'>
                <Form.Control
                  type='text'
                  placeholder='Book Title'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='description'>
                <Form.Control
                  type='text'
                  placeholder='Book Descritpion'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='image'>
                <Form.Label className='themecolor'>Cover Page</Form.Label>
                <Form.Control
                  type='hidden'
                  value={coverPage}
                  onChange={(e) => setCoverPage(e.target.value)}
                ></Form.Control>
                <Form.File
                  id='image-file'
                  custom
                  onChange={coverPageUploadHandler}
                ></Form.File>
                {errorCoverPage && (
                  <small className='themecolor'>Only Images Allowed</small>
                )}
                {uploadingCoverPage && <Loader></Loader>}
              </Form.Group>
              <Form.Group controlId='image'>
                <Form.Label className='themecolor'>
                  Ebook File (Epub only)
                </Form.Label>
                <Form.Control
                  type='hidden'
                  value={ebookFile}
                  onChange={(e) => setEbookFile(e.target.value)}
                ></Form.Control>
                <Form.File
                  id='image-file'
                  custom
                  onChange={ebookUploadHandler}
                ></Form.File>
                {errorEbookFile && (
                  <small className='themecolor'>
                    Upload Failed! Only Epub files are allowed
                  </small>
                )}
                {uploadingEbookFile && <Loader></Loader>}
              </Form.Group>
              <br />
            </Col>
            <Col md={6}>
              <Form.Group controlId='author'>
                <Form.Control
                  type='text'
                  placeholder='Author Name'
                  value={author}
                  name='author'
                  onChange={(e) => setAuthor(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='publisher'>
                <Form.Control
                  type='text'
                  placeholder='Publisher Name'
                  value={publisher}
                  name='publisher'
                  onChange={(e) => setPublisher(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='pages'>
                <Form.Control
                  type='text'
                  placeholder='Pages'
                  value={pages}
                  name='pages'
                  onChange={(e) => setPages(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='publishingYear'>
                <Form.Control
                  type='text'
                  placeholder='Published Year'
                  value={publishingYear}
                  name='publishingYear'
                  onChange={(e) => setPublishingYear(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='genre'>
                <Form.Control
                  type='text'
                  placeholder='Genre ex: Romance,Crime,...'
                  value={genre}
                  name='genre'
                  onChange={(e) => setGenre(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <br />
              <div>Terms and Condition</div>
              <br />
              <Button type='submit' varaint='primary'>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </FormContainer>
    </Container>
  )
}

export default SubmissionScreen
