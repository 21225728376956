export function getCategory(id) {
  const categoryArray = [
    'Life Story',
    'Short Story',
    'Random',
    'Action',
    'Adventure',
    'Biography',
    'Erotica',
    'Fantasy',
    'General Fiction',
    'Historical Fiction',
    'Horror',
    'Health',
    'Mystery',
    'Non-Fiction',
    'Poetry',
    'Science Fiction',
    'Spiritual',
    'Self Help',
    'Travel',
    'Classic',
    'Literature',
  ]
  return categoryArray[id]
}

export function storyStatus(id) {
  const categoryArray = ['Draft', 'On Going', 'Completed']
  return categoryArray[id]
}

export function timeConvert(n) {
  var num = n
  if (n < 60) {
    return n + 'm'
  }
  var hours = num / 60
  var rhours = Math.floor(hours)
  var minutes = (hours - rhours) * 60
  var rminutes = Math.round(minutes)
  return rhours + 'h ' + rminutes + 'm'
}
export function myFunction(text) {
  try {
    // Normalize the string to remove accents and diacritics
    let normalizedText =
      text && text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

    // Replace non-word characters and underscores with a dash
    let slug =
      normalizedText &&
      normalizedText
        .replace(/[\W_]+/g, '-')
        .toLowerCase() // Convert to lowercase
        .trim() // Remove leading and trailing spaces
        .replace(/^-+|-+$/g, '') // Remove leading and trailing dashes

    return slug
  } catch (error) {
    console.error('Error converting to slug:', error)
    throw error
  }
}
export function timeConvertSummary(n) {
  var num = n / 200
  if (num < 60) {
    return Math.ceil(num) + ' minutes'
  }
  var hours = num / 60
  var rhours = Math.floor(hours)
  var minutes = (hours - rhours) * 60
  var rminutes = Math.round(minutes)
  return rhours + 'hours ' + rminutes + 'minutes'
}
export function convertToSlug(Text) {
  return (
    Text &&
    Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  )
}
export function slugifyURL(str) {
  return (
    str &&
    str
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-')
  )
}
export function convertToSlug2(Text) {
  return Text.toLowerCase().replace(/ /g, '-')
}
export function makeTitle(slug) {
  var words = slug.split('-')

  for (var i = 0; i < words.length; i++) {
    var word = words[i]
    words[i] = word.charAt(0).toUpperCase() + word.slice(1)
  }

  return words.join(' ')
}
export function isWebview() {
  const userAgent = window.navigator.userAgent.toLowerCase()

  return userAgent.includes('wv') ? true : false
}
