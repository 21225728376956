import React, { useState } from 'react'
import { Form, Button, Container } from 'react-bootstrap'
import { Row, Col, Card, ListGroupItem } from 'react-bootstrap'
import TextField from '@mui/material/TextField'

const BookSearch = ({ history }) => {
  const [keyword, setKeyword] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      history.push(`/seller/books?keyword=${keyword}`)
    } else {
      history.push('/')
    }
  }
  return (
    <Container>
      <Form onSubmit={submitHandler} inline>
        <Row className='text-center'>
          <Col xs={10}>
            <TextField
              id='outlined-basic'
              label='Enter Title'
              variant='outlined'
              required
              type='Text'
              autoComplete='off'
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Col>
          <Col xs={2}>
            <Button
              type='submit'
              variant='contained'
              style={{ marginTop: '6px', float: 'left' }}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default BookSearch
