import React from 'react'
import { Container, Spinner } from 'react-bootstrap'
const Loader = () => {
  return (
    <Container style={{height:'700px'}}>
      <Spinner
        animation='grow'
        role='status'
        style={{
          width: '60px',
          height: '60px',
          margin: 'auto',
          display: 'block',
          color: '#F34C19',
        }}
      >
        {/* <span className='sr-only'>Loading</span> */}
      </Spinner>
    </Container>
  )
}

export default Loader
