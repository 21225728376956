import { USER_WISHLIST_REMOVE_SUCESS,USER_INTREST_PRODUCT_REQUEST,USER_INTREST_ADD_FAIL, USER_INTREST_ADD_REQUEST, USER_INTREST_ADD_SUCESS, USER_INTREST_LIST_FAIL, USER_INTREST_LIST_REQUEST, USER_INTREST_LIST_SUCESS, USER_INTREST_PRODUCT_FAIL, USER_INTREST_PRODUCT_SUCESS, USER_READ_ADD_FAIL, USER_READ_ADD_REQUEST, USER_READ_ADD_SUCESS, USER_READ_LIST_FAIL, USER_READ_LIST_REQUEST, USER_READ_LIST_SUCESS, USER_READ_PRODUCT_FAIL, USER_READ_PRODUCT_REQUEST, USER_READ_PRODUCT_SUCESS, USER_WISHLIST_ADD_FAIL, USER_WISHLIST_ADD_REQUEST, USER_WISHLIST_ADD_SUCESS, USER_WISHLIST_LIST_REQUEST, USER_WISHLIST_LIST_SUCESS, USER_WISHLIST_REMOVE_REQUEST, USER_WISHLIST_REMOVE_FAIL, USER_INTREST_REMOVE_REQUEST, USER_INTREST_REMOVE_SUCESS, USER_INTREST_REMOVE_FAIL, USER_READ_REMOVE_REQUEST, USER_READ_REMOVE_SUCESS, USER_READ_REMOVE_FAIL } from "../constants/wishlistConstants"


export const wishlistAddReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_WISHLIST_ADD_REQUEST:
            return {
                loading: true
            }
        case USER_WISHLIST_ADD_SUCESS:
            return {
                loading: false,
                success:true,
                wishlistProducts: action.payload
            }
        case USER_WISHLIST_ADD_FAIL:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}

export const wishlistGetReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_WISHLIST_LIST_REQUEST:
            return {
                loading: true
            }
        case USER_WISHLIST_LIST_SUCESS:
            return {
                loading: false,
                success:true,
                wishlists: action.payload
            }
        case USER_WISHLIST_LIST_SUCESS:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}

export const intrestAddReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_INTREST_ADD_REQUEST:
            return {
                loading: true
            }
        case USER_INTREST_ADD_SUCESS:
            return {
                loading: false,
                success:true,
                intrest: action.payload
            }
        case USER_INTREST_ADD_FAIL:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}

export const intrestGetReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_INTREST_LIST_REQUEST:
            return {
                loading: true
            }
        case USER_INTREST_LIST_SUCESS:
            return {
                loading: false,
                success:true,
                intrests: action.payload
            }
        case USER_INTREST_LIST_FAIL:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}
export const getIntrestsByProductReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_INTREST_PRODUCT_REQUEST:
            return {
                loading: true
            }
        case USER_INTREST_PRODUCT_SUCESS:
            return {
                loading: false,
                success:true,
                intrestsCount: action.payload
            }
        case USER_INTREST_PRODUCT_FAIL:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}
export const getAlreadyReadsByProductReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_READ_PRODUCT_REQUEST:
            return {
                loading: true
            }
        case USER_READ_PRODUCT_SUCESS:
            return {
                loading: false,
                success:true,
                alreadyreadsCount: action.payload
            }
        case USER_READ_PRODUCT_FAIL:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}

export const alreadyReadAddReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_READ_ADD_REQUEST:
            return {
                loading: true
            }
        case USER_READ_ADD_SUCESS:
            return {
                loading: false,
                success:true,
                alreadyRead: action.payload
            }
        case USER_READ_ADD_FAIL:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}

export const alreadyReadGetReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_READ_LIST_REQUEST:
            return {
                loading: true
            }
        case USER_READ_LIST_SUCESS:
            return {
                loading: false,
                success:true,
                alreadyReads: action.payload
            }
        case USER_READ_LIST_FAIL:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}

export const wishlistRemoveReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_WISHLIST_REMOVE_REQUEST:
            return {
                loading: true
            }
        case USER_WISHLIST_REMOVE_SUCESS:
            return {
                loading: false,
                success:true,
                removedWishlist: action.payload
            }
        case USER_WISHLIST_REMOVE_FAIL:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}

export const intrestRemoveReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_INTREST_REMOVE_REQUEST:
            return {
                loading: true
            }
        case USER_INTREST_REMOVE_SUCESS:
            return {
                loading: false,
                success:true,
                removedIntrest: action.payload
            }
        case USER_INTREST_REMOVE_FAIL:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}
export const alreadyReadRemoveReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_READ_REMOVE_REQUEST:
            return {
                loading: true
            }
        case USER_READ_REMOVE_SUCESS:
            return {
                loading: false,
                success:true,
                removedAlreadyRead: action.payload
            }
        case USER_READ_REMOVE_FAIL:
            return {
                loading: false,
                success:false,
                error: action.payload
            }
        default:
            return state
    }
}

