import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup } from 'react-bootstrap'
import Product from '../components/Product'
import axios from 'axios'
import { listProducts } from '../actions/productActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import CPaginate from '../components/CPaginate'
import { Container } from 'react-bootstrap'
import ProductCarosel from '../components/ProductCarosel'
import Meta from '../components/Meta'
import BoxProgressBar from '../components/BoxProgressBar'
import BoxCarosel from '../components/BoxCarosel'
import { listProductsByAuthor } from '../actions/categoryActions'

const AuthorProductScreen = ({ match }) => {
  const pageNumber = match.params.pageNumber || 1
  const author = match.params.author || ''
  const authorProduct = useSelector((state) => state.authorProduct)
  const {
    loading,
    error,
    products: authorProducts,
    pages,
    page,
  } = authorProduct

  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(listProductsByAuthor(author.replace('-', ' '), pageNumber, false))
  }, [dispatch, author, pageNumber, match])
  return (
    <Container>
      <Helmet>
        <title>
          Buy Best {author.replace(/-/g, ' ')} Books Online at Best Price in
          India
        </title>

        <meta charset='UTF-8' />
        <meta
          name='title'
          content={
            'Buy Best' +
            match.params.author.replace(/-/g, ' ') +
            'Books Online at Best Price in India'
          }
        ></meta>
        <meta
          name='description'
          content={
            'Buy Best ' +
            match.params.author.replace(/-/g, ' ') +
            'secondhand Books online'
          }
        ></meta>
        <meta
          name='keyword'
          content={
            ('second hand ' + match.params.author.replace(/-/g, ' ') + ' books',
            'second hand ' +
              match.params.author.replace(/-/g, ' ') +
              ' books online')
          }
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        ></meta>
        {/* <link rel='canonical' href={url}></link> */}
        <meta name='robots' content='index, follow' />
      </Helmet>{' '}
      <h1>{match.params.author.replace(/-/g, ' ')} books</h1>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>
          <h2>error</h2>
        </Message>
      ) : (
        <>
          <Container>
            <Row>
              {authorProducts.map((product) => (
                <Col
                  key={product._id}
                  sm={4}
                  md={4}
                  lg={2}
                  xs={4}
                  className='product-grid-size'
                >
                  <Route
                    render={({ history }) => (
                      <Product history={history} product={product} />
                    )}
                  />
                </Col>
              ))}

              <CPaginate
                pages={pages}
                page={page}
                category={match.params.category}
              ></CPaginate>
            </Row>
          </Container>
        </>
      )}
    </Container>
  )
}

export default AuthorProductScreen
