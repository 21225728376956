import {
  CREATE_BOOK_FAIR_ENTRY_FAIL,
  CREATE_BOOK_FAIR_ENTRY_REQUEST,
  CREATE_BOOK_FAIR_ENTRY_SUCCEES,
  LIST_BOOK_FAIR_ENTRY_FAIL,
  LIST_BOOK_FAIR_ENTRY_REQUEST,
  LIST_BOOK_FAIR_ENTRY_SUCCEES,
  CREATE_BOOK_FAIR_ENTRY_RESET,
  LIST_BOOK_FAIR_ENTRY_STATS_REQUEST,
  LIST_BOOK_FAIR_ENTRY_STATS_SUCCEES,
  LIST_BOOK_FAIR_ENTRY_STATS_FAIL,
} from '../constants/bookFairEntryConstants'

export const createBookFairEntryReducer = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case CREATE_BOOK_FAIR_ENTRY_REQUEST:
      return {
        loading: true,
      }
    case CREATE_BOOK_FAIR_ENTRY_SUCCEES:
      return {
        loading: false,
        success: true,
        bookFairEntry: action.payload,
      }
    case CREATE_BOOK_FAIR_ENTRY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case CREATE_BOOK_FAIR_ENTRY_RESET:
      return {}
    default:
      return state
  }
}

export const listBookFairEntryReducer = (
  state = { bookFairEntries: [], page: 0, pages: 0 },
  action
) => {
  switch (action.type) {
    case LIST_BOOK_FAIR_ENTRY_REQUEST:
      return {
        loading: true,
      }
    case LIST_BOOK_FAIR_ENTRY_SUCCEES:
      return {
        loading: false,
        success: true,
        bookFairEntries: action.payload.bookfairEntries,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case LIST_BOOK_FAIR_ENTRY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const listBookFairEntryStatsReducer = (
  state = { stats: [], page: 0, pages: 0 },
  action
) => {
  switch (action.type) {
    case LIST_BOOK_FAIR_ENTRY_STATS_REQUEST:
      return {
        loading: true,
      }
    case LIST_BOOK_FAIR_ENTRY_STATS_SUCCEES:
      return {
        loading: false,
        success: true,
        stats: action.payload,
      }
    case LIST_BOOK_FAIR_ENTRY_STATS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
