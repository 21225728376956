import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {
  FaBookReader,
  FaStar,
  FaExclamationCircle,
  FaGrinStars,
} from 'react-icons/fa'
const SurpriseStats = ({ product, coins, binding }) => {
  return (
    <>
      <Row className='storystatsrow'>
        <Col>
          <div className='storiestatslabel'>
            <FaBookReader /> Rating
          </div>
          <div className='storiestatsvalue'>4.81</div>
        </Col>
        <Col>
          <div className='storiestatslabel'>
            <FaStar /> Reviews
          </div>
          <div className='storiestatsvalue'>1,988</div>
        </Col>
        <Col>
          <div className='storiestatslabel'>
            <FaGrinStars /> Customers
          </div>
          <div className='storiestatsvalue'>1+ Lakh</div>
        </Col>
        <Col>
          <div className='storiestatslabel'>
            <FaExclamationCircle /> Quality
          </div>
          <div className='storiestatsvalue'>100%</div>
        </Col>
      </Row>
    </>
  )
}

export default SurpriseStats
