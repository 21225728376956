import {
  LIST_USER_INTREST_FAIL,
  LIST_USER_INTREST_REQUEST,
  LIST_USER_INTREST_SUCCEES,
} from '../constants/intrestArConstants'

export const listUserIntrestReducer = (
  state = { intrests: [], loading: true },
  action
) => {
  switch (action.type) {
    case LIST_USER_INTREST_REQUEST:
      return {
        loading: true,
      }
    case LIST_USER_INTREST_SUCCEES:
      return {
        loading: false,
        success: true,
        intrests: action.payload,
      }
    case LIST_USER_INTREST_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      }
    default:
      return state
  }
}
