import axios from 'axios'
import {
  GENRE_LIST_FAIL,
  GENRE_LIST_REQUST,
  GENRE_LIST_SUCCESS,
  PLOT_GENRE_LIST_FAIL,
  PLOT_GENRE_LIST_REQUST,
  PLOT_GENRE_LIST_SUCCESS,
} from '../constants/genreConstants'

export const getGenres = (type) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GENRE_LIST_REQUST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.get(`/api/genres/${type}`, config)

    dispatch({
      type: GENRE_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GENRE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getPlotGenres = (type) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PLOT_GENRE_LIST_REQUST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.get(`/api/genres/plots`, config)

    dispatch({
      type: PLOT_GENRE_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PLOT_GENRE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
