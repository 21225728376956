import React from 'react'
import { Pagination, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'
const SocialShare = ({ url, title, verticle = false }) => {
  return (
    <Container>
      <h5>Share</h5>
      <FacebookShareButton
        url={url}
        quotes={'Hey 99bookscart.com is amazing! Check this Story'}
        hashtag={'#99bookscart'}
      >
        <FacebookIcon size={36} round={true} />
      </FacebookShareButton>
      {verticle ? <br /> : <>&nbsp;</>}
      <TwitterShareButton
        url={url}
        title={title}
        related={['99bookscart']}
        hashtag={'#99bookscart'}
      >
        <TwitterIcon size={36} round={true} />
      </TwitterShareButton>
      {verticle ? <br /> : <>&nbsp;</>}
      <WhatsappShareButton title={title} url={url}>
        <WhatsappIcon size={36} round={true} />
      </WhatsappShareButton>
      {verticle ? <br /> : <>&nbsp;</>}
      <LinkedinShareButton
        url={url}
        summary={'Hey 99bookscart.com is amazing! Check this Story'}
        source={'99bookscart'}
      >
        <LinkedinIcon size={36} round={true} />
      </LinkedinShareButton>
    </Container>
  )
}

export default SocialShare
