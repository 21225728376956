export const CREATE_NEW_EPISODE_REQUEST = 'CREATE_NEW_EPISODE_REQUEST'
export const CREATE_NEW_EPISODE_SUCCEES = 'CREATE_NEW_EPISODE_SUCCEES'
export const CREATE_NEW_EPISODE_FAIL = 'CREATE_NEW_EPISODE_FAIL'

export const GET_EPISODE_REQUEST = 'GET_EPISODE_REQUEST'
export const GET_EPISODE_SUCCEES = 'GET_EPISODE_SUCCEES'
export const GET_EPISODE_FAIL = 'GET_EPISODE_FAIL'

export const READ_EPISODE_REQUEST = 'READ_EPISODE_REQUEST'
export const READ_EPISODE_SUCCEES = 'READ_EPISODE_SUCCEES'
export const READ_EPISODE_FAIL = 'READ_EPISODE_FAIL'

export const COMMENT_EPISODE_REQUEST = 'COMMENT_EPISODE_REQUEST'
export const COMMENT_EPISODE_SUCCEES = 'COMMENT_EPISODE_SUCCEES'
export const COMMENT_EPISODE_FAIL = 'COMMENT_EPISODE_FAIL'

export const COMMENT_LIST_REQUEST = 'COMMENT_LIST_REQUEST'
export const COMMENT_LIST_SUCCEES = 'COMMENT_LIST_SUCCEES'
export const COMMENT_LIST_FAIL = 'COMMENT_LIST_FAIL'

export const DELETE_EPISODE_REQUEST = 'DELETE_EPISODE_REQUEST'
export const DELETE_EPISODE_SUCCEES = 'DELETE_EPISODE_SUCCEES'
export const DELETE_EPISODE_FAIL = 'DELETE_EPISODE_FAIL'

export const GET_FREE_EPISODE_REQUEST = 'GET_FREE_EPISODE_REQUEST'
export const GET_FREE_EPISODE_SUCCEES = 'GET_FREE_EPISODE_SUCCEES'
export const GET_FREE_EPISODE_FAIL = 'GET_FREE_EPISODE_FAIL'
