import React, { useState, useEffect } from 'react'
import { Table, Form, Button, Row, Card, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { userAddressList, deleteUserAddress } from '../actions/userActions'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'

const UserAddressListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1
  const cancelOrders = match.params.cancel

  const userAddressListData = useSelector((state) => state.userAddressList)
  const { addressList, loading, success, error } = userAddressListData

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(userAddressList())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, pageNumber, cancelOrders, match])
  const deleteUserAddressById = (addressId) => {
    dispatch(deleteUserAddress(addressId))
  }
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          <Row>
            <div role='presentation'>
              <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
                <Link underline='hover' color='inherit' to={'/profile'}>
                  Profile
                </Link>
                <Typography color='text.primary'>Addresses</Typography>
              </Breadcrumbs>
            </div>
          </Row>
          <Row>
            {addressList.length == 0 && (
              <Message>No Addresses have been added to your Profile!</Message>
            )}
            {addressList.map((address) => (
              <Col xs={12} md={4} key={address._id}>
                <Card className=' rounded py-1' key={address._id}>
                  <Card.Body>
                    <Card.Text className='text-pointer'>
                      <span>
                        {address.name}
                        <br />
                        {address.address},{address.address1}
                        <br />
                        {address.city},{address.state},<br />
                        {address.zipcode}
                        <br />
                        {address.phone}
                      </span>
                    </Card.Text>
                    <Button
                      className='btn-sm'
                      variant='dark'
                      onClick={() => deleteUserAddressById(address._id)}
                    >
                      <i className='fas fa-trash-alt'></i> Remove
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </Container>
  )
}

export default UserAddressListScreen
