import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import {
  Table,
  Form,
  Button,
  Row,
  Col,
  Container,
  Modal,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getBookFair, submitBookFairIntrest } from '../actions/bookFairActions'
import EventMeta from '../components/EventMeta'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const BookFairPScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1

  const getBookFairData = useSelector((state) => state.getBookFair)
  const { loading, error, success, bookfair } = getBookFairData

  const listBookFairEntry = useSelector((state) => state.listBookFairEntry)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [codeShow, setCodeShow] = useState(false)
  const [show, setShow] = useState(false)
  const [phone, setPhone] = useState(false)
  const [phoneError, setPhoneError] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getBookFair(match.params.id))
    window.onscroll = () => {
      let flag
      {
        localStorage.getItem('bookfairphone') &&
        localStorage.getItem('bookfairphone') == 'true'
          ? (flag = true)
          : (flag = false)
      }
      if (window.pageYOffset > 500 && !flag) {
        localStorage.setItem('bookfairphone', true)
        setShow(true)
      }
    }
  }, [dispatch, history, userInfo, pageNumber, match])
  const submitHandler = (e) => {
    e.preventDefault()
    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(phone)) {
      dispatch(submitBookFairIntrest(phone, bookfair))
      setCodeShow(true)
    } else {
      setPhoneError('Enter Valid Phone Number')
    }
  }
  const handleClose = () => {
    setShow(false)
  }
  const showphoneForm = () => {
    setShow(true)
  }
  return (
    <Container style={{ color: '#000' }}>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <EventMeta event={bookfair}></EventMeta>
          <Row className='align-items-center'>
            <Col>
              <LazyLoadImage
                src={bookfair.image}
                width={'100%'}
                alt={bookfair.name}
              />
              
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <h1>{bookfair.name}</h1>
              <p className='bookfair-event-header'>
                <strong>
                  <i class='fas fa-clock'></i> {formatDate2(bookfair.startDate)}{' '}
                  to {formatDate2(bookfair.endDate)}, 2023 | 9am - 10pm
                </strong>
                <br />
                <strong>
                  <i class='far fa-building	'></i> {bookfair.address}
                </strong>{' '}
                <br />
                <strong>
                  <i className='fas fa-globe	'></i>{' '}
                  <a href={bookfair.googleLink} target='_blank'>
                    <u> Google Map</u>
                  </a>{' '}
                </strong>
                <br />
              </p>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <Button
                variant='primary'
                onClick={showphoneForm}
                style={{ textAlign: 'center' }}
              >
                Intrested
              </Button>
              &nbsp;&nbsp;
              <Button
                variant='primary'
                onClick={showphoneForm}
                style={{ textAlign: 'center' }}
              >
                Send me Details
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <p style={{ whiteSpace: 'pre-wrap' }}>
                {bookfair.description}
                Venue: {bookfair.address} | {formatDate2(bookfair.startDate)} to{' '}
                {formatDate2(bookfair.endDate)}, 2023 | 9am - 10pm
                <br /> Map:
                <a href={bookfair.googleLink} target='_blank'>
                  <u> Google Map</u>
                </a>{' '}
                <br />
                Contact: +91 9019158183 or info@99bookscart.com
                <br />
              </p>
            </Col>
          </Row>
          <Modal show={show} onHide={handleClose}>
            <Modal.Body>
              <Row>
                <Col>
                  <Button
                    variant='secondary'
                    onClick={handleClose}
                    style={{ float: 'right' }}
                  >
                    X
                  </Button>
                </Col>
              </Row>
              {codeShow ? (
                <div>
                  <h2>Thank you for your Intrest in 99bookscart</h2>
                  <p>
                    Mention <strong>PROUDREADER</strong> and get 5% discount
                  </p>
                </div>
              ) : (
                <Form onSubmit={submitHandler} className='cart-form'>
                  <Form.Group>
                    <h4>{bookfair.name}</h4>
                    <h5>Get 5% OFF in bookfair</h5>
                    <p>Code will be shown after submiting the number!</p>
                    <Form.Text className='red-text'>
                      {phoneError && <span>{phoneError}</span>}
                    </Form.Text>
                    <Form.Control
                      type='phone'
                      placeholder='Enter Phone'
                      required
                      value={phone || ''}
                      onChange={(e) => setPhone(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Button
                    type='submit'
                    variant='primary'
                    className='float-right'
                  >
                    Get 5% OFF
                  </Button>
                </Form>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
      <br />
      <br />
    </Container>
  )
}

const formatDate2 = (dateString) => {
  const options = { month: 'long', day: 'numeric' }
  return new Date(dateString).toLocaleDateString(undefined, options)
}
export default BookFairPScreen
