import React, { useState, useEffect } from 'react'

import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Table, Row, Col, Image, Modal } from 'react-bootstrap'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { listSellerOrders } from '../../../actions/orderActions'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import SellerOrder from '../components/SellerOrder'
import { getStore } from '../../../actions/storeActions'

const SellerOrderListScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const pageNumber = match.params.pageNumber || 1

  const handleChange = (event, value) => {
    history.push(`/seller/orders/page/${value}`)
  }
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const sorderList = useSelector((state) => state.sellerOrderList)
  const { loading, error, orders, success, page, pages } = sorderList

  const storeData = useSelector((state) => state.getStore)
  const {
    loading: storeLoading,
    error: storeError,
    success: storeSuccess,
    result,
  } = storeData
  useEffect(() => {
    window.scrollTo(0, 0)
    if (userLoggedIn && !loading) {
      dispatch(getStore())
    }
    if (storeSuccess) {
      if (result.store.storeName) {
        dispatch(listSellerOrders(pageNumber))
      } else {
        history.push('/seller/create/new/store')
      }
    }
  }, [dispatch, userLoggedIn, pageNumber, storeSuccess])
  return (
    <>
      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          success && (
            <Container>
              <Row>
                <div role='presentation'>
                  <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
                    <Link underline='hover' color='inherit' to={'/profile'}>
                      Dashboard
                    </Link>
                    <Typography color='text.primary'>Order List</Typography>
                  </Breadcrumbs>
                </div>
              </Row>

              <Row>
                <Col xs={12} sm={12}>
                  {orders && orders.length == 0 ? (
                    <Message>No orders has been placed yet!</Message>
                  ) : (
                    <>
                      {orders &&
                        orders.map((order) => (
                          <>
                            <SellerOrder order={order} history={history} />
                          </>
                        ))}
                      <Stack spacing={1}>
                        <Pagination
                          count={pages}
                          page={page}
                          onChange={handleChange}
                          style={{
                            justifyContent: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        />
                      </Stack>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          )
        )}
      </Container>
    </>
  )
}
{
}

export default SellerOrderListScreen
