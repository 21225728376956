import {
  BLOG_CREATET_SUCCESS,
  BLOG_CREATE_FAIL,
  BLOG_CREATE_REQUEST,
  BLOG_DELETE_FAIL,
  BLOG_DELETE_REQUEST,
  BLOG_DELETE_SUCCESS,
  BLOG_EDIT_FAIL,
  BLOG_EDIT_REQUEST,
  BLOG_EDIT_SUCCESS,
  BLOG_GET_FAIL,
  BLOG_GET_REQUEST,
  BLOG_GET_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
} from '../constants/blogConstants'
import {} from '../constants/userConstants'

export const createBlogReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_CREATE_REQUEST:
      return {
        loading: true,
      }
    case BLOG_CREATET_SUCCESS:
      return {
        loading: false,
        success: true,
        blog: action.payload,
      }
    case BLOG_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const listBlogReducer = (state = { blogs: [] }, action) => {
  switch (action.type) {
    case BLOG_LIST_REQUEST:
      return {
        loading: true,
      }
    case BLOG_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        blogs: action.payload.blogs,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case BLOG_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getBlogReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case BLOG_GET_REQUEST: 
      return {
        loading: true,
      }
    case BLOG_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        blog: action.payload,
      }
    case BLOG_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const editBlogReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case BLOG_EDIT_REQUEST:
      return {
        loading: true,
      }
    case BLOG_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        blog: action.payload,
      }
    case BLOG_EDIT_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const deleteBlogReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case BLOG_DELETE_REQUEST:
      return {
        loading: true,
      }
    case BLOG_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        blog: action.payload,
      }
    case BLOG_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
