import axios from 'axios'

import {
  SUBMISSION_ADD_FAIL,
  SUBMISSION_ADD_RESET,
  SUBMISSION_ADD_SUCCEES,
  SUBMISSION_LIST_FAIL,
  SUBMISSION_LIST_REQUEST,
  SUBMISSION_LIST_SUCCEES,
} from '../constants/submissionConstants'

export const submitSubmission = (submission) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUBMISSION_ADD_RESET,
    })

    const { data } = await axios.post(`/api/submission/add`, submission)

    dispatch({
      type: SUBMISSION_ADD_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SUBMISSION_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listEbookSubmission = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUBMISSION_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/submission`, config)

    dispatch({
      type: SUBMISSION_LIST_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SUBMISSION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
