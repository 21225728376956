import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const AppPlotSearchPaginate = ({
  pages,
  page,
  isAdmin = false,
  category = '',
  language,
}) => {
  let pagesPage = 10
  let lb = Math.ceil(page / pagesPage)
  lb = isNaN(lb) ? 10 : lb
  let lowerBound = Number((lb - 1) * 10)

  let nexPage = lowerBound + 10
  let prevPage = nexPage - 20
  if (prevPage < 0) {
    prevPage = 1
  }
  let remainingPages = pages - page
  let limitPagesPage = remainingPages < 1 ? lb + 2 : 10

  return (
    pages > 1 && (
      <Pagination>
        {nexPage > 10 && (
          <LinkContainer
            key={prevPage + 1}
            to={`/app/en/search/${category}/page/${prevPage + 1}`}
          >
            <Pagination.Item>Prev</Pagination.Item>
          </LinkContainer>
        )}
        {[...Array(limitPagesPage).keys()].map((x) => (
          <LinkContainer
            key={x + 1 + lowerBound}
            to={`/app/en/search/${category}/page/${x + 1 + lowerBound}`}
          >
            <Pagination.Item active={x + 1 + lowerBound === page}>
              {x + 1 + lowerBound}
            </Pagination.Item>
          </LinkContainer>
        ))}
        {pages > 10 && limitPagesPage === 10 && (
          <LinkContainer
            key={nexPage + 1}
            to={`/app/en/search/${category}/page/${nexPage + 1}`}
          >
            <Pagination.Item>Next</Pagination.Item>
          </LinkContainer>
        )}
      </Pagination>
    )
  )
}

export default AppPlotSearchPaginate
