import React, { useState } from 'react'
import { Card, Button, Modal, Image } from 'react-bootstrap'
import Rating from './Rating'
import { Link } from 'react-router-dom'
import { addToCart } from '../actions/cartActions.js'
import { useDispatch, useSelector } from 'react-redux'
import BoxProgressBar from './BoxProgressBar'
import { FaBoxOpen, FaInfoCircle } from 'react-icons/fa'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const SellerProduct = ({ product, history, match, addtocart = true }) => {
  const dispatch = useDispatch()
  // const [show, setShow] = useState(false);
  const [disable, setDisable] = React.useState(false)

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart
  const slug = convertToSlug(product.product.name)
  let shortlistedBook = false
  cartItems.map((item) => {
    if (item.product == product._id) {
      shortlistedBook = true
    }
  })

  const goToCartHandler = () => {
    history.push(`/cart`)
  }
  const addToCartHandler = () => {
    dispatch(addToCart(product._id, 1))
    setDisable(true)
  }
  return (
    <>
      <Card className='rounded py-1'>
        <Link
          to={`/book/${product.product.isbn}/${slug}`}
          className='book-image'
        >
          <LazyLoadImage
            src={product.product.image}
            width={'100%'}
            alt={product.product.name}
            className='book-image'
            placeholderSrc={
              'https://s3-books-images.s3.ap-south-1.amazonaws.com/e130987d-d9e5-4567-9b62-0eb5cced1cec.png'
            }
          />
        </Link>
        <Card.Body>
          <Link
            to={`/book/${product.product.isbn}/${slug}`}
            style={{ textDecoration: 'none' }}
          >
            <Card.Title as='div' className='book-title'>
              <span className='title-main'>
                {product.product.name.toLowerCase()}
              </span>
            </Card.Title>
          </Link>
          {product.type == 'New' && (
            <Card.Subtitle className='new-books text-center'>
              New Book
            </Card.Subtitle>
          )}

          {product.grating > 0 && <Rating value={product.grating} />}

          <Card.Text className='text-center'>
            <small>
              <span className='productPrice'>&#8377;{product.salePrice}</span>{' '}
              <small className='red-text'>
                -{Math.round(100 - (product.salePrice / product.price) * 100)}%
              </small>
            </small>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default SellerProduct
