export const BLOG_COMMENT_CREATE_REQUEST = 'BLOG_COMMENT_CREATE_REQUEST'
export const BLOG_COMMENT_CREATET_SUCCESS = 'BLOG_COMMENT_CREATET_SUCCESS'
export const BLOG_COMMENT_CREATE_FAIL = 'BLOG_COMMENT_CREATE_FAIL'

export const BLOG_COMMENT_LIST_REQUEST = 'BLOG_COMMENT_LIST_REQUEST'
export const BLOG_COMMENT_LIST_SUCCESS = 'BLOG_COMMENT_LIST_SUCCESS'
export const BLOG_COMMENT_LIST_FAIL = 'BLOG_COMMENT_LIST_FAIL'

export const BLOG_COMMENT_GET_REQUEST = 'BLOG_COMMENT_GET_REQUEST'
export const BLOG_COMMENT_GET_SUCCESS = 'BLOG_COMMENT_GET_SUCCESS'
export const BLOG_COMMENT_GET_FAIL = 'BLOG_COMMENT_GET_FAIL'

export const BLOG_COMMENT_EDIT_REQUEST = 'BLOG_COMMENT_EDIT_REQUEST'
export const BLOG_COMMENT_EDIT_SUCCESS = 'BLOG_COMMENT_EDIT_SUCCESS'    
export const BLOG_COMMENT_EDIT_FAIL = 'BLOG_COMMENT_EDIT_FAIL'

export const BLOG_COMMENT_DELETE_REQUEST = 'BLOG_COMMENT_DELETE_REQUEST'
export const BLOG_COMMENT_DELETE_SUCCESS = 'BLOG_COMMENT_DELETE_SUCCESS'
export const BLOG_COMMENT_DELETE_FAIL = 'BLOG_COMMENT_DELETE_FAIL'
