import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Row, Col, Image, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  register,
  sendOTP,
  verifyOtp,
  forgotPassword,
} from '../actions/userActions'
import SocialLogin from '../components/SocialLogin'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Helmet } from 'react-helmet'
const RegisterScreen = ({ location, history }) => {
  const userAgent = window.navigator.userAgent.toLowerCase()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [passwordMessage, setPasswordMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState(false)
  const [message, setMessage] = useState(null)
  const [showMainForm, setShowMainForm] = useState(true)
  const [showSocials, setSocials] = useState(false)
  const [otpErrorMessage, setOtpErrorMessage] = useState('')
  const [remail, setRemail] = useState('')
  const [showResetPassMessage, setShowResetPassMessage] = useState('')
  const [show, setShow] = useState(false)

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, success: registerSuccess, userInfo } = userRegister

  const redirect = location.search ? location.search.split('=')[1] : '/'
  const forgotPasswordRed = useSelector((state) => state.forgotPassword)
  const {
    loading: fploading,
    error: fperror,
    result,
    success: fpsuccess,
  } = forgotPasswordRed

  
  useEffect(() => {
    window.scrollTo(0, 0)
    if (userLoggedIn) {
      history.push(redirect)
    } 
    if (registerSuccess && userInfo) {
      if (userInfo.error) {
        setErrorMessage(userInfo.errorMessage)
      } else {
        if (redirect == 'subscription') {
          history.push('/profile/billing')
        } else {
          history.push(redirect)
        }
      }
    }
    if (fpsuccess) {
      if (result.error && result.errorMessage) {
        setShowResetPassMessage(result.errorMessage)
      } else {
        setShowResetPassMessage('Password Reset Email has been Sent')
        setRemail('')
      }
    }
  }, [history, userInfo, redirect, registerSuccess, fpsuccess])

  // const responseFacebook = (response) => {
  //   if (response.name) {
  //     var isSocial = true
  //     dispatch(
  //       register({
  //         name: response.name,
  //         email: response.email,
  //         isSocial: isSocial,
  //         source: 'facebook',
  //         externalId: response.id,
  //         accessToken: response.accessToken,
  //         profileImage: response.picture
  //           ? response.picture.data
  //             ? response.picture.data.url
  //             : ''
  //           : '',
  //       })
  //     )
  //   }
  // }

  const submitHandler = (e) => {
    e.preventDefault()
    setPasswordMessage('')
    if (password.length < 5) {
      setPasswordMessage('Password is too short')
    } else {
      var isSocial = false
      dispatch(register({ name, email, password, isSocial }))
    }
  }
  const backtoSocialHandler = (e) => {
    setShowMainForm(false)
    setSocials(true)
  }
  const loginwithEmailHandler = (e) => {
    setShowMainForm(true)
    setSocials(false)
  }
  const forgotPasswordHandler = (e) => {
    e.preventDefault()
    setShow(true)
  }
  const handleClose = (e) => {
    setShow(false)
  }
  const forgotpasswordsubmitHandler = (e) => {
    e.preventDefault()
    dispatch(forgotPassword({ email: remail }))
  }
  return (
    <Container>
      <Helmet>
        <title>Register</title>
        <meta charset='UTF-8' />
        <meta
          name='title'
          content={'Build Your Personal Library With Our Online Book Fair'}
        ></meta>
        <meta
          name='description'
          content={
            'Discover the joy of reading with our online book fair! Curate your own book box and choose any 7 books for just ₹999/-. Start building your personal library today'
          }
        ></meta>
        <meta
          name='keyword'
          content={('Online Book Fair', 'Your Own Book Box')}
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        ></meta>
        <link
          rel='canonical'
          href={'https://www.99bookscart.com/register'}
        ></link>
      </Helmet>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <>
          <Col md={12}>
            {showMainForm && (
              <div
                style={{
                  width: '100%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <FormContainer>
                  <div style={{ textAlign: 'center', marginBottom: '8px' }}>
                    <Avatar
                      sx={{ m: 1 }}
                      style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    >
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component='h1' variant='h1'>
                      Sign Up
                    </Typography>
                  </div>
                  {!userAgent.includes('wv') && (
                    <div className='social-logins'>
                      <SocialLogin />
                    </div>
                  )}

                  <br />
                  {message && <Message variant='danger'>{message}</Message>}
                  {error && <Message variant='danger'>{error}</Message>}
                  {/* {loading && <Loader></Loader>} */}
                  {errorMessage && (
                    <div>
                      <span className='red-text'>{errorMessage}</span>{' '}
                      <Link to={''} onClick={(e) => forgotPasswordHandler(e)}>
                        Forgot password?
                      </Link>
                      <br />
                    </div>
                  )}

                  <form onSubmit={submitHandler}>
                    <TextField
                      id='outlined-basic'
                      label='Full Name'
                      variant='outlined'
                      required
                      type='Text'
                      autoComplete='off'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{ width: '100%', marginBottom: '10px' }}
                    />

                    <TextField
                      id='outlined-basic'
                      label='Email'
                      variant='outlined'
                      required
                      type='Email'
                      autoComplete='off'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ width: '100%', marginBottom: '10px' }}
                    />
                    {passwordMessage && (
                      <div className='red-text'>{passwordMessage}</div>
                    )}

                    <TextField
                      id='outlined-basic'
                      label='Password'
                      variant='outlined'
                      required
                      type='Password'
                      autoComplete='off'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ width: '100%', marginBottom: '10px' }}
                    />
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      className='theme-button'
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Sign Up
                    </Button>
                    <div>
                      <div style={{ textAlign: 'center', fontSize: '14px' }}>
                        <Link
                          to={
                            redirect ? `/login?redirect=${redirect}` : '/login'
                          }
                          variant='body2'
                        >
                          Already have an account? Sign In
                        </Link>
                        <br />
                        <Link
                          to={'/seller/register'}
                          variant='body2'
                          className='text-center'
                          style={{ color: '#fff' }}
                        >
                          <Button
                            className='theme-button'
                            style={{ color: '#fff', marginTop: '10px' }}
                          >
                            Register as Seller{' '}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </form>
                </FormContainer>
              </div>
            )}
          </Col>
        </>
      </Box>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Row>
            <Col>
              <Button
                variant='secondary'
                onClick={handleClose}
                style={{ float: 'right' }}
              >
                X
              </Button>
            </Col>
          </Row>

          <form
            onSubmit={forgotpasswordsubmitHandler}
            className='cart-form'
            style={{ padding: '22px' }}
          >
            <Typography variant='h6'>Forgot Password?</Typography>
            <Typography variant='body2'>
              Enter your email to reset password
            </Typography>
            {showResetPassMessage && (
              <span className='red-text'>{showResetPassMessage}</span>
            )}

            <TextField
              id='outlined-basic'
              label='Email Address'
              variant='outlined'
              required
              type='Email'
              autoComplete='off'
              value={remail || ''}
              onChange={(e) => setRemail(e.target.value)}
              style={{ width: '100%', marginBottom: '10px', marginTop: '15px' }}
            />
            <Button
              type='submit'
              variant='contained'
              fullWidth
              className='theme-button'
            >
              Send Password Reset Link
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default RegisterScreen
