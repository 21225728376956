import React, { useState, useEffect } from 'react'
import { Form, Button, Col, Row, Container, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import {
  getCartDetailsFromStorage,
  saveShippingMethodToStorage,
} from '../actions/cartActions'
import { Link } from 'react-router-dom'
import CheckoutSteps from '../components/CheckoutSteps'
import OrderSummary from '../components/OrderSummary'
import { FaTruck } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'
import Razorpay from 'razorpay'
import Loader from '../components/Loader'
import { addDiscountCode } from '../actions/discountCodeActions'
import Cookies from 'universal-cookie'

import {
  createOrder,
  payOrder,
  postOrderPayment,
} from '../actions/orderActions'
import {
  saveDiscountPrice,
  saveDiscountCode,
  removeDiscountFromCart,
} from '../actions/cartActions'
import { createSubscription } from '../actions/subscriptionActions'
const cookies = new Cookies()
function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay))
}
const ExpressCheckout = ({ history }) => {
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }
  const dispatch = useDispatch()
  const [isBox, setIsBox] = useState(false)
  const [codCost, setCodCost] = useState('')
  const [payButtonClick, setPayButtonClick] = useState(false)
  const [payS, SetPayS] = useState(true)
  const [processingOrder, setProcessingOrder] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [isCodeApplied, setIsCodeApplied] = useState(false)
  const [isCodeA, setIsCodeA] = useState(false)
  const [displayCartError, setDisplayCartError] = useState(false)
  const [couponCodeError, setCouponCodeError] = useState(false)
  const [isCodeB, setIsCodeB] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState('')
  const [codeShow, setCodeShow] = useState(false)
  const [show, setShow] = useState(false)
  const [phone, setPhone] = useState(false)
  const [phoneError, setPhoneError] = useState(null)
  const [email, setEmail] = useState(false)
  const [name, setName] = useState(false)
  const [emailError, setEmailError] = useState(null)

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success: orderSuccess, error } = orderCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cSubscription = useSelector((state) => state.createSubscription)
  const {
    loading,
    success: successSubscription,
    error: subcriptionError,
    subscription,
  } = cSubscription

  const paymentROrder = useSelector((state) => state.orderPayRazor)
  const {
    orderPayDetails,
    success: paySuccess,
    error: payError,
    loading: payLoading,
  } = paymentROrder
  var days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const submitHandler = (e) => {
    e.preventDefault()
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }

  var options = {
    key: 'rzp_live_04AG18531Cjx9B',
    amount: 99,
    currency: 'INR',
    name: 'tempName',
    description: '99bookscart',
    // "order_id":orderPayDetails.id,
    image:
      'https://cdn.shopify.com/s/files/1/0287/9612/5316/files/99bookscart_759b028b-6768-47b8-9d79-ee2cf0190e04_180x.png?v=1646232311',
    handler: function (response) {
      const paymentResult = {
        id: response.razorpay_payment_id,
        rid: response.razorpay_order_id,
        signature: response.razorpay_signature,
      }
      dispatch(
        createOrder({
          // orderItems: cart.cartItems,
          // shippingAddress: cart.shippingAddress,
          // paymentMethod: cart.paymentMethod,
          // shippingMethod: 'Standard',
          // itemPrice: cart.cartPrice,
          // shippingPrice: cart.shippingPrice,
          // taxPrice: cart.taxPrice,
          // totalPrice: cart.totalPrice,
          // codPrice: cart.codPrice,
          // isPaid: response.razorpay_order_id ? true : false,
          // paidAt: response.razorpay_order_id ? Date.now() : '',
          // paymentResult: paymentResult,
          // cartId: cart._id,
        })
      )
      timeout(1000)
    },
    prefill: {
      name: name,
      email: email,
      contact: phone,
    },
    theme: {
      color: '#4267b2',
    },
  }

  // if (!shippingAddress) {
  //   history.push('./basic-information')
  // }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  const submitHandler2 = (e) => {
    e.preventDefault()

    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(phone)) {
      dispatch(createSubscription({ email, phone, name }))
    } else {
      setPhoneError('Enter Valid Phone Number')
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (successSubscription) {
      setProcessingOrder(false)
      SetPayS(false)
      options['subscription_id'] = subscription
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }
  }, [history, successSubscription, paySuccess, order, isCodeA])

  return (
    <>
      <Container>
        {/* <CheckoutSteps step1 step2 step3 /> */}
        <h1 className='cart-header'>Payment Method</h1>

        <>
          <Container>
            <Row>
              <Button
                type='submit'
                variant='primary'
                className='float-right'
                onClick={submitHandler}
              >
                Place Order
              </Button>
            </Row>
          </Container>
        </>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <Row>
              <Col>
                <Button
                  variant='secondary'
                  onClick={handleClose}
                  style={{ float: 'right' }}
                >
                  X
                </Button>
              </Col>
            </Row>
            {codeShow ? (
              <div>
                <h2>Thank you for your Intrest in 99bookscart</h2>
                <p>
                  Mention <strong>PROUDREADER</strong> and get 5% discount
                </p>
              </div>
            ) : (
              <Form onSubmit={submitHandler2} className='cart-form'>
                <h5>Subscribe and get accees to unlimited books</h5>
                <p>₹99/month</p>
                <Form.Group>
                  <Form.Control
                    type='text'
                    placeholder='Enter Name'
                    required
                    value={name || ''}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Text className='red-text'>
                    {emailError && <span>{emailError}</span>}
                  </Form.Text>
                  <Form.Control
                    type='email'
                    placeholder='Enter Email'
                    required
                    value={email || ''}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Text className='red-text'>
                    {phoneError && <span>{phoneError}</span>}
                  </Form.Text>
                  <Form.Control
                    type='phone'
                    placeholder='Enter Phone'
                    required
                    value={phone || ''}
                    onChange={(e) => setPhone(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary' className='float-right'>
                  Get 5% OFF
                </Button>
              </Form>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}

export default ExpressCheckout
