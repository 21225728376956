import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'

export const AiBox = (val) => {
  return (
    <Container style={{ paddingLeft: '14px' }}>
      <Row>
        <Col xs={12}>
          <h1>India&apos;s First AI Curated Book Box from 99bookscart</h1>
          <h2>Personalized Reading Experience with Theme-based Books</h2>
          <ul>
            <li>
              Our AI technology curates a selection of books based on your
              reading preferences.
            </li>
            <li>
              With 10 books for just ₹999/-, you can explore new authors and
              themes without breaking the bank.
            </li>
            <li>
              Discover new perspectives and expand your reading horizons with
              every delivery.
            </li>
          </ul>
          <h2>Diverse Writing Styles to Keep You Engaged</h2>
          <ul>
            <li>
              Our book box includes a mix of writing styles, from award-winning
              literary fiction to page-turning thrillers and everything in
              between.
            </li>
            <li>
              Each book is carefully selected to offer a unique reading
              experience, so you&apos;ll never get bored.
            </li>
            <li>
              With our AI technology, you can trust that every book in the box
              is high-quality and worth your time.
            </li>
          </ul>
          <h2>Emotional Connections Through the Power of Storytelling</h2>
          <ul>
            <li>
              Our book box goes beyond just offering a selection of books - it
              aims to create an emotional connection through the power of
              storytelling.
            </li>
            <li>
              Whether you&apos;re laughing, crying, or feeling inspired, our
              books will take you on a journey of self-discovery and growth.
            </li>
            <li>
              Join a community of readers who share a passion for literature and
              the transformative power of stories.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}
