import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Modal, Form, Image, video } from 'react-bootstrap'
import Product from '../../components/Product'
import axios from 'axios'
import { listProducts } from '../../actions/productActions'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Paginate from '../../components/Paginate'
import { Container } from 'react-bootstrap'
import HomeMeta from '../../components/HomeMeta'
import HProductCarosel from '../../components/HProductCarosel'
import { setEmailPopUpShown, submitEmail } from '../../actions/cartActions'
import FooterMenu from '../../components/FooterMenu'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import AmazonAd from '../../components/amazon/AmazonAd'
const userAgent = window.navigator.userAgent.toLowerCase()

const PosterHomeScreen = ({ match }) => {
  const keyword = match.params.keyword
  const search = match.params.search || false
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, pages, page } = productList
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [codeShow, setCodeShow] = useState(false)
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(listProducts(keyword, pageNumber, search))

    // window.onscroll = () => {
    //   let flag
    //   {
    //     localStorage.getItem('emailPopUpShown') &&
    //     localStorage.getItem('emailPopUpShown') == 'true'
    //       ? (flag = true)
    //       : (flag = false)
    //   }
    //   if (window.pageYOffset > 1000 && !flag && !userInfo) {
    //     dispatch(setEmailPopUpShown(true))
    //     setShow(true)
    //   }
    // }
  }, [dispatch, keyword, pageNumber, userInfo])
  const submitHandler = () => {
    dispatch(submitEmail(email))
    setCodeShow(true)
  }
  const Results = () => (
    <div id='results' className='search-results'>
      <strong>THANK YOU</strong>
    </div>
  )

  return (
    <>
      <Container>
        <HomeMeta></HomeMeta>
        {/* <Query></Query> */}
        {!keyword && pageNumber == 1 ? (
          <>
            {/* <Link
              to={`/book/BookscartBox1/surprise-box-15-books`}
              style={{ textDecoration: 'none' }}
            >
              <LazyLoadImage
                src={
                  '../images/home/bb.PNG'
                }
                width={'100%'}
                alt='99bookscart mystery box'
              />
            </Link> */}
            {/* <div className='video-background'>
              <div className='content'>
                {' '}
                <div className='main-heading-text'>
                  <h2>
                    <span style={{ fontSize: '27px' }}>
                      <Typist style={{}}>Build Your Own Library</Typist>
                    </span>
                  </h2>
                  <h2 style={{ color: '#000' }}>₹69/book</h2>
                  <div
                    style={{
                      color: '#005d6e',
                      fontSize: '14px',
                      marginBottom: '6px',
                    }}
                  >
                    Read, Lead & Succeed
                  </div>

                  <div>
                    <Link to={'/book/BookscartBox1/surprise-box-15-books'}>
                      <Button variant='contained'>Get Your Surprise Box</Button>
                    </Link>{' '}
                     
                  </div>
                </div>
              </div>
            </div> */}
            {/* <BooksCartBox></BooksCartBox> */}
            {/* <Container>
            <Link to={`/category/top-rated`} style={{ textDecoration: 'none' }}>
              <video
                autoPlay
                playsInline
                muted
                className='video'
                loop
                src='https://s3-books-images.s3.ap-south-1.amazonaws.com/Copy+of+Pune+Book+Fair+(2).mp4'
                onLoadedData={() => {
                  //   setVideoLoaded();
                }}
              />
            </Link>
          </Container> */}

            {/* <BoxCarosel></BoxCarosel> */}

            {/* <Link
            to={`/book/BookscartBox1/surprise-box-15-books`}
            style={{ textDecoration: 'none' }}
          >
            <Image
              src='https://cdn.shopify.com/s/files/1/0287/9612/5316/files/Copy_of_10_books_for_999.png?v=1629471121'
              width='100%'
              alt='buy 15 books for 999'
            />
          </Link> */}
            {/* <Link to={`/book-ideas`} style={{ textDecoration: 'none' }}>
            <amp-img
              src='https://s3-books-images.s3.ap-south-1.amazonaws.com/fa5b488a-26cc-449c-9707-008b3723b875.png'
              alt='near me bookstore, books store near me'
              layout='responsive'
              height='120'
              width='1200'
            />
          </Link> */}

            {/* <ProductCarosel></ProductCarosel> */}
            {/* <Container> */}
            {/* <Row>
              <Col md={12}>
                <Link
                  to={`/category/only-99/`}
                  style={{ textDecoration: 'none' }}
                >
                  <amp-img
                    src='https://cdn.shopify.com/s/files/1/0287/9612/5316/files/hist_ry_101-min.jpg?v=1617393803'
                    alt='second hand books, bookstore near me'
                    layout='responsive'
                    height='280'
                    width='1200'
                  />
                </Link>
              </Col>
            </Row> */}

            {/* </Container> */}
            {/* <BoxCarosel></BoxCarosel> */}
            {/* <Row>
            <Col>
              <Button>Buy Books</Button>
              <Button>Read Stories</Button>
            </Col>
          </Row> */}

            <Row>
              <Col md={12}>
                <Link
                  to={`/book/BookscartBox1/surprise-box-15-books`}
                  style={{ textDecoration: 'none' }}
                >
                  <LazyLoadImage
                    src='https://s3-books-images.s3.amazonaws.com/3b0a47d3-03ec-4978-8ef5-a326c4f9942d.png'
                    width='100%'
                    alt='Build your own Library and inspire other to read'
                  />
                </Link>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Link
                  to={`/online-book-fair`}
                  style={{ textDecoration: 'none' }}
                >
                  <LazyLoadImage
                    src={'../images/2.png'}
                    width={'100%'}
                    alt='99bookscart plots best non fiction book summary'
                  />
                </Link>
              </Col>
              <Col sm={6}>
                <Link
                  to={`/book/BookscartBox1/surprise-box-15-books`}
                  style={{ textDecoration: 'none' }}
                >
                  <LazyLoadImage
                    src={'../images/1.png'}
                    width={'100%'}
                    alt='99bookscart teen surprise box get 10 books for 999'
                  />
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className='text-center'>
                <br />
                <br />
                <div>
                  <Link to={'/fiction-books'}>
                    <Button variant='contained' className='theme-button'>
                      Fictional
                    </Button>
                  </Link>{' '}
                  <Link to={'/non-fiction-books'}>
                    <Button variant='contained' className='theme-button'>
                      Non Fictional
                    </Button>
                  </Link>
                </div>
                <br />
                <br />
              </Col>
            </Row>
            {/* <BookCats></BookCats> */}

            {/* <Link to={`/plots`} style={{ textDecoration: 'none' }}>
              <LazyLoadImage
                src={
                  'https://s3-books-images.s3.amazonaws.com/057ca431-7162-4beb-8ed2-ee2ef833e025.png'
                }
                width={'100%'}
                alt='99bookscart plots Read for FREE'
              />
            </Link> */}

            {/* <Container>
              <Row>
                <Col>
                  <Link
                    to={`/book/KidsBookscartBox/kids-surprise-box-10-books`}
                    style={{ textDecoration: 'none' }}
                  >
                    <LazyLoadImage
                      src={'../images/home/kids.png'}
                      width={'100%'}
                      alt='99bookscart kids surprise box get 10 books for 999'
                    />
                  </Link>
                </Col>
                <Col>
                  <Link
                    to={`/book/TeenBookscartBox/teen-surprise-box-10-books`}
                    style={{ textDecoration: 'none' }}
                  >
                    <LazyLoadImage
                      src={'../images/home/teen.png'}
                      width={'100%'}
                      alt='99bookscart teen surprise box get 10 books for 999'
                    />
                  </Link>
                </Col>
              </Row>
            </Container> */}

            {/* <Link to={`category/summary`} style={{ textDecoration: 'none' }}>
            <amp-img
              src='https://s3-books-images.s3.amazonaws.com/31bb5c13-047f-4963-bc82-416e63135a74.png'
              alt='near me bookstore, books store near me'
              layout='responsive'
              height='180'
              width='1200'
            />
          </Link> */}
            {/* <HProductCarosel></HProductCarosel> */}
            {/*<LanguageCarosel></LanguageCarosel> */}
          </>
        ) : (
          <Link to='/' className='btn btn-light'>
            Go Back
          </Link>
        )}
        {loading ? (
          <Loader></Loader>
        ) : error ? (
          <Message>
            <h2>error</h2>
          </Message>
        ) : (
          <>
            <Container>
              {!keyword && (
                <>
                  <Row className='near-me-bookstore'>
                    <br />
                    <h2 className='text-center homepage-mobile-bar-col'>
                      Explore
                    </h2>
                  </Row>
                </>
              )}
              <Row>
                {products.map((product) => (
                  <Col
                    key={product._id}
                    sm={6}
                    md={4}
                    lg={1.5}
                    xs={4}
                    className='product-grid-size'
                  >
                    <Route
                      render={({ history }) => (
                        <Product history={history} product={product} />
                      )}
                    />
                  </Col>
                ))}
                {pages == 0 && (
                  <Container>
                    <Row className='mb-30'>
                      <h4 className='text-center'>No results Found</h4>
                    </Row>
                  </Container>
                )}
              </Row>

              {keyword && (
                <>
                  <Container>
                    <AmazonAd />

                    <HProductCarosel></HProductCarosel>
                  </Container>
                </>
              )}
              <Paginate
                pages={pages}
                page={page}
                keyword={keyword ? keyword : ''}
              ></Paginate>
            </Container>
          </>
        )}

        <Container>
          <Row>
            <Col>
              <Link to={`/sell-books`} style={{ textDecoration: 'none' }}>
                <LazyLoadImage
                  src={'../images/home/sell.png'}
                  width={'100%'}
                  alt='99bookscart teen surprise box get 10 books for 999'
                />
              </Link>
            </Col>
            <Col>
              {!userAgent.includes('wv') && (
                <a
                  href={`https://play.google.com/store/apps/details?id=com.bookscart99`}
                  style={{ textDecoration: 'none' }}
                  target='_blank'
                >
                  <LazyLoadImage
                    src={'../images/home/ap.png'}
                    width={'100%'}
                    alt='Download 99bookscart app and get 50 rs OFF'
                  />
                </a>
              )}
            </Col>
          </Row>
          <Row>
            <Col className='text-left'>
              <>
                <br />
                <h1>Buy & Sell Second Hand Books Online In India</h1>
                <Typography component={'span'} variant='body1'>
                  <p>
                    Welcome to 99bookscart.com India's #1 online marketplace for
                    books, your go-to online second hand bookstore catering to
                    the diverse reading interests of over 2 lakh readers
                    globally. Our robust platform bridges the gap between 1300+
                    sellers, 300+ bookstores, and book enthusiasts, making the
                    quest to buy and <Link to='sell-books'>sell books</Link>{' '}
                    online a delightful experience. We also suggest best book to
                    read
                  </p>
                  <h2>Why buy second hand book?</h2>
                  <p>
                    By choosing to buy second-hand books, you contribute to a
                    sustainable future. Every purchase made at 99bookscart.com
                    reduces the demand for new book production, which conserves
                    valuable natural resources and minimizes carbon emissions
                    associated with manufacturing and shipping. Join us in
                    promoting environmental consciousness and reducing your
                    ecological footprint one book at a time.
                  </p>
                  <h2>
                    If you are looking for book store near me or book shop near
                    me?
                  </h2>
                  <p>
                    Look no further than 99bookscart.com. Because we have a
                    largest collection of books in India for sustainable
                    reading.
                  </p>
                  <h2>Why 99bookscart.com?</h2>
                  <p>
                    We are a one-stop destination for all best seller books.
                    Authors like{' '}
                    <Link
                      to='/author/sudha-murthy'
                      style={{ textTransform: 'capitalize' }}
                    >
                      sudha murthy books
                    </Link>
                    ,{' '}
                    <Link
                      to='/author/colleen-hoover'
                      style={{ textTransform: 'capitalize' }}
                    >
                      colleen hoover books
                    </Link>
                    <Link
                      to='/author/robin-sharma'
                      style={{ textTransform: 'capitalize' }}
                    >
                      {' '}
                      robin sharma books
                    </Link>
                    ,{' '}
                    <Link
                      to='/author/jane-austen'
                      style={{ textTransform: 'capitalize' }}
                    >
                      {' '}
                      Jane Austin books
                    </Link>
                    <Link
                      to='/author/Yann-Martel'
                      style={{ textTransform: 'capitalize' }}
                    >
                      Yann Martel books
                    </Link>
                    ,
                    <Link
                      to='/author/Arthur-Golden'
                      style={{ textTransform: 'capitalize' }}
                    >
                      Arthur Golden books
                    </Link>
                    ,
                    <Link
                      to='/author/Stephenie-Meyer'
                      style={{ textTransform: 'capitalize' }}
                    >
                      Stephenie Meyer books
                    </Link>
                    ,{' '}
                    <Link
                      to='/author/Bill-Bryson'
                      style={{ textTransform: 'capitalize' }}
                    >
                      Bill Bryson books
                    </Link>
                    ,{' '}
                    <Link
                      to='/author/Cassandra-Clare'
                      style={{ textTransform: 'capitalize' }}
                    >
                      Cassandra Clare books
                    </Link>
                    ,
                    <Link
                      to='/author/Stieg-Larsson'
                      style={{ textTransform: 'capitalize' }}
                    >
                      Stieg Larsson books
                    </Link>
                    ,
                    <Link
                      to='/author/Dan-Brown'
                      style={{ textTransform: 'capitalize' }}
                    >
                      Dan Brown books
                    </Link>{' '}
                    ,
                    <Link
                      to='/author/Suzanne-Collins'
                      style={{ textTransform: 'capitalize' }}
                    >
                      Suzanne Collins books
                    </Link>
                    ,
                    <Link
                      to='/author/J.K.%20Rowling'
                      style={{ textTransform: 'capitalize' }}
                    >
                      J.K.Rowling books
                    </Link>{' '}
                    and many more.{' '}
                  </p>
                  <p>
                    We also carry collection of books like{' '}
                    <Link
                      to='/blog/view/648db40fc4f4eb2cd4986054/the-harry-potter-book-series-a-journey-through-magic-and-adventure'
                      style={{ textTransform: 'capitalize' }}
                    >
                      harry potter series books
                    </Link>
                    ,
                    <Link
                      to='/search/diary%20of%20a%20wimpy%20kid/true'
                      style={{ textTransform: 'capitalize' }}
                    >
                      diary of a wimpy kid books
                    </Link>
                    ,
                    <Link
                      to='/search/chronicles%20of%20narnia/true'
                      style={{ textTransform: 'capitalize' }}
                    >
                      chronicles of narnia books
                    </Link>
                    ,
                    <Link
                      to='/search/lord%20of%20the%20rings/true'
                      style={{ textTransform: 'capitalize' }}
                    >
                      lord of the rings books
                    </Link>
                    ,
                    <Link
                      to='/search/game%20of%20thrones/true'
                      style={{ textTransform: 'capitalize' }}
                    >
                      game of thrones books
                    </Link>
                    ,
                    <Link
                      to='/search/percy%20jackson/true'
                      style={{ textTransform: 'capitalize' }}
                    >
                      percy jackson books
                    </Link>
                    ,
                    <Link
                      to='/blog/view/648e1dfec4f4eb2cd498b920/ncert-comprehensive-overview-of-the-national-council-of-educational-research-and-training'
                      style={{ textTransform: 'capitalize' }}
                    >
                      ncert books
                    </Link>
                  </p>
                  <h2>How to sell used books online?</h2>
                  <p>
                    If you have a collection of used books gathering dust on
                    your shelves, it's time to turn them into cash. Selling your
                    used books online can be a profitable endeavor, allowing you
                    to declutter your space while making room for new collection
                    of the books.{' '}
                  </p>
                  <p>
                    Just register on our platform and start selling any used
                    books. We have more the 13,00 sellers currently selling
                    books and we have more than 2000 reviews on all our website.{' '}
                  </p>
                  <h2>How to preserve second hand books</h2>
                  <p>
                    Preserving old books requires care and attention to many
                    factors including handling, storage, cleaning, and even the
                    environment around them. Here are some general steps to help
                    you in preserving your used books:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Handle with Care: Always ensure your hands are clean and
                        dry when handling old books. The oils from your skin can
                        cause damage over time.
                      </p>
                    </li>
                    <li>
                      <p>
                        Cleaning: Dust your books with a soft cloth or a gentle
                        vacuum using a brush attachment. Don't use any chemical
                        cleaning products as these may damage the book.
                      </p>
                    </li>
                    <li>
                      <p>
                        Storage: Store books upright on a shelf, preferably not
                        crammed. Don't store second hand books in a damp or
                        humid area as it can encourage mold growth.
                      </p>
                    </li>
                  </ul>
                  <p>
                    Remember, the goal is to slow down the aging process as much
                    as possible, not to make the book new again. Each book will
                    have its own specific needs depending on factors like its
                    age, condition, value, and materials.{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/6454bea508f5f49469706890/how-do-i-preserve-and-maintain-my-book-collection'
                    >
                      read more about preserving secong hand books
                    </a>
                  </p>
                  <h2>
                    How to build your own personal library with second hand
                    books
                  </h2>
                  <p>
                    Building your own library with second hand books can be an
                    exciting and rewarding project. It allows you to curate a
                    personal collection while also being eco-friendly and often
                    more cost-effective.
                  </p>

                  <ul>
                    <li>
                      <p>
                        Define Your Library: Decide on the type of library you
                        want. This could be anything from a general interest
                        library to a collection focused on a specific theme,
                        author, period, or genre.
                      </p>
                    </li>
                    <li>
                      <p>
                        Create a Budget: Decide how much you're willing to spend
                        on your library. Remember to account for the costs of
                        bookshelves and other library furnishings, in addition
                        to the cost of the books themselves.
                      </p>
                    </li>
                    <li>
                      <p>
                        Check Condition and Price: When purchasing books , be
                        sure to read the description of the book's condition
                        carefully. Books are usually graded as New, Like New,
                        Very Good, Good, and Acceptable.
                      </p>
                    </li>
                    <li>
                      <p>
                        Organize Your Books: Once you start acquiring your
                        books, think about how you'll organize them.
                      </p>
                    </li>
                    <li>
                      <p>
                        Protect and Maintain Your Library: Ensure your books are
                        well cared for. Store them out of direct sunlight and in
                        a cool, dry place.
                      </p>
                    </li>
                  </ul>

                  <h2>
                    Why Online used Bookstores Are Making A Comeback In India
                  </h2>
                  <p>
                    One of the main reasons for the resurgence of online
                    bookstores is nostalgia. Many people have fond memories of
                    visiting these stores as children and spending hours
                    browsing through the shelves.Another reason for the
                    popularityof second-hand bookstores is affordability.
                  </p>
                  <h2>What Books to read?</h2>
                  <p>
                    Our expansive library caters to every reader's taste, from
                    thrilling mysteries and heartwarming romances to insightful
                    biographies and enlightening non-fiction. We have more 400+
                    book genres saparating in categories fiction and non fiction
                    books.
                  </p>
                  <p>
                    {' '}
                    Books to read on{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/search/harry%20potter/true'
                    >
                      Harry Potter
                    </a>
                    , best books to read on{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/non-fiction-books/self-help'
                    >
                      self helf
                    </a>
                    , books to read on{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/non-fiction-books/true-crime'
                    >
                      true crimes
                    </a>
                    , books to read on{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/non-fiction-books/psychology'
                    >
                      psychology
                    </a>
                    , best books to be read on{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/fiction-books/classic-literature'
                    >
                      classic literature
                    </a>
                    , books to read for teenagers, books to read on{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/fiction-books/military-fiction'
                    >
                      military fiction
                    </a>
                    , books to read on{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/fiction-books/psychological-thriller'
                    >
                      Psychological Thriller
                    </a>
                    , best books to be read on{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/fiction-books/travel'
                    >
                      travel fiction
                    </a>
                    , best book to be read on{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/non-fiction-books/history'
                    >
                      history
                    </a>
                    , best books to read on{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/non-fiction-books/spirituality'
                    >
                      spirituality
                    </a>
                    , top{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/non-fiction-books/autobiography'
                    >
                      autobiography books
                    </a>{' '}
                    to be read,{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648c83e034cc79fe5b24b91b/top-5-places-to-buy-second-hand-books-in-bangalore'
                    >
                      Top 5 places to get second hand books in banglore
                    </a>
                    ,{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648c884334cc79fe5b24c4d6/top-5-remarkable-destinations-to-purchase-second-hand-books-in-chennai'
                    >
                      Top 5 places to get second hand books in chennai
                    </a>
                    ,{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648c9b9934cc79fe5b24fab6/top-5-places-to-buy-second-hand-books-in-kerala'
                    >
                      Top 5 places to get second hand books in kerala
                    </a>
                    ,
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648ca1b234cc79fe5b2509e1/top-5-places-to-buy-second-hand-books-in-mumbai'
                    >
                      Top 5 places to puchase second hand books in mumbai
                    </a>
                    ,{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648ca59534cc79fe5b2514df/top-5-places-to-buy-second-hand-books-in-pune'
                    >
                      Top 5 places to buy books in pune
                    </a>
                    ,{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648c9fc834cc79fe5b2505a8/top-5-places-to-buy-second-hand-books-in-hyderabad'
                    >
                      Top 5 places to buy second hand books in hyderabad
                    </a>
                    ,{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648ca45334cc79fe5b251147/top-5-places-to-buy-second-hand-books-in-delhi'
                    >
                      Best 5 Places to purchase second hand books in Delhi
                    </a>
                    ,{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648ca6c934cc79fe5b25179e/top-5-places-to-buy-second-hand-books-in-kolkata'
                    >
                      Best 5 Places to purchase second hand books in Kolkata
                    </a>
                    ,{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648ca91834cc79fe5b251dde/top-5-places-to-buy-second-hand-books-in-jaipur'
                    >
                      Best 5 Places to purchase second hand books in Jaipur
                    </a>
                    ,
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648cab1034cc79fe5b25237f/top-5-places-to-buy-second-hand-books-in-uttarakhand'
                    >
                      5 Amazing places to get second hand books in Uttarakhand
                    </a>
                    ,{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.99bookscart.com/blog/view/648cac8d34cc79fe5b25274c/top-5-places-to-buy-second-hand-books-in-coimbatore'
                    >
                      5 Amazing places to get second hand books in Coimbatore
                    </a>
                  </p>
                  <h2>
                    Best Genres Books to Read: Unveiling a World of Reading
                    Delights
                  </h2>
                  <p>
                    The universe of literature offers a bounty of genres, each
                    providing a unique lens through which we can explore the
                    human experience, distant galaxies, historical events, and
                    even alternate realities.{' '}
                  </p>
                  <p>
                    Understanding these genres and their distinct features can
                    help readers navigate the vast ocean of "books to read" and
                    discover "the best books to read" that resonate with their
                    interests. From fantasy novels to self-help guides, there's
                    something for everyone. In this post, we delve into some of
                    the most compelling genres that promise rewarding reading
                    experiences
                  </p>

                  {/* <p>
                  Explore our virtual "bookstore near me" feature that connects
                  you with hundreds of sellers from your vicinity and beyond.
                  With us, proximity doesn't limit your choices. Whether you're
                  looking for a new release, a second-hand classic, or a rare
                  edition, your next book is just a few clicks away.
                </p>
                <p>
                  If you cherish the charm of pre-loved books/ second hand
                  books, 99bookscart.com is your ultimate destination. Dive into
                  our extensive collection of second-hand books online, each
                  carrying a unique story beyond the printed words. Enjoy the
                  joy of reading at budget-friendly prices, without compromising
                  the quality and the thrill of finding your next favorite book.
                  For the avid collectors out there, our platform is a treasure
                  trove of rare books. Delve into the realm of antiquity,
                  uncover unique prints and hard-to-find editions, all in our
                  "bookshop near me" online experience.
                </p>
                <p>
                  For those looking to declutter their shelves or make room for
                  more, selling books on our platform is as easy as 1-2-3.
                  Connect with book lovers looking for their next read by
                  listing your books, and find your books a new home. With our
                  wide-reaching readers base, selling books online is quick,
                  easy, and efficient.
                </p>
                <p>
                  Our commitment is to make your book buying and selling
                  experience smooth and enjoyable. With user-friendly
                  navigation, a secure payment system, and an efficient delivery
                  network, 99bookscart is your reliable online book marketplace.
                  Embark on your literary journey with 99bookscart, your local
                  and global online bookshop near you. Dive in, explore, and
                  connect with a community that shares your love for books!
                </p>
                <p>
                  99bookscart.com offer lakhs of fiction and non fiction books
                  on their marketplace and each category has 100’s of genres
                  like self help books,
                </p> */}
                  {/* <p>
                If you're looking for the books, bookstore near me, bookshop
                near me or second hand books online, then look no further than
                99bookscart. This online bookstore has everything you need for
                your reading pleasure, from the latest bestsellers to
                hard-to-find classics. Plus, you can order your books in just a
                few clicks, with no need to search around for a bookstore near
                me. And with their fast and reliable delivery, you can get your
                books right on your doorstep. Plus, they even offer discounts
                and special offers, so you can get books at the best prices. So
                whether you're looking for books on business, travel,
                literature, or any other topic, 99bookscart has it all. And best
                of all, you don't even need to leave your home to find what
                you're looking for! So the next time you need books, check out
                99bookscart – it's India's best online bookstore! Don't waste
                your time looking for bookstore near me or bookshop near me.
              </p> */}
                </Typography>
                <p>
                  <ul>
                    <li>
                      <span>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://www.99bookscart.com/fiction-books/fantasy'
                        >
                          <strong>
                            Fantasy: A Leap into the Extraordinary (Harry
                            Potter)
                          </strong>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://www.99bookscart.com/fiction-books/mystery'
                        >
                          <strong>
                            Mystery/Thriller: A Dance with Suspense and Intrigue
                          </strong>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://www.99bookscart.com/fiction-books/science-fiction'
                        >
                          <strong>
                            Science Fiction: Venturing into Future Realities and
                            Distant Galaxies
                          </strong>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://www.99bookscart.com/non-fiction-books'
                        >
                          <strong>
                            Non-Fiction: Gleaning Truths from Reality
                          </strong>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://www.99bookscart.com/fiction-books/romance'
                        >
                          <strong>
                            Romance: Tales of Love and Relationships
                          </strong>
                        </a>
                      </span>
                    </li>
                    <li>
                      <span>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://www.99bookscart.com/fiction-books/historical-fiction'
                        >
                          <strong>
                            Historical Fiction: A Journey Back in Time
                          </strong>
                        </a>
                      </span>
                    </li>
                  </ul>
                </p>
              </>
            </Col>
          </Row>
          <FooterMenu></FooterMenu>
        </Container>
        <Modal show={show} onHide={handleClose}>
          <div style={{ padding: '20px' }}>
            <Modal.Body>
              <Row>
                <Col>
                  <Button
                    variant='secondary'
                    onClick={handleClose}
                    style={{ float: 'right' }}
                  >
                    X
                  </Button>
                  <img
                    src='https://s3-books-images.s3.ap-south-1.amazonaws.com/27395bc1-e4e0-4f59-9f79-af7153e35ec9.png'
                    style={{ float: 'center' }}
                    alt='bookstore near by'
                  />
                </Col>
              </Row>
              {codeShow ? (
                <Results />
              ) : (
                <Form onSubmit={submitHandler} className='cart-form'>
                  <Form.Group>
                    <h5>Be the first one to know</h5>
                    <p>Get latest deals in your email with discount codes.</p>

                    <Form.Control
                      type='email'
                      placeholder='Enter Email'
                      required
                      value={email || ''}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Button
                    type='submit'
                    variant='primary'
                    className='float-right'
                  >
                    Get Deals
                  </Button>
                </Form>
              )}
            </Modal.Body>
          </div>
        </Modal>
      </Container>
    </>
  )
}

export default PosterHomeScreen
