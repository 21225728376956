import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import { readFreeEpisode, voteEpisode } from '../../../actions/episodeActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Row, Col } from 'react-bootstrap'
import { Button, Typography } from '@mui/material'

import Loader from '../../../components/Loader'
import ReactDOMServer from 'react-dom'
import crypto from 'crypto'
import { addFollower } from '../../../actions/followerActions'
import { FaBookReader, FaStar, FaComment } from 'react-icons/fa'
import Comments from '../../../components/Comments'
import SocialShare from '../../../components/SocialShare'
import Cookies from 'universal-cookie'
import { convertToSlug, makeTitle } from '../../../helpers/helpers'
import { FaLightbulb, FaSortAlphaUp, FaSortAlphaDown } from 'react-icons/fa'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import EpisodeMeta from '../../../components/plots/meta/EpisodeMeta'
import AdInArticle from '../../../components/Ads/AdInArticle'
import VerticleSidebar from '../../../components/Ads/VerticleSidebar'

const ReadFreePlotsScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const cookies = new Cookies()
  const [content, SetContent] = useState('')
  const [follow, setFollow] = useState(false)
  const [voteText, setVoteText] = useState('Vote')
  const [flag, setFlag] = useState(false)
  const [color, setColor] = useState(cookies.get('theme') || '#fff')
  const url = `https://www.99bookscart.com/episode/${match.params.id}`
  const [paragraph, setParagraph] = useState([])

  const readFreeEpisodeRes = useSelector((state) => state.readFreeEpisode)
  const { episode, nexchapterId, loading, error, suceess } = readFreeEpisodeRes
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const voteEpisodeHandler = () => {
    if (userLoggedIn) {
      dispatch(voteEpisode(match.params.id))
      setVoteText('Voted')
    } else {
      history.push('/register')
    }
  }
  const followHandler = () => {
    dispatch(addFollower({ following: episode.user._id }))
    setFollow(true)
  }
  const fontDecrese = () => {
    if (
      color == '#fff' ||
      color == 'smallfontreading' ||
      color == 'largefontreading'
    ) {
      setColor('smallfontreading')
    } else {
      setColor('bgs-black smallfontreading')
    }
  }
  const fontIncrease = () => {
    if (
      color == '#fff' ||
      color == 'smallfontreading' ||
      color == 'largefontreading'
    ) {
      setColor('largefontreading')
    } else {
      setColor('bgs-black largefontreading')
    }
  }
  const background = () => {
    if (color == '#fff') {
      cookies.set('theme', 'bgs-black', {
        path: '/',
        maxAge: 34550000,
      })
      setColor('bgs-black')
    } else {
      cookies.set('theme', '#fff', {
        path: '/',
        maxAge: 34550000,
      })
      setColor('#fff')
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if ((!suceess || (episode && episode._id != match.params.id)) && !loading) {
      dispatch(readFreeEpisode(match.params.id, match.params.sid))
    }

    if (suceess) {
      var algorithm = 'aes256'
      var key = 'password'
      var decipher = crypto.createDecipher(algorithm, key)
      var decrypted =
        decipher.update(episode.content, 'hex', 'utf8') + decipher.final('utf8')
      SetContent(decrypted)
      setFlag(true)
      var l = decrypted.length
      var kk = l / 1300

      var plist = []

      var l = decrypted.length
      var kk = l / 600
      var t2 = 0
      var temp = 600
      for (var i = 0; i <= kk; i++) {
        plist.push(decrypted.substring(t2, temp))
        setParagraph(plist)
        temp = temp + 600
        t2 = t2 + 600
      }
    }
  }, [
    suceess,
    episode,
    content,
    match.params.id,
    match.params.sid,
    loading,
    userLoggedIn,
    history,
  ])

  return (
    <Container
      style={{ paddingTop: '35px', paddingBottom: '35px' }}
      className={color}
    >
      {loading || !suceess ? (
        <Loader></Loader>
      ) : (
        <>
          {episode.story && (
            <EpisodeMeta episode={episode} story={episode.story}></EpisodeMeta>
          )}
          <Row>
            <Col>
              <Container>
                <div style={{ cursor: 'pointer' }}>
                  <Link
                    style={{ float: 'left' }}
                    to={`/plots/en/book/${convertToSlug(episode.story.title)}/${
                      episode.story._id
                    }`}
                  >
                    Back
                  </Link>
                </div>
                <div style={{ cursor: 'pointer' }}>
                  <span onClick={voteEpisodeHandler} style={{ float: 'right' }}>
                    <FaStar style={{ color: '#666' }}></FaStar> {voteText}
                  </span>
                </div>
              </Container>
            </Col>
          </Row>
          <div className='episodereadheader'>
            <small>{episode.story.title}</small>
            <h1 className='episodetitle-name' style={{ color: 'inherit' }}>
              {episode.title}
            </h1>
            <div className='storystatslist'>
              <FaBookReader></FaBookReader> {episode.totalReadCount}{' '}
              <FaStar></FaStar> {episode.totalVoteCount} <FaComment></FaComment>{' '}
              {episode.totalComment}
            </div>
          </div>
          {episode.image && (
            <LazyLoadImage
              src={episode.image}
              alt={episode.title}
              className='img-fluid'
            />
          )}
          <Row>
            <Col xs={12} sm={12} md={8}>
              <AdInArticle />
              {/* <div
                className='episodecontent'
                dangerouslySetInnerHTML={{
                  __html: content.replace(/(<? *script)/gi, 'illegalscript'),
                }}
              ></div> */}
              {paragraph &&
                paragraph.map((p, index) => (
                  <Typography variant='' key={index}>
                    <div
                      key={index}
                      className='blog-text'
                      style={{ color: '#000' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          suceess &&
                          p.replace(/(<? *script)/gi, 'illegalscript'),
                      }}
                    ></div>
                    <AdInArticle />
                    <AdInArticle />
                  </Typography>
                ))}
              <div style={{ textAlign: 'center' }}>
                <br />
                {nexchapterId != null ? (
                  <div>
                    {userLoggedIn || true ? (
                      <Link
                        to={`/plots/en/read/${nexchapterId}/${episode.story._id}`}
                      >
                        <Button variant='contained'>Next Idea</Button>
                      </Link>
                    ) : (
                      <Link
                        to={`/register?redirect=/app/en/book/${convertToSlug(
                          episode.story.title
                        )}/${episode.story._id}`}
                      >
                        <Button>Next Idea</Button>
                      </Link>
                    )}
                    <br />
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* <Comments episode={episode}></Comments> */}
            </Col>
            <Col xs={12} sm={12} md={4}>
              <VerticleSidebar></VerticleSidebar>
              <VerticleSidebar></VerticleSidebar>
              <VerticleSidebar></VerticleSidebar>
              <VerticleSidebar></VerticleSidebar>
              <VerticleSidebar></VerticleSidebar>
              <VerticleSidebar></VerticleSidebar>
              <VerticleSidebar></VerticleSidebar>
              <VerticleSidebar></VerticleSidebar>
              <VerticleSidebar></VerticleSidebar>
              <VerticleSidebar></VerticleSidebar>
            </Col>
            {/* {userLoggedIn && userLoggedIn.isAdmin && (
              <Row>
                <Link
                  to={`/write/episode/${match.params.id}/${episode.story._id}`}
                  className='newbuttons'
                >
                  Edit
                </Link>
              </Row>
            )} */}
            {/* <Col xs={12} md={3} style={{ textAlign: 'center' }}>
              <div>
                {nexchapterId != null ? (
                  <div>
                    <Link to={`/app/idea/${nexchapterId}`}>
                      <Button>Next Chapter</Button>
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <br />
              <div style={{ fontSize: '12px' }}>
                by{' '}
                <Link to={`/user/${episode.user._id}`} className='writer-name'>
                  {episode.user.name}
                </Link>
              </div>
              <Button onClick={followHandler} disabled={follow ? true : false}>
                Follow
              </Button>
              <SocialShare
                url={url}
                title={episode.title}
                verticle={true}
              ></SocialShare>
            </Col> */}
            {/* <Container>
              <div className='footer-menu'>
                <div className='footer-menu-list'>
                  <div className='footer-menu-list-item'>
                    <FaLightbulb onClick={background}></FaLightbulb>
                  </div>
                  <div className='footer-menu-list-item'>
                    <FaSortAlphaDown onClick={fontDecrese}></FaSortAlphaDown>
                  </div>
                  <div className='footer-menu-list-item'>
                    <FaSortAlphaUp onClick={fontIncrease}></FaSortAlphaUp>
                  </div>
                </div>
              </div>
            </Container> */}
          </Row>
        </>
      )}
    </Container>
  )
}

export default ReadFreePlotsScreen
