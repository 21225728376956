import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup } from 'react-bootstrap'
import Product from '../../components/Product'
import axios from 'axios'
import { listProducts } from '../../actions/productActions'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import CPaginate from '../../components/CPaginate'
import { Container } from 'react-bootstrap'
import ProductCarosel from '../../components/ProductCarosel'
import Meta from '../../components/Meta'
import BoxProgressBar from '../../components/BoxProgressBar'
import BoxCarosel from '../../components/BoxCarosel'
import { listProductsByCategory } from '../../actions/categoryActions'
import FooterMenu from '../../components/FooterMenu'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

const OnlineBookFairScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1

  const productList = useSelector((state) => state.categoryProduct)
  const { loading, error, products, pages, page } = productList

  const dispatch = useDispatch()
  const handleChange = (event, value) => {
    history.push(`/category/thriller/page/${value}`)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(listProductsByCategory('thriller', pageNumber))
  }, [dispatch, match, pageNumber, match.params.category])
  return (
    <Container>
      <Helmet>
        <title>Online Book Fair</title>
        <meta charset='UTF-8' />
        <meta
          name='title'
          content={'Build Your Personal Library With Our Online Book Fair'}
        ></meta>
        <meta
          name='description'
          content={
            'Discover the joy of reading with our online book fair! Curate your own book box and choose any 7 books for just ₹999/-. Start building your personal library today'
          }
        ></meta>
        <meta
          name='keyword'
          content={('Online Book Fair', 'Your Own Book Box')}
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        ></meta>
        <link
          rel='canonical'
          href={'https://www.99bookscart.com/online-book-fair'}
        ></link>
        <meta name='robots' content='index, follow' />
      </Helmet>

      <div className='text-center'>
        <br />
        <h1 className='text-center dark'>India's Largest Online BookFair</h1>

        {/* <h2 style={{ fontSize: '20px' }}>Save Time & Save Money</h2> */}
        <h3>Select any 9 Books for ₹999/-</h3>
        <p>Free Shipping & Free Bookmarks</p>
        {/* <div style={{ marginTop: '3px' }}>
          20+ genres & <strong className='dark'>Free Shipping</strong>
        </div> */}

        {/* <p>
          Your personal library can be a reflection of your personality and
          taste. With our Online Book Fair, you can select books that not only
          entertain and educate you but also look great on your bookshelf.
        </p> */}
        {/* <img
          src='https://s3-books-images.s3.amazonaws.com/dcd79a12-9c25-4da7-aaaa-dd497c24eaba.png'
          width={'100%'}
        /> */}
      </div>
      <Row>
        <Col className='text-center'>
          <div>Save Time & Money browse 2 lakh+ Books from home</div>
          <div>
            <Link to={'/fiction-books'}>
              <Button
                variant='contained'
                className='theme-button'
                style={{ marginTop: '12px' }}
              >
                Fictional Genres
              </Button>
            </Link>{' '}
            <Link to={'/non-fiction-books'}>
              <Button
                variant='contained'
                className='theme-button'
                style={{ marginTop: '12px' }}
              >
                Non Fictional Genres
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>
          <h2>error</h2>
        </Message>
      ) : (
        <>
          <Container>
            <h4
              style={{
                textAlign: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              Find your next Adventure
            </h4>
            <Row>
              {products.map((product) => (
                <Col
                  key={product._id}
                  sm={4}
                  md={4}
                  lg={2}
                  xs={4}
                  className='product-grid-size'
                >
                  <Route
                    render={({ history }) => (
                      <Product history={history} product={product} />
                    )}
                  />
                </Col>
              ))}
              <Container>
                <Stack spacing={1}>
                  <Pagination
                    count={pages}
                    page={parseInt(page)}
                    onChange={handleChange}
                    style={{
                      justifyContent: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                    hidePrevButton
                    hideNextButton
                  />
                </Stack>
              </Container>
            </Row>
          </Container>
        </>
      )}
      <Container>
        <Row>
          <Col md={6} xs={12}>
            <h3>Why Online Book Fair?</h3>
            <p>
              You can Spend more time with book by reading about Main
              Characters, Themes, Places,Summary of the book and more. Browse
              50+ genres in non fiction and 80+ genres in fiction category.
            </p>
            <p>
              You can also search by author, genre and key events from books.
            </p>
          </Col>
          <Col md={6} xs={12}>
            <h3>How Online Book Fair Works?</h3>
            <p>
              You can select any 7 books from a collection 190k+ books. All the
              books which has box icon is available for Online Book Fair.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <FooterMenu></FooterMenu>
      </Container>
    </Container>
  )
}

export default OnlineBookFairScreen
