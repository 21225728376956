import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserCoins } from '../actions/coinActions'
import Paginate from '../components/Paginate'
import { MdAddAlert } from 'react-icons/md'
import { BsBoxSeam, BsCoin } from 'react-icons/bs'
import { FaTruck, FaCreditCard } from 'react-icons/fa'
import {
  reedemDiscountCode,
  listUserDiscountCode,
} from '../actions/discountCodeActions'
import Ebook from '../components/Ebook'
import { getWishlist, removeWishlist } from '../actions/wishlistActions'
import Product from '../components/Product'
import { Link } from 'react-router-dom'
import { getUserReadings } from '../actions/readingActions'
const UserEbookScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userReadingList = useSelector((state) => state.getUserReading)
  const { loading, error, success, readingList } = userReadingList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserReadings())
    } else {
      history.push('/login')
    }
  }, [dispatch, history])

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          {success && (
            <>
              <h1>Your Readings</h1>
              <Row>
                {readingList.length == 0 ? (
                  <Message>No Books added to your list</Message>
                ) : (
                  <>
                    {readingList.map((wishlist) => (
                      // <Row key={wishlist._id}>
                      //         <Col xs={12}>
                      //          Code <strong>{wishlist.product.name}</strong>
                      //         </Col>
                      // </Row>
                      <Col
                        key={wishlist.product._id}
                        sm={6}
                        md={4}
                        lg={2}
                        xs={4}
                      >
                        <Route
                          render={({ history }) => (
                            <Ebook
                              history={history}
                              product={wishlist.product}
                              addtocart={false}
                            />
                          )}
                        />
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </>
          )}
        </Container>
      )}
    </>
  )
}

export default UserEbookScreen
