import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Table, Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listUsers, deleteUser } from '../actions/userActions'
import Paginate from '../components/Paginate'
import { getBookFairIntrest, getBookFairList } from '../actions/bookFairActions'
const BookFairIntrestScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const listBookFair = useSelector((state) => state.getBookFairIntrest)
  const { loading, error, success, intrests } = listBookFair

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userInfo && userInfo.isAdmin) {
      dispatch(getBookFairIntrest(match.params.id))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  const deleteHandler = (id) => {
    if (window.confirm('are you Sure')) {
      dispatch(deleteUser(id))
    }
  }
  return (
    <>
      <h1>Book Fair Intrest</h1>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <tbody>
              {intrests.map((intrest) => (
                <tr key={intrest._id}>
                  <td>{intrest.phone}</td>
                  <td>{intrest.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}
const formatDate2 = (dateString) => {
  const options = { month: 'long', day: 'numeric' }
  return new Date(dateString).toLocaleDateString(undefined, options)
}
export default BookFairIntrestScreen
