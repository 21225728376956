import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listOrders } from '../actions/orderActions'
import Paginate from '../components/Paginate'
import OrderSearchBox from '../components/OrderSearchBox'
import { Route } from 'react-router-dom'
import AdminNav from '../components/AdminNav'
import TimeAgo from '../components/TimeAgo'

const OrderListScreen = ({ location, history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1
  const cancelOrders = match.params.cancel || false
  const keyword = match.params.keyword || null

  const orderList = useSelector((state) => state.orderList)
  const { loading, error, orders, page, pages } = orderList
  const date = location.search ? location.search.split('=')[1] : ''
  const filter = location.search ? location.search.split('=')[2] : ''

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if ((userInfo && userInfo.isAdmin) || cancelOrders) {
      dispatch(listOrders(pageNumber, cancelOrders, keyword, date, filter))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, pageNumber, cancelOrders, match])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <AdminNav></AdminNav>
          <h1>Orders</h1>
          <Route
            render={({ history }) => <OrderSearchBox history={history} />}
          />
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>Orders</th>
                <th>Total</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <LinkContainer
                  to={`/admin/order/${order._id}`}
                  className='order-list-admin'
                >
                  <tr key={order._id}>
                    <td>
                      <strong>{order.orderName && order.orderName}</strong>

                      <br />
                      <small>
                        {order.shippingAddress && order.shippingAddress.name}
                      </small>
                      <br />
                      <small>
                        <TimeAgo timestamp={order.createdAt}></TimeAgo>
                      </small>
                      <br />
                      {order.status == 'ORDER PLACED' ? (
                        <small className='topicos'> {order.status}</small>
                      ) : order.status == 'Manifested' ? (
                        <small className='topicos topiccolor1'>
                          {' '}
                          {order.status}
                        </small>
                      ) : order.status == 'In Transit' ? (
                        <small className='topicos topiccolor2'>
                          {' '}
                          {order.status}
                        </small>
                      ) : order.status == 'Fulfilled' ||
                        order.status == 'Delivered' ? (
                        <small className='topicos topiccolor3'>
                          {' '}
                          {order.status}
                        </small>
                      ) : order.status == 'RTO' ? (
                        <small className='topicos topiccolor4'>
                          {' '}
                          {order.status}
                        </small>
                      ) : (
                        order.status
                      )}
                    </td>
                    <td>
                      {' '}
                      <strong className='estimated-price'>
                        &#8377;{order.totalPrice}
                      </strong>
                    </td>
                    <td>
                      {order.isPartialPaid ? (
                        <i
                          className='fas fa-check'
                          style={{ color: 'blue' }}
                        ></i>
                      ) : order.isPaid ? (
                        <i
                          className='fas fa-check'
                          style={{ color: 'green' }}
                        ></i>
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'red' }}
                        ></i>
                      )}
                      {order.isConfirmed}
                      {order.isConfirmed &&
                        !order.isPartialPaid &&
                        !order.isPaid && (
                          <>
                            {' '}
                            <i
                              className='fab fa-diaspora'
                              style={{ color: 'blue' }}
                            ></i>
                          </>
                        )}
                      {order.isFufilled ? (
                        <i
                          className='fas fa-truck'
                          style={{ color: 'green' }}
                        ></i>
                      ) : (
                        <i className='fas fa-bell' style={{ color: 'red' }}></i>
                      )}
                    </td>
                    {/* <td>
                       {order.tracking && (
                        <>
                          <small>{order.tracking.utmSource}</small>
                          <br />
                          <small>{order.tracking.utmMedium}</small>
                          <br />
                          <small>{order.tracking.utmCampaign}</small>
                        </>
                      )} 
                    </td>*/}
                  </tr>
                </LinkContainer>
              ))}
            </tbody>
          </Table>
          <Paginate
            pages={pages}
            page={page}
            isAdmin={true}
            keyword={keyword}
            orders={true}
          ></Paginate>
        </>
      )}
    </Container>
  )
}
// const getISTTime = (date) => {
//   let d = new Date(date)
//   let newData = d.getTime() + 5.5 * 60
//   let ist = new Date(newData)
//   return (
//     ist.getDate() +
//     '/' +
//     ist.getMonth() +
//     '/' +
//     ist.getFullYear() +
//     ' ' +
//     ist.getHours() +
//     ':' +
//     ist.getMinutes()
//   )
// }
export default OrderListScreen
