import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Form,
  FormGroup,
  Modal,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { convertToSlug } from '../../helpers/helpers'
import AdminNav from '../../components/AdminNav'
import {
  getOrderDetails,
  deliverOrder,
  cancelOrder,
  confirmOrder,
  createOrderComment,
  addTagtoOrder,
  currateBox,
  sendOrderOTP,
  sendTrackingDetails,
} from '../../actions/orderActions'
import {
  createShipment,
  assignAwb,
  getShipmentDetails,
} from '../../actions/shipmentActions'
import {
  ORDER_COMMENT_RESET,
  ORDER_DELEVERED_RESET,
} from '../../constants/orderConstants'
import { SHIPPING_STATE } from '../../constants/orderConstants'

import AIBoxAttributes from '../../components/AIBoxAttributes'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Button from '@mui/material/Button'
import { editOrderadmin } from '../../actions/cartActions'
import { LinkContainer } from 'react-router-bootstrap'
import TimeAgo from '../../components/TimeAgo'
const AdminOrderScreen = ({ match, history }) => {
  const orderId = match.params.id
  const razorId = match.params.razid

  const dispatch = useDispatch()
  const [height, setHeight] = useState(false)
  const [weight, setWeight] = useState(false)
  const [length, setLength] = useState(false)
  const [width, setWidth] = useState(false)
  const [flag1, SetFlag1] = useState(false)
  const [show, setShow] = useState(false)
  const [tLink, setTLink] = useState('')
  const [courierId, setCourierId] = useState('')
  const [shipmentId, setShipmentId] = useState('')
  const [showDimensions, setShowDimensions] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const [tags, setTags] = useState([])
  const [trackMessage, setTrackMessage] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [zipError, setZipError] = useState(null)
  const [showAvailableCourier, setShowAvailableCourier] = useState(false)
  const [editOrder, setEditOrder] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleCloseDimension = () => setShowDimensions(false)
  const handleCloseAvailableCourier = () => setShowAvailableCourier(false)

  const [comment, setComment] = useState('')
  const orderDetails = useSelector((state) => state.orderDetails)

  const {
    order,
    loading,
    error,
    success,
    existingOrders,
    countOrders,
    shippingLabels,
    messages,
  } = orderDetails
  const [address, setAddress] = useState(order.shippingAddress.address)
  const [address2, setAddress2] = useState(order.shippingAddress.address2)
  const [city, setCity] = useState(order.shippingAddress.city)
  const [postalCode, setPostalCode] = useState(order.shippingAddress.postalCode)
  const [phone, setPhone] = useState(order.shippingAddress.phone)
  const [state, setState] = useState(order.shippingAddress.state)
  const [payment, setPayment] = useState(order.isPaid)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver

  const orderPayStatus = useSelector((state) => state.orderPayStatus)
  const { loading: orderPayStatusLoading } = orderPayStatus

  const editOrderRes = useSelector((state) => state.editOrder)
  const { success: editSuccess } = editOrderRes

  const currateBoxRes = useSelector((state) => state.currateBox)
  const { success: currateBoxSuccesss } = currateBoxRes

  const orderOTPRes = useSelector((state) => state.orderOTP)
  const { success: orderOTPSuccess } = orderOTPRes

  const orderComments = useSelector((state) => state.orderCommentList)
  const {
    success: successComment,
    error: errorComments,
    comments,
  } = orderComments

  const shipmentCreate = useSelector((state) => state.shipmentCreate)
  const {
    success: successShipment,
    error: errorShipment,
    loading: loadingShipment,
    shipments,
    shipmentId: sid,
  } = shipmentCreate

  const assignAWB = useSelector((state) => state.assignAWB)
  const {
    success: successIShipment,
    error: errorIShipment,
    loading: loadingIShipment,
    shipment: iShipment,
  } = assignAWB

  const getShipment = useSelector((state) => state.getShipment)
  const {
    success: successOrderShipment,
    error: errorOrderShipment,
    loading: loadingOrderShipment,
    shipment: OrderShipment,
  } = getShipment

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (
      (!order ||
        order._id !== orderId ||
        successDeliver ||
        orderPayStatusLoading) &&
      !loading &&
      !flag1
    ) {
      dispatch(getOrderDetails(orderId, 2))

      // dispatch(getShipmentDetails(orderId))
      if (successDeliver) {
        dispatch({ type: ORDER_DELEVERED_RESET })
      }
    }
    if (editSuccess) {
      window.location.reload(false)
    }
    if (successComment) {
      setComment('')
      dispatch({ type: ORDER_COMMENT_RESET })
    }
    if (currateBoxSuccesss) {
      window.location.reload(false)
    }
    if (successShipment) {
      // setShowAvailableCourier(false)
      setShowDimensions(false)
      // console.log(shipment)
      setShowAvailableCourier(true)
    }
    if (successIShipment) {
      window.location.reload(false)
    }
  }, [
    dispatch,
    order,
    history,
    orderId,
    successDeliver,
    orderPayStatusLoading,
    loading,
    successComment,
    successShipment,
    successIShipment,
    currateBoxSuccesss,
    shipments,
    orderOTPSuccess,
    editSuccess,
  ])
  const addTags = () => {
    dispatch(addTagtoOrder(tags, order._id))
    lineItems.push(tags)
    setTags('')
  }
  const removeTags = (item, e) => {
    e.preventDefault()
    const index = lineItems.indexOf(item)
    if (index > -1) {
      lineItems.splice(index, 1)
    }
    setLineItems(lineItems)
  }
  const sendCODOTP = () => {
    dispatch(sendOrderOTP(order._id))
    dispatch(createOrderComment(match.params.id, { comment: 'ORDER OTP SENT' }))
  }
  const deliverHandler = () => {
    dispatch(deliverOrder(order, tLink))
    setShow(false)
    setTLink('')
  }
  const cancelHandler = () => {
    dispatch(cancelOrder(order))
    history.push('/admin/orderlist')
  }
  const confirmHandler = () => {
    dispatch(confirmOrder(order))
    history.push('/admin/orderlist')
  }
  const commentHandler = (e) => {
    e.preventDefault()
    dispatch(
      createOrderComment(match.params.id, {
        comment,
      })
    )
  }
  const currateBoxHandler = () => {
    dispatch(currateBox(order._id))
  }
  const sendTrackingHandler = () => {
    dispatch(sendTrackingDetails(order._id))
    setTrackMessage('Tracking Details sent to email and Whatsapp')
  }

  // const printHandler = () => {
  //   const doc = new jsPDF('p', 'pt', 'a4')
  //   document.querySelector('.print-label').style.display = 'block'
  //   doc.html(document.querySelector('.print-label'), {
  //     callback: function (pdf) {
  //       doc.output('dataurlnewwindow')
  //     },
  //   })
  // }
  const generateShipment = () => {
    setShowDimensions(true)
  }

  const generateShipment2 = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    var data = await axios.get(`/api/shipment/${order._id}/delhiship`, config)
  }
  const handleCloseEditOrder = () => {
    setEditOrder(false)
  }
  const openEditOrder = () => {
    setAddress(order.shippingAddress.address)
    setAddress2(order.shippingAddress.address2)
    setCity(order.shippingAddress.city)
    setPostalCode(order.shippingAddress.postalCode)
    setPhone(order.shippingAddress.phone)
    setState(order.shippingAddress.state)
    setPayment(order.isPaid)
    setEditOrder(true)
  }

  const createShipmentHandler = (e) => {
    e.preventDefault()
    dispatch(createShipment(order._id, weight, height, width, length, false))
  }
  const genLable = (cid, shipID) => {
    dispatch(assignAwb(cid, shipID, order._id))
  }
  const editOrderHandler = (e) => {
    e.preventDefault()

    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(phone)) {
      setPhoneError('')
      if (postalCode.length == 6) {
        setZipError('')

        dispatch(
          editOrderadmin({
            order: order._id,
            address,
            address2,
            city,
            postalCode,
            state,
            phone,
            payment,
          })
        )
      } else {
        setZipError('Enter Valid ZipCode')
      }
    } else {
      setPhoneError('Enter Valid Phone Number')
    }
  }

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Container>
          <AdminNav></AdminNav>
          <Row>
            <>
              <strong>{order.orderName}</strong>

              {/* {userInfo.isAdmin &&
                order.aiItems &&
                order.aiItems.length == 0 && (
                  <Col>
                    <Button
                      type='button'
                      className='btn btn-block'
                      onClick={currateBoxHandler}
                    >
                      Currate Box
                    </Button>
                  </Col>
                )} */}

              {userInfo.isAdmin && !order.isConfirmed && !order.isFufilled && (
                <Col md={3} xs={6}>
                  <Button
                    variant='contained'
                    className='admin-button'
                    onClick={confirmHandler}
                  >
                    Mark as Confirmed
                  </Button>
                </Col>
              )}
              {userInfo.isAdmin && order.isFufilled && (
                <Col md={6} xs={6}>
                  <div>{trackMessage && trackMessage}</div>
                  <Button
                    variant='contained'
                    className='admin-button'
                    onClick={sendTrackingHandler}
                  >
                    Send Tracking Details
                  </Button>
                </Col>
              )}

              {userInfo.isAdmin && !order.isDelivered && !order.isFufilled && (
                <>
                  <Col md={3} xs={6}>
                    <Button
                      variant='contained'
                      className='admin-button'
                      onClick={handleShow}
                    >
                      Mark as Fulfilled
                    </Button>
                  </Col>
                  <Col md={3} xs={6}>
                    <Button
                      variant='contained'
                      className='admin-button'
                      onClick={generateShipment}
                    >
                      Ship by Shiprocket
                    </Button>
                  </Col>
                  <Col md={3} xs={6}>
                    <Button
                      variant='contained'
                      className='admin-button'
                      onClick={generateShipment2}
                    >
                      Ship By Delivery
                    </Button>
                  </Col>
                </>
              )}
            </>
          </Row>
          <Row className='print-test'>
            <Col md={8}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <p>
                    <br />
                    {order.isCancel && 'CANCELLED'}
                    {order.status == 'ORDER PLACED' ? (
                      <small className='topicos'> {order.status}</small>
                    ) : order.status == 'Manifested' ? (
                      <small className='topicos topiccolor1'>
                        {' '}
                        {order.status}
                      </small>
                    ) : order.status == 'In Transit' ? (
                      <small className='topicos topiccolor2'>
                        {' '}
                        {order.status}
                      </small>
                    ) : order.status == 'Fulfilled' ||
                      order.status == 'Delivered' ? (
                      <small className='topicos topiccolor3'>
                        {' '}
                        {order.status}
                      </small>
                    ) : order.status == 'RTO' ? (
                      <small className='topicos topiccolor4'>
                        {' '}
                        {order.status}
                      </small>
                    ) : (
                      order.status
                    )}
                    <br />
                    {order.isFufilled && (
                      <a href={`${order.trackingLink}`} target='_blank'>
                        Track order
                      </a>
                    )}
                    {shippingLabels &&
                      shippingLabels.map(
                        (label) =>
                          label.shiprocketLabelURL && (
                            <a
                              key={label.shiprocketLabelURL}
                              href={label.shiprocketLabelURL}
                              target='_blank'
                            >
                              {' '}
                              Shipping Label
                            </a>
                          )
                      )}
                  </p>
                  <p>
                    Paid:{' '}
                    {order.isPaid ? (
                      <i
                        className='fas fa-check'
                        style={{ color: 'green' }}
                      ></i>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}{' '}
                    Confirmed:
                    {order.isConfirmed ? (
                      <>
                        {' '}
                        <i
                          className='fab fa-diaspora'
                          style={{ color: 'blue' }}
                        ></i>
                      </>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                    {!order.isPaid && !order.isFufilled && (
                      <>
                        <br />
                        <Button
                          type='button'
                          variant='contained'
                          className='admin-button'
                          onClick={sendCODOTP}
                        >
                          Send COD OTP
                        </Button>
                        {orderOTPSuccess && <>OTP Sent</>}
                      </>
                    )}
                  </p>
                  {/* <p><strong>Email:</strong><a href={`mailto:${order.user.email}`}>{order.user.email}</a></p> */}
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className='whatsappmsgs'>
                    {messages && messages.length == 0 ? (
                      <div>No Messages</div>
                    ) : (
                      messages &&
                      messages.length > 0 &&
                      messages.map(
                        (message) =>
                          message.eventType == 'message' && (
                            <p>
                              <span
                                style={{ fontWeight: 'bold', fontSize: '16px' }}
                              >
                                {message.owner
                                  ? '99bookscart'
                                  : order.shippingAddress.name}{' '}
                              </span>
                              :{message.text}
                              <br />
                              <TimeAgo timestamp={message.created}></TimeAgo>
                            </p>
                          )
                      )
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h5>Order Items</h5>
                  {order.orderItems.length == 0 ? (
                    <Message>Order is Empty</Message>
                  ) : (
                    <ListGroup variant='flush'>
                      {order.orderItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={2} xs={2}>
                              <Image
                                src={item.image}
                                alt={item.name}
                                fluid
                                rounded
                                className='orderImage'
                              />
                            </Col>
                            <Col m={5}>
                              <Link
                                to={`/book/${item.isbn}/${convertToSlug(
                                  item.name
                                )}`}
                              >
                                {item.name}{' '}
                                {item.variantName &&
                                  '(' + item.variantName + ')'}
                              </Link>
                              {item.sellerName &&
                                item.sellerName != '99bookscart' && (
                                  <strong>
                                    <br />
                                    Seller:{' '}
                                    <Link
                                      to={`/admin/store/${convertToSlug(
                                        item.sellerName
                                      )}/${item.store}`}
                                    >
                                      {item.sellerName}
                                    </Link>{' '}
                                    <br />
                                    {item.status && (
                                      <div> Status: {item.status}</div>
                                    )}
                                    {item.tracking && (
                                      <a href={item.tracking} target='_black'>
                                        Track Your Order
                                      </a>
                                    )}
                                  </strong>
                                )}
                              {item.name == 'Mystery Box' ||
                                (item.name == 'Surprise Box' &&
                                  item.boxtype &&
                                  (item.boxtype == 1
                                    ? '(15 Books)'
                                    : item.boxtype == 2
                                    ? '(50 Books)'
                                    : '(100 Books)'))}
                              <br />
                              <strong>
                                {item.sellerName &&
                                  item.sellerName == '99bookscart' &&
                                  item.location &&
                                  item.location.length > 0 &&
                                  `Location: ` + item.location}
                              </strong>
                              {item.isbn == 'AIBOX' && order.cartData && (
                                <AIBoxAttributes
                                  history={history}
                                  cart={order}
                                ></AIBoxAttributes>
                              )}
                            </Col>
                            <Col md={2} xs={2}>
                              <span className='productPrice'>
                                &#8377;{item.salePrice}
                              </span>
                              <br />
                              <strike>&#8377;{item.price}</strike>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                  <Row>
                    {order.cartData &&
                      order.aiItems.length > 0 &&
                      order.aiItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={2} xs={2}>
                              <Image
                                src={item.image}
                                alt={item.name}
                                fluid
                                rounded
                                className='orderImage'
                              />
                            </Col>
                            <Col m={5}>
                              <Link
                                to={`/book/${item.isbn}/${convertToSlug(
                                  item.name
                                )}`}
                              >
                                {item.name}{' '}
                                {item.variantName &&
                                  '(' + item.variantName + ')'}
                              </Link>
                              <br />
                              <span>
                                {userInfo.isAdmin &&
                                  `Location: ` + item.location}
                              </span>
                              <br />
                              {item.qty} qty
                            </Col>
                            <Col md={2} xs={2}>
                              <span className='productPrice'>
                                &#8377;{item.salePrice}
                              </span>
                              <br />
                              <strike>&#8377;{item.price}</strike>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                  </Row>
                </ListGroup.Item>
              </ListGroup>
              {userInfo.isAdmin && (
                <ListGroup.Item>
                  <Form.Group className='mb-3' controlId='tags'>
                    <Form.Label>Tags</Form.Label>
                    <Row>
                      <Col xs={12}>
                        <Form.Control
                          type='text'
                          placeholder='Tags'
                          value={tags}
                          onChange={(e) => setTags(e.target.value)}
                        />
                      </Col>
                      <Col xs={12}>
                        <Button
                          variant='contained'
                          className='admin-button'
                          style={{ float: 'left' }}
                          onClick={addTags}
                        >
                          Add Tags
                        </Button>
                      </Col>
                    </Row>

                    {lineItems.length > 0 && (
                      <div>
                        {lineItems.map((item) => (
                          <small className='topic' key={item}>
                            {item}{' '}
                            <Link to='' onClick={(e) => removeTags(item, e)}>
                              X
                            </Link>
                          </small>
                        ))}
                      </div>
                    )}
                  </Form.Group>
                  <Form onSubmit={commentHandler}>
                    <FormGroup controlId='comment'>
                      <Form.Label>Comment</Form.Label>
                      <Form.Control
                        as='textarea'
                        value={comment}
                        row='3'
                        onChange={(e) => setComment(e.target.value)}
                      ></Form.Control>
                    </FormGroup>
                    <Button
                      type='submit'
                      variant='contained'
                      className='admin-button'
                    >
                      Comment
                    </Button>
                  </Form>
                  <h5>Comments</h5>
                  {order.comments.length == 0 && <Message>No Comments</Message>}
                  <ListGroup varaint='flush'>
                    {order.comments.reverse().map((comment, index) => (
                      <ListGroup.Item key={index}>
                        <Card>
                          <Card.Body>
                            <Card.Title>{comment.name}</Card.Title>
                            <Card.Text>
                              at{' '}
                              {comment.commentedAt &&
                                comment.commentedAt.substring(19, 0)}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </ListGroup.Item>
              )}
            </Col>
            <Col md={4}>
              <div style={{ maxHeight: '450px', overflow: 'scroll' }}>
                <h5> User Details</h5>
                <div style={{ fontSize: '20px' }}>
                  Total Orders:{countOrders}
                </div>
                {existingOrders &&
                  existingOrders.map((order) => (
                    <div
                      style={{
                        paddingBottom: '5px',
                        borderBottom: '1px solid #333',
                      }}
                    >
                      <a href={`/admin/order/${order._id}`} target='_blank'>
                        {order.orderName}
                        {' ==> '}
                        {order.status}
                        <br />
                        <TimeAgo timestamp={order.createdAt}></TimeAgo> <br />
                        &#8377;{order.totalPrice}
                        {order.tags &&
                          order.tags.map((tag) => (
                            <span className='topic'>{tag.name}</span>
                          ))}
                        <br />
                        Paid:{' '}
                        {order.isPaid ? (
                          <i
                            className='fas fa-check'
                            style={{ color: 'green' }}
                          ></i>
                        ) : (
                          <i
                            className='fas fa-times'
                            style={{ color: 'red' }}
                          ></i>
                        )}{' '}
                        Confirmed:
                        {order.isConfirmed ? (
                          <>
                            {' '}
                            <i
                              className='fab fa-diaspora'
                              style={{ color: 'blue' }}
                            ></i>
                          </>
                        ) : (
                          <i
                            className='fas fa-times'
                            style={{ color: 'red' }}
                          ></i>
                        )}
                      </a>
                    </div>
                  ))}
              </div>
              <p className='text-transform'>
                <h5> Order Summary</h5>
                <strong>IP: </strong>{' '}
                {order.shippingAddress.ip && order.shippingAddress.ip}
                <br />
                <strong>Name: </strong>{' '}
                {order.user ? order.user.name : order.shippingAddress.name}
                <br />
                <strong>Email: </strong>{' '}
                {order.user ? order.user.email : order.shippingAddress.email}
                <br />
                <strong>Address: </strong>
                {order.shippingAddress.address},{order.shippingAddress.address2}
                ,<br />
                <strong>City: </strong> {order.shippingAddress.city}
                <br />
                <strong>State: </strong> {order.shippingAddress.state}
                <br />
                <strong>Zip Code: </strong> {order.shippingAddress.postalCode}
                <br />
                <strong>Phone: </strong> {order.shippingAddress.phone}
                <br />
                <Button
                  variant='contained'
                  className='admin-button'
                  onClick={openEditOrder}
                >
                  Edit
                </Button>
                <Link to={`/admin/order/${order._id}/print`}>
                  <Button
                    type='button'
                    variant='contained'
                    className='admin-button'
                  >
                    Print
                  </Button>
                </Link>
              </p>
              <Card>
                <ListGroup variant='flash'>
                  <ListGroupItem>
                    <ListGroupItem>
                      <Row>
                        <Col>Items</Col>
                        <Col>&#8377;{order.itemPrice}</Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Row>
                        <Col>Shipping</Col>
                        <Col>&#8377;{order.shippingPrice}</Col>
                      </Row>
                    </ListGroupItem>
                    {order.codPrice > 0 && (
                      <ListGroupItem>
                        <Row>
                          <Col>COD Charges</Col>
                          <Col>&#8377;{order.codPrice}</Col>
                        </Row>
                      </ListGroupItem>
                    )}

                    {order.taxPrice > 0 && (
                      <ListGroupItem>
                        <Row>
                          <Col>Tax</Col>
                          <Col>&#8377;{order.taxPrice}</Col>
                        </Row>
                      </ListGroupItem>
                    )}

                    {order.discountPrice > 0 && (
                      <ListGroupItem>
                        <Row>
                          <Col>Discount</Col>
                          <Col>-&#8377;{order.discountPrice}</Col>
                        </Row>
                      </ListGroupItem>
                    )}

                    <ListGroupItem>
                      <Row>
                        <Col>Total</Col>
                        <Col>&#8377;{order.totalPrice}</Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroup.Item>
                      <h5>Payment Method</h5>
                      <p>
                        <strong>Method: </strong>
                        {order.codPrice > 0 ? 'Cash On Delivery' : 'Paid'}
                      </p>
                      {userInfo.isAdmin &&
                        !order.isCancelled &&
                        !order.isFufilled && (
                          <Button
                            variant='contained'
                            className='admin-button'
                            onClick={cancelHandler}
                          >
                            Mark as Cancelled
                          </Button>
                        )}
                    </ListGroup.Item>
                  </ListGroupItem>
                </ListGroup>

                {userInfo.isAdmin && successOrderShipment && (
                  <ListGroup.Item>
                    {order.isFufilled ? (
                      <Message variant='success'>
                        Fulfilled on:{order.fulfilledAt.substring(0, 10)}
                      </Message>
                    ) : (
                      <Message variant='danger'>Not Fulfilled</Message>
                    )}
                    <ListGroup>
                      {OrderShipment.map((shipment, index) => (
                        <ListGroup.Item key={index}>
                          AWB: {shipment.awb}
                          <br />
                          Frieght Charge: {shipment.totalCharge}
                          <br />
                          COD Charges: {shipment.codCharge}
                          <br />
                          Courier: {shipment.courierName}
                          <br />
                          Shipping Label:{' '}
                          <a href={shipment.shiprocketLabelURL} target='_blank'>
                            Download
                          </a>
                          <br />
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </ListGroup.Item>
                )}
              </Card>
            </Col>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Enter Tracking Link</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={deliverHandler}>
                  <FormGroup controlId='comment'>
                    <Form.Label>Tracking</Form.Label>
                    <Form.Control
                      as='textarea'
                      value={tLink}
                      row='3'
                      onChange={(e) => setTLink(e.target.value)}
                      required
                    ></Form.Control>
                  </FormGroup>
                  <Button
                    type='submit'
                    variant='contained'
                    className='admin-button'
                  >
                    Fullfill Order
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </Row>

          <Modal show={showDimensions} onHide={handleCloseDimension}>
            <Container>
              <Row>
                <Col className='text-center'>
                  <br />

                  <h1>Enter dimensions & weight of the package</h1>
                  <br />
                </Col>
              </Row>
              <Modal.Body>
                <Container>
                  <Form onSubmit={createShipmentHandler} className='cart-form'>
                    <Form.Group>
                      <Form.Label>Weight in kg</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Weight'
                        required
                        value={weight || ''}
                        onChange={(e) => setWeight(e.target.value)}
                      ></Form.Control>
                      <Form.Label>Length in cm</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Length'
                        required
                        value={length || ''}
                        onChange={(e) => setLength(e.target.value)}
                      ></Form.Control>
                      <Form.Label>Width in cm</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Width'
                        required
                        value={width || ''}
                        onChange={(e) => setWidth(e.target.value)}
                      ></Form.Control>
                      <Form.Label>Height in cm</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Height'
                        required
                        value={height || ''}
                        onChange={(e) => setHeight(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Button
                      type='submit'
                      variant='contained'
                      className='admin-button'
                    >
                      Generate Shipment
                    </Button>
                  </Form>
                  <Button
                    variant='secondary'
                    onClick={handleCloseDimension}
                    className='center-link'
                  >
                    Close
                  </Button>
                </Container>
              </Modal.Body>
            </Container>
          </Modal>
          <Modal show={showAvailableCourier} onHide={handleCloseDimension}>
            <Modal.Body>
              <Container style={{ padding: '20px' }}>
                {loadingShipment ? (
                  <Loader></Loader>
                ) : errorShipment ? (
                  <Message>{errorShipment}</Message>
                ) : (
                  <>
                    <div>
                      {shipments &&
                        shipments.map((data) => (
                          <div xs={12}>
                            <div>
                              {data.courier_name}
                              In days: {data.estimated_delivery_days} Rate:
                              {data.rate} Rating
                              {data.rating}
                            </div>
                            <Button
                              onClick={(e) =>
                                genLable(data.courier_company_id, sid)
                              }
                            >
                              {' '}
                              Select
                            </Button>
                          </div>
                        ))}
                    </div>
                    {/* <Button
                      onClick={genLable}
                      variant='primary'
                      className='float-right'
                    >
                      Generate Label
                    </Button>

                    <Button
                      variant='secondary'
                      onClick={handleCloseAvailableCourier}
                      className='center-link'
                    >
                      Close
                    </Button> */}
                  </>
                )}
              </Container>
            </Modal.Body>
          </Modal>
          <Modal show={editOrder} onHide={handleCloseEditOrder}>
            <Container>
              <Row>
                <Col className='text-center'>
                  <br />

                  <h1>Edit Order {order.orderName}</h1>
                  <br />
                </Col>
              </Row>
              <Modal.Body>
                <Container>
                  <Form onSubmit={editOrderHandler} className='cart-form'>
                    <Form.Group>
                      <TextField
                        id='outlined-basic'
                        label='Address'
                        variant='outlined'
                        required
                        type='Text'
                        autoComplete='off'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        style={{ width: '100%', marginBottom: '10px' }}
                      />
                      <TextField
                        id='outlined-basic'
                        label='Apartment, Suite, etc (Optional)'
                        variant='outlined'
                        type='Text'
                        autoComplete='off'
                        value={address2}
                        onChange={(e) => setAddress2(e.target.value)}
                        style={{ width: '100%', marginBottom: '10px' }}
                      />

                      <TextField
                        id='outlined-basic'
                        label='City'
                        variant='outlined'
                        type='Text'
                        required
                        autoComplete='off'
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        style={{ width: '48%', marginBottom: '10px' }}
                      />
                      <TextField
                        id='outlined-basic'
                        label='Zip Code'
                        variant='outlined'
                        type='Number'
                        required
                        autoComplete='off'
                        error={zipError ? true : false}
                        helperText={zipError ? zipError : ''}
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        style={{
                          width: '48%',
                          marginBottom: '10px',
                          marginLeft: '10px',
                        }}
                      />

                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>
                          State
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          required
                          label='State'
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        >
                          {SHIPPING_STATE.map((state) => (
                            <MenuItem value={state} key={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <TextField
                        id='outlined-basic'
                        label='Phone Number'
                        variant='outlined'
                        type='phone'
                        margin='normal'
                        autoComplete='off'
                        error={phoneError ? true : false}
                        required
                        helperText={phoneError ? phoneError : ''}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        style={{ width: '100%' }}
                      />
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>
                          Payment Method
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          required
                          label='State'
                          value={payment}
                          onChange={(e) => setPayment(e.target.value)}
                        >
                          <MenuItem value={'true'} key={'paid'}>
                            Paid
                          </MenuItem>
                          <MenuItem value={'false'} key={'cod'}>
                            COD
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <br />
                      <Button
                        type='submit'
                        variant='contained'
                        className='admin-button'
                      >
                        Save
                      </Button>
                      <Button
                        variant='contained'
                        className='admin-button'
                        onClick={handleCloseEditOrder}
                      >
                        Close
                      </Button>
                    </Form.Group>
                  </Form>
                </Container>
              </Modal.Body>
            </Container>
          </Modal>
        </Container>
      )}
    </Container>
  )
}

export default AdminOrderScreen
