import React, { useState, useEffect } from 'react'

function TimeAgo({ timestamp }) {
  const [timeAgo, setTimeAgo] = useState('')

  useEffect(() => {
    // Function to convert UTC timestamp to IST
    const convertUTCtoIST = (utcTimestamp) => {
      const utcDate = new Date(utcTimestamp)
      // Convert UTC to IST by adding 5 hours and 30 minutes
      utcDate.setHours(utcDate.getHours() + 0)
      utcDate.setMinutes(utcDate.getMinutes() + 0)

      return utcDate
    }

    // Function to calculate time difference and return in 'X time ago' format
    const getTimeAgo = (timestamp) => {
      const currentTime = new Date()
      const convertedTimestamp = convertUTCtoIST(timestamp)
      const timeDifference = currentTime - convertedTimestamp

      // Convert milliseconds to seconds
      const seconds = Math.floor(timeDifference / 1000)

      let interval = Math.floor(seconds / 31536000)

      if (interval >= 1) {
        return `${interval} year${interval > 1 ? 's' : ''} ago`
      }
      interval = Math.floor(seconds / 2592000)
      if (interval >= 1) {
        return `${interval} month${interval > 1 ? 's' : ''} ago`
      }
      interval = Math.floor(seconds / 86400)
      if (interval >= 1) {
        return `${interval} day${interval > 1 ? 's' : ''} ago`
      }
      interval = Math.floor(seconds / 3600)
      if (interval >= 1) {
        return `${interval} hour${interval > 1 ? 's' : ''} ago`
      }
      interval = Math.floor(seconds / 60)
      if (interval >= 1) {
        return `${interval} minute${interval > 1 ? 's' : ''} ago`
      }
      return `${Math.floor(seconds)} second${
        Math.floor(seconds) > 1 ? 's' : ''
      } ago`
    }

    // Set the time ago string
    setTimeAgo(getTimeAgo(timestamp))
  }, [timestamp])

  return <span>{timeAgo}</span>
}

export default TimeAgo
