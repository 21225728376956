import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { convertToSlug2, getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import { register } from '../../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'
import SocialLogin from '../../../components/SocialLogin'

import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/plots/meta/HomeMeta'
import { getStore, getStoreById } from '../../../actions/storeActions'
import SellerProductView from '../components/SellerProductView'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { sendStoreOTP, storeOTPVerify } from '../../../actions/userActions'
import SellerProduct from '../../../components/SellerProduct'
import BookPaginate from '../../../components/paginate/BookPaginate'

const StoreHomeScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const getStoreByIdRes = useSelector((state) => state.getStoreById)
  const { loading, error, success, store, products, pages, page } =
    getStoreByIdRes

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getStoreById(match.params.id, pageNumber))
  }, [dispatch, userLoggedIn])
  return (
    <>
      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          success &&
          (store.storeName ? (
            <Container>
              {pageNumber == 1 && (
                <Row>
                  <Col>
                    <div className='text-center '>
                      <LazyLoadImage
                        src={store.storeImage}
                        height={'250'}
                        width={'250'}
                        // alt={`${userLoggedIn.name} 99bookscart profile image`}
                      />

                      <br />

                      <div
                        style={{ width: '350px', color: '#fff' }}
                        className='center-block'
                      ></div>
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <h1 className='text-center'>{store.storeName}</h1>
                </Col>
              </Row>
              <Container>
                <Row>
                  {products.map((product) => (
                    <Col
                      key={product._id}
                      sm={4}
                      md={4}
                      lg={2}
                      xs={4}
                      className='product-grid-size'
                    >
                      <Route
                        render={({ history }) => (
                          <SellerProduct history={history} product={product} />
                        )}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
              <Row>
                <BookPaginate
                  pages={pages}
                  page={page}
                  category={'top-rated'}
                  url={`store/${convertToSlug2(store.storeName)}/${store._id}`}
                ></BookPaginate>
              </Row>
            </Container>
          ) : (
            <Link to={'/seller/create/new/store'} className='btn'>
              <Button> Create Store</Button>
            </Link>
          ))
        )}
      </Container>
    </>
  )
}

export default StoreHomeScreen
