import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { TextField } from '@mui/material'
import FormContainer from '../components/FormContainer'
import { unsubscribeEmail } from '../actions/userActions'
import Message from '../components/Message'

const UnsubscribeScreen = ({ history }) => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')

  const unsubscribe = useSelector((state) => state.unsubscribe)
  const { loading, success, error, data } = unsubscribe
  useEffect(() => {
    window.scrollTo(0, 0)
    if (success) {
      setEmail('')
    }
  }, [dispatch, success])

  const unsubscribeHandler = (e) => {
    e.preventDefault()
    dispatch(unsubscribeEmail(email))
  }
  return (
    <Container>
      <Container>
        <Row>
          <h2>Unsubscribe</h2>
          <p>
            We're sorry to see you go! If you no longer wish to receive updates
            from us, please enter your email address below and click
            'Unsubscribe'. We'll remove you from our mailing list promptly.
            Remember, you can always resubscribe at any time in the future if
            you change your mind. Thank you for your past engagement with us.
          </p>
        </Row>
        <FormContainer>
          <form onSubmit={unsubscribeHandler}>
            <Row>
              {success && <Message>{data && data}</Message>}
              <Col>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  required
                  type='Email'
                  autoComplete='off'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type='submit'
                  variant='primary'
                  style={{ float: 'right' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Unsubscribe
                </Button>
              </Col>
            </Row>
          </form>
        </FormContainer>
      </Container>
    </Container>
  )
}

export default UnsubscribeScreen
