import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserCoins } from '../actions/coinActions'
import Paginate from '../components/Paginate'
import { MdAddAlert } from 'react-icons/md'
import { BsBoxSeam, BsCoin } from 'react-icons/bs'
import { FaTruck, FaCreditCard } from 'react-icons/fa'
import {
  reedemDiscountCode,
  listUserDiscountCode,
} from '../actions/discountCodeActions'
import { getWishlist, removeWishlist } from '../actions/wishlistActions'
import Product from '../components/Product'
import { Link } from 'react-router-dom'
import { getUserReadings } from '../actions/readingActions'
const UserSubscriptionScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userReadingList = useSelector((state) => state.getUserReading)
  const { loading, error, success, readingList } = userReadingList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserReadings())
    } else {
      history.push('/login')
    }
  }, [dispatch, history])

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          {success && (
            <>
              <h1>Your Subscrption</h1>
              <Row>
                <div>
                  <h2>Activate your subscription</h2>
                  <p>
                    You are enrolling in automatic payments of ₹299/month (plus
                    tax) beginning at the end of your free trial. You can cancel
                    anytime from your Account Settings.{' '}
                  </p>
                  <Button>Start Subscrption</Button>
                </div>
              </Row>
            </>
          )}
        </Container>
      )}
    </>
  )
}

export default UserSubscriptionScreen
