import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Form,
  FormGroup,
  Modal,
  Container,
} from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'

import { getCartDetails } from '../actions/cartActions'
import { createOrder, createOrderAB } from '../actions/orderActions'
import AdminNav from '../components/AdminNav'
import AIBoxAttributes from '../components/AIBoxAttributes'
import { convertToSlug } from '../helpers/helpers'

const AbandonCartScreen = ({ match, history }) => {
  const cartId = match.params.id
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const abandonCartDetail = useSelector((state) => state.abandonCartDetail)
  const { abandonCart: cart, loading, error } = abandonCartDetail

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success: orderSuccess, error: successError } = orderCreate
  const createOrders = (type) => {
    const paymentResult = {
      id: uuidv4(),
      rid: type == 'online' ? uuidv4() : null,
      signature: null,
    }
    dispatch(
      createOrderAB({
        paymentMethod: type,
        shippingMethod: 'Standard',
        discount: {},
        paymentResult: paymentResult,
        cartId: cart._id,
      })
    )
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userInfo && !userInfo.isAdmin) {
      history.push('/login')
    }
    dispatch(getCartDetails(cartId))
    if (order && orderSuccess) {
      if (!order.isPaid) {
        history.push(`/order/${order._id}/orderconfirm/cod`)
      } else {
        history.push(
          `/order/${order._id}/orderconfirm/${order.paymentResult.rid}`
        )
      }
    }
  }, [dispatch, cartId, orderSuccess, order])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <>
          <AdminNav></AdminNav>
          <Row>
            <Col>
              <h4>Abandon Cart Details</h4>
            </Col>
            <Col>
              <Link
                to='/admin/abandoncartlist'
                className='btn btn-light float-right'
              >
                Go back
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h2>Shipping</h2>
                  {cart.shippingAddress && (
                    <>
                      <p>
                        <strong>Address:</strong>
                        <br />
                        {cart.shippingAddress.name && cart.shippingAddress.name}
                        <br />
                        {cart.shippingAddress.email &&
                          cart.shippingAddress.email}
                        <br />
                        {cart.shippingAddress.address &&
                          cart.shippingAddress.address}
                        , <br />
                        {cart.shippingAddress.address2 &&
                          cart.shippingAddress.address2}
                        ,<br />
                        <strong>City: </strong>{' '}
                        {cart.shippingAddress.city && cart.shippingAddress.city}
                        <br />
                        <strong>State: </strong>{' '}
                        {cart.shippingAddress.state &&
                          cart.shippingAddress.state}
                        <br />
                        <strong>Zip code: </strong>{' '}
                        {cart.shippingAddress.postalCode &&
                          cart.shippingAddress.postalCode}
                        <br />
                        <strong>Phone: </strong>{' '}
                        {cart.shippingAddress.phone &&
                          cart.shippingAddress.phone}
                      </p>
                    </>
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <h2>Order Items</h2>
                  {cart.cartItems.length == 0 ? (
                    <Message>Order is Empty</Message>
                  ) : (
                    <ListGroup variant='flush'>
                      {cart.cartItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={2} xs={2}>
                              <Image
                                src={item.image}
                                alt={item.name}
                                fluid
                                rounded
                                className='cartImage'
                              />
                            </Col>
                            <Col m={5}>
                              <Link
                                to={`/book/${item.isbn}/${convertToSlug(
                                  item.name
                                )}`}
                              >
                                {item.name}
                              </Link>
                              <br />
                              <span>
                                {item.variantName &&
                                  '(' + item.variantName + ')'}
                                <br />

                                {userInfo.isAdmin &&
                                  `Location: ` + item.location}
                              </span>
                              {item.isbn == 'AIBOX' && cart.cartData && (
                                <AIBoxAttributes
                                  history={history}
                                  cart={cart}
                                ></AIBoxAttributes>
                              )}
                            </Col>
                            {/* <Col md={2} xs={2}>
                                                <span className='productPrice'>&#8377;{item.salePrice}</span><br/><strike>&#8377;{item.price}</strike>
                                                </Col> */}
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant='flash'>
                  <ListGroupItem>
                    <Row>
                      <Col>
                        <h3>Cart Details</h3>
                        Cart Price : {cart.cartPrice && '₹' + cart.cartPrice}
                        <br /> Shipping Price :
                        {cart.shippingPrice && '₹' + cart.shippingPrice}
                        <br /> Cod Price: {cart.codPrice && '₹' + cart.codPrice}
                        <br /> Discount:{' '}
                        {cart.discountPrice && '-₹' + cart.discountPrice}
                        <br /> Total: {cart.totalPrice &&
                          '₹' + cart.totalPrice}{' '}
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Row>
                      <Col>Is Converted</Col>
                      <Col>
                        {cart.isConverted ? (
                          <>
                            <i className='fas fa-check'></i>
                          </>
                        ) : (
                          <i className='fas fa-times'></i>
                        )}
                      </Col>
                    </Row>
                    {!cart.isConverted && (
                      <>
                        <Row>
                          <Button onClick={(e) => createOrders('online')}>
                            Create Paid Order
                          </Button>
                        </Row>
                        <Row>
                          <Button onClick={(e) => createOrders('cod')}>
                            Create COD Order
                          </Button>
                        </Row>
                      </>
                    )}
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default AbandonCartScreen
