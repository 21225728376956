import {
  ASSIGN_AWB_FAIL,
  ASSIGN_AWB_REQUEST,
  ASSIGN_AWB_SUCCESS,
  GENERATE_SHIPPMENT_FAIL,
  GENERATE_SHIPPMENT_REQUEST,
  GENERATE_SHIPPMENT_SUCCESS,
  GET_SHIPPMENT_FAIL,
  GET_SHIPPMENT_REQUEST,
  GET_SHIPPMENT_SUCCESS,
  LIST_SHIPPMENT_FAIL,
  LIST_SHIPPMENT_REQUEST,
  LIST_SHIPPMENT_SUCCESS,
} from '../constants/shipmentConstants'
import axios from 'axios'
export const createShipment =
  (orderId, weight, height, width, length, seller) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GENERATE_SHIPPMENT_REQUEST,
      })
      const dimensions = {
        weight: weight,
        height: height,
        width: width,
        length: length,
        seller: seller,
      }
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      var data
      if (seller) {
        data = await axios.post(
          `/api/shipment/${orderId}/seller`,
          dimensions,
          config
        )
      } else {
        data = await axios.post(`/api/shipment/${orderId}`, dimensions, config)
      }

      dispatch({
        type: GENERATE_SHIPPMENT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GENERATE_SHIPPMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const assignAwb = (cid, sid, orderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSIGN_AWB_REQUEST,
    })
    const shipmentData = {
      cid: cid,
      sid: sid,
    }
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(
      `/api/shipment/${orderId}/assignawb`,
      shipmentData,
      config
    )
    dispatch({
      type: ASSIGN_AWB_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ASSIGN_AWB_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getShipmentDetails =
  (shipmentId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_SHIPPMENT_REQUEST,
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(`/api/shipment/${shipmentId}`, config)
      dispatch({
        type: GET_SHIPPMENT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_SHIPPMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listShipments = (pagenumber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LIST_SHIPPMENT_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(
      `/api/shipment/list?pageNumber=${pagenumber}`,
      config
    )
    dispatch({
      type: LIST_SHIPPMENT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LIST_SHIPPMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
