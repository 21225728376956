import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
  Form,
  Modal,
} from 'react-bootstrap'
import { listProductsDetails } from '../../actions/productActions.js'
import Loader from '../../components/Loader.js'
import Message from '../../components/Message.js'
import Rating from '../../components/Rating.js'
import SurpriseStats from '../../components/SurpriseStats.js'
import { userRegisterLP, userAddressLP } from '../../actions/userActions.js'
import { BoxDescription } from '../../components/BoxDescription.js'
import { addToCart } from '../../actions/cartActions.js'
import { SHIPPING_STATE } from '../../constants/orderConstants.js'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { TextField } from '@mui/material'
import { createOrder } from '../../actions/orderActions.js'
import { FaDownload } from 'react-icons/fa'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const cookies = new Cookies()
const EbookBoxScreen = ({ match, history }) => {
  const dispatch = useDispatch()
  const [vprice, setVprice] = useState(999)
  const [vname, setVname] = useState('Mixed Fiction')
  const [vId, SetVid] = useState(0)
  const [show, setShow] = useState(false)
  const [showNext, setShowNext] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [state, setState] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [zipError, setZipError] = useState(null)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product, success, readingCount } = productDetails

  const userRegisterLPRes = useSelector((state) => state.userRegisterLP)
  const { success: lpRegSuccess, userData } = userRegisterLPRes

  const userAddressLPRes = useSelector((state) => state.userAddressLP)
  const { success: lpAddSuccess, userData2 } = userAddressLPRes

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success: orderSuccess } = orderCreate

  var reviewsLenght = 0
  var reviewsList = []

  const handleClose = () => {
    setShow(false)
  }
  const handleCloseNext = () => {
    setShowNext(false)
  }
  function isEmail(email) {
    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (email !== '' && email.match(emailFormat)) {
      return true
    }
    return false
  }
  const submitHandlerNext = (e) => {
    e.preventDefault()
    dispatch(
      userAddressLP({
        address,
        address2,
        city,
        postalCode,
        state,
        uid: userData._id,
        cid: cookies.get('_cid'),
      })
    )
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    if (isEmail(email)) {
      setEmailError('')
      var phonenoExp = /^\d{10}$/
      if (phonenoExp.test(phone)) {
        setPhoneError('')

        dispatch(
          userRegisterLP({
            email,
            phone,
          })
        )
      } else {
        setPhoneError('Enter Valid Phone Number')
      }
    } else {
      setEmailError('Enter Valid email')
    }
  }
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay))
  }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  useEffect(() => {
    if (!product || !product._id || product.isbn != 'self-help-bundle') {
      window.scrollTo(0, 0)
      dispatch(listProductsDetails('self-help-bundle', 'self-help-bundle'))
      loadScript('https://checkout.razorpay.com/v1/checkout.js')
    }

    if (lpRegSuccess) {
      setShow(false)

      var options = {
        //key: 'rzp_test_Uc74kK1ulMDQoS',
        key: 'rzp_live_04AG18531Cjx9B',
        amount: 99 * 100,
        currency: 'INR',
        name: phone,
        description: '99bookscart',
        order_id: userData.presults.id,
        image:
          'https://cdn.shopify.com/s/files/1/0287/9612/5316/files/99bookscart_759b028b-6768-47b8-9d79-ee2cf0190e04_180x.png?v=1646232311',
        handler: async (response) => {
          const paymentResult = {
            id: response.razorpay_payment_id,
            rid: response.razorpay_order_id,
            signature: response.razorpay_signature,
          }
          setShowNext(true)
        },
        prefill: {
          name: phone,
          email: email,
          contact: phone,
        },
        theme: {
          color: '#4267b2',
        },
      }

      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }
  }, [vprice, vname, lpRegSuccess, lpAddSuccess, product, orderSuccess, order])

  const BuyNowHandler = (e) => {
    e.preventDefault()
    setShow(true)
    //setShowNext(true)
  }
  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <>
          <Row className='ldp-ma'>
            <div className='ldp-fs'>
              <img
                src='../../images/logo.png'
                className='siteLogo'
                alt='99bookscart logo'
              />
              <br />
              <br />
              ✨ Be More Curious!
              <br /> ✨ Be More Knowledgable!
              <br /> ✨ Be More Succeesful!
              <br /> ✨ Be More Happy!
              <br />
              <br />
              <small>
                💡 Your mind is your greatest asset – it’s time to let it
                explore the unexplored! 💡
                <br />
              </small>
            </div>
          </Row>
          <>
            <>
              <Row>
                <br />
                <br />
                <Container>
                  <h1
                    className='ldp-mh'
                    style={{ paddingLeft: '8px', paddingRight: '8px' }}
                  >
                    Unlock Your Full Potential with The Ultimate
                    Self-Transformation Collection!
                  </h1>

                  <div className='text-center-rating text-center'>
                    <div>Instant Download!</div>

                    <Rating value={4.8} />
                    <small>45 Reviews</small>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <LazyLoadImage
                      src='https://s3-books-images.s3.amazonaws.com/51ea3aad-977f-4505-afc2-c49e8dd25c6d.png'
                      width={'90%'}
                      style={{
                        marginLeft: '20px',
                        maxWidth: '800px',
                      }}
                    ></LazyLoadImage>
                  </div>
                  <div>
                    <p className='ldp-sh'>
                      🚀11 Life-Changing Self-Help eBooks for Just ₹99/-. Invest
                      in Yourself Today!📚
                    </p>
                    <p className='ldp-sh'>
                      <h3>5 Free Books</h3>
                      <Row style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <Col>
                          <LazyLoadImage
                            src='https://s3-books-images.s3.amazonaws.com/6b15f8a3-d6d5-43eb-87d3-494dcf8153d7.png'
                            width={'90%'}
                            style={{}}
                          ></LazyLoadImage>
                        </Col>
                        <Col>
                          {' '}
                          <LazyLoadImage
                            src='https://s3-books-images.s3.amazonaws.com/9766a589-cad5-4ab4-9690-cb2c8462d230.png'
                            width={'90%'}
                            style={{}}
                          ></LazyLoadImage>
                        </Col>
                        <Col>
                          {' '}
                          <LazyLoadImage
                            src='https://s3-books-images.s3.ap-south-1.amazonaws.com/fed19001-87a0-42dc-8887-48b43a327151.png'
                            width={'90%'}
                            style={{}}
                          ></LazyLoadImage>
                        </Col>
                        <Col>
                          {' '}
                          <LazyLoadImage
                            src='https://s3-books-images.s3.ap-south-1.amazonaws.com/47eb5c0f-44dc-4570-8bc4-d8365c84b37b.png'
                            width={'90%'}
                            style={{}}
                          ></LazyLoadImage>
                        </Col>
                        <Col>
                          {' '}
                          <LazyLoadImage
                            src='https://s3-books-images.s3.ap-south-1.amazonaws.com/b877faf9-2311-439e-9246-c503d70b980a.png'
                            width={'90%'}
                            style={{}}
                          ></LazyLoadImage>
                        </Col>
                      </Row>
                    </p>
                  </div>
                </Container>
                {success && (
                  <>
                    <Row style={{ marginBottom: '120px' }} className='ddd'>
                      <Row>
                        <div
                          className='text-center'
                          style={{ fontSize: '26px', fontWeight: 'bold' }}
                        >
                          ₹99/-
                        </div>
                        <div className='text-center'>87% OFF</div>
                      </Row>
                      <Row>
                        <div className='text-center'>
                          <Button className='box-buy' onClick={BuyNowHandler}>
                            Get Instant Access Now!
                          </Button>
                        </div>
                      </Row>
                      <Row>
                        <div style={{ textAlign: 'center' }}>
                          <br />
                          <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                            Seen on
                          </div>
                          <div>
                            <LazyLoadImage
                              src='../images/toi.png'
                              width={'140px'}
                            ></LazyLoadImage>
                            <LazyLoadImage
                              src='../images/startupindia.png'
                              width={'140px'}
                            ></LazyLoadImage>

                            <LazyLoadImage
                              src='../images/ownstartup.png'
                              width={'140px'}
                            ></LazyLoadImage>
                            <LazyLoadImage
                              src='../images/thehindu.jpg'
                              width={'140px'}
                            ></LazyLoadImage>
                          </div>
                        </div>
                        <div className='ebookdesc'>
                          <section class='section' style={{ padding: '8px' }}>
                            <h2 class='section-title'>
                              Why Choose This Book Bundle?
                            </h2>
                            <ul class='benefits-list'>
                              <li>
                                11 Essential Self-Help eBooks in One
                                Comprehensive Bundle
                              </li>
                              <li>
                                Boost Motivation, Improve Productivity, and
                                Achieve Financial Freedom
                              </li>
                              <li>Just ₹99/- (Limited Time Offer)</li>
                              <li>Instant Digital Access – Start Today!</li>
                            </ul>
                          </section>
                          <section>
                            <img
                              src='https://s3-books-images.s3.amazonaws.com/3da442d7-8201-47aa-a64d-7d274f68464d.png'
                              width={'300px'}
                            />
                          </section>
                          <section class='section'>
                            <div class='container'>
                              <h2 class='section-title'>
                                What’s Inside? Discover Your Path to Growth
                              </h2>
                              <p>
                                Our carefully curated bundle brings together 11
                                transformative ebooks that cover every key area
                                of self-development. Here's a glimpse of what
                                you'll get:
                              </p>

                              <div class='book-list'>
                                <div class='book-category'>
                                  <h3>🌟 Personal Growth & Motivation</h3>
                                  <ul>
                                    <li>
                                      <strong>Unleash Your Inner Power:</strong>{' '}
                                      A Transformative Guide to Personal Growth
                                    </li>
                                    <li>
                                      <strong>Fuel Your Fire:</strong> The
                                      Ultimate Guide to Staying Motivated Daily
                                    </li>
                                    <li>
                                      <strong>Rise Above:</strong> Unlock the
                                      Secrets of Unstoppable Motivation
                                    </li>
                                  </ul>
                                </div>

                                <div class='book-category'>
                                  <h3>⏳ Time Management & Productivity</h3>
                                  <ul>
                                    <li>
                                      <strong>Time Mastery:</strong> Unlock the
                                      Secrets to Focus and Peak Productivity
                                    </li>
                                    <li>
                                      <strong>
                                        Micro Habits, Massive Impact:
                                      </strong>{' '}
                                      The Formula for Life-Changing Success
                                    </li>
                                  </ul>
                                </div>

                                <div class='book-category'>
                                  <h3>🧘 Mental Health & Well-being</h3>
                                  <ul>
                                    <li>
                                      <strong>The Mindful Way:</strong> A
                                      Journey to Lasting Inner Peace
                                    </li>
                                    <li>
                                      <strong>Calm in Chaos:</strong> Proven
                                      Techniques to Conquer Anxiety and Find
                                      Peace
                                    </li>
                                  </ul>
                                </div>

                                <div class='book-category'>
                                  <h3>💬 Relationships & Communication</h3>
                                  <ul>
                                    <li>
                                      <strong>The Communication Code:</strong>{' '}
                                      Master the Art of Building Deep
                                      Connections
                                    </li>
                                    <li>
                                      <strong>The Power of Boundaries:</strong>{' '}
                                      Crafting Healthy Relationships with
                                      Confidence
                                    </li>
                                  </ul>
                                </div>

                                <div class='book-category'>
                                  <h3>💸 Financial Mastery</h3>
                                  <ul>
                                    <li>
                                      <strong>The Wealth Mindset:</strong>{' '}
                                      Achieve Financial Freedom and Design Your
                                      Dream Life
                                    </li>
                                    <li>
                                      <strong>Freedom by Design:</strong> Your
                                      Roadmap to Financial Independence
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <p>
                                Each ebook is packed with actionable insights,
                                empowering you to take control of every aspect
                                of your life. Whether you want to boost your
                                productivity, improve your relationships, or
                                master your finances, this bundle has you
                                covered!
                              </p>
                            </div>
                          </section>

                          <section class='cta-section'>
                            <div class='container'>
                              <h2>
                                Get All 11 eBooks for ₹99/- – Limited Time
                                Offer!
                              </h2>
                              <p>Instant Digital Download – Don't Miss Out!</p>
                              <a
                                href='#pricing'
                                class='cta-button'
                                onClick={BuyNowHandler}
                              >
                                Download Now
                              </a>
                            </div>
                          </section>

                          <section class='testimonials'>
                            <div class='container'>
                              <h2 class='section-title'>
                                What People Are Saying About These Books
                              </h2>
                              <div class='testimonial'>
                                <img
                                  src='https://s3-books-images.s3.amazonaws.com/e9a80379-0074-49c8-8120-e904179a5a7a.png'
                                  alt="A. Patel's Profile Picture"
                                  class='testimonial-img'
                                />
                                <div class='testimonial-text'>
                                  <p>
                                    “These books completely transformed how I
                                    approach my goals and daily habits!”
                                  </p>
                                  <p>
                                    <strong>– A. Patel</strong>
                                  </p>
                                </div>
                              </div>
                              <div class='testimonial'>
                                <img
                                  src='https://s3-books-images.s3.amazonaws.com/150d280c-ec40-4499-8090-c60f36527dc2.png'
                                  alt="S. Gupta's Profile Picture"
                                  class='testimonial-img'
                                />
                                <div class='testimonial-text'>
                                  <p>
                                    “The best investment I've made in myself!
                                    Practical, powerful, and life-changing.”
                                  </p>
                                  <p>
                                    <strong>– S. Gupta</strong>
                                  </p>
                                </div>
                              </div>
                              <div class='testimonial'>
                                <img
                                  src='https://s3-books-images.s3.ap-south-1.amazonaws.com/06a01f8d-0e8c-4b26-a186-756001675178.png'
                                  alt="R. Singh's Profile Picture"
                                  class='testimonial-img'
                                />
                                <div class='testimonial-text'>
                                  <p>
                                    “I have seen tremendous improvement in my
                                    productivity and mindset thanks to this
                                    collection.”
                                  </p>
                                  <p>
                                    <strong>– R. Singh</strong>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </section>

                          <section id='pricing' class='pricing-section'>
                            <div class='container'>
                              <h2>
                                Limited Time Offer: ₹99/- for 11 Life-Changing
                                Books!
                              </h2>
                              <p class='original-price'>₹2,499/-</p>
                              <p class='discount-price'>₹99/-</p>
                              <Button
                                className='box-buy'
                                onClick={BuyNowHandler}
                              >
                                Buy Now and Start Your Transformation!
                              </Button>
                            </div>
                          </section>

                          <section class='faq-section'>
                            <div class='container'>
                              <h2>Frequently Asked Questions</h2>
                              <div class='faq'>
                                <div class='faq-item'>
                                  <h4>How will I receive the books?</h4>
                                  <p>
                                    You will get instant access to download all
                                    ebooks after purchase.
                                  </p>
                                </div>
                                <div class='faq-item'>
                                  <h4>Can I read the books on any device?</h4>
                                  <p>
                                    Yes, the ebooks are compatible with all
                                    devices, including smartphones, tablets, and
                                    computers.
                                  </p>
                                </div>
                                <div class='faq-item'>
                                  <h4>Is there a refund policy?</h4>
                                  <p>
                                    Since this is a digital product, all sales
                                    are final. However, if you have issues, feel
                                    free to contact us.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </Row>
                    </Row>
                  </>
                )}
              </Row>
            </>
          </>
        </>
      )}
      <Modal show={show} onHide={handleClose} backdrop='static'>
        <Modal.Body>
          <Form onSubmit={submitHandler2}>
            <div style={{ padding: '20px', textAlign: 'center' }}>
              <Form.Group>
                <div style={{ marginBottom: '20px' }}>
                  <h5>One Life-Changing Self Help Bundle</h5>
                </div>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    🌟 <strong>Personal Growth & Motivation</strong>
                  </li>
                  <li>
                    ⏳ <strong>Time Management & Productivity</strong>
                  </li>
                  <li>
                    🧘 <strong>Mental Health & Well-being</strong>
                  </li>
                  <li>
                    💬 <strong>Relationships & Communication</strong>
                  </li>
                  <li>
                    💸 <strong>Financial Mastery</strong>
                  </li>
                </ul>
                <div> Instant Download 11 Self Help books.</div>
                <br />
                {/* <img
                  src='https://s3-books-images.s3.amazonaws.com/3da442d7-8201-47aa-a64d-7d274f68464d.png'
                  width={'300px'}
                  style={{ textAlign: 'center' }}
                /> */}
                <TextField
                  id='outlined-basic'
                  label='Phone Number'
                  variant='outlined'
                  required
                  type='Number'
                  autoComplete='off'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ width: '100%', marginBottom: '5px' }}
                />
                <br />
                <TextField
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  required
                  type='email'
                  autoComplete='off'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: '100%', marginBottom: '5px' }}
                />
              </Form.Group>
              <Row style={{ marginTop: '9px', textAlign: 'left' }}>
                <Col>
                  <Button variant='outline-primary' onClick={handleClose}>
                    X
                  </Button>
                </Col>
                <Col>
                  <Button type='submit' className='float-right'>
                    Download Books
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showNext} onHide={handleCloseNext} backdrop='static'>
        <Modal.Body>
          <div style={{ padding: '20px' }}>
            <Row>
              <h5>Download Your Books</h5>
              <div style={{ fontSize: '22px' }}>
                Congratulations on your Self-Transformation Journey
              </div>
            </Row>
            <Row>
              <Col>
                <a
                  href='https://drive.google.com/file/d/1rw36e-R4zhx4Os4OB-JBV81N3-PfcDBO/view?usp=sharing'
                  target='_blank'
                >
                  <Button variant='outline-primary' className='float-right'>
                    <FaDownload></FaDownload> Download Books
                  </Button>
                </a>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EbookBoxScreen
// dispatch(listProductsDetails('BookscartBox1', 'surprise-box-15-books'))
