export const CREATE_BOOK_FAIR_ENTRY_REQUEST = 'CREATE_BOOK_FAIR_ENTRY_REQUEST'
export const CREATE_BOOK_FAIR_ENTRY_SUCCEES = 'CREATE_BOOK_FAIR_ENTRY_SUCCEES'
export const CREATE_BOOK_FAIR_ENTRY_FAIL = 'CREATE_BOOK_FAIR_ENTRY_FAIL'
export const CREATE_BOOK_FAIR_ENTRY_RESET = 'CREATE_BOOK_FAIR_ENTRY_RESET'

export const LIST_BOOK_FAIR_ENTRY_REQUEST = 'LIST_BOOK_FAIR_ENTRY_REQUEST'
export const LIST_BOOK_FAIR_ENTRY_SUCCEES = 'LIST_BOOK_FAIR_ENTRY_SUCCEES'
export const LIST_BOOK_FAIR_ENTRY_FAIL = 'LIST_BOOK_FAIR_ENTRY_FAIL'

export const LIST_BOOK_FAIR_ENTRY_STATS_REQUEST =
  'LIST_BOOK_FAIR_ENTRY_STATS_REQUEST'
export const LIST_BOOK_FAIR_ENTRY_STATS_SUCCEES =
  'LIST_BOOK_FAIR_ENTRY_STATS_SUCCEES'
export const LIST_BOOK_FAIR_ENTRY_STATS_FAIL = 'LIST_BOOK_FAIR_ENTRY_STATS_FAIL'
