import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import AppStory from '../../../components/AppStory'
import {
  getCategory,
  convertToSlug2,
  convertToSlug,
} from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import AppHeader from '../../../components/plots/AppHeader'

import StoryStats from '../../../components/StoryStats'
import { FaBookReader, FaLock, FaClock } from 'react-icons/fa'
import SocialShare from '../../../components/SocialShare'
import Rating from '../../../components/Rating'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const AppPlotViewScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const url = `https://www.99bookscart.com/stories/${match.params.id}`
  const [title, setTitle] = useState('')
  const [content, SetContent] = useState('')
  const [isReadMore, setIsReadMore] = useState(true)
  const getStoryRed = useSelector((state) => state.getStory)
  const {
    story,
    StoryStats: stats,
    similarStories,
    loading,
    error,
    success,
  } = getStoryRed

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const storyCoverPage = {
    width: '100px',
    float: 'right',
    borderRadius: '5px',
  }
  const storyCoverPageDesktop = {
    width: '250px',
  }
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    } else {
      if (!userLoggedIn.isSubscribed) {
        //history.push('/app/profile/billing')
      }
    }
    dispatch(getStory(match.params.id))
  }, [dispatch, match, history])
  return (
    <Container>
      <AppHeader></AppHeader>
      {loading || !success ? (
        <Loader></Loader>
      ) : (
        <Container>
          <Container>
            <Row className='story-head-section'>
              <Col xs={4}>
                <div>
                  <LazyLoadImage
                    src={story.coverImage}
                    width={'100%'}
                    alt={story.title}
                    className='storypagecover'
                  />
                </div>
              </Col>
              <Col xs={8}>
                <div style={{ padding: '10px' }}>
                  <h1>{story.title}</h1>
                  <div className='writer-name'>
                    {/* <Link to={`/user/${story.user._id}`}> */}
                    <span>{story.user && story.user.name}</span>
                    {/* </Link> */}
                  </div>

                  {/* <div className='desktop-view' style={{ width: '50%' }}>
                    <StoryStats story={story}></StoryStats>
                  </div> */}
                  <div>
                    {' '}
                    <Rating value={story.rating}></Rating>{' '}
                    <small>
                      {story.reviewCount && story.reviewCount.toLocaleString()}{' '}
                      Reviews
                    </small>
                    <br />
                    <Link to={`/app/en/plot/${story.episodes[0]._id}`}>
                      <Button className='btn-primary'>
                        <FaBookReader className='orange' />
                        &nbsp; Start reading
                      </Button>
                    </Link>
                  </div>
                  <div style={{ float: 'left', marginTop: '10px' }}>
                    <div className='storiestatslabel'>
                      <FaClock />{' '}
                      <span className='storiestatsvalue'>
                        {story.totalReadingTime}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Row style={{ padding: '8px' }}>
            <Col>
              <>
                {/* <div className='mobile-view'>
                  <StoryStats story={story}></StoryStats>
                </div> */}

                <br />
                <h3 className='storietaglabel'>What's in the book? </h3>

                <div className='storydescription'>
                  {isReadMore
                    ? ReactHtmlParser(
                        story.description && story.description.slice(0, 500)
                      )
                    : ReactHtmlParser(story.description)}
                  <div onClick={toggleReadMore} className='read-or-hide'>
                    {isReadMore ? '...read more' : ' show less'}
                  </div>
                </div>
                {story.tags.length > 0 && (
                  <div className='story-tags'>
                    <span className='storietaglabel'>Tags: </span>
                    {story.tags.map((tag) => (
                      <Link
                        to={`/stories`}
                        key={`${tag._id}`}
                        className='topic'
                      >
                        <span>{tag.name}</span>
                      </Link>
                    ))}
                  </div>
                )}
                <h2>Ideas</h2>
                <div className='storychaptersection'>
                  {story.episodes.map((episode, index) => (
                    <>
                      <Link to={`/app/en/plot/${episode._id}`}>
                        <Row key={episode._id} className='chaptersection'>
                          <Col xs={9} className='chapter-title'>
                            {'#' + (index + 1) + ' ' + episode.title}
                            <br />
                          </Col>
                          <Col xs={3} style={{ float: 'right' }}>
                            {episode.duration}in{' '}
                          </Col>
                        </Row>
                      </Link>
                    </>
                  ))}
                </div>
                <div>
                  {typeof story.category === 'string' ? (
                    <>{getCategory(story.category)}</>
                  ) : (
                    <>
                      <h3 className='storietaglabel'>Category</h3>
                      {story.category.map(
                        (cat) =>
                          cat &&
                          cat.name && (
                            <Link
                              to={`/app/en/category/${convertToSlug2(
                                cat.name
                              )}`}
                            >
                              <span key={`${cat._id}`} className='topic'>
                                <span>{cat.name}</span>
                              </span>
                            </Link>
                          )
                      )}
                    </>
                  )}
                </div>
                <SocialShare story={story}></SocialShare>
              </>
            </Col>
          </Row>
          {userLoggedIn && userLoggedIn.isAdmin && (
            <Container>
              <Row>
                <Link
                  to={`/write/story/${
                    story.episodes.length > 0
                      ? story.episodes[0]._id
                      : 'nullEpisode'
                  }/${story._id}`}
                  className='newbuttons'
                >
                  Edit
                </Link>
              </Row>
            </Container>
          )}
          <Container>
            <h1>Realted Books</h1>
            <Row>
              {similarStories.map((story) => (
                <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                  <Route
                    render={({ history }) => (
                      <AppStory history={history} story={story} />
                    )}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      )}
    </Container>
  )
}
{
}

export default AppPlotViewScreen
