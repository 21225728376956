import {
  BLOG_COMMENT_CREATET_SUCCESS,
  BLOG_COMMENT_CREATE_FAIL,
  BLOG_COMMENT_CREATE_REQUEST,
  BLOG_COMMENT_GET_FAIL,
  BLOG_COMMENT_GET_REQUEST,
  BLOG_COMMENT_GET_SUCCESS,
} from '../constants/blogCommentsConstants'

export const createBlogCommentReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_COMMENT_CREATE_REQUEST:
      return {
        loading: true,
      }
    case BLOG_COMMENT_CREATET_SUCCESS:
      return {
        loading: false,
        success: true,
        comment: action.payload,
      }
    case BLOG_COMMENT_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getBlogCommentReducer = (state = { comments: [] }, action) => {
  switch (action.type) {
    case BLOG_COMMENT_GET_REQUEST:
      return {
        loading: true,
      }
    case BLOG_COMMENT_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        comments: action.payload,
      }
    case BLOG_COMMENT_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
