import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const PosterBox = (val) => {
  return (
    <Container style={{ paddingLeft: '14px' }} className='text-center'>
      <Row>
        <Col xs={12}>
          <Container>
            <h1>Description</h1>
            <ul style={{ textAlign: 'left' }}>
              <li>
                Final product doesn't carry 99bookscart watermark. There might
                be a small signature from the artist.
              </li>
              <li>
                Poster Artwork is printed on 250 GSM high quality matte paper.
              </li>
              <li>
                Size is approximate and varies slightly based on aspect ratio.
              </li>
              <li>
                The poster is securely packed in sturdy cardboard tubes which
                prevents any wear during transportation.
              </li>
            </ul>
            <h1>Unleash Your Potential</h1>
            <p>
              Our carefully curated collection of 30 motivational posters is
              designed to inspire, motivate, and elevate your everyday
              environment. Whether you're decking out your office, classroom, or
              home gym, these vibrant posters with bold and energizing messages
              are sure to boost productivity and positivity. Featuring timeless
              quotes from world-renowned thinkers and achievers, each poster is
              a portal to potential, driving you to push limits and shatter
              boundaries.
            </p>
            <h1>Design Meets Inspiration</h1>
            <p>
              Not only are these posters crafted to inspire, but they're also
              designed with aesthetics in mind. Each 4X6 inch poster is printed
              on high-quality, durable paper with a sleek, modern finish that
              stands out in any setting. The blend of striking colors and
              minimalist design ensures that they complement any decor style,
              making them a versatile addition to your space. Perfect for
              creating a gallery wall that speaks volumes about your drive and
              passion.
            </p>
            <h1>Cost-Effective Transformation</h1>
            <p>
              Priced at just ₹499, this set of 30 motivational posters is an
              unbeatable deal that includes free shipping directly to your
              doorstep. Transform large spaces without the hefty price tag, and
              enjoy the convenience of refreshing your decor in an instant.
              Ideal for educators, team leaders, and anyone looking to inject a
              dose of motivation into their daily lives without breaking the
              bank.
            </p>
            <LazyLoadImage
              src='/images/posterbanner.JPG'
              width='80%'
              style={{ maxWidth: '700px', textAlign: 'center' }}
            />
          </Container>
        </Col>
      </Row>
    </Container>
  )
}
