import React, { useState, useEffect } from 'react'

import { Table, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listContactFormSubmission } from '../actions/formActions'
import AdminNav from '../components/AdminNav'

const ContactFromSubmissionScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const submissionList = useSelector((state) => state.listContactForm)
  const { loading, error, submits, success } = submissionList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo.isAdmin) {
      history.push('/login')
    } else if (loading) {
      dispatch(listContactFormSubmission())
    }
  }, [dispatch, history, userInfo, submits])

  return (
    <Container>
      {error && <Message>{error}</Message>}
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          {' '}
          <AdminNav></AdminNav>
          <Row className='align-items-center'>
            <Col>
              <h1>Contact Form Submission</h1>
            </Col>
          </Row>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>Name</th>
                <th>email</th>
                <th>contact</th>
                <th>MessageType</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {submits.map((submit) => (
                <tr key={submit._id}>
                  <td>{submit.name}</td>
                  <td>{submit.email}</td>
                  <td>{submit.contact}</td>
                  <td>{submit.messageType}</td>
                  <td>{submit.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  )
}

export default ContactFromSubmissionScreen
