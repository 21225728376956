import axios from 'axios'
import { LANGUAGE_PRODUCT_LIST_SUCCESS, LANGUAGE_PRODUCT_LIST_FAIL, LANGUAGE_PRODUCT_LIST_REQUST } from '../constants/languageConstants'

export const listProductsByLanguage =(language,pageNumber='')=> async(dispatch)=>{
    try{
        dispatch({type:LANGUAGE_PRODUCT_LIST_REQUST})
        const {data} = await axios.get(`/api/language/${language}/page/${pageNumber}/`)
        dispatch({
            type:LANGUAGE_PRODUCT_LIST_SUCCESS, 
            payload:data
        })
    }catch(error){
        dispatch({
            type:LANGUAGE_PRODUCT_LIST_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}
