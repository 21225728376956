import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Table, Row, Col, Image, Modal } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import { register } from '../../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'
import SocialLogin from '../../../components/SocialLogin'

import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/plots/meta/HomeMeta'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FaTree } from 'react-icons/fa'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
const SellerHomeScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [disable, setDisable] = React.useState(false)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({ name, email, password, isSocial: false, shorts: true })
      )
    }
  }
  const startSub = () => {
    if (userLoggedIn) {
      history.push('/profile')
    } else {
      history.push('/seller/register')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userLoggedIn) {
      history.push('/profile')
    }

    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        history.push('/profile')
      }
    }
  }, [dispatch, userInfo, registerSuccess, emailError])
  return (
    <>
      <Container>
        <HomeMeta></HomeMeta>
        <Container>
          <Row className='story-hero-text'>
            <Col xs={12} sm={6}>
              <div style={{ marginTop: '25px' }}>
                <Link to={'/'}>
                  <LazyLoadImage
                    src={'../../images/logo.png'}
                    width={'100%'}
                    alt={'99bookscart logo'}
                    className='siteLogo'
                  />
                </Link>

                <h1 className='hero-text mb-2'>
                  Each Book Should be read by 100 people!
                </h1>

                <p>Sell your old books & make room for new</p>

                <Button variant='contained' onClick={startSub}>
                  Register as Seller
                </Button>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div>
                <LazyLoadImage
                  src={'../images/seller/sellerdash.png'}
                  width={'100%'}
                  alt={'99bookscart plots read fast & read smart'}
                  className='hero-image-subs'
                />
              </div>
              <br />
              <p>
                <strong>
                  Register, Upload Books, Sell & Earn. It's that simple!
                </strong>
              </p>
            </Col>
          </Row>
          {/* <Row>
              <Col>
                <div className='dash-item'>
                  Sold more than 6 million second hand books & Saved a small
                  forest<FaTree></FaTree>
                </div>
                <div className='dash-subhead'>
                  Join the community of 300+ Book stores, 1300+ Book sellers &
                  2.5Lakh Readers
                </div>
              </Col>
            </Row> */}
          <Row>
            <Col className='text-center'>
              <br />
              <strong>How it works?</strong>
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <LazyLoadImage src='../images/icons/buy.png' width={'30px'} />
              <div className='dash-item'>Buy & Read your favorite books</div>
              <small>New best sellers available</small>
            </Col>

            <Col className='text-center'>
              <LazyLoadImage src='../images/icons/list.png' width={'30px'} />
              <div className='dash-item'>List your finished books</div>
              <small>Simple as entering ISBN</small>
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <LazyLoadImage src='../images/icons/ship.png' width={'30px'} />
              <div className='dash-item'>Get order & ship books</div>
              <small>Pick up service is available</small>
            </Col>
            <Col className='text-center'>
              <LazyLoadImage src='../images/icons/pay.png' width={'30px'} />
              <div className='dash-item'>Get payment & Buy more books</div>
              <small>To an authorized bank account</small>
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <small>Delivery Partner</small>
              <br />
              <LazyLoadImage
                src={'../images/home/delhivery.png'}
                width={'130px'}
              />
              <br />
              <br />
            </Col>
          </Row>
          <Row className='story-bg-points'>
            <div>
              <h2 style={{ color: '#000' }}>
                Why You should sell your books once you finish reading?
              </h2>
            </div>
            <Col xs={12} sm={4}>
              <h3 className='pb-1'>Environmental Impact</h3>
              <p className='sel-ben-desc'>
                By selling & purchasing second-hand books, you're participating
                in the reuse of resources, which helps reduce the demand for new
                books and thus minimizes the impact on the environment
              </p>
            </Col>
            <Col xs={12} sm={4}>
              <h3 className='pb-1'>Space Conservation</h3>
              <p className='sel-ben-desc'>
                Books take up physical space. If you live in a small apartment
                or you're simply trying to declutter, selling your books can
                help free up some room.
              </p>
            </Col>
            <Col xs={12} sm={4}>
              <h3 className='pb-1'>Minimizing Waste</h3>
              <p className='sel-ben-desc'>
                If you're unlikely to read a book again, it's better for the
                environment to sell it and let someone else enjoy it rather than
                it sitting unused.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <h3 className='pt-3'>Commission Rate</h3>

              <div>
                2% Payment Gateway Fees <br />
                7% Referral Fees upto ₹300 or 9% above ₹300
                <br />
                Shipping Charges starts from ₹35/Book
              </div>
              <br />
              <br />
            </Col>
          </Row>
          {/* <Row>
            <Col style={{ textAlign: 'center' }}>
              <div className='lp-text-app'>
                <h1>Why Subscribe?</h1>
                <br />
                <p>
                  Open the door to new worlds of wisdom, beauty, and inspiration
                  with our book Ideas subscription service. Discover the
                  transformative power of books in just 15-20 minutes or less,
                  with our hand-picked selection of 10 key ideas from over 1000+
                  non-fiction books.
                </p>

                <p>
                  Immerse yourself in the voices of the great thinkers, poets,
                  and visionaries of our time. Experience the rush of excitement
                  and inspiration that comes from discovering a new idea, a new
                  perspective, a new way of seeing the world.
                </p>

                <p>
                  99bookscart is a gateway to a life of meaning, purpose, and
                  fulfillment. It's a bridge between the wisdom of the past and
                  the vision of the future. It's a testament to the power of
                  words to transform, uplift, and inspire.
                </p>

                <p>
                  So join us on this journey of discovery. Let us be your guide,
                  your friend, your ally. Let us show you the way to a brighter,
                  more beautiful tomorrow.
                </p>

                <p>Subscribe today and let the magic begin.</p>
              </div>
              <div>
                <Button onClick={startSub }>Explore Ideas</Button>
              </div>
            </Col>
          </Row> */}
        </Container>
        <Modal show={show} onHide={handleClose} backdrop='static'>
          <Modal.Body>
            <Form onSubmit={submitHandler2}>
              <div>
                <Button className='close-button' onClick={handleClose}>
                  X
                </Button>
              </div>
              <div style={{ padding: '20px' }}>
                <Form.Group>
                  <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <LazyLoadImage
                      src={'../../images/logo.png'}
                      width={'100%'}
                      alt={'99bookscart logo'}
                      className='siteLogo'
                    />
                    <br />
                    <div className='dash-subhead'>
                      Contribution to sustainable reading!
                    </div>
                    <br />
                    <div className='social-logins'>
                      <SocialLogin />
                    </div>
                  </div>
                  <Form.Text className='red-text'>
                    {errorMessage && <span>{errorMessage}</span>}
                  </Form.Text>

                  <TextField
                    id='outlined-basic'
                    label='Full Name'
                    variant='outlined'
                    required
                    type='Text'
                    autoComplete='off'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ width: '100%', marginBottom: '10px' }}
                  />
                  <Form.Text className='red-text'>
                    {emailError && <span>{emailError}</span>}
                  </Form.Text>

                  <TextField
                    id='outlined-basic'
                    label='Email'
                    variant='outlined'
                    required
                    type='Email'
                    autoComplete='off'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ width: '100%', marginBottom: '10px' }}
                  />
                  <Form.Text className='red-text'>
                    {passwordError && <span>{passwordError}</span>}
                  </Form.Text>
                  <TextField
                    id='outlined-basic'
                    label='Passsword'
                    variant='outlined'
                    required
                    type='Passsword'
                    autoComplete='off'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ width: '100%', marginBottom: '10px' }}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Button type='submit' variant='contained' fullWidth>
                      Sign Up
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-center'>
                    <br />
                    Existing Customer?{' '}
                    <Link to={'/login?redirect=/seller/dashboard'}>Login</Link>
                  </Col>
                </Row>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}
{
}

export default SellerHomeScreen
