import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
} from 'react-bootstrap'

const GenreScreen = ({ match, history }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Container>
        <Row>
          <Col xs={6}></Col>
          <Col xs={6}></Col>
          <Col xs={6}></Col>
        </Row>
      </Container>
    </>
  )
}

export default GenreScreen
