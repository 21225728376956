import React from 'react'
import { Helmet } from 'react-helmet'
const EventMeta = ({ event }) => {
  const url = `https://www.99bookscart.com/bookfair/${event._id}`
  const eventStructedData = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: event.name,
    startDate: event.startDate,
    endDate: event.endDate,
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'Place',
      name: event.address,
      address: {
        '@type': 'PostalAddress',
        streetAddress: event.address,
        postalCode: event.zipCode,
        addressRegion: 'KA',
        addressCountry: 'IN',
      },
    },
    image: [event.image],
    description: event.description,
    offers: {
      '@type': 'Offer',
      url: 'https://99bookscart.com/book/BookscartBox1/surprise-box-15-books',
      price: '999',
      priceCurrency: 'INR',
      availability: 'https://schema.org/InStock',
      validFrom: '2023-01-01T12:00',
    },
    organizer: {
      '@type': 'Organization',
      name: '99bookscart',
      url: 'https://www.99bookscart.com',
    },
  }

  return (
    <Helmet>
      <title>{event.name}</title>
      <meta charset='UTF-8' />

      <meta property='og:title' content={event.name} />

      <head prefix='book: https://ogp.me/ns/book#'></head>
      <meta property='og:type' content='book:isbn' />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={event.image} />
      <meta property='og:description' content={event.description} />
      <meta property='og:site_name' content='99bookscart' />
      <meta name='title' content={event.name}></meta>
      <meta name='description' content={event.description}></meta>
      <meta name='keyword' content={event.name}></meta>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0'
      ></meta>
      <link rel='canonical' href={url}></link>
      <meta name='robots' content='index, follow' />
      <script className='structured-data-list' type='application/ld+json'>
        {JSON.stringify(eventStructedData)}
      </script>
    </Helmet>
  )
}
EventMeta.defaultProps = {
  title: '99bookscart',
  description: `India's Most Favorite Book Fair`,
  keyword: 'Book Fair near me book lover ',
}

export default EventMeta
