import axios from 'axios'
import {
  CREATE_BOOK_FAIR_FAIL,
  CREATE_BOOK_FAIR_REQUEST,
  CREATE_BOOK_FAIR_SUCCEES,
  INTREST_BOOK_FAIR_FAIL,
  INTREST_BOOK_FAIR_REQUEST,
  INTREST_BOOK_FAIR_SUCCEES,
  LIST_BOOK_FAIR_FAIL,
  LIST_BOOK_FAIR_REQUEST,
  LIST_BOOK_FAIR_SUCCEES,
  VIEW_BOOK_FAIR_FAIL,
  VIEW_BOOK_FAIR_REQUEST,
  VIEW_BOOK_FAIR_SUCCEES,
} from '../constants/bookFairConstants'

export const createNewBookFair = (bookfair) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_BOOK_FAIR_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/bookfair/`, bookfair, config)
    dispatch({
      type: CREATE_BOOK_FAIR_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CREATE_BOOK_FAIR_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getBookFairList =
  (flag = false) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LIST_BOOK_FAIR_REQUEST })

      if (flag) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const { data } = await axios.get(`/api/bookfair/list`, config)
        dispatch({
          type: LIST_BOOK_FAIR_SUCCEES,
          payload: data,
        })
      } else {
        const {
          userLogin: { userInfo },
        } = getState()
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        const { data } = await axios.get(`/api/bookfair/`, config)
        dispatch({
          type: LIST_BOOK_FAIR_SUCCEES,
          payload: data,
        })
      }
    } catch (error) {
      dispatch({
        type: LIST_BOOK_FAIR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getBookFair = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: VIEW_BOOK_FAIR_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.get(`/api/bookfair/${id}`, config)
    dispatch({
      type: VIEW_BOOK_FAIR_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VIEW_BOOK_FAIR_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getBookFairIntrest = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INTREST_BOOK_FAIR_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/bookfair/intrest/${id}`, config)
    dispatch({
      type: INTREST_BOOK_FAIR_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: INTREST_BOOK_FAIR_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const submitBookFairIntrest =
  (phone, bookfair) => async (dispatch, getState) => {
    try {
      let submitedEmail = {
        phone: phone,
        bookfair: bookfair,
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      await axios.post(
        `/api/bookfair/intrest`,
        submitedEmail,
        config
      )
    } catch (error) {}
  }
