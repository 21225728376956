import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { login } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import {} from '../actions/userActions'
import {
  deleteProduct,
  listProductsDetails,
  updateProduct,
} from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id
  console.log(productId)
  const [name, setName] = useState('')
  const [price, setPrice] = useState(0)
  const [image, setImage] = useState('')
  const [description, setDescription] = useState('')
  const [tags, setTags] = useState('')
  const [type, setType] = useState('')
  const [category, setCategory] = useState('')
  const [isbn, setIsbn] = useState('')
  const [salePrice, setSaleprice] = useState('')
  const [countInStock, setCountInStock] = useState('')
  const [pages, setPages] = useState('')
  const [edition, setEdition] = useState('')
  const [author, setAuthor] = useState('')
  const [publisher, setPublisher] = useState('')
  const [grating, setGrating] = useState('')
  const [gnumReviews, setGnumReviews] = useState('')
  const [location, setLocation] = useState('')
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET })
      history.push('/admin/productlist')
    } else {
      if (product && (!product.name || product._id !== productId)) {
        // dispatch(listProductsDetails(productId))
      } else {
        // setName(product.name)
        setPrice(product.price)
        setImage(product.image)
        setDescription(product.description)
        setTags(product.tags)
        setType(product.type)
        setCategory(product.category)
        setIsbn(product.isbn)
        setSaleprice(product.salePrice)
        setCountInStock(product.countInStock)
        setPages(product.pages)
        setEdition(product.edition)
        setAuthor(product.author)
        setPublisher(product.publisher)
        setGrating(product.grating)
        setGnumReviews(product.gnumReviews)
        setLocation(product.location)
      }
    }
  }, [dispatch, history, productId, product, successUpdate])

  const uploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }
  const deleteHandler = (id) => {
    if (window.confirm('are you Sure')) {
      dispatch(deleteProduct(id))
    }
  }
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateProduct({
        _id: productId,
        name,
        description,
        image,
        price,
        tags,
        type,
        category,
        isbn,
        salePrice,
        countInStock,
        pages,
        edition,
        author,
        publisher,
        grating,
        gnumReviews,
        location,
      })
    )
  }
  return (
    <>
      <Link to='/admin/productlist' className='btn btn-light my-3'>
        Go back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader></Loader>}
        {errorUpdate && <Message>{errorUpdate}</Message>}
        {loading ? (
          <Loader></Loader>
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='description'>
              <Form.Label>Desription</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='image'>
              <Form.Label>Image</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Image'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.File
                id='image-file'
                label='Choose File'
                custom
                onChange={uploadHandler}
              ></Form.File>
              {uploading && <Loader></Loader>}
            </Form.Group>
            <Form.Group controlId='price'>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter price'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='tags'>
              <Form.Label>Tags</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Tags'
                value={tags}
                onChange={(e) => setTags(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='type'>
              <Form.Label>Type</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Type'
                value={type}
                onChange={(e) => setType(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='category'>
              <Form.Label>Category</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Category'
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='isbn'>
              <Form.Label>ISBN</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter isbn'
                value={isbn}
                onChange={(e) => setIsbn(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='saleprice'>
              <Form.Label>Saleprice</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Saleprice'
                value={salePrice}
                onChange={(e) => setSaleprice(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='countInStock'>
              <Form.Label>countInStock</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter countInStock'
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='pages'>
              <Form.Label>pages</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter pages'
                value={pages}
                onChange={(e) => setPages(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='edition'>
              <Form.Label>edition</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter edition'
                value={edition}
                onChange={(e) => setEdition(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='author'>
              <Form.Label>author</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter author'
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='publisher'>
              <Form.Label>publisher</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter publisher'
                value={publisher}
                onChange={(e) => setPublisher(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='grating'>
              <Form.Label>grating</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter grating'
                value={grating}
                onChange={(e) => setGrating(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='gnumReviews'>
              <Form.Label>gnumReviews</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter gnumReviews'
                value={gnumReviews}
                onChange={(e) => setGnumReviews(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='location'>
              <Form.Label>location</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter location'
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type='submit' varaint='primary' className='float-right'>
              Update
            </Button>
            <Button
              varaint='primary'
              onClick={() => deleteHandler(product._id)}
            >
              Delete
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default ProductEditScreen
