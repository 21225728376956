import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
  Form,
  FormGroup,
  Modal,
} from 'react-bootstrap'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  getOrderDetails,
  deliverOrder,
  payOrder,
} from '../actions/orderActions'
import {
  ORDER_CREATE_RESET,
  ORDER_DELEVERED_RESET,
} from '../constants/orderConstants'
import { CART_RESET } from '../constants/cartConstants'
import { FaTruckMoving } from 'react-icons/fa'
import { createNewPassword, otpVerify } from '../actions/userActions'
import { TextField } from '@mui/material'

const OrderConfirmScreen = ({ history, match }) => {
  const orderId = match.params.id

  const dispatch = useDispatch()
  const [otpMessage, setOtpMessage] = useState('')
  const [stp, setStp] = useState()
  const [password, setPassword] = useState('')
  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, coin, loading, error, succees } = orderDetails

  const otpVerifyRes = useSelector((state) => state.otpVerify)
  const { loading: otpLoading, success: otpSuccess, result } = otpVerifyRes

  const payOr = useSelector((state) => state.orderPayStatus)
  const { loading: conLoading, success: conSuccess, result: conresult } = payOr

  const createPassword = useSelector((state) => state.createPassword)
  const {
    resetResult,
    loading: resetLoading,
    error: resetError,
    succees: resetSuccess,
  } = createPassword

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [tLink, setTLink] = useState('')

  const [show, setShow] = useState(
    localStorage.getItem('isPassword') === 'true' ? false : true
  )
  const [otp, setOtp] = useState(false)
  const closeModal = () => {
    setShow(false)
    localStorage.setItem('isPassword', false)
  }
  const updatePassword = () => {
    dispatch(createNewPassword(password))
    localStorage.setItem('isPassword', true)
    setInterval(function () {
      setShow(false)
    }, 2000)
  }
  dispatch({ type: ORDER_CREATE_RESET })
  dispatch({ type: CART_RESET })
  const otpHandler = (e) => {
    e.preventDefault()
    dispatch(
      otpVerify({
        phone: order.shippingAddress.phone,
        otp: otp,
        order: orderId,
      })
    )
  }
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay))
  }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  const confirmOrderHandler = (e) => {
    dispatch(payOrder(order._id, true))
  }
  const confirmOrderHandler2 = (e) => {
    console.log(stp)
    var options = {
      //key: 'rzp_test_Uc74kK1ulMDQoS',
      key: 'rzp_live_04AG18531Cjx9B',
      amount: 900,
      currency: 'INR',
      name: order.shippingAddress.name,
      description: '99bookscart',
      order_id: stp,
      image:
        'https://cdn.shopify.com/s/files/1/0287/9612/5316/files/99bookscart_759b028b-6768-47b8-9d79-ee2cf0190e04_180x.png?v=1646232311',
      handler: async (response) => {
        const paymentResult = {
          id: response.razorpay_payment_id,
          rid: response.razorpay_order_id,
          signature: response.razorpay_signature,
        }
        if (userInfo) {
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
          const { data } = await axios.get(
            `/api/orders/${order._id}/pp`,
            config
          )
          if (data) {
            const path = window.location.pathname
            window.location.assign(path)
          }
        }
      },
      prefill: {
        name: order.shippingAddress.name,
        email: order.shippingAddress.email,
        contact: order.shippingAddress.phone,
      },
      theme: {
        color: '#4267b2',
      },
    }

    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }
  useEffect(() => {
    if (conSuccess) {
      setStp(conresult.data.id)
      var options = {
        //key: 'rzp_test_Uc74kK1ulMDQoS',
        key: 'rzp_live_04AG18531Cjx9B',
        amount: 900,
        currency: 'INR',
        name: order.shippingAddress.name,
        description: '99bookscart',
        order_id: conresult.data.id,
        image:
          'https://cdn.shopify.com/s/files/1/0287/9612/5316/files/99bookscart_759b028b-6768-47b8-9d79-ee2cf0190e04_180x.png?v=1646232311',
        handler: async (response) => {
          const paymentResult = {
            id: response.razorpay_payment_id,
            rid: response.razorpay_order_id,
            signature: response.razorpay_signature,
          }
          if (userInfo) {
            const config = {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
              },
            }
            const { data } = await axios.get(
              `/api/orders/${order._id}/pp`,
              config
            )
            if (data) {
              const path = window.location.pathname
              window.location.assign(path)
            }
          }
        },
        prefill: {
          name: order.shippingAddress.name,
          email: order.shippingAddress.email,
          contact: order.shippingAddress.phone,
        },
        theme: {
          color: '#4267b2',
        },
      }

      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }
    if (userInfo) {
      if (!order || order._id !== orderId) {
        dispatch(getOrderDetails(orderId))
        dispatch({ type: ORDER_DELEVERED_RESET })
        loadScript('https://checkout.razorpay.com/v1/checkout.js')
      }

      if (!loading) {
        const addDecimals = (num) => {
          return (Math.round(num * 100) / 100).toFixed(2)
        }
        order.itemsPrice = addDecimals(
          order.orderItems.reduce(
            (acc, item) => acc + item.salePrice * item.qty,
            0
          )
        )
      }

      if (otpSuccess) {
        setOtp('')
        if (result.result) {
          setOtpMessage('Order has been Confimred')
          setInterval(history.push(`/order/${order._id}`), 2000)
        } else {
          setOtpMessage('OTP is not verified')
        }
      }
    } else {
      history.push('/login')
    }
  }, [dispatch, order, orderId, succees, otpSuccess, result, conSuccess])
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        order && (
          <Container>
            <Row style={{ marginBottom: '60px' }}>
              <Col md={8}>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <div style={{ fontSize: '28px' }}>
                      <i className='far fa-check-circle green'></i> Thank you
                      for Placing the Order
                    </div>
                    <div>
                      Congratulations You have earned {coin && coin.coins}{' '}
                      Points
                    </div>

                    {order &&
                      !order.isPartialPaid &&
                      order.codPrice > 0 &&
                      !order.isConfirmed && (
                        <div style={{ float: 'left' }}>
                          <div style={{ fontSize: '20px' }}>
                            Your order won't be processed until you confirm!
                          </div>
                          {/* <div className='otp-handler'>
                        <h2>
                          Enter OTP Sent to email {order.shippingAddress.email}{' '}
                          & text {order.shippingAddress.phone}
                        </h2>
                        <small>
                          All COD Orders needs to be Confirmed by OTP
                        </small>
                        <Form onSubmit={otpHandler}>
                          <FormGroup controlId='otp'>
                            {otpMessage && (
                              <span className='red-text'>{otpMessage}</span>
                            )}
                            <Form.Control
                              type='text'
                              placeholder='OTP'
                              onChange={(e) => setOtp(e.target.value)}
                              required
                            ></Form.Control>
                            <div>
                              <Button
                                style={{ float: 'right' }}
                                type='submit'
                                variant='primary'
                                className='text-right'
                              >
                                Verify OTP
                              </Button>
                            </div>
                          </FormGroup>
                        </Form> */}
                          <Button
                            style={{
                              fontSize: '18px',
                              marginBottom: '12px',
                              padding: '18px',
                            }}
                            variant='primary'
                            className='text-right'
                            onClick={(e) => confirmOrderHandler(e)}
                          >
                            Confirm Your Order by paying &#8377;9/-
                          </Button>
                        </div>
                      )}

                    <div className='checkout-shipping-address'>
                      <br />
                      <br />
                      <br />
                      <br />
                      <h6>Shipping Address</h6>
                      <strong>Name:</strong>
                      {order.user
                        ? order.user.name
                        : order.shippingAddress.name}
                      <br />
                      <strong> Email:</strong>
                      {order.user
                        ? order.user.email
                        : order.shippingAddress.email}
                      <p>
                        {order.shippingAddress.name}
                        <br />
                        {order.shippingAddress.address},
                        {order.shippingAddress.address2},<br />
                        {order.shippingAddress.city},
                        {order.shippingAddress.state},
                        {order.shippingAddress.postalCode}
                        <br />
                        {order.shippingAddress.phone}{' '}
                      </p>
                    </div>
                  </ListGroup.Item>
                  {/* <ListGroup.Item>
                            <Row>
                                <Col>
                                    <h4>Payment</h4>
                                    <p>
                                        <strong>
                                        <span className='capitalize-text'>{order.paymentMethod}</span>({order.isPaid?<small className='text-muted'>Paid on:{order.paidAt.substring(0,10)}</small>:
                                    <small className='text-muted'>Not Paid</small>})</strong>
                                    </p>
                                    
                                </Col>
                                <Col>
                                <h4>Shipping</h4>
                                    <p>
                                        <strong>
                                        <span className='capitalize-text'>{order.shippingMethod}</span></strong>
                                    </p>
                                </Col>
                            </Row>
                            
                        </ListGroup.Item> */}
                  <ListGroup.Item>
                    <h6>Order Details</h6>
                    {order.orderItems.length == 0 ? (
                      <Message>Order is Empty</Message>
                    ) : (
                      <ListGroup variant='flush'>
                        {order.orderItems.map((item, index) => (
                          <ListGroup.Item key={item.product}>
                            <Row>
                              <Col md={2} xs={2}>
                                <Image
                                  src={item.image}
                                  alt={item.name}
                                  fluid
                                  rounded
                                  className='cartImage'
                                  style={{ padding: '6px' }}
                                />
                              </Col>
                              <Col
                                md={8}
                                xs={8}
                                style={{ padding: '6px', paddingLeft: '16px' }}
                              >
                                <Link
                                  to={`/book/${item.isbn}/${convertToSlug(
                                    item.name
                                  )}`}
                                >
                                  {item.name}{' '}
                                  {item.variantName &&
                                    '(' + item.variantName + ')'}
                                </Link>
                                <br />
                                &#8377;
                                {Number.parseFloat(item.salePrice).toFixed(2)}
                                <br />
                                {item.sellerName && (
                                  <small className='text-muted'>
                                    Sold By: {item.sellerName}
                                  </small>
                                )}
                              </Col>
                              {/* <Col md={2} xs={2}>
                                            &#8377;{Number.parseFloat(item.salePrice).toFixed(2)}
                                            </Col> */}
                              {/* <Col md={2} xs={2}>
                                            <Form.Control as='select' value={item.qty} onChange={(e)=>
                                                    dispatch(addToCart(item.product,Number(e.target.value)))}>
                                                {[...Array(item.countInStock).keys()].map((x)=>(
                                                    <option key={x+1} value={x+1}>{x+1}</option>
                                                ))}
                                            </Form.Control>
                                            </Col> */}
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={4} style={{ padding: '6px' }}>
                <Card>
                  <ListGroup variant='flash'>
                    <ListGroupItem>
                      <h6> Order Summary</h6>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Row>
                        <Col>Items Total</Col>
                        <Col className='productPrice'>
                          &#8377;{Number.parseFloat(order.itemPrice).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Row>
                        <Col>Shipping</Col>
                        <Col className='productPrice'>
                          &#8377;
                          {Number.parseFloat(order.shippingPrice).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroupItem>
                    {order.codPrice > 0 && (
                      <ListGroupItem>
                        <Row>
                          <Col>COD Charges</Col>
                          <Col className='productPrice'>
                            &#8377;
                            {Number.parseFloat(order.codPrice).toFixed(2)}
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )}
                    {order.taxPrice > 0 && (
                      <ListGroupItem>
                        <Row>
                          <Col>Tax</Col>
                          <Col className='productPrice'>
                            &#8377;
                            {Number.parseFloat(order.taxPrice).toFixed(2)}
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )}
                    {order.discountPrice > 0 && (
                      <ListGroupItem>
                        <Row>
                          <Col>Discount</Col>
                          <Col className='productPrice'>
                            -&#8377;
                            {Number.parseFloat(order.discountPrice).toFixed(2)}
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )}
                    <ListGroupItem>
                      <Row>
                        <Col>Total</Col>
                        <Col className='productPrice'>
                          &#8377;
                          {Number.parseFloat(order.totalPrice).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </Container>
        )
      )}
    </Container>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default OrderConfirmScreen
