import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'

import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import AdminNav from '../../components/AdminNav'
import AbandoncartSearchBox from '../../components/AbandoncartSearchBox'
import { Route } from 'react-router-dom'
import { listShipments } from '../../actions/shipmentActions'

const ShipmentListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const shipmentsList = useSelector((state) => state.listShipment)
  const { loading, error, shipments } = shipmentsList
  const pageNumber = match.params.pageNumber || 1
  const keyword = match.params.keyword || null
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    window.scrollTo(0, 0)

    if (userInfo && userInfo.isAdmin) {
      dispatch(listShipments(pageNumber))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Row>
          <AdminNav></AdminNav>
          <Route
            render={({ history }) => <AbandoncartSearchBox history={history} />}
          />
          <Row>
            <Col>
              <h4>Shipments</h4>
            </Col>
            <Col>
              <Link to='/' className='btn btn-light float-right'>
                Go back
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>AWB</th>
                    <th>Converted</th>
                    <th>DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {shipments.map((cart) => (
                    <tr key={cart._id}>
                      <td>{cart.awb}</td>
                      <td>{cart.courierName}</td>
                      <td>{cart.weight}</td>
                      <td>{cart.totalCharge}</td>
                      {/* <td>
                        {cart.isConverted ? (
                          <i className='fas fa-check'></i>
                        ) : (
                          <i className='fas fa-times'></i>
                        )}
                      </td>
                      <td>
                        <small>
                          {cart.createdAt.substring(0, 10)}
                          <br />
                          {cart.createdAt.substring(19, 11)}
                        </small>
                      </td>
                      <td>
                        <LinkContainer
                          to={`/admin/abandoncartdetails/${cart._id}`}
                        >
                          <Button className='btn-sm' variant='light'>
                            Details
                          </Button>
                        </LinkContainer>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Row>
      )}
    </Container>
  )
}

export default ShipmentListScreen
