import React, { useState, useEffect, useRef } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { createNewEpisode, getEpisodeData } from '../../actions/episodeActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Button } from 'react-bootstrap'
import Loader from '../../components/Loader'
import crypto from 'crypto'
import { Editor } from '@tinymce/tinymce-react'

const WriteEpisodeScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const episodeId = match.params.id || null
  const storyId = match.params.sid || null
  const [content, SetContent] = useState('')
  const [contentError, SetContentError] = useState('')
  const [title, setTitle] = useState('Chapter 1')

  const [contentMessage, SetContentMessage] = useState('')
  const [publish, SetPublish] = useState(false)
  const [refresh, SetRefresh] = useState(false)

  const createEpisode = useSelector((state) => state.createEpisode)
  const { episode, loading, error, suceess } = createEpisode

  const editorRef = useRef(null)

  const getEpisodeRedu = useSelector((state) => state.getEpisode)
  const {
    episode: getEpisode,
    loading: getLoading,
    error: getError,
    suceess: getSuceess,
  } = getEpisodeRedu

  const submitEpisode = (e) => {
    e.preventDefault()
    if (content.length < 20) {
      SetContentError('Content is too short')
    } else {
      dispatch(
        createNewEpisode({
          content,
          title,
          status: true,
          id: episodeId,
          story: storyId,
          update: true,
        })
      )
      SetPublish(true)
      SetRefresh(true)
    }
  }
  const newChapter = (e) => {
    e.preventDefault()
    history.push(`/create/episode/${storyId}`)
  }
  const saveEpisode = (e) => {
    e.preventDefault()
    dispatch(
      createNewEpisode({
        content,
        title,
        status: false,
        id: episodeId,
        story: storyId,
        update: true,
      })
    )
    SetRefresh(true)
  }
  const example_image_upload_handler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', '/api/upload/editor')

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100)
      }

      xhr.onload = () => {
        if (xhr.status === 403) {
          reject({ message: 'HTTP Error: ' + xhr.status, remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject('HTTP Error: ' + xhr.status)
          return
        }

        const json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location != 'string') {
          reject('Invalid JSON: ' + xhr.responseText)
          return
        }

        resolve(json.location)
      }

      xhr.onerror = () => {
        reject(
          'Image upload failed due to a XHR Transport error. Code: ' +
            xhr.status
        )
      }

      const formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    })
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!getSuceess || refresh) {
      dispatch(getEpisodeData(episodeId))
      SetRefresh(false)
    }
    if (suceess && publish) {
      history.push(`/write/story/${episodeId}/${storyId}`)
    }

    if (getSuceess) {
      if (getEpisode.content.length > 0) {
        var algorithm = 'aes256' // or any other algorithm supported by OpenSSL
        var key = 'password'
        var decipher = crypto.createDecipher(algorithm, key)
        var decrypted =
          decipher.update(getEpisode.content, 'hex', 'utf8') +
          decipher.final('utf8')
        SetContent(decrypted)
        setTitle(getEpisode.title)
      }
    }
  }, [dispatch, episodeId, getSuceess, suceess, publish, getEpisode, storyId])
  return (
    <Container>
      {contentMessage && <div>Saved</div>}
      {getLoading || loading ? (
        <Loader></Loader>
      ) : (
        <Form onSubmit={submitEpisode}>
          {/* <button onClick={log}>Log editor content</button> */}

          <Form.Group>
            <Form.Label>Chapter Name</Form.Label>
            <Form.Control
              type='text'
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='content'>
            <Form.Label>Content</Form.Label>
            <Form.Control as='textarea' rows={40} value={content} onChange={(e) => SetContent(e.target.value)}/>
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Chapter</Form.Label>
            <Form.Control
              as='textarea'
              rows={12}
              value={content}
              onChange={(e) => SetContent(e.target.value)}
            />
          </Form.Group> */}
          {/* <div
            className='episodecontent'
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{
              __html: content.replace(/(<? *script)/gi, 'illegalscript'),
            }}
          ></div> */}

          {/* <Editor
            apiKey='g547goe8xzsy9huuryqujg4tz7deth3i6cqhgr3173uv4ifc'
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={content}
            onEditorChange={(newValue, editor) => SetContent(newValue)}
            init={{
              images_upload_handler: example_image_upload_handler,
              height: 500,
              menubar: false,
              forced_root_block: false,
              statusbar: false,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'image',
              ],
              toolbar:
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright   | ' +
                'image',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;white-space: pre-wrap }.wrappretext {white-space: pre-wrap; word-wrap: break-word;}',
              formats: {
                pre: { block: 'pre', classes: 'wrappretext' },
              },
            }}
          /> */}

          <div style={{ textAlign: 'right' }} className='mt-4'>
            {contentError && <div className='red-text'>{contentError}</div>}
            <Form.Group>
              <Button
                variant='primary'
                type='button'
                onClick={(e) => newChapter(e)}
              >
                Add New Chapter
              </Button>{' '}
              &nbsp;
              <Button
                variant='primary'
                type='submit'
                onClick={(e) => saveEpisode(e)}
              >
                Save
              </Button>{' '}
              &nbsp;
              <Button variant='primary' type='submit'>
                Publish
              </Button>
            </Form.Group>
          </div>
        </Form>
      )}
    </Container>
  )
}

export default WriteEpisodeScreen
