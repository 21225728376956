import {
  SUBMISSION_ADD_FAIL,
  SUBMISSION_ADD_REQUEST,
  SUBMISSION_ADD_RESET,
  SUBMISSION_ADD_SUCCEES,
  SUBMISSION_LIST_FAIL,
  SUBMISSION_LIST_REQUEST,
  SUBMISSION_LIST_SUCCEES,
} from '../constants/submissionConstants'

export const addToSubmissionReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case SUBMISSION_ADD_REQUEST:
      return {
        loading: true,
      }
    case SUBMISSION_ADD_SUCCEES:
      return {
        loading: false,
        submission: action.payload,
        success: true,
      }
    case SUBMISSION_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case SUBMISSION_ADD_RESET:
      return {}
    default:
      return state
  }
}

export const listEbookSubmissionReducer = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case SUBMISSION_LIST_REQUEST:
      return {
        loading: true,
      }
    case SUBMISSION_LIST_SUCCEES:
      return {
        loading: false,
        submissions: action.payload,
        success: true,
      }
    case SUBMISSION_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
