import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'

import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listMyOrders } from '../actions/orderActions'
import BuyerOrder from './seller/components/BuyerOrder'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { FaMoneyBillAlt } from 'react-icons/fa'
const UserOrderListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1
  const cancelOrders = match.params.cancel

  const orderListMy = useSelector((state) => state.orderListMy)
  const { loading, error, orders, page, pages } = orderListMy

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const handleChange = (event, value) => {
    history.push(`/profile/orders/page/${value}`)
  }
  useEffect(() => {
    if (userInfo) {
      dispatch(listMyOrders(pageNumber))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, pageNumber, cancelOrders, match])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          <Row>
            <div role='presentation'>
              <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
                <Link underline='hover' color='inherit' to={'/profile'}>
                  Profile
                </Link>
                <Typography color='text.primary'>Order List</Typography>
              </Breadcrumbs>
            </div>
          </Row>

          {orders.length == 0 && (
            <Message>No orders has been placed yet!</Message>
          )}
          {orders.map((order) => (
            <BuyerOrder order={order} history={history} />
          ))}
          <Stack spacing={1}>
            <Pagination
              count={pages}
              page={page}
              onChange={handleChange}
              style={{
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            />
          </Stack>
        </Container>
      )}
    </Container>
  )
}

export default UserOrderListScreen
