import {
  CREATE_BOOK_FAIR_FAIL,
  CREATE_BOOK_FAIR_REQUEST,
  CREATE_BOOK_FAIR_SUCCEES,
  INTREST_BOOK_FAIR_FAIL,
  INTREST_BOOK_FAIR_REQUEST,
  INTREST_BOOK_FAIR_SUCCEES,
  LIST_BOOK_FAIR_FAIL,
  LIST_BOOK_FAIR_REQUEST,
  LIST_BOOK_FAIR_SUCCEES,
  VIEW_BOOK_FAIR_FAIL,
  VIEW_BOOK_FAIR_REQUEST,
  VIEW_BOOK_FAIR_SUCCEES,
} from '../constants/bookFairConstants'

export const createBookFairReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BOOK_FAIR_REQUEST:
      return {
        loading: true,
      }
    case CREATE_BOOK_FAIR_SUCCEES:
      return {
        loading: false,
        success: true,
        bookfair: action.payload,
      }
    case CREATE_BOOK_FAIR_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const listBookFairReducer = (state = { bookfairs: [] }, action) => {
  switch (action.type) {
    case LIST_BOOK_FAIR_REQUEST:
      return {
        loading: true,
      }
    case LIST_BOOK_FAIR_SUCCEES:
      return {
        loading: false,
        success: true,
        bookfairs: action.payload,
      }
    case LIST_BOOK_FAIR_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getBookFairReducer = (state = { bookfair: [] }, action) => {
  switch (action.type) {
    case VIEW_BOOK_FAIR_REQUEST:
      return {
        loading: true,
      }
    case VIEW_BOOK_FAIR_SUCCEES:
      return {
        loading: false,
        success: true,
        bookfair: action.payload,
      }
    case VIEW_BOOK_FAIR_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getBookFairIntrestReducer = (
  state = { intrests: [], loading: false },
  action
) => {
  switch (action.type) {
    case INTREST_BOOK_FAIR_REQUEST:
      return {
        loading: true,
      }
    case INTREST_BOOK_FAIR_SUCCEES:
      return {
        loading: false,
        success: true,
        intrests: action.payload,
      }
    case INTREST_BOOK_FAIR_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
