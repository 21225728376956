export const CREATE_BOOK_FAIR_REQUEST = 'CREATE_BOOK_FAIR_REQUEST'
export const CREATE_BOOK_FAIR_SUCCEES = 'CREATE_BOOK_FAIR_SUCCEES'
export const CREATE_BOOK_FAIR_FAIL = 'CREATE_BOOK_FAIR_FAIL'

export const LIST_BOOK_FAIR_REQUEST = 'LIST_BOOK_FAIR_REQUEST'
export const LIST_BOOK_FAIR_SUCCEES = 'LIST_BOOK_FAIR_SUCCEES'
export const LIST_BOOK_FAIR_FAIL = 'LIST_BOOK_FAIR_FAIL'

export const VIEW_BOOK_FAIR_REQUEST = 'VIEW_BOOK_FAIR_REQUEST'
export const VIEW_BOOK_FAIR_SUCCEES = 'VIEW_BOOK_FAIR_SUCCEES'
export const VIEW_BOOK_FAIR_FAIL = 'VIEW_BOOK_FAIR_FAIL'

export const INTREST_BOOK_FAIR_REQUEST = 'INTREST_BOOK_FAIR_REQUEST'
export const INTREST_BOOK_FAIR_SUCCEES = 'INTREST_BOOK_FAIR_SUCCEES'
export const INTREST_BOOK_FAIR_FAIL = 'INTREST_BOOK_FAIR_FAIL'
