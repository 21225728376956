import {
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_SUCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCESS,
  USER_UPDATE_PROFILE_REQUEST,
  USER_DETAILS_RESET,
  USER_LIST_REQUEST,
  USER_LIST_SUCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,
  USER_ADDRESS_REQUEST,
  USER_ADDRESS_SUCESS,
  USER_ADDRESS_FAIL,
  USER_ADDRESS_DELETE_SUCESS,
  USER_ADDRESS_DELETE_FAIL,
  USER_ADDRESS_DELETE_REQUEST,
  USER_OTP_VERIFY_REQUEST, 
  USER_OTP_VERIFY_SUCESS,
  USER_OTP_VERIFY_FAIL,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCESS,
  USER_VERIFY_FAIL,
  USER_OTP_VERIFY_RESET,
  USER_VERIFY_RESET,
  USER_REGISTER_RESET,
  USER_GUEST_CREATE_REQUEST,
  USER_GUEST_CREATE_SUCESS,
  USER_GUEST_CREATE_FAIL,
  USER_DESC_REQUEST,
  USER_DESC_SUCESS,
  USER_DESC_FAIL,
  USER_FOLLOW_REQUEST,
  USER_FOLLOW_SUCESS,
  USER_FOLLOW_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCESS,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCESS,
  USER_PROFILE_FAIL,
  USER_REGISTER_LP_REQUEST,
  USER_REGISTER_LP_SUCESS,
  USER_REGISTER_LP_FAIL,
  USER_ADDRESS_LP_REQUEST,
  USER_ADDRESS_LP_SUCESS,
  USER_ADDRESS_LP_FAIL,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCESS,
  OTP_VERIFY_RESET,
  USER_SELLER_REGISTER_REQUEST,
  USER_SELLER_REGISTER_SUCESS,
  USER_SELLER_REGISTER_FAIL,
  USER_SELLER_REGISTER_RESET,
  USER_SELLER_OTP_REGISTER_REQUEST,
  USER_SELLER_OTP_REGISTER_SUCESS,
  USER_SELLER_OTP_REGISTER_FAIL,
  USER_CREATE_PASSWORD_REQUEST,
  USER_CREATE_PASSWORD_SUCESS,
  USER_CREATE_PASSWORD_FAIL,
  USER_UNSUBSCRIBE_REQUEST,
  USER_UNSUBSCRIBE_SUCESS,
  USER_UNSUBSCRIBE_LOGOUT,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
      }
    case USER_LOGIN_SUCESS:
      return {
        loading: false,
        userInfo: action.payload,
      }
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case USER_LOGOUT:
      return {
        loading: false,
      }
    default:
      return state
  }
}

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return {
        loading: true,
      }
    case USER_REGISTER_SUCESS:
      return {
        loading: false,
        userInfo: action.payload,
        success: true,
      }
    case USER_REGISTER_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case USER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}
export const userSellerRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SELLER_REGISTER_REQUEST:
      return {
        loading: true,
      }
    case USER_SELLER_REGISTER_SUCESS:
      return {
        loading: false,
        userInfo: action.payload,
        success: true,
      }
    case USER_SELLER_REGISTER_FAIL:
      return {
        loading: false,
        userInfo: action.payload,
        success: true,
      }
    case USER_SELLER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case USER_DETAILS_SUCESS:
      return {
        loading: false,
        success: true,
        user: action.payload,
      }
    case USER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case USER_DETAILS_RESET:
      return { user: {} }
    default:
      return state
  }
}

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return {
        loading: true,
      }
    case USER_UPDATE_PROFILE_SUCESS:
      return {
        loading: false,
        userInfo: action.payload,
        success: true,
      }
    case USER_UPDATE_PROFILE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        loading: true,
      }
    case USER_LIST_SUCESS:
      return {
        loading: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case USER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case USER_LIST_RESET:
      return { users: [] }
    default:
      return state
  }
}

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return {
        loading: true,
      }
    case USER_DELETE_SUCESS:
      return {
        loading: false,
        success: true,
      }
    case USER_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case USER_LIST_RESET:
      return { users: [] }
    default:
      return state
  }
}

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return {
        loading: true,
      }
    case USER_UPDATE_PROFILE_SUCESS:
      return {
        loading: false,
        success: true,
      }
    case USER_UPDATE_PROFILE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case USER_UPDATE_RESET:
      return { user: {} }
    default:
      return state
  }
}

export const userAddressListReducer = (state = { addressList: [] }, action) => {
  switch (action.type) {
    case USER_ADDRESS_REQUEST:
      return {
        loading: true,
      }
    case USER_ADDRESS_SUCESS:
      return {
        loading: false,
        addressList: action.payload,
        success: true,
      }
    case USER_ADDRESS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const userAddressDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ADDRESS_DELETE_REQUEST:
      return {
        loading: true,
      }
    case USER_ADDRESS_DELETE_SUCESS:
      return {
        loading: false,
        success: true,
      }
    case USER_ADDRESS_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const userOtpVerifyReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case USER_OTP_VERIFY_REQUEST:
      return {
        loading: true,
      }
    case USER_OTP_VERIFY_SUCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
      }
    case USER_OTP_VERIFY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case USER_OTP_VERIFY_RESET:
      return {}
    default:
      return state
  }
}
export const userVerifyReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case USER_VERIFY_REQUEST:
      return {
        loading: true,
      }
    case USER_VERIFY_SUCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
      }
    case USER_VERIFY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case USER_VERIFY_RESET:
      return {}
    default:
      return state
  }
}

export const guestUserReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case USER_GUEST_CREATE_REQUEST:
      return {
        loading: true,
      }
    case USER_GUEST_CREATE_SUCESS:
      return {
        loading: false,
        success: true,
        user: action.payload,
      }
    case USER_GUEST_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const updateUserDescReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case USER_DESC_REQUEST:
      return {
        loading: true,
      }
    case USER_DESC_SUCESS:
      return {
        loading: false,
        success: true,
        user: action.payload,
      }
    case USER_DESC_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const forgotPasswordReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case USER_FORGOT_PASSWORD_REQUEST:
      return {
        loading: true,
      }
    case USER_FORGOT_PASSWORD_SUCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
      }
    case USER_FORGOT_PASSWORD_SUCESS:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const resetPasswordReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST:
      return {
        loading: true,
      }
    case USER_RESET_PASSWORD_SUCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
      }
    case USER_RESET_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const userProfileReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        loading: true,
      }
    case USER_PROFILE_SUCESS:
      return {
        loading: false,
        profile: action.payload.user,
        stories: action.payload.stories,
        success: true,
      }
    case USER_PROFILE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const userRegisterLPReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_LP_REQUEST:
      return {
        loading: true,
      }
    case USER_REGISTER_LP_SUCESS:
      return {
        loading: false,
        userData: action.payload,
        success: true,
      }
    case USER_REGISTER_LP_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
export const userAddressLPReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ADDRESS_LP_REQUEST:
      return {
        loading: true,
      }
    case USER_ADDRESS_LP_SUCESS:
      return {
        loading: false,
        userData2: action.payload,
        success: true,
      }
    case USER_ADDRESS_LP_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
export const otpVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case OTP_VERIFY_REQUEST:
      return {
        loading: true,
      }
    case OTP_VERIFY_SUCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
      }
    case OTP_VERIFY_RESET:
      return {
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
export const sellerOtpVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SELLER_OTP_REGISTER_REQUEST:
      return {
        loading: true,
      }
    case USER_SELLER_OTP_REGISTER_SUCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
      }
    case USER_SELLER_OTP_REGISTER_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
export const createPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_PASSWORD_REQUEST:
      return {
        loading: true,
      }
    case USER_CREATE_PASSWORD_SUCESS:
      return {
        loading: false,
        resetResult: action.payload,
        success: true,
      }
    case USER_CREATE_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const unsubscribeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UNSUBSCRIBE_REQUEST:
      return {
        loading: true,
      }
    case USER_UNSUBSCRIBE_SUCESS:
      return {
        loading: false,
        data: action.payload.message,
        success: true,
      }
    case USER_UNSUBSCRIBE_LOGOUT:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
