import React, { useState, useEffect, useRef } from 'react'
import { Form, Card, Row, Col, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../../components/FormContainer'

import { Link } from 'react-router-dom'
import { FaTruck } from 'react-icons/fa'
import { sellerRegisOTPVerify } from '../../../actions/userActions'
import Cookies from 'universal-cookie'
import { SHIPPING_STATE } from '../../../constants/orderConstants'
import { getStore } from '../../../actions/storeActions'

import Loader from '../../../components/Loader'
import { createStore } from '../../../actions/storeActions'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'

import { Breadcrumbs, Typography } from '@mui/material'
const cookies = new Cookies()
const CreateStoreScreen = ({ history }) => {
  const firstRender = useRef(true)

  const dispatch = useDispatch()

  const guestUser = useSelector((state) => state.guestUser)
  const { user, success: guestSuccess, error: guestError } = guestUser

  const createStoreRes = useSelector((state) => state.createStore)
  const { loading, success: storeSuccess, error } = createStoreRes

  const storeData = useSelector((state) => state.getStore)
  const { loading: getStoreLoading, success, result } = storeData

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const sellerOtpVerify = useSelector((state) => state.sellerOtpVerify)
  const {
    loading: otploading,
    error: otperror,
    success: otpSuccess,
    result: otpResult,
  } = sellerOtpVerify

  const [email, setEmail] = useState(cookies.get('_cfx_email'))
  const [storeName, setStoreName] = useState('')
  const [numofbooks, setNumofbooks] = useState(0)
  const [ownStore, setOwnStore] = useState(false)
  const [otpError, setOtpError] = useState(null)
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [phone, setPhone] = useState(cookies.get('_cfx_phone'))
  const [state, setState] = useState('')
  const [zipError, setZipError] = useState(null)
  const [disable, setDisabled] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState({})
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [show, setShow] = useState(false)
  const [otp, setOTP] = useState('')

  var date = new Date()
  // const [country, setCountry] = useState(shippingAddress.country)
  let country = 'India'
  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    dispatch(sellerRegisOTPVerify(otp, phone))
  }
  useEffect(() => {
    window.scrollTo(0, 0)

    if (!userLoggedIn) {
      history.push('/login')
    }
    if (storeSuccess) {
      setShow(true)
      //history.push('/sell/new/book?storeC=true')
    }
    if (otpSuccess && userLoggedIn) {
      if (otpResult.result) {
        history.push('/sell/new/book?storeC=true')
      } else {
        setOtpError('Number is not Verified')
      }
    }
    if (userLoggedIn && !getStoreLoading && !success) {
      dispatch(getStore())
    }
    if (success && result.store.storeName) {
      history.push('/profile')
    }
  }, [
    dispatch,
    guestSuccess,
    guestError,
    userLoggedIn,
    storeSuccess,
    success,
    result,
    getStoreLoading,
    otpSuccess,
    otpResult,
  ])

  function isEmail(email) {
    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (email !== '' && email.match(emailFormat)) {
      return true
    }

    return false
  }
  const submitHandler = (e) => {
    e.preventDefault()
    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(phone)) {
      setPhoneError('')
      if (postalCode.length == 6) {
        setZipError('')
        dispatch(
          createStore({
            storeName,
            numofbooks,
            ownStore,
            address,
            address2,
            city,
            postalCode,
            state,
            country,
            phone,
          })
        )
      } else {
        setZipError('Enter Valid ZipCode')
      }
    } else {
      setPhoneError('Enter Valid Phone Number')
    }
  }
  return (
    <>
      <>
        <CssBaseline />
        <Container>
          <Row>
            <div role='presentation'>
              <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
                <Link underline='hover' color='inherit' to={'/profile'}>
                  Dashboard
                </Link>
                <Typography color='text.primary'>Store</Typography>
              </Breadcrumbs>
            </div>
          </Row>
        </Container>
        <FormContainer>
          <Typography variant='h6' className='cart-header'>
            Sell Your Used Books and Give Them New Life!
          </Typography>
          {loading && <Loader></Loader>}
          <>
            {/* <Container className='delivery-date'>
            <FaTruck></FaTruck> Delivery by {deliveryDate.getDate()}{' '}
            {months[deliveryDate.getMonth()]}
          </Container> */}

            {/* <Container>
              <Row>
                <Col className='text-center'>
                  <Typography>
                    Sell your books to more 2.8 Lakhs customers across India
                  </Typography>
                </Col>
              </Row>
            </Container> */}

            <form onSubmit={submitHandler} className='cart-form'>
              <TextField
                required
                type='hidden'
                margin='normal'
                autoComplete='off'
                value={userLoggedIn.name}
                onChange={(e) => setStoreName(e.target.value)}
                hidden
              />

              <TextField
                id='outlined-basic'
                label='How many books do you have?'
                variant='outlined'
                required
                type='Number'
                autoComplete='off'
                value={numofbooks || ''}
                onChange={(e) => setNumofbooks(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              <br />

              <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                <Typography variant='caption' gutterBottom>
                  Complete Address from where books will shipped
                </Typography>
              </div>
              <TextField
                id='outlined-basic'
                label='Address'
                variant='outlined'
                autoComplete='off'
                required
                type='text'
                value={address || ''}
                onChange={(e) => setAddress(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
              />

              <TextField
                id='outlined-basic'
                label='Apartment, Suite, etc (Optional)'
                variant='outlined'
                autoComplete='off'
                type='text'
                value={address2 || ''}
                onChange={(e) => setAddress2(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              <TextField
                id='outlined-basic'
                label='City'
                variant='outlined'
                type='text'
                autoComplete='off'
                required
                value={city || ''}
                onChange={(e) => setCity(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
              />

              <TextField
                id='outlined-basic'
                label='Zip Code'
                variant='outlined'
                type='Number'
                autoComplete='off'
                required
                value={postalCode || ''}
                onChange={(e) => setPostalCode(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
              />

              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>State</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  required
                  label='State'
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  {SHIPPING_STATE.map((state) => (
                    <MenuItem value={state} key={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <small style={{ paddingTop: '8px' }}>
                Whatsapp OTP verification required
              </small>
              <TextField
                id='outlined-basic'
                label='Phone Number'
                variant='outlined'
                type='phone'
                margin='normal'
                autoComplete='off'
                error={phoneError ? true : false}
                required
                helperText={phoneError ? phoneError : ''}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                style={{ width: '100%' }}
              />
              <br />
              <Row
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                className='text-center'
              >
                <Col>
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={disable}
                    className='basic-info-submit'
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </form>
            <br />
          </>
        </FormContainer>
      </>
      <Modal show={show} onHide={handleClose} backdrop='static'>
        <Modal.Body>
          {otploading ? (
            <Loader />
          ) : (
            <Form onSubmit={submitHandler2}>
              <div style={{ padding: '20px' }}>
                <Form.Group>
                  <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                    Enter OTP sent your Phone
                  </div>
                  <Form.Text className='red-text'>
                    {/* {otpMessage && <span>{errorMessage}</span>} */}
                  </Form.Text>

                  <TextField
                    id='outlined-basic'
                    label='Enter OTP'
                    variant='outlined'
                    required
                    type='Number'
                    autoComplete='off'
                    value={otp}
                    error={otpError ? true : false}
                    helperText={otpError ? otpError : ''}
                    onChange={(e) => setOTP(e.target.value)}
                    style={{ width: '100%', marginBottom: '10px' }}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Button type='submit' variant='contained' fullWidth>
                      Verify OTP
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CreateStoreScreen
