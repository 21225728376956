export const USER_FOLLOW_REQUEST = 'USER_FOLLOW_REQUEST'
export const USER_FOLLOW_SUCESS = 'USER_FOLLOW_SUCESS'
export const USER_FOLLOW_FAIL = 'USER_FOLLOW_FAIL'
export const USER_FOLLOW_RESET = 'USER_FOLLOW_RESET'

export const USER_FOLLOWER_LIST_REQUEST = 'USER_FOLLOWER_LIST_REQUEST'
export const USER_FOLLOWER_LIST_SUCESS = 'USER_FOLLOWER_LIST_SUCESS'
export const USER_FOLLOWER_LIST_FAIL = 'USER_FOLLOWER_LIST_FAIL'

export const USER_FOLLOWING_LIST_REQUEST = 'USER_FOLLOWING_LIST_REQUEST'
export const USER_FOLLOWING_LIST_SUCESS = 'USER_FOLLOWING_LIST_SUCESS'
export const USER_FOLLOWING_LIST_FAIL = 'USER_FOLLOWING_LIST_FAIL'
