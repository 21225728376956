import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
const BookPaginate = ({
  pages,
  page,
  isAdmin = false,
  category = '',
  language,
  url,
  history,
}) => {
  const [page1, setPage1] = React.useState(1)
  const [curPage, setCurPage] = React.useState(1)

  const handleChange = () => {
    console.log("asdasd")
    // if (curPage < value) {
    //   setPage1(value + 1)
    // } else {
    //   setPage1(value - 1)
    // }

    // history.push(`/seller/books/page/${value}`)
  }

  let pagesPage = 10
  let lb = Math.ceil(page / pagesPage)
  lb = isNaN(lb) ? 10 : lb
  let lowerBound = Number((lb - 1) * 10)

  let nexPage = lowerBound + 10
  let prevPage = nexPage - 20
  if (prevPage < 0) {
    prevPage = 1
  }
  let remainingPages = pages - page
  let limitPagesPage = remainingPages < 1 ? lb + 2 : 10

  return (
    <>
      {/* pages > 1 && (
      <Stack spacing={2}>
        <Pagination>
          {nexPage > 10 && (
            <LinkContainer
              key={prevPage + 1}
              to={`/${url}/page/${prevPage + 1}`}
            >
              <Pagination.Item>Prev</Pagination.Item>
            </LinkContainer>
          )}
          {[...Array(limitPagesPage).keys()].map((x) => (
            <LinkContainer
              key={x + 1 + lowerBound}
              to={`/${url}/page/${x + 1 + lowerBound}`}
            >
              <Pagination.Item active={x + 1 + lowerBound === page}>
                {x + 1 + lowerBound}
              </Pagination.Item>
            </LinkContainer>
          ))}
          {pages > 10 && limitPagesPage === 10 && (
            <LinkContainer key={nexPage + 1} to={`/${url}/page/${nexPage + 1}`}>
              <Pagination.Item>Next</Pagination.Item>
            </LinkContainer>
          )}
        </Pagination>
      </Stack>
      ) */}
      <Stack spacing={2}>
        <Typography>Page: {page}</Typography>
        <Pagination count={pages} page={page} onChange={handleChange} />
      </Stack>
    </>
  )
}

export default BookPaginate
