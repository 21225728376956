import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import { addToCart, updateCartData } from '../../actions/cartActions.js'
import { Link } from 'react-router-dom'
import {
  FaFemale,
  FaMale,
  FaArrowAltCircleRight,
  FaArrowAltCircleLeft,
} from 'react-icons/fa'
import AiBoxBar from '../../components/AiBoxBar.js'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ThemeScreen = ({ location, history }) => {
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const [step, setStep] = useState(0)
  const [desc, setDesc] = useState('Click to know more about option')
  const [message, setMessage] = useState('')

  const updateCartRes = useSelector((state) => state.updateCart)
  const { loading, error, abandonCart, success } = updateCartRes
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [selectedOptions, setSelectedOptions] = useState({
    step0: [],
    step1: [],
    step2: [],
    step3: [],
    step4: [],
  })

  const handleOptionSelect = (stepName, option) => {
    if (selectedOptions[stepName].includes(option.key)) {
      const newAr = splice(selectedOptions[stepName], option.key)
      setSelectedOptions((prevState) => ({
        ...prevState,
        [stepName]: newAr,
      }))
      cookies.set('_it', selectedOptions, {
        path: '/',
        maxAge: 34550000,
      })
    } else {
      if (selectedOptions[stepName].length > 4 && stepName != 'step4') {
        setMessage('Only 5 options allowed')
        setDesc(option.desc)
      } else {
        if (stepName == 'step0') {
          setSelectedOptions((prevState) => ({
            ...prevState,
            [stepName]: [option.key],
          }))

          cookies.set('_it', selectedOptions, {
            path: '/',
            maxAge: 34550000,
          })
        } else {
          if (selectedOptions[stepName].length > 6 && stepName == 'step4') {
            setMessage('Only 7 emotions allowed')
            setDesc(option.desc)
          } else {
            setSelectedOptions((prevState) => ({
              ...prevState,
              [stepName]: [...prevState[stepName], option.key],
            }))

            setMessage('')
            setDesc(option.desc)
            cookies.set('_it', selectedOptions, {
              path: '/',
              maxAge: 34550000,
            })
          }
        }
      }
    }
  }
  useEffect(() => {
    if (success && step == 4) {
      if (userInfo) {
        history.push('/checkout/select-address')
      } else {
        history.push('/checkout/basic-information')
      }
    }
    const storedValue = cookies.get('_it')
    if (storedValue && selectedOptions.step0.length == 0) {
      setSelectedOptions(storedValue)
      storedValue.step0.length == 0 && setStep(0)
      storedValue.step1.length < 2 && setStep(1)
      storedValue.step2.length < 2 && setStep(2)
      storedValue.step3.length < 2 && setStep(3)
      storedValue.step4.length < 4 && setStep(4)
      storedValue.step4.length > 4 && setStep(0)
    }
  }, [success, userInfo])
  const handleNextStep = (stepName) => {
    if (selectedOptions[stepName].length < 3) {
      if (stepName == 'step0') {
        if (selectedOptions[stepName].length < 1) {
          setMessage('Select atleast 1 option')
        } else {
          dispatch(addToCart('644b94e97ab5b3695fb24988', 0, 0))
          setMessage('')
          setDesc('')
          setStep((prevStep) => prevStep + 1)
        }
      } else {
        setMessage('Select atleast 3 options')
      }
    } else {
      console.log(selectedOptions)
      setMessage('')
      setDesc('')
      if (step == 4) {
        dispatch(updateCartData(selectedOptions))
      } else {
        setStep((prevStep) => prevStep + 1)
      }
    }
  }

  const handlePrevStep = () => {
    setMessage('')
    setDesc('')
    setStep((prevStep) => prevStep - 1)
  }
  const step0Options = [
    {
      key: 'Male',
    },
    {
      key: 'Female',
    },
    {
      key: "Doesn't matter",
    },
  ]
  const step1Options = [
    {
      key: 'Descriptive',
      desc: 'Descriptive: This style focuses on creating a vivid picture of the setting, characters, and events.',
    },
    {
      key: 'Narrative',
      desc: 'Narrative:This style is focused on telling a story, often through a third-person narrator.',
    },
    {
      key: 'Character driven',
      desc: 'Character-driven: This style is centered on the characters and their inner lives.',
    },
    {
      key: 'Stream of consciousness',
      desc: 'Stream-of-consciousness: This style mimics the way thoughts flow through a character mind.',
    },
    {
      key: 'Epistolary',
      desc: 'Epistolary: This style is told through a series of letters, diary entries, or other written documents.',
    },
    {
      key: 'Experimental',
      desc: 'Experimental: This style involves breaking traditional narrative conventions and experimenting with structure, language, and form.',
    },
    {
      key: 'Gothic',
      desc: 'Gothic: This style emphasizes dark and mysterious elements, such as haunted houses, supernatural events, and creepy atmospheres',
    },
    {
      key: 'Literary',
      desc: 'Literary: This style is focused on language and often includes complex sentence structures, rich imagery, and symbolism.',
    },
    {
      key: 'Historical',
      desc: 'Historical: This style is set in a particular time period and often includes detailed descriptions of historical events, customs, and language.',
    },
    {
      key: 'Realistic',
      desc: 'Realistic: This style aims to accurately depict the world as it is, without romanticizing or idealizing it. ',
    },
    {
      key: 'Satirical',
      desc: 'Satirical: This style uses humor and irony to criticize or ridicule societal norms, beliefs, and values.',
    },
    {
      key: 'Dystopian',
      desc: 'Dystopian: This style is set in a future world where society has collapsed or is in a state of decline. ',
    },
  ]

  const step2Options = [
    {
      key: 'Quest',
      desc: 'Quest: This theme follows characters on a journey or mission to achieve a particular goal, often involving challenges, obstacles, and personal growth.',
    },
    {
      key: 'Redemption',
      desc: 'Redemption: This theme involves characters seeking forgiveness and making amends for past mistakes or wrongdoing.',
    },
    {
      key: 'Good vs. Evil',
      desc: 'Good vs. Evil: This theme involves characters struggling with moral and ethical dilemmas, often in the context of a larger conflict between forces of good and evil.',
    },
    {
      key: 'Betrayal',
      desc: 'Betrayal: This theme involves characters experiencing or perpetrating acts of betrayal, often leading to a breakdown of trust and relationships.',
    },
    {
      key: 'Friendship',
      desc: 'Friendship: This theme explores the dynamics of close friendships and the support, loyalty, and challenges that come with them.',
    },
    {
      key: 'Coming of age',
      desc: 'Coming of age: This theme follows a young protagonist as they transition from childhood to adulthood, often exploring themes of identity, independence, and responsibility.',
    },
    // {
    //   key: 'Identity',
    //   desc: 'Identity: This theme explores the concept of self and how it is shaped by personal experiences, relationships, and external factors such as culture and society.',
    // },
    {
      key: 'Power',
      desc: 'Power: This theme involves characters seeking, gaining, or losing power over others or themselves. It can include political power, social power, or personal power.',
    },
    {
      key: 'Loss',
      desc: 'Loss: This theme involves characters dealing with grief and the process of letting go after losing something or someone important.',
    },
    {
      key: 'War',
      desc: 'War: This theme involves characters experiencing the effects of war, including violence, trauma, loss, and political conflict.',
    },
    {
      key: 'Social justice',
      desc: 'Social justice: This theme explores issues of inequality and discrimination, often involving characters fighting for the rights of marginalized groups.',
    },
    // {
    //   key: 'Nature',
    //   desc: 'Nature: This theme involves characters interacting with or experiencing the natural world, often exploring the relationship between humans and the environment.',
    // },
    {
      key: 'Family',
      desc: 'Family: This theme explores the dynamics of family relationships, including love, conflict, loyalty, and betrayal.',
    },
    // {
    //   key: 'Transformation',
    //   desc: 'Transformation: This theme involves characters undergoing significant changes, whether physical, emotional, or spiritual, often leading to a newfound sense of self or purpose.',
    // },
    {
      key: 'Survival',
      desc: 'Survival: This theme involves characters facing extreme situations and fighting to survive, often against seemingly insurmountable odds.',
    },
  ]

  const step3Options = [
    {
      key: 'Linear',
      desc: 'Linear: This structure tells the story in chronological order, starting from the beginning and ending at the end.',
    },
    {
      key: 'Non-linear',
      desc: 'Non-linear: This structure tells the story out of order, jumping back and forth in time. It can create a more complex and layered narrative.',
    },
    {
      key: 'Multiple Narrators',
      desc: 'Multiple Narrators: This structure tells the story from the perspectives of multiple characters. It can create a more complex and nuanced narrative',
    },
    {
      key: 'Framed Narrative',
      desc: 'Framed Narrative: This structure involves a story within a story, where the main narrative is framed by a secondary narrative. ',
    },
    {
      key: 'Circular',
      desc: 'Circular: This structure starts and ends in the same place, creating a sense of symmetry and completeness. It can create a sense of closure and resolution.',
    },
    {
      key: 'Parallel Narrative',
      desc: 'Parallel Narrative: This structure tells two or more interrelated stories that take place at the same time. ',
    },
    {
      key: 'Flashback/Flashforward',
      desc: 'Flashback/Flashforward: This structure interrupts the linear timeline of the story to revisit events from the past or future. ',
    },
    {
      key: 'Unreliable Narrator',
      desc: 'Unreliable Narrator: This structure uses a narrator whose credibility is questionable or compromised. ',
    },
  ]
  const step4Options = [
    {
      key: 'Love',
    },
    {
      key: 'Hate',
    },
    {
      key: 'Joy',
    },
    {
      key: 'Sadness',
    },
    {
      key: 'Fear',
    },
    {
      key: 'Anger',
    },
    // {
    //   key: 'Envy',
    // },
    {
      key: 'Jealousy',
    },
    // {
    //   key: 'Shock',
    // },
    {
      key: 'Confusion',
    },
    {
      key: 'Surprise',
    },
    {
      key: 'Anticipation',
    },
    {
      key: 'Excitement',
    },
    {
      key: 'Empathy',
    },
    {
      key: 'Curiosity',
    },
    {
      key: 'Ambition',
    },
    {
      key: 'Pride',
    },
    {
      key: 'Guilt',
    },
    {
      key: 'Regret',
    },
    {
      key: 'Nostalgia',
    },
  ]
  function splice(arr, val) {
    for (var i = arr.length; i--; ) {
      if (arr[i] === val) {
        arr.splice(i, 1)
      }
    }
    return arr
  }
  return (
    <Container>
      <Container>
        <div style={{ textAlign: 'center' }}>
          <Link to={'/'}>
            <LazyLoadImage
              src={'https://www.99bookscart.com/images/logo.png'}
              alt={'Indias first AI curated box'}
              className='curated-box-logo'
            />
          </Link>
          {step === 0 && (
            <div className='text-center'>
              {/* <amp-img
                src='https://s3-books-images.s3.amazonaws.com/c3b61e1d-b9e8-4c34-bfab-576ca7a1eede.png'
                alt='near me bookstore, books store near me'
                layout='responsive'
                height='300'
                width='1500'
              /> */}
              <h1 style={{ fontSize: '24px' }}>
                India's First AI Currated Book Box
              </h1>
              <h1>Get 10 Books for ₹999</h1>
              <div style={{ marginTop: '18px' }}>
                Based on your prefrence our AI will select books from huge
                collection 1.8 million books
              </div>
              {/* <small className='text-muted'>Free Shipping</small> */}
              <br />
              <AiBoxBar value={20}></AiBoxBar>
              <Row style={{ textAlign: 'center' }}>
                <div className='curated-explantion'>
                  <div style={{ float: 'left', marginRight: '10px' }}>
                    <LazyLoadImage
                      src={
                        'https://s3-books-images.s3.amazonaws.com/0cfb76fd-ed3e-468b-b01c-eab50e0e112a.png'
                      }
                      alt={'Indias first AI curated box 99bookscart'}
                      style={{ float: 'right' }}
                    />
                  </div>
                  <div className='assitant-mesg'>
                    {' '}
                    Select prefered author gender
                  </div>
                </div>
              </Row>

              <div className='option-message'>{message && message}</div>
              <Container>
                <Row>
                  {step0Options.map((option) => (
                    <Col xs={4} sm={4} md={4} lg={4} key={option.key}>
                      <Row key={option.key} className='ai-options'>
                        <Container>
                          {selectedOptions.step0.includes(option.key) ? (
                            <Button
                              className='topic-option-selected'
                              onClick={() =>
                                handleOptionSelect('step0', option)
                              }
                            >
                              {option.key == 'Male' && <FaMale />}
                              {option.key == 'Female' && <FaFemale />}
                              {option.key}
                            </Button>
                          ) : (
                            <Button
                              className='topic-option'
                              onClick={() =>
                                handleOptionSelect('step0', option)
                              }
                            >
                              {option.key == 'Male' && <FaMale />}
                              {option.key == 'Female' && <FaFemale />}
                              {option.key}
                            </Button>
                          )}
                        </Container>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Container>
              <Container>
                <Row style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <br />
                  <Button
                    className='step-button-one'
                    onClick={() => handleNextStep('step0')}
                  >
                    Next <FaArrowAltCircleRight />
                  </Button>
                </Row>
              </Container>
            </div>
          )}
          {step === 1 && (
            <div className='text-center'>
              <AiBoxBar value={40}></AiBoxBar>
              <Row style={{ textAlign: 'center' }}>
                <div className='curated-explantion'>
                  <div style={{ float: 'left', marginRight: '10px' }}>
                    <LazyLoadImage
                      src='https://s3-books-images.s3.amazonaws.com/0cfb76fd-ed3e-468b-b01c-eab50e0e112a.png'
                      alt={'Indias first AI curated box 99bookscart'}
                      style={{ float: 'right' }}
                    />
                  </div>
                  <div className='assitant-mesg'>
                    {' '}
                    Select 3-5 author writing style
                  </div>
                </div>
              </Row>

              {desc && <div className='style-description'>{desc}</div>}

              <div className='option-message'>{message && message}</div>
              <Container>
                <Row>
                  {step1Options.map((option) => (
                    <Col xs={6} sm={6} md={4} lg={3} key={option.key}>
                      <Row key={option.key} className='ai-options'>
                        <Container>
                          {selectedOptions.step1.includes(option.key) ? (
                            <Button
                              className='topic-option-selected'
                              onClick={() =>
                                handleOptionSelect('step1', option)
                              }
                            >
                              {option.key}
                            </Button>
                          ) : (
                            <Button
                              className='topic-option'
                              onClick={() =>
                                handleOptionSelect('step1', option)
                              }
                            >
                              {option.key}
                            </Button>
                          )}
                        </Container>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Container>
              <Button className='step-button' onClick={handlePrevStep}>
                <FaArrowAltCircleLeft /> Previous
              </Button>
              <Button
                className='step-button'
                onClick={() => handleNextStep('step1')}
              >
                Next <FaArrowAltCircleRight />
              </Button>
            </div>
          )}

          {step === 2 && (
            <div className='text-center'>
              <AiBoxBar value={60}></AiBoxBar>
              <Row style={{ textAlign: 'center' }}>
                <div className='curated-explantion'>
                  <div style={{ float: 'left', marginRight: '10px' }}>
                    <LazyLoadImage
                      src='https://s3-books-images.s3.amazonaws.com/0cfb76fd-ed3e-468b-b01c-eab50e0e112a.png'
                      alt={'Indias first AI curated box 99bookscart'}
                      style={{ float: 'right' }}
                    />
                  </div>
                  <div className='assitant-mesg'>
                    {' '}
                    Select 3-5 favorite themes
                  </div>
                </div>
              </Row>

              {desc && <div className='style-description'>{desc}</div>}
              <div className='option-message'>{message && message}</div>
              <Container>
                <Row>
                  {step2Options.map((option) => (
                    <Col xs={6} sm={6} md={4} lg={3} key={option.key}>
                      <Row key={option.key} className='ai-options'>
                        <Container>
                          {selectedOptions.step2.includes(option.key) ? (
                            <Button
                              className='topic-option-selected'
                              onClick={() =>
                                handleOptionSelect('step2', option)
                              }
                            >
                              {option.key}
                            </Button>
                          ) : (
                            <Button
                              className='topic-option'
                              onClick={() =>
                                handleOptionSelect('step2', option)
                              }
                            >
                              {option.key}
                            </Button>
                          )}
                        </Container>
                        {/* <Col xs={2}>
                      <input
                        type='checkbox'
                        checked={selectedOptions.step1.includes(option)}
                        onChange={() => handleOptionSelect('step1', option)}
                        className='ai-checkbox'
                      />
                    </Col>
                    <Col xs={10}>
                      <label>{option}</label>
                    </Col> */}
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Container>
              <Button className='step-button' onClick={handlePrevStep}>
                <FaArrowAltCircleLeft /> Previous
              </Button>
              <Button
                className='step-button'
                onClick={() => handleNextStep('step2')}
              >
                Next <FaArrowAltCircleRight />
              </Button>
              <br />
            </div>
          )}

          {step === 3 && (
            <div className='text-center'>
              <AiBoxBar value={80}></AiBoxBar>
              <Row style={{ textAlign: 'center' }}>
                <div className='curated-explantion'>
                  <div style={{ float: 'left', marginRight: '10px' }}>
                    <LazyLoadImage
                      src='https://s3-books-images.s3.amazonaws.com/0cfb76fd-ed3e-468b-b01c-eab50e0e112a.png'
                      alt={'Indias first AI curated box 99bookscart'}
                      style={{ float: 'right' }}
                    />
                  </div>
                  <div className='assitant-mesg'>
                    {' '}
                    Select 3-5 favorite narrative structure
                  </div>
                </div>
              </Row>

              {desc && <div className='style-description'>{desc}</div>}
              <div className='option-message'>{message && message}</div>
              <Container>
                <Row>
                  {step3Options.map((option) => (
                    <Col xs={6} sm={6} md={4} lg={3} key={option.key}>
                      <Row key={option.key} className='ai-options'>
                        <Container>
                          {selectedOptions.step3.includes(option.key) ? (
                            <Button
                              className='topic-option-selected'
                              onClick={() =>
                                handleOptionSelect('step3', option)
                              }
                            >
                              {option.key}
                            </Button>
                          ) : (
                            <Button
                              className='topic-option'
                              onClick={() =>
                                handleOptionSelect('step3', option)
                              }
                            >
                              {option.key}
                            </Button>
                          )}
                        </Container>
                        {/* <Col xs={2}>
                    <input
                      type='checkbox'
                      checked={selectedOptions.step1.includes(option)}
                      onChange={() => handleOptionSelect('step1', option)}
                      className='ai-checkbox'
                    />
                  </Col>
                  <Col xs={10}>
                    <label>{option}</label>
                  </Col> */}
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Container>
              <Button className='step-button' onClick={handlePrevStep}>
                <FaArrowAltCircleLeft /> Previous
              </Button>
              <Button
                className='step-button'
                onClick={() => handleNextStep('step3')}
              >
                Next <FaArrowAltCircleRight />
              </Button>
            </div>
          )}
          {step === 4 && (
            <div className='text-center'>
              <AiBoxBar value={100}></AiBoxBar>
              <Row style={{ textAlign: 'center' }}>
                <div className='curated-explantion'>
                  <div style={{ float: 'left', marginRight: '10px' }}>
                    <LazyLoadImage
                      src='https://s3-books-images.s3.amazonaws.com/0cfb76fd-ed3e-468b-b01c-eab50e0e112a.png'
                      alt={'Indias first AI curated box 99bookscart'}
                      style={{ float: 'right' }}
                    />
                  </div>
                  <div className='assitant-mesg'>
                    {' '}
                    Select 5-7 favorite emotions
                  </div>
                </div>
              </Row>

              {/* <div className='style-description'>{desc}</div> */}
              <div className='option-message'>{message && message}</div>
              <Container>
                <Row>
                  {step4Options.map((option) => (
                    <Col xs={6} sm={6} md={4} lg={3} key={option.key}>
                      <Row key={option.key} className='ai-options'>
                        <Container>
                          {selectedOptions.step4.includes(option.key) ? (
                            <Button
                              className='topic-option-selected'
                              onClick={() =>
                                handleOptionSelect('step4', option)
                              }
                            >
                              {option.key}
                            </Button>
                          ) : (
                            <Button
                              className='topic-option'
                              onClick={() =>
                                handleOptionSelect('step4', option)
                              }
                            >
                              {option.key}
                            </Button>
                          )}
                        </Container>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Container>
              <Button className='step-button' onClick={handlePrevStep}>
                <FaArrowAltCircleLeft /> Previous
              </Button>
              <Button
                className='step-button'
                onClick={() => handleNextStep('step4')}
              >
                Confirm <FaArrowAltCircleRight />
              </Button>
            </div>
          )}
          {/* <div className='step-footer'>
            <div>1500+ Reviews</div>
            <div>100% Customer Satisfaction</div>
            <div>Free Bookmarks</div>
          </div> */}
        </div>
      </Container>
    </Container>
  )
}
export default ThemeScreen
