export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS'
export const CART_SAVE_PAYMENT_METHOD = 'CART_SAVE_PAYMENT_METHOD'
export const CART_SAVE_SHIPPING_METHOD = 'CART_SAVE_SHIPPING_METHOD'
export const CART_SAVE_TOTAL = 'CART_SAVE_TOTAL'
export const CART_SAVE_DISCOUNT = 'CART_SAVE_DISCOUNT'
export const CART_RESET = 'CART_RESET'
export const CART_SAVE = 'CART_SAVE'

export const CART_READ = 'SMALL BOX'
export const CART_READER = 'MEDIUM BOX'
export const CART_READEST = 'LARGE BOX'

export const ABANDON_CART_REQUEST = 'ABANDON_CART_REQUEST'
export const ABANDON_CART_SUCCESS = 'ABANDON_CART_SUCCESS'
export const ABANDON_CART_FAIL = 'ABANDON_CART_FAIL'
export const ABANDON_CART_RESET = 'ABANDON_CART_RESET'

export const RECENT_VIEW_ADD_ITEM = 'RECENT_VIEW_ADD_ITEM'

export const ABANDON_CART_LIST_REQUEST = 'ABANDON_CART_LIST_REQUEST'
export const ABANDON_CART_LIST_SUCCESS = 'ABANDON_CART_LIST_SUCCESS'
export const ABANDON_CART_LIST_FAIL = 'ABANDON_CART_LIST_FAIL'

export const CART_LIST_REQUEST = 'CART_LIST_REQUEST'
export const CART_LIST_SUCCESS = 'CART_LIST_SUCCESS'
export const CART_LIST_FAIL = 'CART_LIST_FAIL'

export const CART_UPDATE_REQUEST = 'CART_UPDATE_REQUEST'
export const CART_UPDATE_SUCCESS = 'CART_UPDATE_SUCCESS'
export const CART_UPDATE_FAIL = 'CART_UPDATE_FAIL'

export const ABANDON_CART_DETAILS_REQUEST = 'ABANDON_CART_DETAILS_REQUEST'
export const ABANDON_CART_DETAILS_SUCCESS = 'ABANDON_CART_DETAILS_SUCCESS'
export const ABANDON_CART_DETAILS_FAIL = 'ABANDON_CART_DETAILS_FAIL'

export const EMAIL_POP_UP_REQUEST = 'EMAIL_POP_UP_REQUEST'
export const EMAIL_POP_UP_SUCCESS = 'EMAIL_POP_UP_SUCCESS'
export const EMAIL_POP_UP_FAIL = 'EMAIL_POP_UP_FAIL'

export const EMAIL_SUBMIT_REQUEST = 'EMAIL_SUBMIT_REQUEST'
export const EMAIL_SUBMIT_SUCCESS = 'EMAIL_SUBMIT_SUCCESS'
export const EMAIL_SUBMIT_FAIL = 'EMAIL_SUBMIT_FAIL'

export const EMAIL_SUBMIT_LIST_REQUEST = 'EMAIL_SUBMIT_LIST_REQUEST'
export const EMAIL_SUBMIT_LIST_SUCCESS = 'EMAIL_SUBMIT_LIST_SUCCESS'
export const EMAIL_SUBMIT_LIST_FAIL = 'EMAIL_SUBMIT_LIST_FAIL'

export const USER_CART_REQUEST = 'USER_CART_REQUEST'
export const USER_CART_SUCCESS = 'USER_CART_SUCCESS'
export const USER_CART_FAIL = 'USER_CART_FAIL'
export const USER_CART_RESET = 'USER_CART_RESET'

export const USER_CART_EDIT_REQUEST = 'USER_CART_EDIT_REQUEST'
export const USER_CART_EDIT_SUCCESS = 'USER_CART_EDIT_SUCCESS'
export const USER_CART_EDIT_FAIL = 'USER_CART_EDIT_FAIL'

export const USER_CART_REMOVE_REQUEST = 'USER_CART_REMOVE_REQUEST'
export const USER_CART_REMOVE_SUCCESS = 'USER_CART_REMOVE_SUCCESS'
export const USER_CART_REMOVE_FAIL = 'USER_CART_REMOVE_FAIL'
export const USER_CART_REMOVE_RESET = 'USER_CART_REMOVE_RESET'

export const DISCOUNT_CODE_REMOVE_REQUEST = 'DISCOUNT_CODE_REMOVE_REQUEST'
export const DISCOUNT_CODE_REMOVE_SUCCESS = 'DISCOUNT_CODE_REMOVE_SUCCESS'
export const DISCOUNT_CODE_REMOVE_FAIL = 'DISCOUNT_CODE_REMOVE_FAIL'

export const USER_CART_PAYMENT_REQUEST = 'USER_CART_PAYMENT_REQUEST'
export const USER_CART_PAYMENT_SUCCESS = 'USER_CART_PAYMENT_SUCCESS'
export const USER_CART_PAYMENT_FAIL = 'USER_CART_PAYMENT_FAIL'
export const USER_CART_PAYMENT_RESET = 'USER_CART_PAYMENT_RESET'

export const USER_CART_EMAIL_SAVE_REQUEST = 'USER_CART_EMAIL_SAVE_REQUEST'
export const USER_CART_EMAIL_SAVE_SUCCESS = 'USER_CART_EMAIL_SAVE_SUCCESS'
export const USER_CART_EMAIL_SAVE_FAIL = 'USER_CART_EMAIL_SAVE_FAIL'

export const WISHLIST_ADD_ITEM = 'WISHLIST_ADD_ITEM'
