import { React, useEffect } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { adminOrderList } from '../actions/orderActions'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Loader from '../components/Loader'
import Message from '../components/Message'
import AdminNav from '../components/AdminNav'
import DayProgressBar from '../components/Admin/DayProgressBar'
import { logout } from '../actions/userActions'

export const AdminStatsDashboardScreen = ({ history }) => {
  const orderStatsRes = useSelector((state) => state.adminOrderList)
  const { loading, error, data, orderStats } = orderStatsRes

  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(adminOrderList())
    if (error) {
      dispatch(logout())
      history.push('/login')
    }
  }, [dispatch])
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>error</Message>
      ) : (
        <Container>
          <AdminNav></AdminNav>

          <Row>
            <div className='adminBox'>
              <>
                <p>Today</p>
                <h6>
                  Orders:{data.thisDay.count}
                  <br />
                  {(
                    (data.thisDay.count / data.thisDay.abandonCartCount) *
                    100
                  ).toFixed(2)}
                  %
                </h6>
                <h6>
                  Abandon Cart:{data.thisDay.abandonCartCount}
                  <br />
                  {(
                    (data.thisDay.abandonCartCount / data.thisDay.cartCount) *
                    100
                  ).toFixed(2)}
                  %
                </h6>
                <h6>Cart:{data.thisDay.cartCount}</h6>

                <h4>{data.thisDay.sum.toLocaleString()}</h4>
              </>
            </div>
            <div className='adminBox'>
              <>
                <p>This week</p>
                <h6>
                  Orders:{data.thisWeek.count}
                  <br />
                  {(
                    (data.thisWeek.count / data.thisWeek.abandonCartCount) *
                    100
                  ).toFixed(2)}
                  %
                </h6>
                <h6>
                  Abandon Cart:{data.thisWeek.abandonCartCount}
                  <br />
                  {(
                    (data.thisWeek.abandonCartCount / data.thisWeek.cartCount) *
                    100
                  ).toFixed(2)}
                  %
                </h6>
                <h6>Cart:{data.thisWeek.cartCount}</h6>
                <h4>{data.thisWeek.sum.toLocaleString()}</h4>
              </>
            </div>
            <div className='adminBox'>
              <>
                <p>This Month</p>
                <h6>
                  Orders:{data.thisMonth.count}
                  <br />
                  {(
                    (data.thisMonth.count / data.thisMonth.abandonCartCount) *
                    100
                  ).toFixed(2)}
                </h6>
                <h6>
                  Abandon Cart:{data.thisMonth.abandonCartCount}
                  <br />
                  {(
                    (data.thisMonth.abandonCartCount /
                      data.thisMonth.cartCount) *
                    100
                  ).toFixed(2)}
                  %
                </h6>
                <h6>Cart:{data.thisMonth.cartCount}</h6>
                <h4>{data.thisMonth.sum.toLocaleString()}</h4>
              </>
            </div>
          </Row>
          <Row>
            {orderStats &&
              orderStats.map((stats) => (
                <>
                  <Row className='order-row'>
                    <h2>{stats._id.createdAt}</h2>
                    <DayProgressBar
                      ProgressValue={stats.fullfilledOrder / stats.count}
                    ></DayProgressBar>
                    <Col>
                      Orders: {stats.count}
                      <br />
                      COD:{stats.CODOrders} Paid:{stats.paidOrders}
                      <br />
                      P: ₹
                      {(
                        stats.totalAmount -
                        stats.codTotal -
                        (stats.count * 200 +
                          (stats.count * 150 - stats.shippingTotal) +
                          (stats.numberOfBox * 10 +
                            (stats.numberOfAIBox + 10) +
                            (stats.itemCounts -
                              stats.numberOfAIBox -
                              stats.numberOfBox)) *
                            33)
                      ).toLocaleString()}
                    </Col>
                    <Col>
                      # Box: {stats.numberOfBox}
                      <br /># AI Box: {stats.numberOfAIBox}
                      <br />
                      Lose Orders:{stats.numberLossOrders - stats.numberOfAIBox}
                      <br />
                      Fulfilled: {stats.fullfilledOrder}
                    </Col>
                    <Col>
                      Item Charges: ₹{stats.cartTotal.toLocaleString()}
                      <br />
                      Shipping Charges: ₹{stats.shippingTotal.toLocaleString()}
                      <br />
                      COD Charges: ₹{stats.codTotal.toLocaleString()}
                    </Col>
                    <Col>
                      Marketing Cost: ₹{(stats.count * 150).toLocaleString()}
                      <br />
                      Shipping Cost: ₹
                      {(
                        stats.count * 250 -
                        stats.shippingTotal
                      ).toLocaleString()}
                      <br />
                      Book cost: ₹
                      {(
                        (stats.numberOfBox * 15 +
                          stats.numberOfAIBox * 15 +
                          (stats.itemCounts -
                            stats.numberOfAIBox -
                            stats.numberOfBox)) *
                        33
                      ).toLocaleString()}{' '}
                      <br />
                      <Link to={`/admin/orderlist?date=${stats._id.createdAt}`}>
                        <Button>View</Button>
                      </Link>
                      {/* total item count {stats.itemCounts} <br />
                      total loss orders:
                      {stats.numberLossOrders - stats.numberOfAIBox} <br />
                      loss orderlineitems{' '}
                      {stats.itemCounts -
                        stats.numberOfAIBox -
                        stats.numberOfBox}
                      <br />
                      per order:
                      {(stats.itemCounts -
                        stats.numberOfAIBox -
                        stats.numberOfBox) /
                        (stats.numberLossOrders - stats.numberOfAIBox)} */}
                    </Col>
                  </Row>
                  <br />
                </>
              ))}
          </Row>
        </Container>
      )}
    </Container>
  )
}
