export const USER_READINGS_ADD_REQUEST = 'USER_READINGS_ADD_REQUEST'
export const USER_READINGS_ADD_SUCESS = 'USER_READINGS_ADD_SUCESS'
export const USER_READINGS_ADD_FAIL = 'USER_READINGS_ADD_FAIL'

export const USER_READING_ADD_REQUEST = 'USER_READING_ADD_REQUEST'
export const USER_READING_ADD_SUCESS = 'USER_READING_ADD_SUCESS'
export const USER_READING_ADD_FAIL = 'USER_READING_ADD_FAIL'

export const USER_READING_LIST_REQUEST = 'USER_READING_LIST_REQUEST'
export const USER_READING_LIST_SUCESS = 'USER_READING_LIST_SUCESS'
export const USER_READING_LIST_FAIL = 'USER_READING_LIST_FAIL'

export const READING_COUNT_REQUEST = 'READING_COUNT_REQUEST'
export const READING_COUNT_SUCCEES = 'READING_COUNT_SUCCEES'
export const READING_COUNT_FAIL = 'READING_COUNT_FAIL'

export const USER_READING_STATUS_REQUEST = 'USER_READING_STATUS_REQUEST'
export const USER_READING_STATUS_SUCESS = 'USER_READING_STATUS_SUCESS'
export const USER_READING_STATUS_FAIL = 'USER_READING_STATUS_FAIL'
