import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Card,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {
  FaBookOpen,
  FaBoxOpen,
  FaMoneyBillAlt,
  FaThList,
  FaTools,
} from 'react-icons/fa'
import { getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import { register } from '../../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'
import SocialLogin from '../../../components/SocialLogin'

import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/plots/meta/HomeMeta'
import { getStore } from '../../../actions/storeActions'
import SellerProductView from '../components/SellerProductView'

const SellerAppHomeScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const storeData = useSelector((state) => state.getStore)
  const { loading, error, success, result } = storeData

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userLoggedIn) {
      dispatch(getStore())
      if (success && !result.store.storeName) {
        history.push('/seller/create/new/store')
      }
    } else {
      history.push('/register?redirect=/seller/dashboard')
    }
  }, [dispatch, userLoggedIn])
  return (
    <>
      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          success &&
          (result.store.storeName ? (
            <Container>
              <Row>
                <Col>
                  <div className='text-center'>
                    <div>
                      <Link
                        to={`/store/${result.store.storeName}/${result.store._id}`}
                      >
                        {result.store.storeName}
                      </Link>
                    </div>
                    <Link
                      to={`/store/${result.store.storeName}/${result.store._id}`}
                    >
                      <LazyLoadImage
                        src={result.store.storeImage}
                        height={'250'}
                        width={'250'}
                        alt={`${userLoggedIn.name} 99bookscart profile image`}
                      />
                    </Link>
                    <br />

                    <div
                      style={{ width: '350px', color: '#fff' }}
                      className='center-block'
                    ></div>
                  </div>
                </Col>
              </Row>

              <Container>
                {/* <Row>
                <Col xs={12} className='center-block'>
                  <br />
                  <Link
                    to={'/create/episode'}
                    className='newbuttons text-center wd200'
                  >
                    <FaPlus></FaPlus> Write Your Story
                  </Link>
                </Col>
              </Row> */}
                <Row>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <Link
                      to='/sell/new/book'
                      className=' seller-profile-button'
                    >
                      <FaBoxOpen></FaBoxOpen> Add Books
                    </Link>
                    <Link to='/seller/books' className=' seller-profile-button'>
                      <FaBookOpen> </FaBookOpen> Books
                    </Link>
                    <Link
                      to='/seller/orders'
                      className=' seller-profile-button'
                    >
                      <FaMoneyBillAlt></FaMoneyBillAlt> Orders
                    </Link>
                    <Link to='/seller/store' className=' seller-profile-button'>
                      <FaThList></FaThList> Store
                    </Link>
                    <Link
                      to='/seller/payment'
                      className=' seller-profile-button'
                    >
                      <FaTools></FaTools> Payments
                    </Link>
                  </Col>
                </Row>
              </Container>
              {/* <Row>
                <Col xs={12} sm={6}>
                  <h1 className='text-center'>Welcome to Seller dashboard</h1>
                  <div>
                    <h3>Store Name: {result.store.storeName}</h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Link to={'/sell/new/book'}>
                  <Button>Add Book</Button>
                </Link>
                <Link to={'/seller/books'}>
                  <Button>Books</Button>
                </Link>
                <Link to={'/seller/orders'}>
                  <Button>Order</Button>
                </Link>
                <Link to={'/seller/store'}>
                  <Button>Store</Button>
                </Link>
                <Link to={'/seller/payment'}>
                  <Button>Payments</Button>
                </Link>
              </Row> */}
            </Container>
          ) : (
            <Row>
              <Col className='text-center'>
                <br />
                <br />
                <h1>Create You own Book Store </h1>
                <br />
                <div>Sell your old books and make room for new books</div>
                <br />
                <Link to={'/seller/create/new/store'} className='btn'>
                  <Button> Create Store</Button>
                </Link>
                <br />
                <br />
                <small>All books will be picked up from your place/store</small>
              </Col>
            </Row>
          ))
        )}
      </Container>
    </>
  )
}

export default SellerAppHomeScreen
