import {
  PRODUCT_LIST_REQUST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_REQUST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_REQUST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_REQUST,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_CREATE_REVIEW_REQUST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_PRODUCT_TOP_REQUST,
  PRODUCT_PRODUCT_TOP_SUCCESS,
  PRODUCT_PRODUCT_TOP_FAIL,
  PRODUCT_BESTSELLING_REQUST,
  PRODUCT_BESTSELLING_SUCCESS,
  PRODUCT_BESTSELLING_FAIL,
  PRODUCT_REDIRECT_REQUST,
  PRODUCT_REDIRECT_SUCCESS,
  PRODUCT_REDIRECT_FAIL,
  PRODUCT_SCAN_REQUST,
  PRODUCT_SCAN_SUCCESS,
  PRODUCT_SCAN_FAIL,
  PRODUCT_UPSERT_REQUST,
  PRODUCT_UPSERT_SUCCESS,
  PRODUCT_UPSERT_FAIL,
  PRODUCT_AT_REQUST,
  PRODUCT_AT_SUCCESS,
  PRODUCT_AT_FAIL,
  PRODUCT_ALL_LIST_REQUST,
  PRODUCT_ALL_LIST_SUCCESS,
  PRODUCT_ALL_LIST_FAIL,
  SELLER_PRODUCT_SCAN_REQUST,
  SELLER_PRODUCT_SCAN_SUCCESS,
  SELLER_PRODUCT_SCAN_FAIL,
  PRODUCT_BY_TYPE_REQUST,
  PRODUCT_BY_TYPE_SUCCESS,
  PRODUCT_BY_TYPE_FAIL,
  PRODUCT_BY_GENRE_REQUST,
  PRODUCT_BY_GENRE_SUCCESS,
  PRODUCT_BY_GENRE_FAIL,
} from '../constants/productConstants'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
export const listProducts =
  (keyword = '', pageNumber = '', search = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUST })
      const { data } = await axios.get(
        `/api/products?keyword=${keyword}&pageNumber=${pageNumber}&search=${search}`
      )
      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listProductsDetails = (id, slug) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUST })
    const { data } = await axios.get(`/api/products/${id}/${slug}`)
    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.delete(`/api/products/${id}`, config)

    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/products/`, product, config)

    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const updateProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(
      `/api/products/${product._id}`,
      product,
      config
    )

    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const createProductReview =
  (productId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_CREATE_REVIEW_REQUST,
      })

      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      await axios.post(`/api/products/${productId}/reviews`, review, config)

      dispatch({
        type: PRODUCT_CREATE_REVIEW_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_CREATE_REVIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const listTopProducts =
  (keyword = '', pageNumber = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_PRODUCT_TOP_REQUST })
      const { data } = await axios.get(`/api/products/new/`)
      dispatch({
        type: PRODUCT_PRODUCT_TOP_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_PRODUCT_TOP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const listBestSellingProducts =
  (keyword = '', pageNumber = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_BESTSELLING_REQUST })
      const { data } = await axios.get(`/api/products/bestsellers/`)
      dispatch({
        type: PRODUCT_BESTSELLING_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_BESTSELLING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const getProductById = (id) => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.get(`/api/products/${id}`, config)

    return data
  } catch (error) {
    return null
  }
}

export const listProductsDetailsForRedirect =
  (title) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_REDIRECT_REQUST })
      const { data } = await axios.get(`/api/products/redirect/${title}`)
      dispatch({
        type: PRODUCT_REDIRECT_SUCCESS,
        payload: data,
      })
      return data
    } catch (error) {
      dispatch({
        type: PRODUCT_REDIRECT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
      return error
    }
  }
export const scanProduct = (isbn) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_SCAN_REQUST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/products/scan/${isbn}`, config)

    dispatch({
      type: PRODUCT_SCAN_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_SCAN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const upsertProductDetails =
  (isbn, price, inventory, location, cover, language, type, mrp) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_UPSERT_REQUST,
      })

      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.post(
        `/api/products/upsert/${isbn}`,
        { isbn, price, inventory, location, cover, language, type, mrp },
        config
      )

      dispatch({
        type: PRODUCT_UPSERT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_UPSERT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const getProductAttributes = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_AT_REQUST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/products/attribute/${id}`, config)

    dispatch({
      type: PRODUCT_AT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_AT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const productListAll = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_ALL_LIST_REQUST,
    })

    const { data } = await axios.get(`/api/products/all`)

    dispatch({
      type: PRODUCT_ALL_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_ALL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const sellerScanProduct = (isbn) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SELLER_PRODUCT_SCAN_REQUST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(
      `/api/products/seller/scan/${isbn}`,
      config
    )

    dispatch({
      type: SELLER_PRODUCT_SCAN_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SELLER_PRODUCT_SCAN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const listProductsByType =
  (type, pageNumber = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_BY_TYPE_REQUST })
      const { data } = await axios.get(
        `/api/products/byType?type=${type}&pageNumber=${pageNumber}`
      )
      dispatch({
        type: PRODUCT_BY_TYPE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_BY_TYPE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listProductsByGenre =
  (genre, pageNumber = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_BY_GENRE_REQUST })

      const { data } = await axios.get(
        `/api/products/byGenre?genre=${encodeURIComponent(
          genre
        )}&pageNumber=${pageNumber}`
      )

      dispatch({
        type: PRODUCT_BY_GENRE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_BY_GENRE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

