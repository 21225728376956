import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { listStory } from '../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import Loader from '../../components/Loader'
import Story from '../../components/Story'
import PaginateStory from '../../components/PaginateStory'
import Message from '../../components/Message'
import AdminNav from '../../components/AdminNav'
const AdminMenuScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1

  const listStoryD = useSelector((state) => state.listStory)
  const { stories, loading, error, suceess, page, pages } = listStoryD

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [dispatch, pageNumber])
  return (
    <Container>
      <AdminNav></AdminNav>
      <Row>
        <Col>dsfsdf</Col>
      </Row>
    </Container>
  )
}

export default AdminMenuScreen
