import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'
import { login } from '../../../actions/userActions'
import FormContainer from '../../../components/FormContainer'
import { convertToSlug, convertToSlug2 } from '../../../helpers/helpers'
import AWS from 'aws-sdk'
import fs from 'fs'
import {
  sellerScanProduct,
  createProduct,
} from '../../../actions/productActions'
// import Camera from 'react-html5-camera-photo'

import { INVENTORY_CREATE_RESET } from '../../../constants/inventoryConstants'
import { createInventory } from '../../../actions/inventoryActions'
import { addStoreBook } from '../../../actions/storeActions'

import { Table, Button, Row, Col, Container, Modal } from 'react-bootstrap'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import CssBaseline from '@mui/material/CssBaseline'
import Breadcrumbs from '@mui/material/Breadcrumbs'
//import Link from '@mui/material/Link'
import { Typography } from '@mui/material'
import { getStore } from '../../../actions/storeActions'
import debounce from 'lodash.debounce'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FaCheck } from 'react-icons/fa'
const AddNewBookScreen = ({ match, history }) => {
  const productId = match.params.id
  const [loadingP, setLoadingP] = useState(false)

  const [addNewBook, setAddNewBook] = useState(false)
  const [addNewBook2, setAddNewBook2] = useState(false)
  const [scanned, setScanned] = useState(false)
  const [newBook, setNewBook] = useState(false)
  const [bookId, setBookId] = useState('')
  const [lang, setLang] = useState('')
  const [binding, setBinding] = useState('')
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [image, setImage] = useState('')
  const [description, setDescription] = useState('')
  const [tags, setTags] = useState('')
  const [type, setType] = useState('')
  const [bookType, setBookType] = useState('')
  const [selectedGenre, setselectedGenre] = useState('')
  const [genres, setGenres] = useState([])
  const [isbn, setIsbn] = useState('')
  const [salePrice, setSaleprice] = useState('')
  const [countInStock, setCountInStock] = useState(1)
  const [pages, setPages] = useState('')
  const [edition, setEdition] = useState('')
  const [author, setAuthor] = useState('')
  const [publisher, setPublisher] = useState('')
  const [grating, setGrating] = useState('')
  const [gnumReviews, setGnumReviews] = useState('')
  const [location, setLocation] = useState('')
  const [uploading, setUploading] = useState(false)
  const [uploadingEbook, setUploadingEbook] = useState(false)
  const [isEbook, setIsEbook] = useState(false)
  const [onLoad, setOnLoad] = useState(true)
  const [load, setLoad] = useState(false)
  const [paymentType, setPaymentType] = useState('')
  const [searc, setSearch] = useState('')
  const [show, setShow] = useState(false)
  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const search = window.location.search
  const params = new URLSearchParams(search)
  const storeC = params.get('storeC')
  const [file, setFile] = useState(null)
  const [resultz, setResultz] = useState()
  const [resShow, setResShow] = useState(false)
  const [pstat, setPstat] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const productScan = useSelector((state) => state.sellerProductScan)
  const {
    loading: scanLoading,
    success: scanSuccess,
    error: scanError,
    product: scanedProduct,
    newBook: nBook,
  } = productScan

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const createInventoryRes = useSelector((state) => state.createInventory)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = createInventoryRes

  const addBookToStore = useSelector((state) => state.addBookToStore)
  const { success: successBookToStore } = addBookToStore

  const storeData = useSelector((state) => state.getStore)
  const {
    loading: storeLoading,
    error: storeError,
    success: storeSuccess,
    result,
  } = storeData

  useEffect(() => {
    window.scrollTo(0, 0)
    if (searc.length < 4) {
      setResults([])
      return
    }
    setIsLoading(true)
    // Debounce to prevent calling the API on every keystroke
    const searchBooks = debounce((searchTerm) => {
      axios
        .get(`/api/products?keyword=${searchTerm}&limit=7`)
        .then((response) => {
          setIsLoading(false)
          setResults(response.data.products)
          if (response.data.products.length === 0) {
            setAddNewBook(true)
            setAddNewBook2(true)
            if (!isNaN(parseInt(searchTerm))) {
              setIsbn(searchTerm)
            } else {
              setName(searchTerm)
              setIsbn('')
            }
          } else {
            setAddNewBook(false)
            setAddNewBook2(false)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
        })
    }, 500)

    searchBooks(searc)
    if (userLoggedIn && !loading && !storeSuccess && !storeC) {
      dispatch(getStore())
    }
    if (storeSuccess && !storeC) {
      if (!result.store.storeName) {
        history.push('/seller/store')
      }
    }
    if (successCreate || successBookToStore) {
      dispatch({ type: INVENTORY_CREATE_RESET })
      setScanned(false)
      setName('')
      setImage('')
      setDescription('')
      setIsbn('')
      setPages('')
      setEdition('')
      setAuthor('')
      setPublisher('')
      history.push('/seller/books')
    } else {
    }

    if (scanSuccess) {
      setLoad(false)
      setLoadingP(true)
      setScanned(true)
      if (nBook) {
        setNewBook(true)
        setName('')
        setImage('')
        setDescription('')
        setIsbn(isbn)
        setPages('')
        setEdition('')
        setAuthor('')
        setPublisher('')
      }
    }
    if (onLoad) {
      setScanned(false)
      setName('')
      setImage('')
      setDescription('')
      setIsbn(isbn)
      setPages('')
      setEdition('')
      setAuthor('')
      setPublisher('')
    }
    if (!scanLoading && scanSuccess && scanedProduct) {
      if (scanedProduct.name) {
        setName(scanedProduct.name)
        setImage(scanedProduct.image)
        setDescription(scanedProduct.description)
        setIsbn(isbn)
        setPages(scanedProduct.pages)
        setEdition(scanedProduct.edition)
        setAuthor(scanedProduct.author)
        setPublisher(scanedProduct.publisher)
      } else {
        setNewBook(true)
      }
    }
  }, [
    dispatch,
    history,
    productId,
    successCreate,
    isEbook,
    scanSuccess,
    scanedProduct,
    scanned,
    loadingP,
    nBook,
    successBookToStore,
    storeSuccess,
    searc,
    resShow,
  ])

  const submitBulkUpload = async (e) => {
    const file = e.target.files[0]
    const user = userLoggedIn._id
    const formData = new FormData()
    formData.append('file', file)
    formData.append('user', user)
    try {
      const response = await axios.post('/api/upload/bulkupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userLoggedIn.token}`,
        },
      })

      setResultz(response.data)
      setResShow(true)
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }
  // const submitBulkUpload = async (e) => {}
  const uploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }
  // const setBookTypeHandler = (value) => {
  //   setBookType(value)
  //   if (value === 'fiction') {
  //     setGenres(fictions)
  //   }
  //   if (value === 'non fiction') {
  //     setGenres(nonFictions)
  //   }
  // }

  const Handler = (e) => {
    e.preventDefault()
    if (!addNewBook2) {
      submitHandler(e)
    } else {
      submitHandler2(e)
    }
  }
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createInventory({
        product: bookId,
        type: type,
        salePrice: salePrice,
        inventory: countInStock,
        image: image,
      })
    )
  }
  const closeModal = (e) => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    dispatch(
      addStoreBook({
        name: name,
        description: description,
        image: image,
        isbn: isbn,
        pages: pages,
        edition: edition,
        author: author,
        publisher: publisher,
        lang: lang,
        binding: binding,
        type: type,
        price: price,
        salePrice: salePrice,
        inventory: countInStock,
      })
    )
  }
  const selectBook = (book) => {
    setAddNewBook(true)
    setBookId(book._id)
    setName(book.name)
    setImage(book.image)
    setDescription(book.description)
    setIsbn(book.isbn)
    setPages(book.pages)
    setEdition(book.edition)
    setAuthor(book.author)
    setPublisher(book.publisher)
    setLang(book.lang)
    setBinding(book.binding)
    setResults([])
  }
  // function handleTakePhoto(dataUri) {
  //   // Do stuff with the photo...
  //   console.log('takePhoto')
  // }
  return (
    <Container>
      <Container>
        <Row>
          <div role='presentation'>
            {/* <Typography variant='h6' gutterBottom mt={1}>
                List New Book
              </Typography> */}
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' to={'/profile'}>
                Dashboard
              </Link>
              <Typography color='text.primary'>Add Book</Typography>
            </Breadcrumbs>
          </div>
        </Row>
      </Container>
      <Row>
        <Container>
          <FormContainer>
            {/* <Camera
              onTakePhoto={(dataUri) => {
                handleTakePhoto(dataUri)
              }}
            /> */}
            {/* <form onSubmit={scanHandler}>
            <Row>
              <Col xs={8} sm={9}>
                <TextField
                  id='outlined-basic'
                  label='Enter ISBN or Title'
                  variant='outlined'
                  required
                  type='Number'
                  autoComplete='off'
                  value={isbn}
                  onChange={(e) => setIsbn(e.target.value)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </Col>
              <Col xs={4} sm={3}>
                <Button
                  type='submit'
                  variant='contained'
                  style={{ marginTop: '6px', float: 'right' }}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </form> */}
            <Row>
              <Col>
                <h2>Add your Books</h2>
                <br />
                <Button className='text-right' onClick={(e) => setShow(true)}>
                  Bulk Upload
                </Button>{' '}
                &nbsp;&nbsp;
                <Link to={'/seller/books'}>
                  <Button className='text-right'>Manage Your Books</Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <div>
                  <TextField
                    id='outlined-basic'
                    label='Enter ISBN or Title'
                    variant='outlined'
                    type='text'
                    placeholder='Search for books'
                    value={searc}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{
                      width: '100%',
                      marginBottom: '10px',
                      marginTop: '10px',
                    }}
                  />
                  {isLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <table className='C'>
                      <tbody>
                        {results.map((result) => (
                          <Row
                            onClick={(e) => selectBook(result)}
                            key={result._id}
                          >
                            <tr className='search_result' width='100%'>
                              <td style={{ marginLeft: '5px' }}>
                                <LazyLoadImage
                                  src={result.image}
                                  alt={result.name}
                                  width='50px'
                                />
                              </td>
                              <td
                                style={{
                                  verticalAlign: 'top',
                                  paddingTop: '3px',
                                }}
                              >
                                <strong>{result.name} </strong>
                                <br />
                                <small>by {result.author}</small>
                              </td>
                            </tr>
                          </Row>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {addNewBook2 && (
                    <span>
                      Add new book
                      <br />
                    </span>
                  )}
                </div>
              </Col>
              <Col sm={4} style={{ float: 'right' }}>
                <br />
              </Col>
            </Row>
            {loadingCreate && <Loader></Loader>}
            {errorCreate && <Message>{errorCreate}</Message>}
            <>
              {/* {scanLoading && <Loader></Loader>} */}
              {addNewBook && (
                <>
                  {/* {scanSuccess && scanedProduct && !onLoad && (
                  <>
                    <Row>
                      <Col xs={4}>
                        <img src={scanedProduct.image} width='100px' />
                      </Col>
                    </Row>
                  </>
                )} */}

                  <Form onSubmit={Handler}>
                    <Row>
                      <Col xs={12}>
                        <h2 style={{ paddingBottom: '8px' }}>
                          {name && name} {author && <span>by {author} </span>}
                        </h2>

                        {nBook ||
                          (addNewBook2 && (
                            <>
                              <TextField
                                id='outlined-basic'
                                label='Title'
                                variant='outlined'
                                required
                                type='Text'
                                autoComplete='off'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{ width: '100%', marginBottom: '10px' }}
                              />
                              <TextField
                                id='outlined-multiline-static'
                                label='Desription'
                                variant='outlined'
                                multiline
                                rows={3}
                                required
                                type='Text'
                                autoComplete='off'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                style={{ width: '100%', marginBottom: '10px' }}
                              />
                              <TextField
                                id='outlined-basic'
                                label='ISBN'
                                variant='outlined'
                                required
                                type='Number'
                                autoComplete='off'
                                value={isbn}
                                onChange={(e) => setIsbn(e.target.value)}
                                style={{ width: '100%', marginBottom: '10px' }}
                              />
                              <TextField
                                hidden
                                required
                                type='hidden'
                                value={image}
                                onChange={(e) => setImage(e.target.value)}
                              />
                              <Typography variant='caption' gutterBottom>
                                Upload Book Image
                              </Typography>
                              <TextField
                                id='outlined-basic'
                                variant='outlined'
                                required
                                type='file'
                                onChange={uploadHandler}
                                style={{ width: '100%', marginBottom: '10px' }}
                              />
                              {uploading && (
                                <Loader style={{ maxHeight: '200px' }}></Loader>
                              )}
                            </>
                          ))}
                        {!addNewBook2 && (
                          <div className='text-center'>
                            <img
                              src={image}
                              width='120px'
                              style={{ paddingBottom: '9px' }}
                            />
                          </div>
                        )}
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>
                            Book Condition
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={type}
                            required
                            label='Book Condition'
                            onChange={(e) => setType(e.target.value)}
                            style={{ width: '100%', marginBottom: '10px' }}
                          >
                            <MenuItem value={'Excellent'}>Excellent</MenuItem>
                            <MenuItem value={'Good'}>Good</MenuItem>
                            <MenuItem value={'Readable'}>Readable</MenuItem>
                            <MenuItem value={'New'}>New</MenuItem>
                          </Select>
                        </FormControl>

                        <Form.Group controlId='isbn'>
                          <Form.Control
                            type='hidden'
                            placeholder='Enter isbn'
                            value={isbn}
                            onChange={(e) => setIsbn(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        {nBook ||
                          (addNewBook2 ? (
                            <TextField
                              id='outlined-basic'
                              label='Price'
                              variant='outlined'
                              required
                              type='Number'
                              autoComplete='off'
                              value={salePrice}
                              onChange={(e) => setSaleprice(e.target.value)}
                              style={{
                                width: '48%',
                                marginBottom: '10px',
                                marginRight: '10px',
                              }}
                            />
                          ) : (
                            <TextField
                              id='outlined-basic'
                              label='Price'
                              variant='outlined'
                              required
                              type='Number'
                              fullWidth
                              autoComplete='off'
                              value={salePrice}
                              onChange={(e) => setSaleprice(e.target.value)}
                              style={{
                                marginBottom: '10px',
                                marginRight: '10px',
                              }}
                            />
                          ))}

                        {nBook ||
                          (addNewBook2 && (
                            <>
                              <TextField
                                id='outlined-basic'
                                label='Pages'
                                variant='outlined'
                                required
                                autoComplete='off'
                                type='Number'
                                value={pages}
                                onChange={(e) => setPages(e.target.value)}
                                style={{
                                  width: '48%',
                                  marginBottom: '10px',
                                  marginLeft: '10px',
                                }}
                              />

                              <TextField
                                id='outlined-basic'
                                label='Edition(Optional)'
                                variant='outlined'
                                type='Text'
                                autoComplete='off'
                                value={edition}
                                onChange={(e) => setEdition(e.target.value)}
                                style={{
                                  width: '100%',
                                  marginBottom: '10px',
                                }}
                              />

                              <TextField
                                id='outlined-basic'
                                label='Author'
                                variant='outlined'
                                autoComplete='off'
                                required
                                type='Text'
                                value={author}
                                onChange={(e) => setAuthor(e.target.value)}
                                style={{
                                  width: '100%',
                                  marginBottom: '10px',
                                }}
                              />

                              <TextField
                                id='outlined-basic'
                                label='Publisher'
                                variant='outlined'
                                autoComplete='off'
                                required
                                type='Text'
                                value={publisher}
                                onChange={(e) => setPublisher(e.target.value)}
                                style={{
                                  width: '100%',
                                  marginBottom: '10px',
                                }}
                              />

                              <FormControl fullWidth>
                                <InputLabel id='demo-simple-select-label'>
                                  Language
                                </InputLabel>
                                <Select
                                  labelId='demo-simple-select-label'
                                  id='demo-simple-select'
                                  value={lang}
                                  required
                                  label='Language'
                                  onChange={(e) => setLang(e.target.value)}
                                  style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                  }}
                                >
                                  <MenuItem value=''>Select Language</MenuItem>
                                  <MenuItem value={'eng'}>English</MenuItem>
                                  <MenuItem value={'hindi'}>Hindi</MenuItem>
                                  <MenuItem value={'kannada'}>Kannada</MenuItem>
                                  <MenuItem value={'tamil'}>Tamil</MenuItem>
                                  <MenuItem value={'telugu'}>Telugu</MenuItem>
                                  <MenuItem value={'malayalam'}>
                                    Malayalam
                                  </MenuItem>
                                  <MenuItem value={'marathi'}>Marathi</MenuItem>
                                  <MenuItem value={'gujarati'}>
                                    Gujarati
                                  </MenuItem>
                                  <MenuItem value={'punjabi'}>Punjabi</MenuItem>
                                </Select>
                              </FormControl>

                              <FormControl fullWidth>
                                <InputLabel id='demo-simple-select-label'>
                                  Binding
                                </InputLabel>
                                <Select
                                  labelId='demo-simple-select-label'
                                  id='demo-simple-select'
                                  value={binding}
                                  required
                                  label='Binding'
                                  onChange={(e) => setBinding(e.target.value)}
                                  style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                  }}
                                >
                                  <MenuItem value=''>Select Binding</MenuItem>
                                  <MenuItem value={'hardcover'}>
                                    Hard Cover
                                  </MenuItem>
                                  <MenuItem value={'paperback'}>
                                    Paper Back
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </>
                          ))}
                        <Row className='text-center'>
                          <Col>
                            <Button
                              type='submit'
                              variant='primary'
                              className='add-books-button'
                            >
                              Sell Book
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </>
          </FormContainer>
        </Container>
      </Row>
      <Modal show={show} backdrop='static'>
        <Modal.Body>
          <Container style={{ padding: '35px' }}>
            <h2>Bulk Upload</h2>

            <br />
            <div>
              <a
                href='https://99bookscart-assets.s3.ap-south-1.amazonaws.com/BulkUpload.xlsx'
                target='_blank'
                style={{ textDecoration: 'underline' }}
              >
                Download Bulk upload Template
              </a>
            </div>
            <br />
            <TextField
              hidden
              required
              type='hidden'
              name='user'
              value={userLoggedIn}
            />
            <Form.File
              id='image-file'
              custom
              onChange={submitBulkUpload}
            ></Form.File>
            {resShow && (
              <>
                <br />
                <div>
                  <FaCheck />
                  {resultz.success}/{resultz.total} Updated
                </div>
                <a href={resultz.location} target='_blank'>
                  Download Results
                </a>
              </>
            )}
            <Button onClick={closeModal} style={{ float: 'right' }}>
              Close
            </Button>
            <br />
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default AddNewBookScreen
