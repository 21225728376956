import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { createNewEpisode } from '../../actions/episodeActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Button } from 'react-bootstrap'
import Loader from '../../components/Loader'
const CretaeEpisodeScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const storyId = match.params.sid || null
  const [content, SetContent] = useState('')

  const createEpisode = useSelector((state) => state.createEpisode)
  const { data, loading, error, suceess } = createEpisode

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!suceess) {
      dispatch(
        createNewEpisode({
          content,
          status: false,
          id: null,
          story: storyId,
          update: false,
        })
      )
    }

    if (suceess && data.episode && data.story) {
      history.push(`/write/episode/${data.episode._id}/${data.story._id}`)
    }
  }, [suceess, data])
  return <Container>{loading && <Loader></Loader>}</Container>
}

export default CretaeEpisodeScreen
