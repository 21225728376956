import React from 'react'
import Helmet from 'react-helmet'
const HomeMeta = () => {
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://www.99bookscart.com/',
    logo: 'https://www.99bookscart.com/images/logo.png',
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate:
          'https://www.99bookscart.com/search/{search_term_string}/true',
      },
      'query-input': 'required name=search_term_string',
    },
  }
  return (
    <Helmet>
      <title>Read Smart & Read Fast 99bookscart plots</title>
      <meta charset='UTF-8' />

      <meta
        property='og:title'
        content='Read Smart & Read Fast 99bookscart plots'
      />

      <head prefix='book: https://ogp.me/ns/book#'></head>
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://www.99bookscart.com' />
      <meta
        property='og:image'
        content='https://99bookscart.com/images/logo.png'
      />
      <meta
        property='og:description'
        content='99bookscart.com Online reading platform to escape the reality! Read Smart and Read Fast'
      />
      <meta property='og:site_name' content='litplots' />
      <meta
        name='description'
        content='99bookscart.com Online reading platform to escape the reality! Read Smart and Read Fast'
      ></meta>
      <meta
        name='keyword'
        content='Best non fiction books, key ideas from book'
      ></meta>
      <meta
        name='title'
        content='Read Smart & Read Fast 99bookscart plots'
      ></meta>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0'
      ></meta>
      <link rel='canonical' href='https://www.99bookscart.com'></link>
      <meta name='robots' content='index, follow' />
      <script className='structured-data-list' type='application/ld+json'>
        {JSON.stringify(articleStructuredData)}
      </script>
    </Helmet>
  )
}

function convertToSlug(string) {
  return string
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default HomeMeta
