import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Table, Row, Col, Image, Modal } from 'react-bootstrap'
import { Button } from '@mui/material'
import ReactHtmlParser from 'react-html-parser'
import { register } from '../../../actions/userActions'
import {
  getCategory,
  convertToSlug,
  convertToSlug2,
} from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import Story from '../../../components/Story'
import StoryStats from '../../../components/StoryStats'
import { FaBookReader, FaLock, FaClock } from 'react-icons/fa'
import SocialShare from '../../../components/SocialShare'
import SubNav from '../../../components/SubNav'
import SocialLogin from '../../../components/SocialLogin'
import BookMeta from '../../../components/plots/meta/BookMeta'
import Rating from '../../../components/Rating'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import AdInArticle from '../../../components/Ads/AdInArticle'

const PlotViewScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const url = `https://www.99bookscart.com/stories/${match.params.id}`

  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [redirect, setRedirect] = useState('/app/home')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isReadMore, setIsReadMore] = useState(true)
  const getStoryRed = useSelector((state) => state.getStory)
  const {
    story,
    StoryStats: stats,
    similarStories,
    loading,
    error,
    success,
  } = getStoryRed

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({ name, email, password, isSocial: false, shorts: true })
      )
    }
  }
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  const onEpisodeRead = (index, id) => {
    {
      index > 1 && false
        ? history.push(
            `/register?redirect=/app/en/book/${convertToSlug(story.title)}/${
              story._id
            }`
          )
        : history.push(`/plots/en/read/${id}/${story._id}`)
    }
  }

  const subscribeButton = () => {
    history.push(`/plots/en/read/${story.episodes[0]._id}/${story._id}`)
    // setShow(true)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getStory(match.params.id))
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        if (userInfo.isSubscribed) {
          history.push(redirect)
        } else {
          history.push(
            `/app/en/book/${convertToSlug(story.title)}/${story._id}`
          )
        }
      }
    }
    if (success) {
      setRedirect(`/app/en/book/${convertToSlug(story.title)}/${story._id}`)
    }
  }, [dispatch, match, userInfo, registerSuccess, emailError])
  return (
    <Container>
      {story && <BookMeta story={story}></BookMeta>}
      <SubNav history={history} redirect={redirect}></SubNav>
      {loading || !success ? (
        <Loader></Loader>
      ) : (
        <>
          <Container>
            <Row className='story-head-section'>
              <Col xs={4}>
                <div>
                  <LazyLoadImage
                    src={story.coverImage}
                    width={'100%'}
                    alt={story.title + ' by ' + story.user.name}
                    className='storypagecover'
                  />
                </div>
              </Col>
              <Col xs={8}>
                <div style={{ padding: '10px' }}>
                  <h1>{story.title}</h1>
                  <h2 className='writer-name'>
                    <Link
                      to={`/plots/en/author/${convertToSlug2(
                        story.user.name
                      )}/${story.user._id}`}
                    >
                      <span className='text-muted'>
                        {story.user && story.user.name}
                      </span>
                    </Link>
                  </h2>

                  {/* <div className='desktop-view' style={{ width: '50%' }}>
                    <StoryStats story={story}></StoryStats>
                  </div> */}
                  <div>
                    {/* <Rating value={story.rating}></Rating>{' '}
                    <small>
                      {story.reviewCount && story.reviewCount.toLocaleString()}{' '}
                      Reviews
                    </small>
                    <br /> */}
                    <Button
                      className='btn-primary'
                      variant='contained'
                      onClick={subscribeButton}
                    >
                      <FaBookReader className='orange' />
                      &nbsp; Start reading
                    </Button>
                  </div>
                  <div style={{ float: 'left', marginTop: '10px' }}>
                    <div className='storiestatslabel'>
                      <FaClock />{' '}
                      <span className='storiestatsvalue'>
                        {story.totalReadingTime}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Row style={{ padding: '8px' }}>
            <Col>
              <>
                {/* <div className='mobile-view'>
                  <StoryStats story={story}></StoryStats>
                </div> */}
                {/* <AdInArticle /> */}
                <h2 className='storietaglabel mb-1'>What's in the book? </h2>
                <div className='storydescription'>
                  {story.description && ReactHtmlParser(story.description)}
                  {/* {isReadMore
                    ? ReactHtmlParser(
                        story.description.substring(0, 1200) + '...'
                      )
                    : ReactHtmlParser(story.description)}
                  <div onClick={toggleReadMore} className='read-or-hide'>
                    {isReadMore ? '...read more' : ' show less'}
                  </div> */}
                </div>
                <AdInArticle />
                <h2>Chapters</h2>
                <div className='storychaptersection'>
                  {story.episodes.map((episode, index) => (
                    // <Link to={`/episode/${episode._id}`}>
                    <Row key={episode._id} className='chaptersection'>
                      <Col xs={9} className='chapter-title' key={episode._id}>
                        <h2
                          onClick={(e) => onEpisodeRead(index, episode._id)}
                          style={{
                            cursor: 'pointer',
                            fontWeight: 'normal',
                            color: '#000',
                          }}
                        >
                          {'#' + episode.title}
                        </h2>
                      </Col>
                      <Col
                        xs={3}
                        style={{ float: 'right' }}
                        key={episode._id + '2'}
                      >
                        {episode.duration}{' '}
                      </Col>
                    </Row>
                    // </Link>
                  ))}
                </div>
                <AdInArticle />
                <div>
                  {typeof story.category === 'string' ? (
                    <>{getCategory(story.category)}</>
                  ) : (
                    <>
                      <h2>Related Topics</h2>
                      {story.genres.map((cat) => (
                        <Link
                          to={`/plots/en/category/${cat.slug}`}
                          key={cat._id}
                        >
                          <span key={cat._id} className='topic'>
                            <span key={cat._id}>{cat.name}</span>
                          </span>
                        </Link>
                      ))}
                    </>
                  )}
                </div>
                {/* {story.tags.length > 0 && (
                  <div className='story-tags'>
                    <span className='storietaglabel'>Tags: </span>
                    {story.tags.map((tag) => (
                      <Link
                        to={`/stories`}
                        key={`${tag._id}`}
                        className='topic'
                      >
                        <span>{tag.name}</span>
                      </Link>
                    ))}
                  </div>
                )} */}

                <SocialShare story={story}></SocialShare>
              </>
            </Col>
          </Row>
          <Container>
            <h2>Books</h2>
            <Row>
              {similarStories.map((story) => (
                <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                  <Route
                    render={({ history }) => (
                      <Story history={history} story={story} key={story._id} />
                    )}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
      <Modal show={show} onHide={handleClose} backdrop='static'>
        <Modal.Body>
          <Form onSubmit={submitHandler2}>
            <div>
              <Button className='close-button' onClick={handleClose}>
                X
              </Button>
            </div>
            <div style={{ padding: '20px' }}>
              <Form.Group>
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                  <LazyLoadImage
                    src={'../../images/logo.png'}
                    width={'100%'}
                    alt={'99bookscart logo'}
                    className='siteLogo'
                  />
                  <br />
                  <strong>Read Smart & Read Fast</strong>
                  <div>Read Key Ideas from 1000+ Non Fiction Books!</div>
                  <br />
                  <div className='social-logins'>
                    <SocialLogin />
                  </div>
                </div>
                <Form.Text className='red-text'>
                  {errorMessage && <span>{errorMessage}</span>}
                </Form.Text>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  required
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>

                <Form.Text className='red-text'>
                  {emailError && <span>{emailError}</span>}
                </Form.Text>

                <Form.Control
                  type='email'
                  placeholder='Email'
                  required
                  value={email || ''}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
                <Form.Text className='red-text'>
                  {passwordError && <span>{passwordError}</span>}
                </Form.Text>
                <Form.Control
                  type='password'
                  placeholder='Passsword'
                  required
                  value={password || ''}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Row>
                <Col>
                  <Button
                    type='submit'
                    variant='primary'
                    className='float-right'
                  >
                    Sign Up
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className='text-center'>
                  Existing Customer? <Link to={'/login'}>Login</Link>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default PlotViewScreen
