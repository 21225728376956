export const GENRE_LIST_REQUST = 'GENRE_LIST_REQUST'
export const GENRE_LIST_SUCCESS = 'GENRE_LIST_SUCCESS'
export const GENRE_LIST_FAIL = 'GENRE_LIST_FAIL'


export const PLOT_GENRE_LIST_REQUST = 'PLOT_GENRE_LIST_REQUST'
export const PLOT_GENRE_LIST_SUCCESS = 'PLOT_GENRE_LIST_SUCCESS'
export const PLOT_GENRE_LIST_FAIL = 'PLOT_GENRE_LIST_FAIL'


