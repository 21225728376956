import React, { useEffect } from 'react'

function VerticleSidebar() {
  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return (
    <div>
      <script
        async
        src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5651899416865358'
        crossOrigin='anonymous'
      ></script>
      <ins
        className='adsbygoogle'
        style={{ display: 'block' }}
        data-ad-client='ca-pub-5651899416865358'
        data-ad-slot='2027257292'
        data-ad-format='auto'
        data-full-width-responsive='true'
      ></ins>
    </div>
  )
}

export default VerticleSidebar
