import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Form,
  Button,
  Row,
  Col,
  Table,
  Modal,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listFlows, createFlow, deleteFLow } from '../actions/flowActions'
import AdminNav from '../components/AdminNav'

const FlowListScreen = ({ location, history }) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [flow, setFlow] = useState()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const flowList = useSelector((state) => state.flowList)
  const { success, loading, error, flows } = flowList

  const flowCreate = useSelector((state) => state.flowCreate)
  const { success: flowSuccess } = flowCreate

  const flowDelete = useSelector((state) => state.flowDelete)
  const { loading: flowDeleteSuccess } = flowDelete

  useEffect(() => {
    dispatch(listFlows())
  }, [dispatch, flowSuccess, flowDeleteSuccess])

  const createFlowHandler = () => {
    setShow(true)
  }
  const submitFlowHandler = () => {
    dispatch(createFlow(flow))
    setShow(false)
  }
  const deleteHandler = (id) => {
    dispatch(deleteFLow(id))
    dispatch(listFlows())
  }
  return (
    <Container>
      <Row>
        <Col xs={12}>
          {loading ? (
            <Loader></Loader>
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <>
              <AdminNav></AdminNav>
              <Row className='align-items-center'>
                <Col>
                  <h1>Flow List</h1>
                </Col>
                <Col className='text-right'>
                  <Button className='my-3' onClick={createFlowHandler}>
                    <i className='fas fas-plus'>Create Flow</i>
                  </Button>
                </Col>
              </Row>
              <Table striped bordered hover responsive className='table-sm'>
                <tbody>
                  {flows.map((flow) => (
                    <tr key={flow._id}>
                      <td>{flow.name}</td>
                      <td>
                        <LinkContainer to={`/admin/flow/${flow._id}`}>
                          <Button className='btn-sm' variant='light'>
                            Details
                          </Button>
                        </LinkContainer>
                      </td>
                      <td>
                        <Button
                          variant='danger'
                          className='btn-sm'
                          onClick={() => deleteHandler(flow._id)}
                        >
                          <i className='fas fa-trash-alt'></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Flow</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitFlowHandler}>
            <Form.Group controlId='name'>
              <Form.Label>FLow Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Name'
                value={flow}
                onChange={(e) => setFlow(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br />
            <Button type='submit' varaint='primary'>
              Create Flow
            </Button>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default FlowListScreen
