import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { getFollowerList } from '../../actions/followerActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import Loader from '../../components/Loader'
import Story from '../../components/Story'

const FollowerScreen = ({ history }) => {
  const dispatch = useDispatch()
  const getFollower = useSelector((state) => state.getFollower)
  const { followers, loading, error, suceess } = getFollower

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!suceess) {
      dispatch(getFollowerList())
    }
  }, [suceess])
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          <h1>Stories</h1>
          <Row>
            {followers.length > 0 &&
              followers.map((follower) => (
                <Col key={follower._id} sm={4} md={4} lg={3} xs={12}>
                  <Link to={`/user/${follower.follow._id}`}>
                    <Row>
                      <Col>
                        <Image
                          src={follower.follow.profileImage}
                          className='follow-profile-image'
                        ></Image>
                      </Col>
                      <Col>{follower.follow.name}</Col>
                    </Row>
                  </Link>
                </Col>
              ))}
          </Row>
        </>
      )}
    </Container>
  )
}

export default FollowerScreen
