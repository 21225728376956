import React, { useEffect } from 'react'

function AdInArticle() {
  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return (
    <div>
      <script
        async
        src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5651899416865358'
        crossOrigin='anonymous'
      ></script>
      <ins
        className='adsbygoogle'
        style={{ display: 'block',textAlign:'center' }}
        data-ad-layout='in-article'
        data-ad-format='fluid'
        data-ad-client='ca-pub-5651899416865358'
        data-ad-slot='4148058210'
      ></ins> 
    </div>
  ) 
}

export default AdInArticle
