import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Container } from 'react-bootstrap'
import Loader from './Loader'
import Message from './Message'
import {
  listTopProducts,
  listBestSellingProducts,
} from '../actions/productActions'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Product from './Product'
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 6,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
}
const HProductCarosel = (type) => {
  const dispatch = useDispatch()
  const productTopRated = useSelector((state) => state.productTopRated)
  const {
    loading: loadingTR,
    error: errorTR,
    products: productsTR,
  } = productTopRated

  const productBestSelling = useSelector((state) => state.productBestSelling)
  const { loading: loadingBS, products: productsBS } = productBestSelling

  useEffect(() => {
    dispatch(listTopProducts())
    dispatch(listBestSellingProducts())
  }, [dispatch])

  return loadingTR && loadingBS ? (
    <Loader></Loader>
  ) : errorTR ? (
    <Message variant='danger'></Message>
  ) : (
    <Container>
      <Row>
        <Col>
          <Row className='homepage-mobile-bar'>
            <Col xs={9}>
              <h4 className='mt-2 homepage-mobile-bar-col'>Book Sets</h4>
            </Col>
            <Col xs={3}>
              <Link
                to='/category/book-set'
                className='float-right mt-2 homepage-mobile-bar-col'
              >
                See All
              </Link>
            </Col>
          </Row>

          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition='all .5'
            transitionDuration={500}
            containerClass='carousel-container'
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass='custom-dot-list-style'
            itemClass='carousel-item-padding-40-px'
          >
            {productsBS.map((product) => (
              <div key={product._id} className='hproducts'>
                <Route
                  render={({ history }) => (
                    <Product history={history} product={product} />
                  )}
                />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='homepage-mobile-bar'>
            <Col xs={9}>
              <h4 className='mt-2 homepage-mobile-bar-col'>Popular</h4>
            </Col>
            <Col xs={3}>
              <Link
                to='/category/top-rated'
                className='float-right mt-2 homepage-mobile-bar-col'
              >
                See All
              </Link>
            </Col>
          </Row>

          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition='all .5'
            transitionDuration={500}
            containerClass='carousel-container'
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass='custom-dot-list-style'
            itemClass='carousel-item-padding-40-px'
          >
            {productsTR.map((product) => (
              <div key={product._id} className='hproducts'>
                <Route
                  render={({ history }) => (
                    <Product history={history} product={product} />
                  )}
                />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  )
}

export default HProductCarosel
