import { AI_FAIL, AI_REQUEST, AI_SUCCEES } from "../constants/aiConstants"
import {  } from "../constants/userConstants"


export const callAiReducer = (state = { products:[] }, action) => {
    switch (action.type) {
        case AI_REQUEST:
            return {
                loading: true
            }
        case AI_SUCCEES:
            return {
                loading: false,
                success:true,
                response: action.payload,
            }
        case AI_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}



