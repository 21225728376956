export const PRODUCT_LIST_REQUST = 'PRODUCT_LIST_REQUST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUST = 'PRODUCT_DETAILS_REQUST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const PRODUCT_DELETE_REQUST = 'PRODUCT_DELETE_REQUST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export const PRODUCT_CREATE_REQUST = 'PRODUCT_CREATE_REQUST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUST = 'PRODUCT_UPDATE_REQUST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_CREATE_REVIEW_REQUST = 'PRODUCT_CREATE_REVIEW_REQUST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_PRODUCT_TOP_REQUST = 'PRODUCT_PRODUCT_TOP_REQUST'
export const PRODUCT_PRODUCT_TOP_SUCCESS = 'PRODUCT_PRODUCT_TOP_SUCCESS'
export const PRODUCT_PRODUCT_TOP_FAIL = 'PRODUCT_PRODUCT_TOP_FAIL'

export const PRODUCT_BESTSELLING_REQUST = 'PRODUCT_BESTSELLING_REQUST'
export const PRODUCT_BESTSELLING_SUCCESS = 'PRODUCT_BESTSELLING_SUCCESS'
export const PRODUCT_BESTSELLING_FAIL = 'PRODUCT_BESTSELLING_FAIL'

export const PRODUCT_REDIRECT_REQUST = 'PRODUCT_REDIRECT_REQUST'
export const PRODUCT_REDIRECT_SUCCESS = 'PRODUCT_REDIRECT_SUCCESS'
export const PRODUCT_REDIRECT_FAIL = 'PRODUCT_REDIRECT_FAIL'

export const PRODUCT_PAYMENTTYPE_FREE = 0
export const PRODUCT_PAYMENTTYPE_SUBSCRIPTION = 1
export const PRODUCT_PAYMENTTYPE_PURCHASE = 2

export const PRODUCT_SCAN_REQUST = 'PRODUCT_SCAN_REQUST'
export const PRODUCT_SCAN_SUCCESS = 'PRODUCT_SCAN_SUCCESS'
export const PRODUCT_SCAN_FAIL = 'PRODUCT_SCAN_FAIL'

export const PRODUCT_UPSERT_REQUST = 'PRODUCT_UPSERT_REQUST'
export const PRODUCT_UPSERT_SUCCESS = 'PRODUCT_UPSERT_SUCCESS'
export const PRODUCT_UPSERT_FAIL = 'PRODUCT_UPSERT_FAIL'

export const PRODUCT_AT_REQUST = 'PRODUCT_AT_REQUST'
export const PRODUCT_AT_SUCCESS = 'PRODUCT_AT_SUCCESS'
export const PRODUCT_AT_FAIL = 'PRODUCT_AT_FAIL'

export const PRODUCT_ALL_LIST_REQUST = 'PRODUCT_ALL_LIST_REQUST'
export const PRODUCT_ALL_LIST_SUCCESS = 'PRODUCT_ALL_LIST_SUCCESS'
export const PRODUCT_ALL_LIST_FAIL = 'PRODUCT_ALL_LIST_FAIL'

export const SELLER_PRODUCT_SCAN_REQUST = 'SELLER_PRODUCT_SCAN_REQUST'
export const SELLER_PRODUCT_SCAN_SUCCESS = 'SELLER_PRODUCT_SCAN_SUCCESS'
export const SELLER_PRODUCT_SCAN_FAIL = 'SELLER_PRODUCT_SCAN_FAIL'

export const PRODUCT_BY_TYPE_REQUST = 'PRODUCT_BY_TYPE_REQUST'
export const PRODUCT_BY_TYPE_SUCCESS = 'PRODUCT_BY_TYPE_SUCCESS'
export const PRODUCT_BY_TYPE_FAIL = 'PRODUCT_BY_TYPE_FAIL'

export const PRODUCT_BY_GENRE_REQUST = 'PRODUCT_BY_GENRE_REQUST'
export const PRODUCT_BY_GENRE_SUCCESS = 'PRODUCT_BY_GENRE_SUCCESS'
export const PRODUCT_BY_GENRE_FAIL = 'PRODUCT_BY_GENRE_FAIL'
