import React, { useState, useEffect } from 'react'
import { Table, Form, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  getUserDetails,
  updateUserProfile,
  logout,
} from '../actions/userActions'
import { listMyOrders } from '../actions/orderActions'
import { myOrderDetailsReducer } from '../reducers/orderReducers'
import { LinkContainer } from 'react-router-bootstrap'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
const UserSettingScreen = ({ location, history, getState }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!userInfo.name) {
        dispatch(getUserDetails('profile'))
      } else {
        setName(userInfo.name)
        setEmail(userInfo.email)
        setPhone(userInfo.phone)
      }
    }
  }, [dispatch, history, userInfo])
  const logoutHandler = () => {
    dispatch(logout())
    history.push('/')
  }
  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Password Do not Match')
    } else {
      dispatch(updateUserProfile({ id: userUpdateProfile._id, name, email }))
    }
  }
  return (
    <>
      <Container>
        <Row>
          <div role='presentation'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' to={'/profile'}>
                Profile
              </Link>
              <Typography color='text.primary'>Settings</Typography>
            </Breadcrumbs>
          </div>
        </Row>
        <Row>
          <>
            <Col md={6}>
              <Container>
                <Button
                  variant='contained'
                  style={{ float: 'right', marginBottom: '10px' }}
                  onClick={logoutHandler}
                >
                  Logout
                </Button>

                {message && <Message variant='danger'>{message}</Message>}
                {error && <Message variant='danger'>{error}</Message>}
                {success && (
                  <Message variant='success'>UPDATED SUCCESSFULLY</Message>
                )}
                {loading && <Loader></Loader>}
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      readOnly
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter Email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      readOnly
                    ></Form.Control>
                  </Form.Group>
                  {/* <Form.Group controlId='password'>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type='text' value={phone}></Form.Control>
                  </Form.Group>
                  <Button type='submit' varaint='primary'>
                    Update
                  </Button> */}
                </Form>
              </Container>
            </Col>
            <Col md={6}>
              <Container>
                <div>
                  <Typography variant='caption'>Manage Addresses</Typography>
                </div>
                <Link to='/profile/addresses'>
                  <Button variant='contained'>Address</Button>
                </Link>
              </Container>
            </Col>
          </>
        </Row>
      </Container>
    </>
  )
}

export default UserSettingScreen
