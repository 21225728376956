import React from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'


const CheckoutSteps = ({ step1, step2, step4 }) => {
  return (
    <>
      <Nav className='justify-content-center mb-4 nav-pills flex-sm-row cart-nav'>
        <Nav.Item>
          {step1 ? (
            <LinkContainer to='/checkout/basic-information'>
              <Nav.Link>
                <i className='fas fa-user-circle '></i>{' '}
              </Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>
              <i className='fas fa-user-circle'></i>{' '}
            </Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step2 ? (
            <LinkContainer to='/checkout/shipping'>
              <Nav.Link>
                <i className='fas fa-shipping-fast'></i>
              </Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>
              <i className='fas fa-shipping-fast'></i>
            </Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step4 ? (
            <LinkContainer to='/checkout/placeorder'>
              <Nav.Link>
                <i className='fas fa-book-reader'></i>
              </Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>
              <i className='fas fa-book-reader'></i>
            </Nav.Link>
          )}
        </Nav.Item>
      </Nav>
    </>
  )
}

export default CheckoutSteps
