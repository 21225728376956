import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import { register } from '../../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'
import SocialLogin from '../../../components/SocialLogin'

import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/plots/meta/HomeMeta'
import { getStore } from '../../../actions/storeActions'
import SellerProductView from '../components/SellerProductView'
import { Breadcrumbs, Typography } from '@mui/material'

const SellerPaymentScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const storeData = useSelector((state) => state.getStore)
  const { loading, error, success, result } = storeData

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userLoggedIn) {
      dispatch(getStore())
    }
  }, [dispatch, userLoggedIn])
  return (
    <>
      <Container>
        <Row>
          <div role='presentation'>
            <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
              <Link underline='hover' color='inherit' to={'/profile'}>
                Profile
              </Link>
              <Typography color='text.primary'>Payments</Typography>
            </Breadcrumbs>
          </div>
        </Row>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          success &&
          (result.store.storeName ? (
            <Container>
              <Row>
                <h2>No payments available</h2>
              </Row>
            </Container>
          ) : (
            <Link to={'/seller/create/new/store'} className='btn'>
              <Button> Create Store</Button>
            </Link>
          ))
        )}
      </Container>
    </>
  )
}
{
}

export default SellerPaymentScreen
