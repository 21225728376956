import { React, useState } from 'react'
import { Route, useLocation, Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'

import { FaUserAlt } from 'react-icons/fa'

import { FaSearch } from 'react-icons/fa'
import 'react-pro-sidebar/dist/css/styles.css'
import { useHistory } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const AdminNav = ({}) => {
  const history = useHistory()

  const dispatch = useDispatch()
  const [menuCollapse, setMenuCollapse] = useState(false)

  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)
  }
  const location = useLocation()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)

  const logoutHandler = () => {
    dispatch(logout())
    history.push('/')
  }
  var url = ''
  if (location.pathname.indexOf('us') > 0) {
    url = '/app/profile/us/billing'
  } else {
    url = '/app/profile/billing'
  }
  return (
    <header>
      {location.pathname.indexOf('app') > 0 ? (
        <>
          <Navbar expand='lg' collapseOnSelect className='customer-nav'>
            <LinkContainer to={url}>
              <Navbar.Brand href={url}>
                <LazyLoadImage
                  src={'../../images/logo.png'}
                  width={'100%'}
                  alt={'99bookscart logo'}
                  className='siteLogo-app'
                />
              </Navbar.Brand>
            </LinkContainer>
            <>
              <Navbar.Toggle aria-controls='basic-navbar-nav' />
              <Navbar.Collapse
                id='basic-navbar-nav'
                className='justify-content-end'
              >
                <Nav className='ml-auto'>
                  {/* <NavDropdown title='Categories' id='Categories'>
                    <LinkContainer to='/app/category/Money'>
                      <NavDropdown.Item>Money</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/app/category/Investment'>
                      <NavDropdown.Item>Investment</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown> */}
                  <NavDropdown title='Setting' id='setting'>
                    <LinkContainer to='/app/profile/subscriptions'>
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </>
          </Navbar>
        </>
      ) : location.pathname.indexOf('99bookscart-surprise-box') > 0 ||
        location.pathname.indexOf('book-ideas') > 0 ? (
        ''
      ) : (
        <>
          {location.pathname.indexOf('checkout') > 0 ||
          location.pathname.indexOf('BookscartBox1') > 0
            ? ''
            : location.pathname.indexOf('stories') < 0 && (
                <></>
                // <div className='header-top'>
                //   <Link to='/category/top-rated' className='header-link'>
                //     Get any 9 Books for ₹999/-
                //   </Link>
                // </div>
              )}
          {
            // location.pathname.indexOf('stories') > 0 ? (
            //   <></>
            // ) :
            userInfo && userInfo.isAdmin && userInfo.isBookFair ? (
              <>
                <Navbar expand='lg' collapseOnSelect className='customer-nav'>
                  <Container>
                    <LinkContainer to='/admin/dashboard'>
                      <Navbar.Brand href='/admin/dashboard'>
                        <img
                          src='../../images/logo.png'
                          className='siteLogo'
                          alt='99bookscart logo'
                        />
                      </Navbar.Brand>
                    </LinkContainer>
                    <>
                      <Navbar.Toggle aria-controls='basic-navbar-nav' />
                      <Navbar.Collapse
                        id='basic-navbar-nav'
                        className='justify-content-end'
                      >
                        <Nav className='ml-auto'>
                          <NavDropdown title='bookfairs' id='bookfairs'>
                            <LinkContainer to='/admin/bookfairList'>
                              <NavDropdown.Item>
                                List Book Fairs
                              </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to='/admin/bookfairNew'>
                              <NavDropdown.Item>New Book Fair</NavDropdown.Item>
                            </LinkContainer>
                          </NavDropdown>
                          <NavDropdown title='Setting' id='setting'>
                            <LinkContainer to='/profile'>
                              <NavDropdown.Item>Profile</NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Item onClick={logoutHandler}>
                              Logout
                            </NavDropdown.Item>
                          </NavDropdown>
                        </Nav>
                      </Navbar.Collapse>
                    </>
                  </Container>
                </Navbar>
              </>
            ) : userInfo && userInfo.isAdmin && userInfo.isScan ? (
              <>
                <>
                  <Navbar expand='lg' collapseOnSelect className='customer-nav'>
                    <Container>
                      <LinkContainer to='/'>
                        <Navbar.Brand href='/'>
                          <img
                            src='../../images/logo.png'
                            className='siteLogo'
                            alt='99bookscart logo'
                          />
                        </Navbar.Brand>
                      </LinkContainer>
                      <>
                        <Navbar.Toggle aria-controls='basic-navbar-nav' />
                        <Navbar.Collapse
                          id='basic-navbar-nav'
                          className='justify-content-end'
                        >
                          <Nav className='ml-auto'>
                            <NavDropdown title='users' id='users'>
                              <LinkContainer to='/admin/userlist'>
                                <NavDropdown.Item>List Users</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/subscriberList'>
                                <NavDropdown.Item>Subscribers</NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title='orders' id='orders'>
                              <LinkContainer to='/admin/orderlist'>
                                <NavDropdown.Item>List Orders</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/orderlist/2/type/2'>
                                <NavDropdown.Item>
                                  Seller Orders
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/abandoncartlist'>
                                <NavDropdown.Item>AbandonCart</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/orderlist/1/type/1'>
                                <NavDropdown.Item>
                                  Cancelled Order
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/product/scan'>
                                <NavDropdown.Item>Scan</NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>
                            <LinkContainer to='/admin/productlist'>
                              <Nav.Link>Products</Nav.Link>
                            </LinkContainer>
                            <NavDropdown title='submissions' id='submissions'>
                              <LinkContainer to='/admin/formsubmissions'>
                                <NavDropdown.Item>
                                  Contact Form
                                </NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title='Setting' id='setting'>
                              <LinkContainer to='/profile'>
                                <NavDropdown.Item>Profile</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/discountCode'>
                                <NavDropdown.Item>
                                  Discount Code
                                </NavDropdown.Item>
                              </LinkContainer>
                              <NavDropdown.Item onClick={logoutHandler}>
                                Logout
                              </NavDropdown.Item>
                            </NavDropdown>
                          </Nav>
                        </Navbar.Collapse>
                      </>
                    </Container>
                  </Navbar>
                </>
              </>
            ) : userInfo && userInfo.isAdmin ? (
              <>
                <>
                  <Navbar expand='lg' collapseOnSelect className='customer-nav'>
                    <Container>
                      <LinkContainer to='/admin/dashboard'>
                        <Navbar.Brand href='/admin/dashboard'>
                          <img
                            src='../../images/logo.png'
                            className='siteLogo'
                            alt='99bookscart logo'
                          />
                        </Navbar.Brand>
                      </LinkContainer>
                      <>
                        <Navbar.Toggle aria-controls='basic-navbar-nav' />
                        <Navbar.Collapse
                          id='basic-navbar-nav'
                          className='justify-content-end'
                        >
                          <Nav className='ml-auto'>
                            <LinkContainer to='/admin/dashboard'>
                              <Nav.Link>Dashboard</Nav.Link>
                            </LinkContainer>

                            <NavDropdown title='bookfairs' id='bookfairs'>
                              <LinkContainer to='/admin/bookfairList'>
                                <NavDropdown.Item>
                                  List Book Fairs
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/bookfairNew'>
                                <NavDropdown.Item>
                                  New Book Fair
                                </NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title='sellers' id='sellers'>
                              <LinkContainer to='/admin/orderlist/1/type/2'>
                                <NavDropdown.Item>
                                  Seller Orders
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/seller-books'>
                                <NavDropdown.Item>
                                  Pending Books
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/stores'>
                                <NavDropdown.Item>Stores</NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title='users' id='users'>
                              <LinkContainer to='/admin/userlist'>
                                <NavDropdown.Item>List Users</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/subscriberList'>
                                <NavDropdown.Item>Subscribers</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/intrests'>
                                <NavDropdown.Item>
                                  User Intrests
                                </NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title='orders' id='orders'>
                              <LinkContainer to='/admin/orderlist'>
                                <NavDropdown.Item>List Orders</NavDropdown.Item>
                              </LinkContainer>

                              <LinkContainer to='/admin/abandoncartlist'>
                                <NavDropdown.Item>AbandonCart</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/cartList'>
                                <NavDropdown.Item>Cart</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/orderlist/1/type/1'>
                                <NavDropdown.Item>
                                  Cancelled Order
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/product/scan'>
                                <NavDropdown.Item>Scan</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/product/scanNew'>
                                <NavDropdown.Item>Tray Scan</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/product/boxScan'>
                                <NavDropdown.Item>Box Scan</NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>
                            <LinkContainer to='/admin/productlist'>
                              <Nav.Link>Products</Nav.Link>
                            </LinkContainer>
                            <NavDropdown title='marketing' id='marketing'>
                              <LinkContainer to='/admin/flowlist'>
                                <NavDropdown.Item>
                                  Email Sequences
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/dashboard2'>
                                <NavDropdown.Item>Stats</NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title='submissions' id='submissions'>
                              <LinkContainer to='/admin/formsubmissions'>
                                <NavDropdown.Item>
                                  Contact Form
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/ebooksubmissions'>
                                <NavDropdown.Item>Ebook</NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title='Setting' id='setting'>
                              <LinkContainer to='/profile'>
                                <NavDropdown.Item>Profile</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/admin/discountCode'>
                                <NavDropdown.Item>
                                  Discount Code
                                </NavDropdown.Item>
                              </LinkContainer>
                              <NavDropdown.Item onClick={logoutHandler}>
                                Logout
                              </NavDropdown.Item>
                            </NavDropdown>
                          </Nav>
                        </Navbar.Collapse>
                      </>
                    </Container>
                  </Navbar>
                </>
              </>
            ) : (
              <>
                <Navbar expand='lg' collapseOnSelect className='customer-nav'>
                  <Container>
                    <LinkContainer to='/'>
                      <Navbar.Brand href='/'>
                        <img
                          src='../../images/logo.png'
                          className='siteLogo'
                          alt='99bookscart logo'
                        />
                      </Navbar.Brand>
                    </LinkContainer>
                    {location.pathname.indexOf('checkout') > 0 ||
                    location.pathname.indexOf('BookscartBox1') > 0 ? (
                      ''
                    ) : (
                      <>
                        {/* <div className='onlyDesktop'>
                        <Route
                          render={({ history }) => (
                            <SearchBox history={history} />
                          )}
                        />
                      </div> */}
                        {/* <div className='wishlist'>
                        <Link to='/search'>
                          <FaSearch className='themeColor'></FaSearch>{' '}
                          &nbsp;&nbsp;
                        </Link>
                      </div> */}

                        <Navbar.Toggle aria-controls='basic-navbar-nav' />
                        <Navbar.Collapse
                          id='basic-navbar-nav'
                          className='justify-content-end'
                        >
                          <Nav className='ml-auto'>
                            {/* <LinkContainer to='/ebooks'>
                            <Nav.Link>ebooks</Nav.Link>
                          </LinkContainer> */}
                            <LinkContainer to='/search'>
                              <Nav.Link>
                                <FaSearch className='themeColor'></FaSearch>{' '}
                                Search
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/cart'>
                              <Nav.Link>
                                <i className='fas fa-shopping-cart themeColor'></i>{' '}
                                Cart
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/category/only-99'>
                              <Nav.Link>Only ₹99</Nav.Link>
                            </LinkContainer>
                            {/* <LinkContainer to='/stories'>
                            <Nav.Link>Stories</Nav.Link>
                          </LinkContainer> */}
                            {/* <LinkContainer to='/category/top-rated'>
                                        <Nav.Link >Top Rated</Nav.Link>
                                    </LinkContainer> */}
                            {/* <NavDropdown title='language' id='language'>
                                        <LinkContainer to ='/language/english'>
                                            <NavDropdown.Item>English</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to ='/language/kannada'>
                                            <NavDropdown.Item>Kannada</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to ='/language/tamil'>
                                            <NavDropdown.Item>Tamil</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to ='/language/telugu'>
                                            <NavDropdown.Item>Telugu</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to ='/language/marathi'>
                                            <NavDropdown.Item>Marathi</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to ='/language/hindi'>
                                            <NavDropdown.Item>Hindi</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to ='/language/malayalam'>
                                            <NavDropdown.Item>Malayalam</NavDropdown.Item>
                                        </LinkContainer>
                                    </NavDropdown> */}
                            <NavDropdown title='category' id='category'>
                              <LinkContainer to='/category/crime-and-thriller'>
                                <NavDropdown.Item>
                                  Crime and Thriller
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/classics'>
                                <NavDropdown.Item>Classics</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/young-adult'>
                                <NavDropdown.Item>Young Adult</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/literature-and-fiction'>
                                <NavDropdown.Item>
                                  Literature And Fiction
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/childrens'>
                                <NavDropdown.Item>Childrens</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/fantasy'>
                                <NavDropdown.Item>Fantasy</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/science-fiction'>
                                <NavDropdown.Item>
                                  Science Fiction
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/romance'>
                                <NavDropdown.Item>Romance</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/contemporary'>
                                <NavDropdown.Item>
                                  Contemporary
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/biography'>
                                <NavDropdown.Item>Biography</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/non-fiction'>
                                <NavDropdown.Item>Non Fiction</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/mystery'>
                                <NavDropdown.Item>Mystery</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/history'>
                                <NavDropdown.Item>History</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/historical-fiction'>
                                <NavDropdown.Item>
                                  Historical Fiction
                                </NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to='/category/womens-fiction'>
                                <NavDropdown.Item>
                                  Womens Fiction
                                </NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>

                            {userInfo ? (
                              <NavDropdown title='Profile' id='profile'>
                                <LinkContainer to='/profile'>
                                  <NavDropdown.Item>Profile</NavDropdown.Item>
                                </LinkContainer>
                                {/* <LinkContainer to='/profile/subscriptions'>
                                  <NavDropdown.Item>
                                    Subscriptions
                                  </NavDropdown.Item>
                                </LinkContainer> */}
                                {/*<LinkContainer to='/profile/addresses'>
                                <NavDropdown.Item>
                                  Write Stories
                                </NavDropdown.Item>
                              </LinkContainer> */}
                                {/* <LinkContainer to ='/profile/coins'>
                                                <NavDropdown.Item>Coins</NavDropdown.Item>
                                            </LinkContainer>
                                            <LinkContainer to ='/profile/wishlist'>
                                                <NavDropdown.Item>Wishlist</NavDropdown.Item>
                                            </LinkContainer>
                                            <LinkContainer to ='/profile/setting'>
                                                <NavDropdown.Item>Setting</NavDropdown.Item>
                                            </LinkContainer> */}
                                <NavDropdown.Item onClick={logoutHandler}>
                                  Logout
                                </NavDropdown.Item>
                              </NavDropdown>
                            ) : (
                              <LinkContainer to='/register'>
                                <Nav.Link>
                                  <i className='fas fa-user'></i> SignIn
                                </Nav.Link>
                              </LinkContainer>
                            )}
                          </Nav>
                        </Navbar.Collapse>
                      </>
                    )}
                  </Container>
                </Navbar>
              </>
            )
          }
        </>
      )}
    </header>
  )
}

export default AdminNav
