import axios from 'axios'
import {
  USER_FOLLOWER_LIST_FAIL,
  USER_FOLLOWER_LIST_REQUEST,
  USER_FOLLOWER_LIST_SUCESS,
  USER_FOLLOWING_LIST_FAIL,
  USER_FOLLOWING_LIST_REQUEST,
  USER_FOLLOWING_LIST_SUCESS,
  USER_FOLLOW_FAIL,
  USER_FOLLOW_REQUEST,
  USER_FOLLOW_SUCESS,
} from '../constants/followerConstant'

export const addFollower = (following) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_FOLLOW_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/follower`, following, config)

    dispatch({
      type: USER_FOLLOW_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_FOLLOW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getFollowerList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_FOLLOWER_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/follower/list`, config)

    dispatch({
      type: USER_FOLLOWER_LIST_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_FOLLOWER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getFolloweingList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_FOLLOWING_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/follower/following`, config)

    dispatch({
      type: USER_FOLLOWING_LIST_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_FOLLOWING_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
