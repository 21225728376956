import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listUsers, deleteUser } from '../actions/userActions'
import PaginateFair from '../components/PaginateFair'
import { getBookFair } from '../actions/bookFairActions'
import { listBookFEntries } from '../actions/bookFairEntryActions'
const BookFairScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1

  const getBookFairData = useSelector((state) => state.getBookFair)
  const { loading, error, success, bookfair } = getBookFairData

  const listBookFairEntry = useSelector((state) => state.listBookFairEntry)
  const {
    loading: entriesLoading,
    error: entriesError,
    success: entriesSuccess,
    bookFairEntries,
    pages,
    page,
  } = listBookFairEntry

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(getBookFair(match.params.id))
      dispatch(listBookFEntries(match.params.id, pageNumber))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, pageNumber, match])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row className='align-items-center'>
            <Col>
              <h1>{bookfair.name}</h1>
            </Col>
            <Col>
              <Link to={`/admin/bookfair/${match.params.id}/new`}>
                <Button className='btn-md' variant='dark'>
                  Create Entry
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Starts at {formatDate(bookfair.startDate)}
                <br />
                Ends at : {formatDate(bookfair.endDate)}
                <br />
                Address: {bookfair.address}{' '}
              </p>
            </Col>
          </Row>

          {entriesLoading ? (
            <Loader></Loader>
          ) : error ? (
            <Message variant='danger'>Something wrong</Message>
          ) : (
            <>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>Phone</th>
                    <th>BoxType</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {bookFairEntries.map((entry) => (
                    <tr key={entry._id}>
                      <td>{entry.phone}</td>
                      <td>{boxType(entry.boxType)}</td>
                      <td>
                        <small>{paymentType(entry.paymentMethod)}</small>
                        <br />₹{entry.orderTotal}
                      </td>

                      <td>
                        {/* <LinkContainer to={`/admin/bookfair/${bookfair._id}`}>
                          <Button className='btn-sm' variant='light'>
                            View
                          </Button>
                        </LinkContainer> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <PaginateFair
                pages={pages}
                page={page}
                bookfair={match.params.id}
              ></PaginateFair>
            </>
          )}
        </>
      )}
    </Container>
  )
}

const boxType = (type) => {
  return type == 0
    ? 'Small'
    : type == 1
    ? 'Medium'
    : type == 2
    ? 'Large'
    : type == 3
    ? '999'
    : type == 4
    ? '1499'
    : (type = 5 ? 'custom' : '')
}
const paymentType = (type) => {
  return type == 0 ? 'Cash' : type == 1 ? 'UPI' : type == 2 ? 'CARD' : ''
}
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Date(dateString).toLocaleDateString(undefined, options)
}
export default BookFairScreen
