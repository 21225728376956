import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getWishlist, removeWishlist } from '../actions/wishlistActions'
import Product from '../components/Product'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

const UserWishListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userWishLists = useSelector((state) => state.wishlistGet)
  const { loading, error, success: sucessWishlist, wishlists } = userWishLists

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userWishlistRemove = useSelector((state) => state.wishlistRemove)
  const { success: wishlistRemoveSucces } = userWishlistRemove

  useEffect(() => {
    if (userInfo) {
      dispatch(getWishlist())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, wishlistRemoveSucces])

  const removeFromWishlist = (id, e) => {
    e.preventDefault()
    dispatch(removeWishlist(id))
  }
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          {sucessWishlist && (
            <>
              <Row>
                <div role='presentation'>
                  <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
                    <Link underline='hover' color='inherit' to={'/profile'}>
                      Profile
                    </Link>
                    <Typography color='text.primary'>My Library</Typography>
                  </Breadcrumbs>
                </div>
              </Row>
              <Row>
                {wishlists.length == 0 && (
                  <Message>No Books in your Library!</Message>
                )}
                {wishlists.map((wishlist) => (
                  // <Row key={wishlist._id}>
                  //         <Col xs={12}>
                  //          Code <strong>{wishlist.product.name}</strong>
                  //         </Col>
                  // </Row>
                  <Col key={wishlist.product._id} sm={6} md={4} lg={2} xs={4}>
                    <Route
                      render={({ history }) => (
                        <Product
                          history={history}
                          product={wishlist.product}
                          addtocart={false}
                        />
                      )}
                    />
                    <Link
                      className='text-center'
                      onClick={(e) =>
                        removeFromWishlist(wishlist.product._id, e)
                      }
                      to=''
                    >
                      <i className='fas fa-trash'></i>
                    </Link>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Container>
      )}
    </Container>
  )
}

export default UserWishListScreen
