import axios from 'axios'
import {
  LIST_USER_INTREST_FAIL,
  LIST_USER_INTREST_REQUEST,
  LIST_USER_INTREST_SUCCEES,
} from '../constants/intrestArConstants'

export const listUserIntrests = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LIST_USER_INTREST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/intrested/list`, config)

    dispatch({
      type: LIST_USER_INTREST_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LIST_USER_INTREST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
