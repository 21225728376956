import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import SocialShare from './SocialShare'
import { Typography } from '@mui/material'

const cookies = new Cookies()

const Footer = () => {
  const location = useLocation()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const urlParams = new URLSearchParams(location.search)
  const utmSource = urlParams.get('utm_source')
  const utmMedium = urlParams.get('utm_medium')
  const utmCampaign = urlParams.get('utm_campaign')

  // Set cookies based on URL parameters
  if (utmSource) {
    cookies.set('utm_source', utmSource, { path: '/', maxAge: 34550000 })
    cookies.remove('utm_medium')
    cookies.remove('utm_campaign')
  }
  if (utmMedium) {
    cookies.set('utm_medium', utmMedium, { path: '/', maxAge: 34550000 })
  }
  if (utmCampaign) {
    cookies.set('utm_campaign', utmCampaign, { path: '/', maxAge: 34550000 })
  }
  if (urlParams.get('country')) {
    cookies.set('country', urlParams.get('country'), {
      path: '/',
      maxAge: 34550000,
    })
  }

  const isExcludedPath = (path) => location.pathname.includes(path)

  return (
    <Container>
      <footer>
        {/* {!isExcludedPath('blog/') && (
          <link
            rel="preload"
            as="style"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
            integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
        )} */}

        {![
          'profile',
          'admin',
          'cart',
          'checkout',
          'seller',
          'web-stories/',
          'ai-curated-box',
          '99bookscart-surprise-box',
          'self-help-books',
        ].some(isExcludedPath) && (
          <Container>
            <Row style={{ fontSize: '12px' }}>
              {![
                'cart',
                'checkout',
                'book-ideas',
                'book',
                'plots/en',
                'plots',
                'app/en',
                'seller',
                'app',
                'orderconfirm',
                'order',
              ].some(isExcludedPath) && (
                <>
                  <div>Genres:</div>
                  <p style={{ textTransform: 'capitalize', fontSize: '12px' }}>
                    {[
                      'crime-and-thriller',
                      'romance',
                      'fiction',
                      'mystery',
                      'literature',
                      'adult',
                      'comics',
                      'poetry',
                      'philosophy',
                      'adventure',
                      'science',
                      'adult-fiction',
                      'religion',
                      'erotica',
                      'business',
                      'art',
                      'travel',
                      'mythology',
                      'feminism',
                      'teen',
                      'cookbooks',
                      'sociology',
                      'inspirational',
                      'health',
                      'spiritual',
                      'childrens',
                      'horror',
                      'manga',
                      'paranormal',
                      'sports',
                      'academic',
                      'music',
                      'wildlife',
                      'war',
                      'relationships',
                      'classic',
                      'science-fiction',
                      'fantasy',
                      'contemporary',
                      'non-fiction',
                      'biography',
                      'historical-fiction',
                      'womens-fiction',
                      'lgbtq',
                      'kids-books',
                      'thriller',
                      'politics',
                      'self-help',
                      'psychology',
                    ].map((category) => (
                      <React.Fragment key={category}>
                        <Link
                          to={`/category/${category}`}
                          style={{ marginLeft: '8px' }}
                        >
                          Second Hand {category.replace('-', ' ')} books
                        </Link>
                        {' | '}
                      </React.Fragment>
                    ))}
                  </p>
                  <div>Languages:</div>
                  <p style={{ fontSize: '12px' }}>
                    {['english', 'kannada', 'malayalam', 'telugu', 'tamil'].map(
                      (language) => (
                        <React.Fragment key={language}>
                          <Link
                            to={`/language/${language}`}
                            style={{ marginLeft: '8px' }}
                          >
                            {language.charAt(0).toUpperCase() +
                              language.slice(1)}{' '}
                            books
                          </Link>
                          {' | '}
                        </React.Fragment>
                      )
                    )}
                  </p>
                  <div>Related Searches:</div>
                  <div style={{ fontSize: '12px', color: '#000' }}>
                    {/* List of related searches */}
                    {[
                      'sell second hand books online',
                      'online bookstore',
                      'buy books online',
                      'read books online',
                      'books with free shipping online',
                      'best book websites',
                      'online book purchase',
                      'book store near me',
                      'second hand book store near me',
                      'ncert books',
                      'books',
                      'best books to read',
                      'books to read',
                      'samacheer kalvi 8th books',
                      'harry potter books',
                      'chetan bhagat books',
                      'ruskin bond books',
                      'dc books',
                      'story books',
                      'english books',
                      'tn books',
                      'upsc books',
                      'arihant books',
                      'psychology books',
                      'online books to read',
                      'second hand book online',
                      'second hand book online shop',
                      'books online second hand',
                      'second hand online books',
                      'second hand bookshop online',
                      'online second hand books',
                      'second hand books online',
                      'online second hand bookshop',
                      'second hand book',
                      'bookstore near to me',
                      'near me bookstore',
                      'books stores near me',
                      'books store near me',
                      'booke shop near me',
                      'book house near me books store in india',
                      'books',
                      'book store online',
                      'second hand books online',
                      'second hand books',
                      'second hand books near me',
                      'buy second hand books online',
                      'second hand books online cash on delivery india',
                      'second hand books online india',
                      'sell second hand books online',
                      'online second hand books sell',
                      'sell second hand books',
                      'second hand books in delhi',
                      'second hand books in bangalore online',
                      'best place to buy second hand books online in india',
                      'buy second hand books online india cash on delivery',
                      'second hand books india',
                      'second hand books online in bangalore',
                      'second hand books online in india',
                      'second hand books online bangalore',
                      'buy second hand books',
                      'second hand books online delhi',
                      'second hand books shop',
                      'second hand books in bangalore',
                      'second hand books online amazon',
                      'second hand books in delhi online',
                      'second hand books delhi',
                      'online second hand books',
                      'second hand books online kerala',
                      'second hand books store near me',
                    ].join(', ')}
                  </div>
                </>
              )}
            </Row>

            <div style={{ width: '100%' }} className='site-footer'>
              <Row className='rounded'>
                <Col md={4} className='site-footer-Col'>
                  <br />
                  <div>Social</div>
                  <a
                    href='https://www.facebook.com/99BooksCart-914845775536031'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='99bookscart facebook'
                  >
                    <i className='fab fa-facebook'></i>
                  </a>
                  &nbsp;
                  <a
                    href='https://instagram.com/99bookscart'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='99bookscart instagram'
                  >
                    <i className='fab fa-instagram'></i>
                  </a>
                  &nbsp;
                  <a
                    href='https://twitter.com/99bookscart'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='99bookscart twitter'
                  >
                    <i className='fab fa-twitter'></i>
                  </a>
                  &nbsp;
                  <a
                    href='https://www.youtube.com/channel/UCzObfutEZXqQk5e2sLLNnXg'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='99bookscart youtube'
                  >
                    <i className='fab fa-youtube'></i>
                  </a>
                  &nbsp;
                  <br />
                  <Link to='/sell-books'>Sell Books</Link>
                  <br />
                  <Link to='/trackorder'>Track Order</Link>
                  <br />
                  <SocialShare
                    url='https://www.99bookscart.com'
                    title="99bookscart is India's social storytelling platform"
                  />
                </Col>
                <Col md={4} className='site-footer-Col'>
                  <br />
                  <h5>Information</h5>
                  <Link to='/pages/about-us' aria-label='about us'>
                    About Us
                  </Link>
                  <br />
                  <Link
                    to='/pages/return-refund'
                    aria-label='Return and Refund Policy'
                  >
                    Return and Refund Policy
                  </Link>
                  <br />
                  <Link
                    to='/pages/terms-and-condition'
                    aria-label='Terms and Condition'
                  >
                    Terms and Condition
                  </Link>
                  <br />
                  <Link to='/pages/privacy-policy' aria-label='Privacy Policy'>
                    Privacy Policy
                  </Link>
                  <br />
                </Col>
                <Col md={4} className='site-footer-Col'>
                  <br />
                  <div>99bookscart</div>
                  <p>
                    Vijayapura 586109
                    <br />
                    Karnataka
                    <br />
                    <i className='fas fa-envelope-square'></i>{' '}
                    info@99bookscart.com
                    <br />
                    <a
                      href='https://wa.me/+919019158183'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      +91-901-915-8183
                    </a>
                    <br />
                    <a
                      href='https://bookscartorg.freshdesk.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Support
                    </a>
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        )}
      </footer>
      {!isExcludedPath('ai-curated-box') && (
        <div className='site-copyright'>
          <Row>
            <Col className='text-center py-3'>&copy; 99bookscart 2024</Col>
          </Row>
        </div>
      )}
    </Container>
  )
}

export default Footer
