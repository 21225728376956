import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { convertToSlug2, getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader, FaCheck, FaCross, FaTimes } from 'react-icons/fa'
import Message from '../../../components/Message'
import {
  getStore,
  getStoreById,
  onBoardStore,
} from '../../../actions/storeActions'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SellerProduct from '../../../components/SellerProduct'
import BookPaginate from '../../../components/paginate/BookPaginate'
import AdminNav from '../../../components/AdminNav'
import TimeAgo from '../../../components/TimeAgo'

const AdminSellerHomeScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const [message, SetMessage] = useState('')
  const pageNumber = match.params.pageNumber || 1
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const getStoreByIdRes = useSelector((state) => state.getStoreById)
  const { loading, error, success, store, products, orders, pages, page } =
    getStoreByIdRes
  const onBoardSeller = () => {
    dispatch(onBoardStore(store._id, store.user._id))
    SetMessage('Store Onboarding request sent')
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if ((userLoggedIn && userLoggedIn.isAdmin) || true) {
      dispatch(getStoreById(match.params.id, pageNumber))
    }
  }, [dispatch, userLoggedIn])
  return (
    <>
      <Container>
        <AdminNav />
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          success &&
          (store.storeName ? (
            <Container>
              {pageNumber == 1 && (
                <Row>
                  <Col xs={12} md={6}>
                    <div className='text-center '>
                      <LazyLoadImage
                        src={store.storeImage}
                        height={'250'}
                        width={'250'}
                        // alt={`${userLoggedIn.name} 99bookscart profile image`}
                      />

                      <br />

                      <div
                        style={{ width: '350px', color: '#fff' }}
                        className='center-block'
                      ></div>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <h1>{store.storeName}</h1>
                    <div>Name:{store.user.name}</div>
                    <div>
                      {' '}
                      Address: {store.address},{store.address1},{store.city},
                      {store.zipcode},{store.state}
                    </div>
                    <div>Phone: {store.phone}</div>
                    <div>
                      Verified: {store.isVerified ? <FaCheck /> : <FaTimes />}
                    </div>
                    <div>
                      OnBoarded:{' '}
                      {store.isOnBoarded ? (
                        <FaCheck />
                      ) : (
                        <div>
                          <Button onClick={onBoardSeller}>On Board</Button>
                          {message && <div>{message}</div>}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <h2>Orders</h2>
                <Table striped bordered hover responsive className='table-sm'>
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Total</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders &&
                      orders.map((order) => (
                        <LinkContainer
                          to={`/admin/order/${order._id}`}
                          className='order-list-admin'
                        >
                          <tr key={order._id}>
                            <td>
                              <strong>
                                {order.orderName && order.orderName}
                              </strong>

                              <br />
                              <small>{order.shippingAddress.name}</small>
                              <br />
                              <small>
                                <TimeAgo timestamp={order.createdAt}></TimeAgo>
                              </small>
                            </td>
                            <td>
                              {' '}
                              <strong className='estimated-price'>
                                &#8377;{order.totalPrice}
                              </strong>
                            </td>
                            <td>
                              {order.isPaid ? (
                                <i
                                  className='fas fa-check'
                                  style={{ color: 'green' }}
                                ></i>
                              ) : (
                                <i
                                  className='fas fa-times'
                                  style={{ color: 'red' }}
                                ></i>
                              )}
                              {order.isConfirmed}
                              {order.isConfirmed && (
                                <>
                                  {' '}
                                  <i
                                    className='fab fa-diaspora'
                                    style={{ color: 'blue' }}
                                  ></i>
                                </>
                              )}
                              {order.isFufilled ? (
                                <i
                                  className='fas fa-truck'
                                  style={{ color: 'green' }}
                                ></i>
                              ) : (
                                <i
                                  className='fas fa-bell'
                                  style={{ color: 'red' }}
                                ></i>
                              )}
                            </td>
                          </tr>
                        </LinkContainer>
                      ))}
                  </tbody>
                </Table>
              </Row>
              <Container>
                <Row>
                  <h2>Books</h2>
                  {products.map((product) => (
                    <Col
                      key={product._id}
                      sm={4}
                      md={4}
                      lg={2}
                      xs={4}
                      className='product-grid-size'
                    >
                      <Route
                        render={({ history }) => (
                          <SellerProduct history={history} product={product} />
                        )}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
              <Row>
                <BookPaginate
                  pages={pages}
                  page={page}
                  category={'top-rated'}
                  url={`store/${convertToSlug2(store.storeName)}/${store._id}`}
                ></BookPaginate>
              </Row>
            </Container>
          ) : (
            <Link to={'/seller/create/new/store'} className='btn'>
              <Button> Create Store</Button>
            </Link>
          ))
        )}
      </Container>
    </>
  )
}

export default AdminSellerHomeScreen
