export const CREATE_NEW_STORY_REQUEST = 'CREATE_NEW_STORY_REQUEST'
export const CREATE_NEW_STORY_SUCCEES = 'CREATE_NEW_STORY_SUCCEES'
export const CREATE_NEW_STORY_FAIL = 'CREATE_NEW_STORY_FAIL'

export const LIST_STORY_REQUEST = 'LIST_STORY_REQUEST'
export const LIST_STORY_SUCCEES = 'LIST_STORY_SUCCEES'
export const LIST_STORY_FAIL = 'LIST_STORY_FAIL'

export const GET_STORY_REQUEST = 'GET_STORY_REQUEST'
export const GET_STORY_SUCCEES = 'GET_STORY_SUCCEES'
export const GET_STORY_FAIL = 'GET_STORY_FAIL'

export const MY_LIST_STORY_REQUEST = 'MY_LIST_STORY_REQUEST'
export const MY_LIST_STORY_SUCCEES = 'MY_LIST_STORY_SUCCEES'
export const MY_LIST_STORY_FAIL = 'MY_LIST_STORY_FAIL'

export const DELETE_STORY_REQUEST = 'DELETE_STORY_REQUEST'
export const DELETE_STORY_SUCCEES = 'DELETE_STORY_SUCCEES'
export const DELETE_STORY_FAIL = 'DELETE_STORY_FAIL'

export const MY_READING_STORY_REQUEST = 'MY_READING_STORY_REQUEST'
export const MY_READING_STORY_SUCCEES = 'MY_READING_STORY_SUCCEES'
export const MY_READING_STORY_FAIL = 'MY_READING_STORY_FAIL'

export const LIST_STORY_BY_CAT_REQUEST = 'LIST_STORY_BY_CAT_REQUEST'
export const LIST_STORY_BY_CAT_SUCCEES = 'LIST_STORY_BY_CAT_SUCCEES'
export const LIST_STORY_BY_CAT_FAIL = 'LIST_STORY_BY_CAT_FAIL'

export const LIST_STORY_BY_KEY_REQUEST = 'LIST_STORY_BY_KEY_REQUEST'
export const LIST_STORY_BY_KEY_SUCCEES = 'LIST_STORY_BY_KEY_SUCCEES'
export const LIST_STORY_BY_KEY_FAIL = 'LIST_STORY_BY_KEY_FAIL'
