import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Table, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  createDiscountCode,
  listDiscountCode,
} from '../actions/discountCodeActions'
import AdminNav from '../components/AdminNav'

const DiscountCodeScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [value, setValue] = useState('')

  const dispatch = useDispatch()
  const discountCode = useSelector((state) => state.discountCodeCreate)
  const {
    success,
    loading,
    error,
    discountCode: newDiscountCode,
  } = discountCode

  const discountCodesList = useSelector((state) => state.discountCodeList)
  const {
    success: successDiscoutCodeList,
    loading: loadingDiscountCodeList,
    error: errorDiscountCodeList,
    discountCodes,
  } = discountCodesList

  useEffect(() => {
    dispatch(listDiscountCode())
  }, [dispatch, success])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(createDiscountCode(name, type, value))
  }
  return (
    <Container>
      <AdminNav />
      <h1>DisCount Code</h1>
      <Row>
        <Col xs={6}>
          {success && (
            <Message variant='success'>Discount Code Created</Message>
          )}
          {error && <Message variant='danger'>{error}</Message>}
          {loading && <Loader></Loader>}
          <FormContainer>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='email'>
                <Form.Label>Type</Form.Label>
                <Form.Check
                  type='radio'
                  label='Percentage'
                  id='percentage'
                  name='type'
                  value='percentage'
                  onChange={(e) => setType(e.target.value)}
                ></Form.Check>
                <Form.Check
                  type='radio'
                  label='Fixed Amount'
                  id='fixedAmount'
                  name='type'
                  value='fixedAmount'
                  onChange={(e) => setType(e.target.value)}
                ></Form.Check>
              </Form.Group>
              <Form.Group controlId='password'>
                <Form.Label>value</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Value'
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button type='submit' varaint='primary'>
                Create Discount Code
              </Button>
              <br />
            </Form>
          </FormContainer>
        </Col>
        <Col xs={6}>
          {loadingDiscountCodeList ? (
            <Loader></Loader>
          ) : errorDiscountCodeList ? (
            <Message variant='danger'>{errorDiscountCodeList}</Message>
          ) : (
            <>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Type</th>
                    <th>Value</th>
                    <th>CreateAt</th>
                  </tr>
                </thead>
                <tbody>
                  {discountCodes.map((code) => (
                    <tr key={code._id}>
                      <td>{code.name}</td>
                      <td>{code.type}</td>
                      <td>
                        {code.type == 'fixedAmount' && '₹'}
                        {code.value}
                        {code.type == 'percentage' && '%'}
                      </td>
                      <td>
                        <small>
                          {code.createdAt.substring(0, 10)}
                          <br />
                          {code.createdAt.substring(19, 11)}
                        </small>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default DiscountCodeScreen
