import React from 'react'
import Helmet from 'react-helmet'
export function convertToSlug2(Text) {
  return (
    Text &&
    Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  )
}
const BlogMeta = ({ blog }) => {
  const title = blog.title
  const image = blog.image
  const author = 'Pooja Rawath'
  const createdAt = blog.createdAt ? blog.createdAt.slice(0, 10) : ''
  const updatedAt = blog.updatedAt ? blog.updatedAt.slice(0, 10) : ''
  const description = blog.description
  const url = `https://www.99bookscart.com/blog/${blog._id}/${convertToSlug2(
    blog.title
  )}`
  const baseUrl = 'https://www.99bookscart.com/blog'
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': 'https://www.99bookscart.com/#organization',
        name: 'www.99bookscart.com.com',
        url: 'https://www.99bookscart.com.com',
        logo: {
          '@type': 'ImageObject',
          '@id': 'https://www.99bookscart.com.com/#logo',
          url: 'https://www.99bookscart.com/images/logo.png',
          contentUrl: 'https://www.99bookscart.com/images/logo.png',
          caption: 'www.99bookscart.com',
          inLanguage: 'en-GB',
          width: '250',
          height: '66',
        },
      },
      {
        '@type': 'WebSite',
        '@id': 'https://www.99bookscart.com/#website',
        url: 'https://www.99bookscart.com',
        name: 'www.99bookscart.com',
        alternateName: 'bookscart',
        publisher: { '@id': 'https://www.99bookscart.com/#organization' },
        inLanguage: 'en-GB',
      },
      {
        '@type': 'ImageObject',
        '@id': blog.image,
        url: blog.image,
        width: '1200',
        height: '675',
        caption: blog.title,
        inLanguage: 'en-GB',
      },
      {
        '@type': 'WebPage',
        '@id': url,
        url: url,
        name: blog.title,
        datePublished: blog.createdAt,
        dateModified: blog.createdAt,
        isPartOf: { '@id': 'https://www.99bookscart.com/#website' },
        primaryImageOfPage: {
          '@id': blog.image,
        },
        inLanguage: 'en-GB',
      },
      {
        '@type': 'Person',
        '@id': 'https://www.99bookscart.com/contact-us',
        name: author,
        url: 'https://www.99bookscart.com/contact-us',
        image: {
          '@type': 'ImageObject',
          '@id':
            'https://secure.gravatar.com/avatar/7ff6461073df442da4c8d1d80556c9d4?s=96&amp;d=mm&amp;r=g',
          url: 'https://secure.gravatar.com/avatar/7ff6461073df442da4c8d1d80556c9d4?s=96&amp;d=mm&amp;r=g',
          caption: author,
          inLanguage: 'en-GB',
        },
        sameAs: ['https://www.99bookscart.com/'],
        worksFor: { '@id': 'https://www.99bookscart.com/#organization' },
      },
      {
        '@type': 'Article',
        headline: title,
        image: blog.image,
        datePublished: createdAt,
        dateModified: updatedAt,
        articleBody: description,
        articleSection: 'Books',
        author: [
          {
            '@type': 'Person',
            name: author,
            url: 'https://www.99bookscart.com/contact-us',
          },
        ],
        publisher: {
          '@type': 'Organization',
          name: '99bookscart',
          logo: {
            '@type': 'ImageObject',
            url: 'https://www.99bookscart.com/images/logo.png',
          },
        },
      },
    ],
  }
  const ff = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': 'https://www.99bookscart.com/#organization',
        name: 'www.99bookscart.com.com',
        url: 'https://www.99bookscart.com.com',
        logo: {
          '@type': 'ImageObject',
          '@id': 'https://www.99bookscart.com.com/#logo',
          url: 'https://www.99bookscart.com/images/logo.png',
          contentUrl: 'https://www.99bookscart.com/images/logo.png',
          caption: 'www.99bookscart.com',
          inLanguage: 'en-GB',
          width: '250',
          height: '66',
        },
      },
      {
        '@type': 'WebSite',
        '@id': 'https://www.99bookscart.com/#website',
        url: 'https://www.99bookscart.com',
        name: 'www.99bookscart.com',
        alternateName: 'bookscart',
        publisher: { '@id': 'https://www.99bookscart.com/#organization' },
        inLanguage: 'en-GB',
      },
      {
        '@type': 'ImageObject',
        '@id': blog.image,
        url: blog.image,
        width: '1200',
        height: '675',
        caption: blog.title,
        inLanguage: 'en-GB',
      },
      {
        '@type': 'WebPage',
        '@id': url,
        url: url,
        name: blog.title,
        datePublished: createdAt,
        dateModified: createdAt,
        isPartOf: { '@id': 'https://www.99bookscart.com/#website' },
        primaryImageOfPage: {
          '@id': blog.image,
        },
        inLanguage: 'en-GB',
      },
      {
        '@type': 'Person',
        '@id': 'https://www.99bookscart.com/contact-us',
        name: author,
        url: 'https://www.99bookscart.com/contact-us',
        image: {
          '@type': 'ImageObject',
          '@id':
            'https://secure.gravatar.com/avatar/7ff6461073df442da4c8d1d80556c9d4?s=96&amp;d=mm&amp;r=g',
          url: 'https://secure.gravatar.com/avatar/7ff6461073df442da4c8d1d80556c9d4?s=96&amp;d=mm&amp;r=g',
          caption: author,
          inLanguage: 'en-GB',
        },
        sameAs: ['https://www.99bookscart.com/'],
        worksFor: { '@id': 'https://www.99bookscart.com/#organization' },
      },
      {
        '@type': 'NewsArticle',
        headline: blog.title,
        keywords: blog.topi,
        datePublished: createdAt,
        dateModified: createdAt,
        articleSection: 'Arts and Literature',
        author: {
          '@id': 'https://www.99bookscart.com/contact-us',
          name: author,
        },
        publisher: { '@id': 'https://www.99bookscart.com/#organization' },
        description: blog.metaDescription,
        name: blog.title,
        '@id': url + '/#richSnippet',
        isPartOf: {
          '@id': url + '/#richSnippet',
        },
        image: {
          '@id': blog.image,
        },
        inLanguage: 'en-GB',
        mainEntityOfPage: {
          '@id': url + '/#webpage',
        },
      },
    ],
  }

  const newStructured = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    headline: blog.title,
    alternativeHeadline: blog.metaTitle,
    description: blog.metaDescription,
    articleSection: 'Arts and Literature',
    articleBody: blog.metaDescription,
    author: {
      '@type': 'Person',
      name: author,
      url: 'https://www.99bookscart.com/contact-us',
    },
    datePublished: blog.createdAt,
    dateModified: blog.createdAt,
    publisher: {
      '@type': 'Organization',
      name: '99bookscart',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.99bookscart.com/images/logo.png',
      },
    },
    image: {
      '@type': 'ImageObject',
      url: blog.image,
      width: 800,
      height: 600,
    },
    wordCount: '5000',
    timeRequired: 'PT20M',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    keywords: blog.topic,
    inLanguage: 'en-UK',
  }

  return (
    <Helmet>
      <title>{blog.metaTitle ? blog.metaTitle : blog.title}</title>
      <meta charset='UTF-8' />
      <link rel='canonical' href={url} />
      <meta
        property='og:title'
        content={blog.metaTitle ? blog.metaTitle : blog.title}
      />

      <head prefix='book: https://ogp.me/ns/book#'></head>
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://www.99bookscart.com' />
      <meta
        property='og:image'
        content='https://99bookscart.com/images/logo.png'
      />
      <meta property='og:description' content={blog.metaDescription} />
      <meta property='og:site_name' content='99bookscart' />
      <meta name='description' content={blog.metaDescription}></meta>
      <meta name='keyword' content={blog.topic && blog.topic.toString()}></meta>
      <meta
        name='title'
        content={blog.metaTitle ? blog.metaTitle : blog.title}
      ></meta>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0'
      ></meta>

      <meta name='robots' content='index, follow' />
      <script async src='https://cdn.ampproject.org/v0.js'></script>

      <script
        async
        custom-element='amp-story'
        src='https://cdn.ampproject.org/v0/amp-story-1.0.js'
      ></script>
      <script
        async
        custom-element='amp-video'
        src='https://cdn.ampproject.org/v0/amp-video-0.1.js'
      ></script>
    </Helmet>
  )
}

export default BlogMeta
