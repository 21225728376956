export const USER_WISHLIST_ADD_REQUEST = 'USER_WISHLIST_ADD_REQUEST'
export const USER_WISHLIST_ADD_SUCESS = 'USER_WISHLIST_ADD_SUCESS'
export const USER_WISHLIST_ADD_FAIL = 'USER_WISHLIST_ADD_FAIL'

export const USER_WISHLIST_LIST_REQUEST = 'USER_WISHLIST_LIST_REQUEST'
export const USER_WISHLIST_LIST_SUCESS = 'USER_WISHLIST_LIST_SUCESS'
export const USER_WISHLIST_LIST_FAIL = 'USER_WISHLIST_LIST_FAIL'

export const USER_WISHLIST_REMOVE_REQUEST = 'USER_WISHLIST_REMOVE_REQUEST'
export const USER_WISHLIST_REMOVE_SUCESS = 'USER_WISHLIST_REMOVE_SUCESS'
export const USER_WISHLIST_REMOVE_FAIL = 'USER_WISHLIST_REMOVE_FAIL'



export const USER_INTREST_ADD_REQUEST = 'USER_INTREST_ADD_REQUEST'
export const USER_INTREST_ADD_SUCESS = 'USER_INTREST_ADD_SUCESS'
export const USER_INTREST_ADD_FAIL = 'USER_INTREST_ADD_FAIL'

export const USER_INTREST_LIST_REQUEST = 'USER_INTREST_LIST_REQUEST'
export const USER_INTREST_LIST_SUCESS = 'USER_INTREST_LIST_SUCESS'
export const USER_INTREST_LIST_FAIL = 'USER_INTREST_LIST_FAIL'

export const USER_INTREST_PRODUCT_REQUEST = 'USER_INTREST_PRODUCT_REQUEST'
export const USER_INTREST_PRODUCT_SUCESS = 'USER_INTREST_PRODUCT_SUCESS'
export const USER_INTREST_PRODUCT_FAIL = 'USER_INTREST_PRODUCT_FAIL'

export const USER_INTREST_REMOVE_REQUEST = 'USER_INTREST_REMOVE_REQUEST'
export const USER_INTREST_REMOVE_SUCESS = 'USER_INTREST_REMOVE_SUCESS'
export const USER_INTREST_REMOVE_FAIL = 'USER_INTREST_REMOVE_FAIL'


export const USER_READ_ADD_REQUEST = 'USER_READ_ADD_REQUEST'
export const USER_READ_ADD_SUCESS = 'USER_READ_ADD_SUCESS'
export const USER_READ_ADD_FAIL = 'USER_READ_ADD_FAIL'

export const USER_READ_LIST_REQUEST = 'USER_READ_LIST_REQUEST'
export const USER_READ_LIST_SUCESS = 'USER_READ_LIST_SUCESS'
export const USER_READ_LIST_FAIL = 'USER_READ_LIST_FAIL'

export const USER_READ_PRODUCT_REQUEST = 'USER_READ_PRODUCT_REQUEST'
export const USER_READ_PRODUCT_SUCESS = 'USER_READ_PRODUCT_SUCESS'
export const USER_READ_PRODUCT_FAIL = 'USER_READ_PRODUCT_FAIL'

export const USER_READ_REMOVE_REQUEST = 'USER_READ_REMOVE_REQUEST'
export const USER_READ_REMOVE_SUCESS = 'USER_READ_REMOVE_SUCESS'
export const USER_READ_REMOVE_FAIL = 'USER_READ_REMOVE_FAIL'