import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import AppHeader from '../../../components/plots/AppHeader'

import Loader from '../../../components/Loader'
import { updateSubscriptions } from '../../../actions/subscriptionActions'
const SubscriptionSuccess = ({ history, match }) => {
  const dispatch = useDispatch()
  const uSubscription = useSelector((state) => state.updateSubscription)
  const {
    success: successSubscriptionUpdate,
    subscription: updatedSubs,
    loading: subUpdateLoading,
  } = uSubscription
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    } else {
      dispatch(
        updateSubscriptions({
          razorpayId: match.params.id2,
          subscriptionId: match.params.id3,
          status: 1,
          sid: match.params.id,
        })
      )
    }
  }, [dispatch, match])
  return (
    <Container>
      <AppHeader></AppHeader>
      {subUpdateLoading ? (
        <Loader></Loader>
      ) : (
        <Container>
          <Row className='story-hero-text'>
            <Col xs={12} sm={12}>
              <div>
                <h1 className='hero-text'>Congratualtions</h1>
                <p>Subscription is Active!</p>
              </div>
            </Col>
            <Col xs={12} sm={12}>
              <div>
                <Link to={'/app/home'}>
                  <Button>Start Reading</Button>
                </Link>{' '}
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <Container></Container>
    </Container>
  )
}
{
}

export default SubscriptionSuccess
