import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../actions/userActions'
import AppHeader from '../../../components/plots/AppHeader'

import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { listSubscriptions } from '../../../actions/subscriptionActions'
import Loader from '../../../components/Loader'

const SubscriptionList = ({ history, match }) => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const listSubscriptionRes = useSelector((state) => state.listSubscription)
  const { success, subscriptions, loading } = listSubscriptionRes
  const logoutHandler = () => {
    dispatch(logout())
    history.push('/')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    }
    dispatch(listSubscriptions())
  }, [dispatch, history, userLoggedIn])
  return (
    <Container>
      <AppHeader></AppHeader>
      {loading && !success ? (
        <Loader></Loader>
      ) : (
        <Container>
          <Row>
            <p className='text-center'> {userLoggedIn.email}</p>
          </Row>
          {/* <Row>
            <Col>
              {subscriptions && subscriptions.length == 0 && (
                <div style={{ textAlign: 'center' }}>
                  No Active Subscription
                  <br />
                  <Link to={'/app/profile/billing'}>
                    <Button>Subscribe Now</Button>
                  </Link>
                </div>
              )}
              {subscriptions &&
                subscriptions.length > 0 &&
                subscriptions.map((s) => (
                  <div style={{ textAlign: 'center' }}>
                    {s.name}
                    <br />₹{s.amount}/Month
                    <br />
                    <span className='topic'>Active</span>
                  </div>
                ))}
            </Col>
          </Row> */}
          <Row>
            <Col className='text-center'>
              <Button
                variant='primary'
                className='btn'
                style={{ float: 'center', marginTop: '10%' }}
                onClick={logoutHandler}
              >
                Logout
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  )
}
{
}

export default SubscriptionList
