import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import {
  Table,
  Form,
  Button,
  Row,
  Col,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'
import { getUserProfile } from '../../../actions/userActions'

import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {
  FaBookOpen,
  FaBoxOpen,
  FaMoneyBillAlt,
  FaThList,
  FaTools,
} from 'react-icons/fa'
import Product from '../../../components/Product'
import { addFollower } from '../../../actions/followerActions'
import ReactHtmlParser from 'react-html-parser'

import { Link } from 'react-router-dom'
import { FaBookReader, FaStar, FaList, FaClock, FaPlus } from 'react-icons/fa'
import Story from '../../../components/Story'
import AddIcon from '@mui/icons-material/Add'
const PlotAuthorScreen = ({ location, history, match }) => {
  const dispatch = useDispatch()
  const [follow, setFollow] = useState(false)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userProfile = useSelector((state) => state.userProfile)
  const {
    loading,
    error,
    profile: profile,
    success: userSuccess,
    stories,
  } = userProfile

  const [key, setKey] = useState('home')
  const followHandler = () => {
    if (userInfo) {
      dispatch(addFollower({ following: profile._id }))
      setFollow(true)
    } else {
      history.push('/register')
    }
  }
  useEffect(() => {
    dispatch(getUserProfile(match.params.id))
  }, [dispatch, history, userInfo])

  return (
    <Container>
      <Container>
        {!userSuccess ? (
          <Loader></Loader>
        ) : (
          <>
            <Row>
              <Col>
                <div className='text-center profile-backgrd'>
                  {/* <amp-img
                    src={profile.profileImage && profile.profileImage}
                    alt={`${profile.name} 99bookscart profile image`}
                    height='130'
                    width='130'
                    style={{ borderRadius: '130px' }}
                  />
                  <br /> */}
                  <div>{profile && profile.name}</div>

                  <div style={{ color: '#fff' }} className='center-block'>
                    <Row className='storystatsrow'>
                      <Col>
                        <div className='storiestatslabel'>
                          <FaBookReader /> Plots
                        </div>
                        <div className='storiestatsvalue'>
                          {profile.stories}
                        </div>
                      </Col>
                      <Col>
                        <div className='storiestatslabel'>
                          <FaStar /> Vote
                        </div>
                        <div className='storiestatsvalue'>
                          {profile.voteCount}
                        </div>
                      </Col>
                      <Col>
                        <div className='storiestatslabel'>
                          <FaList /> Followers
                        </div>
                        <div className='storiestatsvalue'>
                          {profile.followerss}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <Container>
              <Row>
                <Col xs={12} md={12}>
                  <br />

                  {profile.aboutMe && (
                    <>
                      <h1>About {profile.name}</h1>
                      <br />
                      <p>{ReactHtmlParser(profile.aboutMe)}</p>
                    </>
                  )}
                  <div style={{ float: 'center' }}>
                    <Button
                      onClick={followHandler}
                      disabled={follow ? true : false}
                    >
                      <AddIcon style={{ backgroundColor: '#fff' }}></AddIcon>
                      {'  '}
                      Follow
                    </Button>
                  </div>
                </Col>
                <Col xs={12} md={12}>
                  <div style={{ display: 'block' }}>
                    <h2 className='text-center'>
                      {profile && profile.name} Books
                    </h2>
                  </div>
                  <Row>
                    {stories.map((story) => (
                      <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                        <Route
                          render={({ history }) => (
                            <Story history={history} story={story} />
                          )}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Container>
    </Container>
  )
}

export default PlotAuthorScreen
