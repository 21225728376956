import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { getMyStoryReading } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  ProgressBar,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import Loader from '../../../components/Loader'
import AppStory from '../../../components/AppStory'
import AppPlotListPaginate from '../../../components/plots/AppPlotListPaginate'
import AppHeader from '../../../components/plots/AppHeader'

const AppReadPlotListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const myReadingStory = useSelector((state) => state.myReadingStory)
  const { stories, loading, error, suceess, pages, page } = myReadingStory

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    } else {
      if (!userLoggedIn.isSubscribed) {
        history.push('/app/profile/billing')
      }
    }
    dispatch(getMyStoryReading())
  }, [dispatch, pageNumber, history])
  return (
    <>
      <Container>
        <AppHeader></AppHeader>
        {loading && !suceess ? (
          <Loader></Loader>
        ) : (
          <Container>
            <h1>My Readings</h1>
            <Row>
              {stories &&
                stories.map((story) => (
                  <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                    <Route
                      render={({ history }) => (
                        <AppStory history={history} story={story} />
                      )}
                    />
                    <ProgressBar variant='success' now={story.count * 10} />
                  </Col>
                ))}
              <AppPlotListPaginate
                pages={pages}
                page={page}
                category={match.params.category}
              ></AppPlotListPaginate>
            </Row>
          </Container>
        )}
        <br />
        <br />
      </Container>
    </>
  )
}
{
}

export default AppReadPlotListScreen
