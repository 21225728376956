import React, { useState } from 'react'
import { Card, Button, Modal, Image, Row, Col } from 'react-bootstrap'
import Rating from './Rating'
import { Link } from 'react-router-dom'
import { addToCart } from '../actions/cartActions.js'
import { useDispatch, useSelector } from 'react-redux'
import BoxProgressBar from './BoxProgressBar'
import { FaBoxOpen } from 'react-icons/fa'
import { getCategory, storyStatus } from '../helpers/helpers'
import { FaBookReader, FaStar, FaList, FaClock } from 'react-icons/fa'
import { convertToSlug } from '../helpers/helpers'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Story = ({ story, history, match, addtocart = true }) => {
  const dispatch = useDispatch()
  // const [show, setShow] = useState(false);
  const [disable, setDisable] = React.useState(false)

  return (
    <>
      <Row key={story._id} style={{ padding: '3px' }} className='storylistrow'>
        <Col xs={4} className='story-cover'>
          <Link
            to={`/plots/en/book/${convertToSlug(story.title)}/${story._id}`}
          >
            <LazyLoadImage
              src={story.coverImage}
              width={'100%'}
              alt={story.title + ' by ' + story.user.name}
            />
          </Link>
        </Col>
        <Col xs={8} className='story-cover' style={{ marginTop: '10px' }}>
          <h6>
            <Link
              to={`/plots/en/book/${convertToSlug(story.title)}/${story._id}`}
              className='text-color'
            >
              {story.title}
            </Link>
          </h6>
          <div style={{ fontSize: '12px' }} className='text-muted'>
            {/* <Link to={`/user/${story.user._id}`} className='writer-name'> */}
            {story.user && story.user.name}
            {/* </Link> */}
          </div>
          <div className='storystatslist'>
            {/* <FaBookReader></FaBookReader> {story.readCount} <FaStar></FaStar>{' '}
            {story.voteCount} */}
            {/* <Rating value={story.rating}></Rating>{' '}
            <small>
              {story.reviewCount &&
                story.reviewCount.toLocaleString() + ' Reviews'}
            </small> */}
          </div>
          {/* <div>
            {story.category &&
              (typeof story.category === 'string' ? (
                <>{getCategory(story.category)}</>
              ) : (
                <>
                  {story.category.map((cat) => (
                    <>
                      <span key={`${cat._id}`} className='topic'>
                        <span>{cat.name}</span>
                      </span>
                    </>
                  ))}
                </>
              ))}
          </div> */}
          <div className='storydescriptionlist'>{story.description}</div>
        </Col>
      </Row>
    </>
  )
}

export default Story
