import React, { useState } from 'react'
import { Form, Button, Container } from 'react-bootstrap'
import { Row, Col, Card, ListGroupItem } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const AmazonAd = ({ history }) => {
  const [keyword, setKeyword] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      history.push(`/admin/abandoncartlist/1/${keyword}`)
    } else {
      history.push('/')
    }
  }

  return (
    <Container>
      <Row className='text-center'>
        <Col className='affproduct'>
          <a href='https://amzn.to/4a3RGas' target='_blank'>
            <LazyLoadImage
              src='https://m.media-amazon.com/images/I/71nK5FjjYVL._SY425_.jpg'
              width={140}
              height={140}
            />
            <br />
            <small>
              A Song of Ice and Fire - A Game of Thrones: The Complete Boxset of
              7 Books
            </small>
            <div></div>
          </a>
        </Col>
        <Col className='affproduct'>
          <a href='https://amzn.to/3PWYBu7' target='_blank'>
            <LazyLoadImage
              src='https://m.media-amazon.com/images/I/718X1SWIsjL._SY425_.jpg'
              width={140}
              height={140}
            />
            <br />
            <small>Harry Potter Box Set: The Complete Collection</small>
            <div></div>
          </a>
        </Col>
        <Col className='affproduct'>
          <a href='https://amzn.to/3IQbLVy' target='_blank'>
            <LazyLoadImage
              src='https://m.media-amazon.com/images/I/51jBAvi09jL._SY445_SX342_.jpg'
              width={140}
              height={140}
            />
            <br />
            <small>The Shiva Triology Boxset of 3 Books</small>
            <div></div>
          </a>
        </Col>
        <Col className='affproduct'>
          <a href='https://amzn.to/4a3SvA4' target='_blank'>
            <LazyLoadImage
              src='https://m.media-amazon.com/images/I/51yWCNkRbqL._SX342_SY445_.jpg'
              width={140}
              height={140}
            />
            <br />
            <small>
              Bloomsbury Publishing India Harry Potter&The Philosopher's Stone
            </small>
            <div></div>
          </a>
        </Col>
      </Row>
      <Row className='text-center'>
      <Col className='affproduct'>
          <a href='https://amzn.to/4a8eoy4' target='_blank'>
            <LazyLoadImage
              src='https://m.media-amazon.com/images/I/61AiYw92f+L._SY425_.jpg'
              width={110}
              height={140}
            />
            <br />
            <small>The Complete Chronicles of Narnia</small>
            <div></div>
          </a>
        </Col>
        <Col className='affproduct'>
          <a href='https://amzn.to/4cwlIoE' target='_blank'>
            <LazyLoadImage
              src='https://m.media-amazon.com/images/I/71iY7MvElDL._SY425_.jpg'
              width={140}
              height={140}
            />
            <br />
            <small>Tokyo Ghoul Re Boxset: Includes vols. 1-16</small>
            <div></div>
          </a>
        </Col>
        <Col className='affproduct'>
          <a href='https://amzn.to/43sXMi7' target='_blank'>
            <LazyLoadImage
              src='https://m.media-amazon.com/images/I/817dIguWe9L._SY425_.jpg'
              width={140}
              height={140}
            />
            <br />
            <small>Diary of a Wimpy Kid Box Set</small>
            <div></div>
          </a>
        </Col>
        <Col className='affproduct'>
          <a href='https://amzn.to/3vgAVK2' target='_blank'>
            <LazyLoadImage
              src='https://m.media-amazon.com/images/I/81IzbD2IiIL._SY425_.jpg'
              width={110}
              height={140}
            />
            <br />
            <small>The Kite Runner</small>
            <div></div>
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default AmazonAd
