import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { listUsers, deleteUser } from '../../actions/userActions'
import ReactHtmlParser from 'react-html-parser'
import AdminNav from '../../components/AdminNav'
import { listBlog } from '../../actions/blogActions'
import { myFunction } from '../../helpers/helpers'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ListBlogScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1
  const listBlogRes = useSelector((state) => state.listBlog)
  const { loading, error, blogs, page, pages, success } = listBlogRes
  const handleChange = (event, value) => {
    history.push(`/blog/page/${value}`)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(listBlog(pageNumber))
  }, [dispatch, pageNumber])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          <h1>99bookscart blog</h1>
          <Row>
            {blogs.map((blog) => (
              <Col key={blog._id} sm={12} md={6} lg={6} xs={12}>
                <Link to={`/blog/view/${blog._id}/${myFunction(blog.title)}`}>
                  <div>
                    <LazyLoadImage
                      src={blog.image}
                      width={'100%'}
                      alt={blog.title}
                    />
                  </div>
                  <br />
                  <h1>{blog.title}</h1>
                  <br />
                  {/* <div>{ReactHtmlParser(blog.description.slice(0, 100))}</div> */}
                  {/* <div>
                    Tag:{' '}
                    {success &&
                      blog.topic.map((topic) => (
                        <span className='topic'>{topic}</span>
                      ))}
                  </div> */}
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </Container>
  )
}

export default ListBlogScreen
