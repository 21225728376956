import React, { useState, useEffect, useRef } from 'react'
import { Form, Card, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { saveShippingAddress } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps'
import OrderSummary from '../components/OrderSummary'
import { Link } from 'react-router-dom'
import { FaCheckCircle, FaTruck } from 'react-icons/fa'
import {
  userAddressList,
  deleteUserAddress,
  createGuestUserAddress,
} from '../actions/userActions'
import { getCartDetailsFromStorage } from '../actions/cartActions.js'
import Cookies from 'universal-cookie'
import { SHIPPING_STATE } from '../constants/orderConstants'
import { Autocomplete, TextField } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Loader from '../components/Loader'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
const cookies = new Cookies()
const BasicInformationScreen = ({ history }) => {
  const firstRender = useRef(true)

  const dispatch = useDispatch()

  const guestUser = useSelector((state) => state.guestUser)
  const { user, success: guestSuccess, error: guestError } = guestUser
  const userCart = useSelector((state) => state.userCart)
  const { cart, loading: cartLoading, success: cartSuccess } = userCart
  const [email, setEmail] = useState(cookies.get('_cfx_email'))
  const [name, setName] = useState(cookies.get('_cfx_name'))

  const [address, setAddress] = useState(cookies.get('_cfx_address'))
  const [address2, setAddress2] = useState(cookies.get('_cfx_address1'))
  const [city, setCity] = useState(cookies.get('_cfx_city'))
  const [postalCode, setPostalCode] = useState(cookies.get('_cfx_pin'))
  const [phone, setPhone] = useState(cookies.get('_cfx_phone'))
  const [state, setState] = useState(cookies.get('_cfx_state'))

  const [disable, setDisabled] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState({})
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [zipError, setZipError] = useState(null)
  const [addNew, setAddNew] = useState(false)
  var date = new Date()
  var deliveryDate = new Date(date.setTime(date.getTime() + 7 * 86400000))
  // const [country, setCountry] = useState(shippingAddress.country)
  let country = 'India'
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getCartDetailsFromStorage())

    if (guestSuccess) {
      if (!user.data) {
        history.push('/checkout/shipping')
      }
    }
    if (guestError) {
      if (guestError.type == 'phone') {
        setPhoneError(guestError.data)
      }
      if (guestError.type == 'email') {
        setEmailError(guestError.data)
      }
    }
  }, [dispatch, guestSuccess, guestError])

  function isEmail(email) {
    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (email !== '' && email.match(emailFormat)) {
      return true
    }

    return false
  }
  const submitHandler = (e) => {
    e.preventDefault()
    if (isEmail(email)) {
      setEmailError('')
      var phonenoExp = /^\d{10}$/
      if (phonenoExp.test(phone)) {
        setPhoneError('')
        if (postalCode.length == 6) {
          setZipError('')

          dispatch(
            createGuestUserAddress({
              name,
              address,
              address2,
              city,
              postalCode,
              state,
              country,
              phone,
              email,
              cartId: cookies.get('_cid'),
            })
          )
        } else {
          setZipError('Enter Valid ZipCode')
        }
      } else {
        setPhoneError('Enter Valid Phone Number')
      }
    } else {
      setEmailError('Enter Valid email')
    }
  }
  return (
    <Container>
      <CssBaseline />
      <FormContainer>
        {/* <CheckoutSteps step1 /> */}

        <div style={{ textAlign: 'center', marginBottom: '8px' }}>
          {/* <Avatar
            sx={{ m: 0 }}
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <LockOutlinedIcon />
          </Avatar> */}
          <div style={{ fontSize: '22px', padding: '8px' }}>
            <strong>Build Your Own Library</strong>
          </div>
          {/* <OrderSummary cart={cart}></OrderSummary> */}
          <div style={{ fontSize: '14px' }} className='text-center'>
            Free Shipping on all Boxes
          </div>
        </div>

        <>
          <form onSubmit={submitHandler} className='cart-form'>
            <Form.Text className='red-text'>
              {emailError && <span>{emailError}</span>}
            </Form.Text>
            <TextField
              id='outlined-basic'
              label='Email'
              variant='outlined'
              required
              type='Email'
              autoComplete='off'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <TextField
              id='outlined-basic'
              label='Full Name'
              variant='outlined'
              required
              type='Text'
              autoComplete='off'
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />

            <TextField
              id='outlined-basic'
              label='Address'
              variant='outlined'
              required
              type='Text'
              autoComplete='off'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <TextField
              id='outlined-basic'
              label='Apartment, Suite, etc (Optional)'
              variant='outlined'
              type='Text'
              autoComplete='off'
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <TextField
              id='outlined-basic'
              label='City'
              variant='outlined'
              type='Text'
              required
              autoComplete='off'
              value={city}
              onChange={(e) => setCity(e.target.value)}
              style={{ width: '48%', marginBottom: '10px' }}
            />

            <TextField
              id='outlined-basic'
              label='Zip Code'
              variant='outlined'
              type='Number'
              required
              autoComplete='off'
              error={zipError ? true : false}
              helperText={zipError ? zipError : ''}
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              style={{ width: '48%', marginBottom: '10px', marginLeft: '10px' }}
            />

            <Autocomplete
              options={SHIPPING_STATE}
              getOptionLabel={(option) => option}
              value={state}
              onChange={(event, newValue) => {
                setState(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='State'
                  variant='outlined'
                  fullWidth
                  required
                />
              )}
            />

            <TextField
              id='outlined-basic'
              label='Phone Number'
              variant='outlined'
              type='phone'
              margin='normal'
              autoComplete='off'
              hidden={phone && phone.length == 10 ? true : false}
              error={phoneError ? true : false}
              required
              helperText={phoneError ? phoneError : ''}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{ width: '100%' }}
            />
            <br />
            <Row
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
              className='text-center'
            >
              <Col>
                <Button
                  type='submit'
                  variant='contained'
                  className='theme-button'
                  disabled={disable}
                  fullWidth
                  sx={{ mt: 2, mb: 2 }}
                >
                  Continue
                </Button>
              </Col>
            </Row>
            <Row>
              <div style={{ fontSize: '20px' }} className='text-center'>
                READ LEAD SUCCEED
              </div>
            </Row>
            {/* <Row>
              <Col
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                className='text-center'
              >
                <Typography variant='body2' xs={{ mb: 2 }}>
                  <small>Already have an Account?</small>{' '}
                  <Link to='/login'>Login</Link> or{' '}
                  <Link to='/register'>Create Account</Link>
                </Typography>
                <br />
                <Typography
                  variant='body1'
                  xs={{ mb: 2 }}
                  style={{ color: '#005D6E' }}
                >
                  <a href='https://www.99bookscart.com/pages/return-refund'>
                    Return & Refund Policy
                  </a>
                  <br />
                  <a href='https://www.99bookscart.com/contact-us'>
                    Contact us
                  </a>
                  <br />
                  <Link
                    to='/pages/terms-and-condition'
                    aria-label='Terms and Condition'
                  >
                    Terms and Condition
                  </Link>{' '}
                  <br />
                  <Link to='/pages/privacy-policy' aria-label='Privacy Policy'>
                    Privacy Policy
                  </Link>{' '}
                  <br />
                </Typography>
              </Col>
            </Row> */}
            <br />
          </form>
          <br />
        </>
      </FormContainer>
    </Container>
  )
}

export default BasicInformationScreen
