import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Paginate = ({pages,page,isAdmin=false,keyword='',orders=false,users=false}) => {
    let pagesPage = 10
    let lb = Math.ceil(page/pagesPage)
    let lowerBound = (lb-1)*10
    let nexPage = lowerBound+10
    let prevPage = nexPage-20;
    let remainingPages = pages-page
    let limitPagesPage = remainingPages<9?lb+2:10
    return pages>1 && (
        <Pagination>
            {nexPage>10 && (<LinkContainer key={  prevPage+1 } to={ !isAdmin ? keyword ? `/search/${keyword}/page/${prevPage+1}`:`/page/${prevPage+1}`:users?`/admin/userlist/${prevPage+1}`:orders?`/admin/orderlist/${prevPage+1}/${keyword}`:`/admin/productlist/${prevPage+1}`}>
                <Pagination.Item>Prev</Pagination.Item></LinkContainer>)}
            {[...Array(limitPagesPage).keys()].map(x=>(
                <LinkContainer key={ (x+1) + lowerBound } to={ !isAdmin ? keyword ? `/search/${keyword}/page/${(x+1)+lowerBound}`:`/page/${(x+1)+lowerBound}`:users?`/admin/userlist/${(x+1)+lowerBound}`:orders?`/admin/orderlist/${(x+1)+lowerBound}/${keyword}`:`/admin/productlist/${(x+1)+lowerBound}`}>
                    <Pagination.Item active={(x+1)+lowerBound === page}>{(x+1)+lowerBound}</Pagination.Item>
                </LinkContainer>
                
            ))}
            {(pages>10 && limitPagesPage==10) && (<LinkContainer key={nexPage+1 } to={ !isAdmin ? keyword ? `/search/${keyword}/page/${nexPage+1}`:`/page/${nexPage+1}`:users?`/admin/userlist/${nexPage+1}`:orders?`/admin/orderlist/${nexPage+1}/${keyword}`:`/admin/productlist/${nexPage+1}`}>
                <Pagination.Item>Next</Pagination.Item></LinkContainer>)}
        </Pagination>
    )
}

export default Paginate
