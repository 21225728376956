import {
  FORM_ADD_FAIL,
  FORM_ADD_REQUEST,
  FORM_ADD_SUCCEES,
  FORM_ADD_RESET,
  FORM_LIST_REQUEST,
  FORM_LIST_SUCCEES,
} from '../constants/formConstants'

export const addToFormReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case FORM_ADD_REQUEST:
      return {
        loading: true,
      }
    case FORM_ADD_SUCCEES:
      return {
        loading: false,
        form: action.payload,
        success: true,
      }
    case FORM_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case FORM_ADD_RESET:
      return {}
    default:
      return state
  }
}

export const listContactFormReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case FORM_LIST_REQUEST:
      return {
        loading: true,
      }
    case FORM_LIST_SUCCEES:
      return {
        loading: false,
        submits: action.payload,
        success: true,
      }
    case FORM_LIST_SUCCEES:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
