import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import {
  Row,
  Col,
  Card,
  ListGroupItem,
  Modal,
  Container,
} from 'react-bootstrap'
import { register } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import SocialLogin from '../components/SocialLogin'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const SubNav = ({ history, redirect = '/app/home' }) => {
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()
  const userRegister = useSelector((state) => state.userRegister)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const location = useLocation()
  var url = ''
  if (userLoggedIn) {
    if (location.pathname.indexOf('us') > 0) {
      url = '/app/profile/us/billing'
    } else {
      url = '/app/profile/billing'
    }
  } else {
    if (location.pathname.indexOf('us') > 0) {
      url = '/plots-us'
    } else {
      url = '/plots'
    }
  }

  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({ name, email, password, isSocial: false, shorts: true })
      )
    }
  }
  const showMore = () => {
    setShow(true)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        if (location.pathname.indexOf('us') > 0) {
          history.push('/app/profile/us/billing')
        } else {
          history.push(redirect)
        }
      }
    }
  }, [dispatch, userInfo, registerSuccess, emailError])
  return (<></>
    // <Container style={{ marginBottom: '15px', marginTop: '10px' }}>
    //   <Row>
    //     <Col>
    //       <Link to={'/plots'}>
    //         <LazyLoadImage
    //           src={'../../images/logo.png'}
    //           width={'100%'}
    //           alt={'99bookscart logo'}
    //           className='siteLogo'
    //         />
    //       </Link>
    //     </Col>
    //     <Col>
    //       <div
    //         style={{ float: 'right', marginTop: '16px', marginRight: '8px' }}
    //       >
    //         <Button onClick={showMore}>Subscribe</Button>
    //       </div>
    //     </Col>
    //   </Row>
    //   <Modal show={show} onHide={handleClose} backdrop='static'>
    //     <Modal.Body>
    //       <Form onSubmit={submitHandler2}>
    //         <div>
    //           <Button className='close-button' onClick={handleClose}>
    //             X
    //           </Button>
    //         </div>
    //         <div style={{ padding: '20px' }}>
    //           <Form.Group>
    //             <div style={{ marginBottom: '20px', textAlign: 'center' }}>
    //               <LazyLoadImage
    //                 src={'../../images/logo.png'}
    //                 width={'100%'}
    //                 alt={'99bookscart logo'}
    //                 className='siteLogo'
    //               />
                  
    //               <br />
    //               <strong>Read Smart & Read Fast</strong>
    //               <div>Read Key Ideas from 1000+ Non Fiction Books!</div>
    //               <br />
    //               <div className='social-logins'>
    //                 <SocialLogin redirect={redirect} />
    //               </div>
    //             </div>
    //             <Form.Text className='red-text'>
    //               {errorMessage && <span>{errorMessage}</span>}
    //             </Form.Text>
    //             <Form.Control
    //               type='text'
    //               placeholder='Name'
    //               required
    //               value={name || ''}
    //               onChange={(e) => setName(e.target.value)}
    //             ></Form.Control>

    //             <Form.Text className='red-text'>
    //               {emailError && <span>{emailError}</span>}
    //             </Form.Text>

    //             <Form.Control
    //               type='email'
    //               placeholder='Email'
    //               required
    //               value={email || ''}
    //               onChange={(e) => setEmail(e.target.value)}
    //             ></Form.Control>
    //             <Form.Text className='red-text'>
    //               {passwordError && <span>{passwordError}</span>}
    //             </Form.Text>
    //             <Form.Control
    //               type='password'
    //               placeholder='Passsword'
    //               required
    //               value={password || ''}
    //               onChange={(e) => setPassword(e.target.value)}
    //             ></Form.Control>
    //           </Form.Group>
    //           <Row>
    //             <Col>
    //               <Button
    //                 type='submit'
    //                 variant='primary'
    //                 className='float-right'
    //               >
    //                 Sign Up
    //               </Button>
    //             </Col>
    //           </Row>
    //           <Row>
    //             <Col className='text-center'>
    //               Existing Customer? <Link to={'/login'}>Login</Link>
    //             </Col>
    //           </Row>
    //         </div>
    //       </Form>
    //     </Modal.Body>
    //   </Modal>
    // </Container>
  )
}

export default SubNav
