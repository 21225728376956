import React, { useState } from 'react'
import { Card, Modal, Image } from 'react-bootstrap'
import { Button, Tooltip, Typography } from '@mui/material'
import Rating from './Rating'
import { Link } from 'react-router-dom'
import { addToCart } from '../actions/cartActions.js'
import { useDispatch, useSelector } from 'react-redux'
import BoxProgressBar from './BoxProgressBar'
import { FaBoxOpen, FaInfoCircle } from 'react-icons/fa'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Paper from '@mui/material/Paper'
import InventoryIcon from '@mui/icons-material/Inventory'
const Product = ({ product, history, match, addtocart = true }) => {
  const dispatch = useDispatch()
  // const [show, setShow] = useState(false);
  const [disable, setDisable] = React.useState(false)
  const [addToCartMessage, setAddToCartMessage] = useState(false)
  const [messgae, setMessage] = useState('Add')
  const cart = useSelector((state) => state.cart)
  const { cartItems, cartSuccess } = cart
  const slug = convertToSlug(product.name)
  let shortlistedBook = false
  cartItems.map((item) => {
    if (item.product == product._id) {
      shortlistedBook = true
    }
  })

  const goToCartHandler = () => {
    history.push(`/cart`)
  }

  const addToCartHandler = (product) => {
    dispatch(addToCart(product._id, 1, 0, 0))
    setMessage('In Cart')
  }
  return (
    <>
      <Card className='rounded py-1' style={{ marginBottom: '2px' }}>
        <Link to={`/book/${product.isbn}/${slug}`} className='book-image'>
          <LazyLoadImage
            src={product.image}
            width={'100%'}
            alt={'second hand book ' + product.name}
            className='book-image'
            placeholderSrc={
              'https://s3-books-images.s3.ap-south-1.amazonaws.com/e130987d-d9e5-4567-9b62-0eb5cced1cec.png'
            }
            style={{
              backgroundColor: product.imageColor
                ? product.imageColor
                : '#EEEBED',
            }}
          />
        </Link>
        <Card.Body>
          <Link
            to={`/book/${product.isbn}/${slug}`}
            style={{ textDecoration: 'none' }}
          >
            <Card.Title as='div' className='book-title'>
              <span className='title-main'>{product.name.toLowerCase()}</span>
              <br />
              {product.grating > 0 && <Rating value={product.grating} />}
            </Card.Title>
          </Link>
          {product.type == 'New' && (
            <Card.Subtitle className='new-books text-center'>
              New Book
            </Card.Subtitle>
          )}

          {product.salePrice > 0 && (
            <Card.Text className='text-center'>
              <>
                {/* {(product.isBookscartBox || product.salePrice < 139) && (
                  <Tooltip title='Available for online book fair'>
                    <InventoryIcon
                      style={{
                        fontSize: '13px',
                        marginRight: '3px',
                        color: '#000',
                      }}
                      color='#000'
                    />
                  </Tooltip>
                )} */}
                <span className='productPrice'>
                  &#8377;{product.salePrice.toLocaleString()}
                </span>{' '}
                <small className='red-text'>
                  -{Math.round(100 - (product.salePrice / product.price) * 100)}
                  %
                </small>
              </>
              <br />
              {product.countInStock > 0 && (
                <Button
                  variant={messgae == 'In Cart' ? 'outline' : 'contained'}
                  className='add-to-c'
                  onClick={(e) => addToCartHandler(product)}
                >
                  {messgae}
                </Button>
              )}

              {addToCartMessage && (
                <>
                  <br />
                  <span className='addtocart-message'>
                    <Typography variant='subtitle' style={{ marginTop: '6px' }}>
                      Added
                    </Typography>
                  </span>
                  <br />
                </>
              )}
            </Card.Text>
          )}
        </Card.Body>
      </Card>
    </>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default Product
