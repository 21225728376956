import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import { register } from '../../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'
import SocialLogin from '../../../components/SocialLogin'
import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/plots/meta/HomeMeta'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import AdminNav from '../../../components/AdminNav'
import {
  listInventory,
  approveInventory,
} from '../../../actions/inventoryActions'
import BookPaginate from '../../../components/paginate/BookPaginate'
const SellerBooksScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [disable, setDisable] = React.useState(false)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const inventories = useSelector((state) => state.listInventory)
  const { loading, error, success, products, page, pages } = inventories

  const appInventories = useSelector((state) => state.approveInventory)
  const {
    loading: AppLoading,
    error: AppError,
    success: AppSuccess,
    result: AppResult,
  } = appInventories

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userLoggedIn) {
      dispatch(listInventory(pageNumber))
    }
  }, [dispatch, userLoggedIn, pageNumber])
  const approveBook = (id) => {
    dispatch(approveInventory(id))
  }
  return (
    <>
      <AdminNav></AdminNav>
      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          success && (
            <Container>
              <Row>
                {products.length > 0 &&
                  products.map(
                    (invent) =>
                      invent.product && (
                        <Row>
                          <Col xs={12} md={12}>
                            <Row>
                              <Col>
                                <LazyLoadImage
                                  src={
                                    invent.image
                                      ? invent.image
                                      : invent.product.image
                                  }
                                  width={'80px'}
                                  className='seller-book-image'
                                ></LazyLoadImage>
                              </Col>
                              <Col>
                                {invent.product && invent.product.name}
                                <div>
                                  Qty:{invent.qty}
                                  <br /> Price: {invent.salePrice} <br />
                                  condition: {invent.condition}
                                  <br />
                                  Sold By: {invent.store.storeName}
                                  <br />
                                  Verified:{' '}
                                  {invent.store.isVerified
                                    ? 'Verified'
                                    : 'Not Verified'}
                                  <br />
                                  {invent.status == 0 ? (
                                    <Button
                                      onClick={(e) => approveBook(invent._id)}
                                    >
                                      Approve
                                    </Button>
                                  ) : (
                                    <Button disabled>Approved</Button>
                                  )}
                                  {AppSuccess && <small>Done</small>}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                  )}
              </Row>
              <Row>
                <BookPaginate
                  pages={pages}
                  page={page}
                  category={'top-rated'}
                  url={'admin/seller-books'}
                ></BookPaginate>
              </Row>
            </Container>
          )
        )}
      </Container>
    </>
  )
}
{
}

export default SellerBooksScreen
