import React, { useState, useEffect } from 'react'
import { Table, Form, Button, Row, Col, Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { createNewBookFair } from '../actions/bookFairActions'
import { listMyOrders } from '../actions/orderActions'
import { myOrderDetailsReducer } from '../reducers/orderReducers'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { scanProduct } from '../actions/productActions'
import { createNewBookFairEntry } from '../actions/bookFairEntryActions'
import { CREATE_BOOK_FAIR_ENTRY_RESET } from '../constants/bookFairEntryConstants'
const BookFairEntryNewScreen = ({ location, history, match }) => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [boxType, setBoxType] = useState('')
  const [lineItems, setLineItems] = useState([])
  const [note, setNote] = useState('')
  const [amount, setAmount] = useState('')

  const [isbn, setIsbn] = useState('')
  const [orderTotal, setOrderTotal] = useState(0)
  const [noBooks, setNoBooks] = useState(0)
  const [source, setSource] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [newEntryMessage, setNewEntryMessage] = useState('')

  const dispatch = useDispatch()

  const createBookfair = useSelector((state) => state.createBookFair)
  const { loading, error, bookfair, success } = createBookfair

  const productScan = useSelector((state) => state.productScan)
  const {
    loading: loadinScan,
    success: successScan,
    error: errorScan,
    product,
  } = productScan

  const createBookFairEntry = useSelector((state) => state.createBookFairEntry)
  const {
    loading: loadingBookFairEntry,
    error: errorBookFairEntry,
    bookFairEntry,
    success: successBookFairEntry,
  } = createBookFairEntry

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  var orderTotalN = 0
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (success) {
        history.push('/admin/BookFairList')
      }
    }

    if (isbn.length > 12) {
      dispatch(scanProduct(isbn))
    }
    if (successBookFairEntry) {
      setNewEntryMessage('Added Successfully')
      dispatch({ type: CREATE_BOOK_FAIR_ENTRY_RESET })
      setName('')
      setPhone('')
      setBoxType(-1)
      setPaymentMethod(-1)
      setNote('')
      setSource('')
    }
    if (successScan) {
      var item = {
        isbn: product.book.isbn,
        price: product.book.salePrice,
        qty: 1,
        name: product.book.name,
      }
      var index = lineItems.findIndex((x) => x.isbn == product.book.isbn)
      if (index === -1) {
        lineItems.push(item)
        if (boxType !== '3' && boxType !== '4') {
          setOrderTotal(orderTotal + item.price)
        }
      }
      setIsbn('')
    } else {
      if (boxType == '0') {
        setOrderTotal(1199)
      } else if (boxType == '1') {
        setOrderTotal(1799)
      } else if (boxType == '2') {
        setOrderTotal(2299)
      } else if (boxType == '3') {
        setOrderTotal(999)
      } else if (boxType == '4') {
        setOrderTotal(1499)
      } else if (boxType == '5') {
        setOrderTotal(amount)
      }
    }
  }, [
    dispatch,
    history,
    userInfo,
    boxType,
    isbn,
    lineItems,
    successScan,
    product,
    orderTotal,
    orderTotalN,
    successBookFairEntry,
    amount,
  ])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      createNewBookFairEntry({
        name: name,
        phone: phone,
        boxtype: boxType,
        paymentType: paymentMethod,
        orderTotal: orderTotal,
        lineItems: lineItems,
        source: source,
        bookfair: match.params.id,
      })
    )
  }

  return (
    <Container>
      <Row className='login-register'>
        <Form onSubmit={submitHandler}>
          <Col xs={12}>
            <h2>New Customer</h2>

            {newEntryMessage && (
              <Message variant='success'>{newEntryMessage}</Message>
            )}
            {error && <Message variant='danger'>{error}</Message>}
            {success && (
              <Message variant='success'>UPDATED sUCCESSFULLY</Message>
            )}
            {loading && <Loader></Loader>}

            {/* <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group> */}
            <Form.Group controlId='startDate'>
              {/* <Form.Label>Phone</Form.Label> */}
              <Form.Control
                type='String'
                placeholder='Enter Phone Number'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='boxtype'>
              {/* <Form.Label>BoxType</Form.Label> */}
              <Form.Control
                as='select'
                aria-label='Select Box Type'
                onChange={(e) => setBoxType(e.target.value)}
              >
                <option value='-1'>Select Type of Box</option>
                <option value='0'>Small</option>
                <option value='1'>Medium</option>
                <option value='2'>Large</option>
                <option value='3'>9/999</option>
                <option value='4'>9/1499</option>
                <option value='5'>Custom</option>
              </Form.Control>
            </Form.Group>
            {boxType == 5 && (
              <Form.Control
                type='text'
                placeholder='Enter Amount'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              ></Form.Control>
            )}

            <Form.Group controlId='paymentType'>
              {/* <Form.Label>Payment Type</Form.Label> */}
              <Form.Control
                as='select'
                aria-label='Select Payment Type'
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <option value='-1'>Select Payment Type</option>
                <option value='0'>Cash</option>
                <option value='1'>UPI</option>
                <option value='2'>CARD</option>
              </Form.Control>
            </Form.Group>
            {/* <Form.Group controlId='startDate'>
              <Form.Label>Scan ISBN</Form.Label>
              <Form.Control
                type='String'
                placeholder='Scan Isbn'
                value={isbn}
                onChange={(e) => setIsbn(e.target.value)}
              ></Form.Control>
            </Form.Group> */}
            <Form.Control
              type='text'
              placeholder='Enter Source'
              value={source}
              onChange={(e) => setSource(e.target.value)}
            ></Form.Control>
            <Form.Control
              type='text'
              placeholder='Enter Note'
              value={note}
              onChange={(e) => setNote(e.target.value)}
            ></Form.Control>
            {lineItems.length > 0 &&
              lineItems.map((item) => (
                <small key={item.isbn}>
                  {item.name} {'->'} {item.price}
                  <br />
                </small>
              ))}
            <br />
            <Link to={`/admin/bookfair/${match.params.id}`}>
              <Button type='button' varaint='primary'>
                Back
              </Button>
            </Link>

            <div className='float-right'>
              Total: ₹{orderTotal}
              <br />
              <Button type='submit' varaint='primary'>
                Create New
              </Button>
            </div>
          </Col>
        </Form>
      </Row>
    </Container>
  )
}

export default BookFairEntryNewScreen
