import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Form, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserCoins } from '../actions/coinActions'
import Paginate from '../components/Paginate'
import { MdAddAlert } from 'react-icons/md'
import { BsBoxSeam, BsCoin } from 'react-icons/bs'
import { FaTruck, FaCreditCard } from 'react-icons/fa'
import {
  reedemDiscountCode,
  listUserDiscountCode,
} from '../actions/discountCodeActions'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

const UserCoinListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userCoins = useSelector((state) => state.userCoin)
  const { loading, error, coins } = userCoins

  const reedemDiscount = useSelector((state) => state.reedemDiscount)
  const { success: successDiscountCode, discountCode } = reedemDiscount

  const listUserDiscounts = useSelector((state) => state.listUserDiscounts)
  const { success: successListUserDiscountCode, discounts } = listUserDiscounts

  var getCoinCount = 0
  if (!loading) {
    getCoinCount = coins.reduce((x, y) => y.status == 0 && x + y.coins, 0)
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserCoins())
      dispatch(listUserDiscountCode())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])
  const redeemCoins = () => {
    dispatch(reedemDiscountCode())
  }
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          <Row>
            <div role='presentation'>
              <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
                <Link underline='hover' color='inherit' to={'/profile'}>
                  Profile
                </Link>
                <Typography color='text.primary'>Coins</Typography>
              </Breadcrumbs>
            </div>
          </Row>

          {getCoinCount > 0 && !successDiscountCode && (
            <Row>
              <Col xs={12} md={6}>
                <Typography variant='h6'>
                  Total coins earned {getCoinCount}
                </Typography>
                <p>&#8377;{getCoinCount * 2} OFF</p>
              </Col>
              <Col xs={12} md={6}>
                <Button
                  variant='contained'
                  onClick={() => redeemCoins()}
                  disabled
                >
                  Reedem Coins
                </Button>
              </Col>
            </Row>
          )}
          {successDiscountCode && (
            <p>
              Your discount Code <strong>{discountCode.name}</strong>
            </p>
          )}
          <br />
          {/* {successListUserDiscountCode && (
            <>
              <h1>Discounts</h1>
              {discounts.length == 0 && (
                <Message>No Discount available yet!</Message>
              )}
              {discounts.map((discount) => (
                <Row key={discount._id}>
                  <Col xs={12}>
                    Code <strong>{discount.name}</strong> for &#8377;{' '}
                    {discount.value}{' '}
                    {discount.status == 0 ? <p>Active</p> : <p>Used</p>}
                  </Col>
                </Row>
              ))}
            </>
          )} */}
          {coins.length == 0 && (
            <Message>No Coins! Place Orders to Earn Coins</Message>
          )}
          {coins.map((coin) =>
            coin.order ? (
              <>
                <Card
                  sx={{ minWidth: 275 }}
                  variant='outlined'
                  key={coin._id}
                  style={{ marginBottom: '6px' }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color='text.secondary'
                      gutterBottom
                    >
                      {coin.createdAt && coin.createdAt.substring(0, 10)}
                    </Typography>
                    <Typography variant='h6' component='div'>
                      Earned <strong>{coin.coins}</strong> coins{' '}
                      <BsCoin></BsCoin>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      on order {coin.order && coin.order.orderName}
                    </Typography>
                    <Typography variant='body2'>
                      {coin.order && coin.order.orderTotal}
                    </Typography>
                  </CardContent>
                  <CardActions style={{ float: 'right' }}>
                    {coin.status == 0 ? <p>Active</p> : <p>Inactive</p>}
                  </CardActions>
                </Card>
              </>
            ) : (
              <Message>Place orders to earn Coins</Message>
            )
          )}
        </Container>
      )}
    </Container>
  )
}

export default UserCoinListScreen
