import axios from 'axios'
import { AI_FAIL, AI_REQUEST, AI_SUCCEES } from '../constants/aiConstants'
import {
  BLOG_CREATET_SUCCESS,
  BLOG_CREATE_FAIL,
  BLOG_CREATE_REQUEST,
  BLOG_GET_REQUEST,
  BLOG_GET_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
} from '../constants/blogConstants'
import {
  STORE_ADD_BOOK_FAIL,
  STORE_ADD_BOOK_REQUEST,
  STORE_ADD_BOOK_SUCCESS,
  STORE_ADMIN_LIST_FAIL,
  STORE_ADMIN_LIST_REQUEST,
  STORE_ADMIN_LIST_SUCCESS,
  STORE_CREATET_SUCCESS,
  STORE_CREATE_FAIL,
  STORE_CREATE_REQUEST,
  STORE_GET_BYID_FAIL,
  STORE_GET_BYID_REQUEST,
  STORE_GET_BYID_SUCCESS,
  STORE_GET_FAIL,
  STORE_GET_REQUEST,
  STORE_GET_SUCCESS,
} from '../constants/storeConstant'

export const createStore = (store) => async (dispatch, getState) => {
  try {
    dispatch({ type: STORE_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/store`, store, config)
    dispatch({
      type: STORE_CREATET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: STORE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listBlog = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_LIST_REQUEST })

    const { data } = await axios.get(`/api/blog`)
    dispatch({
      type: BLOG_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BLOG_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getStore = () => async (dispatch, getState) => {
  try {
    dispatch({ type: STORE_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/store`, config)
    dispatch({
      type: STORE_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: STORE_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addStoreBook = (book) => async (dispatch, getState) => {
  try {
    dispatch({ type: STORE_ADD_BOOK_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/store/add/book`, book, config)
    dispatch({
      type: STORE_ADD_BOOK_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: STORE_ADD_BOOK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getStoreById = (id, pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: STORE_GET_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    if (userInfo) {
      var config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      var { data } = await axios.get(
        `/api/store/admin/${id}?pageNumber=${pageNumber}`,
        config
      )
    } else {
      var config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      var { data } = await axios.get(
        `/api/store/${id}?pageNumber=${pageNumber}`,
        config
      )
    }

    dispatch({
      type: STORE_GET_BYID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: STORE_GET_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAdminStores = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: STORE_ADMIN_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(
      `/api/store/list?pageNumber=${pageNumber}`,
      config
    )
    dispatch({
      type: STORE_ADMIN_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: STORE_ADMIN_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const onBoardStore = (id, user) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/store/onboard/${id}/${user}`, config)
  } catch (error) {}
}
