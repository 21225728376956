import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Row, Col, Card, ListGroupItem } from 'react-bootstrap'
const SearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      history.push(`/search/${keyword}/${true}`)
    } else {
      history.push('/')
    }
  }
  return (
    <Form onSubmit={submitHandler} inline>
      <Row className='text-center'>
        <Col xs={10}>
          <Form.Control
            type='text'
            name='q'
            onChange={(e) => setKeyword(e.target.value)}
            placeholder='Title,Author & ISBN'
            className='mr-sm-2 ml-sm-5'
          ></Form.Control>
        </Col>
        <Col xs={2}>
          <Button
            type='submit'
            variant='outline-success'
            className='search-button-mobile'
          >
            <i className='fas fa-search'></i>
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default SearchBox
