import React from 'react'
import PropTypes from 'prop-types'
import { propTypes } from 'react-bootstrap/esm/Image'

const Rating = ({ value, reviews, text, colorr = 'FFA500' }) => {
  const color = '#FFA500'
  return (
    <>
      <span className='rating'>
        <i
          style={{ color }}
          className={
            value >= 1
              ? 'fas fa-star'
              : value >= 0.5
              ? 'fas fa-star-half-alt'
              : 'far fa-star'
          }
        ></i>
        <i
          style={{ color }}
          className={
            value >= 2
              ? 'fas fa-star'
              : value >= 1.5
              ? 'fas fa-star-half-alt'
              : 'far fa-star'
          }
        ></i>
        <i
          style={{ color }}
          className={
            value >= 3
              ? 'fas fa-star'
              : value >= 2.5
              ? 'fas fa-star-half-alt'
              : 'far fa-star'
          }
        ></i>
        <i
          style={{ color }}
          className={
            value >= 4
              ? 'fas fa-star'
              : value >= 3.5
              ? 'fas fa-star-half-alt'
              : 'far fa-star'
          }
        ></i>
        <i
          style={{ color }}
          className={
            value >= 5
              ? 'fas fa-star'
              : value >= 4.5
              ? 'fas fa-star-half-alt'
              : 'far fa-star'
          }
        ></i>
      </span>{' '}
      {/* <small style={{ color: 'black' }}>{value && value}</small> */}
      {/* {reviews && reviews.toLocaleString()` Reviews`} */}
    </>
  )
}

Rating.defaultProps = {
  color: '#FBC40E',
}

Rating.prototype = {
  value: propTypes.number,
  text: propTypes.string,
  color: propTypes.string,
}
export default Rating
