export const FLOW_LIST_REQUEST = 'FLOW_LIST_REQUEST'
export const FLOW_LIST_SUCCESS = 'FLOW_LIST_SUCCESS'
export const FLOW_LIST_FAIL = 'FLOW_LIST_FAIL'

export const FLOW_DETAIL_REQUEST = 'FLOW_DETAIL_REQUEST'
export const FLOW_DETAIL_SUCCESS = 'FLOW_DETAIL_SUCCESS'
export const FLOW_DETAIL_FAIL = 'FLOW_DETAIL_FAIL'

export const FLOW_CREATE_REQUEST = 'FLOW_CREATE_REQUEST'
export const FLOW_CREATE_SUCCESS = 'FLOW_CREATE_SUCCESS'
export const FLOW_CREATE_FAIL = 'FLOW_CREATE_FAIL'

export const FLOW_DELETE_REQUEST = 'FLOW_DELETE_REQUEST'
export const FLOW_DELETE_SUCCESS = 'FLOW_DELETE_SUCCESS'
export const FLOW_DELETE_FAIL = 'FLOW_DELETE_FAIL'

export const FLOW_DETAIL_CREATE_REQUEST = 'FLOW_DETAIL_CREATE_REQUEST'
export const FLOW_DETAIL_CREATE_SUCCESS = 'FLOW_DETAIL_CREATE_SUCCESS'
export const FLOW_DETAIL_CREATE_FAIL = 'FLOW_DETAIL_CREATE_FAIL'

export const FLOW_DETAIL_DELETE_REQUEST = 'FLOW_DETAIL_DELETE_REQUEST'
export const FLOW_DETAIL_DELETE_SUCCESS = 'FLOW_DETAIL_DELETE_SUCCESS'
export const FLOW_DETAIL_DELETE_FAIL = 'FLOW_DETAIL_DELETE_FAIL'