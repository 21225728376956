import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Form,
  FormGroup,
  Modal,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'
import { convertToSlug } from '../../../helpers/helpers'
import AdminNav from '../../../components/AdminNav'
import {
  getOrderDetails,
  deliverOrder,
  cancelOrder,
  confirmOrder,
  createOrderComment,
  addTagtoOrder,
  currateBox,
} from '../../../actions/orderActions'
import {
  createShipment,
  assignAwb,
  getShipmentDetails,
} from '../../../actions/shipmentActions'
import {
  ORDER_COMMENT_RESET,
  ORDER_DELEVERED_RESET,
} from '../../../constants/orderConstants'

import AIBoxAttributes from '../../../components/AIBoxAttributes'

const SellerOrderScreen = ({ match, history }) => {
  const orderId = match.params.id
  const razorId = match.params.razid

  const dispatch = useDispatch()
  const [height, setHeight] = useState(false)
  const [weight, setWeight] = useState(false)
  const [length, setLength] = useState(false)
  const [width, setWidth] = useState(false)
  const [flag1, SetFlag1] = useState(false)
  const [show, setShow] = useState(false)
  const [tLink, setTLink] = useState('')
  const [courierId, setCourierId] = useState('')
  const [shipmentId, setShipmentId] = useState('')
  const [showDimensions, setShowDimensions] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const [couriers, setCouriers] = useState([])
  const [tags, setTags] = useState([])

  const [showAvailableCourier, setShowAvailableCourier] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleCloseDimension = () => setShowDimensions(false)
  const handleCloseAvailableCourier = () => setShowAvailableCourier(false)

  const [comment, setComment] = useState('')
  const orderDetails = useSelector((state) => state.orderDetails)

  const {
    order,
    loading,
    error,
    success,
    existingOrders,
    countOrders,
    shippingLabels,
  } = orderDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver

  const orderPayStatus = useSelector((state) => state.orderPayStatus)
  const { loading: orderPayStatusLoading } = orderPayStatus

  const currateBoxRes = useSelector((state) => state.currateBox)
  const { success: currateBoxSuccesss } = currateBoxRes

  const orderComments = useSelector((state) => state.orderCommentList)
  const {
    success: successComment,
    error: errorComments,
    comments,
  } = orderComments

  const shipmentCreate = useSelector((state) => state.shipmentCreate)
  const {
    success: successShipment,
    error: errorShipment,
    loading: loadingShipment,
    shipments,
    shipmentId: sellersid,
  } = shipmentCreate

  const assignAWB = useSelector((state) => state.assignAWB)
  const {
    success: successIShipment,
    error: errorIShipment,
    loading: loadingIShipment,
    shipment: iShipment,
  } = assignAWB

  const getShipment = useSelector((state) => state.getShipment)
  const {
    success: successOrderShipment,
    error: errorOrderShipment,
    loading: loadingOrderShipment,
    shipment: OrderShipment,
  } = getShipment

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userInfo) {
      history.push('/login')
    }
    if (
      (!order ||
        order._id !== orderId ||
        successDeliver ||
        orderPayStatusLoading) &&
      !loading &&
      !flag1
    ) {
      dispatch(getOrderDetails(orderId, 1))

      // dispatch(getShipmentDetails(orderId))
      if (successDeliver) {
        dispatch({ type: ORDER_DELEVERED_RESET })
      }
    }
    if (successComment) {
      setComment('')
      dispatch({ type: ORDER_COMMENT_RESET })
    }
    if (currateBoxSuccesss) {
      window.location.reload(false)
    }
    if (successShipment) {
      setShipmentId(sellersid)
      setShowDimensions(false)
      setShowAvailableCourier(true)
      //window.location.reload(false)
    }
    if (successIShipment) {
      window.location.reload(false)
    }
  }, [
    dispatch,
    order,
    history,
    orderId,
    successDeliver,
    orderPayStatusLoading,
    loading,
    successComment,
    successShipment,
    successIShipment,
    currateBoxSuccesss,
  ])
  const addTags = () => {
    dispatch(addTagtoOrder(tags, order._id))
    lineItems.push(tags)
    setTags('')
  }
  const removeTags = (item, e) => {
    e.preventDefault()
    const index = lineItems.indexOf(item)
    if (index > -1) {
      lineItems.splice(index, 1)
    }
    setLineItems(lineItems)
  }
  const deliverHandler = () => {
    dispatch(deliverOrder(order, tLink))
    setShow(false)
    setTLink('')
  }

  const commentHandler = (e) => {
    e.preventDefault()
    dispatch(
      createOrderComment(match.params.id, {
        comment,
      })
    )
  }
  const currateBoxHandler = () => {
    dispatch(currateBox(order._id))
  }

  const generateShipment = () => {
    setShowDimensions(true)
  }

  const createShipmentHandler = (e) => {
    e.preventDefault()
    dispatch(createShipment(order._id, weight, height, width, length, true))
  }
  const genLable = () => {
    dispatch(assignAwb(shipments[0].courier_company_id, shipmentId, order._id))
  }

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Container>
          <Row>
            <Col>
              <h4>Order</h4>
            </Col>
            <Col>
              <Link to='/seller/orders' className='btn btn-light float-right'>
                Go back
              </Link>
            </Col>
          </Row>
          <Row className='print-test'>
            <Col md={8}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <p>
                    <strong>{order.orderName}</strong>
                    <br />
                    <strong>Name: </strong>{' '}
                    {order.user ? order.user.name : order.name}
                    <br />
                    <small>
                      {order.codPrice == 0 || order.isConfirmed || true ? (
                        <div>
                          Confirmed by buyer
                          <br />
                          {shippingLabels && shippingLabels.length > 0 ? (
                            shippingLabels.map(
                              (label) =>
                                label.shiprocketLabelURL && (
                                  <a
                                    key={label.shiprocketLabelURL}
                                    href={label.shiprocketLabelURL}
                                    target='_blank'
                                  >
                                    <Button>Download Shipping Label</Button>
                                  </a>
                                )
                            )
                          ) : (
                            <Button onClick={generateShipment}>
                              Generate Shipping Label
                            </Button>
                          )}
                        </div>
                      ) : (
                        'Not Confirmed by buyer'
                      )}
                    </small>
                    {order.isCancel ? 'CANCELLED' : order.status}
                    <br />
                    {order.isFufilled && (
                      <a href={`${order.trackingLink}`} target='_blank'>
                        Track order
                      </a>
                    )}
                  </p>
                  {/* <p><strong>Email:</strong><a href={`mailto:${order.user.email}`}>{order.user.email}</a></p> */}
                  <p className='text-transform'>
                    {/* <strong>Address: </strong>
                    {order.shippingAddress.address},
                    {order.shippingAddress.address2},<br />
                    <strong>City: </strong> {order.shippingAddress.city}
                    <br />
                    <strong>State: </strong> {order.shippingAddress.state}
                    <br />
                    <strong>Zip Code: </strong>{' '}
                    {order.shippingAddress.postalCode}
                    <br />
                    <strong>Phone: </strong> {order.shippingAddress.phone} */}
                  </p>
                </ListGroup.Item>
                {/* {userInfo.isAdmin && (
                  <ListGroup.Item>
                    <Form.Group className='mb-3' controlId='tags'>
                      <Form.Label>Tags</Form.Label>
                      <Row>
                        <Col xs={12}>
                          <Form.Control
                            type='text'
                            placeholder='Tags'
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                          />
                        </Col>
                        <Col xs={12}>
                          <Button
                            variant='primary'
                            className='mb-10'
                            style={{ float: 'left' }}
                            onClick={addTags}
                          >
                            Add Tags
                          </Button>
                        </Col>
                      </Row>

                      {lineItems.length > 0 && (
                        <div>
                          {lineItems.map((item) => (
                            <small className='topic' key={item}>
                              {item}{' '}
                              <Link to='' onClick={(e) => removeTags(item, e)}>
                                X
                              </Link>
                            </small>
                          ))}
                        </div>
                      )}
                    </Form.Group>
                    <Form onSubmit={commentHandler}>
                      <FormGroup controlId='comment'>
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as='textarea'
                          value={comment}
                          row='3'
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </FormGroup>
                      <Button type='submit' variant='primary'>
                        Comment
                      </Button>
                    </Form>
                    <h5>Comments</h5>
                    {order.comments && order.comments.length == 0 && (
                      <Message>No Comments</Message>
                    )}
                    <ListGroup varaint='flush'>
                      {order.comments &&
                        order.comments.reverse().map((comment, index) => (
                          <ListGroup.Item key={index}>
                            <Card>
                              <Card.Body>
                                <Card.Title>{comment.name}</Card.Title>
                                <Card.Text>
                                  at{' '}
                                  {comment.commentedAt &&
                                    comment.commentedAt.substring(19, 0)}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </ListGroup.Item>
                )} */}
                <ListGroup.Item>
                  <h5>Order Items</h5>
                  {order.orderItems.length == 0 ? (
                    <Message>Order is Empty</Message>
                  ) : (
                    <ListGroup variant='flush'>
                      {order.orderItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={2} xs={2}>
                              <Image
                                src={item.image}
                                alt={item.name}
                                fluid
                                rounded
                                className='orderImage'
                              />
                            </Col>
                            <Col m={5}>
                              <Link
                                to={`/book/${item.isbn}/${convertToSlug(
                                  item.name
                                )}`}
                              >
                                {item.name}{' '}
                                {item.variantName &&
                                  '(' + item.variantName + ')'}
                              </Link>
                              <br />

                              {item.isbn == 'AIBOX' && order.cartData && (
                                <AIBoxAttributes
                                  history={history}
                                  cart={order}
                                ></AIBoxAttributes>
                              )}
                            </Col>
                            <Col md={2} xs={2}>
                              <span className='productPrice'>
                                &#8377;{item.salePrice}
                              </span>
                              <br />
                              <strike>&#8377;{item.price}</strike>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant='flash'>
                  <ListGroupItem>
                    <ListGroup.Item>
                      <h5>Payment Method</h5>
                      <p>{order.codPrice > 0 ? 'Cash On Delivery' : 'Paid'}</p>
                    </ListGroup.Item>
                  </ListGroupItem>
                </ListGroup>

                {userInfo.isAdmin && successOrderShipment && (
                  <ListGroup.Item>
                    {order.isFufilled ? (
                      <Message variant='success'>
                        Fulfilled on:{order.fulfilledAt.substring(0, 10)}
                      </Message>
                    ) : (
                      <Message variant='danger'>Not Fulfilled</Message>
                    )}
                    <ListGroup>
                      {OrderShipment.map((shipment, index) => (
                        <ListGroup.Item key={index}>
                          AWB: {shipment.awb}
                          <br />
                          Frieght Charge: {shipment.totalCharge}
                          <br />
                          COD Charges: {shipment.codCharge}
                          <br />
                          Courier: {shipment.courierName}
                          <br />
                          Shipping Label:{' '}
                          <a href={shipment.shiprocketLabelURL} target='_blank'>
                            Download
                          </a>
                          <br />
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </ListGroup.Item>
                )}
              </Card>
            </Col>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Enter Tracking Link</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={deliverHandler}>
                  <FormGroup controlId='comment'>
                    <Form.Label>Tracking</Form.Label>
                    <Form.Control
                      as='textarea'
                      value={tLink}
                      row='3'
                      onChange={(e) => setTLink(e.target.value)}
                      required
                    ></Form.Control>
                  </FormGroup>
                  <Button type='submit' variant='primary'>
                    Fullfill Order
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </Row>

          <Modal show={showDimensions} onHide={handleCloseDimension}>
            <Row>
              <Col className='text-center'>
                <br />

                <h1>Enter dimensions & weight of the package</h1>
                <br />
              </Col>
            </Row>
            <Modal.Body>
              <Container>
                <Form onSubmit={createShipmentHandler} className='cart-form'>
                  <Form.Group>
                    <Form.Label>Weight in kg</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Weight'
                      required
                      value={weight || ''}
                      onChange={(e) => setWeight(e.target.value)}
                    ></Form.Control>
                    <Form.Label>Length in cm</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Length'
                      required
                      value={length || ''}
                      onChange={(e) => setLength(e.target.value)}
                    ></Form.Control>
                    <Form.Label>Width in cm</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Width'
                      required
                      value={width || ''}
                      onChange={(e) => setWidth(e.target.value)}
                    ></Form.Control>
                    <Form.Label>Height in cm</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Height'
                      required
                      value={height || ''}
                      onChange={(e) => setHeight(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Button
                    type='submit'
                    variant='primary'
                    className='float-right'
                  >
                    Generate Shipment
                  </Button>
                </Form>
                <Button
                  variant='secondary'
                  onClick={handleCloseDimension}
                  className='center-link'
                >
                  Close
                </Button>
              </Container>
            </Modal.Body>
          </Modal>
          <Modal show={showAvailableCourier} onHide={handleCloseDimension}>
            <Modal.Body>
              <Container style={{ padding: '20px' }}>
                {loadingShipment ? (
                  <Loader></Loader>
                ) : errorShipment ? (
                  <Message>{errorShipment}</Message>
                ) : shipments && shipments.length > 0 ? (
                  <>
                    <div>Service Available</div>
                    <Button
                      onClick={genLable}
                      variant='primary'
                      className='float-right'
                    >
                      Generate Label
                    </Button>
                  </>
                ) : (
                  <Container>
                    <div className='text-center'>No Service Available</div>
                  </Container>
                )}
                <Button
                  variant='secondary'
                  onClick={handleCloseAvailableCourier}
                  className='center-link'
                >
                  Close
                </Button>
              </Container>
            </Modal.Body>
          </Modal>
        </Container>
      )}
    </Container>
  )
}

export default SellerOrderScreen
