import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_RESET,
  MY_ORDERS_DETAILS_REQUEST,
  MY_ORDERS_DETAILS_FAIL,
  MY_ORDERS_DETAILS_SUCCESS,
  MY_ORDERS_DETAILS_RESET,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_RESET,
  ORDER_LIST_REQUEST,
  ORDER_DELEVERED_REQUEST,
  ORDER_DELEVERED_SUCCESS,
  ORDER_DELEVERED_FAIL,
  ORDER_DELEVERED_RESET,
  ORDER_PAYMENT_CREATE_REQUEST,
  ORDER_PAYMENT_CREATE_SUCCESS,
  ORDER_PAYMENT_CREATE_FAIL,
  ORDER_CREATE_RESET,
  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_CANCEL_RESET,
  ORDER_CONFIRM_REQUEST,
  ORDER_CONFIRM_SUCCESS,
  ORDER_CONFIRM_FAIL,
  ORDER_CONFIRM_RESET,
  ORDER_COMMENT_REQUEST,
  ORDER_COMMENT_SUCCESS,
  ORDER_COMMENT_RESET,
  ORDER_COMMENT_FAIL,
  ORDER_ADMIN_REQUEST,
  ORDER_ADMIN_SUCCESS,
  ORDER_ADMIN_FAIL,
  ORDER_CURRATE_REQUEST,
  ORDER_CURRATE_SUCCESS,
  ORDER_CURRATE_FAIL,
  SELLER_ORDER_LIST_REQUEST,
  SELLER_ORDER_LIST_SUCCESS,
  SELLER_ORDER_LIST_FAIL,
  ORDER_NLIST_REQUEST,
  ORDER_NLIST_SUCCESS,
  ORDER_NLIST_FAIL,
  ORDER_OTP_REQUEST,
  ORDER_OTP_SUCCESS,
  ORDER_OTP_FAIL,
  ORDER_TRACK_REQUEST,
  ORDER_TRACK_SUCCESS,
  ORDER_TRACK_FAIL,
} from '../constants/orderConstants'

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        loading: true,
      }
    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload.order,
      }
    case ORDER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_CREATE_RESET:
      return {}
    default:
      return state
  }
}
export const orderDetailsReducer = (
  state = {
    order: { comments: [], orderItems: [], shippingAddress: {}, loading: true },
    countOrders: 0,
    existingOrders: [],
    coin: 0,
    success: false,
  },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload.order,
        existingOrders: action.payload.existingOrders,
        countOrders: action.payload.countOrders,
        coin: action.payload.coin,
        shippingLabels: action.payload.shippingLabels,
        messages: action.payload.messages ? action.payload.messages : [],
        success: true,
      }
    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const orderPayReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case ORDER_PAY_REQUEST:
      return {
        loading: true,
      }
    case ORDER_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
      }
    case ORDER_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_PAY_RESET:
      return {}
    default:
      return state
  }
}

export const orderListMyReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case MY_ORDERS_DETAILS_REQUEST:
      return {
        loading: true,
      }
    case MY_ORDERS_DETAILS_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case MY_ORDERS_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case MY_ORDERS_DETAILS_RESET:
      return { orders: [] }
    default:
      return state
  }
}

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        loading: true,
      }
    case ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case ORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const orderDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELEVERED_REQUEST:
      return {
        loading: true,
      }
    case ORDER_DELEVERED_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case ORDER_DELEVERED_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_DELEVERED_RESET:
      return {}
    default:
      return state
  }
}
export const orderCancelReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CANCEL_REQUEST:
      return {
        loading: true,
      }
    case ORDER_CANCEL_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case ORDER_CANCEL_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_CANCEL_RESET:
      return {}
    default:
      return state
  }
}
export const orderConfirmReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CONFIRM_REQUEST:
      return {
        loading: true,
      }
    case ORDER_CONFIRM_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case ORDER_CONFIRM_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_CONFIRM_RESET:
      return {}
    default:
      return state
  }
}
export const payOrderReducer = (state = { orderPayDetails: [] }, action) => {
  switch (action.type) {
    case ORDER_PAYMENT_CREATE_REQUEST:
      return {
        loading: true,
        success: false,
      }
    case ORDER_PAYMENT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        orderPayDetails: action.payload,
      }
    case ORDER_PAYMENT_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
        success: false,
      }
    default:
      return state
  }
}
export const orderCommentListReducer = (state = { comments: [] }, action) => {
  switch (action.type) {
    case ORDER_COMMENT_REQUEST:
      return {
        loading: true,
      }
    case ORDER_COMMENT_SUCCESS:
      return {
        loading: false,
        comments: action.payload,
        success: true,
      }
    case ORDER_COMMENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_COMMENT_RESET:
      return {}
    default:
      return state
  }
}
export const adminOrderListReducer = (
  state = { data: {}, loading: true },
  action
) => {
  switch (action.type) {
    case ORDER_ADMIN_REQUEST:
      return {
        loading: true,
      }
    case ORDER_ADMIN_SUCCESS:
      return {
        loading: false,
        data: action.payload.statsResponse,
        orderStats: action.payload.orderStats,
        success: true,
      }
    case ORDER_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const currateBoxReducer = (
  state = { data: {}, loading: true },
  action
) => {
  switch (action.type) {
    case ORDER_CURRATE_REQUEST:
      return {
        loading: true,
      }
    case ORDER_CURRATE_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
      }
    case ORDER_CURRATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const sellerOrderListReducer = (state = {}, action) => {
  switch (action.type) {
    case SELLER_ORDER_LIST_REQUEST:
      return {
        loading: true,
      }
    case SELLER_ORDER_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case SELLER_ORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
export const nListOrderReducer = (
  state = { orders: {}, loading: true },
  action
) => {
  switch (action.type) {
    case ORDER_NLIST_REQUEST:
      return {
        loading: true,
      }
    case ORDER_NLIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
        success: true,
      }
    case ORDER_NLIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const orderOTPReducer = (
  state = { order: {}, loading: true },
  action
) => {
  switch (action.type) {
    case ORDER_OTP_REQUEST:
      return {
        loading: true,
      }
    case ORDER_OTP_SUCCESS:
      return {
        loading: false,
        order: action.payload,
        success: true,
      }
    case ORDER_OTP_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const orderTrackReducer = (
  state = { order: {}, loading: true },
  action
) => {
  switch (action.type) {
    case ORDER_TRACK_REQUEST:
      return {
        loading: true,
      }
    case ORDER_TRACK_SUCCESS:
      return {
        loading: false,
        data: action.payload.message,
        success: true,
      }
    case ORDER_TRACK_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
