import React from 'react'
import { Helmet } from 'react-helmet'
const Meta = ({ title, description, keyword }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta charset='UTF-8' />
      <meta name='description' content={description}></meta>
      <meta name='keyword' content={keyword}></meta>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0'
      ></meta>
      {/* <link rel='canonical' href={url}></link> */}
      <meta name='robots' content='index, follow' />
    </Helmet>
  )
}

Meta.defaultProps = {
  title: 'Buy Books|New & Second Hand Online Bookstore|Best Bookshop',
  description:
    "99bookscart.com is India's most favorite new & second hand books online best bookstore.Shop for 2022 Books",
  keyword:
    'Books, New & second hand books, bookstore near me, bookshop near me,Shop for 2022 Books',
}

export default Meta
