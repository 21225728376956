export const STORIES_CREATE_REQUEST = 'STORIES_CREATE_REQUEST'
export const STORIES_CREATET_SUCCESS = 'STORIES_CREATET_SUCCESS'
export const STORIES_CREATE_FAIL = 'STORIES_CREATE_FAIL'

export const STORIES_LIST_REQUEST = 'STORIES_LIST_REQUEST'
export const STORIES_LIST_SUCCESS = 'STORIES_LIST_SUCCESS'
export const STORIES_LIST_FAIL = 'STORIES_LIST_FAIL'

export const STORIES_GET_REQUEST = 'STORIES_GET_REQUEST'
export const STORIES_GET_SUCCESS = 'STORIES_GET_SUCCESS'
export const STORIES_GET_FAIL = 'STORIES_GET_FAIL'

export const STORIES_EDIT_REQUEST = 'STORIES_EDIT_REQUEST'
export const STORIES_EDIT_SUCCESS = 'STORIES_EDIT_SUCCESS'
export const STORIES_EDIT_FAIL = 'STORIES_EDIT_FAIL'

export const STORIES_DELETE_REQUEST = 'STORIES_DELETE_REQUEST'
export const STORIES_DELETE_SUCCESS = 'STORIES_DELETE_SUCCESS'
export const STORIES_DELETE_FAIL = 'STORIES_DELETE_FAIL'

export const STORIES_LIST_TOP_REQUEST = 'STORIES_LIST_TOP_REQUEST'
export const STORIES_LIST_TOP_SUCCESS = 'STORIES_LIST_TOP_SUCCESS'
export const STORIES_LIST_TOP_FAIL = 'STORIES_LIST_TOP_FAIL'
