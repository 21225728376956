import React, { useState } from 'react'
import {
  Card,
  Button,
  Modal,
  Image,
  Row,
  Col,
  Container,
} from 'react-bootstrap'
import Rating from './Rating.js'
import { Link } from 'react-router-dom'
import { addToCart } from '../actions/cartActions.js'
import { useDispatch, useSelector } from 'react-redux'
import BoxProgressBar from './BoxProgressBar.js'
import { FaBolt, FaBoxOpen } from 'react-icons/fa'
import { getCategory, storyStatus } from '../helpers/helpers.js'
import { FaBookReader, FaStar, FaList, FaClock } from 'react-icons/fa'
import { convertToSlug } from '../helpers/helpers.js'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Paper } from '@mui/material'
import { PosterBox } from './PosterBox.js'

const PosterLogic = ({ product, bookAttribute }) => {
  const dispatch = useDispatch()

  const [price, setPrice] = React.useState(199)
  const [vid, setVid] = React.useState('')
  const cartRes = useSelector((state) => state.cart)
  const {
    cartItems,
    cartType,
    recentView: recentProducts,
    cartSuccess,
  } = cartRes
  const updateVariantPrice = (ee) => {
    setPrice(ee.target.value)
    setVid(ee.target.name)
  }
  const addToCartHandler = (ee) => {
    dispatch(addToCart(product._id, 1, vid, null, price, false))
  }
  return (
    <>
      <Row>
        <Container className='story-head-section'>
          <Row className='productviewdesign'>
            {/* {product.isBox && <Timer></Timer>} */}
            <Col xs={12} md={4}>
              <LazyLoadImage
                src={product.image}
                width={'100%'}
                alt={`buy ${product.name} book on 99bookscart`}
                className='poster-image-main'
                style={{
                  backgroundColor: product.imageColor
                    ? product.imageColor
                    : '#000000',
                }}
              />
            </Col>
            <Col xs={12} md={8}>
              <div style={{ paddingLeft: '8px' }}>
                <h1 className='title-main-poster' style={{ color: '#3C3C3B' }}>
                  {product.name && product.name.toLowerCase()}
                </h1>
                <div>
                  <span className='posterPrice'>&#8377; {price}/-</span>
                  <br />
                  <small>Tax Included.</small>
                </div>
                <div>
                  <Button
                    variant='outline-primary'
                    key='456987'
                    id='456987'
                    data-id='456987'
                    value='199'
                    name='Mini Poster 8"X12"'
                    onClick={(e) => updateVariantPrice(e)}
                    className='posterVariantButton'
                  >
                    Mini Poster 8"X12"
                  </Button>

                  <Button
                    variant='outline-primary'
                    key='456789456'
                    id='456789456'
                    data-id='456789456'
                    value='249'
                    name='Regular Poster 12"X18"'
                    onClick={(e) => updateVariantPrice(e)}
                    className='posterVariantButton'
                  >
                    Regular Poster 12"X18"
                  </Button>

                  <Button
                    variant='outline-primary'
                    key='451245'
                    id='451245'
                    data-id='451245'
                    value='99'
                    name='Digital Download'
                    onClick={(e) => updateVariantPrice(e)}
                    className='posterVariantButton'
                  >
                    Digital Download
                  </Button>
                </div>
                <div
                  style={{
                    maxWidth: '370px',
                    marginLeft: '24px',
                    marginTop: '24px',
                  }}
                  className='text-center'
                >
                  <Row>
                    <Paper xs={4} md={4} className='gurentee'>
                      COD Available Now
                    </Paper>
                    <Paper xs={4} md={4} className='gurentee'>
                      Next day dispatch
                    </Paper>
                    <Paper xs={4} md={4} className='gurentee'>
                      100% Quality Guarantee
                    </Paper>
                  </Row>
                </div>
                <div style={{ marginTop: '18px' }}>
                  {cartSuccess && (
                    <p>
                      Added to <a href='/cart'>cart</a>
                    </p>
                  )}
                  <Button
                    variant='primary'
                    key='451245'
                    id='451245'
                    data-id='451245'
                    value='69'
                    name='Add to Cart'
                    onClick={(e) => addToCartHandler(e)}
                    style={{
                      width: '290px',

                      marginBottom: '18px',
                    }}
                  >
                    Add to Cart
                  </Button>
                </div>
                <div>
                  <FaBolt></FaBolt>3 visitor(s) looking at this poster
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Container>
              {' '}
              <PosterBox description={product.description}></PosterBox>
            </Container>
          </Row>
        </Container>
      </Row>
    </>
  )
}

export default PosterLogic
