import { React, useState } from 'react'
import { Route, useLocation, Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { FaUserAlt } from 'react-icons/fa'
import { FaBookReader, FaPowerOff } from 'react-icons/fa'

import { FaSearch } from 'react-icons/fa'
import 'react-pro-sidebar/dist/css/styles.css'
import { useHistory } from 'react-router-dom'
const FooterMenu = ({ history }) => {
  const [keywords, setKeywords] = useState('')

  return (
    <Container style={{ marginBottom: '15px' }}>
      <Row>
        <Container>
          <Navbar expand='lg' collapseOnSelect className='customer-nav'>
            <div style={{ width: '100%' }}>
              <div style={{ float: 'left' }}>
                <Link to='/app/home'>
                  <img
                    src='../../images/logo.png'
                    className='siteLogo-app'
                    alt='litplots logo'
                  />
                </Link>
              </div>

              <div style={{ float: 'right' }}>
                <Link to='/app/profile/readings'>
                  <FaBookReader className='profile-icons' />
                </Link>
                <Link to='/app/profile/subscriptions'>
                  <FaUserAlt className='profile-icons'></FaUserAlt>
                </Link>
              </div>
            </div>
          </Navbar>
        </Container>
      </Row>
    </Container>
  )
}

export default FooterMenu
