import React, { useEffect } from 'react';

function AdHeaderBanner() {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5651899416865358"
        crossOrigin="anonymous"
      />
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-format="auto"
        data-ad-client="ca-pub-5651899416865358"
        data-ad-slot="2458873928"
        data-full-width-responsive="true"
      />
    </div>
  );
}


export default AdHeaderBanner;
