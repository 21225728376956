import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup } from 'react-bootstrap'
import Product from '../components/Product'
import axios from 'axios'
import { listProducts } from '../actions/productActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import CPaginate from '../components/CPaginate'
import { Container } from 'react-bootstrap'
import ProductCarosel from '../components/ProductCarosel'
import Meta from '../components/Meta'
import BoxProgressBar from '../components/BoxProgressBar'
import BoxCarosel from '../components/BoxCarosel'
import { listProductsByCategory } from '../actions/categoryActions'
import FooterMenu from '../components/FooterMenu'
import ScrollToTop from '../components/ScrollToTop'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
const CategoryProductScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const categ = match.params.category || ''
  const productList = useSelector((state) => state.categoryProduct)
  const { loading, error, products, pages, page } = productList

  const dispatch = useDispatch()
  const handleChange = (event, value) => {
    history.push(`/category/${categ}/page/${value}`)
  }
  useEffect(() => {
    window.scrollTo(0, 0)

    dispatch(listProductsByCategory(categ, pageNumber))
  }, [dispatch, pageNumber, categ, match.params.category])
  return (
    <Container>
      <Helmet>
        <title>
          Buy Best {match.params.category.replace(/-/g, ' ')} Books Online at
          Best Price in India
        </title>
        <meta charset='UTF-8' />
        <meta
          name='title'
          content={
            'Buy Best' +
            match.params.category.replace(/-/g, ' ') +
            '99bookscart.com'
          }
        ></meta>
        <meta
          name='description'
          content={
            'Buy Best ' +
            match.params.category.replace(/-/g, ' ') +
            'second hand Books to read online online'
          }
        ></meta>
        <meta
          name='keyword'
          content={
            ('second hand ' +
              match.params.category.replace(/-/g, ' ') +
              ' books',
            'second hand ' +
              match.params.category.replace(/-/g, ' ') +
              ' books online')
          }
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        ></meta>
        <link
          rel='canonical'
          href={`https://www.99bookscart.com/category/${categ}`}
        ></link>
        <meta name='robots' content='index, follow' />
      </Helmet>
      {/* {match.params.category == 'top-rated' ? (
        <>
          <h4 className='text-center'>Curate your own Book Box</h4>
          <img
            src='https://s3-books-images.s3.amazonaws.com/dcd79a12-9c25-4da7-aaaa-dd497c24eaba.png'
            width={'100%'}
          />
        </>
      ) : ( */}
      <>
        <h1>{match.params.category.replace(/-/g, ' ')} Books</h1>
      </>
      {/* )} */}
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>
          <h2>error</h2>
        </Message>
      ) : (
        <>
          <Container>
            <Row>
              {products.map((product) => (
                <Col
                  key={product._id}
                  sm={6}
                  md={6}
                  lg={2}
                  xs={6}
                  className='product-grid-size'
                >
                  <Route
                    render={({ history }) => (
                      <Product history={history} product={product} />
                    )}
                  />
                </Col>
              ))}
              <Container>
                <Stack spacing={1}>
                  <Pagination
                    count={pages}
                    page={parseInt(page)}
                    onChange={handleChange}
                    style={{
                      justifyContent: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                    hidePrevButton
                    hideNextButton
                  />
                </Stack>
              </Container>
            </Row>
          </Container>
        </>
      )}
      <Container>
        <FooterMenu></FooterMenu>
        <Row>
          <h2>Best {match.params.category.replace(/-/g, ' ')} Books</h2>
          {match.params.category.replace(/-/g, ' ') == 'mystery' && (
            <small style={{ fontSize: '13px' }}>
              If you're looking for a thrilling read, mystery books are the
              perfect answer. From the classic stories of Sherlock Holmes to the
              heart-pounding thrillers of the modern day, mystery books offer a
              captivating escape from the real world. Whether you're a fan of
              the cozy variety or the more action-packed stories, there's
              something for everyone. Plus, with the popularity of podcasts and
              streaming services, there are more options than ever for getting
              your mystery fix. Pick up an old classic or check out one of the
              new releases. And if you're looking for more, there are always the
              exciting world of fan-fiction and short stories. With mystery
              books, the possibilities are endless. So whether you're looking
              for a quick escape or a deeper dive into a world of suspense,
              mystery books are sure to provide hours of entertainment.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'romance' && (
            <small style={{ fontSize: '13px' }}>
              Romance books are a great way to escape reality and explore a
              world of fantasy and adventure. Whether you're looking for a
              thrilling adventure or a heartwarming love story, there's a
              romance book out there for you. From historical romances to steamy
              contemporaries, the possibilities are endless. Romance books are
              also great for getting lost in a different world and exploring
              different cultures, settings, and characters. You could be reading
              about a passionate love affair in the Middle Ages, a forbidden
              romance in India, or a tender love story in the Caribbean. And
              when it comes to the characters, you'll find a wide variety of
              personalities, from strong and independent heroines to
              swoon-worthy heroes. So if you're looking for an escape from the
              real world, there's no better way than to dive into a romance
              book. With just a few pages, you'll be transported to a world
              filled with passion, adventure, and love.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'fantasy' && (
            <small style={{ fontSize: '13px' }}>
              There's something undeniably exciting about fantasy books. From
              the vivid worlds they create to the fantastic creatures they bring
              to life, fantasy books are some of the most captivating stories
              around. They take readers on incredible journeys, from epic
              battles to hidden realms, and allow us to explore a realm of
              possibilities that we never thought possible. It's no wonder so
              many of us are drawn to these stories - they give us a chance to
              escape and experience something different. From magical creatures
              to daring heroes, fantasy books are full of larger-than-life
              characters that keep us coming back for more. Whether you're a fan
              of dragons and wizards or you prefer tales of mystical creatures
              and far-away lands, there's something for everyone in the fantasy
              genre. So if you're looking for a thrilling and exciting escape,
              these stories are the perfect choice. Get ready to be whisked away
              on a fantastical journey - you won't regret it.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') ==
            'literature and fiction' && (
            <small style={{ fontSize: '13px' }}>
              Literature and fiction books are the perfect escape from the
              everyday hustle and bustle of life. Whether you're looking for a
              classic novel, a gripping thriller, or a fantasy story, there's
              something for everyone. These books transport you to a different
              world where you can explore complex characters, beautiful
              settings, and intense plotlines. You can immerse yourself in the
              story and discover new perspectives and ideas that you didn't
              think were possible. Reading literature and fiction can awaken
              your creativity and help you see the world in a different light.
              It can also help you explore your own emotions and thoughts in a
              safe, non-judgmental environment. Best of all, reading fiction
              books can bring you joy and relaxation. So if you're looking for a
              way to escape to another world, pick up a book and let your
              imagination do the rest!
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'crime and thriller' && (
            <small style={{ fontSize: '13px' }}>
              Crime and Thriller books have always been a favorite of avid
              readers. They offer a thrilling ride through the criminal
              underworld, often investigating cases that the police can't solve.
              These books are filled with suspense and unexpected twists, making
              them almost impossible to put down. Readers get to experience the
              rush of trying to solve the mystery before the protagonist, and
              the adrenaline-pumping ending is often the icing on the cake. From
              gritty detective stories to whodunits and psychological thrillers,
              Crime and Thriller books provide a unique and exciting escape from
              reality. So if you're looking for a thrilling read, you can't go
              wrong with a crime or thriller book. You'll be transported into
              another world and get a unique insight into the criminal mind.
              Grab one of these thrilling books, and see if you have what it
              takes to solve the mystery!
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'non fiction' && (
            <small style={{ fontSize: '13px' }}>
              When it comes to reading, there's nothing like a good nonfiction
              book. They're dense, they're informative, and they're often full
              of interesting facts and information. And if you're looking for
              some good nonfiction books to read, you're in luck. Here are a few
              to check out: The 5 Love Languages by Gary Chapman The 4-Hour Work
              Week by Timothy Ferriss The Power of Intention by Dr. Wayne Dyer
              The Mind-Body Connection by Deepak Chopra
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'young adult' && (
            <small style={{ fontSize: '13px' }}>
              Young Adult books are a popular genre, and for good reason.
              They're exciting, suspenseful, and full of adventure. They're a
              great way to introduce young adults to new genres and authors, and
              they can also teach valuable life lessons. Here are a few of the
              most popular young adult books: The Hunger Games by Suzanne
              Collins Harry Potter and the Sorcerer's Stone by J.K. Rowling The
              Maze Runner by James Dashner The Fault in Our Stars by John Green
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'history' && (
            <small style={{ fontSize: '13px' }}>
              History books are a great way to learn about the past. They can
              teach you about famous people and events, and they can also teach
              you about the history of your own country. If you're looking for a
              good history book, be sure to consider one that's written in an
              interesting, creative style. This will make the experience more
              enjoyable, and it will also help you to learn more effectively.
              There are many great history books available, so it's easy to find
              one that's perfect for your needs. So be sure to browse the
              shelves of your local bookstore, and find the history book that
              will enrich your learning experience and bring you closer to the
              past.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'poetry' && (
            <small style={{ fontSize: '13px' }}>
              Poetry books can be a beautiful thing. They can be used to store
              poems and poems can be used to store thoughts. They can be used to
              store words and words can be used to store thoughts. They can be
              used to store emotions and emotions can be used to store thoughts.
              They can be used to store feelings and feelings can be used to
              store thoughts. They can be used to store memories and memories
              can be used to store thoughts. They can be used to store dreams
              and dreams can be used to store thoughts. They can be used to
              store aspirations and aspirations can be used to store thoughts.
              They can be used to store anything and everything that a person
              wants or needs them to store. Poetry books are a beautiful thing,
              and poets are a beautiful thing, and poetry is a beautiful thing.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'childrens' && (
            <small style={{ fontSize: '13px' }}>
              Children's books are a great way to share your love of reading
              with your children. They can learn about different cultures and
              religions, and they can learn about the world around them. And, of
              course, they can learn to read. Children's books are also a great
              way to introduce your children to new authors and illustrators.
              There are a variety of different types of children's books, and
              there is something for everyone. Whether you're looking for a
              story about a heroic dog, a story about a princess who saves her
              kingdom, or a story about a clever monkey, you're sure to find
              something that you'll love. So don't wait any longer - go ahead
              and take your children to the bookstore!
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'horror' && (
            <small style={{ fontSize: '13px' }}>
              Horror books are some of the most popular genres out there, and
              for good reason. They're suspenseful, exciting, and incredibly
              scary. They're the perfect way to get your adrenaline pumping and
              your heart racing. There's something about horror books that's
              just really addicting. Maybe it's the suspenseful atmosphere or
              the bone-chilling sound effects. Maybe it's the feeling of being
              terrified for hours on end. Or maybe it's just the thrill of being
              scared to death for a few hours. Whatever the reason, horror books
              are definitely one of the most popular genres out there. If you're
              looking for a scary and exciting read, don't miss out on the genre
              of horror books. They're sure to give you a good scare - and maybe
              even a little bit of excitement too!
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'classic' && (
            <small style={{ fontSize: '13px' }}>
              There's something special about reading a classic book. Not only
              do they offer timeless wisdom and entertainment, but they also
              remind us of a time when books were actually worth reading.
              Classic books are often filled with deep and meaningful messages
              that can help us learn about ourselves and our world. And, of
              course, there's always the chance for a suspenseful or romantic
              story. Whether you're a fan of classic novels or you just enjoy
              spending time reading, these are a few of our favorite books.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'science' && (
            <small style={{ fontSize: '13px' }}>
              Science books are a great way to learn about the world around us.
              They can teach you about the natural sciences, mathematics, and
              more. If you're looking for a good science book, you're in luck.
              Here are a few of our favorites: 1. The Physics of Everyday Life
              by Neil Gershenfeld This book is full of interesting and
              entertaining stories about the physics of everyday objects. It's
              perfect for anyone who wants to know more about the science behind
              their everyday experiences. 2. The Universe in a Nutshell by Brian
              Greene This book is chock-full of information about the universe.
              It covers everything from the basic laws of physics to the latest
              discoveries in cosmology. It's an ideal book for anyone who wants
              to learn more about the big picture. 3. The Science of Breath by
              Anupama Vasudevan This book covers everything from the history of
              breath to the physiology of breathing. It's an excellent resource
              for anyone who wants to learn more about the science behind
              breathing and health.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'business' && (
            <small style={{ fontSize: '13px' }}>
              If you're interested in starting or expanding your business, you
              need to read a business book. There's no substitute for learning
              from experienced business owners, and books can teach you
              everything from how to start a business to how to grow your
              business. In fact, there are dozens of great business books out
              there, covering a variety of topics and industries. So whether
              you're looking for a business strategy book, a business growth
              book, or a book on how to market your business, you'll find the
              perfect one right here. So what are you waiting for? Start reading
              today!
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'self help' && (
            <small style={{ fontSize: '13px' }}>
              Self-help books are a genre of books that offer advice on how to
              improve one's life. They are often written by professionals in the
              field of psychology, psychiatry, psychotherapy, or social work.
              <br />
              The first self-help book was published in 1859 by Samuel Smiles
              and entitled "Self-Help: With Illustrations of Character and
              Conduct". Smiles' book is based on his own experiences as well as
              the experiences of people he had met throughout his life.
              <br />
              Self-help books are often written for people who need help with a
              particular problem. For example, a self-help book for someone with
              depression might be about how to overcome depression through
              various techniques such as cognitive behavioral therapy or
              mindfulness meditation.
              <br />
              Some self-help books have been criticized for being too general in
              their approach to solving problems or not providing enough
              specific information to help readers solve their problems.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'self help' && (
            <small style={{ fontSize: '13px' }}>
              Self-help books are a genre of books that offer advice on how to
              improve one's life. They are often written by professionals in the
              field of psychology, psychiatry, psychotherapy, or social work.
              <br />
              The first self-help book was published in 1859 by Samuel Smiles
              and entitled "Self-Help: With Illustrations of Character and
              Conduct". Smiles' book is based on his own experiences as well as
              the experiences of people he had met throughout his life.
              <br />
              Self-help books are often written for people who need help with a
              particular problem. For example, a self-help book for someone with
              depression might be about how to overcome depression through
              various techniques such as cognitive behavioral therapy or
              mindfulness meditation.
              <br />
              Some self-help books have been criticized for being too general in
              their approach to solving problems or not providing enough
              specific information to help readers solve their problems.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'contemporary' && (
            <small style={{ fontSize: '13px' }}>
              The contemporary books are those that are published within the
              last five years.
              <br />
              In recent years, there has been a huge rise in the popularity of
              contemporary books. This is because there are more people who want
              to read about what is happening in the world right now, rather
              than what happened in the past.
              <br />
              The contemporary books include a wide range of genres, such as
              romance, mystery and thriller, science fiction and fantasy,
              children's literature and more.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'travel' && (
            <small style={{ fontSize: '13px' }}>
              Travel books are a great way to learn about other cultures and
              different perspectives. They can be a great source of inspiration
              for your next trip, or even just to get some new ideas for your
              daily life.
              <br />
              The best travel books will always be the ones that are written by
              travelers themselves, not journalists who have never been there
              before. The best way to learn about a country is through their
              eyes and their experience.
              <br />I would recommend reading anything by Bill Bryson, he has
              written multiple books on his travels around the world and they
              are all amazing.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'sports' && (
            <small style={{ fontSize: '13px' }}>
              It's hard to think of a world without books. They have been around
              for centuries, and the book industry is worth billions of dollars.
              <br />
              Books are an essential part of our lives, whether we read them or
              not. They are used to educate us, entertain us, and inspire us.
              <br />
              But there is still a lot we don't know about books and how they
              work.
              <br />
              What do you think? What are some other things you would like to
              know about books?
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'historical fiction' && (
            <small style={{ fontSize: '13px' }}>
              Historical fiction is a genre of fiction that typically deals with
              fictional events set in the past.
              <br />
              The genre can be divided into three different periods: ancient,
              medieval, and modern. Ancient historical fiction covers the time
              period from about the year 500 to about the year 1500. Medieval
              historical fiction covers the time period from about AD 1000 to
              about AD 1600. Modern historical fiction covers the time period
              from about AD 1700 to present day.
              <br />
              Many of these books are set in places that were not well known by
              people living today like ancient Rome and Egypt or medieval
              England and France.
              <br />
              In this section we will talk about some famous examples of
              historical novels such as "The Pillars of The Earth" by Ken
              Follett and "The Da Vinci Code" by Dan Brown.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'womens fiction' && (
            <small style={{ fontSize: '13px' }}>
              A lot of women are reading fiction to escape the daily grind and
              to find a place where they can find themselves. This is an
              exciting time for womens fiction books. <br />
              The genre has been around for decades and has had many great
              successes over the years, but in recent years, it has seen a
              resurgence of popularity.
            </small>
          )}
          {match.params.category.replace(/-/g, ' ') == 'biography' && (
            <small style={{ fontSize: '13px' }}>
              Biography books are less popular than fiction ones, but they are
              still an important genre. It is a type of nonfiction that tells
              the story of a person's life. Biographies can be written about any
              subject and in any form, but they always tell the story of someone
              who has died.
              <br />
              The biography may be written by a family member or associate, or
              it may be written by a professional biographer who is given
              exclusive access to all aspects of the person's life and work. The
              word "biography" comes from two Greek words: "bios" meaning "life"
              and "graphien" meaning "to write".
              <br />
            </small>
          )}
        </Row>
      </Container>
    </Container>
  )
}

export default CategoryProductScreen
