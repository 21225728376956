import React from 'react'
import Helmet from 'react-helmet'
const HomeMeta = () => {
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: '99bookscart',
    url: 'https://www.99bookscart.com/',
    logo: 'https://www.99bookscart.com/images/logo.png',
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate:
          'https://www.99bookscart.com/search/{search_term_string}/true',
      },
      'query-input': 'required name=search_term_string',
    },
    publisher: {
      '@type': 'Organization',
      name: '99bookscart',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.99bookscart.com/images/logo.png',
        width: 200,
        height: 60,
      },
    },
  }
  return (
    <Helmet>
      <title>Home to India's Best Book Boxes| 99bookscart</title>
      <meta
        property='og:title'
        content="Home to India's Best Book Boxes | 99bookscart"
      />

      <head prefix='book: https://ogp.me/ns/book#'></head>
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://www.99bookscart.com' />
      <meta
        property='og:image'
        content='https://99bookscart.com/images/logo.png'
      />
      <meta
        property='og:description'
        content="Buy Best Second Hand Books to read on 99bookscart.com. It's a online Bookstore offers range of Second Hand Books and Home to Million Used Best Books to read "
      />
      <meta property='og:site_name' content='99bookscart' />
      <meta
        name='description'
        content="Buy Best Second Hand Books to read on 99bookscart.com. It's a online Bookstore offers range of Second Hand Books and Home to Million Used Best Books to read "
      ></meta>
      <meta
        name='keyword'
        content='second hand books to read,online used books, best books to read,online book marketplace, buy books online, sell books online, new books, second-hand books, rare books, book lovers, literature, readers, sellers, book stores, Books, New & second hand books, bookstore near me, bookshop near me,Shop for 2023 Books'
      ></meta>
      <meta
        name='title'
        content="Home to India's Best Book Boxes | 99bookscart"
      ></meta>

      <link rel='canonical' href='https://www.99bookscart.com'></link>
      <meta name='robots' content='index, follow' />
      <script className='structured-data-list' type='application/ld+json'>
        {JSON.stringify(articleStructuredData)}
      </script>
    </Helmet>
  )
}

function convertToSlug(string) {
  return string
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default HomeMeta
