import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listUsers, deleteUser } from '../actions/userActions'
import Paginate from '../components/Paginate'
import AdminNav from '../components/AdminNav'

const UserListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1
  const userList = useSelector((state) => state.userList)
  const { loading, error, users, pages, page } = userList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDelete = useSelector((state) => state.userDelete)
  const { success: successDelete } = userDelete

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers(pageNumber))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, successDelete, pageNumber])

  const deleteHandler = (id) => {
    if (window.confirm('are you Sure')) {
      dispatch(deleteUser(id))
    }
  }
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          <AdminNav></AdminNav>
          <h1>Users</h1>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Created </th>
                <th>Email</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>
                    <small>{getISTTime(user.createdAt)}</small>
                  </td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>
                    {user.shorts ? (
                      <i
                        className='fas fa-check'
                        style={{ color: 'green' }}
                      ></i>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}{' '}
                    {user.isSubscribed ? (
                      <i
                        className='fas fa-check'
                        style={{ color: 'green' }}
                      ></i>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                  </td>
                  <td>
                    {/* <LinkContainer to={`/admin/user/${user._id}/edit`}>
                      <Button className='btn-sm' variant='light'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(user._id)}
                    >
                      <i className='fas fa-trash-alt'></i>
                    </Button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate
            pages={pages}
            page={page}
            isAdmin={true}
            orders={false}
            users={true}
          ></Paginate>
        </Container>
      )}
    </Container>
  )
}
const getISTTime = (date) => {
  let d = new Date(date)
  let newData = d.getTime() + 5.5 * 60 * 60
  let ist = new Date(newData)
  return (
    ist.getDate() +
    '/' +
    ist.getMonth() +
    '/' +
    ist.getFullYear() +
    ' ' +
    ist.getHours() +
    ':' +
    ist.getMinutes()
  )
}
export default UserListScreen
