import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Modal,
  Form,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import CheckoutSteps from '../components/CheckoutSteps'
import {
  createOrder,
  payOrder,
  postOrderPayment,
} from '../actions/orderActions'
import Razorpay from 'razorpay'
import { CART_RESET } from '../constants/cartConstants'
import { register } from '../actions/userActions'
import { v4 as uuidv4 } from 'uuid'
import Loader from '../components/Loader'
import { getDiscountCode } from '../actions/discountCodeActions'
import {
  DISCOUNT_CODE_CREATE_RESET,
  DISCOUNT_CODE_FIXEDAMOUNT,
  DISCOUNT_CODE_PERCENTAGE,
  DISCOUNT_CODE_RESET,
} from '../constants/discountCodeConstants'
import {  saveDiscountPrice } from '../actions/cartActions'
import { getProductById } from '../actions/productActions'
import { removeFromCart } from '../actions/cartActions.js'
import { FaTruckMoving } from 'react-icons/fa'

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay))
}

const PlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch()
  const [isCod, setIsCod] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [isCodeApplied, setIsCodeApplied] = useState(false)
  const [cartValidation, setCartValidation] = useState(false)
  const [displayCartError, setDisplayCartError] = useState(false)
  const [couponCodeError, setCouponCodeError] = useState(false)

  const [payS, SetPayS] = useState(true)

  var finalresult = []
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }
  const cart = useSelector((state) => state.cart)
  const { cartItems, totalPrice, discountPrice, cartType, shippingAddress } =
    cart

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.salePrice * item.qty, 0)
  )

  var cartShipping =
    cart.shippingMethod == 'standard'
      ? 129
      : cart.shippingMethod == 'speed'
      ? 169
      : cart.shippingMethod == 'standard-box'
      ? 199
      : 249

  cart.shippingPrice = addDecimals(cartShipping)
  cart.codPrice = addDecimals(cart.paymentMethod == 'cod' ? 50 : 0)
  cart.totalPrice =
    Number(cart.itemsPrice) + Number(cart.shippingPrice) + Number(cart.codPrice)

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success: orderSuccess, error } = orderCreate

  const paymentROrder = useSelector((state) => state.orderPayRazor)
  const {
    orderPayDetails,
    success: paySuccess,
    error: payError,
    loading: payLoading,
  } = paymentROrder

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const discountCodeGet = useSelector((state) => state.discountCodeGet)
  const {
    discountCode,
    success: discountCodeSuccess,
    error: discountCodeError,
    loding: discountCodeLoading,
  } = discountCodeGet
  const getShippingCost = (CartItems) => {
    const totalCart = cartItems.reduce(
      (acc, item) => acc + item.qty * item.salePrice,
      0
    )
    if (totalCart < 999) {
      return 89
    } else {
      var bookscartboxSum = 0
      var bookscartboxCount = 0
      var bookscartboxExtraSum = 0
      var otherSum = 0
      var boxCount = 0
      const cartPrice = cartItems.forEach((item, index) => {
        if (item.isBookscartBox) {
          bookscartboxSum = bookscartboxSum + item.salePrice
          bookscartboxCount++
        } else {
          otherSum = otherSum + item.salePrice
        }
        if (bookscartboxCount > 9) {
          bookscartboxExtraSum = bookscartboxExtraSum + item.salePrice
        }
        if (item.isBox) {
          boxCount++
        }
      })
      const totalBooks = cartItems.length - boxCount
      const shippingByBox = 249 * boxCount
      const shippingByBooks = totalBooks * 19
      return shippingByBooks + shippingByBox
    }
  }
  const shippingCost = getShippingCost(cartItems)
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  var options = {
    key: 'rzp_test_8AXVyCq8AzjLmr',
    amount: cart.totalPrice,
    currency: 'INR',
    name: userInfo ? userInfo.name : cart.shippingAddress.name,
    description: '99bookscart',
    // "order_id":orderPayDetails.id,
    image:
      'https://cdn.shopify.com/s/files/1/0287/9612/5316/files/99bookscart_759b028b-6768-47b8-9d79-ee2cf0190e04_180x.png?v=1646232311',
    handler: function (response) {
      const paymentResult = {
        id: response.razorpay_payment_id,
        rid: response.razorpay_order_id,
        signature: response.razorpay_signature,
      }
      dispatch(
        createOrder({
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          paymentMethod: cart.paymentMethod,
          shippingMethod: cart.shippingMethod,
          itemPrice: cart.itemsPrice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
          codPrice: cart.codPrice,
          isPaid: response.razorpay_order_id ? true : false,
          paidAt: response.razorpay_order_id ? Date.now() : '',
          paymentResult: paymentResult,
        })
      )
      timeout(1000)
      setPayButtonClick(false)
    },
    prefill: {
      name: userInfo ? userInfo.name : cart.shippingAddress.name,
      email: userInfo ? userInfo.email : cart.shippingAddress.email,
      contact: cart.shippingAddress.phone,
    },
    theme: {
      color: '#4267b2',
    },
  }

  const [payButtonClick, setPayButtonClick] = useState(false)
  let paymentLoading = false

  useEffect(() => {
    // window.scrollTo(0, 0)
    loadScript('https://checkout.razorpay.com/v1/checkout.js')
    if (paySuccess && payButtonClick && payS) {
      SetPayS(false)
      options['order_id'] = orderPayDetails.id
      console.log(options)
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }

    if (orderSuccess && isCod) {
      dispatch(payOrder(order._id))
      history.push(`/order/${order._id}/orderconfirm/cod`)
    }
    if (orderSuccess && order && cart.paymentMethod == 'online') {
      dispatch(payOrder(order._id))
      history.push(
        `/order/${order._id}/orderconfirm/${order.paymentResult.rid}`
      )
    }
    if (discountPrice > 0) {
      setIsCodeApplied(true)
    }
    if (discountCodeSuccess) {
      if (discountCode.type) {
        let newTotalPrice
        let newDiscountPrice
        if (discountCode.type == DISCOUNT_CODE_PERCENTAGE) {
          newTotalPrice =
            Number(cart.totalPrice) -
            Number(cart.totalPrice) * (discountCode.value / 100)
          newDiscountPrice =
            Number(cart.totalPrice) * (discountCode.value / 100)
        }
        if (discountCode.type == DISCOUNT_CODE_FIXEDAMOUNT) {
          newTotalPrice = Number(cart.totalPrice) - Number(discountCode.value)
          newDiscountPrice = discountCode.value
        }
        // dispatch(saveTotalPrice(newTotalPrice))
        dispatch(saveDiscountPrice(newDiscountPrice))
        setIsCodeApplied(true)
        cart.discountPrice = newDiscountPrice
        cart.totalPrice = newTotalPrice
      } else {
        setCouponCodeError('Discount code is not valid!')
      }
      dispatch({ type: DISCOUNT_CODE_RESET })
    }
  }, [
    history,
    dispatch,
    cartValidation,
    payS,
    finalresult,
    paySuccess,
    orderPayDetails,
    payLoading,
    payError,
    cartItems,
    order,
    payButtonClick,
    orderSuccess,
    isCod,
    paymentLoading,
    discountCodeSuccess,
    totalPrice,
    discountPrice,
  ])
  const placeOrderHandler = () => {
    setCartValidation(true)
    var promises = []
    for (var i = 0; i < cartItems.length; i++) {
      promises.push(dispatch(getProductById(cartItems[i].product)))
    }
    Promise.all(promises).then((dt) => {
      for (var j = 0; j < dt.length; j++) {
        for (var i = 0; i < cartItems.length; i++) {
          if (cartItems[i].product == dt[j]._id && dt[j].countInStock < 1) {
            finalresult.push(cartItems[i])
            removeFromCartHandler(dt[j]._id)
          }
        }
      }

      if (finalresult.length > 0) {
        setDisplayCartError(true)
      } else {
        setDisplayCartError(false)
        paymentLoading = true
        SetPayS(true)
        if (
          !paySuccess ||
          (orderPayDetails && orderPayDetails.amount !== cart.totalPrice)
        ) {
          dispatch(
            postOrderPayment({
              id: uuidv4(),
              orderItems: cart.cartItems,
              shippingAddress: cart.shippingAddress,
              paymentMethod: cart.paymentMethod,
              shippingMethod: cart.shippingMethod,
              itemPrice: cart.itemsPrice,
              shippingPrice: cart.shippingPrice,
              taxPrice: cart.taxPrice,
              totalPrice: cart.totalPrice,
            })
          )
        }
        setCartValidation(false)
        setPayButtonClick(true)
      }
    })
  }
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }
  const placeOrderCOD = () => {
    SetPayS(false)
    setCartValidation(true)
    var promises = []
    for (var i = 0; i < cartItems.length; i++) {
      promises.push(dispatch(getProductById(cartItems[i].product)))
    }
    Promise.all(promises).then((dt) => {
      for (var j = 0; j < dt.length; j++) {
        for (var i = 0; i < cartItems.length; i++) {
          if (cartItems[i].product == dt[j]._id && dt[j].countInStock < 1) {
            finalresult.push(cartItems[i])
            removeFromCartHandler(dt[j]._id)
          }
        }
      }

      if (finalresult.length > 0) {
        setDisplayCartError(true)
      } else {
        setDisplayCartError(false)
        if (!orderSuccess) {
          const paymentResult = {
            id: uuidv4(),
            rid: null,
            signature: null,
          }
          dispatch(
            createOrder({
              orderItems: cart.cartItems,
              shippingAddress: cart.shippingAddress,
              paymentMethod: cart.paymentMethod,
              shippingMethod: cart.shippingMethod,
              itemPrice: cart.itemsPrice,
              shippingPrice: cart.shippingPrice,
              taxPrice: cart.taxPrice,
              totalPrice: cart.totalPrice,
              codPrice: cart.codPrice,
              discountPrice: cart.discountPrice,
              isPaid: false,
              paidAt: null,
              paymentResult: paymentResult,
            })
          )
        }
        setIsCod(true)
        setPayButtonClick(true)
      }
    })
  }
  const couponCodeHandler = (e) => {
    e.preventDefault()
    dispatch(getDiscountCode(couponCode))
  }
  return (
    <Container>
      <CheckoutSteps step1 step2 step3 step4 />
      {paymentLoading && <Loader></Loader>}
      {cartValidation ? (
        <Loader></Loader>
      ) : (
        <Row>
          <Col md={8}>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <div className='checkout-shipping-address'>
                  <h6>
                    Shipping To <FaTruckMoving></FaTruckMoving>
                  </h6>
                  <p>
                    {shippingAddress.name}
                    <br />
                    {shippingAddress.address},{shippingAddress.address2},<br />
                    {shippingAddress.city},{shippingAddress.state},
                    {shippingAddress.postalCode}
                    <br />
                    {shippingAddress.phone}{' '}
                  </p>
                </div>
              </ListGroup.Item>

              <ListGroup.Item>
                <h6>Order Items</h6>
                {/* <p> */}
                {/* <strong>Books:</strong> {cartItems.reduce((acc,item)=>acc+item.qty,0)}<br/> */}
                {/* <strong>Estimated Cart Value: &#8377;</strong> <span className='estimated-price'>{cartItems.reduce((acc,item)=>acc+item.salePrice,0)}</span> (<strike>{cartItems.reduce((acc,item)=>acc+item.price,0)}</strike>) */}
                {/* </p> */}

                {displayCartError > 0 && (
                  <>
                    <Message>Some Books have already sold!</Message>
                  </>
                )}
                {cart.cartItems.length == 0 ? (
                  <Message>Your Cart is Empty</Message>
                ) : (
                  <ListGroup variant='flush'>
                    {cart.cartItems.map((item, index) => (
                      <ListGroup.Item key={item.product}>
                        <Row>
                          <Col md={2} xs={2}>
                            <Image
                              src={item.image}
                              alt={item.name}
                              fluid
                              rounded
                              className='cartImage'
                            />
                          </Col>
                          <Col m={5}>
                            <Link to={`/book/${item.product}`}>
                              {item.name}
                            </Link>
                            <br />
                            <span>by {item.author}</span>
                          </Col>
                          <Col md={2} xs={2}>
                            <span className='productPrice'>
                              &#8377;{item.salePrice}
                            </span>
                            <br />
                            <strike>&#8377;{item.price}</strike>
                          </Col>
                          <Col md={2} xs={2}></Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <Card>
              <ListGroup variant='flash'>
                <ListGroupItem>
                  <h4> Order Summary</h4>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>Items</Col>
                    <Col>
                      &#8377;{Number.parseFloat(cart.itemsPrice).toFixed(2)}
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>
                      Shipping Charges
                      <br />
                      <small className='capitalize-text'>
                        {cart.shippingMethod.includes('standard')
                          ? 'Standard'
                          : 'Speed'}
                      </small>
                    </Col>
                    <Col>
                      &#8377;{Number.parseFloat(cart.shippingPrice).toFixed(2)}
                    </Col>
                  </Row>
                </ListGroupItem>
                {cart.codPrice > 0 && (
                  <ListGroupItem>
                    <Row>
                      <Col>COD Charges</Col>
                      <Col>
                        &#8377;{Number.parseFloat(cart.codPrice).toFixed(2)}
                      </Col>
                    </Row>
                  </ListGroupItem>
                )}

                {discountPrice > 0 && (
                  <ListGroupItem>
                    <Row>
                      <Col>Discount</Col>
                      <Col>
                        -&#8377;{Number.parseFloat(discountPrice).toFixed(2)}
                      </Col>
                    </Row>
                  </ListGroupItem>
                )}
                <ListGroupItem>
                  <Row>
                    <Col>Total</Col>
                    <Col>
                      &#8377;{Number.parseFloat(cart.totalPrice).toFixed(2)}
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  {/* {error && <Message variant='danger'>{error}</Message>}
                                    {loading && <Loader></Loader>} */}
                  <Form onSubmit={couponCodeHandler}>
                    {couponCodeError && couponCodeError}
                    {discountCodeSuccess && discountCode.name}
                    <Row>
                      <Col>
                        <Form.Group controlId='couponCode'>
                          <Form.Control
                            type='text'
                            placeholder='Apply Coupon Code'
                            required
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button
                          type='submit'
                          varaint='primary'
                          disabled={isCodeApplied}
                        >
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </ListGroupItem>
                <ListGroupItem>
                  {error && <Message variant='danger'>{error}</Message>}
                </ListGroupItem>
                <ListGroupItem>
                  {/* <small>{cart.paymentMethod == 'cod'?'Pay Online and save COD Charges':''}</small><br/> */}
                  <Button
                    type='button'
                    className='btn-block'
                    disabled={cart.cartItems.length === 0}
                    onClick={
                      cart.paymentMethod == 'cod'
                        ? placeOrderCOD
                        : placeOrderHandler
                    }
                  >
                    {cart.paymentMethod == 'cod' ? 'Place Order' : 'Pay Order'}
                  </Button>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default PlaceOrderScreen
