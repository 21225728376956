import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup } from 'react-bootstrap'
import Product from '../components/Product'
import axios from 'axios'
import { listProducts } from '../actions/productActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import CPaginate from '../components/CPaginate'
import { Container } from 'react-bootstrap'
import ProductCarosel from '../components/ProductCarosel'
import Meta from '../components/Meta'
import BoxProgressBar from '../components/BoxProgressBar'
import BoxCarosel from '../components/BoxCarosel'
import { listProductsByLanguage } from '../actions/languageActions'

const LanguageProductScreen = ({ match }) => {
  const pageNumber = match.params.pageNumber || 1

  const productList = useSelector((state) => state.languageProduct)
  const { loading, error, products, pages, page } = productList

  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(listProductsByLanguage(match.params.language, pageNumber))
  }, [dispatch, match, pageNumber])
  return (
    <>
      <Meta></Meta>

      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>
          <h2>error</h2>
        </Message>
      ) : (
        <>
          <Container>
            <Row>
              <h1>{match.params.language.replace(/-/g, ' ')}</h1>
              {products.map((product) => (
                <Col
                  key={product._id}
                  sm={4}
                  md={4}
                  lg={2}
                  xs={4}
                  className='product-grid-size'
                >
                  <Route
                    render={({ history }) => (
                      <Product history={history} product={product} />
                    )}
                  />
                </Col>
              ))}

              <CPaginate
                pages={pages}
                page={page}
                isAdmin={false}
                category={''}
                language={match.params.language}
              ></CPaginate>
            </Row>
          </Container>
        </>
      )}
    </>
  )
}

export default LanguageProductScreen
