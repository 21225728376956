import axios from 'axios'
import {
  DISCOUNT_CODE_CREATE_FAIL,
  DISCOUNT_CODE_CREATE_REQUEST,
  DISCOUNT_CODE_CREATE_SUCCESS,
  DISCOUNT_CODE_FAIL,
  DISCOUNT_CODE_LIST_FAIL,
  DISCOUNT_CODE_LIST_REQUEST,
  DISCOUNT_CODE_LIST_SUCCESS,
  DISCOUNT_CODE_REEDEM_FAIL,
  DISCOUNT_CODE_REEDEM_REQUEST,
  DISCOUNT_CODE_REEDEM_SUCCESS,
  DISCOUNT_CODE_REQUEST,
  DISCOUNT_CODE_SUCCESS,
  USER_DISCOUNT_CODE_LIST_FAIL,
  USER_DISCOUNT_CODE_LIST_REQUEST,
  USER_DISCOUNT_CODE_LIST_SUCCESS,
} from '../constants/discountCodeConstants'

export const createDiscountCode =
  (name, type, value) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DISCOUNT_CODE_CREATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.post(
        '/api/discountCode',
        { name, type, value },
        config
      )

      dispatch({
        type: DISCOUNT_CODE_CREATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DISCOUNT_CODE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listDiscountCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DISCOUNT_CODE_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get('/api/discountCode', config)

    dispatch({
      type: DISCOUNT_CODE_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DISCOUNT_CODE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getDiscountCode = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DISCOUNT_CODE_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.get(`/api/discountCode/${name}`, config)

    dispatch({
      type: DISCOUNT_CODE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DISCOUNT_CODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const reedemDiscountCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DISCOUNT_CODE_REEDEM_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/discountCode/reedem`, config)

    dispatch({
      type: DISCOUNT_CODE_REEDEM_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DISCOUNT_CODE_REEDEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const listUserDiscountCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DISCOUNT_CODE_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/discountCode/user`, config)

    dispatch({
      type: USER_DISCOUNT_CODE_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_DISCOUNT_CODE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
