import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import StoriesMeta from '../components/meta/StoriesMeta'
import { getStories } from '../actions/storiesActions'

const ViewStoryScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const getStoriesRes = useSelector((state) => state.getStories)
  const { loading, error, success, stories } = getStoriesRes

  useEffect(() => {
    window.scrollTo(0, 0)

    if (success) {
      if (stories._id !== match.params.id) {
        dispatch(getStories(match.params.id))
      }
    } else {
      dispatch(getStories(match.params.id))
    }
  }, [dispatch, success, match, stories])

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        stories && (
          <>
            <StoriesMeta story={stories}></StoriesMeta>
            <amp-story
              standalone
              title={stories.title}
              publisher='99bookscart'
              publisher-logo-src='https://www.99bookscart.com/images/logo.png'
              poster-portrait-src={stories.coverImage && stories.coverImage}
            >
              <amp-story-page id='page5' style={{ backgroundColor: '#000' }}>
                <amp-story-grid-layer template='fill'>
                  <amp-img
                    src={stories.coverImage && stories.coverImage}
                    animate-in='fly-in-top'
                    width='720'
                    height='1280'
                    layout='responsive'
                    alt={stories.title}
                  ></amp-img>
                </amp-story-grid-layer>
                <amp-story-grid-layer template='thirds'>
                  <div
                    grid-area='middle-third'
                    className='text-center'
                    animate-in='twirl-in'
                  >
                    <p
                      grid-area='middle-third'
                      style={{
                        color: '#000',
                        backgroundColor: '#F1C40F',
                        padding: '8px',
                        marginBottom: '5px',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                      }}
                    >
                      {stories.title}
                    </p>
                  </div>
                </amp-story-grid-layer>
                <amp-story-grid-layer template='thirds'>
                  <div grid-area='lower-third' className='text-center'>
                    <p
                      grid-area='lower-third'
                      style={{
                        color: '#000',
                        backgroundColor: '#F1C40F',
                        padding: '8px',
                        marginBottom: '5px',
                        fontSize: '14px',
                      }}
                    >
                      {stories.metaDescription &&
                        stories.metaDescription.slice(0, 160)}
                    </p>
                  </div>
                </amp-story-grid-layer>
              </amp-story-page>
              {stories.pages &&
                stories.pages.map((story, index) => (
                  <>
                    <amp-story-page id={story.heading} key={story.heading}>
                      <amp-story-grid-layer template='fill'>
                        <amp-img
                          src={story.image}
                          width='900'
                          height='1280'
                          layout='responsive'
                        ></amp-img>
                      </amp-story-grid-layer>
                      <amp-story-grid-layer template='thirds'>
                        <div className='contentnnn' grid-area='lower-third'>
                          <h2
                            animate-in='twirl-in'
                            animate-in-delay='0.4s'
                            style={{
                              color: '#000',
                              backgroundColor: '#F1C40F',
                              padding: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            {story.heading}
                          </h2>
                          <p
                            style={{
                              fontSize: '14px',
                              backgroundColor: '#F1C40F',
                              padding: '8px',
                            }}
                            animate-in='fly-in-bottom'
                            animate-in-delay='0.4s'
                          >
                            {story.description.slice(0, 120)}
                          </p>
                        </div>
                      </amp-story-grid-layer>
                      <amp-story-page-outlink layout='nodisplay'>
                        <a
                          href={stories && stories.link}
                          title={'Read more about ' + story.title}
                        ></a>
                      </amp-story-page-outlink>
                    </amp-story-page>
                    {index % 2 === 0 && (
                      <amp-story-page
                        id={story.heading + 'ad'}
                        key={story.heading + 'ad'}
                      >
                        <amp-ad
                          width='100vw'
                          height='320'
                          type='adsense'
                          data-ad-client='ca-pub-5651899416865358'
                          data-ad-slot='9228402575'
                          data-auto-format='rspv'
                        >
                          <div overflow=''></div>
                        </amp-ad>
                      </amp-story-page>
                    )}
                  </>
                ))}
            </amp-story>
          </>
        )
      )}
    </>
  )
}

export default ViewStoryScreen
