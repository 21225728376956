import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup } from 'react-bootstrap'
import Product from '../../components/Product'
import axios from 'axios'
import { listProductsByGenre } from '../../actions/productActions'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import BookPaginate from '../../components/paginate/BookPaginate'
import { Container } from 'react-bootstrap'
import ProductCarosel from '../../components/ProductCarosel'
import Meta from '../../components/Meta'
import BoxProgressBar from '../../components/BoxProgressBar'
import BoxCarosel from '../../components/BoxCarosel'
import { listProductsByCategory } from '../../actions/categoryActions'
import FooterMenu from '../../components/FooterMenu'
import { makeTitle } from '../../helpers/helpers'
import Breadcrumbs from '@mui/material/Breadcrumbs'
//import Link from '@mui/material/Link'
import { Typography } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
const GenreBookScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const genre = match.params.genre || ''
  const [booktype, setBookType] = useState('fiction-books')
  const productList = useSelector((state) => state.productListByGenre)
  const { loading, error, products, pages, page } = productList

  const dispatch = useDispatch()
  const handleChange = (event, value) => {
    history.push(`/${booktype}/${genre}/page/${value}`)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (window.location.href.indexOf('non-fiction-books') > -1) {
      setBookType('non-fiction-books')
    }
    dispatch(listProductsByGenre(genre, pageNumber))
  }, [dispatch, match, pageNumber, genre])
  return (
    <Container>
      <Helmet>
        <title>{makeTitle(genre)} Books</title>
        <meta charset='UTF-8' />
        <meta
          name='title'
          content={`Best selling ${makeTitle(genre)} books on 99bookscart`}
        ></meta>
        <meta
          name='description'
          content={`Buy Best Second Hand ${makeTitle(
            genre
          )} Books to read on 99bookscart.com. It's a online Bookstore offers range of Second Hand Books`}
        ></meta>
        <meta
          name='keyword'
          content={('Books to read', 'Second Hand Books', 'Best Books')}
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        ></meta>
        <link
          rel='canonical'
          href={`https://www.99bookscart.com/${booktype}/${genre}`}
        ></link>
        <meta name='robots' content='index, follow' />
      </Helmet>
      <div role='presentation'>
        <Typography variant='h1' gutterBottom mt={1}>
          {makeTitle(genre)} Books
        </Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to={`/${booktype}`}>{makeTitle(booktype)}</Link>

          <Typography color='text.primary'>{makeTitle(genre)} </Typography>
        </Breadcrumbs>
      </div>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>
          <h2>error</h2>
        </Message>
      ) : (
        <>
          <Container>
            <Row>
              {products.map((product) => (
                <Col
                  key={product._id}
                  sm={6}
                  md={6}
                  lg={2}
                  xs={6}
                  className='product-grid-size'
                >
                  <Route
                    render={({ history }) => (
                      <Product history={history} product={product} />
                    )}
                  />
                </Col>
              ))}
              <Container>
                <Stack spacing={1}>
                  <Pagination
                    count={pages}
                    page={page}
                    onChange={handleChange}
                    style={{
                      justifyContent: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                    hidePrevButton
                    hideNextButton
                  />
                </Stack>
              </Container>
            </Row>
          </Container>
        </>
      )}
      <Container>
        <FooterMenu></FooterMenu>
      </Container>
    </Container>
  )
}

export default GenreBookScreen
