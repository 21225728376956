import {
  EBOOK_LIST_FAIL,
  EBOOK_LIST_REQUEST,
  EBOOK_LIST_SUCCESS,
} from '../constants/ebookConstants'

export const listEbookReducer = (state = {}, action) => {
  switch (action.type) {
    case EBOOK_LIST_REQUEST:
      return {
        loading: true,
      }
    case EBOOK_LIST_SUCCESS:
      return {
        loading: false,
        ebooks: action.payload,
      }
    case EBOOK_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
