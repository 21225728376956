import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Table, Row, Col, Image, Modal } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import {
  register,
  sellerRegisOTPVerify,
  sellerregister,
  forgotPassword,
} from '../../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'
import SocialLogin from '../../../components/SocialLogin'
import FormContainer from '../../../components/FormContainer'

import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/plots/meta/HomeMeta'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FaTree } from 'react-icons/fa'

import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import { SHIPPING_STATE } from '../../../constants/orderConstants'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Helmet } from 'react-helmet'

const SellerRegisterScreen = ({ history, match, location }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()
  const redirect = location.search ? location.search.split('=')[1] : '/'
  const userAgent = window.navigator.userAgent.toLowerCase()
  const cart = useSelector((state) => state.cart)
  const { shippingAddress, loading: cartloading, cartItems, cartSuccess } = cart
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [passwordMessage, setPasswordMessage] = useState('')
  const [message, setMessage] = useState(null)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [address, setAddress] = useState(shippingAddress.address)
  const [address2, setAddress2] = useState(shippingAddress.address2)
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [phone, setPhone] = useState(shippingAddress.phone)
  const [state, setState] = useState(shippingAddress.state)
  const [selectedAddress, setSelectedAddress] = useState({})
  const [emailError, setEmailError] = useState('')
  const [phoneError, setPhoneError] = useState(null)
  const [remail, setRemail] = useState('')

  const [numofbooks, setNumofbooks] = useState('')
  const [zipError, setZipError] = useState(null)
  const [otpError, setOtpError] = useState(null)
  const [otp, setOTP] = useState('')
  const [disable, setDisable] = React.useState(false)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const [showResetPassMessage, setShowResetPassMessage] = useState('')

  const sellerOtpVerify = useSelector((state) => state.sellerOtpVerify)
  const {
    loading: otploading,
    error: otperror,
    success: otpSuccess,
    result,
  } = sellerOtpVerify

  const userSellerRegister = useSelector((state) => state.userSellerRegister)
  const {
    loading,
    error,
    success: registerSellerSuccess,
    userInfo,
  } = userSellerRegister
  const forgotPasswordRed = useSelector((state) => state.forgotPassword)
  const {
    loading: fploading,
    error: fperror,
    result: resetResult,
    success: fpsuccess,
  } = forgotPasswordRed
  const handleCloseshow2 = () => {
    setShow2(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    dispatch(sellerRegisOTPVerify(otp, phone))
  }

  const submitHandler = (e) => {
    e.preventDefault()
    setPasswordMessage('')
    if (password.length < 5) {
      setPasswordMessage('Password is too short')
    } else {
      var isSocial = false
      dispatch(
        sellerregister({
          name,
          email,
          password,
          isSocial,
          address,
          address2,
          city,
          postalCode,
          state,
          phone,
          numofbooks,
        })
      )
    }
  }
  const forgotPasswordHandler = (e) => {
    e.preventDefault()
    setShow2(true)
  }
  const handleClose = (e) => {
    setShow(false)
  }
  const forgotpasswordsubmitHandler = (e) => {
    e.preventDefault()
    dispatch(forgotPassword({ email: remail }))
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (otpSuccess && userInfo) {
      if (result.result) {
        history.push('/profile')
      } else {
        setOtpError('Number is not Verified')
      }
    }
    if (registerSellerSuccess && userInfo) {
      if (userInfo.error) {
        setEmailError(userInfo.errorMessage)
      } else {
        setShow(true)
      }
    }
    if (fpsuccess) {
      if (resetResult.error && resetResult.errorMessage) {
        setShowResetPassMessage(resetResult.errorMessage)
      } else {
        setShowResetPassMessage('Password Reset Email has been Sent')
        setRemail('')
      }
    }
  }, [
    dispatch,
    userInfo,
    registerSellerSuccess,
    otpSuccess,
    emailError,
    fpsuccess,
  ])
  return (
    <>
      <Container>
        <Helmet>
          <title>Seller Registration</title>
        </Helmet>
        <Container>
          <Row>
            <FormContainer>
              <div style={{ textAlign: 'center', marginBottom: '8px' }}>
                <Avatar
                  sx={{ m: 1 }}
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                >
                  <LockOutlinedIcon />
                </Avatar>
                <h1>Sign up as a seller</h1>
              </div>
              {!userAgent.includes('wv') && (
                <div className='social-logins'>
                  <SocialLogin />
                </div>
              )}

              <br />
              {message && <Message variant='danger'>{message}</Message>}
              {error && <Message variant='danger'>{error}</Message>}
              {/* {loading && <Loader></Loader>} */}
              {emailError && (
                <div>
                  <span className='red-text'>{emailError}</span>{' '}
                  <Link to={''} onClick={(e) => forgotPasswordHandler(e)}>
                    Forgot password?
                  </Link>
                  <br />
                  <br />
                </div>
              )}
              <form onSubmit={submitHandler}>
                <TextField
                  id='outlined-basic'
                  label='Full Name'
                  variant='outlined'
                  required
                  type='Text'
                  autoComplete='off'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
                <TextField
                  id='outlined-basic'
                  label='How Many Books Do You Have?'
                  variant='outlined'
                  required
                  type='Number'
                  value={numofbooks}
                  onChange={(e) => setNumofbooks(e.target.value)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />

                <TextField
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  required
                  type='Email'
                  autoComplete='off'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
                {passwordMessage && (
                  <div className='red-text'>{passwordMessage}</div>
                )}

                <TextField
                  id='outlined-basic'
                  label='Password'
                  variant='outlined'
                  required
                  type='Password'
                  autoComplete='off'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
                <TextField
                  id='outlined-basic'
                  label='Address'
                  variant='outlined'
                  required
                  type='Text'
                  autoComplete='off'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
                <TextField
                  id='outlined-basic'
                  label='Apartment, Suite, etc (Optional)'
                  variant='outlined'
                  type='Text'
                  autoComplete='off'
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
                <TextField
                  id='outlined-basic'
                  label='City'
                  variant='outlined'
                  type='Text'
                  required
                  autoComplete='off'
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  style={{ width: '48%', marginBottom: '10px' }}
                />
                <TextField
                  id='outlined-basic'
                  label='Zip Code'
                  variant='outlined'
                  type='Number'
                  required
                  autoComplete='off'
                  error={zipError ? true : false}
                  helperText={zipError ? zipError : ''}
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  style={{
                    width: '48%',
                    marginBottom: '10px',
                    marginLeft: '10px',
                  }}
                />

                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>State</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    required
                    label='State'
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    {SHIPPING_STATE.map((state) => (
                      <MenuItem value={state} key={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <small style={{ paddingTop: '8px' }}>
                  Whatsapp OTP verification required
                </small>
                <TextField
                  id='outlined-basic'
                  label='Phone Number'
                  variant='outlined'
                  type='phone'
                  margin='normal'
                  autoComplete='off'
                  error={phoneError ? true : false}
                  required
                  helperText={phoneError ? phoneError : ''}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ width: '100%' }}
                />

                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  className='theme-button'
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>
                <div>
                  <div style={{ textAlign: 'center', fontSize: '14px' }}>
                    <Link
                      to={redirect ? `/login?redirect=${redirect}` : '/login'}
                      variant='body2'
                    >
                      Already have an account? Sign In
                    </Link>
                  </div>
                </div>
              </form>
            </FormContainer>
          </Row>
          <Row>
            <br />
          </Row>
        </Container>
        <Modal show={show} onHide={handleClose} backdrop='static'>
          <Modal.Body>
            {otploading ? (
              <Loader />
            ) : (
              <Form onSubmit={submitHandler2}>
                <div style={{ padding: '20px' }}>
                  <Form.Group>
                    <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                      Enter OTP sent your Phone
                    </div>
                    <Form.Text className='red-text'>
                      {/* {otpMessage && <span>{errorMessage}</span>} */}
                    </Form.Text>

                    <TextField
                      id='outlined-basic'
                      label='Enter OTP'
                      variant='outlined'
                      required
                      type='Number'
                      autoComplete='off'
                      value={otp}
                      error={otpError ? true : false}
                      helperText={otpError ? otpError : ''}
                      onChange={(e) => setOTP(e.target.value)}
                      style={{ width: '100%', marginBottom: '10px' }}
                    />
                  </Form.Group>
                  <Row>
                    <Col>
                      <Button
                        type='submit'
                        variant='contained'
                        className='theme-button'
                        fullWidth
                      >
                        Verify OTP
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Modal.Body>
        </Modal>
        <Modal show={show2} onHide={handleCloseshow2}>
          <Modal.Body>
            <Row>
              <Col>
                <Button
                  variant='secondary'
                  onClick={handleCloseshow2}
                  style={{ float: 'right' }}
                >
                  X
                </Button>
              </Col>
            </Row>

            <form
              onSubmit={forgotpasswordsubmitHandler}
              className='cart-form'
              style={{ padding: '22px' }}
            >
              <Typography variant='h6'>Forgot Password?</Typography>
              <Typography variant='body2'>
                Enter your email to reset password
              </Typography>
              {showResetPassMessage && (
                <span className='red-text'>{showResetPassMessage}</span>
              )}

              <TextField
                id='outlined-basic'
                label='Email Address'
                variant='outlined'
                required
                type='Email'
                autoComplete='off'
                value={remail || ''}
                onChange={(e) => setRemail(e.target.value)}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  marginTop: '15px',
                }}
              />
              <Button
                type='submit'
                variant='contained'
                fullWidth
                className='theme-button'
              >
                Send Password Reset Link
              </Button>
            </form>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}
{
}

export default SellerRegisterScreen
