import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Row, Col, Card, ListGroupItem, Container } from 'react-bootstrap'
const SearchBox = ({ history }) => {
  const [keywords, setKeywords] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()
    if (keywords.trim()) {
      history.push(`/app/en/search/${keywords}`)
    } else {
      history.push('/')
    }
  }
  return (
    <Container>
      <Row>
        <Container>
          <Form onSubmit={submitHandler} inline>
            <Row className='text-center'>
              <Col xs={10}>
                <div className='search-bar-lp'>
                  <Form.Control
                    type='text'
                    name='q'
                    onChange={(e) => setKeywords(e.target.value)}
                    placeholder='Book Title'
                    className='mr-sm-2 ml-sm-5 searchbar'
                  ></Form.Control>
                </div>
              </Col>
              <Col xs={2}>
                <div>
                  <Button
                    type='submit'
                    variant='outline-success'
                    className='search-button-mobile search-icon'
                  >
                    <i className='fas fa-search'></i> 
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </Row>
    </Container>
  )
}

export default SearchBox
