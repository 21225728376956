import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { TextField } from '@mui/material'
import FormContainer from '../components/FormContainer'
import { trackOrder } from '../actions/orderActions'
import Message from '../components/Message'

const TrackOrderScreen = ({ history }) => {
  const dispatch = useDispatch()
  const [orderNumber, setOrderNumber] = useState('')
  const [orderNumberError, setOrderNumberError] = useState('')
  const orderTrack = useSelector((state) => state.orderTrack)
  const { loading, success, error, data } = orderTrack
  useEffect(() => {
    window.scrollTo(0, 0)
    if (success) {
      setOrderNumber('')
    }
  }, [dispatch, success, data])

  const unsubscribeHandler = (e) => {
    if (orderNumber.length > 8) {
      e.preventDefault()
      dispatch(trackOrder(orderNumber))
    } else {
      setOrderNumberError('Enter Valid Order Number')
    }
  }
  return (
    <Container>
      <Container>
        <Row>
          <h2>Track Your Order</h2>
          <p>Enter Your Order Id (Starts with 99BOOKSCARTXXXX)</p>
        </Row>
        <FormContainer>
          <form onSubmit={unsubscribeHandler}>
            <Row>
              <Col>
                <TextField
                  id='outlined-basic'
                  label='Order Number'
                  variant='outlined'
                  required
                  type='Text'
                  autoComplete='off'
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type='submit'
                  variant='primary'
                  style={{ float: 'right', marginBottom: '20px'}}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Track Your Order
                </Button>
              </Col>
            </Row>
            <Row>
              {success &&
                (data[0].tracking_data &&
                data[0].tracking_data.shipment_track[0] ? (
                  <>
                    <div>
                      <strong>Destination:</strong>{' '}
                      {data[0].tracking_data.shipment_track[0].destination}
                    </div>
                    <div>
                      <strong>Courier Name:</strong>{' '}
                      {data[0].tracking_data.shipment_track[0].courier_name}
                    </div>
                    <div>
                      <strong>Estimated Delivery Date:</strong>{' '}
                      {data[0].tracking_data.shipment_track[0].edd}
                    </div>
                    <div>
                      <h4>Shipment Activity</h4>
                      {data[0].tracking_data.shipment_track_activities ? (
                        <>
                          {data[0].tracking_data.shipment_track_activities.map(
                            (at) => (
                              <p>
                                {at.date}
                                <br />
                                <strong>{at.activity}</strong>
                              </p>
                            )
                          )}
                        </>
                      ) : (
                        <p>No Tracking Activities</p>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <br />
                    <Message variant='success' >
                      Your Order is ready waiting to be picked up by Courier
                      Service
                    </Message>
                  </>
                ))}
            </Row>
          </form>
        </FormContainer>
      </Container>
    </Container>
  )
}

export default TrackOrderScreen
