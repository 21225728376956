import {
  USER_FOLLOWER_LIST_FAIL,
  USER_FOLLOWER_LIST_REQUEST,
  USER_FOLLOWER_LIST_SUCESS,
  USER_FOLLOWING_LIST_FAIL,
  USER_FOLLOWING_LIST_REQUEST,
  USER_FOLLOWING_LIST_SUCESS,
  USER_FOLLOW_FAIL,
  USER_FOLLOW_REQUEST,
  USER_FOLLOW_SUCESS,
} from '../constants/followerConstant'

export const userFollowReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case USER_FOLLOW_REQUEST:
      return {
        loading: true,
      }
    case USER_FOLLOW_SUCESS:
      return {
        loading: false,
        success: true,
        user: action.payload,
      }
    case USER_FOLLOW_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getFollowerReducer = (
  state = { followers: [], success: false },
  action
) => {
  switch (action.type) {
    case USER_FOLLOWER_LIST_REQUEST:
      return {
        loading: true,
      }
    case USER_FOLLOWER_LIST_SUCESS:
      return {
        loading: false,
        success: true,
        followers: action.payload.followers,
      }
    case USER_FOLLOWER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getFollowingReducer = (
  state = { success: false, followings: [] },
  action
) => {
  switch (action.type) {
    case USER_FOLLOWING_LIST_REQUEST:
      return {
        loading: true,
      }
    case USER_FOLLOWING_LIST_SUCESS:
      return {
        loading: false,
        success: true,
        followings: action.payload.followings,
      }
    case USER_FOLLOWING_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
