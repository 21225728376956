import axios from 'axios'
import { WISHLIST_ADD_ITEM } from '../constants/cartConstants'
import {
  USER_READ_PRODUCT_REQUEST,
  USER_INTREST_ADD_FAIL,
  USER_INTREST_ADD_REQUEST,
  USER_INTREST_ADD_SUCESS,
  USER_INTREST_LIST_FAIL,
  USER_INTREST_LIST_REQUEST,
  USER_INTREST_LIST_SUCESS,
  USER_INTREST_PRODUCT_FAIL,
  USER_INTREST_PRODUCT_REQUEST,
  USER_INTREST_PRODUCT_SUCESS,
  USER_READ_ADD_FAIL,
  USER_READ_ADD_REQUEST,
  USER_READ_ADD_SUCESS,
  USER_READ_LIST_FAIL,
  USER_READ_LIST_REQUEST,
  USER_READ_LIST_SUCESS,
  USER_READ_PRODUCT_FAIL,
  USER_READ_PRODUCT_SUCESS,
  USER_WISHLIST_ADD_FAIL,
  USER_WISHLIST_ADD_REQUEST,
  USER_WISHLIST_ADD_SUCESS,
  USER_WISHLIST_LIST_FAIL,
  USER_WISHLIST_LIST_REQUEST,
  USER_WISHLIST_LIST_SUCESS,
  USER_WISHLIST_REMOVE_REQUEST,
  USER_WISHLIST_REMOVE_SUCESS,
  USER_WISHLIST_REMOVE_FAIL,
  USER_INTREST_REMOVE_REQUEST,
  USER_INTREST_REMOVE_SUCESS,
  USER_INTREST_REMOVE_FAIL,
  USER_READ_REMOVE_REQUEST,
  USER_READ_REMOVE_SUCESS,
  USER_READ_REMOVE_FAIL,
} from '../constants/wishlistConstants'

export const addToWIshlist = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_WISHLIST_ADD_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/wishlist/add/${product._id}`, config)

   

    dispatch({
      type: USER_WISHLIST_ADD_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_WISHLIST_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getWishlist = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_WISHLIST_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/wishlist/list`, config)

    dispatch({
      type: USER_WISHLIST_LIST_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_WISHLIST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addToIntrested = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_INTREST_ADD_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/intrested/add/${id}`, config)

    dispatch({
      type: USER_INTREST_ADD_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_INTREST_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getIntrested = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_INTREST_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/intrested/user/list`, config)

    dispatch({
      type: USER_INTREST_LIST_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_INTREST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addToAlreadRead = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_READ_ADD_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/alreadyread/add/${id}`, config)

    dispatch({
      type: USER_READ_ADD_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_READ_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAlreadyRead = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_READ_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/alreadyread/user/list`, config)

    dispatch({
      type: USER_READ_LIST_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_READ_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getIntrestsByProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_INTREST_PRODUCT_REQUEST,
    })

    const { data } = await axios.get(`/api/intrested/${id}`)

    dispatch({
      type: USER_INTREST_PRODUCT_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_INTREST_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAlreadyReadsByProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_READ_PRODUCT_REQUEST,
    })

    const { data } = await axios.get(`/api/alreadyread/${id}`)

    dispatch({
      type: USER_READ_PRODUCT_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_READ_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const removeWishlist = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_WISHLIST_REMOVE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/wishlist/remove/${id}`, config)

    dispatch({
      type: USER_WISHLIST_REMOVE_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_WISHLIST_REMOVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const removeIntrest = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_INTREST_REMOVE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/intrested/remove/${id}`, config)

    dispatch({
      type: USER_INTREST_REMOVE_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_INTREST_REMOVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const removeAlreadyRead = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_READ_REMOVE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/alreadyread/remove/${id}`, config)

    dispatch({
      type: USER_READ_REMOVE_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_READ_REMOVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
