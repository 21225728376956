import {
  PRODUCT_LIST_REQUST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_REQUST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_REQUST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_RESET,
  PRODUCT_UPDATE_REQUST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_CREATE_REVIEW_REQUST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_PRODUCT_TOP_REQUST,
  PRODUCT_PRODUCT_TOP_SUCCESS,
  PRODUCT_PRODUCT_TOP_FAIL,
  PRODUCT_BESTSELLING_REQUST,
  PRODUCT_BESTSELLING_SUCCESS,
  PRODUCT_BESTSELLING_FAIL,
  PRODUCT_REDIRECT_REQUST,
  PRODUCT_REDIRECT_SUCCESS,
  PRODUCT_REDIRECT_FAIL,
  PRODUCT_SCAN_REQUST,
  PRODUCT_SCAN_SUCCESS,
  PRODUCT_SCAN_FAIL,
  PRODUCT_UPSERT_REQUST,
  PRODUCT_UPSERT_SUCCESS,
  PRODUCT_UPSERT_FAIL,
  PRODUCT_AT_REQUST,
  PRODUCT_AT_SUCCESS,
  PRODUCT_AT_FAIL,
  PRODUCT_ALL_LIST_REQUST,
  PRODUCT_ALL_LIST_SUCCESS,
  PRODUCT_ALL_LIST_FAIL,
  SELLER_PRODUCT_SCAN_REQUST,
  SELLER_PRODUCT_SCAN_SUCCESS,
  SELLER_PRODUCT_SCAN_FAIL,
  PRODUCT_BY_TYPE_SUCCESS,
  PRODUCT_BY_TYPE_FAIL,
  PRODUCT_BY_TYPE_REQUST,
  PRODUCT_BY_GENRE_REQUST,
  PRODUCT_BY_GENRE_SUCCESS,
  PRODUCT_BY_GENRE_FAIL,
} from '../constants/productConstants'
import crypto from 'crypto'

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUST:
      return {
        loading: true,
        products: [],
      }
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case PRODUCT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const productDetailsReducer = (
  state = { product: { categories: [], genres: [], reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUST:
      return {
        loading: true,
        ...state,
      }
    case PRODUCT_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload.product,
        bookAttribute: action.payload.product.bookAttribute,
        readingCount: action.payload.readingCount,
        inventory: action.payload.inventory,
      }
    case PRODUCT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUST:
      return {
        loading: true,
      }
    case PRODUCT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case PRODUCT_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUST:
      return {
        loading: true,
      }
    case PRODUCT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload,
      }
    case PRODUCT_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case PRODUCT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const productUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUST:
      return {
        loading: true,
      }
    case PRODUCT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload,
      }
    case PRODUCT_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case PRODUCT_UPDATE_RESET:
      return { product: {} }
    default:
      return state
  }
}

export const productReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUST:
      return {
        loading: true,
      }
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case PRODUCT_CREATE_REVIEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case PRODUCT_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}
export const productTopRatedReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_PRODUCT_TOP_REQUST:
      return {
        loading: true,
        products: [],
      }
    case PRODUCT_PRODUCT_TOP_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      }
    case PRODUCT_PRODUCT_TOP_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const productBestSellingReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_BESTSELLING_REQUST:
      return {
        loading: true,
        products: [],
      }
    case PRODUCT_BESTSELLING_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      }
    case PRODUCT_BESTSELLING_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const productRedirectReducer = (
  state = { product: { categories: [], reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_REDIRECT_REQUST:
      return {
        loading: true,
        ...state,
      }
    case PRODUCT_REDIRECT_SUCCESS:
      return {
        loading: false,
        product: action.payload,
        success: true,
        readingCount: 0,
      }
    case PRODUCT_REDIRECT_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const productScanReducer = (
  state = { product: { categories: [], reviews: [] }, success: false },
  action
) => {
  switch (action.type) {
    case PRODUCT_SCAN_REQUST:
      return {
        loading: true,
        ...state,
      }
    case PRODUCT_SCAN_SUCCESS:
      return {
        loading: false,
        product: action.payload,
        success: true,
      }
    case PRODUCT_SCAN_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const productUpsertReducer = (
  state = { product: { categories: [], reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_UPSERT_REQUST:
      return {
        loading: true,
        ...state,
      }
    case PRODUCT_UPSERT_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload,
      }
    case PRODUCT_UPSERT_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getProductAttributesReducer = (
  state = { product: { bookAttribute: {} } },
  action
) => {
  switch (action.type) {
    case PRODUCT_AT_REQUST:
      return {
        loading: true,
        ...state,
      }
    case PRODUCT_AT_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload,
        bookAttribute: action.payload.bookAttribute,
      }
    case PRODUCT_AT_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const productListAllReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_ALL_LIST_REQUST:
      return {
        loading: true,
      }
    case PRODUCT_ALL_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        products: action.payload,
      }
    case PRODUCT_ALL_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const sellerProductScanReducer = (
  state = {
    loading: true,
    product: { categories: [], reviews: [] },
    success: false,
    newBook: false,
  },
  action
) => {
  switch (action.type) {
    case SELLER_PRODUCT_SCAN_REQUST:
      return {
        loading: true,
        ...state,
      }
    case SELLER_PRODUCT_SCAN_SUCCESS:
      return {
        loading: false,
        product: action.payload.book,
        newBook: action.payload.newBook,
        success: true,
      }
    case SELLER_PRODUCT_SCAN_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const productListByTypeReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_BY_TYPE_REQUST:
      return {
        loading: true,
        products: [],
      }
    case PRODUCT_BY_TYPE_SUCCESS:
      return {
        loading: false,
        success: true,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case PRODUCT_BY_TYPE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const productListByGenreReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_BY_GENRE_REQUST:
      return {
        loading: true,
        products: [],
      }
    case PRODUCT_BY_GENRE_SUCCESS:
      return {
        loading: false,
        success: true,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case PRODUCT_BY_GENRE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
