import React, { useState, useEffect } from 'react'
import { Table, Form, Button, Row, Col, Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { createNewBookFair } from '../actions/bookFairActions'
import { listMyOrders } from '../actions/orderActions'
import { myOrderDetailsReducer } from '../reducers/orderReducers'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { scanProduct } from '../actions/productActions'
import { getBookFEntriesStats } from '../actions/bookFairEntryActions'
import { CREATE_BOOK_FAIR_ENTRY_RESET } from '../constants/bookFairEntryConstants'

const BookFairEntryStatsScreen = ({ location, history, match }) => {
  const [isbn, setIsbn] = useState('')
  const dispatch = useDispatch()
 
  const listBookFairEntryStats = useSelector(
    (state) => state.listBookFairEntryStats
  )
  const { loading, error, stats, success } = listBookFairEntryStats

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(getBookFEntriesStats(match.params.id))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  return (
    <Container>
      <Row>
        {loading ? (
          <Loader></Loader>
        ) : (
          <>
            

            {stats.map((stat) => (
              <div key={stat._id}>
                {stat._id}
                <br />
                <strong className='right'>
                  {stat.sum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}(
                  {stat.count})
                </strong>

                <Table>
                  <thead>
                    <tr>
                      <th>Payment Method</th>
                      <th>Count</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stat.books.map((book) => (
                      <tr key={book._id}>
                        <td>{paymentType(book.paymentMethod)}</td>
                        <td>{book.count}</td>
                        <td>
                          {book.sum
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ))}
          </>
        )}
      </Row>
    </Container>
  )
}
const paymentType = (type) => {
  return type == 0 ? 'Cash' : type == 1 ? 'UPI' : type == 2 ? 'CARD' : ''
}
export default BookFairEntryStatsScreen
