import {
  BLOG_CREATET_SUCCESS,
  BLOG_CREATE_FAIL,
  BLOG_CREATE_REQUEST,
  BLOG_GET_FAIL,
  BLOG_GET_REQUEST,
  BLOG_GET_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
} from '../constants/blogConstants'
import {
  STORE_ADD_BOOK_FAIL,
  STORE_ADD_BOOK_REQUEST,
  STORE_ADD_BOOK_SUCCESS,
  STORE_ADMIN_LIST_FAIL,
  STORE_ADMIN_LIST_REQUEST,
  STORE_ADMIN_LIST_SUCCESS,
  STORE_CREATET_SUCCESS,
  STORE_CREATE_FAIL,
  STORE_CREATE_REQUEST,
  STORE_GET_BYID_FAIL,
  STORE_GET_BYID_REQUEST,
  STORE_GET_BYID_SUCCESS,
  STORE_GET_FAIL,
  STORE_GET_REQUEST,
  STORE_GET_SUCCESS,
  STORE_OTP_FAIL,
  STORE_OTP_REQUEST,
  STORE_OTP_SUCCESS,
  STORE_VERIFY_FAIL,
  STORE_VERIFY_REQUEST,
  STORE_VERIFY_SUCCESS,
} from '../constants/storeConstant'
import {} from '../constants/userConstants'

export const createStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_CREATE_REQUEST:
      return {
        loading: true,
      }
    case STORE_CREATET_SUCCESS:
      return {
        loading: false,
        success: true,
        blog: action.payload,
      }
    case STORE_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const listBlogReducer = (state = { blogs: [] }, action) => {
  switch (action.type) {
    case BLOG_LIST_REQUEST:
      return {
        loading: true,
      }
    case BLOG_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        blogs: action.payload,
      }
    case BLOG_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getStoreReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case STORE_GET_REQUEST:
      return {
        loading: true,
      }
    case STORE_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
      }
    case STORE_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const otpStoreReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case STORE_OTP_REQUEST:
      return {
        loading: true,
      }
    case STORE_OTP_SUCCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
      }
    case STORE_OTP_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const otpStoreVerifyReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case STORE_VERIFY_REQUEST:
      return {
        loading: true,
      }
    case STORE_VERIFY_SUCCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
      }
    case STORE_VERIFY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const addBookToStoreReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case STORE_ADD_BOOK_REQUEST:
      return {
        loading: true,
      }
    case STORE_ADD_BOOK_SUCCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
      }
    case STORE_ADD_BOOK_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const getStoreByIdReducer = (
  state = { store: {}, products: [] },
  action
) => {
  switch (action.type) {
    case STORE_GET_BYID_REQUEST:
      return {
        loading: true,
      }
    case STORE_GET_BYID_SUCCESS:
      return {
        loading: false,
        success: true,
        store: action.payload.store,
        products: action.payload.products,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case STORE_GET_BYID_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const listAdminStoresReducer = (state = { stores: [] }, action) => {
  switch (action.type) {
    case STORE_ADMIN_LIST_REQUEST:
      return {
        loading: true,
      }
    case STORE_ADMIN_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        stores: action.payload.stores,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case STORE_ADMIN_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
