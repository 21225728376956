import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import {
  Table,
  Form,
  Button,
  Row,
  Col,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserDesc } from '../actions/userActions'
import { listMyOrders } from '../actions/orderActions'
import { myOrderDetailsReducer } from '../reducers/orderReducers'
import { LinkContainer } from 'react-router-bootstrap'
import { getUserCoins } from '../actions/coinActions'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {
  FaBookOpen,
  FaBoxOpen,
  FaCartArrowDown,
  FaCoins,
  FaEdit,
  FaFileUpload,
  FaGrinHearts,
  FaMoneyBillAlt,
  FaMoneyCheck,
  FaShippingFast,
  FaThList,
  FaTools,
} from 'react-icons/fa'
import Product from '../components/Product'
import {
  getIntrested,
  getAlreadyRead,
  removeIntrest,
  removeAlreadyRead,
} from '../actions/wishlistActions'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { logout } from '../actions/userActions'
const useStyles = makeStyles({
  tabContent: {
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginTop: '10px',
  },
})

const ProfileScreen = ({ location, history, getState }) => {
  const dispatch = useDispatch()
  const [gender, setGender] = useState('')
  const [description, setDescription] = useState('')
  const [show, setShow] = useState(false)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState('seller')

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }
  const userIntrests = useSelector((state) => state.intrestGet)
  const {
    success: intrestsSucces,
    loading: intrestsLoading,
    intrests,
  } = userIntrests

  const userAlreadyReads = useSelector((state) => state.alreadyReadGet)
  const {
    success: alreadyReadsSucces,
    loading: alreadyReadsLoading,
    alreadyReads,
  } = userAlreadyReads

  const userIntrestRemove = useSelector((state) => state.intrestRemove)
  const { success: intrestsRemoveSucces } = userIntrestRemove

  const userAlreadyReadRemove = useSelector((state) => state.alreadyReadRemove)
  const { success: alreadyReadRemoveSucces } = userAlreadyReadRemove

  const userDetails = useSelector((state) => state.userDetails)
  const {
    loading,
    error,
    user: loggedInUser,
    success: userSuccess,
  } = userDetails

  const updateUserDescRed = useSelector((state) => state.updateUserDesc)
  const {
    loading: userUpdateLoading,
    user: userUpdateUser,
    success: userUpdateSuccess,
  } = updateUserDescRed
  const [key, setKey] = useState('home')
  const logoutHandler = () => {
    // dispatch(logout())
    history.push('/')
  }
  useEffect(() => {
    if (userInfo && userInfo._id) {
      dispatch(getUserDetails(userInfo._id))
    } else {
      history.push('/login')

      // dispatch(getIntrested())
      // dispatch(getAlreadyRead())
    }
    if (error) {
      // console.log(error)
      dispatch(logout())
      history.push('/login')
    }
    if (userUpdateSuccess) {
      setShow(false)
      dispatch(getUserDetails(userInfo._id))
    }
  }, [
    dispatch,
    history,
    userInfo,
    intrestsRemoveSucces,
    alreadyReadRemoveSucces,
    userSuccess,
    userUpdateSuccess,
    error,
  ])
  const removeFromIntrest = (id, e) => {
    e.preventDefault()
    dispatch(removeIntrest(id))
  }

  const removeFromAlreadyRead = (id, e) => {
    e.preventDefault()
    dispatch(removeAlreadyRead(id))
  }
  const addDescriptionHandler = (e) => {
    e.preventDefault()
    setShow(true)
  }
  const handleClose = () => setShow(false)

  const submitProfileDescription = (e) => {
    e.preventDefault()
    dispatch(updateUserDesc({ description, gender }))
  }
  return (
    <>
      <Container>
        {!userSuccess && loggedInUser !== 'undefined' ? (
          <Loader></Loader>
        ) : (
          <Container>
            <div style={{ marginTop: '15px', textAlign: 'center' }}>
              <h1 style={{ marginTop: '15px', textAlign: 'center' }}>
                Welcome {userInfo.name}
              </h1>
              <Link to={'/profile/setting'}>
                <FaEdit /> Edit Profile
              </Link>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div>
                <Button
                  className={
                    activeTab === 'seller' ? 'tab-button-a' : 'tab-button'
                  }
                  onClick={() => handleTabClick('seller')}
                >
                  Buyer
                </Button>
                <Button
                  className={
                    activeTab === 'buyer' ? 'tab-button-a' : 'tab-button'
                  }
                  onClick={() => handleTabClick('buyer')}
                >
                  Seller
                </Button>
              </div>
              {activeTab === 'seller' && (
                <Row style={{ marginTop: '22px' }}>
                  <Col class='col-sm-4'>
                    <Card style={{ width: '18rem' }} className='dashcard'>
                      <Card.Body>
                        <Card.Title className='dashcardtitle'>
                          <FaCartArrowDown /> Purchases
                        </Card.Title>
                        <Card.Text>Track your Orders</Card.Text>
                        <Link to={'/profile/orders'}>
                          <Button variant='primary' className='dashButton'>
                            Details
                          </Button>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col class='col-sm-4'>
                    <Card style={{ width: '18rem' }} className='dashcard'>
                      <Card.Body>
                        <Card.Title className='dashcardtitle'>
                          <FaGrinHearts /> Wishlist
                        </Card.Title>
                        <Card.Text>Grow Your Wish Library</Card.Text>
                        <Link to={'/profile/mylibrary'}>
                          <Button variant='primary' className='dashButton'>
                            Details
                          </Button>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col class='col-sm-4'>
                    <Card style={{ width: '18rem' }} className='dashcard'>
                      <Card.Body>
                        <Card.Title className='dashcardtitle'>
                          <FaCoins /> Coins
                        </Card.Title>
                        <Card.Text>Coins and Discounts</Card.Text>
                        <Link to={'/profile/coins'}>
                          <Button variant='primary' className='dashButton'>
                            Details
                          </Button>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
              {activeTab === 'buyer' && (
                <Row style={{ marginTop: '22px' }}>
                  <h2>
                    Sustainable Reading! Sell your old books and make room for
                    new.
                  </h2>
                  <Col class='col-sm-4'>
                    <Card style={{ width: '18rem' }} className='dashcard'>
                      <Card.Body>
                        <Card.Title className='dashcardtitle'>
                          <FaFileUpload /> Books
                        </Card.Title>
                        <Card.Text>Upload & Sell Books</Card.Text>
                        <Link to={'/sell/new/book'}>
                          <Button variant='primary' className='dashButton'>
                            Details
                          </Button>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col class='col-sm-4'>
                    <Card style={{ width: '18rem' }} className='dashcard'>
                      <Card.Body>
                        <Card.Title className='dashcardtitle'>
                          <FaShippingFast /> Sold
                        </Card.Title>
                        <Card.Text>Recieved Orders & Shipping</Card.Text>
                        <Link to={'/seller/orders'}>
                          <Button variant='primary' className='dashButton'>
                            Details
                          </Button>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col class='col-sm-4'>
                    <Card style={{ width: '18rem' }} className='dashcard'>
                      <Card.Body>
                        <Card.Title className='dashcardtitle'>
                          <FaMoneyCheck /> Earned{' '}
                        </Card.Title>
                        <Card.Text>Manage Earnings & Credits</Card.Text>
                        <Link to={'/seller/payment'}>
                          <Button variant='primary' className='dashButton'>
                            Details
                          </Button>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </div>

            {/* <Row>
              <Col xs={12} sm={6}>
                <div className='text-center '>
                  <LazyLoadImage
                    src={loggedInUser.profileImage}
                    height={'150'}
                    width={'150'}
                    alt={`${loggedInUser.name} 99bookscart profile image`}
                    style={{ borderRadius: '130px' }}
                  />

                  <br />
                  <div>{loggedInUser && loggedInUser.name}</div>

                  <div
                    style={{ width: '350px', color: '#fff' }}
                    className='center-block'
                  >
                    <Link
                      to='/profile/orders'
                      className=' seller-profile-button'
                    >
                      <FaBoxOpen></FaBoxOpen> Purchases
                    </Link>
                    <Link
                      to='/profile/mylibrary'
                      className=' seller-profile-button'
                    >
                      <FaThList></FaThList> My Library
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <Row>
                  <Col style={{ textAlign: 'center' }}>
                    <div className='text-center '>
                      <Typography variant='h6'>Sustainable Reading</Typography>
                      <Typography>
                        Sell your old books and make room for new books.
                      </Typography>
                      <Link
                        to='/seller/store'
                        className=' seller-profile-button'
                      >
                        <FaBookOpen> </FaBookOpen> Store
                      </Link>
                      <Link
                        to='/seller/books'
                        className=' seller-profile-button'
                      >
                        <FaBookOpen> </FaBookOpen> Books
                      </Link>
                      <Link
                        to='/sell/new/book'
                        className=' seller-profile-button'
                      >
                        <FaBoxOpen></FaBoxOpen> Add Books
                      </Link>
                      <Link
                        to='/seller/orders'
                        className=' seller-profile-button'
                      >
                        <FaMoneyBillAlt></FaMoneyBillAlt> Orders
                      </Link>
                      <Link
                        to='/seller/payment'
                        className=' seller-profile-button'
                      >
                        <FaTools></FaTools> Payments
                      </Link>
                      <Link
                        to='#'
                        className=' seller-profile-button'
                        onClick={logoutHandler}
                      >
                        <FaMoneyBillAlt></FaMoneyBillAlt> Logout
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row> */}

            <Container>
              <Row>
                {/* <Col xs={12} md={12}>
                  <div style={{ display: 'block', padding: 30 }}>
                    <span className='text-center'>
                      Manage your Virtual Library Here
                    </span>
                    <Tabs defaultActiveKey='first' className='profile-tabs'>
                      <Tab eventKey='first' title='Intrested'>
                        {intrestsLoading ? (
                          <Loader></Loader>
                        ) : (
                          <Row>
                            {intrestsSucces &&
                              intrests.length > 0 &&
                              intrests.map((intrest) => (
                                <Col
                                  key={intrest.product._id}
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  xs={4}
                                >
                                  <>
                                    <Route
                                      render={({ history }) => (
                                        <Product
                                          history={history}
                                          product={intrest.product}
                                          addtocart={false}
                                        />
                                      )}
                                    />
                                    <Link
                                      className='text-center'
                                      onClick={(e) =>
                                        removeFromIntrest(
                                          intrest.product._id,
                                          e
                                        )
                                      }
                                      to=''
                                    >
                                      <i className='fas fa-trash'></i>
                                    </Link>
                                  </>
                                </Col>
                              ))}
                          </Row>
                        )}
                      </Tab>
                      <Tab eventKey='second' title='Already Read'>
                        {alreadyReadsLoading ? (
                          <Loader></Loader>
                        ) : (
                          <Row>
                            {alreadyReadsSucces &&
                              alreadyReads.map((alreadyRead) => (
                                <Col
                                  key={alreadyRead.product._id}
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  xs={4}
                                >
                                  <>
                                    <Route
                                      render={({ history }) => (
                                        <Product
                                          history={history}
                                          product={alreadyRead.product}
                                          addtocart={false}
                                        />
                                      )}
                                    />
                                    {alreadyReadRemoveSucces ? (
                                      'Removed'
                                    ) : (
                                      <Link
                                        className='text-center'
                                        onClick={(e) =>
                                          removeFromAlreadyRead(
                                            alreadyRead.product._id,
                                            e
                                          )
                                        }
                                        to=''
                                      >
                                        <i className='fas fa-trash'></i>
                                      </Link>
                                    )}
                                  </>
                                </Col>
                              ))}
                          </Row>
                        )}
                      </Tab>
                    </Tabs>
                  </div>
                </Col> */}
              </Row>
            </Container>
          </Container>
        )}
      </Container>
    </>
  )
}

export default ProfileScreen
