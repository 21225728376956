import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Form,
  FormGroup,
  Modal,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'

import AdminNav from '../../components/AdminNav'
import {
  getOrderDetails,
  deliverOrder,
  cancelOrder,
  confirmOrder,
  createOrderComment,
  addTagtoOrder,
  currateBox,
} from '../../actions/orderActions'
import {
  createShipment,
  assignAwb,
  getShipmentDetails,
} from '../../actions/shipmentActions'
import {
  ORDER_COMMENT_RESET,
  ORDER_DELEVERED_RESET,
} from '../../constants/orderConstants'

import AIBoxAttributes from '../../components/AIBoxAttributes'

const OrderPrintScreen = ({ match, history }) => {
  const orderId = match.params.id
  const razorId = match.params.razid

  const dispatch = useDispatch()
  const [height, setHeight] = useState(false)
  const [weight, setWeight] = useState(false)
  const [length, setLength] = useState(false)
  const [width, setWidth] = useState(false)
  const [flag1, SetFlag1] = useState(false)
  const [show, setShow] = useState(false)
  const [tLink, setTLink] = useState('')
  const [courierId, setCourierId] = useState('')
  const [shipmentId, setShipmentId] = useState('')
  const [showDimensions, setShowDimensions] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const [sortedItems, setSortedItems] = useState([])

  const [showAvailableCourier, setShowAvailableCourier] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleCloseDimension = () => setShowDimensions(false)
  const handleCloseAvailableCourier = () => setShowAvailableCourier(false)

  const [comment, setComment] = useState('')
  const orderDetails = useSelector((state) => state.orderDetails)

  const { order, loading, error, success, existingOrders, countOrders } =
    orderDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver

  const orderPayStatus = useSelector((state) => state.orderPayStatus)
  const { loading: orderPayStatusLoading } = orderPayStatus

  const currateBoxRes = useSelector((state) => state.currateBox)
  const { success: currateBoxSuccesss } = currateBoxRes

  const orderComments = useSelector((state) => state.orderCommentList)
  const {
    success: successComment,
    error: errorComments,
    comments,
  } = orderComments

  const shipmentCreate = useSelector((state) => state.shipmentCreate)
  const {
    success: successShipment,
    error: errorShipment,
    loading: loadingShipment,
    shipment,
  } = shipmentCreate

  const assignAWB = useSelector((state) => state.assignAWB)
  const {
    success: successIShipment,
    error: errorIShipment,
    loading: loadingIShipment,
    shipment: iShipment,
  } = assignAWB

  const getShipment = useSelector((state) => state.getShipment)
  const {
    success: successOrderShipment,
    error: errorOrderShipment,
    loading: loadingOrderShipment,
    shipment: OrderShipment,
  } = getShipment

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }
    if (
      (!order ||
        order._id !== orderId ||
        successDeliver ||
        orderPayStatusLoading) &&
      !loading &&
      !flag1
    ) {
      dispatch(getOrderDetails(orderId))

      // dispatch(getShipmentDetails(orderId))
      if (successDeliver) {
        dispatch({ type: ORDER_DELEVERED_RESET })
      }
    }
    if (success && order) {
      const sortedData = [...order.orderItems].sort((a, b) => {
        if (a['location'] && a['location'].length > 0) {
          return a['location'][0] - b['location'][0]
        }
      })
      setSortedItems(sortedData)
    }
    if (successComment) {
      setComment('')
      dispatch({ type: ORDER_COMMENT_RESET })
    }
    if (currateBoxSuccesss) {
      window.location.reload(false)
    }
    if (successShipment) {
      // setShowAvailableCourier(false)
      setShowDimensions(false)
      setShowAvailableCourier(true)
    }
    if (successIShipment) {
      window.location.reload(false)
    }
  }, [
    dispatch,
    order,
    history,
    orderId,
    successDeliver,
    orderPayStatusLoading,
    loading,
    successComment,
    successShipment,
    successIShipment,
    currateBoxSuccesss,
  ])

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Container>
          <Row className='print-label'>
            <div className='text-center'>
              <img
                src='https://www.99bookscart.com/images/logo.png'
                width='250px'
              />
            </div>

            <Col xs={6}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h1>99bookscart</h1>
                  <strong>Order Number: {order.orderName}</strong>
                </ListGroup.Item>
                <br />
                <ListGroup.Item>
                  <h4>Shipping Address</h4>

                  {/* <p><strong>Name:</strong>{order.user.name}</p> */}
                  {/* <p><strong>Email:</strong><a href={`mailto:${order.user.email}`}>{order.user.email}</a></p> */}
                  <p>
                    {' '}
                    <strong>Name: </strong>{' '}
                    {order.user ? order.user.name : order.shippingAddress.name}
                    <br />
                    <strong>Email: </strong>{' '}
                    {order.user
                      ? order.user.email
                      : order.shippingAddress.email}
                    <br />
                    <strong>Address:</strong>
                    {order.shippingAddress.address}, <br />
                    {order.shippingAddress.address2},<br />
                    {order.shippingAddress.city},{order.shippingAddress.state},{' '}
                    {order.shippingAddress.postalCode}
                    <br />
                    Payment Method:{!order.isPaid ? 'COD' : 'Paid'}
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col xs={6}>
              <Container>
                <br />
                <br />
                <ListGroup variant='flash'>
                  <ListGroupItem>
                    <h4> Order Summary</h4>
                  </ListGroupItem>
                  <ListGroupItem style={{ paddingBottom: '2px' }}>
                    <Row>
                      <Col>Items</Col>
                      <Col>Rs.{order.itemPrice}</Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem style={{ paddingBottom: '2px' }}>
                    <Row>
                      <Col>Shipping</Col>
                      <Col>Rs.{order.shippingPrice}</Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem style={{ paddingBottom: '2px' }}>
                    <Row>
                      <Col>COD Charges</Col>
                      <Col>Rs.{order.codPrice}</Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem style={{ paddingBottom: '2px' }}>
                    <Row>
                      <Col>Discount</Col>
                      <Col>-Rs.{order.discountPrice}</Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem style={{ paddingBottom: '2px' }}>
                    <Row>
                      <Col>Total</Col>
                      <Col>Rs.{order.totalPrice}</Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Container>
            </Col>
            <Col xs={12}>
              <ListGroup.Item>
                <h4>Books</h4>
                {order.orderItems.length == 0 ? (
                  <Message>Order is Empty</Message>
                ) : (
                  <ListGroup variant='flush'>
                    {order.orderItems.map((item, index) => (
                      <Container>
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={9} xs={9}>
                              <Link to={`/book/${item.isbn}/${item.name}`}>
                                {item.name}
                              </Link>
                              <br />
                              {/* <span>{userInfo.isAdmin && (`Location: `+item.location)}</span> */}
                            </Col>
                            <Col md={3} xs={3}>
                              <span className='productPrice'>
                                Rs.{item.salePrice}
                              </span>
                              <strike>Rs.{item.price}</strike>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </Container>
                    ))}
                  </ListGroup>
                )}
              </ListGroup.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Row>
                <ListGroup.Item>
                  <br />
                  <br />
                  <hr />
                  <h5>Reference</h5>
                  <ListGroup.Item>
                    <h3>
                      Order Number: {order.orderName} ({order.orderItems.length}{' '}
                      Books)
                    </h3>
                    <>
                      {' '}
                      <strong>Name: </strong>{' '}
                      {order.user
                        ? order.user.name
                        : order.shippingAddress.name}
                      <br />
                      <strong>Address:</strong>
                      {order.shippingAddress.address},
                      {order.shippingAddress.address2},
                      {order.shippingAddress.city},{order.shippingAddress.state}
                      , {order.shippingAddress.postalCode}
                    </>
                  </ListGroup.Item>
                  {order.orderItems.length == 0 ? (
                    <Message>Order is Empty</Message>
                  ) : (
                    <ListGroup variant='flush'>
                      {sortedItems.map((item, index) => (
                        <Container>
                          {/* <ListGroup.Item key={index}> */}
                          <Row>
                            <Col sm={12} xs={12} style={{ fontSize: '17px' }}>
                              {index + 1}{' '}
                              <Link to={`/book/${item.isbn}/${item.name}`}>
                                {item.name}{' '}
                                {item.variantName &&
                                  '(' + item.variantName + ')'}
                              </Link>
                              {item.sellerName &&
                              item.sellerName != '99bookscart' ? (
                                <span>
                                  Seller:<strong> {item.sellerName}</strong>
                                </span>
                              ) : (
                                <>
                                  {item.name == 'Mystery Box' ||
                                    (item.name == 'Surprise Box' &&
                                      item.boxtype &&
                                      (item.boxtype == 1
                                        ? '(9 Books)'
                                        : item.boxtype == 2
                                        ? '(15 Books)'
                                        : '(20 Books)'))}
                                  <span style={{ fontSize: '19px' }}>
                                    {item.location.length > 0 &&
                                      `Location: ` + item.location}
                                  </span>
                                </>
                              )}
                            </Col>
                          </Row>
                          {/* </ListGroup.Item> */}
                        </Container>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
                <ListGroupItem style={{ paddingBottom: '2px' }}>
                  <Container>
                    <Row>
                      <Col>Notes</Col>
                    </Row>
                  </Container>
                </ListGroupItem>
              </Row>
            </Col>
          </Row>{' '}
          {/* <br />
          <br /> <br />
          <br />
          {order.isPaid && (
            <Row>
              <Col lg={6}>
                <p
                  style={{
                    fontSize: '36px',
                    fontWeight: 'bold',
                    marginTop: '10px',
                    textTransform: 'capitalize',
                  }}
                >
                  To
                  <br />
                  {order.user.name}
                  <br />
                  {order.shippingAddress.address}, <br />
                  {order.shippingAddress.address2
                    ? order.shippingAddress.address2 + ',' + <br />
                    : ''}
                  {order.shippingAddress.city},{order.shippingAddress.state},{' '}
                  {order.shippingAddress.postalCode},{' '}
                  {order.shippingAddress.phone}
                </p>
              </Col>
              <Col lg={6}>
                <p
                  style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    marginTop: '25px',
                  }}
                >
                  From
                  <br />
                  99bookscart
                  <br />
                  Kalyan Nagar, <br />
                  Near Ayyappa Swamy Temple, Takke Road Vijayapura,Karnataka
                  586108 901-915-8183
                </p>
              </Col>
            </Row>
          )} */}
        </Container>
      )}
    </>
  )
}

export default OrderPrintScreen
