import { FLOW_CREATE_FAIL, FLOW_CREATE_REQUEST, FLOW_CREATE_SUCCESS, FLOW_DELETE_FAIL, FLOW_DELETE_REQUEST, FLOW_DELETE_SUCCESS, FLOW_DETAIL_CREATE_FAIL, FLOW_DETAIL_CREATE_REQUEST, FLOW_DETAIL_CREATE_SUCCESS, FLOW_DETAIL_DELETE_FAIL, FLOW_DETAIL_DELETE_REQUEST, FLOW_DETAIL_DELETE_SUCCESS, FLOW_DETAIL_FAIL, FLOW_DETAIL_REQUEST, FLOW_DETAIL_SUCCESS, FLOW_LIST_FAIL, FLOW_LIST_REQUEST, FLOW_LIST_SUCCESS } from "../constants/flowConstants"



export const flowListReducers = (state = {flows:[]}, action) => {
    switch (action.type) {
        case FLOW_LIST_REQUEST:
            return {
                loading: true
            }
        case FLOW_LIST_SUCCESS:
            return {
                loading: false,
                flows: action.payload,
                success:true
            }
        case FLOW_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const flowDetailReducers = (state = {flowTriggers:[]}, action) => {
    switch (action.type) {
        case FLOW_DETAIL_REQUEST:
            return {
                loading: true
            }
        case FLOW_DETAIL_SUCCESS:
            return {
                loading: false,
                flowTriggers: action.payload,
                success:true
            }
        case FLOW_DETAIL_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const flowCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case FLOW_CREATE_REQUEST:
            return {
                loading: true
            }
        case FLOW_CREATE_SUCCESS:
            return {
                loading: false,
                flow: action.payload,
                success:true
            }
        case FLOW_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const flowDeleteReducer = (state = {success:false }, action) => {
    switch (action.type) {
        case FLOW_DELETE_REQUEST:
            return {
                loading: true,
            }
        case FLOW_DELETE_SUCCESS:
            return {
                loading: false,
                success:true
            }
        case FLOW_DELETE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const flowTriggerCreateReducer = (state = {flowTrigger:{} }, action) => {
    switch (action.type) {
        case FLOW_DETAIL_CREATE_REQUEST:
            return {
                loading: true,
            }
        case FLOW_DETAIL_CREATE_SUCCESS:
            return {
                loading: false,
                flowTrigger:action.payload,
                success:true
            }
        case FLOW_DETAIL_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const flowTriggerDeleteReducer = (state = {success:false }, action) => {
    switch (action.type) {
        case FLOW_DETAIL_DELETE_REQUEST:
            return {
                loading: true,
            }
        case FLOW_DETAIL_DELETE_SUCCESS:
            return {
                loading: false,
                success:true
            }
        case FLOW_DETAIL_DELETE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}
