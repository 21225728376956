import axios from 'axios'
import { AI_FAIL, AI_REQUEST, AI_SUCCEES } from '../constants/aiConstants'
import {
  BLOG_CREATET_SUCCESS,
  BLOG_CREATE_FAIL,
  BLOG_CREATE_REQUEST,
  BLOG_GET_REQUEST,
  BLOG_GET_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
} from '../constants/blogConstants'
import {
  INVENTORY_APPROVE_FAIL,
  INVENTORY_APPROVE_REQUEST,
  INVENTORY_APPROVE_SUCCESS,
  INVENTORY_CREATET_SUCCESS,
  INVENTORY_CREATE_FAIL,
  INVENTORY_CREATE_REQUEST,
  INVENTORY_DELETE_FAIL,
  INVENTORY_DELETE_REQUEST,
  INVENTORY_DELETE_SUCCESS,
  INVENTORY_GET_FAIL,
  INVENTORY_GET_REQUEST,
  INVENTORY_GET_SUCCESS,
  INVENTORY_LIST_FAIL,
  INVENTORY_LIST_REQUEST,
  INVENTORY_LIST_SELLER_FAIL,
  INVENTORY_LIST_SELLER_REQUEST,
  INVENTORY_LIST_SELLER_SUCCESS,
  INVENTORY_LIST_SUCCESS,
  INVENTORY_UPDATE_FAIL,
  INVENTORY_UPDATE_REQUEST,
  INVENTORY_UPDATE_SUCCESS,
} from '../constants/inventoryConstants'

export const createInventory = (inventory) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVENTORY_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/inventory`, inventory, config)
    dispatch({
      type: INVENTORY_CREATET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: INVENTORY_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listInventory = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVENTORY_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(
      `/api/inventory?pageNumber=${pageNumber}`,
      config
    )
    dispatch({
      type: INVENTORY_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: INVENTORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const approveInventory = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVENTORY_APPROVE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/inventory/approve/${id}`, config)
    dispatch({
      type: INVENTORY_APPROVE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: INVENTORY_APPROVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getInventory = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVENTORY_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/inventory/${id}`, config)
    dispatch({
      type: INVENTORY_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: INVENTORY_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const updateInventory = (inventory) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVENTORY_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(
      `/api/inventory/${inventory._id}`,
      inventory,
      config
    )
    dispatch({
      type: INVENTORY_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: INVENTORY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const deleteInventory = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVENTORY_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.delete(`/api/inventory/${id}`, config)
    dispatch({
      type: INVENTORY_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: INVENTORY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getSellerInventory =
  (pageNumber = 1, keyword = '', type = 2) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: INVENTORY_LIST_SELLER_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(
        `/api/inventory/seller?pageNumber=${pageNumber}&keyword=${keyword}&type=${type}`,
        config
      )
      dispatch({
        type: INVENTORY_LIST_SELLER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: INVENTORY_LIST_SELLER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
