// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import BlogMeta from '../components/meta/BlogMeta'
import { getBlog, listBlog } from '../actions/blogActions'
import { listStories } from '../actions/storiesActions'
import { convertToSlug } from '../helpers/helpers'

const ListStoriesScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const listStoriesRes = useSelector((state) => state.listStories)
  const { loading, error, success, stories } = listStoriesRes

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(listStories(pageNumber))
  }, [dispatch, pageNumber])

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          <Row>
            <h1>List Stories</h1>
            {stories &&
              stories.map((story) => (
                <Col key={story._id} sm={4} md={4} lg={2} xs={4}>
                  <Link
                    to={`/web-stories/${story._id}/${convertToSlug(
                      story.title
                    )}`}
                  >
                    <LazyLoadImage
                      src={story.coverImage}
                      width={'100%'}
                      alt={story.title}
                      placeholderSrc={
                        'https://s3-books-images.s3.ap-south-1.amazonaws.com/e130987d-d9e5-4567-9b62-0eb5cced1cec.png'
                      }
                      style={
                        {
                          // backgroundColor: product.imageColor
                          //   ? product.imageColor
                          //   : '#EEEBED',
                        }
                      }
                    />
                  </Link>
                  <div className='storyTitle'>{story.title}</div>
                </Col>
              ))}
          </Row>
        </Container>
      )}
    </>
  )
}

export default ListStoriesScreen
