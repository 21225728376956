import {
  COMMENT_EPISODE_FAIL,
  COMMENT_EPISODE_REQUEST,
  COMMENT_EPISODE_SUCCEES,
  COMMENT_LIST_FAIL,
  COMMENT_LIST_REQUEST,
  COMMENT_LIST_SUCCEES,
  CREATE_NEW_EPISODE_FAIL,
  CREATE_NEW_EPISODE_REQUEST,
  CREATE_NEW_EPISODE_SUCCEES,
  DELETE_EPISODE_FAIL,
  DELETE_EPISODE_REQUEST,
  DELETE_EPISODE_SUCCEES,
  GET_EPISODE_FAIL,
  GET_EPISODE_REQUEST,
  GET_EPISODE_SUCCEES,
  GET_FREE_EPISODE_FAIL,
  GET_FREE_EPISODE_REQUEST,
  GET_FREE_EPISODE_SUCCEES,
  READ_EPISODE_REQUEST,
  READ_EPISODE_SUCCEES,
} from '../constants/episodeConstants'

export const createEpisodeReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_EPISODE_REQUEST:
      return {
        loading: true,
      }
    case CREATE_NEW_EPISODE_SUCCEES:
      return {
        loading: false,
        suceess: true,
        data: action.payload,
      }
    case CREATE_NEW_EPISODE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getEpisodeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EPISODE_REQUEST:
      return {
        loading: true,
      }
    case GET_EPISODE_SUCCEES:
      return {
        loading: false,
        suceess: true,
        episode: action.payload,
      }
    case GET_EPISODE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const readEpisodeReducer = (state = {}, action) => {
  switch (action.type) {
    case READ_EPISODE_REQUEST:
      return {
        loading: true,
      }
    case READ_EPISODE_SUCCEES:
      return {
        loading: false,
        suceess: true,
        episode: {
          ...action.payload.episode,
          ...action.payload.stats,
        },
        nexchapterId: action.payload.nexchapterId,
      }
    case READ_EPISODE_SUCCEES:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const readFreeEpisodeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FREE_EPISODE_REQUEST:
      return {
        loading: true,
      }
    case GET_FREE_EPISODE_SUCCEES:
      return {
        loading: false,
        suceess: true,
        episode: {
          ...action.payload.episode,
          ...action.payload.stats,
        },
        nexchapterId: action.payload.nexchapterId,
      }
    case GET_FREE_EPISODE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const commentEpisodeReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_EPISODE_REQUEST:
      return {
        loading: true,
      }
    case COMMENT_EPISODE_SUCCEES:
      return {
        loading: false,
        suceess: true,
        episode: action.payload,
      }
    case COMMENT_EPISODE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const listCommentEpisodeReducer = (state = { comments: [] }, action) => {
  switch (action.type) {
    case COMMENT_LIST_REQUEST:
      return {
        loading: true,
      }
    case COMMENT_LIST_SUCCEES:
      return {
        loading: false,
        suceess: true,
        comments: action.payload,
      }
    case COMMENT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const deleteEpisodeReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_EPISODE_REQUEST:
      return {
        loading: true,
      }
    case DELETE_EPISODE_SUCCEES:
      return {
        loading: false,
        suceess: true,
        episode: action.payload,
      }
    case DELETE_EPISODE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
