import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import { register } from '../../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'
import SocialLogin from '../../../components/SocialLogin'

import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/plots/meta/HomeMeta'
import { getStore } from '../../../actions/storeActions'
import SellerProductView from '../components/SellerProductView'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { sendStoreOTP, storeOTPVerify } from '../../../actions/userActions'
import { Breadcrumbs, Typography } from '@mui/material'

const SellerStoreScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const [showVerification, setShowVerification] = useState(false)
  const [otp, setOtp] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const otpStore = useSelector((state) => state.otpStore)
  const { success: successOTP } = otpStore

  const otpStoreVerify = useSelector((state) => state.otpStoreVerify)
  const { success: successOTPVerify, result: resultOTPVerify } = otpStoreVerify

  const storeData = useSelector((state) => state.getStore)
  const { loading, error, success, result } = storeData
  const verifySellerAccount = () => {
    dispatch(sendStoreOTP(result.store))
  }
  const submitOTPHandler = (e) => {
    e.preventDefault()
    dispatch(storeOTPVerify({ otp: otp }))
  }
  const handleCloseVerification = () => setShowVerification(false)
  useEffect(() => {
    window.scrollTo(0, 0)
    if (success && !result.store.storeName) {
      history.push('/seller/create/new/store')
    }
    if (successOTP) {
      setShowVerification(true)
    }
    if (successOTPVerify && resultOTPVerify.result) {
      window.location.reload()
    }
    if (userLoggedIn && !loading && !success) {
      dispatch(getStore())
    }
  }, [dispatch, userLoggedIn, successOTP, successOTPVerify, success])
  return (
    <>
      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          success &&
          (result.store.storeName ? (
            <Container>
              <Row>
                <div role='presentation'>
                  <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
                    <Link underline='hover' color='inherit' to={'/profile'}>
                      Dashboard
                    </Link>
                    <Typography color='text.primary'>Store</Typography>
                  </Breadcrumbs>
                </div>
              </Row>
              <Row>
                <Col>
                  <div className='text-center '>
                    <LazyLoadImage
                      src={result.store.storeImage}
                      height={'250'}
                      width={'250'}
                      alt={`${userLoggedIn.name} 99bookscart profile image`}
                    />

                    <br />
                    <div>
                      <Link
                        to={`/store/${result.store.storeName}/${result.store._id}`}
                      >
                        {result.store.storeName}
                      </Link>
                      {result.store.isVerified ? (
                        <small className='text-muted'>(Verified)</small>
                      ) : (
                        <small className='text-muted'>(Not Verified)</small>
                      )}
                    </div>
                    <div>
                      <Link
                        to={`/store/${result.store.storeName}/${result.store._id}`}
                      >
                        View Store
                      </Link>
                    </div>
                    <div className='text-center'>
                      {result.store.address},{result.store.address1},<br />
                      {result.store.city},{result.store.zipcode},<br />
                      {result.store.state},{result.store.phone}
                    </div>
                    <div
                      style={{ width: '350px', color: '#fff' }}
                      className='center-block'
                    >
                      {!result.store.isVerified && (
                        <Button onClick={verifySellerAccount}>
                          Verify Store
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12}></Col>
              </Row>
            </Container>
          ) : (
            <div className='text-center'>
              <Link to={'/seller/create/new/store'} className='btn'>
                <Button> Create Store</Button>
              </Link>
            </div>
          ))
        )}
        <Modal show={showVerification} onHide={handleCloseVerification}>
          <Modal.Body>
            <Container style={{ padding: '20px' }}>
              <h1>Enter OTP sent your email </h1>
              <Form onSubmit={submitOTPHandler}>
                <Form.Group controlId='otp'>
                  <Form.Label>OTP</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter OTP'
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Button type='submit' variant='primary'>
                  Verify
                </Button>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}
{
}

export default SellerStoreScreen
