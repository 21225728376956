import axios from 'axios'
import {
  CREATE_NEW_STORY_FAIL,
  CREATE_NEW_STORY_REQUEST,
  CREATE_NEW_STORY_SUCCEES,
  DELETE_STORY_FAIL,
  DELETE_STORY_REQUEST,
  DELETE_STORY_SUCCEES,
  GET_STORY_FAIL,
  GET_STORY_REQUEST,
  GET_STORY_SUCCEES,
  LIST_STORY_BY_CAT_FAIL,
  LIST_STORY_BY_CAT_REQUEST,
  LIST_STORY_BY_CAT_SUCCEES,
  LIST_STORY_BY_KEY_FAIL,
  LIST_STORY_BY_KEY_REQUEST,
  LIST_STORY_BY_KEY_SUCCEES,
  LIST_STORY_FAIL,
  LIST_STORY_REQUEST,
  LIST_STORY_SUCCEES,
  MY_LIST_STORY_FAIL,
  MY_LIST_STORY_REQUEST,
  MY_LIST_STORY_SUCCEES,
  MY_READING_STORY_FAIL,
  MY_READING_STORY_REQUEST,
  MY_READING_STORY_SUCCEES,
} from '../constants/storyConstants'

export const createStory = (story) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_NEW_STORY_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/story/`, story, config)
    dispatch({
      type: CREATE_NEW_STORY_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CREATE_NEW_STORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listStory =
  (pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LIST_STORY_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.get(`/api/story/page/${pageNumber}`, config)
      dispatch({
        type: LIST_STORY_SUCCEES,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: LIST_STORY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const getAllStories = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: MY_LIST_STORY_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/story/all/page/${pageNumber}`,
      config
    )

    dispatch({
      type: MY_LIST_STORY_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MY_LIST_STORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getStory = (title) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_STORY_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.get(`/api/story/${title}`, config)
    dispatch({
      type: GET_STORY_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_STORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getMyStories = (admin) => async (dispatch, getState) => {
  try {
    dispatch({ type: MY_LIST_STORY_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/story/my`, config)

    dispatch({
      type: MY_LIST_STORY_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MY_LIST_STORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getMyStoryReading = () => async (dispatch, getState) => {
  try {
    dispatch({ type: MY_READING_STORY_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/story/my/reading`, config)
    dispatch({
      type: MY_READING_STORY_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MY_READING_STORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const deleteStory = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_STORY_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/story/delete/${id}`, config)
    dispatch({
      type: DELETE_STORY_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_STORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listStoryByKeyword =
  (keyword, pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({ type: LIST_STORY_BY_KEY_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      var { data } = await axios.get(
        `/api/story/key/${keyword}/page/${pageNumber}`,
        config
      )

      dispatch({
        type: LIST_STORY_BY_KEY_SUCCEES,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: LIST_STORY_BY_KEY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const listStoryByCat =
  (cat, pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({ type: LIST_STORY_BY_CAT_REQUEST })

      // const {
      //   userLogin: { userInfo },
      // } = getState()
      // const config = {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${userInfo.token}`,
      //   },
      // }
      // if (userInfo) {
      //   var { data } = await axios.get(
      //     `/api/story/cat/${cat}/page/${pageNumber}`,
      //     config
      //   )
      // } else {
      //   var { data } = await axios.get(
      //     `/api/story/cat/${cat}/page/${pageNumber}`
      //   )
      // }
      var { data } = await axios.get(`/api/story/cat/${cat}/page/${pageNumber}`)
      dispatch({
        type: LIST_STORY_BY_CAT_SUCCEES,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: LIST_STORY_BY_CAT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
