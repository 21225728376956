import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  ListGroupItem,
  Form,
  FormGroup,
  Alert,
  Modal,
  Table,
} from 'react-bootstrap'
import Rating from '../components/Rating'
import axios from 'axios'
import {
  listProductsDetails,
  createProductReview,
  getProductAttributes,
} from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_PAYMENTTYPE_FREE,
  PRODUCT_PAYMENTTYPE_SUBSCRIPTION,
} from '../constants/productConstants'
import Meta from '../components/Meta'
import {
  addToCart,
  recentlyViewed,
  saveEmailToCart,
  getCartDetailsFromStorage,
} from '../actions/cartActions.js'
import { Container } from 'react-bootstrap'
import { Route } from 'react-router-dom'
import Product from '../components/Product'
import { listProductsByAuthor } from '../actions/categoryActions'
import BoxProgressBar from '../components/BoxProgressBar'
import {
  FaFlagCheckered,
  FaTruck,
  FaCompressArrowsAlt,
  FaCheck,
  FaGooglePlay,
  FaHeart,
  FaBarcode,
  FaRegNewspaper,
  FaBuilding,
  FaPenFancy,
  FaCalendarAlt,
  FaBookOpen,
  FaLanguage,
  FaCoins,
  FaCartPlus,
  FaRegClock,
  FaCheckCircle,
} from 'react-icons/fa'

import {
  addToIntrested,
  addToWIshlist,
  getWishlist,
  addToAlreadRead,
  getIntrestsByProduct,
  getAlreadyReadsByProduct,
} from '../actions/wishlistActions'
import ReactHtmlParser from 'react-html-parser'
import ProductMeta from '../components/ProductMeta'
import { BoxDescription } from '../components/BoxDescription'
import Cookies from 'universal-cookie'
import SocialShare from '../components/SocialShare'
import BookStats from '../components/BookStats'
import {
  timeConvert,
  timeConvertSummary,
  convertToSlug2,
} from '../helpers/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Timer from '../components/Timer'
import FooterMenu from '../components/FooterMenu'
import { AiBox } from '../components/AiBox'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import PriceDisplay from '../components/PriceDisplay'
import { Paper, Typography } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Notification from '../components/Notification'
import StepFunnel from '../components/StepFunnel.js'
import { PosterBox } from '../components/PosterBox.js'
import PosterLogic from '../components/PosterLogic.js'

var dayss = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const cookies = new Cookies()
const userAgent = window.navigator.userAgent.toLowerCase()

const ProductScreen = ({ history, match }) => {
  const url = `https://www.99bookscart.com/book/${match.params.id}/${match.params.slug}`
  const dispatch = useDispatch()
  const [qty, setQty] = useState(1)
  const [addToCartMessage, setAddToCartMessage] = useState(false)
  const [vprice, setVprice] = useState(0)
  const [vId, SetVid] = useState(0)
  const [vmessage, setVmessage] = useState('')
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [cartClick, setCartClick] = React.useState(false)
  const [showAddToCart, setShowAddToCart] = React.useState(false)
  const [slug, setSlug] = useState('')
  const [image, setImage] = useState('')
  const [disable, setDisable] = React.useState(false)
  const [uploading, setUploading] = useState(false)
  const [isReadMore, setIsReadMore] = useState(true)
  const [inventoryId, setInventoryId] = useState(null)
  const [bookcartBoxCount, SetBookcartBoxCount] = useState(0)
  const [inventoryImage, setInventoryImage] = useState('')
  const [inBox, SetInBox] = useState(false)
  const [show, setShow] = useState(false)
  const [showFunnel, setShowFunnel] = useState(false)
  const [email, setEmail] = useState(false)
  const [phoneMessage, setPhoneMessage] = useState('')
  const [arShow, setArShow] = useState(false)
  const [refresh, setRefresh] = useState(true)
  const [load, setLoad] = useState(true)

  const [boxType, setBoxType] = useState('1')
  const [curCartItems, SetCurCartItems] = useState('')
  var colSizeForSmall = 6
  const setCartEmail = useSelector((state) => state.setCartEmail)
  const { success: successEmail } = setCartEmail

  const productReviewCreate = useSelector((state) => state.productReviewCreate)
  const { success: successReview, error: errorReview } = productReviewCreate

  const cartRes = useSelector((state) => state.cart)
  const {
    cartItems,
    cartType,
    recentView: recentProducts,
    cartSuccess,
  } = cartRes

  const userCart = useSelector((state) => state.userCart)
  const { cart } = userCart

  const productDetails = useSelector((state) => state.productDetails)
  const {
    loading,
    error,
    product,
    success,
    bookAttribute: bookAttributes,
    readingCount,
    inventory,
  } = productDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const wishlist = useSelector((state) => state.wishlistAdd)
  const { success: wishlistSucces } = wishlist

  const wishlists = useSelector((state) => state.wishlistGet)
  const { wishlists: userWishList, success: userWishlistSucces } = wishlists

  const getProductAttributesRes = useSelector(
    (state) => state.getProductAttributes
  )
  var wordCount = 0
  const {
    success: arSucces,
    error: arError,
    product: arProduct,
    bookAttribute,
    loading: arLoading,
  } = getProductAttributesRes

  let inWishlist = false
  // if (userWishlistSucces) {
  //   userWishList.map((item) => {
  //     if (item.product == product._id) {
  //       inWishlist = true
  //     }
  //   })
  // }
  var reviewsLenght = 0
  var reviewsList = []
  var date = new Date()

  var deliveryDate = new Date(date.setTime(date.getTime() + 7 * 86400000))
  useEffect(() => {
    if (cartSuccess) {
      dispatch(getCartDetailsFromStorage())
      setRefresh(false)
    }
    if (
      product &&
      match.params.id &&
      product.isbn != match.params.id &&
      !loading &&
      refresh
    ) {
      setDisable(false)
      setCartClick(false)
      setAddToCartMessage('')
      setInventoryId(null)
      window.scrollTo(0, 0)
      dispatch(listProductsDetails(match.params.id, match.params.slug))
      dispatch(getCartDetailsFromStorage())
    } else {
      if (!match.params.id && !match.params.slug && !product._id) {
        dispatch(listProductsDetails('BookscartBox1', 'surprise-box-15-books'))
      }
      setLoad(false)
      // if (cartSuccess && !disable && cartClick) {
      //   if (product.isbn == 'BookscartBox1') {
      //     if (userInfo) {
      //       history.push('/checkout/select-address')
      //     } else {
      //       history.push('/checkout/basic-information')
      //     }
      //   }
      // }
    }

    if (success && product) {
      setRefresh(true)
      setSlug(convertToSlug(product.name))
      dispatch(recentlyViewed(product._id, 1))

      if (inventory) {
        if (!inventoryId || inventory.length == 1) {
          if (inventory.length > 0) {
            setInventoryId(inventory[0]._id)
          }
        }

        colSizeForSmall =
          inventory.length == 1 ? 12 : inventory.length == 2 ? 6 : 4
      }
    }

    if (cartItems) {
      SetInBox(false)
      if (cartItems.length > 0) {
        var count = 0
        cartItems.forEach((item, index) => {
          if (item.isBookscartBox) {
            if (
              (item.isbn == product.isbn || item.isbn == match.params.id) &&
              !item.variantSelected
            ) {
              SetInBox(true)
            }
            count++
          }
        })
        SetBookcartBoxCount(count)
      }
    }

    if (successReview) {
      setRating(0)
      setComment('')
      setImage('')
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    }

    if (successEmail) {
      setShow(false)
    }
  }, [
    dispatch,
    match,
    successReview,
    success,
    product,
    cartItems,
    bookcartBoxCount,
    cartSuccess,
    addToCartMessage,
    successEmail,
    arSucces,
    wordCount,
    curCartItems,
    inventoryId,
    showFunnel,
  ])
  const startSub = () => {
    setShowFunnel(true)
  }
  const onPriceSelect = (id) => (e) => {
    setInventoryId(id)
    setInventoryImage(inventory.find((item) => item._id == id).image)
  }
  if (success && reviewsList.length == 0) {
    product.description =
      product.description && product.description.replace(/<[^>]*>?/gm, '')
    reviewsLenght = product.reviews.length

    if (product.reviews.length > 0) {
      const list = product.reviews.sort(() => Math.random() - 0.5)
      for (var i = 0; i < 12; i++) {
        if (list[i]) {
          reviewsList.push(list[i])
        }
      }
    }
  }
  if (bookAttributes) {
    if (bookAttributes.plotSummary) {
      wordCount = bookAttributes.plotSummary.split(' ').length
    }
    if (bookAttributes.insights) {
      wordCount = wordCount + bookAttributes.plotSummary.split(' ').length
    }
  }

  const submitHandler2 = (e) => {
    e.preventDefault()
    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(email)) {
      dispatch(saveEmailToCart(email))
      if (product.isbn == 'BookscartBox1') {
        if (userInfo) {
          setInterval(history.push('/checkout/select-address'), 2000)
        } else {
          setInterval(history.push('/checkout/basic-information'), 2000)
        }
      }
    } else {
      setPhoneMessage('Enter Valid Phone Number')
    }
  }
  const handleClose = () => {
    setShow(false)
    if (product.isbn == 'BookscartBox1') {
      if (userInfo) {
        setInterval(history.push('/checkout/select-address'), 2000)
      } else {
        setInterval(history.push('/checkout/basic-information'), 2000)
      }
    }
  }
  const uploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data)
      setUploading(false)
    } catch (error) {
      setUploading(false)
    }
  }

  const BuyNowHandler = () => {
    let variantId = 0
    if (vprice.length > 0) {
      variantId = vId
    } else if (product.isBox) {
      variantId = product.boxType[0]._id
    }
    dispatch(addToCart(product._id, 1, variantId))
    history.push('/cart')
  }
  const addToCartHandler = () => {
    let variantId = 0
    if (product.isbn == 'BookscartBox1') {
      startSub()
    } else {
      if (vprice.length > 0) {
        var e = document.getElementById('boxTypeSelect')
        variantId = vId
      } else if (product.isBox) {
        if (product.boxType && product.boxType.length > 0) {
          setVmessage('Select Box Genre')
          variantId = product.boxType[0]._id
          return
        }
      }
      const invenID = product.isbn == 'BookscartBox1' ? null : inventoryId
      dispatch(addToCart(product._id, 1, variantId, invenID, boxType))
      setCartClick(true)
      setAddToCartMessage(true)
      if (product.isbn == 'BookscartBox1') {
        if (userInfo) {
          setInterval(history.push('/checkout/select-address'), 2000)
        } else {
          if (!cookies.get('_cfx_phone_pop')) {
            setShow(true)
          } else {
            setInterval(history.push('/checkout/basic-information'), 2000)
          }
        }
        // if (userInfo) {
        //   setInterval(history.push('/checkout/select-address'), 2000)
        // } else {
        //   setInterval(history.push('/checkout/basic-information'), 2000)
        // }
      } else {
        if (!userInfo && !cookies.get('_cfx_phone_pop')) {
          //setShow(true)
        }
      }
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProductReview(product._id, {
        rating,
        comment,
        image,
      })
    )
  }

  const updateVariantPrice = (ee) => {
    var e = document.getElementById('boxTypeSelect')
    // product.salePrice = e.options[e.selectedIndex].getAttribute('data-name')
    // setVprice(e.options[e.selectedIndex].value)
    if (boxType == 1) {
      product.salePrice = '999'
      SetVid(ee.target.id)
      setVprice('999')
    } else if (boxType == 2) {
      product.salePrice = '1999'
      SetVid(ee.target.id)
      setVprice('1999')
    } else {
      product.salePrice = '2999'
      SetVid(ee.target.id)
      setVprice('2999')
    }
  }

  const changePrice = (value) => {
    setBoxType(value)
    if (value == 3) {
      setVprice('2999')
      product.salePrice = '2999'
    } else if (value == 2) {
      setVprice('1999')
      product.salePrice = '1999'
    } else {
      setVprice('999')
      product.salePrice = '999'
    }
  }
  const addToUserWishlist = (e) => {
    e.preventDefault()
    if (userInfo) {
      dispatch(addToWIshlist(product))
    } else {
      history.push('/register')
    }
  }
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  const handlearShow = () => setArShow(true)
  const handlearClose = () => setArShow(false)
  const handleCloseAddtoCart = () => setShowAddToCart(false)
  const selectMorebooks = () => setShowAddToCart(false)
  const continueButton = async () => {
    setShowAddToCart(false)
    setShow(true)
  }
  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <>
          <ProductMeta product={product}></ProductMeta>
          {product && product.isPoster ? (
            <PosterLogic product={product} bookAttribute={product} />
          ) : (
            <Row>
              {product.isbn != 'BookscartBox1' ? (
                <>
                  <Container className='story-head-section'>
                    <Row>
                      {userInfo && userInfo.isAdmin && (
                        <Link to={`/admin/product/${product._id}/edit`}>
                          <Button type='button' className='btn btn-block'>
                            Edit
                          </Button>
                        </Link>
                      )}
                    </Row>
                    {/* <Row className='mobile-view'>
                {!product.isBox && product.isAttribute && (
                  <LazyLoadImage
                    src={
                      'https://s3-books-images.s3.amazonaws.com/d94934d6-c9c2-476a-9c26-c387fcc74329.png'
                    }
                    width={'100%'}
                    alt={`read detailed summary on ${product.name} `}
                    className='summary-image'
                  />
                )}
              </Row> */}
                    {/* {!product.isBox && (
                <div role='presentation'>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    style={{ fontSize: '13px' }}
                  >
                    <Link to={`/${product.type}`}>{product.author}</Link>
                    <Typography
                      color='text.primary'
                      style={{ fontSize: '13px' }}
                    >
                      {product.name && product.name}
                    </Typography>
                  </Breadcrumbs>
                </div>
              )} */}

                    <Row className='productviewdesign'>
                      {/* {product.isBox && <Timer></Timer>} */}
                      <Col xs={12} md={4}>
                        <LazyLoadImage
                          src={
                            inventory &&
                            inventory.length > 0 &&
                            inventory[0].image
                              ? inventory[0].image
                              : product.image
                          }
                          width={'100%'}
                          alt={`buy ${product.name} book on 99bookscart`}
                          className='product-image-main'
                          style={{
                            backgroundColor: product.imageColor
                              ? product.imageColor
                              : '#FDE7E7',
                          }}
                        />
                      </Col>
                      <Col xs={12} md={8}>
                        <div style={{ paddingLeft: '8px' }}>
                          <h1
                            className='title-main-book'
                            style={{ color: '#3C3C3B' }}
                          >
                            {product.name && product.name.toLowerCase()}
                          </h1>
                          {product.author ? (
                            <div className='book-author'>
                              by{' '}
                              <span
                                style={{
                                  textTransform: 'capitalize !important',
                                }}
                              >
                                <Link
                                  to={`/author/${convertToSlug2(
                                    product.author
                                  )}`}
                                >
                                  {' '}
                                  {product.author.toLowerCase()}
                                </Link>
                              </span>
                            </div>
                          ) : (
                            <div></div>
                          )}

                          {product.numReviews > 0 ? (
                            <>
                              <Rating value={product.rating} />
                              {product.isBox && product.reviews.length > 0 ? (
                                <small className='mobile-view'>
                                  {product.reviews.length.toLocaleString()}{' '}
                                  Reviews
                                </small>
                              ) : (
                                <small className='mobile-view'>
                                  {product.gnumReviews
                                    ? product.gnumReviews.toLocaleString()
                                    : 0}
                                  Reviews
                                </small>
                              )}
                            </>
                          ) : (
                            <div className='text-center-rating'>
                              {product.grating > 0 && (
                                <>
                                  <Rating
                                    value={product.grating}
                                    reviews={product.gnumReviews}
                                    style={{ textAlign: 'right' }}
                                  />

                                  {product.gnumReviews > 0 && (
                                    <small style={{ fontSize: '12px' }}>
                                      {product.gnumReviews.toLocaleString()}{' '}
                                      Reviews
                                    </small>
                                  )}
                                </>
                              )}
                            </div>
                          )}

                          <div
                            className='desktop-view'
                            style={{
                              width: '80%',
                              marginBottom: '5px',
                              textAlign: 'left',
                            }}
                          >
                            {/* <BookStats
                        product={product}
                        coins={product.salePrice / 50}
                        binding={product.binding}
                      ></BookStats> */}
                          </div>

                          <>
                            {!product.isEbook && (
                              <ListGroup.Item key='cartbutton'>
                                <>
                                  <div className='add-to-wishlist'>
                                    <Link
                                      variant='light'
                                      to='/'
                                      disabled={inWishlist}
                                      onClick={(e) => addToUserWishlist(e)}
                                    >
                                      <small>
                                        <FaHeart className='orange'></FaHeart>{' '}
                                        {wishlistSucces || inWishlist
                                          ? 'In Library'
                                          : 'Add to Library'}
                                      </small>
                                    </Link>
                                  </div>
                                  {/* {!inBox ? ( */}
                                  <Row>
                                    <Col xs={12} md={12} className=''>
                                      {product.isbn == 'AIBOX' ? (
                                        <Link to={'/ai-curated-box'}>
                                          <Button
                                            className='btn-primary addtocart'
                                            type='button'
                                            disabled={
                                              ((inventory &&
                                                inventory.length == 0) ||
                                                disable) &&
                                              !product.variantSelected
                                            }
                                          >
                                            {inventory &&
                                            inventory.length == 0 ? (
                                              'No Stock'
                                            ) : (
                                              <>
                                                <FaCartPlus></FaCartPlus> Curate
                                                your Box
                                              </>
                                            )}
                                          </Button>
                                        </Link>
                                      ) : (
                                        <div className='desktop-view'>
                                          {(inventory &&
                                            inventory.length > 0 &&
                                            !product.isBox) ||
                                          product.type == 'poster' ? (
                                            <Button
                                              className='btn-primary addtocart desktop-view'
                                              onClick={addToCartHandler}
                                              type='button'
                                              variant='contained'
                                              disabled={
                                                ((inventory &&
                                                  inventory.length == 0) ||
                                                  disable) &&
                                                !product.variantSelected &&
                                                !product.isBox
                                              }
                                            >
                                              {inventory &&
                                              inventory.length == 0 &&
                                              !product.isBox ? (
                                                'No Stock'
                                              ) : (
                                                <>
                                                  {!product.isEbook &&
                                                  !product.isBox &&
                                                  product.isBookscartBox ? (
                                                    <>
                                                      <FaCartPlus className='orange'></FaCartPlus>{' '}
                                                      Add to Box
                                                    </>
                                                  ) : (
                                                    <>
                                                      <FaCartPlus className='orange'></FaCartPlus>{' '}
                                                      Add to Cart
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </Button>
                                          ) : (
                                            inventory &&
                                            inventory.length == 0 &&
                                            !product.isBox &&
                                            (product.amazonLink ? (
                                              <>
                                                <strong>
                                                  Not Available with us
                                                </strong>
                                                <br />
                                                <a
                                                  href={product.amazonLink}
                                                  target='_blank'
                                                >
                                                  <Button
                                                    className='btn-primary  desktop-view'
                                                    type='button'
                                                    variant='contained'
                                                  >
                                                    Buy on Amazon
                                                  </Button>
                                                </a>
                                              </>
                                            ) : (
                                              <Button
                                                className='btn-primary  desktop-view'
                                                type='button'
                                                variant='contained'
                                                disabled={true}
                                              >
                                                No Stock
                                              </Button>
                                            ))
                                          )}
                                          {addToCartMessage && (
                                            <>
                                              <span className='desktop-view'>
                                                <Typography
                                                  variant='subtitle'
                                                  style={{ marginTop: '6px' }}
                                                >
                                                  Added to Cart!{' '}
                                                  <strong>
                                                    <Link to={'/cart'}>
                                                      Cart
                                                    </Link>
                                                  </strong>
                                                </Typography>
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </>
                              </ListGroup.Item>
                            )}
                          </>
                          <div>{cart && <BoxProgressBar cart={cart} />}</div>
                          {product.type == 'New' && (
                            <div className=' new-books'>New Book</div>
                          )}
                          <div style={{ fontSize: '12px', marginTop: '6px' }}>
                            {!product.isEbook &&
                              !product.isBox &&
                              product.isBookscartBox && (
                                <>
                                  <div className='savedText2'>
                                    Saving &#8377;
                                    {Math.round(
                                      product.price - product.salePrice
                                    ).toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}{' '}
                                    on this Book
                                  </div>
                                  <span>
                                    Available for{' '}
                                    <a
                                      href='/online-book-fair'
                                      style={{ fontWeight: 'bold' }}
                                    >
                                      Online Book Fair
                                    </a>
                                  </span>
                                </>
                              )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {product.isBox && product.type != 'poster' && (
                      <Row style={{ marginTop: '12px', textAlign: 'center' }}>
                        <h2 className='freeshipping'>Build your own Library</h2>
                        <span className='muted-text'>₹65/Book</span>
                        <strong>Buy Any 2 Boxes and Get FREE Shipping</strong>
                      </Row>
                    )}
                    {(!product.isBox || product.type == 'poster') && (
                      <Row className='mobile-view text-center'>
                        <Col
                          xs={12}
                          md={12}
                          style={{ marginTop: '8px', marginLeft: '8px' }}
                        >
                          {(inventory &&
                            inventory.length > 0 &&
                            !product.isBox) ||
                          product.type == 'poster' ? (
                            <>
                              <Button
                                className='btn-primary'
                                onClick={addToCartHandler}
                                type='button'
                                variant='contained'
                                disabled={
                                  ((inventory && inventory.length == 0) ||
                                    disable) &&
                                  !product.variantSelected &&
                                  !product.isBox
                                }
                              >
                                {inventory &&
                                inventory.length == 0 &&
                                !product.isBox ? (
                                  'No Stock'
                                ) : (
                                  <>
                                    {!product.isEbook &&
                                    !product.isBox &&
                                    product.isBookscartBox ? (
                                      <>
                                        <FaCartPlus className='orange'></FaCartPlus>{' '}
                                        Add to Box
                                      </>
                                    ) : (
                                      <>
                                        <FaCartPlus className='orange'></FaCartPlus>{' '}
                                        Add to Cart
                                      </>
                                    )}
                                  </>
                                )}
                              </Button>
                              <Col
                                xs={12}
                                md={12}
                                style={{
                                  marginTop: '8px',
                                  marginBottom: '12px',
                                }}
                                className='text-center'
                              >
                                <Row>
                                  <Paper xs={4} md={4} className='gurentee'>
                                    COD Available Now
                                  </Paper>
                                  <Paper xs={4} md={4} className='gurentee'>
                                    11 day Replacement
                                  </Paper>
                                  <Paper xs={4} md={4} className='gurentee'>
                                    100% Quality Guarantee
                                  </Paper>
                                </Row>
                              </Col>
                            </>
                          ) : (
                            inventory &&
                            inventory.length == 0 &&
                            !product.isBox &&
                            (product.amazonLink ? (
                              <>
                                <strong>Not Available with us</strong>
                                <br />
                                <a href={product.amazonLink} target='_blank'>
                                  <Button
                                    className='btn-primary'
                                    type='button'
                                    variant='contained'
                                    style={{ width: '90%' }}
                                  >
                                    Buy on Amazon
                                  </Button>
                                </a>
                              </>
                            ) : (
                              <Button
                                className='btn-primary'
                                type='button'
                                variant='contained'
                                disabled={true}
                              >
                                No Stock
                              </Button>
                            ))
                          )}

                          {addToCartMessage && (
                            <div
                              className='text-center'
                              style={{ marginTop: '6px' }}
                            >
                              <div className='mobile-view'>
                                <span className='very-small'>
                                  Added to Cart! <Link to={'/cart'}>Cart</Link>
                                </span>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}

                    <Row className='bookscartbox-section'>
                      {/* <div className='text-center'>
                  <Link
                    onClick={(e) => addToIntrestUser(e)}
                    to=''
                    className='user-intrest'
                  >
                    <FaCompressArrowsAlt> </FaCompressArrowsAlt> Intrested
                    <span className='text-muted'>
                      {intrestsSucces && '(' + intrestsCount.count + ')'}
                    </span>
                  </Link>{' '}
                  <Link
                    to=''
                    onClick={(e) => addToAlreadReadUser(e)}
                    className='user-intrest'
                  >
                    <FaFlagCheckered></FaFlagCheckered> Already Read
                    <span className='text-muted'>
                      {alreadyReadsSucces &&
                        '(' + alreadyreadsCount.count + ')'}
                    </span>
                  </Link>
                </div> */}

                      {product.isBox && product.type != 'poster' && (
                        <>
                          <ListGroup.Item
                            key='textbox'
                            style={{ marginTop: '12px' }}
                          >
                            <strong>
                              🚀 How to know more than your friends & family?
                              The knowledge you gain from reading gives you lots
                              to share & talk about with others.📚
                            </strong>
                          </ListGroup.Item>
                        </>
                      )}
                    </Row>
                    {product.isBox && (
                      <Row className='mobile-view text-center'>
                        <Col xs={12} md={12} style={{ marginTop: '8px' }}>
                          {inventory &&
                          inventory.length == 0 &&
                          !product.isBox &&
                          product.amazonLink ? (
                            <Button
                              className='btn-primary'
                              onClick={addToCartHandler}
                              type='button'
                              variant='contained'
                              fullWidth
                              disabled={
                                ((inventory && inventory.length == 0) ||
                                  disable) &&
                                !product.variantSelected &&
                                !product.isBox
                              }
                            >
                              {inventory &&
                              inventory.length == 0 &&
                              !product.isBox ? (
                                'No Stock'
                              ) : (
                                <>
                                  {!product.isEbook &&
                                  !product.isBox &&
                                  product.isBookscartBox ? (
                                    <>
                                      <FaCartPlus className='orange'></FaCartPlus>{' '}
                                      Add to Box
                                    </>
                                  ) : (
                                    <>
                                      <FaCartPlus className='orange'></FaCartPlus>{' '}
                                      Add to Cart
                                    </>
                                  )}
                                </>
                              )}
                            </Button>
                          ) : (
                            ''
                          )}
                          {addToCartMessage && (
                            <div
                              className='text-center'
                              style={{ marginTop: '6px' }}
                            >
                              <div className='mobile-view'>
                                <span className='very-small'>
                                  Added to Cart! <Link to={'/cart'}>Cart</Link>
                                </span>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}
                  </Container>
                  <Row style={{ padding: '8px' }} className='summary-reading'>
                    <Col>
                      <>
                        {/* <div className='mobile-view'>
                    <BookStats
                      product={product}
                      coins={product.salePrice / 50}
                      binding={product.binding}
                    ></BookStats>
                  </div> */}

                        {product.description &&
                          (product.isBox && product.type !== 'poster' ? (
                            <ListGroup.Item key='description-bookscartBox'>
                              <BoxDescription
                                isbn={product.isbn}
                              ></BoxDescription>
                            </ListGroup.Item>
                          ) : product.isbn == 'AIBOX' ? (
                            <ListGroup.Item key='description-bookscartBox'>
                              <AiBox description={product.description}></AiBox>
                            </ListGroup.Item>
                          ) : product.type == 'poster' ? (
                            <ListGroup.Item key='description-bookscartBox'>
                              <PosterBox
                                description={product.description}
                              ></PosterBox>
                            </ListGroup.Item>
                          ) : (
                            <Container>
                              <h2>Book Description</h2>
                              <ListGroup.Item key='description'>
                                {product.isAttribute ? (
                                  <>
                                    <Typography
                                      variant='body1'
                                      className='product-description'
                                    >
                                      <div>
                                        <div className='summary-titles'>
                                          Read before buy{' '}
                                          {wordCount !== 0 && (
                                            <>
                                              <FaRegClock></FaRegClock>{' '}
                                              {timeConvertSummary(
                                                wordCount + 871
                                              )}
                                            </>
                                          )}
                                        </div>

                                        {/* {bookAttributes.emotions && (
                                    <>
                                      <strong
                                        style={{
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Emotions:
                                      </strong>{' '}
                                      <small
                                        style={{
                                          textTransform: 'capitalize',
                                          wordWrap: 'break-word',
                                        }}
                                      >
                                        {bookAttributes.emotions.join()}
                                      </small>
                                      <br />
                                    </>
                                  )} */}
                                        <div>
                                          {bookAttributes.insights && (
                                            <>
                                              <Typography className='summary-titles'>
                                                What you will learn from reading{' '}
                                                {product.name} by{' '}
                                                {product.author}
                                              </Typography>
                                              <p
                                                style={{
                                                  whiteSpace: 'pre-wrap',
                                                  marginTop: '-40px',
                                                }}
                                              >
                                                {bookAttributes.insights}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                        <div>
                                          {bookAttributes.plotSummary && (
                                            <>
                                              <Typography className='summary-titles'>
                                                Summary of {product.name} by{' '}
                                                {product.author}
                                              </Typography>
                                              <p
                                                style={{
                                                  whiteSpace: 'pre-wrap',
                                                  marginTop: '-40px',
                                                }}
                                              >
                                                {bookAttributes.plotSummary}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                        {bookAttributes.mainNarater && (
                                          <>
                                            <strong
                                              style={{
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Main Narrator:
                                            </strong>{' '}
                                            <small
                                              style={{
                                                textTransform: 'capitalize',
                                                wordWrap: 'break-word',
                                              }}
                                            >
                                              {bookAttributes.mainNarater +
                                                ' (' +
                                                bookAttributes.mainCharacterGender +
                                                ')'}{' '}
                                            </small>
                                            <br />
                                          </>
                                        )}
                                        {bookAttributes.ageGroupRange && (
                                          <>
                                            <strong
                                              style={{
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Suitable Age:
                                            </strong>{' '}
                                            <small
                                              style={{
                                                textTransform: 'capitalize',
                                                wordWrap: 'break-word',
                                              }}
                                            >
                                              {bookAttributes.ageGroupRange}
                                            </small>
                                            <br />
                                          </>
                                        )}

                                        {bookAttributes.places &&
                                          bookAttributes.places.length > 0 && (
                                            <>
                                              <strong
                                                style={{
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                Places:
                                              </strong>{' '}
                                              <small
                                                style={{
                                                  textTransform: 'capitalize',
                                                  wordWrap: 'break-word',
                                                }}
                                              >
                                                {bookAttributes.places.join()}
                                              </small>
                                              <br />
                                            </>
                                          )}
                                        {bookAttributes.keywords &&
                                          bookAttributes.keywords.length >
                                            0 && (
                                            <>
                                              <strong
                                                style={{
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                Keywords:
                                              </strong>{' '}
                                              <small
                                                style={{
                                                  textTransform: 'capitalize',
                                                  wordWrap: 'break-word',
                                                  width: '100%',
                                                }}
                                              >
                                                {bookAttributes.keywords.join()}
                                              </small>
                                            </>
                                          )}
                                        <br />
                                      </div>
                                      <div>
                                        {bookAttributes.mainCharacters &&
                                          bookAttributes.mainCharacters.length >
                                            0 && (
                                            <>
                                              <div>
                                                <div className='summary-titles'>
                                                  Key Characters
                                                </div>

                                                {bookAttributes.mainCharacters.map(
                                                  (ch) => (
                                                    <div
                                                      key={ch.name}
                                                      style={{
                                                        marginBottom: '10px',
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          fontWeight: 'bold',
                                                        }}
                                                      >
                                                        {ch.name}
                                                      </div>{' '}
                                                      {ch.profession &&
                                                        ch.profession}{' '}
                                                      {ch.sex && ch.sex}{' '}
                                                      {ch.age &&
                                                        '(' + ch.age + ')'}
                                                      <br />
                                                      {ch.traits && (
                                                        <>
                                                          <span
                                                            style={{
                                                              fontWeight:
                                                                'bold',
                                                            }}
                                                          >
                                                            Traits:
                                                          </span>{' '}
                                                          {ch.traits.join()}
                                                          <br />
                                                        </>
                                                      )}
                                                      {ch.emotions && (
                                                        <>
                                                          <span
                                                            style={{
                                                              fontWeight:
                                                                'bold',
                                                            }}
                                                          >
                                                            Emotions:{' '}
                                                          </span>
                                                          {ch.emotions.join()}
                                                          <br />
                                                        </>
                                                      )}
                                                      {ch.description}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </>
                                          )}
                                      </div>

                                      {/* <div>
                                  {bookAttributes &&
                                    bookAttributes.quotes &&
                                    bookAttributes.quotes.length > 0 && (
                                      <>
                                        <div className='summary-titles'>
                                          Quotes:
                                        </div>

                                        {arProduct.bookAttributes.quotes.map(
                                          (ch) => (
                                            <div
                                              key={ch}
                                              style={{ marginBottom: '8px' }}
                                            >
                                              {ch}
                                              <br />
                                              {ch.description}
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                </div> */}
                                    </Typography>
                                  </>
                                ) : (
                                  <Typography variant='body1'>
                                    <div style={{ fontWeight: 'bold' }}>
                                      {/* Detailed Summary is not available! */}
                                    </div>
                                    <div>
                                      {isReadMore
                                        ? ReactHtmlParser(
                                            product.description.slice(0, 450)
                                          )
                                        : ReactHtmlParser(product.description)}
                                      <div
                                        className='title-main'
                                        style={{ color: '#3C3C3B' }}
                                      >
                                        {product.name &&
                                          product.author &&
                                          product.name.toLowerCase() +
                                            ' by ' +
                                            product.author.toLowerCase()}
                                      </div>
                                    </div>
                                    <div
                                      onClick={toggleReadMore}
                                      className='read-or-hide'
                                    >
                                      {isReadMore
                                        ? '...read more'
                                        : ' show less'}
                                    </div>
                                  </Typography>
                                )}
                              </ListGroup.Item>
                            </Container>
                          ))}
                        {product &&
                          product.author &&
                          product.type != 'poster' && (
                            <Container>
                              Checkout other books from author{' '}
                              <Link
                                to={`/author/${convertToSlug2(product.author)}`}
                              >
                                {' '}
                                {product.author}
                              </Link>
                            </Container>
                          )}

                        {product.isbn != 'AIBOX' &&
                          product.genres.length > 0 && (
                            <Container>
                              <h2 className='storietaglabel'>Book Genres </h2>
                              <div className='story-tags'>
                                {product.genres.map((category) => (
                                  <Link
                                    to={`/${
                                      product.type == 0
                                        ? 'fiction'
                                        : 'non-fiction'
                                    }-books/${convertToSlug2(category.name)}`}
                                    key={`${category.name}`}
                                    className='topic'
                                  >
                                    <span>{category.name}</span>
                                  </Link>
                                ))}
                              </div>
                            </Container>
                          )}

                        {!product.isBox && (
                          <Container>
                            <h2
                              className='title-main'
                              style={{ color: '#3C3C3B' }}
                            >
                              {product.name &&
                                product.author &&
                                product.name.toLowerCase() +
                                  ' by ' +
                                  product.author.toLowerCase()}
                            </h2>
                            <ListGroup.Item key='details'>
                              <h2 className='storietaglabel'>Book Details </h2>
                              <div className='product-details'>
                                <div>
                                  <strong>
                                    <FaBookOpen></FaBookOpen> Title:
                                  </strong>
                                  {product.name}
                                </div>
                                <div>
                                  <strong>
                                    <FaBarcode></FaBarcode> ISBN-13:{' '}
                                  </strong>
                                  {product.isbn}
                                </div>
                                {product.pages > 1 && (
                                  <div>
                                    <strong>
                                      <FaRegNewspaper></FaRegNewspaper> Pages:{' '}
                                    </strong>
                                    {product.pages}{' '}
                                    <strong>
                                      <FaRegClock></FaRegClock>{' '}
                                      {timeConvert((product.pages * 500) / 100)}
                                    </strong>
                                  </div>
                                )}

                                {product.publisher && (
                                  <div>
                                    <strong>
                                      <FaBuilding> </FaBuilding> Publisher:{' '}
                                    </strong>
                                    {product.publisher}
                                  </div>
                                )}
                                {product.author && (
                                  <div>
                                    <strong>
                                      <FaPenFancy></FaPenFancy> Author:{' '}
                                    </strong>
                                    {product.author}
                                  </div>
                                )}
                                {product.edition && (
                                  <div>
                                    <strong>
                                      <FaCalendarAlt></FaCalendarAlt> Edition:{' '}
                                    </strong>
                                    {product.edition}
                                  </div>
                                )}
                                {product.binding && (
                                  <div>
                                    <strong>
                                      {' '}
                                      <FaBookOpen></FaBookOpen> Binding:{' '}
                                    </strong>
                                    {product.binding}
                                  </div>
                                )}
                                <div>
                                  <strong>
                                    <FaLanguage></FaLanguage> Langauge:{' '}
                                  </strong>
                                  {product.langauge
                                    ? product.langauge
                                    : 'English'}
                                </div>
                              </div>
                            </ListGroup.Item>
                            <h2
                              className='title-main'
                              style={{ color: '#3C3C3B' }}
                            >
                              {product.name &&
                                product.author &&
                                product.name.toLowerCase() +
                                  ' by ' +
                                  product.author.toLowerCase()}
                            </h2>
                          </Container>
                        )}

                        <SocialShare
                          url={url}
                          title={product.name}
                        ></SocialShare>
                      </>
                    </Col>
                  </Row>

                  <Container>
                    <Row>
                      <Col md={12}>
                        {product.reviews.length > 0 && (
                          <Container>
                            <h5>Reviews</h5>
                            <ListGroup.Item key='reviewsss'>
                              <Container>
                                <Row>
                                  {reviewsList.map((review, index) => (
                                    <Col key={review._id} xs={12} md={4}>
                                      <span className='review-tile'>
                                        {review.name}
                                      </span>{' '}
                                      rated {review.rating} stars
                                      <br />
                                      <Rating value={review.rating}></Rating>
                                      <Row key={review._id}>
                                        <Col xs={12} md={4}>
                                          {review.image && (
                                            <LazyLoadImage
                                              src={
                                                review.imageNew
                                                  ? review.imageNew.replace(
                                                      /\\/g,
                                                      '/'
                                                    )
                                                  : review.image.replace(
                                                      /\\/g,
                                                      '/'
                                                    )
                                              }
                                              key={review._id}
                                              className='reviewImage'
                                            />
                                          )}
                                        </Col>
                                        <Col xs={12} md={8} className='right'>
                                          <p key={review._id}>
                                            {review.comment}
                                          </p>
                                        </Col>
                                      </Row>
                                    </Col>
                                  ))}
                                </Row>
                                <Row className='homepage-mobile-bar'>
                                  <Link
                                    to={`/review/${match.params.id}/${match.params.slug}`}
                                  >
                                    <h6 className='text-center homepage-mobile-bar-col'>
                                      Read All Reviews
                                    </h6>
                                  </Link>
                                </Row>
                              </Container>
                            </ListGroup.Item>
                          </Container>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Container>
                        {/* <Col md={6}>
                    {!product.isBox && (
                      <>
                        <br />
                        <h5>GoodReads Reviews</h5>
                        <div id='goodreads-widget'>
                          <iframe
                            // allowtransparency='true'
                            frameBorder='0'
                            height='400'
                            id='the_iframe'
                            src='https://www.goodreads.com/api/reviews_widget_iframe?did=DEVELOPER_ID&amp;format=html&amp;isbn=9780552566032&amp;links=660&amp;min_rating=&amp;review_back=fff&amp;stars=000&amp;text=000'
                            width='100%'
                          ></iframe>
                        </div>
                      </>
                    )}
                  </Col> */}
                        <Col md={12}>
                          <Container>
                            <ListGroup.Item key='customer-review'>
                              <h6>Write a Review</h6>
                              {errorReview && (
                                <Message variant='danger'>
                                  {errorReview}
                                </Message>
                              )}
                              {userInfo ? (
                                <Form onSubmit={submitHandler}>
                                  <Form.Group controlId='rating'>
                                    <Form.Label>Rating</Form.Label>
                                    <Form.Control
                                      as='select'
                                      value={rating}
                                      onChange={(e) =>
                                        setRating(e.target.value)
                                      }
                                    >
                                      <option value=''>Select...</option>
                                      <option value='1'>1 - Poor</option>
                                      <option value='2'>2 - Fair</option>
                                      <option value='3'>3 - Good</option>
                                      <option value='4'>4 - Very Good</option>
                                      <option value='5'>5 - Excellent</option>
                                    </Form.Control>
                                    <Form.Group controlId='image'>
                                      <Form.Label>Image</Form.Label>
                                      <Form.File
                                        id='image-file'
                                        label='Choose File'
                                        custom
                                        onChange={uploadHandler}
                                      ></Form.File>
                                      {uploading && <Loader></Loader>}
                                    </Form.Group>
                                    <FormGroup controlId='comment'>
                                      <Form.Label>Comment</Form.Label>
                                      <Form.Control
                                        as='textarea'
                                        value={comment}
                                        row='3'
                                        onChange={(e) =>
                                          setComment(e.target.value)
                                        }
                                      ></Form.Control>
                                    </FormGroup>
                                    <Button type='submit' variant='primary'>
                                      Comment
                                    </Button>
                                  </Form.Group>
                                </Form>
                              ) : (
                                <Message>
                                  Please{' '}
                                  <Link to='/login'>Login to Review</Link>
                                </Message>
                              )}
                            </ListGroup.Item>
                            <br />
                          </Container>
                        </Col>
                      </Container>
                    </Row>
                    {!product.isBox && <FooterMenu></FooterMenu>}

                    {/* <Row>
              <Col xs={12}>
                {authorLoading ? (
                  <Loader></Loader>
                ) : authorError ? (
                  <Message>
                    <h2>error</h2>
                  </Message>
                ) : (
                  <>
                    <Row className='homepage-mobile-bar'>
                      <Col xs={7}>
                        <div className='homepage-mobile-bar-col'>
                          Books from Author {product.author && product.author}
                        </div>
                      </Col>
                      <Col xs={5}>
                        <Link
                          to={`/author/${product.author}`}
                          className='float-right mt-2 homepage-mobile-bar-col'
                        >
                          See All
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      {authorProducts.map((product) => (
                        <Col
                          key={product._id}
                          sm={4}
                          md={4}
                          lg={2}
                          xs={4}
                          className='product-grid-size'
                        >
                          <Route
                            render={({ history }) => (
                              <Product history={history} product={product} />
                            )}
                          />
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
              </Col>
            </Row> */}
                    <Container>
                      <Row>
                        <Col>
                          <Row className='homepage-mobile-bar'>
                            {recentProducts.length > 1 && (
                              <Col xs={7}>
                                <div className='homepage-mobile-bar-col'>
                                  Recently Viewed
                                </div>
                                {/* {userAgent.includes('wv') ? 'wb' : 'b'} */}
                              </Col>
                            )}
                          </Row>
                          <Row>
                            {recentProducts.length > 1 &&
                              recentProducts.map((product) => (
                                <Col
                                  key={product._id}
                                  sm={4}
                                  md={4}
                                  lg={2}
                                  xs={4}
                                  className='product-grid-size'
                                >
                                  <Route
                                    render={({ history }) => (
                                      <Product
                                        history={history}
                                        product={product}
                                      />
                                    )}
                                  />
                                </Col>
                              ))}
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Container>
                </>
              ) : (
                <Col style={{ marginTop: '0px' }}>
                  <Row
                    style={{
                      position: 'fixed',
                      zIndex: '99999',
                      width: '100%',
                    }}
                    className='hackheader'
                  >
                    {/* <div className='timer-box'>India's largest book box</div> */}
                  </Row>
                  <Row className='text-center'>
                    <div className='background-container'>
                      <div className='text-container'>
                        <Link to={'/'}>
                          <LazyLoadImage
                            src='../images/logot.png'
                            alt='box'
                            width={'100px'}
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                          />
                        </Link>
                        <div className='boxherotext'>
                          Become Most Intresting Person in Room
                        </div>

                        {/* <h3 style={{ color: '#005d6e', marginTop: '-5px' }}>
                      
                    </h3> */}
                      </div>
                    </div>

                    <div
                      style={{
                        backgroundColor: '#405534',
                        padding: '16px',
                        color: '#fff',
                      }}
                    >
                      {/* <div className='text-center'>
                    <LazyLoadImage
                      src='https://s3-books-images.s3.ap-south-1.amazonaws.com/79819583-68e5-474e-9fcd-0b51cf31bac7.png'
                      alt={product.name}
                      width={'100%'}
                    />
                  </div> */}
                      <Typography style={{ marginTop: '2px' }}>
                        <strong>READ LEAD SUCCEED</strong>
                      </Typography>
                    </div>
                    {/* <div>
                  <p style={{ paddingTop: '6px', fontSize: '13px' }}>
                    🚀 Join the Top 3% of Readers by Reading 7 Books per year!📚
                  </p>
                </div> */}
                    <div style={{ backgroundColor: '#FCFCFC' }}>
                      <h1 className='boxtitle'>Surprise Box (15 books)</h1>
                      <div
                        style={{
                          fontSize: '14px',
                          marginTop: '8px',
                          fontWeight: 'bold',
                        }}
                      >
                        Let your Brain Explore the Unexplored!
                      </div>
                      <Rating value={4.8} text='4.8/5' />
                      <div style={{ fontWeight: '700' }}>4.74/5.0</div>
                      <small>5,255+ 5 star reviews</small>
                    </div>

                    {/* </Col> */}
                  </Row>
                  <Row
                    style={{ paddingTop: '8px', backgroundColor: '#FCFCFC' }}
                  >
                    <div
                      className='text-center'
                      style={{
                        marginBottom: '6px',
                        fontWeight: 'bold',
                        color: '#000 ',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                      }}
                    >
                      How it works? You select library type, intrest & size. Our
                      Experts will pick the books.
                      <br />
                    </div>

                    <div
                      className='text-center'
                      style={{
                        marginBottom: '9px',
                        fontSize: '18px',
                        color: '#000 ',
                        backgroundColor: '#D5DBDB',
                        maxWidth: '250px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        borderRadius: '5px',
                      }}
                    >
                      🔖10 FREE BOOKMARKS
                    </div>
                    <div
                      className='text-center'
                      style={{ marginBottom: '5px' }}
                    >
                      <strong className='red-text'>
                        -
                        {Math.round(
                          100 - (product.salePrice / product.price) * 100
                        )}
                        %{' '}
                      </strong>
                      <span
                        className='product-page-price themecolor'
                        style={{ color: '#000' }}
                      >
                        &#8377;
                        {product.salePrice &&
                          product.salePrice.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                        <span style={{ color: '#000', fontSize: '18px' }}>
                          <strike>
                            &#8377;
                            {product.price &&
                              product.price.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </strike>
                        </span>
                      </span>

                      {/* <div className='text-center' style={{ marginTop: '8px' }}>
                    <strong style={{ fontWeight: 'bold', color: '#405534' }}>
                      Free Shipping on All Boxes
                    </strong>
                  </div> */}
                    </div>
                  </Row>
                  <Container>
                    <Row className='text-center'>
                      <Col xs={12} md={12} style={{ marginTop: '0px' }}>
                        {vmessage && <div className='red-text'>{vmessage}</div>}

                        <Button
                          onClick={addToCartHandler}
                          type='button'
                          variant='contained'
                          className='btn-primary box-cart-button'
                          style={{ width: '70%' }}
                          disabled={
                            ((inventory && inventory.length == 0) || disable) &&
                            !product.variantSelected &&
                            !product.isBox
                          }
                        >
                          {inventory &&
                          inventory.length == 0 &&
                          !product.isBox ? (
                            'No Stock'
                          ) : (
                            <>
                              {!product.isEbook &&
                              !product.isBox &&
                              product.isBookscartBox ? (
                                <>
                                  <FaCartPlus className='orange'></FaCartPlus>{' '}
                                  Add to Box
                                </>
                              ) : (
                                <> Build Your Own Library</>
                              )}
                            </>
                          )}
                        </Button>

                        {addToCartMessage && (
                          <div
                            className='text-center'
                            style={{ marginTop: '6px' }}
                          >
                            <div className='mobile-view'>
                              <span className='very-small'>
                                Added to Cart! <Link to={'/cart'}>Cart</Link>
                              </span>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Container>
                        <Row
                          style={{
                            paddingTop: '8px',
                            paddingBottom: '12px',
                          }}
                        >
                          <Paper xs={4} md={4} className='gurenteeone'>
                            95,000+ Happy Readers
                          </Paper>
                          <Paper xs={4} md={4} className='gurenteeone'>
                            100% Original Books{' '}
                          </Paper>
                          <Paper xs={4} md={4} className='gurenteeone'>
                            100% Quality Guarantee
                          </Paper>
                        </Row>
                        <Row>
                          {product.isbn == 'BookscartBox1' && (
                            <>
                              <div className='savedText'>
                                <FaCheckCircle></FaCheckCircle> &nbsp;You saved
                                &#8377;2,700 on this order.
                              </div>
                              {product.isbn == 'BookscartBox1' && (
                                <div style={{ marginTop: '10px' }}>
                                  <Timer></Timer>
                                </div>
                              )}
                            </>
                          )}
                        </Row>
                      </Container>
                      <div
                        className='text-center'
                        style={{
                          fontWeight: 'bold',
                          color: '#000 ',
                          paddingLeft: '6px',
                          paddingRight: '6px',
                          fontSize: '14px',
                          marginBottom: '6px',
                        }}
                      >
                        {' '}
                        Do you want to pick books?{' '}
                        <a href='../online-book-fair'>Visit Online Bookfair</a>
                      </div>
                      <div>
                        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                          Seen on
                        </div>
                        <div>
                          <LazyLoadImage
                            src='../images/toi.png'
                            width={'140px'}
                          ></LazyLoadImage>
                          <LazyLoadImage
                            src='../images/startupindia.png'
                            width={'140px'}
                          ></LazyLoadImage>

                          <LazyLoadImage
                            src='../images/ownstartup.png'
                            width={'140px'}
                          ></LazyLoadImage>
                          <LazyLoadImage
                            src='../images/thehindu.jpg'
                            width={'140px'}
                          ></LazyLoadImage>
                        </div>
                      </div>
                      <br />
                    </Row>
                    <Row style={{ marginTop: '12px' }}>
                      <Col md={12}>
                        {product.reviews.length > 0 && (
                          <>
                            <h2
                              className='text-center'
                              style={{ fontSize: '22px' }}
                            >
                              Reviews
                            </h2>
                            <ListGroup.Item key='reviewsss'>
                              <Container>
                                <Row>
                                  {reviewsList.map((review, index) => (
                                    <Col key={review._id} xs={12} md={4}>
                                      <span className='review-tile'>
                                        {review.name}
                                      </span>{' '}
                                      <small style={{ fontSize: '13px' }}>
                                        {' '}
                                        rated {review.rating} stars.
                                        <FaCheck></FaCheck> Verified Buyer
                                      </small>
                                      <br />
                                      <Rating value={review.rating}></Rating>
                                      <Row key={review._id}>
                                        <Col xs={12} md={4}>
                                          {review.image && (
                                            <LazyLoadImage
                                              src={
                                                review.imageNew
                                                  ? review.imageNew.replace(
                                                      /\\/g,
                                                      '/'
                                                    )
                                                  : review.image.replace(
                                                      /\\/g,
                                                      '/'
                                                    )
                                              }
                                              key={review._id}
                                              className='reviewImage'
                                            />
                                          )}
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={8}
                                          className='right'
                                          style={{
                                            padding: '9px',
                                            wordWrap: 'break-word',
                                          }}
                                        >
                                          <p key={review._id}>
                                            {review.comment.slice(0, 300)}
                                          </p>
                                        </Col>
                                      </Row>
                                    </Col>
                                  ))}
                                </Row>
                                <Row className='homepage-mobile-bar'>
                                  <Link
                                    to={`/review/${match.params.id}/${match.params.slug}`}
                                    target='_blank'
                                  >
                                    <h2 className='text-center homepage-mobile-bar-col'>
                                      Read All Mystery Box Reviews
                                    </h2>
                                  </Link>
                                </Row>
                                <Row class='text-center'>
                                  {vmessage && (
                                    <div className='red-text'>{vmessage}</div>
                                  )}

                                  <Button
                                    onClick={addToCartHandler}
                                    type='button'
                                    variant='contained'
                                    className='btn-primary box-cart-button'
                                    style={{
                                      width: '70%',
                                      marginLeft: 'auto',
                                      marginRight: 'auto',
                                    }}
                                    disabled={
                                      ((inventory && inventory.length == 0) ||
                                        disable) &&
                                      !product.variantSelected &&
                                      !product.isBox
                                    }
                                  >
                                    {inventory &&
                                    inventory.length == 0 &&
                                    !product.isBox ? (
                                      'No Stock'
                                    ) : (
                                      <>
                                        {!product.isEbook &&
                                        !product.isBox &&
                                        product.isBookscartBox ? (
                                          <>
                                            <FaCartPlus className='orange'></FaCartPlus>{' '}
                                            Add to Box
                                          </>
                                        ) : (
                                          <>Build Your Own Library</>
                                        )}
                                      </>
                                    )}
                                  </Button>
                                </Row>
                              </Container>
                            </ListGroup.Item>
                          </>
                        )}
                      </Col>
                      {/* <div className='text-center' style={{ marginTop: '12px' }}>
                    <h2>Unboxing Surprise Box</h2>
                    <iframe
                      width='100%'
                      height='315'
                      src='https://www.youtube.com/embed/6sZ1ol2rrFg'
                      title='YouTube video player'
                      frameborder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                      allowfullscreen
                    ></iframe>
                  </div> */}

                      <BoxDescription isbn={product.isbn}></BoxDescription>
                    </Row>
                  </Container>
                </Col>
              )}
              <Modal show={showAddToCart} onHide={handleCloseAddtoCart}>
                {/* <Modal.Body>
              <>
                <Form className='cart-form'>
                  <Row>
                    <Col xs={12} md={12}>
                      <div>Buy 2 boxes and get 5 free books</div>
                    </Col>
                    <Col xs={6} md={6}>
                      <Button>Add one more Box</Button>
                    </Col>
                    <Col xs={6} md={6}>
                      <Button>Continue</Button>
                    </Col>
                  </Row>
                </Form>
              </>
            </Modal.Body> */}
                <Modal.Body>
                  <Form
                    onSubmit={submitHandler2}
                    className='cart-form'
                    style={{ marginTop: '35px' }}
                  >
                    <div style={{ padding: '20px' }}>
                      <Form.Group>
                        {product.isBox ? (
                          <div className='text-center'>
                            {/* <h5>Free Shipping on mystery book box</h5> */}
                            <small>
                              Gift the Love of Reading to friends & family{' '}
                            </small>
                          </div>
                        ) : (
                          <>
                            <h5>Save your Cart</h5>
                            <small>Reading makes you Happy!</small>
                          </>
                        )}
                        <div className='text-center'>
                          <Button
                            variant='contained'
                            onClick={selectMorebooks}
                            style={{
                              float: 'center',
                              width: '70%',
                              marginTop: '15px',
                            }}
                          >
                            Add One More Box
                          </Button>{' '}
                          <br />
                          <Button
                            variant='contained'
                            style={{
                              float: 'center',
                              width: '70%',
                              marginTop: '15px',
                            }}
                            onClick={continueButton}
                          >
                            Continue
                          </Button>
                        </div>
                      </Form.Group>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
              <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                  <Form
                    onSubmit={submitHandler2}
                    className='cart-form'
                    style={{ marginTop: '35px' }}
                  >
                    <div style={{ padding: '20px' }}>
                      <Form.Group>
                        {product.isBox ? (
                          <>
                            <h5>Save your Box</h5>
                            <small>Reading makes you Happy!</small>
                          </>
                        ) : (
                          <>
                            <h5>Save your Cart</h5>
                            <small>Reading makes you Happy!</small>
                          </>
                        )}
                        <div className='red-text'>
                          {phoneMessage && phoneMessage}
                        </div>
                        <br />
                        <Form.Control
                          type='Number'
                          placeholder='Enter Phone Number'
                          required
                          value={email || ''}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                        <br />
                      </Form.Group>
                      <Row>
                        <Col>
                          <Button
                            variant='outlined'
                            onClick={handleClose}
                            style={{ float: 'left' }}
                          >
                            <small>Don't save</small>
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type='submit'
                            variant='contained'
                            className='float-right'
                          >
                            Save Cart
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
              <Modal
                show={arShow}
                onHide={handlearClose}
                dialogClassName='ar-modal'
              >
                {!arSucces ? (
                  <Loader></Loader>
                ) : (
                  <Modal.Body className='summary-reading'>
                    <div style={{ padding: '10px' }}>
                      <div style={{ width: '100%' }}>
                        <Button
                          onClick={handlearClose}
                          style={{ float: 'right' }}
                        >
                          X
                        </Button>{' '}
                      </div>
                      <div>
                        {arProduct.name} by{' '}
                        {arProduct.author && arProduct.author.name}{' '}
                        {bookAttribute.goodreadRating && (
                          <>
                            <br />
                            <Rating
                              value={bookAttribute.goodreadRating}
                              reviews={bookAttribute.gnumReviews}
                            />{' '}
                            {bookAttribute.gnumReviews &&
                              '(' + bookAttribute.gnumReviews + ')'}
                          </>
                        )}
                      </div>
                      <div>
                        <br />
                        {bookAttribute.emotions && (
                          <>
                            <strong
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Emotions:
                            </strong>{' '}
                            <small
                              style={{
                                textTransform: 'capitalize',
                                wordWrap: 'break-word',
                              }}
                            >
                              {bookAttribute.emotions.join()}
                            </small>
                            <br />
                          </>
                        )}
                        {bookAttribute.mainNarater && (
                          <>
                            <strong
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Main Narrator:
                            </strong>{' '}
                            <small
                              style={{
                                textTransform: 'capitalize',
                                wordWrap: 'break-word',
                              }}
                            >
                              {bookAttribute.mainNarater +
                                '(' +
                                bookAttribute.mainCharacterGender +
                                ')'}{' '}
                            </small>
                            <br />
                          </>
                        )}
                        {bookAttribute.ageGroupRange && (
                          <>
                            <strong
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Suitable Age:
                            </strong>{' '}
                            <small
                              style={{
                                textTransform: 'capitalize',
                                wordWrap: 'break-word',
                              }}
                            >
                              {bookAttribute.ageGroupRange}
                            </small>
                            <br />
                          </>
                        )}

                        {bookAttribute.places && (
                          <>
                            <strong
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Places:
                            </strong>{' '}
                            <small
                              style={{
                                textTransform: 'capitalize',
                                wordWrap: 'break-word',
                              }}
                            >
                              {bookAttribute.places.join()}
                            </small>
                            <br />
                          </>
                        )}
                        {bookAttribute.keywords && (
                          <>
                            <strong
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Key Events:
                            </strong>{' '}
                            <small
                              style={{
                                textTransform: 'capitalize',
                                wordWrap: 'break-word',
                                width: '100%',
                              }}
                            >
                              {bookAttribute.keywords.join()}
                            </small>
                          </>
                        )}
                        <br />
                      </div>
                      <div>
                        {bookAttribute.mainCharacters &&
                          bookAttribute.mainCharacters.length > 0 && (
                            <>
                              <div>
                                <div className='summary-titles'>
                                  Key Characters
                                </div>

                                {bookAttribute.mainCharacters.map((ch) => (
                                  <div
                                    key={ch.name}
                                    style={{ marginBottom: '10px' }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {ch.name}
                                    </div>{' '}
                                    {ch.profession && ch.profession}{' '}
                                    {ch.sex && ch.sex}{' '}
                                    {ch.age && '(' + ch.age + ')'}
                                    <br />
                                    {ch.traits && (
                                      <>
                                        <span style={{ fontWeight: 'bold' }}>
                                          Traits:
                                        </span>{' '}
                                        {ch.traits.join()}
                                        <br />
                                      </>
                                    )}
                                    {ch.emotions && (
                                      <>
                                        <span style={{ fontWeight: 'bold' }}>
                                          Emotions:{' '}
                                        </span>
                                        {ch.emotions.join()}
                                        <br />
                                      </>
                                    )}
                                    {ch.description}
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                      </div>
                      <div>
                        {bookAttribute.plotSummary && (
                          <>
                            <div className='summary-titles'>
                              99bookscart Review
                            </div>
                            <p
                              style={{
                                whiteSpace: 'pre-wrap',
                                marginTop: '-40px',
                              }}
                            >
                              {bookAttribute.plotSummary}
                            </p>
                          </>
                        )}
                      </div>
                      <div>
                        {bookAttribute.insights && (
                          <>
                            <div className='summary-titles'>
                              What you will learn from reading this book?
                            </div>
                            <p
                              style={{
                                whiteSpace: 'pre-wrap',
                                marginTop: '-40px',
                              }}
                            >
                              {bookAttribute.insights}
                            </p>
                          </>
                        )}
                      </div>

                      <div>
                        {bookAttribute.dates && (
                          <>
                            Dates:
                            {bookAttribute.dates.join()}
                          </>
                        )}

                        {bookAttribute.quotes && (
                          <>
                            <div className='summary-titles'>Quotes:</div>

                            {arProduct.bookAttribute.quotes.map((ch) => (
                              <div key={ch} style={{ marginBottom: '8px' }}>
                                {ch}
                                <br />
                                {ch.description}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div>
                        {arProduct.author && (
                          <>
                            <tr>
                              <td colSpan={2}>
                                <div className='summary-titles'>
                                  About Author
                                </div>
                                <p>{arProduct.author.about}</p>
                              </td>
                            </tr>
                          </>
                        )}
                      </div>
                      <div style={{ width: '100%' }}>
                        <Button
                          onClick={handlearClose}
                          style={{ float: 'right' }}
                        >
                          X
                        </Button>{' '}
                      </div>
                    </div>
                  </Modal.Body>
                )}
              </Modal>
              <Modal
                show={showFunnel}
                onHide={handleClose}
                backdrop='static'
                dialogClassName='ar-modal'
                style={{
                  paddingLeft: '0px !important',
                }}
              >
                <StepFunnel history={history} product={product}></StepFunnel>
              </Modal>
            </Row>
          )}
        </>
      )}
    </>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
function loop(index) {
  if (index < 0) {
    return
  }
}
export default ProductScreen
