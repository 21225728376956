import axios from 'axios'
import {
  COMMENT_EPISODE_FAIL,
  COMMENT_EPISODE_REQUEST,
  COMMENT_EPISODE_SUCCEES,
  COMMENT_LIST_FAIL,
  COMMENT_LIST_REQUEST,
  COMMENT_LIST_SUCCEES,
  CREATE_NEW_EPISODE_FAIL,
  CREATE_NEW_EPISODE_REQUEST,
  CREATE_NEW_EPISODE_SUCCEES,
  DELETE_EPISODE_FAIL,
  DELETE_EPISODE_REQUEST,
  DELETE_EPISODE_SUCCEES,
  GET_EPISODE_FAIL,
  GET_EPISODE_REQUEST,
  GET_EPISODE_SUCCEES,
  GET_FREE_EPISODE_FAIL,
  GET_FREE_EPISODE_REQUEST,
  GET_FREE_EPISODE_SUCCEES,
  READ_EPISODE_FAIL,
  READ_EPISODE_REQUEST,
  READ_EPISODE_SUCCEES,
} from '../constants/episodeConstants'

export const createNewEpisode = (episode) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_NEW_EPISODE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/episode/`, episode, config)
    dispatch({
      type: CREATE_NEW_EPISODE_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CREATE_NEW_EPISODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getEpisodeData = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_EPISODE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/episode/${id}`, config)
    dispatch({
      type: GET_EPISODE_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_EPISODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const readEpisode =
  (id, sid = 0) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: READ_EPISODE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(`/api/episode/read/${id}`, config)

      dispatch({
        type: READ_EPISODE_SUCCEES,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: READ_EPISODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const readFreeEpisode = (id, sid) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_FREE_EPISODE_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.get(
      `/api/episode/free/read/${id}/${sid}`,
      config
    )

    dispatch({
      type: GET_FREE_EPISODE_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_FREE_EPISODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const voteEpisode = (episode) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.get(`/api/episode/vote/${episode}`, config)
  } catch (error) {}
}

export const commentEpisode = (comment) => async (dispatch, getState) => {
  try {
    dispatch({ type: COMMENT_EPISODE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/episode/comment/`, comment, config)
    dispatch({
      type: COMMENT_EPISODE_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: COMMENT_EPISODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listCommentEpisode = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COMMENT_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/episode/comment/${id}`, config)
    dispatch({
      type: COMMENT_LIST_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: COMMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const deleteEpisode = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_EPISODE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/episode/delete/${id}`, config)
    dispatch({
      type: DELETE_EPISODE_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_EPISODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
