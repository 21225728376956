import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  ListGroupItem,
  Form,
  FormGroup,
  Alert,
} from 'react-bootstrap'
import Rating from '../components/Rating'
import axios from 'axios'
import {
  listProductsDetailsForRedirect,
  createProductReview,
} from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_PAYMENTTYPE_FREE,
  PRODUCT_PAYMENTTYPE_SUBSCRIPTION,
} from '../constants/productConstants'
import Meta from '../components/Meta'
import { addToCart, recentlyViewed } from '../actions/cartActions.js'
import { Container } from 'react-bootstrap'
import { Route } from 'react-router-dom'
import Product from '../components/Product'
import { listProductsByAuthor } from '../actions/categoryActions'
import BoxProgressBar from '../components/BoxProgressBar'
import {
  FaFlagCheckered,
  FaTruck,
  FaCompressArrowsAlt,
  FaCheck,
  FaGooglePlay,
  FaHeart,
  FaBarcode,
  FaRegNewspaper,
  FaBuilding,
  FaPenFancy,
  FaCalendarAlt,
  FaBookOpen,
  FaLanguage,
} from 'react-icons/fa'

import {
  addToIntrested,
  addToWIshlist,
  getWishlist,
  addToAlreadRead,
  getIntrestsByProduct,
  getAlreadyReadsByProduct,
} from '../actions/wishlistActions'
import ReactHtmlParser from 'react-html-parser'
const RedirectProductScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const [qty, setQty] = useState(1)
  const [addToCartMessage, setAddToCartMessage] = useState(false)
  const [vprice, setVprice] = useState(0)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [slug, setSlug] = useState('')
  const [image, setImage] = useState('')
  const [disable, setDisable] = React.useState(false)
  const [uploading, setUploading] = useState(false)
  const [isReadMore, setIsReadMore] = useState(true)
  const [intrestCheck, setIntrestCheck] = useState(false)
  const [bookcartBoxCount, SetBookcartBoxCount] = useState(0)
  const [alreadyReadCheck, setAlreadyReadCheck] = useState(false)
  const [inBox, SetInBox] = useState(false)

  const productReviewCreate = useSelector((state) => state.productReviewCreate)
  const { success: successReview, error: errorReview } = productReviewCreate

  const cart = useSelector((state) => state.cart)
  const { cartItems, cartType, recentView: recentProducts } = cart

  const productDetails = useSelector((state) => state.productRedirect)
  const { loading, error, product, success, readingCount } = productDetails

  const authorProduct = useSelector((state) => state.authorProduct)
  const {
    loading: authorLoading,
    error: authorError,
    products: authorProducts,
  } = authorProduct

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const wishlist = useSelector((state) => state.wishlistAdd)
  const { success: wishlistSucces } = wishlist

  const wishlists = useSelector((state) => state.wishlistGet)
  const { wishlists: userWishList, success: userWishlistSucces } = wishlists

  const intrest = useSelector((state) => state.intrestAdd)
  const { success: intrestSucces } = intrest

  const alreadyRead = useSelector((state) => state.alreadyReadAdd)
  const { success: alreadyReadSucces } = alreadyRead

  const intrests = useSelector((state) => state.getIntrestsByProduct)
  const { success: intrestsSucces, intrestsCount } = intrests

  const alreadyReads = useSelector((state) => state.getAlreadyReadsByProduct)
  const { success: alreadyReadsSucces, alreadyreadsCount } = alreadyReads

  let inWishlist = false
  // if(userWishlistSucces){
  //     userWishList.map(item=>{
  //         if(item.product == product._id){
  //             inWishlist = true
  //         }
  //     })
  // }

  if (success && product && product.description) {
    product.description =
      product.description && product.description.replace(/<[^>]*>?/gm, '')
  }
  useEffect(() => {
    if (product && !success) {
      setDisable(false)
      window.scrollTo(0, 0)
      dispatch(listProductsDetailsForRedirect(match.params.slug))
    }

    if (success) {
      if (!product || !product.name) {
        history.push('/book/BookscartBox1/surprise-box-15-books')
      } else {
        setSlug(convertToSlug(product.name))
        dispatch(recentlyViewed(product._id, 1))
        dispatch(getIntrestsByProduct(product._id))
        dispatch(getAlreadyReadsByProduct(product._id))
        dispatch(listProductsByAuthor(product.author, 1, true))
      }
    }
    if (cartItems && product && product.name) {
      SetInBox(false)
      if (cartItems.length > 0) {
        var count = 0
        cartItems.forEach((item, index) => {
          if (item.isBookscartBox) {
            if (
              (item.isbn == product.isbn || item.isbn == match.params.id) &&
              !item.variantSelected
            ) {
              SetInBox(true)
            }
            count++
          }
        })
        SetBookcartBoxCount(count)
      }
    }
    if (successReview) {
      setRating(0)
      setComment('')
      setImage('')
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    }
    if (userInfo) {
      // dispatch(getWishlist(product._id))
    }
    if (intrestSucces) {
      setIntrestCheck(true)
    }
    if (alreadyReadSucces) {
      setAlreadyReadCheck(true)
    }
  }, [
    dispatch,
    match,
    successReview,
    success,
    product,
    intrestSucces,
    alreadyReadSucces,
    cartItems,
    bookcartBoxCount,
  ])
  const uploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const BuyNowHandler = () => {
    let variantId = 0
    if (vprice.length > 0) {
      var e = document.getElementById('boxTypeSelect')
      variantId = e.options[e.selectedIndex].getAttribute('data-id')
    } else if (product.isBox) {
      variantId = product.boxType[0]._id
    }
    dispatch(addToCart(product._id, 1, variantId))
    history.push('/cart')
  }
  const addToCartHandler = () => {
    let variantId = 0
    if (vprice.length > 0) {
      var e = document.getElementById('boxTypeSelect')
      variantId = e.options[e.selectedIndex].getAttribute('data-id')
    } else if (product.isBox) {
      variantId = product.boxType[0]._id
    }
    dispatch(addToCart(product._id, 1, variantId))
    setAddToCartMessage(true)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProductReview(product._id, {
        rating,
        comment,
        image,
      })
    )
  }

  const updateVariantPrice = (ee) => {
    var e = document.getElementById('boxTypeSelect')
    product.salePrice = e.options[e.selectedIndex].getAttribute('data-name')
    // setVprice(e.options[e.selectedIndex].value)
    // product.salePrice= e.target.value.name
    setVprice(ee.target.value)
  }
  const addToUserWishlist = (e) => {
    e.preventDefault()
    if (userInfo) {
      dispatch(addToWIshlist(product._id))
    } else {
      history.push('/login')
    }
  }
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  const readNow = () => {
    if (userInfo) {
      history.push(`/read/${product.isbn}/${slug}`)
    } else {
      history.push('/login')
    }
  }

  const subscribeNow = () => {
    if (userInfo) {
      history.push(`/profile/subscription`)
    } else {
      history.push('/login')
    }
  }

  const addToIntrestUser = (e) => {
    e.preventDefault()
    if (userInfo) {
      dispatch(addToIntrested(product._id))
    } else {
      history.push('/login')
    }
  }
  const addToAlreadReadUser = (e) => {
    e.preventDefault()
    if (userInfo) {
      dispatch(addToAlreadRead(product._id))
    } else {
      history.push('/login')
    }
  }
  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        product && (
          <>
            <Meta title={product.name} description={product.description}></Meta>
            <Container>
              <Row>
                {userInfo && userInfo.isAdmin && (
                  <Link to={`/admin/product/${product._id}/edit`}>
                    <Button type='button' className='btn btn-block'>
                      Edit
                    </Button>
                  </Link>
                )}
                <Col md={6}>
                  <div className='text-center'>
                    <div className='mobile-view'>
                      {product.isBookscartBox && (
                        <BoxProgressBar
                          history={history}
                          value={bookcartBoxCount}
                        />
                      )}
                      <h3 className='product-title'>{product.name}</h3>
                      {!product.isBox && (
                        <Link to={`/author/${product.author}`}>
                          <small className='text-muted themeColor'>
                            by {product.author}{' '}
                          </small>
                        </Link>
                      )}
                      {/* <div><span className='popularity-text'>{product.grating > 0 && (<Rating value={product.grating} text={`(${product.gnumReviews.toLocaleString()} reviews)`}/>)}</span></div> */}
                    </div>
                    <Image
                      src={product.image}
                      alt={product.name}
                      fluid
                      className='product-image'
                    />
                    <br />
                  </div>
                </Col>
                <Col md={6}>
                  <ListGroup variants='flush'>
                    <ListGroup.Item key='title'>
                      <>
                        <div className='desktop-view'>
                          <h3 className='product-title'>{product.name}</h3>
                          {!product.isBox && (
                            <Link to={`/author/${product.author}`}>
                              <small className='text-muted themeColor'>
                                by {product.author}{' '}
                              </small>
                            </Link>
                          )}
                          {/* <div><span className='popularity-text'>{product.grating > 0 && (<Rating value={product.grating} text={`(${product.gnumReviews.toLocaleString()} reviews)`}/>)}</span></div> */}
                        </div>
                        {product.isEbook && (
                          <div className='ebook-mention mobile-center'>
                            ebook
                          </div>
                        )}
                        {product.isEbook && readingCount > 0 && (
                          <div className='mobile-center' key='readingCount'>
                            <small>(Readers {readingCount})</small>
                          </div>
                        )}
                        <div className='add-to-wishlist'>
                          <Link
                            variant='light'
                            to='/'
                            disabled={inWishlist}
                            onClick={(e) => addToUserWishlist(e)}
                          >
                            <small>
                              <FaHeart></FaHeart>{' '}
                              {wishlistSucces || inWishlist
                                ? 'In Wishlist'
                                : 'Add to Wishlist'}
                            </small>
                          </Link>
                        </div>
                        {/* <strike>&#8377;{product.price}</strike> */}
                        {product.isBookscartBox && (
                          <div key='progressbar' className='desktop-view'>
                            <BoxProgressBar
                              history={history}
                              value={bookcartBoxCount}
                            />
                          </div>
                        )}
                      </>
                    </ListGroup.Item>
                    {product.numReviews > 0 && (
                      <ListGroup.Item key='reviews' className='mobile-center'>
                        <Rating
                          value={product.rating}
                          text={`${product.numReviews} reviews`}
                        />
                        {/* ({product.views}) Views */}
                      </ListGroup.Item>
                    )}

                    <ListGroup.Item>
                      <div className='text-center-product'>
                        {product.paymentType == PRODUCT_PAYMENTTYPE_FREE ? (
                          <div className='product-page-price themecolor'>
                            FREE
                          </div>
                        ) : product.paymentType ==
                          PRODUCT_PAYMENTTYPE_SUBSCRIPTION ? (
                          <div>Get 1000's of books for just ₹149/month</div>
                        ) : (
                          <>
                            <span className='product-page-price themecolor'>
                              &#8377;{product.salePrice}
                            </span>{' '}
                            &nbsp;
                            <strike>&#8377;{product.price}</strike>
                            <br />
                            <small className='text-muted'>
                              Saving &#8377;
                              {Math.round(product.price - product.salePrice)} (
                              {Math.round(
                                100 - (product.salePrice / product.price) * 100
                              )}
                              % OFF)
                            </small>
                            {!product.isEbook && (
                              <div className='delivery-date'>
                                <FaTruck></FaTruck> Delivered in 7-9 days
                              </div>
                            )}
                            {!product.isBox &&
                              (product.isBookscartBox ? (
                                <>
                                  <small className='green-text'>
                                    Available for BookscartBox
                                  </small>
                                </>
                              ) : (
                                <>
                                  <small className='red-text'>
                                    Not available for BookscartBox
                                  </small>
                                </>
                              ))}
                          </>
                        )}
                      </div>
                    </ListGroup.Item>
                    {product.isBox && (
                      <ListGroup.Item>
                        <Row>
                          <Form.Label>Select Box Type</Form.Label>
                          <Form.Control
                            as='select'
                            id='boxTypeSelect'
                            value={vprice}
                            onChange={(e) => updateVariantPrice(e)}
                          >
                            {product.boxType.map((x) => (
                              <option
                                key={x._id}
                                data-id={x._id}
                                data-name={x.price}
                                value={x.name}
                              >
                                {x.name}
                              </option>
                            ))}
                          </Form.Control>
                        </Row>
                      </ListGroup.Item>
                    )}
                    {/* {product.countInStock > 0 && (
                    <ListGroup.Item>
                        <Row>
                            <Col sm={7}>Qty</Col>
                            <Col sm={1}>
                                <Form.Control as='select' value={qty} onChange={(e)=>
                                        setQty(e.target.value)}>
                                    {[...Array(product.countInStock).keys()].map((x)=>(
                                        <option key={x+1} value={x+1}>{x+1}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                )} */}
                    {/* <ListGroup.Item key='appdownload'>
                <a href='https://play.google.com/store/apps/details?id=boxyourbooks.com' target="new"><FaGooglePlay/>  Download App</a>
                </ListGroup.Item> */}

                    {!product.isEbook && (
                      <ListGroup.Item key='cartbutton'>
                        <>
                          <div>
                            {addToCartMessage && (
                              <>
                                <div className='very-small-line-height mobile-center line-height'>
                                  <span className='very-small'>
                                    Added to Cart! &nbsp;&nbsp;
                                    <Link to='/cart'>View Cart</Link>
                                  </span>
                                </div>
                                <br />
                              </>
                            )}
                          </div>
                          {/* {!inBox ? ( */}
                          <Row>
                            <Col xs={6} md={4}>
                              <Button
                                className='btn-block mt-auto addtocart'
                                onClick={addToCartHandler}
                                type='button'
                                disabled={
                                  (product.countInStock == 0 || disable) &&
                                  !product.variantSelected
                                }
                              >
                                {product.countInStock == 0 ? (
                                  'No Stock'
                                ) : (
                                  <i className='fa fa-box-open'> Add to Cart</i>
                                )}
                              </Button>
                            </Col>
                            <Col xs={6} md={8}>
                              {product.countInStock > 0 && (
                                <>
                                  <Button
                                    className='btn-block mt-auto'
                                    onClick={BuyNowHandler}
                                    type='button'
                                    disabled={
                                      (product.countInStock == 0 || disable) &&
                                      !product.variantSelected
                                    }
                                  >
                                    {product.countInStock == 0 ? (
                                      'No Stock'
                                    ) : (
                                      <i className='fa fa-box-open'>
                                        {' '}
                                        Buy Now{' '}
                                      </i>
                                    )}
                                  </Button>
                                </>
                              )}
                            </Col>
                          </Row>
                          {/* ) : (
                          <p className='mobile-center'>
                            Added in Box. <Link to='/cart'>View Cart</Link>
                          </p>
                        )} */}
                        </>
                      </ListGroup.Item>
                    )}

                    {product.isEbook &&
                      (product.paymentType == PRODUCT_PAYMENTTYPE_FREE ? (
                        <ListGroup.Item>
                          <>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  className='btn-block mt-auto readnow-button'
                                  onClick={readNow}
                                  type='button'
                                  disabled={
                                    (product.countInStock == 0 || disable) &&
                                    !product.variantSelected
                                  }
                                >
                                  {product.countInStock == 0 ? (
                                    'No Stock'
                                  ) : (
                                    <i className='fa fa-box-open'> Read Now </i>
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          </>
                        </ListGroup.Item>
                      ) : product.paymentType ==
                        PRODUCT_PAYMENTTYPE_SUBSCRIPTION ? (
                        <ListGroup.Item>
                          <>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  className='btn-block mt-auto readnow-button'
                                  onClick={subscribeNow}
                                  type='button'
                                  disabled={
                                    (product.countInStock == 0 || disable) &&
                                    !product.variantSelected
                                  }
                                >
                                  {product.countInStock == 0 ? (
                                    'No Stock'
                                  ) : (
                                    <i className='fa fa-box-open'>
                                      {' '}
                                      SUBSCRIBE NOW{' '}
                                    </i>
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          </>
                        </ListGroup.Item>
                      ) : (
                        <ListGroup.Item>
                          <>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  className='btn-block mt-auto readnow-button'
                                  onClick={readNow}
                                  type='button'
                                  disabled={
                                    (product.countInStock == 0 || disable) &&
                                    !product.variantSelected
                                  }
                                >
                                  {product.countInStock == 0 ? (
                                    'No Stock'
                                  ) : (
                                    <i className='fa fa-box-open'>
                                      {' '}
                                      Purchase and Read{' '}
                                    </i>
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          </>
                        </ListGroup.Item>
                      ))}
                    <ListGroup.Item key='action'>
                      <Row className='mobile-center'>
                        <div>
                          <Link
                            onClick={(e) => addToIntrestUser(e)}
                            to=''
                            className='user-intrest'
                          >
                            <FaCompressArrowsAlt> </FaCompressArrowsAlt>{' '}
                            Intrested
                            <span className='text-muted'>
                              {intrestsSucces &&
                                '(' + intrestsCount.count + ')'}
                            </span>
                          </Link>{' '}
                          <Link
                            to=''
                            onClick={(e) => addToAlreadReadUser(e)}
                            className='user-intrest'
                          >
                            <FaFlagCheckered></FaFlagCheckered> Already Read
                            <span className='text-muted'>
                              {alreadyReadsSucces &&
                                '(' + alreadyreadsCount.count + ')'}
                            </span>
                          </Link>
                        </div>
                      </Row>
                    </ListGroup.Item>
                    {product && !product.isBox && (
                      <ListGroup.Item key='details'>
                        <div className='product-details'>
                          <div>
                            <strong>
                              <FaBarcode></FaBarcode> ISBN-13:{' '}
                            </strong>
                            {product.isbn}
                          </div>
                          {product.pages > 1 && (
                            <div>
                              <strong>
                                <FaRegNewspaper></FaRegNewspaper> Pages:{' '}
                              </strong>
                              {product.pages}
                            </div>
                          )}
                          {product.publisher && (
                            <div>
                              <strong>
                                <FaBuilding> </FaBuilding> Publisher:{' '}
                              </strong>
                              {product.publisher}
                            </div>
                          )}
                          {product.author && (
                            <div>
                              <strong>
                                <FaPenFancy></FaPenFancy> Author:{' '}
                              </strong>
                              {product.author}
                            </div>
                          )}
                          {product.edition && (
                            <div>
                              <strong>
                                <FaCalendarAlt></FaCalendarAlt> Edition:{' '}
                              </strong>
                              {product.edition}
                            </div>
                          )}
                          {product.binding && (
                            <div>
                              <strong>
                                {' '}
                                <FaBookOpen></FaBookOpen> Binding:{' '}
                              </strong>
                              {product.binding}
                            </div>
                          )}
                          <div>
                            <strong>
                              <FaLanguage></FaLanguage> Langauge:{' '}
                            </strong>
                            {product.langauge ? product.langauge : 'English'}
                          </div>
                        </div>
                      </ListGroup.Item>
                    )}
                    {product.categories.length > 0 && !product.isBox && (
                      <ListGroup.Item key='category'>
                        <h5>Category: </h5>
                        {product.categories.map((category) => (
                          <Link
                            to={`/category/${category.slug}`}
                            key={`${category.slug}`}
                            className='topic'
                          >
                            {' '}
                            {category.name}
                          </Link>
                        ))}
                      </ListGroup.Item>
                    )}
                    <ListGroup.Item key='description'>
                      <>
                        {product.description && (
                          <>
                            <h5>Description:</h5>
                            <div className='product-description'>
                              {isReadMore
                                ? ReactHtmlParser(
                                    product.description.slice(0, 250)
                                  )
                                : ReactHtmlParser(product.description)}
                              <div
                                onClick={toggleReadMore}
                                className='read-or-hide'
                              >
                                {isReadMore ? '...read more' : ' show less'}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col md={6}>
                  {!product.isBox && (
                    <>
                      <br />
                      <h5>GoodReads Reviews</h5>
                      <div id='goodreads-widget'>
                        <iframe
                          frameBorder='0'
                          height='400'
                          id='the_iframe'
                          src='https://www.goodreads.com/api/reviews_widget_iframe?did=DEVELOPER_ID&amp;format=html&amp;isbn=9780552566032&amp;links=660&amp;min_rating=&amp;review_back=fff&amp;stars=000&amp;text=000'
                          width='100%'
                        ></iframe>
                      </div>
                    </>
                  )}
                </Col>
                <Col md={6}>
                  <h5>Reviews</h5>
                  {product.reviews.length == 0 && <Message>No Reviews</Message>}
                  <ListGroup varaint='flush'>
                    {product.reviews.map((review) => (
                      <ListGroup.Item key={review._id && 0}>
                        <strong>{review.name}</strong>
                        <Rating value={review.rating}></Rating>
                        <p>{review.createdAt.substring(0, 10)}</p>
                        <p>{review.comment}</p>
                        <p>
                          {review.image && (
                            <img
                              src={review.image.replace(/\\/g, '/')}
                              width='300px'
                            />
                          )}
                        </p>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  <ListGroup.Item key='customer-review'>
                    <h6>Write a Review</h6>
                    {errorReview && (
                      <Message variant='danger'>{errorReview}</Message>
                    )}
                    {userInfo ? (
                      <Form onSubmit={submitHandler}>
                        <Form.Group controlId='rating'>
                          <Form.Label>Rating</Form.Label>
                          <Form.Control
                            as='select'
                            value={rating}
                            onChange={(e) => setRating(e.target.value)}
                          >
                            <option value=''>Select...</option>
                            <option value='1'>1 - Poor</option>
                            <option value='2'>2 - Fair</option>
                            <option value='3'>3 - Good</option>
                            <option value='4'>4 - Very Good</option>
                            <option value='5'>5 - Excellent</option>
                          </Form.Control>
                          <Form.Group controlId='image'>
                            <Form.Label>Image</Form.Label>
                            <Form.File
                              id='image-file'
                              label='Choose File'
                              custom
                              onChange={uploadHandler}
                            ></Form.File>
                            {uploading && <Loader></Loader>}
                          </Form.Group>
                          <FormGroup controlId='comment'>
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                              as='textarea'
                              value={comment}
                              row='3'
                              onChange={(e) => setComment(e.target.value)}
                            ></Form.Control>
                          </FormGroup>
                          <Button type='submit' variant='primary'>
                            Comment
                          </Button>
                        </Form.Group>
                      </Form>
                    ) : (
                      <Message>
                        Please <Link to='/login'>Sign In</Link>
                      </Message>
                    )}
                  </ListGroup.Item>
                  <br />
                </Col>
              </Row>
              {/* <Row>
              <Col xs={12}>
                {authorLoading ? (
                  <Loader></Loader>
                ) : authorError ? (
                  <Message>
                    <h2>error</h2>
                  </Message>
                ) : (
                  <>
                    <Row className='homepage-mobile-bar'>
                      <Col xs={7}>
                        <div className='homepage-mobile-bar-col'>
                          Books from Author {product.author && product.author}
                        </div>
                      </Col>
                      <Col xs={5}>
                        <Link
                          to={`/author/${product.author}`}
                          className='float-right mt-2 homepage-mobile-bar-col'
                        >
                          See All
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      {authorProducts.map((product) => (
                        <Col
                          key={product._id}
                          sm={4}
                          md={4}
                          lg={2}
                          xs={4}
                          className='product-grid-size'
                        >
                          <Route
                            render={({ history }) => (
                              <Product history={history} product={product} />
                            )}
                          />
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
              </Col>
            </Row> */}
              <Row>
                <Col>
                  <Row className='homepage-mobile-bar'>
                    <Col xs={7}>
                      <div className='homepage-mobile-bar-col'>
                        Recently Viewed
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {recentProducts.length > 1 &&
                      recentProducts.map((product) => (
                        <Col
                          key={product._id}
                          sm={4}
                          md={4}
                          lg={2}
                          xs={4}
                          className='product-grid-size'
                        >
                          <Route
                            render={({ history }) => (
                              <Product history={history} product={product} />
                            )}
                          />
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </>
        )
      )}
    </>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default RedirectProductScreen
