import axios from "axios"
import { FLOW_CREATE_FAIL, FLOW_CREATE_REQUEST, FLOW_CREATE_SUCCESS, FLOW_DELETE_FAIL, FLOW_DELETE_REQUEST, FLOW_DELETE_SUCCESS, FLOW_DETAIL_REQUEST, FLOW_DETAIL_SUCCESS, FLOW_LIST_FAIL, FLOW_LIST_REQUEST, FLOW_LIST_SUCCESS } from "../constants/flowConstants"



export const listFlows =()=>async(dispatch,getState)=>{
    try{
        dispatch({
            type:FLOW_LIST_REQUEST
        })  

        const {userLogin:{userInfo}} = getState()
        const config ={
            headers:{
                'Content-Type':'application/json',
                Authorization:`Bearer ${userInfo.token}`
            }
        }
        const {data} = await axios.get('/api/flow',config)

        dispatch({
            type:FLOW_LIST_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:FLOW_LIST_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const flowDetails =(id)=>async(dispatch,getState)=>{
    try{
        dispatch({
            type:FLOW_DETAIL_REQUEST
        })  

        const {userLogin:{userInfo}} = getState()
        const config ={
            headers:{
                'Content-Type':'application/json',
                Authorization:`Bearer ${userInfo.token}`
            }
        }
       
        const {data} = await axios.get(`/api/flow/${id}`,config)
        dispatch({
            type:FLOW_DETAIL_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:FLOW_LIST_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}
export const createFlow =(flow)=>async(dispatch,getState)=>{
    try{
        dispatch({
            type:FLOW_CREATE_REQUEST
        })  

        const {userLogin:{userInfo}} = getState()
        const config ={
            headers:{
                'Content-Type':'application/json',
                Authorization:`Bearer ${userInfo.token}`
            }
        }
       
        const {data} = await axios.post(`/api/flow`,{flow},config)
        dispatch({
            type:FLOW_CREATE_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:FLOW_CREATE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}
export const deleteFLow =(id)=>async(dispatch,getState)=>{
    try{
        dispatch({
            type:FLOW_DELETE_REQUEST
        })  

        const {userLogin:{userInfo}} = getState()
        const config ={
            headers:{
                Authorization:`Bearer ${userInfo.token}`
            }
        }
        await axios.delete(`/api/flow/${id}`,config)
        
        dispatch({
            type:FLOW_DELETE_SUCCESS
        })

    }catch(error){
        dispatch({
            type:FLOW_DELETE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}
export const createFlowTrigger =(flow, template,subjectLine,delayType,delayTime)=>async(dispatch,getState)=>{
    try{
        dispatch({
            type:FLOW_CREATE_REQUEST
        })  

        const {userLogin:{userInfo}} = getState()
        const config ={
            headers:{
                'Content-Type':'application/json',
                Authorization:`Bearer ${userInfo.token}`
            }
        }
       
        const {data} = await axios.post(`/api/flow/createtrigger`,{flow, template,subjectLine,delayType,delayTime},config)
        dispatch({
            type:FLOW_CREATE_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:FLOW_CREATE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}
export const deleteFlowTrigger =(id)=>async(dispatch,getState)=>{
    try{
        dispatch({
            type:FLOW_DELETE_REQUEST
        })  

        const {userLogin:{userInfo}} = getState()
        const config ={
            headers:{
                Authorization:`Bearer ${userInfo.token}`
            }
        }
        await axios.delete(`/api/flow/createtrigger/${id}`,config)
        
        dispatch({
            type:FLOW_DELETE_SUCCESS
        })

    }catch(error){
        dispatch({
            type:FLOW_DELETE_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}