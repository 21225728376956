import { USER_COIN_FAIL, USER_COIN_REQUEST, USER_COIN_SUCESS } from "../constants/coinConstants"


export const userCoinReducer = (state = {loading:true}, action) => {
    switch (action.type) {
        case USER_COIN_REQUEST:
            return {
                loading: true
            }
        case USER_COIN_SUCESS:
            return {
                loading: false,
                coins: action.payload
            }
        case USER_COIN_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

