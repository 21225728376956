import {
  GENRE_LIST_FAIL,
  GENRE_LIST_REQUST,
  GENRE_LIST_SUCCESS,
  PLOT_GENRE_LIST_FAIL,
  PLOT_GENRE_LIST_REQUST,
  PLOT_GENRE_LIST_SUCCESS,
} from '../constants/genreConstants'

export const getGenreListReducer = (
  state = { loading: true, genres: [] },
  action
) => {
  switch (action.type) {
    case GENRE_LIST_REQUST:
      return {
        loading: true,
      }
    case GENRE_LIST_SUCCESS:
      return {
        loading: false,
        genres: action.payload,
      }
    case GENRE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getPlotGenreListReducer = (
  state = { loading: true, genres: [] },
  action
) => {
  switch (action.type) {
    case PLOT_GENRE_LIST_REQUST:
      return {
        loading: true,
      }
    case PLOT_GENRE_LIST_SUCCESS:
      return {
        loading: false,
        genres: action.payload,
      }
    case PLOT_GENRE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
