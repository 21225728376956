import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, listStoryByCat } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'

import ReactHtmlParser from 'react-html-parser'
import { register } from '../../../actions/userActions'
import SocialLogin from '../../../components/SocialLogin'
import Loader from '../../../components/Loader'
import Story from '../../../components/Story'
import SubNav from '../../../components/SubNav'
import PlotListPaginate from '../../../components/plots/PlotListPaginate'
import { makeTitle } from '../../../helpers/helpers'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
const PlotListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const category = match.params.category || ''
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const storybyCat = useSelector((state) => state.storybyCat)
  const { stories, loading, error, suceess, pages, page:p } = storybyCat
  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister
  const handleChange = (event, value) => {
    history.push(`/plots/en/category/${category}/page/${value}`)
  }
  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({ name, email, password, isSocial: false, shorts: true })
      )
    }
  }
  const showMore = () => {
    setShow(true)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(listStoryByCat(category, pageNumber))
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        if (userInfo.isSubscribed) {
          history.push(`/app/en/category/${category}`)
        } else {
          history.push(`/app/en/category/${category}`)
        }
      }
    }
  }, [dispatch, pageNumber, category, userInfo, registerSuccess, emailError])
  return (
    <>
      <Container>
        <Helmet>
          <title>
            Read key ideas from {category.replace(/-/g, ' ')} Books! Read Smart
            & Read Fast
          </title>
          <meta charset='UTF-8' />
          <meta
            name='title'
            content={
              'Read key ideas from' +
              match.params.category.replace(/-/g, ' ') +
              ' Books! Read Smart & Read Fast'
            }
          ></meta>
          <meta
            name='description'
            content={
              'Read key ideas from' +
              match.params.category.replace(/-/g, ' ') +
              ' Books! Read Smart & Read Fast'
            }
          ></meta>
          <meta
            name='keyword'
            content={
              'Read key ideas from' +
              match.params.category.replace(/-/g, ' ') +
              ' Books! Read Smart & Read Fast'
            }
          ></meta>
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0'
          ></meta>
          <meta name='robots' content='index, follow' />
        </Helmet>
        <SubNav history={history}></SubNav>
        {loading && !suceess ? (
          <Loader></Loader>
        ) : (
          <Container>
            <h1>{makeTitle(category)} Books</h1>
            <Row>
              {stories &&
                stories.map((story) => (
                  <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                    <Route
                      render={({ history }) => (
                        <Story history={history} story={story} />
                      )}
                    />
                  </Col>
                ))}
              {pages > 1 && (
                <Stack spacing={1}>
                  <Pagination
                    count={pages}
                    page={p}
                    onChange={handleChange}
                    style={{
                      justifyContent: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  />
                </Stack>
              )}
            </Row>
            <Modal show={show} onHide={handleClose} backdrop='static'>
              <Modal.Body>
                <Form onSubmit={submitHandler2}>
                  <div>
                    <Button className='close-button' onClick={handleClose}>
                      X
                    </Button>
                  </div>
                  <div style={{ padding: '20px' }}>
                    <Form.Group>
                      <div
                        style={{ marginBottom: '20px', textAlign: 'center' }}
                      >
                        <LazyLoadImage
                          src={'../../images/logo.png'}
                          width={'100%'}
                          alt={'99bookscart logo'}
                          className='siteLogo'
                        />

                        <br />
                        <strong>Read Smart & Read Fast</strong>
                        <div>Read Key Ideas from 1000+ Non Fiction Books!</div>
                        <br />
                        <div className='social-logins'>
                          <SocialLogin />
                        </div>
                      </div>
                      <Form.Text className='red-text'>
                        {errorMessage && <span>{errorMessage}</span>}
                      </Form.Text>
                      <Form.Control
                        type='text'
                        placeholder='Name'
                        required
                        value={name || ''}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>

                      <Form.Text className='red-text'>
                        {emailError && <span>{emailError}</span>}
                      </Form.Text>

                      <Form.Control
                        type='email'
                        placeholder='Email'
                        required
                        value={email || ''}
                        onChange={(e) => setEmail(e.target.value)}
                      ></Form.Control>
                      <Form.Text className='red-text'>
                        {passwordError && <span>{passwordError}</span>}
                      </Form.Text>
                      <Form.Control
                        type='password'
                        placeholder='Passsword'
                        required
                        value={password || ''}
                        onChange={(e) => setPassword(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Row>
                      <Col>
                        <Button
                          type='submit'
                          variant='primary'
                          className='float-right'
                        >
                          Sign Up
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='text-center'>
                        Existing Customer? <Link to={'/login'}>Login</Link>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </Container>
        )}
      </Container>
    </>
  )
}
{
}

export default PlotListScreen
