import React, { useState } from 'react'
import { Card, Button, Modal, Image, Row, Col } from 'react-bootstrap'
import Rating from './Rating'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BoxProgressBar from './BoxProgressBar'
import { FaBoxOpen } from 'react-icons/fa'
import { getCategory, storyStatus } from '../helpers/helpers'
import { FaBookReader, FaStar, FaList, FaClock } from 'react-icons/fa'
import { convertToSlug } from '../helpers/helpers'
import {
  FaRocket,
  FaFlask,
  FaGlobeAmericas,
  FaBuilding,
  FaLightbulb,
  FaLifeRing,
  FaBrain,
  FaTree,
  FaBook,
  FaCreativeCommonsSampling,
  FaHotel,
  FaBriefcaseMedical,
  FaHistory,
  FaDraftingCompass,
  FaPersonBooth,
  FaMoneyCheckAlt,
  FaMoneyBillAlt,
  FaConnectdevelop,
  FaCheckDouble,
  FaMonument,
  FaBookOpen,
  FaAward,
  FaWhmcs,
  FaArrowCircleRight,
  FaMicrophone,
  FaPodcast,
  FaNetworkWired,
  FaRoad,
  FaPrayingHands,
  FaLeaf,
  FaPaperPlane,
  FaLungsVirus,
} from 'react-icons/fa'
const CategoriesApp = ({ story, history, match, addtocart = true }) => {
  const dispatch = useDispatch()
  // const [show, setShow] = useState(false);
  const [disable, setDisable] = React.useState(false)

  return (
    <>
      <Row style={{ padding: '6px' }}>
        <Col>
          <div className='cate-name-list'>
            <h1>Categories</h1>
            <br />
            <Link
              to={`/app/en/category/entrepreneurship`}
              className='topic-cat'
            >
              <FaRocket></FaRocket> Entrepreneurship
            </Link>

            <Link to={`/app/en/category/science`}>
              <span className='topic-cat'>
                <FaFlask></FaFlask> Science
              </span>
            </Link>
            <Link to={`/app/en/category/economics`}>
              <span className='topic-cat'>
                <FaGlobeAmericas></FaGlobeAmericas> Economics
              </span>
            </Link>
            <Link to={`/app/en/category/corporate-culture`}>
              <span className='topic-cat'>
                <FaBuilding></FaBuilding> Corporate Culture
              </span>
            </Link>
            <Link to={`/app/en/category/motivation-&-inspiration`}>
              <span className='topic-cat'>
                <FaLightbulb></FaLightbulb> Motivation & Inspiration
              </span>
            </Link>
            <Link to={`/app/en/category/productivity`}>
              <span className='topic-cat'>
                <FaLifeRing></FaLifeRing> Productivity
              </span>
            </Link>
            <Link to={`/app/en/category/mindfulness-&-happiness`}>
              <span className='topic-cat'>
                <FaBrain></FaBrain> Mindfulness & Happiness
              </span>
            </Link>
            <Link to={`/app/en/category/nature-&-environment`}>
              <span className='topic-cat'>
                <FaTree></FaTree> Nature & Environment
              </span>
            </Link>
            <Link to={`/app/en/category/career`}>
              <span className='topic-cat'>
                <FaBook></FaBook> Career
              </span>
            </Link>
            <Link to={`/app/en/category/creativity`}>
              <span className='topic-cat'>
                <FaCreativeCommonsSampling></FaCreativeCommonsSampling>{' '}
                Creativity
              </span>
            </Link>
            <Link to={`/app/en/category/politics`}>
              <span className='topic-cat'>
                <FaHotel></FaHotel> Politics
              </span>
            </Link>
            <Link to={`/app/en/category/health-&-nutrition`}>
              <span className='topic-cat'>
                <FaBriefcaseMedical></FaBriefcaseMedical> Health & Nutrition
              </span>
            </Link>
            <Link to={`/app/en/category/history`}>
              <span className='topic-cat'>
                <FaHistory></FaHistory> History
              </span>
            </Link>
            <Link to={`/app/en/category/management`}>
              <span className='topic-cat'>
                <FaDraftingCompass></FaDraftingCompass> Management
              </span>
            </Link>
            <Link to={`/app/en/category/leadership`}>
              <span className='topic-cat'>
                <FaPersonBooth></FaPersonBooth> Leadership
              </span>
            </Link>
            <Link to={`/app/en/category/money`}>
              <span className='topic-cat'>
                <FaMoneyCheckAlt></FaMoneyCheckAlt> Money
              </span>
            </Link>
            <Link to={`/app/en/category/investment`}>
              <span className='topic-cat'>
                <FaMoneyBillAlt></FaMoneyBillAlt> Investment
              </span>
            </Link>
            <Link to={`/app/en/category/sex-&-relationships`}>
              <span className='topic-cat'>
                <FaConnectdevelop></FaConnectdevelop> Sex & Relationships
              </span>
            </Link>
            <Link to={`/app/en/category/parenting`}>
              <span className='topic-cat'>
                <FaCheckDouble></FaCheckDouble> Parenting
              </span>
            </Link>
            {/* <Link to={`/app/en/category/bigraphy-&-memoir`}>
              <span className='topic-cat'>
                <FaMonument></FaMonument> Biography & Memoir
              </span>
            </Link> */}
            <Link to={`/app/en/category/education`}>
              <span className='topic-cat'>
                <FaBookOpen></FaBookOpen> Education
              </span>
            </Link>
            <Link to={`/app/en/category/philosophy`}>
              <span className='topic-cat'>
                <FaAward></FaAward> Philosophy
              </span>
            </Link>
            <Link to={`/app/en/category/marketing-&-sales`}>
              <span className='topic-cat'>
                <FaWhmcs></FaWhmcs> Marketing & Sales
              </span>
            </Link>
            <Link to={`/app/en/category/personal-development`}>
              <span className='topic-cat'>
                <FaArrowCircleRight></FaArrowCircleRight> Personal Development
              </span>
            </Link>
            <Link to={`/app/en/category/communication-skills`}>
              <span className='topic-cat'>
                <FaMicrophone></FaMicrophone> Communication Skills
              </span>
            </Link>
            <Link to={`/app/en/category/psychology`}>
              <span className='topic-cat'>
                <FaPodcast></FaPodcast> Psychology
              </span>
            </Link>
            <Link to={`/app/en/category/technology`}>
              <span className='topic-cat'>
                <FaNetworkWired></FaNetworkWired> Technology
              </span>
            </Link>
            <Link to={`/app/en/category/society-&-culture`}>
              <span className='topic-cat'>
                <FaRoad></FaRoad> Society & Culture
              </span>
            </Link>
            <Link to={`/app/en/category/religion-&-spirituality`}>
              <span className='topic-cat'>
                <FaPrayingHands></FaPrayingHands> Religion & Spirituality
              </span>
            </Link>
            {/* <Link to={`/app/en/category/cooking-reciepes`}>
              <span className='topic-cat'>
                <FaLeaf></FaLeaf> Cooking Reciepes
              </span>
            </Link> */}
            <Link to={`/app/en/category/motivation`}>
              <span className='topic-cat'>
                <FaPaperPlane></FaPaperPlane> Motivation
              </span>
            </Link>
            <Link to={`/app/en/category/life-lessons`}>
              <span className='topic-cat'>
                <FaLungsVirus></FaLungsVirus> Life Lessons
              </span>
            </Link>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default CategoriesApp
