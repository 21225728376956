import axios from 'axios'
import {
  USER_READINGS_ADD_REQUEST,
  USER_READINGS_ADD_SUCESS,
  USER_READINGS_ADD_FAIL,
  USER_READING_LIST_REQUEST,
  USER_READING_LIST_SUCESS,
  USER_READING_LIST_FAIL,
  READING_COUNT_SUCCEES,
  READING_COUNT_FAIL,
  READING_COUNT_REQUEST,
  USER_READING_STATUS_REQUEST,
  USER_READING_STATUS_SUCESS,
  USER_READING_STATUS_FAIL,
} from '../constants/readingConstants'

export const addToUserReadings =
  (id, pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_READINGS_ADD_REQUEST,
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(
        `/api/reading/add/${id}/${pageNumber}`,
        config
      )

      dispatch({
        type: USER_READINGS_ADD_SUCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: USER_READINGS_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const addToUserReading = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_READINGS_ADD_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/reading/add/new/${id}`, config)

    dispatch({
      type: USER_READINGS_ADD_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_READINGS_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserReadings = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    dispatch({ type: USER_READING_LIST_REQUEST })
    const { data } = await axios.get(`/api/reading/get`, config)
    dispatch({
      type: USER_READING_LIST_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_READING_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getReadingsCount = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: READING_COUNT_REQUEST })
    const { data } = await axios.get(`/api/reading/count/${id}`)
    dispatch({
      type: READING_COUNT_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: READING_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getReadingStatus = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    dispatch({ type: USER_READING_STATUS_REQUEST })
    const { data } = await axios.get(`/api/reading/status/${id}`, config)
    dispatch({
      type: USER_READING_STATUS_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_READING_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
