import React, { useState, useEffect } from 'react'
import { Link, Route } from 'react-router-dom'

import { Form, Button, Row, Col, Image, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { login } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import { submitFrom } from '../actions/formActions'
import { FORM_ADD_RESET } from '../constants/formConstants'
import SearchBox from '../components/SearchBox'
import FooterMenu from '../components/FooterMenu'
import AmazonAd from '../components/amazon/AmazonAd'

const SearchScreen = ({ location, history }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [dispatch])

  return (
    <Container>
      <br />
      <Row>
        <Col>
          <Container>
            <Route render={({ history }) => <SearchBox history={history} />} />
          </Container>
        </Col>
      </Row>
      <AmazonAd />
      <FooterMenu></FooterMenu>
    </Container>
  )
}

export default SearchScreen
