import axios from 'axios'
import {
  FORM_ADD_FAIL,
  FORM_ADD_REQUEST,
  FORM_ADD_SUCCEES,
  FORM_LIST_FAIL,
  FORM_LIST_REQUEST,
  FORM_LIST_SUCCEES,
} from '../constants/formConstants'

export const submitFrom = (form) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FORM_ADD_REQUEST,
    })

    const { data } = await axios.post(`/api/form/add`, form)

    dispatch({
      type: FORM_ADD_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: FORM_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listContactFormSubmission = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FORM_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/form`, config)

    dispatch({
      type: FORM_LIST_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: FORM_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
