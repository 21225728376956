import { GET_SHIPPMENT_FAIL } from '../constants/shipmentConstants'
import {
  CREATE_NEW_STORY_FAIL,
  CREATE_NEW_STORY_REQUEST,
  CREATE_NEW_STORY_SUCCEES,
  DELETE_STORY_FAIL,
  DELETE_STORY_REQUEST,
  DELETE_STORY_SUCCEES,
  GET_STORY_FAIL,
  GET_STORY_REQUEST,
  GET_STORY_SUCCEES,
  LIST_STORY_BY_CAT_FAIL,
  LIST_STORY_BY_CAT_REQUEST,
  LIST_STORY_BY_CAT_SUCCEES,
  LIST_STORY_BY_KEY_FAIL,
  LIST_STORY_BY_KEY_REQUEST,
  LIST_STORY_BY_KEY_SUCCEES,
  LIST_STORY_FAIL,
  LIST_STORY_REQUEST,
  LIST_STORY_SUCCEES,
  MY_LIST_STORY_FAIL,
  MY_LIST_STORY_REQUEST,
  MY_LIST_STORY_SUCCEES,
  MY_READING_STORY_FAIL,
  MY_READING_STORY_REQUEST,
  MY_READING_STORY_SUCCEES,
} from '../constants/storyConstants'

export const createStoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_STORY_REQUEST:
      return {
        loading: true,
      }
    case CREATE_NEW_STORY_SUCCEES:
      return {
        loading: false,
        suceess: true,
        story: action.payload,
      }
    case CREATE_NEW_STORY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const listStoryReducer = (
  state = { stories: [], success: false, page: 0, pages: 0 },
  action
) => {
  switch (action.type) {
    case LIST_STORY_REQUEST:
      return {
        loading: true,
      }
    case LIST_STORY_SUCCEES:
      return {
        loading: false,
        suceess: true,
        stories: action.payload.stories,
        page: action.payload.page,
        pages: action.payload.pages,
      }
    case LIST_STORY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getStoryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STORY_REQUEST:
      return {
        loading: true,
      }
    case GET_STORY_SUCCEES:
      return {
        loading: false,
        success: true,
        story: {
          ...action.payload.story,
          ...action.payload.storyStats,
          ...action.payload.similarStories,
        },
        storyStats: action.payload.storyStats,
        similarStories: action.payload.similarStories,
      }
    case GET_STORY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const deleteStoryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STORY_REQUEST:
      return {
        loading: true,
      }
    case DELETE_STORY_SUCCEES:
      return {
        loading: false,
        success: true,
        story: action.payload,
      }
    case DELETE_STORY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const listMyStoryReducer = (
  state = { stories: [], page: 0, pages: 0 },
  action
) => {
  switch (action.type) {
    case MY_LIST_STORY_REQUEST:
      return {
        loading: true,
      }
    case MY_LIST_STORY_SUCCEES:
      return {
        loading: false,
        success: true,
        stories: action.payload.stories,
        page: action.payload.page,
        pages: action.payload.pages,
      }
    case MY_LIST_STORY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const myReadingStoryReducer = (state = { stories: [] }, action) => {
  switch (action.type) {
    case MY_READING_STORY_REQUEST:
      return {
        loading: true,
      }
    case MY_READING_STORY_SUCCEES:
      return {
        loading: false,
        success: true,
        stories: action.payload,
      }
    case MY_READING_STORY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const storybyCatReducer = (state = { stories: [] }, action) => {
  switch (action.type) {
    case LIST_STORY_BY_CAT_REQUEST:
      return {
        loading: true,
      }
    case LIST_STORY_BY_CAT_SUCCEES:
      return {
        loading: false,
        success: true,
        stories: action.payload.stories,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case LIST_STORY_BY_CAT_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const storybyKeyReducer = (state = { stories: [] }, action) => {
  switch (action.type) {
    case LIST_STORY_BY_KEY_REQUEST:
      return {
        loading: true,
      }
    case LIST_STORY_BY_KEY_SUCCEES:
      return {
        loading: false,
        success: true,
        stories: action.payload.stories,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case LIST_STORY_BY_KEY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
