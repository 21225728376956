import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Row, Col, Pagination } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Form, Button, Select } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { login } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import {} from '../actions/userActions'
import AWS from 'aws-sdk'
import fs from 'fs'
import {
  deleteProduct,
  listProductsDetails,
  updateProduct,
  createProduct,
} from '../actions/productActions'
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_UPDATE_RESET,
} from '../constants/productConstants'

const ProductNewScreen = ({ match, history }) => {
  const productId = match.params.id
  console.log(productId)
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [image, setImage] = useState('')
  const [description, setDescription] = useState('')
  const [tags, setTags] = useState('')
  const [type, setType] = useState('')
  const [category, setCategory] = useState('')
  const [isbn, setIsbn] = useState('')
  const [salePrice, setSaleprice] = useState('')
  const [countInStock, setCountInStock] = useState('')
  const [pages, setPages] = useState('')
  const [edition, setEdition] = useState('')
  const [author, setAuthor] = useState('')
  const [publisher, setPublisher] = useState('')
  const [grating, setGrating] = useState('')
  const [gnumReviews, setGnumReviews] = useState('')
  const [location, setLocation] = useState('')
  const [uploading, setUploading] = useState(false)
  const [uploadingEbook, setUploadingEbook] = useState(false)
  const [isEbook, setIsEbook] = useState(false)
  const [ebook, setEbook] = useState('')
  const [paymentType, setPaymentType] = useState('')

  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const productCreate = useSelector((state) => state.productCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = productCreate

  useEffect(() => {
    if (successCreate) {
      dispatch({ type: PRODUCT_CREATE_RESET })
      history.push('/admin/productlist')
    } else {
      // setName(product.name)
      // setPrice(product.price)
      // setImage(product.image)
      // setDescription(product.description)
      // setTags(product.tags)
      // setType(product.type)
      // setCategory(product.category)
      // setIsbn(product.isbn)
      // setSaleprice(product.salePrice)
      // setCountInStock(product.countInStock)
      // setPages(product.pages)
      // setEdition(product.edition)
      // setAuthor(product.author)
      // setPublisher(product.publisher)
      // setGrating(product.grating)
      // setGnumReviews(product.gnumReviews)
      // setLocation(product.location)
    }
  }, [dispatch, history, productId, product, successCreate, isEbook])

  const uploadEbookHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploadingEbook(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload/epub', formData, config)
      setEbook(data)
      setUploadingEbook(false)
    } catch (error) {
      console.error(error)
      setUploadingEbook(false)
    }
  }
  const uploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProduct({
        _id: productId,
        name,
        description,
        image,
        price,
        tags,
        type,
        category,
        isbn,
        salePrice,
        countInStock: type == 'Ebook' ? -99 : countInStock,
        pages,
        edition,
        author,
        publisher,
        grating,
        gnumReviews,
        location,
        isEbook: type == 'Ebook' ? true : false,
        ebook,
        paymentType,
      })
    )
  }
  return (
    <>
      <Link to='/admin/productlist' className='btn btn-light my-3'>
        Go back
      </Link>
      <FormContainer>
        <h1>Add Book</h1>
        {loadingCreate && <Loader></Loader>}
        {errorCreate && <Message>{errorCreate}</Message>}
        {loading ? (
          <Loader></Loader>
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Row>
              <Col xs={6}>
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='description'>
                  <Form.Label>Desription</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='image'>
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type='hidden'
                    placeholder='Enter Image'
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  ></Form.Control>
                  <Form.File
                    id='image-file'
                    custom
                    onChange={uploadHandler}
                  ></Form.File>
                  {uploading && <Loader></Loader>}
                </Form.Group>

                <Form.Group controlId='tags'>
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Tags'
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='isEbook'>
                  <Form.Label>Type of Book</Form.Label>
                  <Form.Control
                    as='select'
                    aria-label='Select Book Type'
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option>Select Type of Book</option>
                    <option value='Excellent'>Excellent</option>
                    <option value='Good'>Good</option>
                    <option value='Readable'>Readable</option>
                    <option value='New'>New</option>
                    <option value='Ebook'>Ebook</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId='category'>
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Category'
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='isbn'>
                  <Form.Label>ISBN</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter isbn'
                    value={isbn}
                    onChange={(e) => setIsbn(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                {/* <Form.Group controlId='isEbook'>
                  <Form.Label>IsEbook</Form.Label>
                  <Form.Control
                    as='select'
                    aria-label='Select Book Type'
                    onChange={(e) => setIsEbook(e.target.value)}
                  >
                    <option>Open this select menu</option>
                    <option value='true'>Yes</option>
                    <option value='false'>No</option>
                  </Form.Control>
                </Form.Group> */}

                <Form.Group controlId='ebook'>
                  <Form.Label>Upload Epub File</Form.Label>
                  <Form.Control
                    type='hidden'
                    placeholder='EbookLink'
                    value={ebook}
                    onChange={(e) => setEbook(e.target.value)}
                  ></Form.Control>
                  <Form.File
                    id='image-file'
                    custom
                    onChange={uploadEbookHandler}
                  ></Form.File>
                  {uploadingEbook && <Loader></Loader>}
                </Form.Group>
              </Col>
              <Col cs={6}>
                <Form.Group controlId='paymentDate'>
                  <Form.Label>Payment Type</Form.Label>
                  <Form.Control
                    as='select'
                    aria-label='Select Payment Type'
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    <option>Payment Type</option>
                    <option value='0'>Free</option>
                    <option value='1'>Subcription</option>
                    <option value='2'>Purchase</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId='price'>
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='Enter price'
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='saleprice'>
                  <Form.Label>Saleprice</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Saleprice'
                    value={salePrice}
                    onChange={(e) => setSaleprice(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='pages'>
                  <Form.Label>Pages</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter pages'
                    value={pages}
                    onChange={(e) => setPages(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='edition'>
                  <Form.Label>Edition</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter edition'
                    value={edition}
                    onChange={(e) => setEdition(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='author'>
                  <Form.Label>Author</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter author'
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='publisher'>
                  <Form.Label>Publisher</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter publisher'
                    value={publisher}
                    onChange={(e) => setPublisher(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                {/* <Form.Group controlId='grating'>
                  <Form.Label>Grating</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter grating'
                    value={grating}
                    onChange={(e) => setGrating(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='gnumReviews'>
                  <Form.Label>GnumReviews</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter gnumReviews'
                    value={gnumReviews}
                    onChange={(e) => setGnumReviews(e.target.value)}
                  ></Form.Control>
                </Form.Group> */}
                {type !== 'Ebook' && (
                  <>
                    <Form.Group controlId='location'>
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter location'
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='countInStock'>
                      <Form.Label>Stock</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Stock'
                        value={countInStock}
                        onChange={(e) => setCountInStock(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </>
                )}

                <Button type='submit' varaint='primary' className='float-right'>
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default ProductNewScreen
