import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import { register } from '../../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'
import SocialLogin from '../../../components/SocialLogin'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/plots/meta/HomeMeta'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getPlotGenres } from '../../../actions/genreActions'
import { convertToSlug, convertToSlug2 } from '../../../helpers/helpers'

const PlotsHomeScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [disable, setDisable] = React.useState(false)
  const storybyCat = useSelector((state) => state.storybyCat)
  const {
    stories,
    loading: storyLoading,
    error: storyError,
    suceess: storySuccess,
    pages,
    page,
  } = storybyCat

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  const getGenreList = useSelector((state) => state.getPlotGenreList)
  const {
    loading: genreLoading,
    error: genreError,
    genres,
    success: genreSuccess,
  } = getGenreList

  const handleChange = (event, value) => {
    history.push(`/plots/en/category/entrepreneurship/page/${value}`)
  }
  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({ name, email, password, isSocial: false, shorts: true })
      )
    }
  }
  const startSub = () => {
    if (userLoggedIn) {
      if (!userLoggedIn.isSubscribed) {
        history.push('/app/profile/billing')
      } else {
        history.push('/app/home')
      }
    } else {
      setShow(true)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getPlotGenres(0))
    dispatch(listStoryByCat('self-help', pageNumber))
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        if (!userInfo.isSubscribed) {
          history.push('/app/home')
        } else {
          history.push('/app/home')
        }
      }
    }
  }, [
    dispatch,
    userInfo,
    registerSuccess,
    emailError,
    pageNumber,
    genreSuccess,
  ])
  return (
    <>
      <Container>
        <HomeMeta></HomeMeta>
        <Container>
          <Row className='story-hero-text'>
            <Col xs={12} sm={12}>
              <div className='main-heading-text' style={{ minHeight: '0px' }}>
                <h2>
                  <span style={{ fontSize: '27px' }}>
                    <>Read Smart & Read Fast</>
                  </span>
                </h2>
                <div style={{ color: '#000' }}>Read Key Ideas from Book </div>
                <div
                  style={{
                    color: '#005d6e',
                    fontSize: '14px',
                    marginBottom: '6px',
                  }}
                >
                  {/* Free Reading */}
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col>
              <h2>Rabindranath Tagor</h2>
            </Col>
            <Col>
              <h2>Andrew Carnegie</h2>
            </Col>
            <Col>
              <h2>Swami Vivekananda</h2>
            </Col>
            <Col>
              <h2>Patanjali</h2>
            </Col>
          </Row> */}
          {/* <Row className='story-bg-points'>
            <div>
              <h2>Why 99bookscart plots provide best nuggets of knowledge?</h2>
            </div>
            <Col xs={12} sm={4}>
              <h3 className='pb-1'>Clear Logic</h3>
              <p>
                Unlock the power of knowledge with ease and clarity, as we
                strive to simplify even the most complex of ideas!
              </p>
            </Col>
            <Col xs={12} sm={4}>
              <h3 className='pb-1'>Brilliant Insights</h3>
              <p>
                Experience the thrill of intellectual discovery as we delve
                deeper into the realm of literature!
              </p>
            </Col>
            <Col xs={12} sm={4}>
              <h3 className='pb-1'>Always Precise</h3>
              <p>
                Your time is precious, and we honor that by crafting every
                single sentence with intention and purpose.
              </p>
            </Col>
          </Row> */}
          {/* <Row>
            <Categories></Categories>
          </Row>
          <br /> */}
          <Row>
            <div className='text-center'>
              {/* <h4 className='hero-text-33'>
                A small investment in time, an incredible growth opportunity
              </h4>{' '} */}
              {/* Read key ideas from best non fiction books & more */}
              <br />
            </div>
          </Row>

          {/* <Row>
            <Col style={{ textAlign: 'center' }}>
              <div className='lp-text-app'>
                <h1>Why Subscribe?</h1>
                <br />
                <p>
                  Open the door to new worlds of wisdom, beauty, and inspiration
                  with our book Ideas subscription service. Discover the
                  transformative power of books in just 15-20 minutes or less,
                  with our hand-picked selection of 10 key ideas from over 1000+
                  non-fiction books.
                </p>

                <p>
                  Immerse yourself in the voices of the great thinkers, poets,
                  and visionaries of our time. Experience the rush of excitement
                  and inspiration that comes from discovering a new idea, a new
                  perspective, a new way of seeing the world.
                </p>

                <p>
                  99bookscart is a gateway to a life of meaning, purpose, and
                  fulfillment. It's a bridge between the wisdom of the past and
                  the vision of the future. It's a testament to the power of
                  words to transform, uplift, and inspire.
                </p>

                <p>
                  So join us on this journey of discovery. Let us be your guide,
                  your friend, your ally. Let us show you the way to a brighter,
                  more beautiful tomorrow.
                </p>

                <p>Subscribe today and let the magic begin.</p>
              </div>
              <div>
                <Button onClick={startSub}>Explore Ideas</Button>
              </div>
            </Col>
          </Row> */}
          {genreLoading ? (
            <Loader></Loader>
          ) : genreError ? (
            <Message>
              <h2>error</h2>
            </Message>
          ) : (
            <Row>
              <Stack direction='row' spacing={1}>
                <Container className='text-center'>
                  {genres.map((genre) => (
                    <Link
                      to={`/plots/en/category/${convertToSlug(genre.genre)}`}
                    >
                      {' '}
                      <span className='topic-genre'>
                        {genre.genre} ({genre.count}){' '}
                      </span>
                    </Link>
                  ))}
                </Container>
              </Stack>
            </Row>
          )}
          <Row>
            {storyLoading ? (
              <Loader></Loader>
            ) : (
              <>
                {stories &&
                  stories.map((story) => (
                    <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                      <Route
                        render={({ history }) => (
                          <Story history={history} story={story} />
                        )}
                      />
                    </Col>
                  ))}
                <Stack spacing={1}>
                  <Pagination
                    count={pages}
                    page={page}
                    onChange={handleChange}
                    style={{
                      justifyContent: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  />
                </Stack>
              </>
            )}
          </Row>

          {/* <Row style={{ backgroundColor: '#F1F7F3' }}>
            <h3 className='pb-2 text-center'>
              <br />
              Reviews
            </h3>
            <Col xs={12} md={4}>
              <div className='lp-rating-sect'>
                <Rating value={4.9}></Rating>
                <span>Satish. C</span>
                <br />
                <p>Great Service thanx a billion@99bookscart</p>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className='lp-rating-sect'>
                <Rating value={5}></Rating>
                <span>Ryan</span>
                <br />
                <p>
                  I was a bit skeptical at first, but after trying out this book
                  summary subscription service, I am now a loyal customer.
                </p>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className='lp-rating-sect'>
                <Rating value={5}></Rating>
                <span>Neha</span>
                <br />
                <p>I absolutely love this book summary service!</p>
              </div>
            </Col>
          </Row> */}
          {/* <Row>
            <Col>
              <div className='hero-text2'>
                Read key ideas from 1000+ non fiction books
                <br />
                <Button onClick={startSub}>Explore Ideas</Button>
              </div>
            </Col>
          </Row> */}
        </Container>
        <Modal show={show} onHide={handleClose} backdrop='static'>
          <Modal.Body>
            <Form onSubmit={submitHandler2}>
              <div>
                <Button className='close-button' onClick={handleClose}>
                  X
                </Button>
              </div>
              <div style={{ padding: '20px' }}>
                <Form.Group>
                  <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <LazyLoadImage
                      src={'../../images/logo.png'}
                      width={'100%'}
                      alt={'99bookscart logo'}
                      className='siteLogo'
                    />
                    <br />
                    <strong>Read Smart & Read Fast</strong>
                    <div>Read Key Ideas from 1000+ Non Fiction Books!</div>
                    <br />
                    <div className='social-logins'>
                      <SocialLogin />
                    </div>
                  </div>
                  <Form.Text className='red-text'>
                    {errorMessage && <span>{errorMessage}</span>}
                  </Form.Text>
                  <Form.Control
                    type='text'
                    placeholder='Name'
                    required
                    value={name || ''}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>

                  <Form.Text className='red-text'>
                    {emailError && <span>{emailError}</span>}
                  </Form.Text>

                  <Form.Control
                    type='email'
                    placeholder='Email'
                    required
                    value={email || ''}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                  <Form.Text className='red-text'>
                    {passwordError && <span>{passwordError}</span>}
                  </Form.Text>
                  <Form.Control
                    type='password'
                    placeholder='Passsword'
                    required
                    value={password || ''}
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Row>
                  <Col>
                    <Button
                      type='submit'
                      variant='primary'
                      className='float-right'
                    >
                      Sign Up
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-center'>
                    Existing Customer? <Link to={'/login'}>Login</Link>
                  </Col>
                </Row>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}
{
}

export default PlotsHomeScreen
