import {  LANGUAGE_PRODUCT_LIST_FAIL, LANGUAGE_PRODUCT_LIST_REQUST, LANGUAGE_PRODUCT_LIST_SUCCESS } from "../constants/languageConstants"
import {  } from "../constants/userConstants"


export const languageProductReducer = (state = { products:[] }, action) => {
    switch (action.type) {
        case LANGUAGE_PRODUCT_LIST_REQUST:
            return {
                loading: true
            }
        case LANGUAGE_PRODUCT_LIST_SUCCESS:
            return {
                loading: false,
                success:true,
                products: action.payload.products,
                pages:action.payload.pages,
                page:action.payload.page
            }
        case LANGUAGE_PRODUCT_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}




