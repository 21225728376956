import {
  STORIES_GET_FAIL,
  STORIES_GET_REQUEST,
  STORIES_GET_SUCCESS,
  STORIES_LIST_FAIL,
  STORIES_LIST_REQUEST,
  STORIES_LIST_SUCCESS,
  STORIES_LIST_TOP_REQUEST,
  STORIES_LIST_TOP_SUCCESS,
  STORIES_LIST_TOP_FAIL,
} from '../constants/storiesConstants'

export const listStoriesReducer = (state = { blogs: [] }, action) => {
  switch (action.type) {
    case STORIES_LIST_REQUEST:
      return {
        loading: true,
      }
    case STORIES_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        stories: action.payload.stories,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case STORIES_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getStoriesReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case STORIES_GET_REQUEST:
      return {
        loading: true,
      }
    case STORIES_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        stories: action.payload,
      }
    case STORIES_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const topStoriesReducer = (state = { blogs: [] }, action) => {
  switch (action.type) {
    case STORIES_LIST_TOP_REQUEST:
      return {
        loading: true,
      }
    case STORIES_LIST_TOP_SUCCESS:
      return {
        loading: false,
        success: true,
        stories: action.payload.stories,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case STORIES_LIST_TOP_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
