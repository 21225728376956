import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import SmallLoader from '../../components/SmallLoader'
import { useDispatch, useSelector } from 'react-redux'
import { createStory, getStory, deleteStory } from '../../actions/storyActions'
import Loader from '../../components/Loader'
import { convertToSlug } from '../../helpers/helpers'
const CretaeStoryScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const storyId = match.params.sid || null
  const episode = match.params.id || null

  const [tags, setTags] = useState('')
  const [status, setStatus] = useState(1)
  const [title, setTitle] = useState('')
  const [author, setAuthor] = useState('')
  const [editable, setEditable] = useState(false)
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [language, setLanguage] = useState('')
  const [flag, setFlag] = useState(false)

  const [catDelete, setCatDelete] = useState(false)

  const [tagsDelete, setTagsDelete] = useState(false)
  const [lineItems, setLineItems] = useState([''])
  const [clineItems, setCLineItems] = useState([])
  const [field, setField] = useState([])

  const [coverImage, setCoverImage] = useState(
    'https://s3-books-images.s3.amazonaws.com/c18a1bca-3ad8-41b5-8218-331ce6d4cb57.png'
  )
  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [singleSelections, setSingleSelections] = useState([])
  const [multiSelections, setMultiSelections] = useState([])
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const createStoryD = useSelector((state) => state.createStory)
  const { story, loading, error, suceess } = createStoryD
  const deleteStoryRed = useSelector((state) => state.deleteStory)

  const {
    story: deletedStory,
    loading: deleteLoading,
    error: deleteError,
    suceess: deleteSuccess,
  } = deleteStoryRed
  const getStoryRed = useSelector((state) => state.getStory)
  const {
    story: existingStory,
    loading: storyLoading,
    error: storyError,
    success: storySuccess,
  } = getStoryRed

  const formLabel = {
    color: '#000',
    fontSize: '16px',
    fontWeight: 'bold',
  }
  const removeCoverImage = () => {
    setUploaded(false)
  }
  const deleteStoryHandler = (e, id) => {
    e.preventDefault()
    dispatch(deleteStory(id))
  }
  const uploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setCoverImage(data)
      setUploaded(true)
      setUploading(false)
    } catch (error) {
      setUploading(false)
    }
  }
  const addTags = () => {
    lineItems.push(tags)
    setTags('')
  }
  const removeTags = (item, e) => {
    e.preventDefault()
    const index = lineItems.indexOf(item)
    if (index > -1) {
      lineItems.splice(index, 1)
    }
    setLineItems(lineItems)
    setTagsDelete(true)
  }

  const addCategory = (e) => {
    console.log(e.target.value)
    clineItems.push(e.target.value)
    console.log(clineItems)
    setCatDelete(true)
  }
  const removeCategory = (item, e) => {
    e.preventDefault()
    const index = clineItems.indexOf(item)
    if (index > -1) {
      clineItems.splice(index, 1)
    }
    setCLineItems(clineItems)
    setCatDelete(true)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createStory({
        title: title,
        story: storyId,
        coverImage: coverImage,
        description: description,
        category: clineItems,
        language,
        tags: lineItems,
        episode: episode,
        status: status,
        author: author,
        flag: flag,
      })
    )
  }
  useEffect(() => {
    if (!storySuccess && !existingStory) {
      dispatch(getStory(storyId))
    }
    if (storySuccess && !editable) {
      setEditable(true)
      setTitle(existingStory.title)
      setDescription(existingStory.description)
      setCoverImage(existingStory.coverImage)
      setCategory(existingStory.category)
      setLanguage('1')
      setAuthor(existingStory.user.name)
      var existingTags = []
      existingStory.tags.map((tag) => existingTags.push(tag.name))
      setLineItems(existingTags)
    }
    if (tagsDelete) {
      setLineItems(lineItems)
      setTagsDelete(false)
    }
    if (deletedStory) {
      alert('deleted')
    }
    if (suceess) {
      history.push(
        `/app/en/book/${convertToSlug(existingStory.title)}/${
          existingStory._id
        }`
      )
    }
  }, [
    tagsDelete,
    suceess,
    storySuccess,
    existingStory,
    catDelete,
    clineItems,
    deletedStory,
    deleteSuccess,
  ])
  return (
    <Container>
      {storyLoading ? (
        <Loader></Loader>
      ) : (
        <>
          <Row>
            <Col>
              <h5>Story Details</h5>
              <Link to={`/write/episode/${episode}`} style={{ float: 'right' }}>
                Back
              </Link>
            </Col>
          </Row>

          <Form onSubmit={submitHandler}>
            <Row>
              <Col sm={6}>
                <Form.Group controlId='coverimage' className='mb-3'>
                  <img src={coverImage} width={'130px'} />
                  <br />
                  <Form.Control type='hidden' value={episode}></Form.Control>
                  <Form.Control type='hidden' value={storyId}></Form.Control>
                  {!uploaded ? (
                    <div className=''>
                      <Form.Label style={formLabel}>Cover Image</Form.Label>
                      <Form.Control
                        type='hidden'
                        placeholder='Add Cover Image'
                        value={coverImage}
                        required
                        onChange={(e) => setCoverImage(e.target.value)}
                      ></Form.Control>
                      <Form.File
                        id='image-file'
                        custom
                        onChange={uploadHandler}
                      ></Form.File>
                      {uploading && <SmallLoader></SmallLoader>}
                      <br />
                    </div>
                  ) : (
                    <Button
                      variant='dark'
                      className='btn-sm'
                      onClick={() => removeCoverImage()}
                    >
                      Delete
                    </Button>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className='mb-3' controlId='Storytitle'>
                  <Form.Label style={formLabel}>Story Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Untitled Story'
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId='description'>
                  <Form.Label style={formLabel}>Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId='category'>
                  <Form.Label style={formLabel}>Category</Form.Label>

                  <Form.Control
                    as='select'
                    multiple
                    value={clineItems}
                    defaultValue={['DEFAULT']}
                    onChange={(e) => addCategory(e)}
                  >
                    <option key='Select Category' value='DEFAULT' disabled>
                      Select Category
                    </option>
                    <option key='Entrepreneurship' value='Entrepreneurship'>
                      Entrepreneurship
                    </option>
                    <option key='Science' value='Science'>
                      Science
                    </option>
                    <option key='Economics' value='Economics'>
                      Economics
                    </option>
                    <option key='Corporate Culture' value='Corporate Culture'>
                      Corporate Culture
                    </option>
                    <option
                      key='Motivation & Inspiration'
                      value='Motivation & Inspiration'
                    >
                      Motivation & Inspiration
                    </option>
                    <option key='Productivity' value='Productivity'>
                      Productivity
                    </option>
                    <option
                      key='Mindfulness & Happiness'
                      value='Mindfulness & Happiness'
                    >
                      Mindfulness & Happiness
                    </option>
                    <option
                      key='Nature & Environment'
                      value='Nature & Environment'
                    >
                      Nature & Environment
                    </option>
                    <option key='Career' value='Career'>
                      Career
                    </option>
                    <option key='Creativity' value='Creativity'>
                      Creativity
                    </option>

                    <option key='Politics' value='Politics'>
                      Politics
                    </option>

                    <option key='Health & Nutrition' value='Health & Nutrition'>
                      Health & Nutrition
                    </option>

                    <option key='History' value='History'>
                      History
                    </option>
                    <option key='Management' value='Management'>
                      Management
                    </option>
                    <option key='Leadership' value='Leadership'>
                      Leadership
                    </option>

                    <option key='Money' value='Money'>
                      Money
                    </option>

                    <option key='Investment' value='Investment'>
                      Investment
                    </option>

                    <option
                      key='Sex & Relationships'
                      value='Sex & Relationships'
                    >
                      Sex & Relationships
                    </option>
                    <option key='Parenting' value='Parenting'>
                      Parenting
                    </option>
                    <option key='Bigraphy & Memoir' value='Bigraphy & Memoir'>
                      Biography & Memoir
                    </option>
                    <option key='Education' value='Education'>
                      Education
                    </option>
                    <option key='Philosophy' value='Philosophy'>
                      Philosophy
                    </option>
                    <option key='Marketing & Sales' value='Marketing & Sales'>
                      Marketing & Sales
                    </option>
                    <option
                      key='Personal Development'
                      value='Personal Development'
                    >
                      Personal Development
                    </option>
                    <option
                      key='Communication Skills'
                      value='Communication Skills'
                    >
                      Communication Skills
                    </option>
                    <option key='Psychology' value='Psychology'>
                      Psychology
                    </option>
                    <option key='Technology' value='Technology'>
                      Technology
                    </option>
                    <option key='Society & Culture' value='Society & Culture'>
                      Society & Culture
                    </option>
                    <option
                      key='Religion & Spirituality'
                      value='Religion & Spirituality'
                    >
                      Religion & Spirituality
                    </option>
                    <option key='Cooking Reciepes' value='Cooking Reciepes'>
                      Cooking Reciepes
                    </option>
                    <option key='Motivation' value='Motivation'>
                      Motivation
                    </option>
                    <option key='Life Lessons' value='Life Lessons'>
                      Life Lessons
                    </option>
                  </Form.Control>
                </Form.Group>

                {/* <Form.Group className='mb-3' controlId='category'>
                  <Form.Label style={formLabel}>Catgories</Form.Label>
                  <Row>
                    <Col xs={9}>
                      <Form.Control
                        type='text'
                        placeholder='Tags'
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      />
                    </Col>
                    <Col xs={3}>
                      <Button
                        variant='primary'
                        className='mb-10'
                        style={{ float: 'left' }}
                        onClick={addCategory}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>

                 
                </Form.Group> */}
                <Form.Group className='mb-3' controlId='tags'>
                  <Form.Label style={formLabel}>Tags</Form.Label>
                  <Row>
                    <Col xs={9}>
                      <Form.Control
                        type='text'
                        placeholder='Tags'
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                      />
                    </Col>
                    <Col xs={3}>
                      <Button
                        variant='primary'
                        className='mb-10'
                        style={{ float: 'left' }}
                        onClick={addTags}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>

                  {lineItems.length > 0 && (
                    <div>
                      {lineItems.map((item) => (
                        <small className='topic' key={item}>
                          {item}{' '}
                          <Link to='' onClick={(e) => removeTags(item, e)}>
                            X
                          </Link>
                        </small>
                      ))}
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId='language'>
                  <Form.Label style={formLabel}>Language</Form.Label>

                  <Form.Control
                    as='select'
                    required
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option key='Select Language' value={''}>
                      Select Language
                    </option>
                    <option key='Bengali' value='0'>
                      Bengali
                    </option>
                    <option key='English' value='1' selected>
                      English
                    </option>
                    <option key='Gujarati' value='2'>
                      Gujarati
                    </option>
                    <option key='Hindi' value='3'>
                      Hindi
                    </option>
                    <option key='Kannada' value='4'>
                      Kannada
                    </option>
                    <option key='Malayalam' value='5'>
                      Malayalam
                    </option>
                    <option key='Marathi' value='6'>
                      Marathi
                    </option>
                    <option key='Tamil' value='7'>
                      Tamil
                    </option>
                    <option key='Telugu' value='8'>
                      Telugu
                    </option>
                    <option key='Punjabi' value='9'>
                      Punjabi
                    </option>
                  </Form.Control>
                </Form.Group>
                {userInfo.isAdmin && (
                  <>
                    <Form.Group className='mb-3' controlId='storyAuthor'>
                      <Form.Label style={formLabel}>Story Author</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Story Author'
                        required
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                      />
                    </Form.Group>
                  </>
                )}
                <Form.Group controlId='language'>
                  <Form.Check
                    type='checkbox'
                    value={2}
                    label='Is Story Completed?'
                    onChange={(e) => setStatus(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId='language'>
                  <Form.Check
                    type='checkbox'
                    value={true}
                    label='Is Book Open Source?'
                    onChange={(e) => setFlag(e.target.value)}
                  />
                </Form.Group>

                <div style={{ float: 'right' }}>
                  <Link
                    to={``}
                    onClick={(e) => deleteStoryHandler(e, storyId)}
                    className='newbuttons'
                  >
                    Delete
                  </Link>
                  <Link to={`/write/episode/${episode}/${storyId}`}>
                    <Button variant='primary'>Back</Button>
                  </Link>{' '}
                  &nbsp; &nbsp;
                  <Button variant='primary' type='submit'>
                    Update Book
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Container>
  )
}

export default CretaeStoryScreen
