export const DISCOUNT_CODE_CREATE_REQUEST = 'DISCOUNT_CODE_CREATE_REQUEST'
export const DISCOUNT_CODE_CREATE_SUCCESS = 'DISCOUNT_CODE_CREATE_SUCCESS'
export const DISCOUNT_CODE_CREATE_FAIL = 'DISCOUNT_CODE_CREATE_FAIL'
export const DISCOUNT_CODE_CREATE_RESET = 'DISCOUNT_CODE_CREATE_RESET'

export const DISCOUNT_CODE_LIST_REQUEST = 'DISCOUNT_CODE_LIST_REQUEST'
export const DISCOUNT_CODE_LIST_SUCCESS = 'DISCOUNT_CODE_LIST_SUCCESS'
export const DISCOUNT_CODE_LIST_FAIL = 'DISCOUNT_CODE_LIST_FAIL'
export const DISCOUNT_CODE_LIST_RESET = 'DISCOUNT_CODE_LIST_RESET'

export const DISCOUNT_CODE_REQUEST = 'DISCOUNT_CODE_REQUEST'
export const DISCOUNT_CODE_SUCCESS = 'DISCOUNT_CODE_SUCCESS'
export const DISCOUNT_CODE_FAIL = 'DISCOUNT_CODE_FAIL'
export const DISCOUNT_CODE_RESET = 'DISCOUNT_CODE_RESET'

export const DISCOUNT_CODE_REEDEM_REQUEST = 'DISCOUNT_CODE_REEDEM_REQUEST'
export const DISCOUNT_CODE_REEDEM_SUCCESS = 'DISCOUNT_CODE_REEDEM_SUCCESS'
export const DISCOUNT_CODE_REEDEM_FAIL = 'DISCOUNT_CODE_REEDEM_FAIL'

export const USER_DISCOUNT_CODE_LIST_REQUEST = 'USER_DISCOUNT_CODE_LIST_REQUEST'
export const USER_DISCOUNT_CODE_LIST_SUCCESS = 'USER_DISCOUNT_CODE_LIST_SUCCESS'
export const USER_DISCOUNT_CODE_LIST_FAIL = 'USER_DISCOUNT_CODE_LIST_FAIL'

export const DISCOUNT_CODE_PERCENTAGE = 'percentage'
export const DISCOUNT_CODE_FIXEDAMOUNT = 'fixedAmount'
