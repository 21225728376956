import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { deleteUser } from '../actions/userActions'
import { getBookFairList } from '../actions/bookFairActions'
import { Helmet } from 'react-helmet'

const BookFairListPScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const listBookFair = useSelector((state) => state.listBookFair)
  const { loading, error, success, bookfairs } = listBookFair

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getBookFairList(true))
  }, [dispatch, history, userInfo])

  const deleteHandler = (id) => {
    if (window.confirm('are you Sure')) {
      dispatch(deleteUser(id))
    }
  }
  return (
    <Container>
      <Helmet>
        <title>
          99bookscart Bookfairs in Banglore|Mumbai|Pune|Other Cities
        </title>
        <meta charset='UTF-8' />

        <meta property='og:title' content='99bookscart Bookfairs' />

        <meta name='title' content='99bookscart Bookfairs'></meta>
        <meta name='description' content=''></meta>
        <meta
          name='keyword'
          content='book fair in banglore, book fair in Mangaluru, book fair in Chennai'
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        ></meta>
        <link
          rel='canonical'
          href='https://www.99bookscart.com/bookfairs'
        ></link>
        <meta name='robots' content='index, follow' />
      </Helmet>
      <h1>99bookscart Book Fairs</h1>
      <p>
        A book fair is an event that brings together authors, publishers, and
        readers to celebrate and promote books and reading. At a book fair, you
        can expect to find a wide variety of books on display, as well as
        activities such as panel discussions, workshops, and book signings.
      </p>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <h1>99bookscart Bookfairs</h1>
          <Table striped bordered hover responsive className='table-sm'>
            <tbody>
              {bookfairs.map((bookfair) => (
                <tr key={bookfair._id}>
                  <td style={{ paddingBottom: '8px' }}>
                    <div>
                      {bookfair.city}, {bookfair.state}
                    </div>
                    <LinkContainer to={`/bookfair/${bookfair._id}`}>
                      <h1>{bookfair.name}</h1>
                    </LinkContainer>
                    <p className='bookfair-event-header'>
                      <strong>
                        {' '}
                        <i class='fas fa-clock'></i>
                        {formatDate2(bookfair.startDate)} to{' '}
                        {formatDate2(bookfair.endDate)}, 2023 | 9am - 10pm
                      </strong>
                      <br />
                      <strong>
                        <i class='far fa-building	'></i> {bookfair.address}{' '}
                      </strong>
                      <br />
                      <strong>
                        <i className='fas fa-globe	'></i>{' '}
                        <a href={bookfair.googleLink} target='_blank'>
                          <u> Google Map</u>
                        </a>{' '}
                      </strong>
                      <br />
                    </p>
                    <LinkContainer to={`/bookfair/${bookfair._id}`}>
                      <Button className='btn-sm' variant='primary'>
                        Details
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  )
}
const formatDate2 = (dateString) => {
  const options = { month: 'long', day: 'numeric' }
  return new Date(dateString).toLocaleDateString(undefined, options)
}
export default BookFairListPScreen
