import React from 'react'
import { ProgressBar } from 'react-bootstrap'

import { useHistory } from 'react-router-dom'
const DayProgressBar = ({ ProgressValue = 0 }) => {
  return (
    <>
      <ProgressBar now={ProgressValue*100} style={{height:'12px'}}/>
    </>
  )
}

export default DayProgressBar
