import React from 'react'
import Helmet from 'react-helmet'
export function convertToSlug2(Text) {
  return (
    Text &&
    Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  )
}
const StoriesMeta = ({ story }) => {
  const title = story.title
  const image = story.coverImage
  const author = 'Pooja Rawath'
  const createdAt = story.createdAt ? story.createdAt.slice(0, 10) : ''
  const updatedAt = story.updatedAt ? story.updatedAt.slice(0, 10) : ''
  const description = story.metaDescription
  const url = `https://www.99bookscart.com/web-stories/${
    story._id
  }/${convertToSlug2(story.title)}`

  const ff = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': 'https://www.99bookscart.com/#organization',
        name: 'www.99bookscart.com.com',
        url: 'https://www.99bookscart.com.com',
        logo: {
          '@type': 'ImageObject',
          '@id': 'https://www.99bookscart.com.com/#logo',
          url: 'https://www.99bookscart.com/images/logo.png',
          contentUrl: 'https://www.99bookscart.com/images/logo.png',
          caption: 'www.99bookscart.com',
          inLanguage: 'en-GB',
          width: '250',
          height: '66',
        },
      },
      {
        '@type': 'WebSite',
        '@id': 'https://www.99bookscart.com/#website',
        url: 'https://www.99bookscart.com',
        name: 'www.99bookscart.com',
        alternateName: 'bookscart',
        publisher: { '@id': 'https://www.99bookscart.com/#organization' },
        inLanguage: 'en-GB',
      },
      {
        '@type': 'ImageObject',
        '@id': story.coverImage,
        url: story.coverImage,
        width: '720',
        height: '1280',
        caption: story.title,
        inLanguage: 'en-GB',
      },
      {
        '@type': 'WebPage',
        '@id': url,
        url: url,
        name: story.title,
        datePublished: story.createdAt,
        dateModified: story.updatedAt,
        isPartOf: { '@id': 'https://www.99bookscart.com/#website' },
        primaryImageOfPage: {
          '@id': story.coverImage,
        },
        inLanguage: 'en-GB',
      },
      {
        '@type': 'Person',
        '@id': 'https://www.99bookscart.com/contact-us',
        name: author,
        url: 'https://www.99bookscart.com/contact-us',
        image: {
          '@type': 'ImageObject',
          '@id':
            'https://secure.gravatar.com/avatar/7ff6461073df442da4c8d1d80556c9d4?s=96&amp;d=mm&amp;r=g',
          url: 'https://secure.gravatar.com/avatar/7ff6461073df442da4c8d1d80556c9d4?s=96&amp;d=mm&amp;r=g',
          caption: author,
          inLanguage: 'en-GB',
        },
        sameAs: ['https://www.99bookscart.com/'],
        worksFor: { '@id': 'https://www.99bookscart.com/#organization' },
      },
      {
        '@type': 'NewsArticle',
        headline: story.title,
        datePublished: story.createdAt,
        dateModified: story.updatedAt,
        articleSection: 'Arts and Literature',
        author: {
          '@id': 'https://www.99bookscart.com/contact-us',
          name: author,
        },
        publisher: { '@id': 'https://www.99bookscart.com/#organization' },
        description: story.metaDescription,
        name: story.title,
        '@id': url + '/#richSnippet',
        isPartOf: {
          '@id': url + '/#richSnippet',
        },
        image: {
          '@id': story.coverImage,
        },
        inLanguage: 'en-GB',
        mainEntityOfPage: {
          '@id': url + '/#webpage',
        },
      },
    ],
  }

  return (
    <Helmet>
      <title>{story.title}</title>
      <meta charset='UTF-8' />

      <meta property='og:title' content={story.title} />
      <link
        rel='preload'
        href={story.coverImage && story.coverImage}
        as='image'
      />
      <link rel='canonical' href={url} />
      <head prefix='book: https://ogp.me/ns/book#'></head>
      <meta property='og:type' content='article' />
      <meta property='og:url' content='https://www.99bookscart.com' />
      <meta
        property='og:image'
        content='https://99bookscart.com/images/logo.png'
      />
      <meta property='og:description' content={story.metaDescription} />
      <meta property='og:site_name' content='99bookscart' />
      <meta name='description' content={story.metaDescription}></meta>
      <meta name='keyword' content={story.title}></meta>
      <meta name='title' content={story.title}></meta>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0'
      ></meta>

      <meta name='robots' content='index, follow' />
      <meta name='author' content={author} />
      <meta name='contact' content='info@99bookscart.com' />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@99bookscart' />
      <meta name='twitter:title' content={story.title} />
      <meta name='twitter:description' content={story.metaDescription} />
      {/* <script async src='https://cdn.ampproject.org/v0.js'></script> */}

      <script
        async
        custom-element='amp-story'
        src='https://cdn.ampproject.org/v0/amp-story-1.0.js'
      ></script>

      <script
        async
        custom-element='amp-ad'
        src='https://cdn.ampproject.org/v0/amp-ad-0.1.js'
      ></script>

      <script className='structured-data-list' type='application/ld+json'>
        {JSON.stringify(ff)}
      </script>
    </Helmet>
  )
}

export default StoriesMeta
