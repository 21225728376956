import axios from 'axios'
import { AI_FAIL, AI_REQUEST, AI_SUCCEES } from '../constants/aiConstants'

import {
  STORIES_GET_FAIL,
  STORIES_GET_REQUEST,
  STORIES_GET_SUCCESS,
  STORIES_LIST_FAIL,
  STORIES_LIST_REQUEST,
  STORIES_LIST_SUCCESS,
  STORIES_LIST_TOP_FAIL,
  STORIES_LIST_TOP_REQUEST,
  STORIES_LIST_TOP_SUCCESS,
} from '../constants/storiesConstants'
export const topStories = (pageNumber=1) => async (dispatch, getState) => {
  try {
    dispatch({ type: STORIES_LIST_TOP_REQUEST })

    const { data } = await axios.get(`/api/stories/top?pageNumber=${pageNumber}`)
    dispatch({
      type: STORIES_LIST_TOP_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: STORIES_LIST_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const listStories = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: STORIES_LIST_REQUEST })

    const { data } = await axios.get(`/api/stories?pageNumber=${pageNumber}`)
    dispatch({
      type: STORIES_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: STORIES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getStories = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: STORIES_GET_REQUEST })

    const { data } = await axios.get(`/api/stories/${id}`)
    dispatch({
      type: STORIES_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: STORIES_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
