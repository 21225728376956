export const GENERATE_SHIPPMENT_REQUEST = 'GENERATE_SHIPPMENT_REQUEST'
export const GENERATE_SHIPPMENT_SUCCESS = 'GENERATE_SHIPPMENT_SUCCESS'
export const GENERATE_SHIPPMENT_FAIL = 'GENERATE_SHIPPMENT_FAIL'

export const GET_SHIPPMENT_REQUEST = 'GET_SHIPPMENT_REQUEST'
export const GET_SHIPPMENT_SUCCESS = 'GET_SHIPPMENT_SUCCESS'
export const GET_SHIPPMENT_FAIL = 'GET_SHIPPMENT_FAIL'

export const ASSIGN_AWB_REQUEST = 'ASSIGN_AWB_REQUEST'
export const ASSIGN_AWB_SUCCESS = 'ASSIGN_AWB_SUCCESS'
export const ASSIGN_AWB_FAIL = 'ASSIGN_AWB_FAIL'

export const LIST_SHIPPMENT_REQUEST = 'LIST_SHIPPMENT_REQUEST'
export const LIST_SHIPPMENT_SUCCESS = 'LIST_SHIPPMENT_SUCCESS'
export const LIST_SHIPPMENT_FAIL = 'LIST_SHIPPMENT_FAIL'
