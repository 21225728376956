import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup } from 'react-bootstrap'
import Product from '../../components/Product'
import axios from 'axios'
import { listProductsByType } from '../../actions/productActions'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import BookPaginate from '../../components/paginate/BookPaginate'
import { Container } from 'react-bootstrap'
import ProductCarosel from '../../components/ProductCarosel'
import Meta from '../../components/Meta'
import BoxProgressBar from '../../components/BoxProgressBar'
import BoxCarosel from '../../components/BoxCarosel'
import { listProductsByCategory } from '../../actions/categoryActions'
import FooterMenu from '../../components/FooterMenu'
import { convertToSlug, convertToSlug2 } from '../../helpers/helpers'
import { getGenres } from '../../actions/genreActions'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

const FictionBookScreen = ({ match }) => {
  const pageNumber = match.params.pageNumber || 1

  const productList = useSelector((state) => state.productListByType)
  const { loading, error, products, pages, page } = productList

  const getGenreList = useSelector((state) => state.getGenreList)
  const {
    loading: genreLoading,
    error: genreError,
    genres,
    success: genreSuccess,
  } = getGenreList

  const fictions = [
    'Crime & Thriller',
    'Action & Adventure',
    'Romance & Erotica',
    'Mystery & Detective',
    'Humour & Comedy',
    'Dystopian & Post-Apocalyptic',
    'Young Adult & Teen',
    'Historical Fiction & Mythology',
    'Horror & Paranormal',
    'Science Fiction & Fantasy',
    'Womens Fiction & Chick Lit',
    'Contemporary Fiction & Literary',
    'Classics & Literature',
    'Comics & Graphic Novels',
    'Childrens Books & Young Readers',
  ]
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
    // if (!genreLoading) {

    // }
    dispatch(getGenres(0))
    // dispatch(listProductsByType(1, pageNumber))
  }, [dispatch, match, pageNumber, match.params.category])
  return (
    <Container>
      <Helmet>
        <title>Fiction Books</title>
        <meta charset='UTF-8' />
        <meta
          name='title'
          content={'Best selling fiction books on 99bookscart'}
        ></meta>
        <meta
          name='description'
          content={
            'Discover the joy of reading with our online book fair! Curate your own book box and choose any 9 books for just ₹999/-. Start building your personal library today'
          }
        ></meta>
        <meta
          name='keyword'
          content={('Online Book Fair', 'Your Own Book Box')}
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        ></meta>
        <link
          rel='canonical'
          href={'https://www.99bookscart.com/fiction-books'}
        ></link>
        <meta name='robots' content='index, follow' />
      </Helmet>
      {pageNumber === 1 && (
        <div className='text-center'>
          <br />
          <h1 className='text-center dark'>Best Fiction Genres</h1>
        </div>
      )}
      <br />
      {genreLoading ? (
        <Loader></Loader>
      ) : genreError ? (
        <Message>
          <h2>error</h2>
        </Message>
      ) : (
        <>
          <Stack direction='row' spacing={1}>
            <Container className='text-center'>
              {genres.map((genre) => (
                <Link to={`/fiction-books/${convertToSlug(genre.genre)}`}>
                  {' '}
                  <span className='topic-genre'>
                    {genre.genre} ({genre.count}){' '}
                  </span>
                </Link>
              ))}
            </Container>
          </Stack>
        </>
      )}
      <Container>
        <FooterMenu></FooterMenu>
      </Container>
    </Container>
  )
}

export default FictionBookScreen
