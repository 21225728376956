import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Image, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { login } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import { submitFrom } from '../actions/formActions'
import { FORM_ADD_RESET } from '../constants/formConstants'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ContactFormScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')
  const [messageType, setMessageType] = useState('')
  const [message, setMessage] = useState('')
  const [phoneError, setPhoneError] = useState('')

  const dispatch = useDispatch()
  const addToForm = useSelector((state) => state.addToForm)
  const { loading, error, success, form } = addToForm

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!loading) {
      dispatch({ type: FORM_ADD_RESET })
      setName('')
      setEmail('')
      setContact('')
      setMessageType('')
      setMessage('')
    }
  }, [dispatch, success, loading])

  const contactSubmitHandler = (e) => {
    e.preventDefault()
    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(contact)) {
      dispatch(submitFrom({ name, email, contact, messageType, message }))
    } else {
      setPhoneError('Enter Valid Phone Number')
    }
  }
  return (
    <Container>
      <Row>
        <Col md={6}>
          <FormContainer>
            <h1>Contact 99bookscart</h1>
            {!loading && <Message variant='success'>Form Submitted</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {/* {loading && <Loader></Loader>} */}
            <Form onSubmit={contactSubmitHandler}>
              <Form.Group controlId='name'>
                {/* <Form.Label>
                        Name
                    </Form.Label> */}
                <Form.Control
                  type='text'
                  placeholder='Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='email'>
                {/* <Form.Label>
                        Email Address
                    </Form.Label> */}
                <Form.Control
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='contact'>
                {/* <Form.Label>
                        Password
                    </Form.Label> */}
                <Form.Text className='text-muted'>
                  {phoneError && <span>{phoneError}</span>}
                </Form.Text>
                <Form.Control
                  type='text'
                  placeholder='Phone Number'
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='messageType'>
                {/* <Form.Label>
                        Password
                    </Form.Label> */}
                <Form.Control
                  as='select'
                  aria-label='Message Type'
                  onChange={(e) => setMessageType(e.target.value)}
                >
                  <option>Select Type of Message</option>
                  <option value='Order Issue'>Order Issue</option>
                  <option value='Feedback'>Feedback</option>
                  <option value='Suggestions'>Suggestions</option>
                  <option value='Complaint'>Complaint</option>
                  <option value='Wholesale'>Wholesale</option>
                  <option value='Patnership'>Patnership</option>
                  <option value='Request a Book'>Request a Book</option>
                  <option value='Other'>Other</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId='message'>
                <Form.Control
                  as='textarea'
                  rows={4}
                  placeholder='Message'
                  value={message}
                  name='message'
                  onChange={(e) => setMessage(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <br />
              <Button type='submit' varaint='primary'>
                Submit
              </Button>
            </Form>
          </FormContainer>
        </Col>
        <Col md={6}>
          <LazyLoadImage
            src={'/images/login.jpg'}
            width={'100%'}
            className='login-image'
            alt={'contact 99boookscart'}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default ContactFormScreen
