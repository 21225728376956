import React from 'react'
import { ProgressBar } from 'react-bootstrap'

const AiBoxBar = ({ value }) => {
  const pragressVaraint = 'success'

  return (
    <>
      <ProgressBar
        className='ai-book-box-bar'
        variant={pragressVaraint}
        now={value}
      />
      <small className='text-muted'>AI Powered</small>
    </>
  )
}

export default AiBoxBar
