import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { getOrderDetails, deliverOrder } from '../../actions/orderActions'
import { ORDER_DELEVERED_RESET } from '../../constants/orderConstants'

const TermsConditionScreen = ({ match, history }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Container>
        <Row>
          <div class='py-3'>
            <p>
              <h1>Terms & Conditions</h1>
            </p>
            <p>
              <strong>1.</strong>These terms and conditions shall govern your
              use of our website.
            </p>
            <p>
              <strong>1.1.</strong>By using our website, you accept these terms
              and conditions in full; accordingly, if you disagree with these
              terms and conditions or any part of these terms and conditions,
              you must not use our website.
            </p>
            <p>
              <strong>1.2.</strong>If you [register with our website, submit any
              material to our website or use any of our website services], we
              will ask you to expressly agree to these terms and conditions.
            </p>
            <p>
              <strong>1.3.</strong>You must be at least [18] years of age to use
              our website; by using our website or agreeing to these terms and
              conditions, you warrant and represent to us that you are at least
              [18] years of age.
            </p>
            <p>
              <strong>1.4.</strong>Our website uses cookies; by using our
              website or agreeing to these terms and conditions, you consent to
              our use of cookies in accordance with the terms of our [privacy
              and cookies policy].
            </p>
            <p>
              <strong>2.</strong>
              <strong>Licence to use website</strong>
            </p>
            <p>
              <strong>2.1.</strong>You may:
            </p>
            <ul>
              <li>view pages from our website in a web browser;</li>
              <li>
                download pages from our website for caching in a web browser;
              </li>
              <li>print pages from our website;</li>
              <li>use or buy by means of a web browser,</li>
            </ul>
            <p>
              subject to the other provisions of these terms and conditions.
            </p>
            <p>
              2.2. Except as expressly permitted BY THE&nbsp;<span>&nbsp;</span>
              provisions of these terms and conditions, you must not download
              any material from our website or save any such material to your
              computer.
            </p>
            <p>
              2.3. You may only use our website for [your own personal and
              business purposes], and you must not use our website for any other
              purposes.
            </p>
            <p>
              2.4.Except as expressly permitted by these terms and conditions,
              you must not edit or otherwise modify any material on our website.
            </p>
            <p>
              2.5.Unless you own or control the relevant rights in the material,
              you must not:
            </p>
            <ul>
              <li>
                republish material from our website (including republication on
                another website);
              </li>
              <li>sell, rent or sub-license material from our website;</li>
              <li>show any material from our website in public;</li>
              <li>
                exploit material from our website for a commercial purpose; or
              </li>
              <li>Â&nbsp;redistribute material from our website.</li>
            </ul>
            <p>
              2.6. We reserve the right to restrict access to areas of our
              website, or indeed our whole website, at our discretion; you must
              not circumvent or bypass, or attempt to circumvent or bypass, any
              access restriction measures on our website.
            </p>
            <p>
              <strong>
                3.<span>&nbsp;</span>
              </strong>
              <strong>Acceptable use</strong>
            </p>
            <p>3.1. You must not:</p>
            <ul>
              <li>
                use our website in any way or take any action that causes, or
                may cause, damage to the website or impairment of the
                performance, availability or accessibility of the website;
              </li>
              <li>
                use our website in any way that is unlawful, illegal, fraudulent
                or harmful, or in connection with any unlawful, illegal,
                fraudulent or harmful purpose or activity;
              </li>
              <li>
                use our website to copy, store, host, transmit, send, use,
                publish or distribute any material which consists of (or is
                linked to) any spyware, computer virus, Trojan horse, worm,
                keystroke logger, rootkit or other malicious computer software;
              </li>
              <li>
                conduct any systematic or automated data collection activities
                (including without limitation scraping, data mining, data
                extraction and data harvesting) on or in relation to our website
                without our express written consent;
              </li>
              <li>
                access or otherwise interact with our website using any robot,
                spider or other automated means[, except for the purpose of
                search engine indexing;
              </li>
              <li>
                violate the directives set out in the robots.txt file for our
                website
              </li>
              <li>
                use data collected from our website for any direct marketing
                activity (including without limitation email marketing, SMS
                marketing, telemarketing and direct mailing).
              </li>
            </ul>
            <p>
              3.2. You must not use data collected from our website to contact
              individuals, companies or other persons or entities.
            </p>
            <p>
              3.3. You must ensure that all the information you supply to us
              through our website, or in relation to our website, is [true,
              accurate, current, complete and non-misleading].
            </p>
            <p>
              <strong>
                4.&nbsp;<span>&nbsp;</span>
              </strong>
              <strong>Registration and accounts</strong>
            </p>
            <p>
              4.1. You may register for an account with our website by
              completing and submitting the account registration form on our
              website, and clicking on the verification link in the email that
              the website will send to you.
            </p>
            <p>
              4.2Â&nbsp;You must not allow any other person to use your account
              to access the website.
            </p>
            <p>
              4.3. You must notify us in writing immediately if you become aware
              of any unauthorised use of your account.
            </p>
            <p>
              4.4 You must not use any other person’s account to access the
              website, unless you have that person’s express permission to do
              so.
            </p>
            <p>
              <strong>
                5.<span>&nbsp;</span>
              </strong>
              <strong>User login details</strong>
            </p>
            <p>
              5.1.If you register for an account with our website, [we will
              provide you with] OR [you will be asked to choose] [a user ID and
              password].
            </p>
            <p>
              5.2. Your user ID must not be liable to mislead; you must not use
              your account or user ID for or in connection with the
              impersonation of any person.
            </p>
            <p>5.3.You must keep your password confidential.</p>
            <p>
              5.4. You must notify us in writing immediately if you become aware
              of any disclosure of your password.
            </p>
            <p>
              5.5. You are responsible for any activity on our website arising
              out of any failure to keep your password confidential, and may be
              held liable for any losses arising out of such a failure.
            </p>
            <p>
              <strong>
                6.<span>&nbsp;</span>
              </strong>
              <strong>Cancellation and suspension of account</strong>
            </p>
            <p>6.1. We may:</p>
            <ul>
              <li>suspend your account;</li>
              <li>cancel your account; and/or</li>
              <li>edit your account details,</li>
            </ul>
            <p>
              &nbsp;at any time in our sole discretion without notice or
              explanation.
            </p>
            <p>
              6.2. You may cancel your account on our website using your account
              control panel on the website.
            </p>
            <p>
              <strong>
                7.&nbsp;<span>&nbsp;</span>
              </strong>
              <strong>Your content: licence</strong>
            </p>
            <p>
              7.1. In these terms and conditions, “your content" means all works
              and materials (including without limitation text, graphics,
              images, audio material, video material, audio-visual material,
              scripts, software and files) that you submit to us or our website
              for storage or publication on, processing by, or transmission via,
              our website.
            </p>
            <p>
              7.2. You grant to us a worldwide, irrevocable, non-exclusive,
              royalty-free licence to use, reproduce, store, adapt, publish,
              translate and distribute your content in any existing or future
              media OR reproduce, store and publish your content on and in
              relation to this website and any successor website OR reproduce,
              store and, with your specific consent, publish your content on and
              in relation to this website.
            </p>
            <p>
              7.3. You hereby waive all your moral rights in your content to the
              maximum extent permitted by applicable law; and you warrant and
              represent that all other moral rights in your content have been
              waived to the maximum extent permitted by applicable law.
            </p>
            <p>
              7.4. You may edit your content to the extent permitted using the
              editing functionality made available on our website.
            </p>
            <p>
              7.5. Without prejudice to our other rights under these terms and
              conditions, if you breach any provision of these terms and
              conditions in any way, or if we reasonably suspect that you have
              breached these terms and conditions in any way, we may delete,
              unpublish or edit any or all of your content.
            </p>
            <p>
              <strong>
                8.<span>&nbsp;</span>
              </strong>
              <strong>Your content: rules</strong>
            </p>
            <p>
              <strong>8.1.</strong>
              <span>&nbsp;</span>You warrant and represent that your content
              will comply with these terms and conditions.
            </p>
            <p>
              <strong>8.2.</strong>&nbsp;Your content must not be illegal or
              unlawful, must not infringe any person’s legal rights, and must
              not be capable of giving rise to legal action against any person
              (in each case in any jurisdiction and under any applicable law).
            </p>
            <p>
              <strong>8.3.</strong>&nbsp;Your content, and the use of your
              content by us in accordance with these terms and conditions, must
              not:
            </p>
            <ul>
              <li>be libellous or maliciously false;</li>
              <li>be obscene or indecent;</li>
              <li>
                infringe any copyright, moral right, database right, trade mark
                right, design right, right in passing off, or other intellectual
                property right;
              </li>
              <li>
                infringe any right of confidence, right of privacy or right
                under data protection legislation;
              </li>
              <li>
                constitute negligent advice or contain any negligent statement;
              </li>
              <li>
                constitute an incitement to commit a crime, instructions for the
                commission of a crime or the promotion of criminal activity];
              </li>
              <li>
                be in contempt of any court, or in breach of any court order;
              </li>
              <li>
                be in breach of racial or religious hatred or discrimination
                legislation;
              </li>
              <li>be blasphemous;</li>
              <li>be in breach of official secrets legislation;</li>
              <li>
                be in breach of any contractual obligation owed to any person;
              </li>
              <li>
                depict violence in an explicit, graphic or gratuitous manner;
              </li>
              <li>be pornographic, lewd, suggestive or sexually explicit;</li>
              <li>be untrue, false, inaccurate or misleading;</li>
              <li>
                consist of or contain any instructions, advice or other
                information which may be acted upon and could, if acted upon,
                cause illness, injury or death, or any other loss or damage;
              </li>
              <li>constitute spam;</li>
              <li>
                be offensive, deceptive, fraudulent, threatening, abusive,
                harassing, anti-social, menacing, hateful, discriminatory or
                inflammatory; or
              </li>
              <li>
                cause annoyance, inconvenience or needless anxiety to any
                person.
              </li>
            </ul>
            <p>
              <strong>
                9.&nbsp;<span>&nbsp;</span>
              </strong>
              <strong>Limited warranties</strong>
            </p>
            <p>9.1. We do not warrant or represent:</p>
            <ul>
              <li>
                the completeness or accuracy of the information published on our
                website;
              </li>
              <li>that the material on the website is up to date; or</li>
              <li>
                that the website or any service on the website will remain
                available.
              </li>
            </ul>
            <p>
              9.2. We reserve the right to discontinue or alter any or all of
              our website services, and to stop publishing our website, at any
              time in our sole discretion without notice or explanation; and
              save to the extent expressly provided otherwise in these terms and
              conditions, you will not be entitled to any compensation or other
              payment upon the discontinuance or alteration of any website
              services, or if we stop publishing the website.
            </p>
            <p>
              9.3. To the maximum extent permitted by applicable law, we exclude
              all representations and warranties relating to the subject matter
              of these terms and conditions, our website and the use of our
              website.
            </p>
            <p>
              <strong>10.</strong>
              <strong>Breaches of these terms and conditions</strong>
            </p>
            <p>
              <strong>10.1.&nbsp;</strong>Without prejudice to our other rights
              under these terms and conditions, if you breach these terms and
              conditions in any way, or if we reasonably suspect that you have
              breached these terms and conditions in any way, we may:
            </p>
            <ul>
              <li>send you one or more formal warnings;</li>
              <li>temporarily suspend your access to our website;</li>
              <li>permanently prohibit you from accessing our website;</li>
              <li>
                block computers using your IP address from accessing our
                website;
              </li>
              <li>
                contact any or all of your internet service providers and
                request that they block your access to our website;
              </li>
              <li>
                commence legal action against you, whether for breach of
                contract or otherwise; and/or
              </li>
              <li>suspend or delete your account on our website.</li>
            </ul>
            <p>
              10.2. Where we suspend or prohibit or block your access to our
              website or a part of our website, you must not take any action to
              circumvent such suspension or prohibition or blocking (including
              without limitation creating and/or using a different account).
            </p>
            <p>
              <strong>11.</strong>
              <strong>Variation</strong>
            </p>
            <p>
              11.1. We may revise these terms and conditions from time to time.
            </p>
            <p>
              11.2.The revised terms and conditions shall apply to the use of
              our website from the date of publication of the revised terms and
              conditions on the website, and you hereby waive any right you may
              otherwise have to be notified of, or to consent to, revisions of
              these terms and conditions. OR We will give you written notice of
              any revision of these terms and conditions, and the revised terms
              and conditions will apply to the use of our website from the date
              that we give you such notice; if you do not agree to the revised
              terms and conditions, you must stop using our website.
            </p>
            <p>
              11.3. If you have given your express agreement to these terms and
              conditions, we will ask for your express agreement to any revision
              of these terms and conditions; and if you do not give your express
              agreement to the revised terms and conditions within such period
              as we may specify, we will disable or delete your account on the
              website, and you must stop using the website.
            </p>
            <p>
              <strong>12.</strong>
              <strong>Assignment</strong>
            </p>
            <p>
              12.1. You hereby agree that we may assign, transfer, sub-contract
              or otherwise deal with our rights and/or obligations under these
              terms and conditions.
            </p>
            <p>
              12.2. You may not without our prior written consent assign,
              transfer, sub-contract or otherwise deal with any of your rights
              and/or obligations under these terms and conditions.
            </p>
            <p>
              <strong>13.</strong>
              <strong>Severability</strong>
            </p>
            <p>
              13.1. If a provision of these terms and conditions is determined
              by any court or other competent authority to be unlawful and/or
              unenforceable, the other provisions will continue in effect.
            </p>
            <p>
              13.2. If any unlawful and/or unenforceable provision of these
              terms and conditions would be lawful or enforceable if part of it
              were deleted, that part will be deemed to be deleted, and the rest
              of the provision will continue in effect.
            </p>
            <p>
              <strong>14.</strong>
              <strong>Third party rights</strong>
            </p>
            <p>
              <strong>14.1.</strong>A contract under these terms and conditions
              is for our benefit and your benefit, and is not intended to
              benefit or be enforceable by any third party.
            </p>
            <p>
              <strong>14.2.&nbsp;</strong>The exercise of the parties’ rights
              under a contract under these terms and conditions is not subject
              to the consent of any third party.
            </p>
            <p>
              <strong>15.</strong>
              <strong>Entire agreement</strong>
            </p>
            <p>
              <strong>15.1.</strong>The terms and conditions, together with our
              privacy and cookies policy, shall constitute the entire agreement
              between you and us in relation to your use of our website and
              shall supersede all previous agreements between you and us in
              relation to your use of our website.
            </p>
            <p>
              <strong>16.</strong>
              <strong>Law and jurisdiction</strong>
            </p>
            <p>
              16.1. These terms and conditions shall be governed by and
              construed in accordance with Indian Law.
            </p>
            <p>
              16.2. Any disputes relating to these terms and conditions shall be
              subject to the exclusive<span>&nbsp;</span>OR non-exclusive
              jurisdiction of the courts of India.
            </p>
            <p>
              <strong>17.</strong>
              <strong>Statutory and regulatory disclosures</strong>
            </p>
            <p>
              17.1. We are registered in<span>&nbsp;</span>
              <em>trade register</em>; you can find the online version of the
              register at<span>&nbsp;</span>
              <em>99bookscart.com</em>
            </p>
            <p>
              17.2.We are subject to<span>&nbsp;</span>
              <em>authorisation scheme</em>, which is supervised by
              <em>
                <span>&nbsp;</span>supervisory authority
              </em>
              .
            </p>
            <p>
              18.<strong>Our details</strong>.
            </p>
            <p>
              18.1.&nbsp;<strong>You</strong>
              <span>&nbsp;</span>can contact us:
            </p>
            <ul>
              <li>Reach us at info@99bookscart.com</li>
              <li>using our website contact form</li>
            </ul>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default TermsConditionScreen
