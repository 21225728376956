export const BLOG_CREATE_REQUEST = 'BLOG_CREATE_REQUEST'
export const BLOG_CREATET_SUCCESS = 'BLOG_CREATET_SUCCESS'
export const BLOG_CREATE_FAIL = 'BLOG_CREATE_FAIL'

export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST'
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS'
export const BLOG_LIST_FAIL = 'BLOG_LIST_FAIL'

export const BLOG_GET_REQUEST = 'BLOG_GET_REQUEST'
export const BLOG_GET_SUCCESS = 'BLOG_GET_SUCCESS'
export const BLOG_GET_FAIL = 'BLOG_GET_FAIL'

export const BLOG_EDIT_REQUEST = 'BLOG_EDIT_REQUEST'
export const BLOG_EDIT_SUCCESS = 'BLOG_EDIT_SUCCESS'    
export const BLOG_EDIT_FAIL = 'BLOG_EDIT_FAIL'

export const BLOG_DELETE_REQUEST = 'BLOG_DELETE_REQUEST'
export const BLOG_DELETE_SUCCESS = 'BLOG_DELETE_SUCCESS'
export const BLOG_DELETE_FAIL = 'BLOG_DELETE_FAIL'
