import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Row,
  Col,
  ListGroup,
  Image,
  ListGroupItem,
  Form,
  FormGroup,
  Modal,
  Container,
} from 'react-bootstrap'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { convertToSlug } from '../../../helpers/helpers'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import EditIcon from '@mui/icons-material/Edit'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
const SellerProductView = ({ product }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  return (
    <Card sx={{ maxWidth: 300 }} md={{ maxWidth: 600 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={product.image ? product.image : product.product.image}
        title={product.product && product.product.name}
      />
      <CardContent>
        <Typography gutterBottom variant='body1'>
          {product.product && product.product.name}{' '}
          {product.status == 0 ? <PauseCircleFilledIcon /> : <CheckBoxIcon />}
        </Typography>
        <Typography variant='' color='text.secondary'>
          <>
            Qty:{product.qty} Price: ₹{product.salePrice.toLocaleString()}{' '}
            <br />
            <small>
              <AutoStoriesIcon /> {product.condition}
            </small>
          </>
        </Typography>
      </CardContent>
      <CardActions>
        {product.status == 1 && (
          <Link
            to={`/book/${product.product.isbn}/${convertToSlug(
              product.product.name
            )}`}
          >
            <Button size='small'>
              <RemoveRedEyeIcon /> View
            </Button>
          </Link>
        )}
        <Link to={`/seller/edit/book/${product._id}`}>
          <Button size='small'>
            <EditIcon /> Edit
          </Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default SellerProductView
