import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Row, Col, Pagination, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Form, Button, Select } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'
import { login } from '../../../actions/userActions'
import FormContainer from '../../../components/FormContainer'

import AWS from 'aws-sdk'
import fs from 'fs'
import {
  sellerScanProduct,
  createProduct,
} from '../../../actions/productActions'
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_UPDATE_RESET,
} from '../../../constants/productConstants'
import {
  createInventory,
  getInventory,
  updateInventory,
  deleteInventory,
} from '../../../actions/inventoryActions'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const EditBookScreen = ({ match, history }) => {
  const inventoryId = match.params.id
  const [uploading, setUploading] = useState(false)
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [condition, setCondition] = useState('')
  const [salePrice, setSaleprice] = useState('')
  const [image, setImage] = useState('')
  const [countInStock, setCountInStock] = useState('')
  const [edit, setEdit] = useState(false)
  const [deletA, setDeletA] = useState(false)

  const [qty, setQty] = useState('')

  const dispatch = useDispatch()

  const getInventoryRes = useSelector((state) => state.getInventory)
  const { loading, error, success, inventory } = getInventoryRes

  const updateInventoryRes = useSelector((state) => state.updateInventory)
  const { success: updateSuccess } = updateInventoryRes

  const deleteInventoryRes = useSelector((state) => state.deleteInventory)
  const { success: deleteSuccess } = deleteInventoryRes

  const productCreate = useSelector((state) => state.productCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = productCreate

  useEffect(() => {
    window.scrollTo(0, 0)
    if (
      (!loading && !inventory) ||
      (inventory && inventory._id !== inventoryId)
    ) {
      dispatch(getInventory(inventoryId))
    }
    if (!loading && success && inventory) {
      setName(inventory.product.name)
      setPrice(inventory.price)
      setSaleprice(inventory.salePrice)
      setQty(inventory.qty)
      setCondition(inventory.condition)
      setImage(inventory.image)
    }
  }, [
    dispatch,
    history,
    inventoryId,
    inventory,
    success,
    loading,
    deleteSuccess,
  ])
  const submitHandler = (e) => {
    e.preventDefault()
    setEdit(true)
    dispatch(
      updateInventory({
        _id: inventory._id,
        price,
        salePrice,
        qty,
        condition,
        image,
      })
    )
  }
  const deleteInven = () => {
    setDeletA(true)
    dispatch(deleteInventory(inventory._id))
  }
  const uploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }
  return (
    <Container>
      {/* <Link to='/admin/productlist' className='btn btn-light my-3'>
        Go back
      </Link> */}

      <FormContainer>
        {loadingCreate && <Loader></Loader>}
        {errorCreate && <Message>{errorCreate}</Message>}
        {loading ? (
          <Loader></Loader>
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Row>
              {deleteSuccess && deletA && (
                <Message>Deleted Successfully</Message>
              )}
              {updateSuccess && edit && <Message>Updated Successfully</Message>}
              <Col xs={12}>
                <div className='text-center'>
                  <img
                    src={inventory && inventory.image ? inventory.image : ''}
                    width='100px'
                  />
                  <br />
                  {name}
                </div>
                <Form.Group controlId='image'>
                  <Form.Label>Upload Book Image</Form.Label>
                  <Form.Control
                    type='hidden'
                    placeholder='Enter Image'
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                    
                  ></Form.Control>
                  <Form.File
                    id='image-file'
                    custom
                    onChange={uploadHandler}
                    
                    className='newbuttons'
                  ></Form.File>
                  {uploading && <Loader></Loader>}
                </Form.Group>
                <Form.Group controlId='isEbook'>
                  <Form.Label>Select Book Condition</Form.Label>
                  <Form.Control
                    as='select'
                    aria-label='Select Book Type'
                    onChange={(e) => setCondition(e.target.value)}
                    required
                    defaultValue={'New'}
                  >
                    <option
                      value='Excellent'
                      selected={condition == 'Excellent'}
                    >
                      Excellent
                    </option>
                    <option value='Good' selected={condition == 'Good'}>
                      Good
                    </option>
                    <option value='Readable' selected={condition == 'Readable'}>
                      Readable
                    </option>
                    <option value='New' selected={condition == 'New'}>
                      New
                    </option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId='price'>
                  <Form.Label>MRP</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='Enter price'
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='saleprice'>
                  <Form.Label>Sale Price</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Saleprice'
                    value={salePrice}
                    onChange={(e) => setSaleprice(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='countInStock'>
                  <Form.Label>Stock</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Stock'
                    value={qty}
                    onChange={(e) => setQty(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Row>
                  <Col>
                    <Button
                      varaint='primary'
                      className='float-left'
                      onClick={(e) => deleteInven()}
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col>
                    {' '}
                    <Button
                      type='submit'
                      varaint='primary'
                      className='float-right'
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </FormContainer>
    </Container>
  )
}

export default EditBookScreen
